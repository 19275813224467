import _ from "lodash";
import { useMemo } from "react";

export function useUrlParams(dependencies = []) {
  const urlParams = useMemo(() => {
    const searchParams = new URLSearchParams(window.location.search);
    const searchParamsEntries = searchParams.entries();
    const _urlParams = Object.fromEntries([...searchParamsEntries].map(([key, value]) => (key.includes("[]") ? [key.replace("[]", ""), searchParams.getAll(key)] : [key, value])));
    return _urlParams;
  }, dependencies);

  return { urlParams };
}
