import { CircularProgress, Paper, Typography } from "@mui/material";
import { HStack } from "Components/Layouts";
import { useEffect, useMemo, useRef, useState } from "react";
import { ScatterChart, Scatter, XAxis, YAxis, Tooltip, Text, TooltipProps, ResponsiveContainer, ReferenceDot, LabelList } from "recharts";
import { Coordinates, DataItem, TopicItem } from "../../Interface/TopicCluster.interface";
import { StyledBaseBox } from "../StyledComponent";
import { useSettingContext } from "../../Hooks/setting-hook";
interface IPerceptualMapView {
  data: TopicItem[];
  handlePointClick: (dataItem: TopicItem) => void;
  importantTopic: number;
}

/**
 * caculate the radius of the topic
 * the radius is calculated by the distance between the center and the farthest example
 * @param center
 * @param examples
 */
function calcRadius(center: Coordinates, examples: Coordinates[]) {
  const distance = examples.map((example) => {
    //cacluate  srqt((x1-x2)^2 + (y1-y2)^2)
    return Math.sqrt(Math.pow(center.x - example.x, 2) + Math.pow(center.y - example.y, 2));
  });
  return Math.max(...distance, 0.1);
}

export default function PerceptualMap({ data, handlePointClick, importantTopic }: IPerceptualMapView) {
  const [marginSize, setMarginSize] = useState(100);
  const minRadius = 10;
  const maxRadius = 100;
  // const radiusScale = useRadiusScale(data, minRadius, maxRadius);
  const elementRef = useRef<HTMLDivElement>(null);
  const [dimensions, setDimensions] = useState({ width: 0, height: 0 });
  const chartData = useMemo(() => transformData(data), [data]);
  const { setting } = useSettingContext();

  useEffect(() => {
    if (marginSize > 20 && data.length > 0) {
      const timer = setTimeout(() => {
        setMarginSize(marginSize - 2);
      }, 0.01);
      return () => clearTimeout(timer);
    }
  }, [marginSize, data.length]);

  useEffect(() => {
    if (elementRef.current) {
      setDimensions({
        width: elementRef.current.offsetWidth,
        height: elementRef.current.offsetHeight,
      });
    }
  }, []);
  useEffect(() => {
    const updateDimensions = () => {
      if (elementRef.current) {
        setDimensions({
          width: elementRef.current.offsetWidth,
          height: elementRef.current.offsetHeight,
        });
      }
    };

    updateDimensions();
    window.addEventListener("resize", updateDimensions);

    return () => {
      window.removeEventListener("resize", updateDimensions);
    };
  }, []);
  useEffect(() => {}, [setting]);
  function transformData(data: TopicItem[]) {
    return data.map((entry) => {
      const { examples, ...rest } = entry;

      //map example coordinates
      const mappedExmpls = examples.map((example, index) => {
        return {
          ...example,
          coor: {
            x: example.coor.x,
            y: example.coor.y,
            // z: 5,
          },
        };
      });

      //topic coordinates

      const topic = {
        ...entry,
        examples: mappedExmpls,
        coor: {
          ...entry.coor,
          z: calcRadius(
            entry.coor,
            mappedExmpls.map((exmpl) => exmpl.coor)
          ),
        },
      };

      return topic;
    });
  }
  const renderScatters = () => {
    switch (setting.display.showItem) {
      case "All":
        return (
          //Ensure the top 5 topics are always on top
          <>
            {chartData.slice(0, setting.display.displayCluster).flatMap((entry, index) => renderExampleScatters(entry, index))}
            {chartData.slice(importantTopic, setting.display.displayCluster).map((entry, index) => renderTopicScatter(entry, index + importantTopic))}
            {chartData.slice(0, importantTopic).map((entry, index) => renderTopicScatter(entry, index))}
          </>
        );
      case "Topic":
        return (
          <>
            {chartData.slice(importantTopic, setting.display.displayCluster).map((entry, index) => renderTopicScatter(entry, index + importantTopic))}
            {chartData.slice(0, importantTopic).map((entry, index) => renderTopicScatter(entry, index))}
          </>
        );
      case "Example":
        return chartData.slice(0, setting.display.displayCluster).flatMap((entry, index) => renderExampleScatters(entry, index));
      default:
        return null;
    }
  };

  const renderTopicScatter = (entry: TopicItem, index: number) => {
    const radius = entry.coor.z ? (entry.coor.z * Math.max(dimensions.height, dimensions.width)) / 15 : 0;

    return (
      <Scatter
        key={`scatter-topic-${index}`}
        name={entry.topic_name}
        cx={entry.coor.x}
        cy={entry.coor.y}
        data={[entry]}
        radius={radius}
        fill={getColor(index, true)}
        shape={<CustomCircle />}
        //shape={setting.display.showCircle ? <CustomCircle /> : () => null}
        onClick={() => handlePointClick(entry)}
      >
        <LabelList dataKey="topic_name" content={<CustomLabel payload={entry} fill={getColor(index, true)} labelFormat={setting.display.labelFormat} />} />
      </Scatter>
    );
  };

  const renderExampleScatters = (entry: TopicItem, index: number) => {
    return <Scatter key={`scatter-example-${index}`} data={entry.examples} fill={getColor(index, false)} shape={"cross"} />;
  };

  const renderChart = () => {
    return (
      <ResponsiveContainer width="100%" height="100%">
        <ScatterChart
          margin={{
            top: marginSize,
            right: marginSize,
            bottom: marginSize,
            left: marginSize,
          }}
        >
          <XAxis type="number" dataKey="coor.x" range={[0, 10]} hide />
          <YAxis type="number" dataKey="coor.y" range={[0, 10]} hide />
          {/* <ZAxis type="number" dataKey="coor.z" /> */}
          <Tooltip wrapperStyle={{ zIndex: 20 }} content={<CustomTooltip />} cursor={{ strokeDasharray: "3 3" }} />
          {renderScatters()}
        </ScatterChart>
      </ResponsiveContainer>
    );
  };

  return (
    <HStack width={"100%"} height={"100%"}>
      <StyledBaseBox ref={elementRef}>{data.length > 0 ? renderChart() : <CircularProgress size={40} />}</StyledBaseBox>
    </HStack>
  );
}

export const getColor = (index: number, transparent: boolean = false, opacity = 0.6) => {
  const baseColors = ["#ff595e", "#ffca3a", "#8ac926", "#1982c4", "#6a4c93"];
  const hexToRgba = (hex: string, opacity: number) => {
    const r = parseInt(hex.slice(1, 3), 16);
    const g = parseInt(hex.slice(3, 5), 16);
    const b = parseInt(hex.slice(5, 7), 16);
    return `rgba(${r}, ${g}, ${b}, ${opacity})`;
  };
  if (index < baseColors.length) {
    return transparent ? hexToRgba(baseColors[index], opacity) : baseColors[index];
  }
  return hexToRgba("#DCDCDC", opacity);
};

const CustomTooltip = ({ active, payload }: TooltipProps<number, string>) => {
  if (active && payload && payload.length > 0) {
    const data = payload[0].payload;
    if (data.text) {
      return (
        <Paper sx={{ padding: "10px" }}>
          <Typography variant="subtitle1">{data.text}</Typography>
        </Paper>
      );
    } else {
      return (
        <Paper sx={{ padding: "10px" }}>
          <Typography variant="h5">{data.topic_name}</Typography>
          <Typography variant="body1">{data.examples.length} Examples</Typography>
          {data.examples.slice(0, 20).map((example: { text: string }, index: number) => (
            <Typography key={`tooltip-example-${index}`} variant="body2">
              {example.text}
            </Typography>
          ))}
          {data.examples.length > 20 && <Typography variant="body2">...</Typography>}
        </Paper>
      );
    }
  }
  return null;
};
const CustomLabel = (props: any) => {
  const { x, y, value, width, payload, fill, labelFormat } = props;
  const radius = width / 2;
  const centerX = x + radius;
  const centerY = y + radius;
  const maxWidth = radius * 1.5;
  const averageCharWidth = 6;
  const maxCharCount = Math.floor(maxWidth / averageCharWidth);
  const trimmedValue = value.length > maxCharCount ? `${value.substring(0, maxCharCount - 3)}...` : value;

  const fontSize = labelFormat == "ratio" ? Math.sqrt(payload.coor.z) / 2 : 20;
  const fillColor = labelFormat == "ratio" ? fill : "white";

  return (
    <Text
      x={centerX}
      y={centerY}
      textAnchor="middle"
      verticalAnchor="middle"
      style={{
        wordWrap: "break-word",
        overflowWrap: "break-word",
        maxWidth: maxWidth,
      }}
      fill={fillColor}
      fontSize={`${fontSize}px`}
      pointerEvents="none"
    >
      {trimmedValue}
    </Text>
  );
};
const CustomCircle = (props: any) => {
  return <circle cx={props.cx} cy={props.cy} r={props.radius} fill={props.fill} stroke={props.stroke} onClick={props.onClick} style={{ cursor: "pointer" }} />;
};
