import { Component } from "react";

import { observer } from "mobx-react";
import PropsType from "prop-types";
import _ from "lodash";
import { Box, Typography } from "@mui/material";

import schema from "./tbschema";
import Summary from "./Summary/Summary";

import Datumizo from "Components/LabIZO/Datumizo/Datumizo";
import { Accessor, ColorX, ErrorX, store } from "static";
import { HStack, VStack } from "Components/LabIZO/Stackizo";
import moment from "moment";
import { DATA_LINK, backendConnector } from "connectors";

class TrainTagging extends Component {
  static propTypes = {
    docID: PropsType.string,
    doc: PropsType.object,
    onQuit: PropsType.func,
    onQuitRefresh: PropsType.func,
    addOns: PropsType.object,
  };

  static defaultProps = {
    docID: "",
    doc: {},
    onQuit: () => {},
    onQuitRefresh: () => {},
    addOns: {},
  };

  constructor() {
    super();
    this.state = {
      title: "",
      serverSidePagination: false,
      intents: [],
      base: {
        title: "",
        exportDoc: "",
        schema: schema,
        reqAuth: "",

        tablizo: {
          showSelector: false,
          columnsToolbar: false,
          filterToolbar: false,
          densityToolbar: true,
          exportToolbar: false,
        },

        Connect: {
          DBInfo: DATA_LINK.TBankDBInfo,
          List: DATA_LINK.TBankList,
          Get: DATA_LINK.TBankGet,
          schema: schema.Table,
        },

        operations: {
          Edit: {
            title: "Edit TrainTagging ",
            url: DATA_LINK.TBankEdit,
            success: "TrainTagging Edited Successfully",
            fail: "TrainTagging Edit Failed: ",
            schema: schema.Edit,
            buttons: ["Revert", "Submit"],
            onSubmit: "Edit",
          },

          Info: {
            title: "TrainTagging ",
            success: "TrainTagging Load Successfully",
            fail: "TrainTagging Load Failed: ",
            schema: schema.Info,
          },
        },

        buttons: {
          inline: [],
          left: [
            {
              inject: (tableData, addOns) => this.renderLogDetails(addOns),
            },
          ],
          right: [
            {
              inject: (tableData) => (
                <HStack width={200} key="inject">
                  <Summary data={tableData} />
                </HStack>
              ),
            },
            {
              icon: "",
              func: this._StartTraining,
              caption: "Proceed to Training",
              reqFunc: "Train",
              theme: {
                label: "white",
                background: "#1976d2",
                hover: {
                  background: "#115293",
                },
              },
            },
          ],
        },
      },
    };
  }

  componentDidMount() {
    this._setAllStates(() => {
      this.getAllIntents();
      this.getAllAnswers();
    });
  }

  componentDidUpdate(prevProps, prevState) {
    if (!Accessor.IsIdentical(prevProps, this.props, Object.keys(TrainTagging.defaultProps))) {
      this._setAllStates();
    }
  }

  componentWillUnmount() {
    this.setState = (state, callback) => {
      return;
    };
  }

  _setAllStates = (callback) => {
    this.setState(
      (state, props) => ({
        ...props,
      }),
      callback
    );
  };

  _StartTraining = async () => {
    let { doc, onQuitRefresh } = this.props;
    console.log("_StartTraining", doc);
    const url = DATA_LINK.TrainingTrain;
    try {
      store.isLoading(true);
      await backendConnector.post(url, doc);
      store.isLoading(false);
      if (onQuitRefresh) onQuitRefresh(doc._id);
    } catch (e) {
      ErrorX.Handle(e);
    }
  };

  onMountDatumizo = (callbacks) => {
    this.MountDatumizo = callbacks;

    //Prevent from disappearing drop down menu on first click
    const ele = document.getElementsByClassName("cellValue")[0];
    if (ele) {
      ele.click();
    }
  };

  getAllIntents = async () => {
    try {
      const { Success, payload } = await backendConnector.post(
        DATA_LINK.IntentList,
        {
          skip: 0,
          limit: 9999,
          fields: ["_id"],
        },
        {
          workspace: "ALL",
          allIntents: true,
        }
      );
      if (Success === true) {
        let docs = payload.docs;
        let intents = _.map(docs, (o, i) => {
          let splited = o._id.split(":");
          let caption = o._id;
          if (splited.length === 2) {
            caption = splited[1];
          }
          return {
            _id: o._id,
            caption: caption,
          };
        });
        intents = [...intents];
        this.setState((state, props) => ({
          intents: intents,
        }));
      } else {
        ErrorX.Handle(payload.message || "");
      }
    } catch (e) {
      ErrorX.Handle(e);
    }
  };

  getAllAnswers = async () => {
    try {
      const { Success, payload } = await backendConnector.post(DATA_LINK.AnswerList, {});
      if (Success === true) {
        let docs = payload.docs;
        this.setState((state, props) => ({
          answers: docs,
        }));
      } else {
        store.Alert(ErrorX.Handle(payload.message || ""), "error");
      }
    } catch (e) {
      ErrorX.Handle(e);
    }
  };

  _onPassToggle = async (id, f) => {
    let { docID } = this.props;
    const url = DATA_LINK.TBankAccept;
    try {
      const { Success, payload } = await backendConnector.post(url, {
        logID: id,
        trainID: docID,
        value: f,
        mode: "Before",
      });
      if (Success === true) {
        store.Alert("Record Updated", "success");
        this.MountDatumizo.SoftReload(id);
      } else {
        store.Alert("Record Update Failed", "error");
      }
    } catch (e) {
      ErrorX.Handle(e);
    }
  };

  _onNewToggle = async (id, f) => {
    let { docID } = this.props;
    const url = DATA_LINK.TBankNewIntent;
    try {
      const { Success, payload } = await backendConnector.post(url, {
        logID: id,
        trainID: docID,
        value: f,
        mode: "Before",
      });

      if (Success === true) {
        store.Alert("Record Updated", "success");
        this.MountDatumizo.SoftReload(id);
      } else {
        store.Alert("Record Update Failed", "error");
      }
    } catch (e) {
      ErrorX.Handle(e);
    }
  };

  _onSuggestIntent = async (id, intent) => {
    let { docID } = this.props;
    const url = DATA_LINK.TBankSuggestedIntent;
    try {
      const { Success, payload } = await backendConnector.post(url, {
        logID: id,
        trainID: docID,
        value: intent,
        mode: "Before",
      });
      if (Success === true) {
        store.Alert("Record Updated", "success");
        this.MountDatumizo.SoftReload(id);
      } else {
        store.Alert("Record Update Failed", "error");
      }
    } catch (e) {
      ErrorX.Handle(e);
    }
  };

  renderLogDetails(addOns) {
    if (!addOns) return;
    let { doc } = addOns;
    if (!doc) return;
    const from = moment(doc.logPeriod.from).format("DD/MM/YYYY");
    const to = moment(doc.logPeriod.to).format("DD/MM/YYYY");
    const logPeriodText = from == "Invalid date" || to == "Invalid date" ? "" : from + " - " + to;
    return (
      <HStack key="details" justifyContent="flex-start" gap={50} width="fit-content">
        <Typography
          style={{
            textAlign: "left",
            fontSize: 16,
            color: ColorX.GetColorCSS("dark"),
          }}
        >
          {doc.logPeriod && "Log Period: " + logPeriodText}
        </Typography>
        <Typography
          style={{
            textAlign: "left",
            fontSize: 16,
            color: ColorX.GetColorCSS("dark"),
          }}
        >
          {"Sample Size: " + (doc.sampleSize * 100 + "%")}
        </Typography>
      </HStack>
    );
  }

  renderAlias() {
    let { doc } = this.props;
    return (
      <HStack justifyContent="flex-start">
        <Typography
          style={{
            textAlign: "left",
            width: "100%",
            minWidth: 500,
            fontSize: 25,
            color: ColorX.GetColorCSS("Primary"),
          }}
        >
          {doc.alias}
        </Typography>
      </HStack>
    );
  }

  render() {
    let { base, serverSidePagination, addOns, intents, answers } = this.state;
    let { docID, doc } = this.props;
    let ModaddOns = {
      ...addOns,
      doc: doc,
      trainID: docID,
      onPassToggle: this._onPassToggle,
      onNewToggle: this._onNewToggle,
      onSuggestIntent: this._onSuggestIntent,
      intents: intents,
      answers: answers,
    };
    return (
      <VStack width="100%">
        <Box padding={1} width="100%">
          <VStack alignItems="flex-start">
            {this.renderAlias()}
            {this.renderLogDetails()}
          </VStack>
        </Box>
        <Datumizo
          base={base}
          onMounted={this.onMountDatumizo}
          serverSidePagination={serverSidePagination}
          refreshOnPageChange={false}
          addOns={ModaddOns}
          onDataChange={this.onDataChange}
          languageSelector={false}
        />
      </VStack>
    );
  }
}

export default observer(TrainTagging);
