import { StyledSwitch, StyledLabel, StyledDiv } from "./StyledSwitchButton";
import { DATA_LINK, backendConnector } from "connectors";
import { ErrorX, store } from "static";

interface SwitchButtonProps {
  defaultValue: boolean;
  URL?: DATA_LINK;
  row?: any;
  fetchData?: (force_refresh: boolean) => void;
  disabled?: boolean;
  customOnChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

export const SwitchButton = ({ defaultValue, URL, row, fetchData, disabled, customOnChange }: SwitchButtonProps) => {
  const handleSwitchChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    if (!URL) return store.Alert("URL is not defined", "error");
    const res = await backendConnector.post(URL, {
      selected: [row?._id],
      enabled: event.target.checked,
    });
    const status = event.target.checked ? "Disabled" : "Enabled";
    if (!res.Success) {
      store.Alert(`FAQ ${status} failed` + res?.message, "error");
      return ErrorX.Handle(res.payload);
    }
    store.Alert(`Row ${row?.alias}  ${status}`, "success");
    setTimeout(() => {
      fetchData && fetchData(true);
    }, 1000);
  };

  return (
    <StyledDiv>
      {defaultValue && (
        <StyledLabel variant="subtitle2" style={{ left: 7 }}>
          ON
        </StyledLabel>
      )}
      <StyledSwitch checked={defaultValue} onChange={customOnChange ?? handleSwitchChange} disabled={disabled} />
      {!defaultValue && (
        <StyledLabel variant="subtitle2" style={{ right: 7 }}>
          OFF
        </StyledLabel>
      )}
    </StyledDiv>
  );
};
