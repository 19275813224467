export type DataEventLog<T extends DataDiffChangeDelta | DataDiffChangeDefault = DataDiffChangeDelta> = {
  _id: string;
  recTime: string;
  auditLogId: string;
} & DataDiff<T>;

export interface DataDiff<T> {
  tableName: string; // e.g. Intent
  docName: string; // e.g. "master:faq_name"
  changes: T[];
}
export interface DataDiffChangeDelta extends BaseDataDiffChange {
  add?: string;
  del?: string;
  update?: [DiffDelta, string][];
}

// based on fast-diff (https://github.com/jhchen/fast-diff); do not edit
export enum DiffDelta {
  DELETE = -1,
  INSERT = 1,
  EQUAL = 0,
}

export interface BaseDataDiffChange {
  field?: string;
  type: DataDiffType;
}

export interface DataDiffChangeDefault extends BaseDataDiffChange {
  before?: string;
  after?: string;
}

export enum DataDiffType {
  ADD = "addition",
  DELETE = "deletion",
  UPDATE = "update",
  CREATE_DOC = "createDoc",
  DELETE_DOC = "deleteDoc",
}
