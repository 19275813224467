import { Typography, Switch } from "@mui/material";
import { styled } from "@mui/material/styles";

export const StyledSwitch = styled(Switch)(({ theme }) => ({
  width: 56,
  height: 26,
  padding: 0,
  "& .MuiSwitch-switchBase": {
    padding: 1,
    "&.Mui-checked": {
      transform: "translateX(30px)",
      color: theme.palette.common.white,
      "& + .MuiSwitch-track": {
        backgroundColor: "primary.main",
        opacity: 1,
        border: "none",
      },
    },
    "&.Mui-disabled": {
      color: "grey",
      "& + .MuiSwitch-track": {
        backgroundColor: "grey",
        opacity: 0.3,
      },
    },
  },
  "& .MuiSwitch-thumb": {
    width: 24,
    height: 24,
  },
  "& .MuiSwitch-track": {
    borderRadius: 13,
    backgroundColor: "grey",
    opacity: 1,
    transition: theme.transitions.create(["background-color", "border"]),
  },
}));

export const StyledLabel = styled(Typography)(({ theme }) => ({
  fontSize: 12,
  position: "absolute",
  zIndex: 1,
  color: theme.palette.common.white,
  userSelect: "none",
  pointerEvents: "none",
}));

export const StyledDiv = styled("div")({
  display: "flex",
  position: "relative",
  alignItems: "center",
  cursor: "pointer",
});
