import store from "./AppStore";
import axios from "axios";

import { DOMAIN } from "config/config";
import { ErrorX } from ".";
import { DATA_LINK, backendConnector } from "connectors";

class Env {
  static async CheckInitialized() {
    let url = DOMAIN + "/Base/Env/IsInitialized";

    let payloadOut = {
      JWT: store.user.JWT,
    };

    try {
      store.isLoading(true);
      const { Success, payload } = await backendConnector.post(DATA_LINK.IsInitialized);
      if (Success) {
        if (!payload) {
          store.Alert("The project is not initialized. \n Please follow the steps to initialize it.", "info");
          store.isLoading(false);
          store.setInitialized(false);
        } else {
          store.isLoading(false);
          store.setInitialized(true);
        }
      } else {
        store.setInitialized(false);
        store.isLoading(false);
      }
    } catch (e) {
      console.log(e);
      store.Alert("Cannot connect to server", "error");
      store.setInitialized(false);
      store.isLoading(false);
    }
  }

  static Logout = async () => {
    await backendConnector.post(DATA_LINK.SignOut);
    setTimeout(() => {
      store.clearUser();
      store.clearClassicMenuMode();

      //redirect to login page
      window.location.assign("./");
    }, 500);
  };

  static async RefreshToken() {
    if (!store.user.JWT) return;
    let payloadOut = {
      JWT: store.user.JWT,
    };

    try {
      //refresh token
      const refreshRes = await backendConnector.post(DATA_LINK.RefreshToken);
      if (!refreshRes.Success) {
        store.Alert("Login Expired", "error");
        this.Logout();
      } else {
        console.log("jwt token refreshed");
        store.setUser({
          ...store.user,
          JWT: refreshRes.payload.jwt,
        });
      }
    } catch (e) {
      console.error(e);
      store.Alert("Login Expired", "error");
      this.Logout();
    }
  }
  static async GetSysInfo() {
    if (!store.user.JWT) return;
    try {
      const data = await backendConnector.post(DATA_LINK.SYSGeneral);
      if (!data.Success) {
        ErrorX.Handle(data.payload);
      }
      store.setSysInfo(data.payload);
    } catch (e) {
      store.Alert("Cannot get system info", "error");
      this.Logout();
    }
  }
}

export default Env;
