import React, { Component } from "react";

import PropsType from "prop-types";
import { Typography } from "@mui/material";
import { HtmlParserWrapper } from "Components/HtmlParserWrapper";

import "./DialogBox.css";
import ZTagParser from "Components/Messenger/components/ZChat/ZCMsg/ZCMHTMLTags/ZTagParser";

import { Accessor } from "static";

/**
 *
 * @augments {Component<Props, State>}
 */
class DialogBox extends Component {
  static propTypes = {
    onMounted: PropsType.func,
  };

  static defaultProps = {
    onMounted: null,
  };

  constructor() {
    super();
    this.state = {};
  }

  componentDidMount() {
    this._setAllStates();
  }

  componentDidUpdate(prevProps, prevState) {
    if (!Accessor.IsIdentical(prevProps, this.props, Object.keys(DialogBox.defaultProps))) {
      this._setAllStates();
    }
  }

  componentWillUnmount() {
    this.setState = (state, callback) => {
      return;
    };
  }

  _setAllStates = (callback) => {
    this.setState(
      (state, props) => ({
        ...props,
      }),
      () => {
        if (this.props.onMounted) {
          this.props.onMounted({
            showMessage: this._showMessage,
          });
        }
        if (callback) callback();
      }
    );
  };

  ReplaceSpecialTags = (text) => {
    let parsed = <HtmlParserWrapper html={text} />;
    return ZTagParser.Parse("", parsed, {});
  };

  _showMessage = (msg) => {
    let blocks = this.ReplaceSpecialTags(msg);
    this.setState(
      {
        msg: blocks,
        show: false,
      },
      () => {
        setTimeout(() => {
          this.setState({
            show: true,
          });
        }, 500);
      }
    );
  };

  render() {
    let { msg, show } = this.state;
    return (
      <div className={"speech-container " + (show ? "show" : "hide")}>
        <div className={"speech-bubble"}>
          <Typography style={{ transform: "rotate(2deg)" }}>{msg}</Typography>
        </div>
      </div>
    );
  }
}

export default DialogBox;
