import { IFLEDoc } from "interfaces/db.interfaces/fle.interface";
import { DBAnswerSpec } from "interfaces";

export const defaultDoc: IFLEDoc<DBAnswerSpec> = {
  _id: "",
  alias: "",
  contexts: [],
  intentDoc: {
    _id: "",
  },
  answers: {
    defaultAnsDoc: {
      _id: "",
      text: { EN: [""] },
      name: "",
      domain: "master",
    },
  },
};
