export enum RoleId {
  GENERAL_PUBLIC = "Public",
  HEALTHCARE_PROFESSIONAL = "Professional",
  HEALTHCARE_PROVIDER = "Provider",
}

export const Roles = [
  {
    value: RoleId.GENERAL_PUBLIC,
    display: {
      EN: "General Public",
      TC: "一般市民",
      SC: "一般市民",
    },
  },
  {
    value: RoleId.HEALTHCARE_PROFESSIONAL,
    display: {
      EN: "Healthcare Professional",
      TC: "醫護專業人員",
      SC: "医护专业人员",
    },
  },
  {
    value: RoleId.HEALTHCARE_PROVIDER,
    display: {
      EN: "Healthcare Provider",
      TC: "醫護機構",
      SC: "医护机构",
    },
  },
];
