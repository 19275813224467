import _, { set } from "lodash";
import moment from "moment";
import { Typography, Button, Link, Tabs, Tab, Alert } from "@mui/material";
import { SaveOutlined, Warning } from "@mui/icons-material";
import schema from "./schema";
import { DATA_LINK, backendConnector } from "connectors";
import Tablizo from "Components/LabIZO/Tablizo";
import { Accessor, ColorX, store, ErrorX, Authority } from "static";
import { HStack, Spacer, VStack } from "Components/LabIZO/Stackizo";
import React, { useEffect, useState } from "react";
import TagForm from "../SysBnR/_gears/TagForm";
import Dropzone from "../SysBnR/_gears/Dropzone";
import { DOMAIN } from "config/config";
import { BACKUP_SERVICE, BNR_MODE } from "./constants/backup-mode";
import { Loading } from "Components/Loading";
import ExpandableBox from "Components/ExpandBox/Expandbox";

interface UserBnRState {
  LastBackup?: string;
  Backups: any[];
  LastImport?: string;
  Imports: any[];
  BackupRes: any[];
  DBBackupRes: any[];
}

interface BnRFileLoc {
  db?: string;
  watsons?: string;
  gptDoc?: string;
  combined?: string;
}

const SysUserBnR: React.FC<{}> = () => {
  const [TagFormRow, setTagFormRow] = useState<any | null>(null);
  const [fileloc, setFileloc] = useState<{ db?: string; watsons?: string; combined?: string } | null>(null);
  const [acceptedFiles, setAcceptedFiles] = useState<File[]>([]);
  const [bnrMode, setBnrMode] = useState<BNR_MODE>(BNR_MODE.BACKUP);

  const [showBackupTagForm, setShowBackupTagForm] = useState<boolean>(false);
  const [showImportTagForm, setShowImportTagForm] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);

  const [UserBnRState, setUserBnRState] = useState<UserBnRState>({
    LastBackup: "",
    Backups: [],
    LastImport: "",
    Imports: [],
    BackupRes: [],
    DBBackupRes: [],
  });

  const { reqFunc } = schema;

  useEffect(() => {
    _GetInfo();
  }, []);

  const _dateStrToDisplay = (str: any) => {
    let mmt = moment(str, "YYYYMMDDHHmmss");
    return mmt.format("DD MMM, YYYY HH:mm:ss");
  };

  const _GetInfo = async () => {
    try {
      const res = await backendConnector.post(DATA_LINK.SYSBackupRestore, { user: true });
      let { Success, payload } = res;
      if (Success) {
        let { LastBackup, Backups, BackupDetails, ImportDetails, Imports, LastImport } = payload;
        console.log("ImportDetails:", ImportDetails);
        console.log("BackupDetails:", BackupDetails);

        Backups = _.map(Backups, (o, i) => {
          return {
            _id: o,
            name: _dateStrToDisplay(o),
            tag: BackupDetails && BackupDetails[i]?.tag,
            fileloc: BackupDetails && BackupDetails[i]?.fileloc,
            backedUpFiles: BackupDetails && BackupDetails[i]?.backedUpFiles,
          };
        });

        Imports = _.map(Imports, (o, i) => {
          return {
            _id: o,
            name: _dateStrToDisplay(o),
            tag: ImportDetails && ImportDetails[i]?.tag,
            fileloc: ImportDetails && ImportDetails[i]?.fileloc,
          };
        });

        let lastFileloc = _.get(BackupDetails[0], "fileloc", undefined);
        let backupRes: any[] = [];

        if (lastFileloc) {
          backupRes = Object.keys(BACKUP_SERVICE).map((key) => {
            return {
              name: BACKUP_SERVICE[key],
              status: _.get(lastFileloc, key, false),
            };
          });
        }

        let dbBackupRes = (BackupDetails && BackupDetails[0]?.backedUpFiles?.db) || [];
        dbBackupRes = _.map(dbBackupRes, (o, i) => {
          return {
            name: o,
            status: true,
          };
        });

        setUserBnRState({
          LastBackup: LastBackup,
          Backups: Backups,
          LastImport: LastImport,
          Imports: Imports,
          BackupRes: backupRes,
          DBBackupRes: dbBackupRes,
        });
      } else {
        store.Alert("Server return error.", "error");
      }
    } catch (e) {
      console.log(e);
      store.Alert("Cannot connect to Server.", "error");
    }
  };

  const onError = (e: any) => {
    ErrorX.Handle(e); // Display error message
    let msg = e.message || "";
    store.Alert(msg, "error");
    store.clearAsk();
  };

  const _Backup = {
    onClick: () => {
      store.Ask("Backup", "Backup System?", _Backup.onSubmit);
    },
    onSubmit: async () => {
      try {
        const res = await backendConnector.post(DATA_LINK.SYSBackup, { user: true });
        console.log(res);
        let { Success, payload } = res;
        if (Success) {
          store.Alert("Backup Successful.", "success");
        } else {
          onError(payload);
        }
        _GetInfo();
      } catch (e) {
        onError(e);
      }
    },
    onDelete: {
      onClick: (datestr: string) => {
        let str = _dateStrToDisplay(datestr);
        store.Ask("Delete", "Delete Backup " + str + "?", async () => {
          await _Backup.onDelete.onSubmit(datestr);
        });
      },
      onSubmit: async (datestr: string) => {
        try {
          const res = await backendConnector.post(DATA_LINK.SYSBackupRestoreDelete, { datestr: datestr, user: true });
          let { Success, payload } = res;
          if (Success) {
            store.Alert("Download Successful.", "success");
            _GetInfo();
          } else {
            onError(payload);
          }
        } catch (e) {
          onError(e);
        }
      },
    },

    onTag: {
      onClick: (row: any) => {
        setTagFormRow(row);
        setShowBackupTagForm(true);
      },
      onClose: () => {
        setShowBackupTagForm(false);
      },
      onSubmit: async (row: any) => {
        try {
          const res = await backendConnector.post(DATA_LINK.SYSBackupRestoreAddtag, { ...row, user: true });
          console.log(res);
          let { Success, payload } = res;
          if (Success) {
            store.Alert(row._id + " Tag Successfully Updated.", "success");
            _GetInfo();
            _Backup.onTag.onClose();
          } else {
            onError(payload);
          }
        } catch (e) {
          onError(e);
        }
      },
    },

    onDownload: {
      onClick: async (fileloc: BnRFileLoc) => {
        setLoading(true);
        setFileloc(fileloc);
        try {
          const res = await backendConnector.post(DATA_LINK.SYSUserBnRDownload, { fileloc: fileloc, dir: "backup" });
          if (!res.Success) {
            onError(res);
          } else {
            let { payload } = res;
            let url = payload?.combined && DOMAIN + payload?.combined.replace("./", "/");
            window.open(url, "_blank");
            store.Alert("Download Successful.", "success");
          }
        } catch (e) {
          onError(e);
        }
        setLoading(false);
      },
    },

    onRestore: {
      onClick: (datestr: string) => {
        let str = _dateStrToDisplay(datestr);
        store.Ask("Restore", "Restore System to " + str + "?<br/>The current state of the system will be backup-ed automatically.", async () => {
          await _Backup.onRestore.onSubmit(datestr);
        });
      },
      onSubmit: async (datestr: string) => {
        let str = _dateStrToDisplay(datestr);

        try {
          const res = await backendConnector.post(DATA_LINK.SYSRestore, {
            datestr: datestr,
            user: true,
            dir: "backup",
          });
          let { Success, payload } = res;
          if (Success) {
            store.Alert("Restore Successful to \n" + str + ".", "success");
            _GetInfo();
          } else {
            onError(payload);
          }
        } catch (e: any) {
          onError(e);
        }
      },
    },
  };

  const _Import = {
    onClick: (datestr: any) => {
      //no file in the dropzone
      if (!acceptedFiles) {
        store.Alert("No files to import", "error");
        store.clearAsk();
        return;
      }
      //more than 1 file
      if (acceptedFiles?.length > 1) {
        store.Alert("Please upload only 1 .enc file at a time.", "error");
        store.clearAsk();
        return;
      }
      //ok, proceed
      store.Ask("Import", "Import and Deploy System?.", async () => {
        await _Import.onSubmit();
      });
    },

    onSubmit: async () => {
      store.SetAskLoading(true);
      console.log("submit Import");

      try {
        if (acceptedFiles) {
          const res = await backendConnector.upload(DATA_LINK.SYSUserBnRImport, acceptedFiles[0]);
          if (!res.Success) throw new Error(res.payload);
          _Import.onSuccess(res.payload);
        }
      } catch (e) {
        onError(e);
      }
    },

    onSuccess: (payload: any) => {
      if (!_.isEmpty(payload.error)) {
        let msg = _.map(payload.error, (o, i) => "ID (" + o.id + "): " + o.error);
        store.Alert("Import Successfully with warning: \n" + msg.join("\n"), "warning");
      } else {
        store.Alert("Import Successfully.", "success");
      }
      store.clearAsk();
      _GetInfo();
    },

    onDelete: {
      onClick: (datestr: string) => {
        let str = _dateStrToDisplay(datestr);
        store.Ask("Delete", "Delete Backup " + str + "?", async () => {
          await _Import.onDelete.onSubmit(datestr);
        });
      },
      onSubmit: async (datestr: string) => {
        let str = _dateStrToDisplay(datestr);

        try {
          const res = await backendConnector.post(DATA_LINK.SYSBackupRestoreDelete, {
            datestr: datestr,
            dir: "import",
            user: true,
          });
          let { Success, payload } = res;
          if (Success) {
            store.Alert(str + " Successfully Deleted.", "success");
            _GetInfo();
          } else {
            onError(payload);
          }
        } catch (e) {
          onError(e); //TODO
        }
      },
    },

    onTag: {
      onClick: (row: any) => {
        setTagFormRow(row);
        setShowImportTagForm(true);
      },
      onClose: () => {
        setShowImportTagForm(false);
      },
      onSubmit: async (row: any) => {
        try {
          const res = await backendConnector.post(DATA_LINK.SYSBackupRestoreAddtag, { ...row, dir: "import", user: true });
          let { Success, payload } = res;
          if (Success) {
            store.Alert(row._id + " Tag Successfully Updated.", "success");
            _GetInfo();
            _Import.onTag.onClose();
          } else {
            onError(payload);
          }
        } catch (e) {
          onError(e);
        }
      },
    },

    onDownload: {
      onClick: async (fileloc: BnRFileLoc) => {
        setFileloc(fileloc);
        const res = await backendConnector.post(DATA_LINK.SYSUserBnRDownload, { fileloc: fileloc, dir: "import" });
        if (!res.Success) {
          store.Alert("Download Failed.", "error");
          return;
        }
        let { payload } = res;
        let url = payload?.combined && DOMAIN + payload?.combined.replace("./", "/");
        window.open(url, "_blank");

        store.Alert("Download Successful.", "success");
        store.clearAsk();
      },
    },

    onRestore: {
      onClick: (datestr: string) => {
        let str = _dateStrToDisplay(datestr);
        store.Ask("Restore", "Restore System to " + str + "?<br/>The current state of the system will be backup-ed automatically.", async () => {
          await _Import.onRestore.onSubmit(datestr);
        });
      },
      onSubmit: async (datestr: string) => {
        console.log("Clicked at import record restore ", datestr);
        let str = _dateStrToDisplay(datestr);

        try {
          const res = await backendConnector.post(DATA_LINK.SYSRestore, {
            datestr: datestr,
            user: true,
            dir: "import",
          });
          let { Success, payload } = res;
          if (Success) {
            store.Alert("Restore Successful to \n" + str + ".", "success");
            _GetInfo();
          } else {
            onError(payload);
          }
        } catch (e: any) {
          onError(e);
        }
      },
    },
  };

  const renderVersions = (type: "backup" | "import") => {
    let { Backups, Imports } = UserBnRState;
    let data = Backups;
    if (type === "import") data = Imports;

    if (!data) return <></>;

    return (
      <>
        <Tablizo
          width={600}
          height="100%"
          density="compact"
          auth={store.user.authority}
          level={store.user.level}
          schema={schema.restore}
          showSelector={false}
          data={data}
          addOns={{
            Restore: type === "backup" ? _Backup.onRestore.onClick : _Import.onRestore.onClick,
            Delete: type === "backup" ? _Backup.onDelete.onClick : _Import.onDelete.onClick,
            Download: type === "backup" ? _Backup.onDownload.onClick : _Import.onDownload.onClick,
            Tag: type === "backup" ? _Backup.onTag.onClick : _Import.onTag.onClick,
          }}
        />
      </>
    );
  };

  const renderBackupOperations = () => {
    const renderBackup = () => {
      return (
        <HStack justifyContent="flex-start" gap={1}>
          <Button onClick={_Backup.onClick} style={{ width: "200px", backgroundColor: ColorX.GetColorCSS("Decorate1"), color: ColorX.GetColorCSS("ButtonText1") }} className="backup-btn">
            <HStack gap={5}>
              <SaveOutlined />
              <Typography>Backup</Typography>
            </HStack>
          </Button>
          <Typography>Last Backup:</Typography>
          <Typography style={{ fontWeight: "bold" }}>{UserBnRState.LastBackup || "No Backup Available."}</Typography>
        </HStack>
      );
    };

    const renderBackupResult = (res: any[], schema: any[], title: string, expand?: boolean) => {
      const renderComonent = (
        <VStack height="100%">
          <Typography>{title}</Typography>
          <VStack paddingY={2}>
            <Tablizo width={400} height="100%" density="compact" auth={store.user.authority} level={store.user.level} rowIdAccessor="name" schema={schema} showSelector={false} data={res ?? []} />
          </VStack>
        </VStack>
      );
      return <VStack height="100%">{expand ? <ExpandableBox component={renderComonent} /> : renderComonent}</VStack>;
    };

    return (
      <HStack width={"100%"} height={"100%"}>
        <VStack justifyContent="flex-start" gap={1} padding={2} paddingX={10}>
          {renderBackup()}
          {renderVersions("backup")}
          <Spacer />
        </VStack>
        {UserBnRState.BackupRes && renderBackupResult(UserBnRState.BackupRes, schema.backupRes, "Last Service Backup Result")}
        {UserBnRState.DBBackupRes && renderBackupResult(UserBnRState.DBBackupRes, schema.dbBackupRes, "Last Database Backup Result", true)}
      </HStack>
    );
  };

  const renderImportOperation = () => {
    const renderDropZone = () => {
      return (
        <VStack>
          <Typography>Backup File</Typography>
          <Dropzone onDrop={(acceptedFiles) => setAcceptedFiles(acceptedFiles)} mode="userBnR" />
        </VStack>
      );
    };

    const renderImport = () => {
      return (
        <VStack justifyContent="flex-start" gap={1} padding={2}>
          <HStack justifyContent="center" gap={1}>
            <Button onClick={_Import.onClick} style={{ width: "200px", backgroundColor: ColorX.GetColorCSS("Decorate1"), color: ColorX.GetColorCSS("ButtonText1") }}>
              <HStack gap={5}>
                <Warning style={{ color: ColorX.GetColorCSS("yellow") }} />
                <Typography>Deploy</Typography>
              </HStack>
            </Button>
            <Typography>Last Import:</Typography>
            <Typography style={{ fontWeight: "bold" }}>{UserBnRState.LastImport || "No Import Available."}</Typography>
          </HStack>
          {renderVersions("import")}
          <Spacer />
        </VStack>
      );
    };

    return (
      <HStack height="100%" width="auto">
        {renderDropZone()}
        {renderImport()}
      </HStack>
    );
  };

  const renderBnRPanel = () => {
    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
      setBnrMode(newValue);
    };

    return (
      <>
        <Tabs value={bnrMode} onChange={handleChange} centered>
          <Tab label="Backup" />
          <Tab label="Import and Restore" />
        </Tabs>
        {bnrMode === BNR_MODE.BACKUP
          ? Authority.RenderComponentAuthCheck(renderBackupOperations(), { reqAuth: "System.UserBnR", reqFunc: reqFunc.Backup })
          : Authority.RenderComponentAuthCheck(renderImportOperation(), { reqAuth: "System.UserBnR", reqFunc: reqFunc.Import })}
      </>
    );
  };

  const renderTagForm = (mode: "import" | "backup") => {
    return (
      <TagForm
        title={mode === "backup" ? "Backup Tag" : "Import Tag"}
        open={mode === "backup" ? showBackupTagForm : showImportTagForm}
        onClose={mode === "backup" ? _Backup.onTag.onClose : _Import.onTag.onClose}
        onSubmit={mode === "backup" ? _Backup.onTag.onSubmit : _Import.onTag.onSubmit}
        row={TagFormRow}
      />
    );
  };

  return (
    <VStack justifyContent="flex-start" height="100%" width="100%" gap={1} paddingX={2}>
      <Loading open={loading} />
      {Authority.RenderComponentAuthCheck(renderBnRPanel(), { reqAuth: "System.UserBnR" })}
      {renderTagForm("backup")}
      {renderTagForm("import")}
    </VStack>
  );
};

export default SysUserBnR;
