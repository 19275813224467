import { useMemo, useState } from "react";

import _ from "lodash";
import { Box, Paper, Tab, Tabs, Typography } from "@mui/material";

import { UserControlTab, tabs } from "./tabs";

import { Authority } from "static";
import { VStack, HStack, Spacer } from "Components/LabIZO/Stackizo";
import { ColorX } from "static";

const UserControl: React.FC<{}> = () => {
  const [selectedTab, setSelectedTab] = useState<number>(0);

  const authorisedTabs = useMemo(() => tabs.filter(({ reqAuth, reqLevel, reqFunc }: UserControlTab) => reqAuth === "None" || Authority.IsAccessibleQ(reqAuth, reqLevel, reqFunc)), []);

  const onChangeTab = (e: React.ChangeEvent<{}>, tab: number) => {
    setSelectedTab(tab);
  };

  const renderTabPanels = (tabs: UserControlTab[]) => {
    const preLoad = false;
    return _.map(tabs, (tab, i) => {
      if (!preLoad && selectedTab !== i) return <Box key={i} />;
      const { render } = tab;
      return (
        <Box key={i} hidden={selectedTab !== i} style={{ width: "100%", height: "100%" }}>
          {render}
        </Box>
      );
    });
  };

  const renderTabButtons = (tabs: UserControlTab[]) => {
    let buttonLabel: JSX.Element;
    return tabs.map((tab: UserControlTab, i) => {
      const { label, icon, noTransform, spacing, alignment, disabled, minHeight, minWidth, iconPos } = tab;
      if (noTransform) {
        buttonLabel = <Typography style={{ textTransform: "none" }}>{label}</Typography>;
      }
      switch (iconPos) {
        case "bottom":
          buttonLabel = (
            <VStack gap={spacing || 5}>
              {buttonLabel}
              {icon}
            </VStack>
          );
          break;
        case "left":
          buttonLabel = (
            <HStack gap={spacing || 5}>
              {alignment === "right" && <Spacer />}
              {icon}
              {label}
              {alignment === "left" && <Spacer />}
            </HStack>
          );
          break;
        case "right":
          buttonLabel = (
            <HStack gap={spacing || 5}>
              {alignment === "right" && <Spacer />}
              {label}
              {icon}
              {alignment === "left" && <Spacer />}
            </HStack>
          );
          break;
        default:
          break;
      }
      return (
        <Tab
          key={i} //
          label={!iconPos || iconPos === "top" ? label : buttonLabel}
          icon={iconPos === "top" ? icon : undefined}
          disabled={disabled}
          style={{ color: ColorX.GetColorCSS("Primary"), minHeight: minHeight || "100%", minWidth: minWidth || 200 }}
        />
      );
    });
  };

  return (
    <VStack width="100%" height="100%">
      <Paper style={{ width: "100%", position: "static" }}>
        <Tabs
          value={selectedTab}
          onChange={onChangeTab}
          style={{ backgroundColor: ColorX.GetColorCSS("TabIndexBG"), color: ColorX.GetColorCSS("Primary"), minHeight: 20 }}
          variant="scrollable"
          scrollButtons="auto"
        >
          {renderTabButtons(authorisedTabs)}
        </Tabs>
      </Paper>
      <Paper style={{ width: "100%", height: "100%", background: "transparent", padding: "5px" }}>{renderTabPanels(authorisedTabs)}</Paper>
    </VStack>
  );
};

export default UserControl;
