import Formizo from "Components/LabIZO/Formizo";
import axios from "axios";
import { DOMAIN } from "config/config";
import { useEffect, useState } from "react";
import { ErrorX, store } from "static";
import { DBAnswerSpec, IDisclaimerConfig } from "interfaces";
import { schema } from "./schema";
import { DATA_LINK, backendConnector } from "connectors";

interface SystemTexts {
  welcome: DBAnswerSpec;
  noAnswer: DBAnswerSpec;
  disclaimer: IDisclaimerConfig;
}

const welcomeAnsDocId = "master:WELCOME";
const noAnswerAnsDocId = "master:NOANSWER";

const SysTexts: React.FC<{}> = () => {
  const [existingTexts, setExistingTexts] = useState<SystemTexts | null>(null);

  const getDefaultTexts = async (): Promise<void> => {
    try {
      store.isLoading(true);
      const ansData = await backendConnector.post<{ docs: DBAnswerSpec[] }>(DATA_LINK.AnswerList, {}, {}, { skipAudit: true });
      const disclaimerData = await backendConnector.post<IDisclaimerConfig>(DATA_LINK.SYSDisclaimerInfo, {}, {}, { skipAudit: true });
      store.isLoading(false);

      if (!ansData.Success) throw new Error("Failed to fetch answer documents from database");
      if (!disclaimerData.Success) throw new Error("Failed to fetch disclaimer from database");

      const welcomeDoc = ansData.payload.docs.find((doc) => doc._id === welcomeAnsDocId);
      const noanswerDoc = ansData.payload.docs.find((doc) => doc._id === noAnswerAnsDocId);
      if (!welcomeDoc || !noanswerDoc) throw new Error(`Database does not contain answer documents [${welcomeAnsDocId} / ${noAnswerAnsDocId}]`);
      setExistingTexts({
        welcome: welcomeDoc,
        noAnswer: noanswerDoc,
        disclaimer: disclaimerData.payload,
      });
    } catch (e: any) {
      ErrorX.Handle(e);
    }
  };

  const handleSubmit = async (textDocs: SystemTexts) => {
    const { welcome, noAnswer, disclaimer } = textDocs;
    welcome.text = Object.entries(welcome.text).reduce<Record<string, string[]>>((acc: any, [key, value]) => {
      acc[key] = typeof value === "string" ? [value] : value;
      return acc;
    }, {});
    noAnswer.text = Object.entries(noAnswer.text).reduce<Record<string, string[]>>((acc: any, [key, value]) => {
      acc[key] = typeof value === "string" ? [value] : value;
      return acc;
    }, {});

    const welcomePayloadOut = { data: welcome, JWT: store.user.JWT };
    const noanswerPayloadOut = { data: noAnswer, JWT: store.user.JWT };
    const disclaimerPayloadOut = { data: disclaimer, JWT: store.user.JWT };

    try {
      store.isLoading(true);
      const res0 = await backendConnector.post<{ Success: boolean }>(DATA_LINK.AnswerEdit, welcome);
      const res1 = await backendConnector.post<{ Success: boolean }>(DATA_LINK.AnswerEdit, noAnswer);
      const res2 = await backendConnector.post<{ Success: boolean }>(DATA_LINK.SYSDisclaimerEdit, disclaimer);
      store.isLoading(false);

      if (res0.Success && res1.Success && res2.Success) {
        store.Alert("System texts updated successfully", "success");
        await getDefaultTexts();
      } else {
        store.Alert("System texts update fails.", "error");
      }
    } catch (e: any) {
      store.Alert(ErrorX.Handle(e), "error");
    }
  };

  useEffect(() => {
    getDefaultTexts();
  }, []);

  if (!existingTexts) {
    store.isLoading(true);
    return <></>;
  }

  store.isLoading(false);

  return (
    <Formizo
      height="100%" //
      schema={schema}
      auth={store.user.authority}
      buttons={["Revert", "Submit"]}
      onSubmit={handleSubmit}
      defaultValue={existingTexts}
    />
  );
};

export { schema };
export default SysTexts;
