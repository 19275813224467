import React, { Component } from 'react';
import PropsType from 'prop-types';
//import ZTablizar from '../../../_Utilities/ZTablizar/ZTablizar';

import "./ZCMsg.css";

class ZCMTable extends Component {

  static propTypes = {
    text: PropsType.string,
  }

  static defaultProps = {
    text: ""
  }

  constructor(){
    super();
    this.state = {
      hide: true
    };
  }

  componentDidMount(){
    this.setState((state, props) => ({ 
      ...props
    }));
  }

  static getDerivedStateFromProps(nextProps, prevStat){
    if(prevStat !== nextProps){
      return {
        ...nextProps
      }
    }
  }

  componentWillUnmount() {
    this.setState = (state, callback) => {
        return;
    };
  }

  renderTable = (table) => {
    //let {browser, cssp} = this.state;
    return ( 
      <div/>
    );
  }

  render(){
    let { cssp, table } = this.state;
    return (
      <div className={cssp + " msg-text"}>
        {this.renderTable(table)}
      </div>
    )
  }
}

export default ZCMTable;