import { FormizoSchema } from "Components/LabIZO/Formizo";
import { FormizoCustom, IFGColumn } from "Components/LabIZO/Formizo/FormizoSchema.interface";
import { store } from "static";
import _ from "lodash";
import { Box, Button, Chip, Typography } from "@mui/material";
import { AvailLANG } from "interfaces";
import { ExampleGenerationInEdit } from "../ExampleGenerationInEdit";
import { Roles } from "interfaces/role.interface";
import { CopyToAllButton } from "../CopyToAllButton";

/**
 * button caption qill be generated if not given uppon add, but will be later required during edit
 */
const defaultLang = store.sysInfo.Language.default;
const ButtonCaptiionSchema = {
  add: (lang: { display: string; value: string }) => ({
    label: "Button Caption",
    name: `intent.${lang.value}.description`,
    format: "text",
  }),
  info: (lang: { display: string; value: string }) => ({
    label: "Button Caption",
    name: `intent.${lang.value}.description`,
    format: "text",
    validate: ["required"],
  }),
};

const FaqFormSchema = (formValue: any, addOns: any, fLang: { display: string; value: string }, buttonCapSchema: any) => {
  return [
    {
      label: "ID",
      name: "name",
      validate: ["required"],
      format: "text",
      readOnly: true,
    },
    {
      label: "Created At",
      name: "CREATED_AT",
      format: "text",
      readOnly: true,
    },
    {
      label: "Last Modified",
      name: "LASTMODIFIED",
      format: "text",
      readOnly: true,
    },
    {
      label: "Alias",
      name: "alias",
      validate: ["required"],
      format: "text",
    },
    {
      label: "Description",
      name: "description",
      format: "text",
    },
    {
      label: "Used By",
      name: "inUse",
      format: "display",
      Custom: (_row: any, field: any, _addOns: any) => {
        return (
          <Box className="usedBy" sx={{ alignItems: "center", justifyContent: "start", width: "100%" }}>
            {field?.length ? (
              field.map((qrbFaqId: string) => {
                return <Chip label={qrbFaqId.split(":")[1]} />;
              })
            ) : (
              <Typography variant="body1" color="#a6aaaa" fontFamily="Roboto, Helvetica, Arial, sans-serif">
                None
              </Typography>
            )}
          </Box>
        );
      },
    },
    {
      columns: [
        QuestionColumn(formValue, addOns, fLang, buttonCapSchema), //
        AnswerColumn(formValue, addOns, fLang, buttonCapSchema),
        QRColumn(formValue, addOns, fLang, buttonCapSchema),
      ],
    },
  ];
};

const QuestionColumn = (formValue: any, addOns: any, fLang: { display: string; value: string }, buttonCapSchema: any) => ({
  width: "48%",
  page: [
    {
      header: "Questions",
    },
    {
      tabs: store.sysInfo.Language.available.map((lang) => ({
        label: `${lang.display}`,
        error: (formValue: any, addOns: any, { formError }: any) => {
          let examplesErrors = _.get(formError, `intent.${lang.value}.examples`) || [];
          const captionError = _.get(formError, `intent.${lang.value}.description`) || [];
          const error = [...examplesErrors, ...captionError].filter((_e: any) => !_.isEmpty(_e));
          return error.length > 0;
        },
        page: () => {
          const defaultLang = store.sysInfo.Language.default;
          const minItems = defaultLang === lang.value ? 1 : 0;

          return [
            buttonCapSchema(lang),
            {
              label: `Examples (${lang.display})`,
              name: `intent.${lang.value}.examples`,
              canAdd: true,
              canDelete: true,
              minItems: minItems,
              array: [
                {
                  label: "",
                  name: "text",
                  format: "text",
                  validate: ["required"],
                },
              ],
            },
            {
              name: `intent.${lang.value}.examples`,
              format: "display",
              width: "100%",
              Custom: (_row: any, field: any, _addOns: any, _onValueChange: any, iname: any, readOnly: any) => {
                return <ExampleGenerationInEdit _row={_row} field={field} _onValueChange={_onValueChange} lang={lang.value as AvailLANG} display={lang.display} readonly={readOnly} />;
              },
            },
          ];
        },
      })),
    },
  ],
});

const AnswerColumn = (formValue: any, addOns: any, fLang: { display: string; value: string }, buttonCapSchema: any): IFGColumn => ({
  width: "48%",
  page: [
    {
      header: "Answer",
    },
    {
      label: "Answer ID",
      name: "answer._id",
      format: "text",
      readOnly: true,
    },
    {
      tabs: Roles.map((role) => ({
        label: `${role.display[store.sysInfo.Language.default as AvailLANG]}`,
        page: [
          {
            label: ` ${fLang.display}(${fLang.value})`,
            name: `answer.${role.value}.text.${fLang.value}.0`,
            format: "textarea",
            rows: 5,
            variant: "outlined",
          },
          {
            label: "Other Languages",
            foldStyle: "none",
            collapse: store.sysInfo.Language.available.map((o) => {
              if (o.value === fLang.value) return {};
              return {
                label: `${o.display}`,
                name: `answer.${role.value}.text.${o.value}.0`,
                format: "textarea",
                rows: 5,
                variant: "outlined",
              } as FormizoSchema;
            }),
          },
          {
            label: "Rating System ",
            name: `answer.${role.value}.rating`,
            format: "bool",
          },
          {
            label: "Image Source",
            name: `answer.${role.value}.image`,
            format: "multiSelect",
            selectStyle: "autocomplete",
            optionType: "static",
            options: [
              // Ref : Image_path of hbb-syva-chatbot
              { value: "contactUs", label: "contactUs" },
              { value: "conversation", label: "conversation" },
              { value: "dataAccessRequest", label: "dataAccessRequest" },
              { value: "dataCorrectionRequest", label: "dataCorrectionRequest" },
              { value: "doctorSearch", label: "doctorSearch" },
              { value: "downloadForMobile", label: "downloadForMobile" },
              { value: "fallBack", label: "fallBack" },
              { value: "forbidden", label: "forbiden" },
              { value: "identityHcp", label: "identityHcp" },
              { value: "identityHcprof2", label: "identityHcprof2" },
              { value: "identityHcprof", label: "identityHcprof" },
              { value: "identityHcr", label: "identityHcr" },
              { value: "mobileEhealthForGreeting", label: "mobileEhealthForGreeting" },
              { value: "successfulRegist", label: "successfulRegist" },
              { value: "UnderMaintenance", label: "UnderMaintenance" },
            ],
          },
          {
            label: "Learn More",
            name: "",
            collapse: [
              {
                label: "Title",
                width: 40,
                name: " ",
                tabs: [
                  {
                    label: "EN",
                    page: [
                      {
                        label: "Title",
                        name: `answer.${role.value}.learnMore.title.EN`,
                        format: "text",
                      },
                    ],
                  },
                  {
                    label: "TC",
                    page: [
                      {
                        label: "Title",
                        name: `answer.${role.value}.learnMore.title.TC`,
                        format: "text",
                      },
                    ],
                  },
                  {
                    label: "SC",
                    page: [
                      {
                        label: "Title",
                        name: `answer.${role.value}.learnMore.title.SC`,
                        format: "text",
                      },
                    ],
                  },
                ],
              },
              {
                label: "Display",
                width: 40,
                name: " ",
                tabs: [
                  {
                    label: "EN",
                    page: [
                      {
                        label: "Display",
                        name: `answer.${role.value}.learnMore.text.EN`,
                        format: "text",
                      },
                    ],
                  },
                  {
                    label: "TC",
                    page: [
                      {
                        label: "Display",
                        name: `answer.${role.value}.learnMore.text.TC`,
                        format: "text",
                      },
                    ],
                  },
                  {
                    label: "SC",
                    page: [
                      {
                        label: "Display",
                        name: `answer.${role.value}.learnMore.text.SC`,
                        format: "text",
                      },
                    ],
                  },
                ],
              },
              {
                label: "URL",
                name: "",
                width: 40,
                tabs: [
                  {
                    label: "EN",
                    page: [
                      {
                        label: "URL",
                        name: `answer.${role.value}.learnMore.url.EN`,
                        format: "text",
                      },
                    ],
                  },
                  {
                    label: "TC",
                    page: [
                      {
                        label: "URL",
                        name: `answer.${role.value}.learnMore.url.TC`,
                        format: "text",
                      },
                    ],
                  },
                  {
                    label: "SC",
                    page: [
                      {
                        label: "URL",
                        name: `answer.${role.value}.learnMore.url.SC`,
                        format: "text",
                      },
                    ],
                  },
                ],
              },
            ],
          },
          {
            label: "",
            name: "",
            format: "display",
            Custom: (data: any, field: any, state: any, _onValueChange: (feildName: string, value: any) => {}) => {
              return <CopyToAllButton data={data} currentRole={role.value} _onValueChange={_onValueChange} />;
            },
          },
        ],
      })),
    },
  ],
});

const QRColumn = (formValue: any, addOns: any, fLang: { display: string; value: string }, buttonCapSchema: any): IFGColumn => ({
  control: "type",
  width: "48%",
  page: [
    {
      header: "Quick Reply",
    },
    {
      tabs: Roles.map((identity) => ({
        label: `${identity.display[store.sysInfo.Language.default as AvailLANG]}`,
        page: [
          {
            label: "Quick Reply Buttons",
            name: `answer.${identity.value}.quickReplies`,
            canAdd: true,
            canDelete: true,
            headerStyle: "outlined",
            addStyle: "header",
            array: [
              {
                label: "Display",
                width: 50,
                name: " ",
                tabs: [
                  {
                    label: "EN",
                    page: [
                      {
                        label: "Display",
                        name: "title.EN",
                        format: "text",
                        validate: defaultLang == "EN" ? ["required"] : [],
                      },
                    ],
                  },
                  {
                    label: "TC",
                    page: [
                      {
                        label: "Display",
                        name: "title.TC",
                        format: "text",
                        validate: defaultLang == "TC" ? ["required"] : [],
                      },
                    ],
                  },
                  {
                    label: "SC",
                    page: [
                      {
                        label: "Display",
                        name: "title.SC",
                        format: "text",
                        validate: defaultLang == "SC" ? ["required"] : [],
                      },
                    ],
                  },
                ],
              },
              {
                label: "Button Payload",
                name: "payload",
                format: "select",
                selectStyle: "dropdown",
                selectRef: "intents",
                selectCap: "caption",
                selectVal: "_id",
                placeholder: "Intent",
                validate: ["required"],
                variant: "grid",
                width: 250,
              },
            ],
          },
        ],
      })),
    },
  ],
});

export const Info: FormizoCustom = (formValue, addOns, fLang: { display: string; value: string }) => {
  return FaqFormSchema(formValue, addOns, fLang, ButtonCaptiionSchema.info);
};
export const Add: FormizoCustom = (formValue, addOns, fLang: { display: string; value: string }) => {
  return FaqFormSchema(formValue, addOns, fLang, ButtonCaptiionSchema.add);
};

export const Edit = Info;
