import { FormizoSchema } from "Components/LabIZO/Formizo";
import { Typography } from "@mui/material";
const schema: FormizoSchema[] = [
  {
    label: "Input",
    name: "input",
    format: "display",
    readOnly: true,
    Custom: (data, feild, addOns) => {
      return <Typography variant="subtitle1">{addOns?.input}</Typography>;
    },
  },
  {
    label: "Suggested Intent",
    name: "suggested",
    format: "select",
    selectStyle: "dropdown",
    selectRef: "intents",
    selectCap: "caption",
    selectVal: "_id",
    placeholder: "Please select intent...",
    validate: ["required"],
  },
  {
    label: "Remarks",
    name: "remarks",
    format: "textarea",
  },
];

export default schema;
