import React, { useEffect, useState } from "react";
import { ColorX } from "static";
import { HtmlParserWrapper } from "Components/HtmlParserWrapper";
import { v1 } from "uuid";
import { Box, Typography, Popper, Chip } from "@mui/material";
import _ from "lodash";

interface IProps {
  cssp: string;
  chipKey: string;
  chipTitle?: string;
  modifier?: {
    display: string;
    filename: string;
  }[];
  chips: {
    value: string;
    alias: string;
    id: string;
    link?: string;
    titleName?: string;
    target?: string;
  }[];
}

const ZCMChips = ({ cssp, chipKey, chips, chipTitle, modifier }: IProps) => {
  const [checkEvents, setCheckEvents] = useState<boolean[]>([]);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [expanded, setExpanded] = useState<boolean>(false);

  useEffect(() => {
    const length = chips.length;
    const newArray = Array(length).fill(false);
    setCheckEvents(newArray);
  }, [chips]);

  const handleMouseOver = (index: number, event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
    const updatedArray = [...checkEvents];
    updatedArray[index] = true;
    setCheckEvents(updatedArray);
  };

  const handleMouseOut = (index: number) => {
    const newArray = Array(chips.length).fill(false);
    setCheckEvents(newArray);
  };

  const handleClick = (index: number) => {
    const link = chips[index].link;
    const target = chips[index].target;

    if (link) {
      if (target === "self") {
        window.location.href = link;
      } else {
        window.open(link, "_blank");
      }
    }
  };
  const renderHoverExpanded = () => {
    let rendered: any[] = [];
    let sortedRendered: any[] = [];

    chips.map((item, index) => {
      const foundModifier = modifier?.find((mod: { filename: string }) => mod.filename === item.alias);
      const display = foundModifier ? foundModifier.display : Infinity;
      const labelName = foundModifier ? (
        <span>
          {foundModifier.display}. {item.titleName}
        </span>
      ) : (
        item.titleName
      );
      rendered.push({
        index,
        labelName,
        display,
      });
    });
    rendered.sort((a, b) => a.display - b.display);
    rendered.forEach(({ index, labelName }) => {
      sortedRendered.push(
        <Chip
          key={index}
          sx={{ p: "1px", m: "5px", maxWidth: "70%", backgroundColor: "#d1dbfa", borderRadius: "3px", color: "#0000ff" }}
          label={labelName}
          onMouseOver={(event) => handleMouseOver(index, event)}
          //onMouseOut={() => handleMouseOut(index)}
          onClick={() => handleClick(index)}
        />
        // checkEvents[index] && (
        //   <Popper onMouseOut={() => handleMouseOut(index)} open={checkEvents[index] && anchorEl != null} placement="top" anchorEl={anchorEl} sx={{ zIndex: 15 }}>
        //     <Box sx={{ background: ColorX.GetColorCSS("Background"), maxWidth: 300, maxHeight: 200, overflowY: "auto", padding: "10px" }}>{<HtmlParserWrapper html={item.value} />}</Box>
        //   </Popper>
        // )
      );
    });

    chips && sortedRendered.push(<Box sx={{ cursor: "pointer" }} onClick={() => setExpanded(false)}>{`Show less`}</Box>);

    return sortedRendered;
  };

  const renderHoverCollapsed = () => {
    let rendered: any[] = [];
    let sortedRendered: any[] = [];
    const th = 5;

    chips?.map((item, index) => {
      const foundModifier = modifier?.find((mod: { filename: string }) => mod.filename === item.alias);
      if (foundModifier) {
        const display = foundModifier.display;
        const labelName = (
          <span>
            {foundModifier.display}. {item.titleName}
          </span>
        );
        rendered.push({
          index,
          labelName,
          display,
        });
      }
    });
    rendered.sort((a, b) => a.display - b.display);
    rendered.forEach(({ index, labelName }) => {
      if (sortedRendered.length < th) {
        sortedRendered.push(
          <Chip
            key={index}
            sx={{ p: "1px", m: "5px", maxWidth: "70%", backgroundColor: "#d1dbfa", borderRadius: "2px", color: "#0000ff" }}
            label={labelName}
            onMouseOver={(event) => handleMouseOver(index, event)}
            //onMouseOut={() => handleMouseOut(index)}
            onClick={() => handleClick(index)}
          />
          // checkEvents[index] && (
          //   <Popper onMouseOut={() => handleMouseOut(index)} open={checkEvents[index] && anchorEl != null} placement="top" anchorEl={anchorEl} sx={{ zIndex: 15 }}>
          //     <Box sx={{ background: ColorX.GetColorCSS("Background"), maxWidth: 300, maxHeight: 200, overflowY: "auto", padding: "10px" }}>{<HtmlParserWrapper html={item.value} />}</Box>
          //   </Popper>
          // )
        );
      }
    });
    chips &&
      chips.length != sortedRendered.length &&
      sortedRendered.push(
        <Box
          sx={{
            cursor: "pointer",
            zIndex: 15,
          }}
          key={v1()}
          onClick={(e) => {
            //display parent onclick
            setExpanded(true);
          }}
        >
          {`+${chips.length - sortedRendered.length} ${chips.length - th > 1 ? "more" : "other"}`}
        </Box>
      );

    return sortedRendered;
  };

  return (
    <Box className={cssp} key={chipKey} sx={{ p: 1, border: "1px solid rgba(0, 0, 0, 0.12)", borderRadius: "10px", mt: 3, background: "#F3F6F9" }}>
      {chipTitle && <Typography variant="body2">{chipTitle}</Typography>}
      {expanded ? renderHoverExpanded() : renderHoverCollapsed()}
    </Box>
  );
};

export default ZCMChips;
