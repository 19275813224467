import { Box } from "@mui/material";
import { ClassicMenuConfigMode, IMenuItemSchema } from "../Menu.interface";
import {
  ModelTraining,
  Groups,
  AccountTree,
  DashboardCustomize,
  Dashboard,
  DocumentScanner,
  Forum,
  NotInterested,
  Science,
  ManageAccounts,
  DashboardCustomizeRounded,
  Extension,
  Message,
  Dvr,
  ReceiptLong,
  Settings,
  Leaderboard,
  Diversity2,
  Grade,
} from "@mui/icons-material";
import WebIcon from "@mui/icons-material/Web";
import { LandingPageSetting } from "Pages/UserControl/SysRole/Component/RoleInfo";
import { IAuthority } from "interfaces";
import _ from "lodash";

const BaseConfigHead: IMenuItemSchema[] = [];
const BaseConfigTail: IMenuItemSchema[] = [];

const NlpMenuConfig: IMenuItemSchema[] = [
  ...BaseConfigHead,
  {
    caption: "DashBoard",
    id: "menu-dashboard",
    link: "/Dashboard",
    icon: <Dashboard />,
    reqAuth: "Dashboard",
  },
  {
    caption: "Starter Dashboard",
    id: "menu-starter-dashboard",
    link: "/StarterDashboard",
    icon: <DashboardCustomizeRounded />,
    reqAuth: "StarterDashboard",
  },
  {
    caption: "Talk-to-VirtualAssistant",
    id: "menu-talk-to-virtual-assistant",
    link: "/Talk2VA",
    icon: "syva_logo",
    reqAuth: "Talk2VA",
    subMenu: [
      {
        caption: "T2VATrainer",
        id: "menu-t2vatrainer",
        link: "/T2VATrainer",
        icon: <ModelTraining />,
        reqAuth: "FAQTrainer",
      },
    ],
  },
  {
    caption: "FAQs",
    id: "menu-faq",
    link: "/FAQ",
    icon: "fas fa-comments fa-lg",
    reqAuth: "FAQ",
    subMenu: [
      {
        caption: "Counter Examples",
        id: "menu-counter-examples",
        link: "/FAQ/CounterExample",
        icon: <NotInterested />,
        reqAuth: "Workspace.CounterExample",
      },
    ],
  },
  {
    caption: "Workspace",
    id: "menu-workspace",
    icon: "fas fa-crop-alt fa-lg",
    reqAuth: "Workspace",
    link: "/Workspace",
    disabled: true,
    subMenu: [
      {
        caption: "Intents",
        id: "menu-intents",
        link: "/Workspace/Intent",
        icon: "fas fa-crosshairs fa-lg",
        reqAuth: "Workspace.Intent",
      },
      {
        caption: "Entities",
        id: "menu-entities",
        link: "/Workspace/Entity",
        icon: <Extension />,
        reqAuth: "Workspace.Entity",
      },
      {
        caption: "Answers",
        id: "menu-answers",
        link: "/Answer",
        icon: <Message />,
        reqAuth: "Answer",
      },
    ],
  },
  {
    caption: "Conversation",
    id: "menu-conversation",
    link: "/Convlog",
    icon: <Forum />,
    reqAuth: "Convlog",
  },
  {
    caption: "Rating Report",
    id: "menu-rating-report",
    link: "/RatingReport",
    icon: <Grade />,
    reqAuth: "RatingReport",
  },
  {
    caption: "Request Log",
    id: "request-log",
    link: "/RequestLog",
    icon: <Dvr />,
    reqAuth: "RequestLog",
  },
  {
    caption: "Audit Log",
    id: "audit-log",
    link: "/AuditLog",
    icon: <ReceiptLong />,
    reqAuth: "AuditLog",
  },
  {
    caption: "Training",
    id: "menu-training",
    link: "/Training",
    icon: "fas fa-robot fa-lg",
    reqAuth: "Training",
  },

  {
    caption: "Flow Editor",
    id: "menu-fle",
    link: "/FLE",
    icon: <AccountTree />,
    reqAuth: "FLE",
  },
  {
    caption: "User Control",
    id: "menu-user",
    link: "/UserControl",
    icon: <ManageAccounts />,
    reqAuth: "UserControl",
  },
  {
    caption: "System",
    id: "menu-system",
    link: "/System",
    icon: <Settings />,
    reqAuth: "System",
  },

  ...BaseConfigTail,
];

const MlMenuConfig: IMenuItemSchema[] = [
  {
    caption: "GPT-QA Dashboard",
    id: "gpt-qa-dashboard",
    link: "/gpt-qa/gpt-qa-dashboard",
    icon: <DashboardCustomize />,
    reqAuth: "GPTQA.Dashboard",
  },
  {
    caption: "Technology Previews",
    id: "menu-technology-previews",
    link: "/TechnologyPreviews",
    icon: <Science />,
    reqAuth: "TechnologyPreviews",
    subMenu: [
      {
        caption: "AWS LiveChat",
        id: "menu-aws-livechat",
        link: "/AWSLiveChat",
        icon: (
          <Box height={"25px"}>
            <img style={{ height: "100%" }} src={`${process.env.PUBLIC_URL}/Images/AWS/aws_connect_icon.png`} alt="ic_connect"></img>
          </Box>
        ),
        reqAuth: "AWSLiveChat",
      },
      // {
      //   caption: "Avatar development",
      //   id: "menu-avatar-development",
      //   link: "/Avatar",
      //   icon: "far fa-grin-alt",
      //   reqAuth: "Avatar",
      // },
    ],
  },
  {
    caption: "Talk2VA",
    id: "menu-talk-to-virtual-assistant-beta",
    link: "/Talk2VA",
    icon: "syva_logo",
    reqAuth: "Talk2VA",
  },
  {
    caption: "Document Manager",
    id: "menu-document-manager",
    link: "/gpt-qa/document-manager",
    icon: <DocumentScanner />,
    reqAuth: "GPTQA.DocumentManager",
  },
  {
    caption: "Document Conversation",
    id: "menu-conversation-log",
    link: "/gpt-qa/conversation-log",
    icon: <Forum />,
    reqAuth: "GPTQA.ConversationLog",
  },
  {
    caption: "Knowledge Group Management",
    id: "menu-knowlege-group-management",
    link: "/gpt-qa/knowledge-grp-management",
    icon: <Groups />,
    reqAuth: "GPTQA.KnowledgeGroupManagement",
  },
  {
    caption: "Website Manager",
    id: "menu-website-manager",
    link: "/gpt-qa/website-manager",
    icon: <WebIcon />,
    reqAuth: "GPTQA.WebsiteManager",
  },
  {
    caption: "Topic Clustering",
    id: "menu-topic-clustering",
    link: "/analytics/topic-clustering",
    icon: <Diversity2 />,
    reqAuth: "Analytics.TopicClustering",
  },
  {
    caption: "Arena Log",
    id: "menu-arena-log",
    link: "/arena/log",
    icon: <Leaderboard />,
    reqAuth: "ArenaLog",
  },
];

export const getMenuConfigModeByLandingPage = (page: LandingPageSetting): ClassicMenuConfigMode => {
  if (!page) return ClassicMenuConfigMode.NLP;
  const pageLink = page[0] !== "/" ? `/${page}` : page;
  if (findPageInPageGroup(NlpMenuConfig, pageLink)) {
    return ClassicMenuConfigMode.NLP;
  } else if (findPageInPageGroup(MlMenuConfig, pageLink)) {
    return ClassicMenuConfigMode.ML;
  } else {
    throw new Error(`Page ${page} belongs to no page group`);
  }
};

const findPageInPageGroup = (pageGroup: IMenuItemSchema[], link: string): IMenuItemSchema | undefined => {
  return pageGroup.find((config) => {
    if (config.link === link) {
      return true;
    } else if (config.subMenu) {
      return config.subMenu.find((config) => config.link === link);
    }
  });
};

/**
 * Get menu config mode(s) from user's authority. For example, if user has authority to both Dashboard
 * and gpt-qa-dashboard, Set{MenuConfigMode.ML, MenuConfigMode.NLP} will be returned.
 */
export const getMenuConfigModesForUser = (authority: IAuthority, keyChain: string[], set: Set<ClassicMenuConfigMode>): Set<ClassicMenuConfigMode> => {
  for (const [comp, auth] of Object.entries(authority)) {
    if (!auth || auth.length === 0) continue;
    let newSet: Set<ClassicMenuConfigMode>;
    if (!_.isArray(auth)) {
      newSet = getMenuConfigModesForUser(auth as IAuthority, [...keyChain, comp], set);
      if (newSet.size > 1) return newSet;
    } else {
      const menuMode = getMenuConfigModeByAuth([...keyChain, comp].join("."));
      if (menuMode !== null) {
        newSet = set.add(menuMode);
        if (newSet.size > 1) return newSet;
      }
    }
  }
  return set;
};

// check whether an auth belongs to NLP / ML menu
const getMenuConfigModeByAuth = (auth: string): ClassicMenuConfigMode | null => {
  if (findAuthinPageGroup(NlpMenuConfig, auth)) {
    return ClassicMenuConfigMode.NLP;
  } else if (findAuthinPageGroup(MlMenuConfig, auth)) {
    return ClassicMenuConfigMode.ML;
  } else {
    // console.log(`Auth ${auth} belongs to no page group`);
    return null;
  }
};

const findAuthinPageGroup = (pageGroup: IMenuItemSchema[], auth: string): IMenuItemSchema | undefined => {
  return pageGroup.find((config) => {
    if (config.reqAuth === auth) {
      return true;
    } else if (config.subMenu) {
      return config.subMenu.find((config) => config.reqAuth === auth);
    }
  });
};

export const getClassicMenuSchema = (configMode: ClassicMenuConfigMode): IMenuItemSchema[] => {
  switch (configMode) {
    case ClassicMenuConfigMode.ML:
      return MlMenuConfig;
    default:
      return NlpMenuConfig;
  }
};
