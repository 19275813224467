import { Typography } from "@mui/material";
import React from "react";
import { ColorX } from "static";
import { DiffDelta } from "interfaces/db.interfaces/DataEventLog.interface";
import prettyStringify from "json-stringify-pretty-compact";
import _ from "lodash";

export interface DeltaProps {
  type: DiffDelta;
  val: string;
  breakWords: boolean;
}

export const Delta: React.FC<DeltaProps> = ({ type, val, breakWords }) => {
  const lines = val.split(/\n/);
  let sxProps = {};
  switch (type) {
    case DiffDelta.INSERT:
      sxProps = { display: "inline", backgroundColor: ColorX.GetColorCSS("Insert"), wordBreak: breakWords ? "break-word" : "normal" };
      break;
    case DiffDelta.DELETE:
      sxProps = { display: "inline", backgroundColor: ColorX.GetColorCSS("Delete"), textDecoration: "line-through" };
      break;
    default: // equal
      sxProps = { display: "inline", wordBreak: breakWords ? "break-word" : "normal" };
  }

  return (
    <>
      {lines.map((line, idx) => {
        let _line: string | JSX.Element = line;
        if (_line.match(/^\[.*\]$/)) _line = _line.slice(1, _line.length - 1); // remove outer []

        try {
          _line = JSON.parse(_line); // improve object readability
          if (_.isObject(_line)) {
            _line = <pre style={{ marginBottom: 0, whiteSpace: "pre-wrap" }}>{prettyStringify(_line)}</pre>;
          } else if (typeof _line === "boolean") {
            _line = JSON.stringify(_line);
          }
        } catch (e: any) {
          // fails if it is not JSON parsable
        }

        return (
          <React.Fragment key={idx}>
            <Typography component={"span"} variant="body2" sx={sxProps}>
              {_line}
            </Typography>
            {idx !== lines.length - 1 && <br />}
          </React.Fragment>
        );
      })}
    </>
  );
};
