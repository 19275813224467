import React from "react";

import { IFSelect } from "../../FormizoSchema.interface";
import { FFDropdown } from "./FFDropdown";
import { FFCheckbox } from "./FFCheckbox";
import { FFRadio } from "./FFRadio";
import FFAutoComplete from "./FFAutoComplete";

interface IFFSelectProps {
  //data
  ischema: IFSelect;
  iname: string;
  addOns: any;

  //root func
  _onValueChange: (name: string, value: any, criteria: any) => void;
  _onBlurInlineSubmit: any;
  _onInlineSubmit: any;
  _onFieldFocus: () => void;
  _onFieldBlur: () => void;

  //disability
  errorsShowOnHelperText: boolean;
  readOnly: boolean;

  //runtime
  formValue?: any;
  formError: any;

  //style
  ifieldStyle: string;
}

export const FFSelect: React.FC<IFFSelectProps> = (props) => {
  const { ischema } = props;
  if (!ischema) {
    return null;
  }
  switch (ischema.selectStyle) {
    case "dropdown":
      return <FFDropdown {...props} />;
    case "checkbox":
      return <FFCheckbox {...props} />;
    case "radio":
      return <FFRadio {...props} />;
    case "autocomplete":
      return <FFAutoComplete {...props} />;
    default:
      return <FFDropdown {...props} />;
  }
};
