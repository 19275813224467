import { HStack, VStack } from "Components/Layouts";
import React from "react";
import SettingPanel from "../SettingPanel/SettingPanel";
import { DataItem, TopicItem } from "../../Interface/TopicCluster.interface";
import PerceptualMap from "./PerceptualMap";

interface IPerceptualMapView {
  data: TopicItem[];
  handlePointClick: (dataItem: TopicItem) => void;
}

const PerceptualMapView: React.FC<IPerceptualMapView> = ({ data, handlePointClick }) => {
  const importantTopic = 5;
  return (
    <HStack width={"100%"} height={"calc(100% - 40px)"}>
      <VStack width={"80%"} height={"100%"}>
        <PerceptualMap data={data} handlePointClick={handlePointClick} importantTopic={importantTopic} />
      </VStack>
      <VStack width={"20%"} height={"100%"}>
        <SettingPanel data={data} handlePointClick={handlePointClick} importantTopic={importantTopic} />
      </VStack>
    </HStack>
  );
};

export default PerceptualMapView;
