import axios from "axios";
import { DOMAIN } from "config/config";
import { ErrorX, store } from "static";
import { useEffect, useState } from "react";
import { IUIEntity } from "../interfaces/IUIEntity";
import { DATA_LINK, backendConnector } from "connectors";

export function useEntities() {
  const [entities, setEntities] = useState<IUIEntity[] | null>(null);
  useEffect(() => {
    async function fetchEntities() {
      const { Success, payload } = await backendConnector.post(
        DATA_LINK.EntityList,
        {
          selector: {
            $and: [{ domain: { $eq: "master" } }],
          },
        },
        {
          workspace: "master",
        }
      );
      if (!Success) ErrorX.Handle(payload.message || "");
      setEntities(payload.docs);
    }
    fetchEntities();
  }, []);
  return entities;
}
