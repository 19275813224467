import { TFMultiSelect } from "Components/LabIZO/Formizo/FormizoSchema.interface";
import _ from "lodash";

export function getOptions(
  schema: TFMultiSelect,
  addOns: any
): {
  disabled?: boolean;
  value: string;
  label: string;
}[] {
  if (schema.optionType === "addOns") {
    const options = _.get(addOns, schema.selectRef, []);
    return options.map((option: any) => {
      const val = _.get(option, schema.selectVal, "");
      const cap = _.get(option, schema.selectCap, "");
      const disabled = _.get(option, "disabled", false);
      return { value: val, label: cap, disabled: disabled };
    });
  }

  return schema.options;
}
