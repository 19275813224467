// const languages = {
//   'af': 'Afrikaans',
//   'sq': 'Albanian',
//   'ar': 'Arabic',
//   'az': 'Azerbaijani',
//   'eu': 'Basque',
//   'bn': 'Bengali',
//   'be': 'Belarusian',
//   'bg': 'Bulgarian',
//   'ca': 'Catalan',
//   'zh-CN': 'Chinese Simplified',
//   'zh-TW': 'Chinese Traditional',
//   'hr': 'Croatian',
//   'cs': 'Czech',
//   'da': 'Danish',
//   'nl': 'Dutch',
//   'en': 'English',
//   'eo': 'Esperanto',
//   'et': 'Estonian',
//   'tl': 'Filipino',
//   'fi': 'Finnish',
//   'fr': 'French',
//   'gl': 'Galician',
//   'ka': 'Georgian',
//   'de': 'German',
//   'el': 'Greek',
//   'gu': 'Gujarati',
//   'ht': 'Haitian Creole',
//   'iw': 'Hebrew',
//   'hi': 'Hindi',
//   'hu': 'Hungarian',
//   'is': 'Icelandic',
//   'id': 'Indonesian',
//   'ga': 'Irish',
//   'it': 'Italian',
//   'ja': 'Japanese',
//   'kn': 'Kannada',
//   'ko': 'Korean',
//   'la': 'Latin',
//   'lv': 'Latvian',
//   'lt': 'Lithuanian',
//   'mk': 'Macedonian',
//   'ms': 'Malay',
//   'mt': 'Maltese',
//   'no': 'Norwegian',
//   'fa': 'Persian',
//   'pl': 'Polish',
//   'pt': 'Portuguese',
//   'ro': 'Romanian',
//   'ru': 'Russian',
//   'sr': 'Serbian',
//   'sk': 'Slovak ',
//   'sl': 'Slovenian',
//   'es': 'Spanish',
//   'sw': 'Swahili',
//   'sv': 'Swedish',
//   'ta': 'Tamil',
//   'te': 'Telugu ',
//   'th': 'Thai',
//   'tr': 'Turkish',
//   'uk': 'Ukrainian',
//   'ur': 'Urdu',
//   'vi': 'Vietnamese',
//   'cy': 'Welsh',
//   'yi': 'Yiddish'
// };
export enum GoogleTranslateSupportedLang {
  Afrikaans = "af",
  Albanian = "sq",
  Arabic = "ar",
  Azerbaijani = "az",
  Basque = "eu",
  Bengali = "bn",
  Belarusian = "be",
  Bulgarian = "bg",
  Catalan = "ca",
  ChineseSimplified = "zh-CN",
  ChineseTraditional = "zh-TW",
  Croatian = "hr",
  Czech = "cs",
  Danish = "da",
  Dutch = "nl",
  English = "en",
  Esperanto = "eo",
  Estonian = "et",
  Filipino = "tl",
  Finnish = "fi",
  French = "fr",
  Galician = "gl",
  Georgian = "ka",
  German = "de",
  Greek = "el",
  Gujarati = "gu",
  HaitianCreole = "ht",
  Hebrew = "iw",
  Hindi = "hi",
  Hungarian = "hu",
  Icelandic = "is",
  Indonesian = "id",
  Irish = "ga",
  Italian = "it",
  Japanese = "ja",
  Kannada = "kn",
  Korean = "ko",
  Latin = "la",
  Latvian = "lv",
  Lithuanian = "lt",
  Macedonian = "mk",
  Malay = "ms",
  Maltese = "mt",
  Norwegian = "no",
  Persian = "fa",
  Polish = "pl",
  Portuguese = "pt",
  Romanian = "ro",
  Russian = "ru",
  Serbian = "sr",
  Slovak = "sk",
  Slovenian = "sl",
  Spanish = "es",
  Swahili = "sw",
  Swedish = "sv",
  Tamil = "ta",
  Telugu = "te",
  Thai = "th",
  Turkish = "tr",
  Ukrainian = "uk",
  Urdu = "ur",
  Vietnamese = "vi",
  Welsh = "cy",
  Yiddish = "yi",
}
