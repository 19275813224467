import React from "react";
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@mui/material";
import { Authority, store } from "static";
import { DATA_LINK, backendConnector } from "connectors";

interface IEmailChangerProps {
  username: string;
}

const UnlockUser = ({ username }: IEmailChangerProps) => {
  const [open, setOpen] = React.useState(false);

  if (!Authority.IsAccessibleQ(`UserControl.User`, 999, "Unlock")) return <></>;
  const onClose = () => {
    setOpen(false);
  };

  const onSubmit = async () => {
    try {
      const rtn = await backendConnector.post(DATA_LINK.UserUnlock, {
        username,
      });
      let { Success } = rtn;
      if (Success) {
        store.Alert("User unblocked successfully");
        setOpen(false);
      } else {
        store.Alert(rtn?.payload?.message, "error");
      }
    } catch (e) {
      store.Alert("Cannot connect to server", "error");
    }
  };

  return (
    <div className="UnblockUser">
      <Button
        variant="outlined"
        color="secondary"
        onClick={() => {
          setOpen(true);
        }}
      >
        Unblock User
      </Button>
      <PopupEmailChanger open={open} username={username} onClose={onClose} onSubmit={onSubmit} />
    </div>
  );
};

interface IUnblockUserProps {
  open: boolean;
  username: string;
  onClose: () => void;
  onSubmit: () => void;
}

const PopupEmailChanger = ({ open, username, onClose, onSubmit }: IUnblockUserProps) => {
  return (
    <Dialog open={open ? true : false} onClose={onClose} aria-labelledby="form-dialog-title">
      <DialogTitle id="form-dialog-title">Unblock User</DialogTitle>
      <DialogContent>
        <DialogContentText> {`Are you sure you want to unblock the user : ${username} ? This action cannot be undone.`}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Cancel
        </Button>
        <Button
          onClick={() => {
            onSubmit();
          }}
          color="primary"
          type="submit"
        >
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default UnlockUser;
