export enum CusAuth {
  Menu = "Menu",
  Info = "Info",
  Add = "Add",
  Edit = "Edit",
  Export = "Export",
  Delete = "Delete",
  InitTrain = "InitTrain",
  NlpInfo = "NlpInfo",
  GPTDetail = "GPTDetail",
  Tag = "Tag",
  Train = "Train",
  Verify = "Verify",
  Details = "Details",
  Restore = "Restore",
  Analyse = "Analyse",
  Import = "Import",
  Sync = "Sync",
  Enable = "Enable",
  Backup = "Backup",

  //Talk2VA Settings
  DocsManagement = "DocsManagement",
  GroupManagement = "GroupManagement",
  Chatbot = "Chatbot",
  Summarize = "Summarize",
  Composition = "Composition",
  Streaming = "Streaming",
  Feedback = "Feedback",
  Regenerate = "Regenerate",
  ModelSelect = "ModelSelect",
  SuggestedIntent = "SuggestedIntent", //Required CusAuth Details

  // system texts
  DefaultMessages = "DefaultMessages",
  Disclaimer = "Disclaimer",

  // NLP Config
  Watson = "Watson",
  Talk2GPT = "Talk2GPT",
  EmbeddingModel = "EmbeddingModel",
  CompletionModel = "CompletionModel",

  //FLE API integrator
  APIIntegrator = "APIIntegrator",

  LLMQuotaLimit = "LLMQuotaLimit",

  RedirectToGpt = "RedirectToGpt",

  IntentGeneration = "IntentGeneration",

  Whatsapp = "Whatsapp",
  Facebook = "Facebook",

  //Document Manager
  "3DView" = "3DView",
  Reupload = "Reupload",
  Translate = "Translate",
  ByPages = "ByPages",
}

export enum SYS_AUTH {
  All = "*",
  Access = "Access",
}
type DEFAULT_AUTH = SYS_AUTH.All[] | SYS_AUTH.Access[] | [];

export interface IAuthority {
  Initialize?: DEFAULT_AUTH;
  Dashboard?: DEFAULT_AUTH;
  Convlog?: DEFAULT_AUTH | Extract<CusAuth, "Info" | "Export" | "InitTrain" | "NlpInfo" | "GPTDetail">[];
  RequestLog?: DEFAULT_AUTH | Extract<CusAuth, "Info" | "Export">[];
  AuditLog?: DEFAULT_AUTH | Extract<CusAuth, "Info" | "Export">[];
  Talk2VA?: {
    FAQ?: DEFAULT_AUTH;
    Hybrid?: DEFAULT_AUTH | Extract<CusAuth, "DocsManagement" | "GroupManagement" | "Details" | "Chatbot" | "Summarize" | "Composition" | "Feedback" | "Regenerate" | "ModelSelect">[];
    GPTQA?: DEFAULT_AUTH | Extract<CusAuth, "DocsManagement" | "GroupManagement" | "Details" | "Chatbot" | "Summarize" | "Composition" | "Feedback" | "Regenerate" | "Streaming" | "ModelSelect">[];
    Playground?: DEFAULT_AUTH | Extract<CusAuth, "Details" | "Feedback" | "Streaming" | "ModelSelect">[];
    Arena?: DEFAULT_AUTH | Extract<CusAuth, "DocsManagement" | "GroupManagement" | "Details" | "Chatbot" | "Summarize" | "Composition" | "Streaming">[];
    SoulMachine?: DEFAULT_AUTH;
    Avatar?: DEFAULT_AUTH;
  };
  Training?: DEFAULT_AUTH | Extract<CusAuth, "Delete" | "Tag" | "Train" | "Verify" | "InitTrain">[];
  FAQ?: DEFAULT_AUTH | Extract<CusAuth, "Edit" | "Delete" | "Add" | "Analyse" | "Export" | "Import" | "Enable" | "RedirectToGpt" | "IntentGeneration">[];
  FLE?: DEFAULT_AUTH | Extract<CusAuth, "Edit" | "Delete" | "Add" | "Export" | "Import" | "Enable" | "APIIntegrator" | "IntentGeneration">[];
  AWSLiveChat?: DEFAULT_AUTH;
  Avatar?: DEFAULT_AUTH;
  Workspace?: {
    Intent?: DEFAULT_AUTH | Extract<CusAuth, "Edit" | "Delete" | "Add" | "Export" | "Import" | "Sync">[];
    Entity?: DEFAULT_AUTH | Extract<CusAuth, "Edit" | "Delete" | "Add" | "Sync" | "Export" | "Import">[];
    CounterExample?: DEFAULT_AUTH | Extract<CusAuth, "Edit" | "Delete" | "Add" | "Export" | "Import" | "Sync">[];
  };
  FAQTrainer?: DEFAULT_AUTH | Extract<CusAuth, "Delete">[];
  Simulator?: DEFAULT_AUTH;
  Answer?: DEFAULT_AUTH | Extract<CusAuth, "Edit" | "Delete" | "Add" | "Export">[];
  Config?: {
    DBNAME?: DEFAULT_AUTH;
    ContentDB?: DEFAULT_AUTH;
    Watsons?: DEFAULT_AUTH;
    Middleware?: DEFAULT_AUTH;
    MWSettings?: DEFAULT_AUTH;
    SpamCheck?: DEFAULT_AUTH;
  };
  Tools?: {};
  DevLab?: {};
  UserControl?: {
    User?: DEFAULT_AUTH | Extract<CusAuth, "Edit" | "Delete" | "Add">[];
    Role?: DEFAULT_AUTH | Extract<CusAuth, "Edit" | "Delete" | "Add">[];
  };
  System?: {
    General?: DEFAULT_AUTH | Extract<CusAuth, "Menu">[];
    BnR?: DEFAULT_AUTH | Extract<CusAuth, "Export" | "Import" | "Restore" | "Edit" | "Delete" | "Backup">[];
    UserBnR?: DEFAULT_AUTH | Extract<CusAuth, "Export" | "Import" | "Restore" | "Edit" | "Delete" | "Backup">[];
    User?: DEFAULT_AUTH | Extract<CusAuth, "Edit" | "Delete" | "Add">[];
    NLP?: DEFAULT_AUTH | Extract<CusAuth, "Watson" | "Talk2GPT" | "EmbeddingModel" | "CompletionModel">[];
    Chatbot?: DEFAULT_AUTH | Extract<CusAuth, "LLMQuotaLimit">[];
    Texts?: DEFAULT_AUTH | Extract<CusAuth, "DefaultMessages" | "Disclaimer">[];
    Spam?: DEFAULT_AUTH;
    Model?: DEFAULT_AUTH;
    ContentFilter?: DEFAULT_AUTH;
    Email?: DEFAULT_AUTH;
    Channel?: DEFAULT_AUTH | Extract<CusAuth, "Whatsapp" | "Facebook">[];
    IntentGeneration?: DEFAULT_AUTH;
    Arena?: DEFAULT_AUTH;
  };
  TechnologyPreviews?: DEFAULT_AUTH;
  GPTQA?: {
    Dashboard?: DEFAULT_AUTH;
    DocumentManager?: DEFAULT_AUTH | Extract<CusAuth, "Edit" | "Delete" | "Add" | "Sync" | "3DView" | "Enable" | "Summarize" | "Reupload" | "Translate" | "ByPages">[];
    ConversationLog?: DEFAULT_AUTH | Extract<CusAuth, "Info" | "Export">[];
    KnowledgeGroupManagement?: DEFAULT_AUTH | Extract<CusAuth, "Edit" | "Delete" | "Add" | "Delete" | "Enable">[];
    WebsiteManager?: DEFAULT_AUTH | Extract<CusAuth, "Edit" | "Delete" | "Add" | "Delete" | "Enable" | "Export" | "Import" | "Sync">[];
  };
  StarterDashboard?: DEFAULT_AUTH;
  ArenaLog?: DEFAULT_AUTH;
  Analytics?: {
    TopicClustering?: DEFAULT_AUTH;
  };
}
