import { Typography, FormControlLabel, TextField, Switch, Checkbox, FormGroup, FormControl, Select, MenuItem, SelectChangeEvent, InputLabel, Autocomplete } from "@mui/material";
import { VStack } from "Components";
import { Box } from "@mui/system";
import { Authority, store } from "static";
import { IDocumentUploader, ITranslationSetting } from "Components/_PageComponents/interfaces/document-uploader.interface";
import { Controller, useFormContext } from "react-hook-form";
import { ControlledTextFieldWithIcon } from "Components/HookForm/ControlledTextFieldWithIcon";
import { useEffect, useState } from "react";
import { AvailLANG, CusAuth } from "interfaces";
import { TranscribeLanguage } from "./constants/support-lang";
import _ from "lodash";
interface IProps {
  reupload?: boolean;
}

export const DocUploaderDetails = ({ reupload }: IProps) => {
  const { control, getValues, setValue, watch } = useFormContext<IDocumentUploader>();
  const [isTranslationEnabled, setIsTranslationEnabled] = useState<boolean>(false);
  const [audioLanguage, setAudioLanguage] = useState<keyof typeof TranscribeLanguage | undefined>(undefined);
  const [documentAlias, setDocumentAlias] = useState<string | null>(getValues("alias")[0]);
  const fileCount = getValues("files")?.length || 0;
  const fileExtensions = getValues("fileTypes") || [];

  // Watch the value of readByPage to handle mutual exclusivity
  const readByPage = watch("readByPage");

  useEffect(() => {
    if (readByPage) {
      setIsTranslationEnabled(isTranslationEnabled);
    }
  }, [readByPage]);

  const renderByPagesSetting = () => {
    if (!Authority.IsAccessibleQ("GPTQA.DocumentManager", undefined, CusAuth.ByPages)) return null;

    return (
      <VStack>
        <Controller
          name="readByPage"
          control={control}
          render={({ field }) => <FormControlLabel control={<Switch {...field} checked={field.value} />} label="Read By Pages" labelPlacement="start" disabled={isTranslationEnabled} />}
        />
        <Typography fontSize={"0.8rem"} color={"grey"} fontStyle={"italic"}>
          *** Only PDF and Word files can be read by Pages. ***
        </Typography>
        <Typography fontSize={"0.8rem"} color={"grey"} fontStyle={"italic"}>
          *** Word files will be read with page breaks. ***
        </Typography>
      </VStack>
    );
  };
  const renderAudioSetting = () => {
    const handleLanguageChange = (_event: any, value: string | null) => {
      if (value && value in TranscribeLanguage) {
        setAudioLanguage(value as keyof typeof TranscribeLanguage);
        setValue("audioLang", [TranscribeLanguage[value as keyof typeof TranscribeLanguage]]);
      }
    };

    return (
      <VStack width={"100%"}>
        <Controller name="summaryInstruction" control={control} render={({ field }) => <TextField {...field} label="Summary Instruction" variant="outlined" multiline fullWidth />} />
        <FormGroup sx={{ flexDirection: "row", width: "100%", alignItems: "center" }}>
          <FormControl variant="outlined" sx={{ width: "50%" }}>
            <Autocomplete
              options={_.keys(TranscribeLanguage)}
              value={audioLanguage}
              onChange={handleLanguageChange}
              renderInput={(params) => <TextField {...params} label="Select Transcribe Language" variant="outlined" />}
            />
          </FormControl>
        </FormGroup>
      </VStack>
    );
  };

  const renderTranslationSetting = () => {
    if (!Authority.IsAccessibleQ("GPTQA.DocumentManager", undefined, CusAuth.Translate)) return null;

    const languageChoices = store.sysInfo.Language.available;

    const handleLanguageChange = (isChecked: boolean, lang: string, newDocName: string) => {
      const currentTranslationSettings: Partial<ITranslationSetting> = getValues("languages") || {};
      const langKeys = lang as AvailLANG;

      if (isChecked) {
        currentTranslationSettings[langKeys] = newDocName;
      } else {
        delete currentTranslationSettings[langKeys];
      }
      setValue("languages", currentTranslationSettings);
    };
    return (
      <VStack>
        <FormControlLabel
          control={
            <Switch
              checked={isTranslationEnabled}
              onChange={(event) => {
                setIsTranslationEnabled(event.target.checked);
                if (event.target.checked === false) {
                  setValue("languages", {});
                }
              }}
              disabled={readByPage}
            />
          }
          label="Automated Translation"
          labelPlacement="start"
        />
        {isTranslationEnabled && (
          <FormGroup>
            {languageChoices.map((lang, index) => {
              const translatedDocName = `${documentAlias}_${lang.value.toLowerCase()}.${fileExtensions[0]}`;
              return (
                <Box key={index} style={{ display: "flex", gap: "10px", alignItems: "center" }}>
                  <Box style={{ flex: "0 0 150px" }}>
                    <FormControlLabel control={<Checkbox onChange={(event) => handleLanguageChange(event.target.checked, lang.value, translatedDocName)} />} label={lang.display} />
                  </Box>
                  <Typography>{translatedDocName}</Typography>
                </Box>
              );
            })}
          </FormGroup>
        )}
      </VStack>
    );
  };

  const renderReferenceSetting = () => {
    return (
      <VStack width="100%">
        <Typography variant="h6" color="primary.main" style={{ font: "normal normal normal 16px/21px Segoe UI", marginLeft: "50px", marginBottom: "10px" }}>
          Reference Settings
        </Typography>
        <ControlledTextFieldWithIcon fullWidth label="Reference Button Label" name="reference.label" control={control} variant="outlined" />
        <ControlledTextFieldWithIcon fullWidth label="Reference URL" name="reference.url" control={control} variant="outlined" />
      </VStack>
    );
  };

  return (
    <VStack width="100%">
      {fileCount === 1 && (
        <Controller
          name="alias"
          control={control}
          render={({ field }) => (
            <TextField
              fullWidth
              label="Document Name"
              onChange={(e) => {
                field.onChange([e.target.value]);
                setDocumentAlias(e.target.value);
              }}
              value={field.value ? field.value[0] : ""}
            />
          )}
        />
      )}
      {(fileExtensions.includes("mp3") || fileExtensions.includes("wav")) && renderAudioSetting()}
      {fileCount === 1 && !reupload && renderReferenceSetting()}
      {fileCount === 1 && !fileExtensions.includes("mp3") && !fileExtensions.includes("wav") && renderByPagesSetting()}
      {fileCount === 1 && renderTranslationSetting()}
    </VStack>
  );
};
