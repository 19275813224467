import _ from "lodash";
import Accessor from "./Accessor";
import store from "./AppStore";

class Authority {
  static AuthCheck = (authority: any, reqAuth: string) => {
    if (_.isEmpty(reqAuth) || (Accessor.Get(authority, reqAuth) && Accessor.Get(authority, reqAuth).length !== 0)) {
      //no authority required or authority tree found
      return true;
    }
    return false;
  };

  static LevelCheck = (level: any, reqLevel: number) => {
    return level <= reqLevel; //level less is more
  };

  static FuncCheck = (authority: any, reqAuth: any, reqFunc: any) => {
    if (_.isEmpty(reqAuth) || _.isEmpty(reqFunc)) return true; //no required
    let func = Accessor.Get(authority, reqAuth);
    if (!func || !_.isArray(func)) return false; //no func authority found or format not correct
    if (func.includes("*") || func.includes(reqFunc)) return true; //wild card or included
    return false;
  };

  static IsAccessible = (authority: any, level: any, reqAuth: string = "", reqLevel: number = 999, reqFunc: any = "") => {
    return this.AuthCheck(authority, reqAuth) && this.LevelCheck(level, reqLevel) && this.FuncCheck(authority, reqAuth, reqFunc);
  };

  static IsAccessibleQ = (reqAuth = "", reqLevel = 999, reqFunc = "") => {
    return this.IsAccessible(store.user.authority, store.user.level, reqAuth, reqLevel, reqFunc);
  };

  static Require(reqAuth: string = "", reqLevel: number = 999, reqFunc: any = []) {
    if (!this.IsAccessibleQ(reqAuth, reqLevel, reqFunc)) {
      window.location.assign("./Denied");
    }
  }

  // render the sub-react component if it passes the auth check
  static RenderComponentAuthCheck = (component: JSX.Element, auth: { reqAuth?: string; reqLevel?: number; reqFunc?: string }): JSX.Element => {
    const { reqAuth, reqLevel, reqFunc } = auth;
    if (Authority.IsAccessibleQ(reqAuth, reqLevel, reqFunc)) {
      return component;
    } else {
      return <></>;
    }
  };
}

export default Authority;
