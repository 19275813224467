import { IKnowledgeGrpManagementPayload, IDocList } from "../../interfaces/knowledge-grp-management-api.interface";
import { useFormContext } from "react-hook-form";
import { DATA_LINK, backendConnector } from "connectors";
import { useEffect, useState } from "react";
import { VStack } from "Components/Layouts";
import { Box, Switch } from "@mui/material";
import { DataGrid, GridColDef, GridToolbarFilterButton, getGridStringOperators, GridSelectionModel } from "@mui/x-data-grid";
import { IDocumentManager } from "Components/_PageComponents/interfaces/document-manager-api.interface";

interface IProps {
  row?: IKnowledgeGrpManagementPayload;
  mode: "Add" | "Edit" | "Info";
}

export const KnowledgeGrpDocInfo = ({ mode, row }: IProps) => {
  const InfoViw = mode === "Info";
  const { setValue } = useFormContext<IKnowledgeGrpManagementPayload>();
  const [docList, setDocList] = useState<IDocList[]>([]);
  const [selectionModel, setSelectionModel] = useState<IDocList[]>([]);

  useEffect(() => {
    setSelectionModel(row?.docs ?? []); //Find default selected values
    fetechDocList();
  }, []);

  const fetechDocList = async () => {
    const DMRespone = await backendConnector.post<{ docs: IDocumentManager[] }>(DATA_LINK.DMList, { filterControl: true });
    const projects = DMRespone.payload.docs;
    const filterDocuments = projects.filter((item: { status: string; enabled: boolean }) => item.status !== "preparing" && item.enabled == true);
    const data = filterDocuments.map((item) => {
      return {
        id: item._id,
        alias: item.alias,
        enabled: row?.docs?.find((doc) => doc.id === item._id)?.enabled ?? true, //Default value is true, if exist in docs return enabled value
      };
    });
    setDocList(data);
  };

  const onEnableChange = (checked: boolean, itemId: string) => {
    const updatedEnableValues = selectionModel?.map((item) => {
      if (item.id === itemId) {
        return {
          ...item,
          enabled: checked,
        };
      }
      return item;
    });
    setSelectionModel(updatedEnableValues);
    setValue("docs", updatedEnableValues);
  };

  const columns: GridColDef[] = [
    {
      field: "alias",
      headerName: "Selected Document Name",
      flex: 1,
      sortingOrder: ["desc", "asc"],
      filterOperators: getGridStringOperators().filter((operator) => operator.value === "contains" || operator.value === "equals"),
    },
    {
      field: "enabled",
      headerName: "Enabled",
      flex: 1,
      filterable: false,
      renderCell: (params) => {
        const showButton = selectionModel?.some((item) => item.id === params.row.id);
        return (
          showButton && (
            <Switch
              disabled={InfoViw}
              defaultChecked={params.row.enabled}
              onChange={(event, checked) => {
                onEnableChange(checked, params.row.id);
              }}
            />
          )
        );
      },
    },
  ];

  const onSelectionModelChange = (newSelectionModel: GridSelectionModel) => {
    const selectedRows = docList.filter((item) => newSelectionModel.includes(item.id));
    setSelectionModel(selectedRows);
    setValue("docs", selectedRows);
  };

  const renderDocList = () => {
    return (
      <VStack sx={{ width: "100%" }}>
        <Box sx={{ height: 730, width: "100%" }}>
          <DataGrid
            checkboxSelection
            rows={docList}
            columns={columns}
            selectionModel={selectionModel?.map((item) => item.id)}
            onSelectionModelChange={onSelectionModelChange}
            isRowSelectable={(params) => !InfoViw}
            components={{
              Toolbar: GridToolbarFilterButton,
            }}
          />
        </Box>
      </VStack>
    );
  };

  return (
    <Box sx={{ width: "100%", height: "100%" }}>
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>{renderDocList()}</Box>
    </Box>
  );
};
