import { HStack, VStack } from "Components/LabIZO/Stackizo";
import { Authority } from "static";
import _ from "lodash";
import moment from "moment";
import { IConvLog } from "interfaces/db.interfaces/conv-log.interface";
import { GPTDetail } from "./GPTDetail";
import { ThumbDownAlt, ThumbUp } from "@mui/icons-material";

interface ConvlogInfoProps {
  doc: IConvLog;
  period: { DateRange: [string, string] };
  renderHeader: (label: string, width?: number) => JSX.Element;
  renderField: (label: string, value: any, width?: number, fontSize?: number, children?: JSX.Element) => JSX.Element;
}

const Thumb = ({ like }: { like?: boolean }) => {
  if (like === true) return <ThumbUp sx={{ color: "#3d6eff" }} />;
  else if (like === false) return <ThumbDownAlt sx={{ color: "red" }} />;
  else return <div>--</div>;
};

export function ConvlogInfo({ doc, period, renderHeader, renderField }: ConvlogInfoProps) {
  const nlpInfoAuth = { reqAuth: "Convlog", reqFunc: "NlpInfo" };
  const transDate = (tzTime: string) => {
    try {
      if (!tzTime) throw new Error("Invalid date");
      return moment(new Date(tzTime)).format("YYYY-MM-DD HH:mm:ss");
    } catch {
      return "Not Applicable.";
    }
  };

  const renderMessage = (doc: IConvLog) => {
    const width = 120;
    return (
      <VStack width="40%" sapcing={2} sx={{ justifyContent: "flex-start" }}>
        {renderHeader("Message")}
        <HStack>
          {renderField("Language", doc.lang, width)}
          {renderField("Type", doc.Input.Type, width)}
        </HStack>
        {renderField("Input", doc.Input.Content, width)}
      </VStack>
    );
  };

  const renderBasic = (doc: IConvLog) => {
    return (
      <VStack width="50%" gap={2}>
        {renderHeader("Basic Information")}
        {renderField("Conversation ID", doc._id, undefined, undefined, doc.pipeline === "gptQa" || doc.pipeline === "llm-playground" ? <GPTDetail period={period} id={doc._id} /> : undefined)}
        {renderField("User ID", doc.user_id)}
        {renderField("Session ID", doc.sessionID)}
        {renderField("Server Recieve Time", transDate(doc.recTime))}
        {renderField("Server Sent Time", transDate(doc.outTime))}
        {renderField("Process Time", doc.processTime + "s")}
        {renderField("Channel", doc.channel)}
        {Authority.RenderComponentAuthCheck(renderField("Chatbot Version", doc.chatbotVersion), { reqAuth: "Convlog", reqFunc: "chatbotVersion" })}
      </VStack>
    );
  };

  const renderInputBasic = (doc: IConvLog) => {
    const width = 120;
    return (
      <VStack width="40%" gap={2}>
        {renderHeader("NLP Basic")}
        {renderField("Workspace", doc.ws, width)}
        {renderField("Intent", doc.intent, width)}
        {renderField("Confidence", doc.confidence, width)}
        {renderField("LiveChat (Beta)", doc.liveChat)}
      </VStack>
    );
  };

  const renderAnsRes = (doc: IConvLog) => {
    enum mapping {
      __change = "Change Topic?",
      __func = "Pre-Function",
      __ans = "Direct Answer",
      __tans = "Direct Template",
      __bfunc = "Post-Function",
      __param = "Other Parameters",
    }

    return _.map(mapping, (o, i) => {
      const accessible = Authority.IsAccessibleQ("Convlog", 0, i);
      if (i === mapping.__change) return renderField(o || "", doc.ansRes?.[i] || "false");
      else if (i === mapping.__ans) return renderField(o || "", doc.ansRes?.[i] || "N/A");
      else return accessible && renderField(o || "", doc.ansRes?.[i] || "N/A");
    });
  };

  const renderSummary = (doc: IConvLog) => {
    return (
      <VStack width="50%" gap={2}>
        {renderHeader("Response Summary")}
        {renderField("Pipeline", doc.pipeline)}
        {renderAnsRes(doc)}
      </VStack>
    );
  };

  return (
    <VStack width="100%" gap={2} height="auto">
      <HStack gap={2} justifyContent="flex-start" alignItems="flex-start">
        {renderMessage(doc)}
        {renderBasic(doc)}
      </HStack>
      <HStack gap={2} justifyContent="flex-start" alignItems="flex-start">
        {Authority.RenderComponentAuthCheck(renderInputBasic(doc), nlpInfoAuth)}
        {Authority.RenderComponentAuthCheck(renderSummary(doc), nlpInfoAuth)}
      </HStack>
    </VStack>
  );
}
