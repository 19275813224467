import { Accordion, AccordionDetails, AccordionSummary, Checkbox, FormControl, FormControlLabel, FormLabel, Radio, RadioGroup, Slider, Typography } from "@mui/material";
import { HStack, VStack } from "Components";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useEffect } from "react";
import { useSettingContext } from "../../Hooks/setting-hook";

export default function DisplaySetting() {
  const { setting, updateSetting } = useSettingContext();
  useEffect(() => {}, [setting.display]);
  const handleShowitemChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    updateSetting({ display: { ...setting.display, showItem: event.target.value as "All" | "Topic" | "Example" } });
  };

  const handleLabelFormatChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    updateSetting({ display: { ...setting.display, labelFormat: event.target.value as "normal" | "ratio" } });
  };

  const handleCircleChange = (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
    updateSetting({ display: { ...setting.display, showCircle: checked } });
  };

  const handleSliderChange = (event: Event, newValue: number | number[]) => {
    updateSetting({ display: { ...setting.display, displayCluster: newValue as number } });
  };
  const renderDisplaySetting = () => {
    return (
      <VStack width={"100%"}>
        <FormControl>
          <FormLabel>Show Item</FormLabel>
          <RadioGroup row aria-labelledby="demo-radio-buttons-group-label" sx={{ width: "100%", color: "primary.main" }} value={setting.display.showItem} onChange={handleShowitemChange}>
            <FormControlLabel labelPlacement="end" value="All" control={<Radio />} label="Topic and Example" />
            <FormControlLabel labelPlacement="end" value="Topic" control={<Radio />} label="Only Topic" />
            <FormControlLabel labelPlacement="end" value="Example" control={<Radio />} label="Only Example" />
          </RadioGroup>
        </FormControl>
        {/* <FormControl fullWidth>
          <FormLabel>Show Label Format</FormLabel>
          <RadioGroup row aria-labelledby="demo-radio-buttons-group-label" sx={{ width: "100%", color: "primary.main" }} value={setting.display.labelFormat} onChange={handleLabelFormatChange}>
            <FormControlLabel labelPlacement="end" value="normal" control={<Radio />} label="Normal" />
            <FormControlLabel labelPlacement="end" value="ratio" control={<Radio />} label="Ratio" />
          </RadioGroup>
        </FormControl> */}
        {/* <HStack width={"100%"} style={{ justifyContent: "left" }}>
          <FormLabel>Show Circle</FormLabel>
          <Checkbox value={setting.display.showCircle} defaultChecked onChange={handleCircleChange} />
        </HStack> */}
        <HStack>
          <Typography sx={{ width: "100%", color: "primary.main" }}> Display Cluster</Typography>
          <Slider
            sx={{ width: "100%", color: "primary.main" }}
            value={setting.display.displayCluster}
            valueLabelDisplay="auto"
            step={1}
            min={5}
            max={setting.display.dataLength}
            onChange={handleSliderChange}
          />
        </HStack>
      </VStack>
    );
  };
  return (
    <Accordion sx={{ zIndex: 3 }}>
      <AccordionSummary id="DisplaySetting" expandIcon={<ExpandMoreIcon />}>
        <Typography variant="body1" sx={{ color: "primary.main", textAlign: "center" }}>
          Display Setting
        </Typography>
      </AccordionSummary>
      <AccordionDetails>{renderDisplaySetting()}</AccordionDetails>
    </Accordion>
  );
}
