import React from "react";
import _ from "lodash";

import styles from "./Dashboard.module.css";
import { Button, Grid } from "@mui/material";
import { IGPTQADashboardState } from "./Dashboard";
import { HStack, VStack } from "Components";
import Report from "./Report/Report";

import moment from "moment";
import type { Moment } from "moment";
import { Header, PieChartStack, ScoreCard, ScoreCardVar } from "Components/Dashboard";
import { LinePlot } from "Components/Graphs";
import { DateRangePicker } from "Components/DatePicker/DateRangePicker";

interface DashboardHookProp extends IGPTQADashboardState {
  onChangeDateRange: (dates: [Moment | null, Moment | null] | null, formatString?: [string, string]) => void;
  fetchData: () => void;
}

// TODO: report export button
const DashboardHook: React.FC<DashboardHookProp> = ({ data, base, dateRange, onChangeDateRange }) => {
  const QuickView = [7, 30, 365];
  const pieChartStackDataSets = [
    {
      title: base.graphTitles.DocQueryDistribution,
      data: data.Feedback.docQueryDistribution,
      take: 12,
    },
    {
      title: base.graphTitles.LikeDistribution,
      data: data.Feedback.likeDistribution,
    },
  ];

  const renderScoreCards = (data: Record<string, string | number>[]) => {
    const scorecard_items = _.map(data, (o, i) => {
      if (i === 0 || i === 1) {
        return (
          <Grid item xs={6} lg={6} key={i} style={{ display: "flex" }}>
            <ScoreCard key={i} title={o.title} values={o.values} remarks={o.remarks}></ScoreCard>
          </Grid>
        );
      } else {
        return (
          <Grid item xs={6} lg={6} key={i}>
            <ScoreCardVar key={i} title={o.title} values={o.values} remarks={o.remarks}></ScoreCardVar>
          </Grid>
        );
      }
    });
    return (
      <div className={styles.scoreCards}>
        <Grid container spacing={2}>
          {scorecard_items}
        </Grid>
      </div>
    );
  };

  const { titles, graphTitles } = base;
  return (
    <Grid container spacing={2} className={styles.container} width={"100%"} sx={{ pt: "10px", height: "100%", width: "100%", minHeight: "950px" }}>
      {/* Top Left */}
      <Grid container item xs={12} lg={7} sx={{ display: "flex", height: "50%", width: "100%", maxWidth: "100%" }}>
        <VStack width={"100%"} style={{ height: "100%", justifyContent: "space-between" }}>
          <HStack width={"100%"}>
            <Header title={titles.Usage} />

            <HStack>
              {/* Quick View */}
              {QuickView.map((o, i) => (
                <Button
                  key={i}
                  onClick={() => {
                    onChangeDateRange([moment().subtract(o - 1, "d"), moment()]);
                  }}
                >
                  {o + "D"}
                </Button>
              ))}
              <DateRangePicker dates={[dateRange.from, dateRange.to]} onChangeDates={onChangeDateRange} />
            </HStack>
          </HStack>

          <LinePlot<{ name: string; count: number }>
            title={graphTitles.DailyCov} //
            dataPoints={data.DailyConv}
          />
        </VStack>
      </Grid>

      {/* Top Right */}
      <Grid container item xs={12} lg={5} style={{ height: "50%", paddingTop: 10 }}>
        <VStack width="100%" style={{ gap: "15px" }}>
          <Report base={base} dateRange={dateRange} />
          {renderScoreCards(data && data.Overview)}
        </VStack>
      </Grid>

      {/* Bot Left */}
      <Grid container item xs={12} lg={7} style={{ height: "50%", width: "100%", paddingTop: 30 }}>
        <VStack width="100%">
          <Header title={titles.Feedback || ""} />
          <PieChartStack dataSets={pieChartStackDataSets} />
        </VStack>
      </Grid>

      {/* Bot Right */}
      <Grid item xs={12} lg={5} style={{ height: "50%", paddingTop: 30 }}>
        <VStack style={{ height: "100%" }}>
          <Header title={titles.CutomerBehaviour} />
          {renderScoreCards(data && data.CustomerBehavior)}
        </VStack>
      </Grid>
    </Grid>
  );
};

export default DashboardHook;
