import { AvailLANG } from "../available-lang.interface";
import { T2EContext } from "./response.interface";

export interface UserInput {
  Location?: any;
  session_id: string;
  user_id: string;
  device_id?: string;
  instance?: string;
  log?: boolean;
  input: {
    type: "text" | "button" | "attachment" | "setContext";
    content: string;
    contexts?: T2EContext[];
  };
  model?: string;
  livechat?: boolean;
  timestamp?: string | Date;
  remarks?: any;
  lang?: AvailLANG;
  qa_mode?: QAMode;

  // for gpt processing
  message_id?: string;
  urlParams?: { lang?: AvailLANG; page?: string; kg?: string[] };
  knowledgeGroup?: string[];
  project?: string[];
}

export enum QAMode {
  INTENT_CLASSIFICATION = "intentClassification",
  LLM = "llm",
  HYBRID = "hybrid", // intentClassification with llm as fallback
  DIRECT_LLM = "direct_llm", // llm only
}

export interface SetContextInput {
  session_id: string;
  user_id: string;
  device_id?: string;
  contexts?: T2EContext[];
  qa_mode: QAMode;
}

export const SampleUserInput: UserInput = {
  session_id: "123",
  user_id: "123",
  input: {
    type: "text",
    content: "hi",
  },
  project: ["All"],
  qa_mode: QAMode.HYBRID,
};
