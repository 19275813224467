import { Component } from "react";

import PropsType from "prop-types";
import _ from "lodash";
import { v1 } from "uuid";
import { Box, Typography } from "@mui/material";
import { ArrowBack } from "@mui/icons-material";

import { Accessor, ColorX } from "static";
import { HStack, Spacer, VStack } from "Components/LabIZO/Stackizo";
import { StyledButton } from "Components/LabIZO/Stylizo";
import Formizo from "Components/LabIZO/Formizo";
import { Button } from "@mui/material";
import { createRef } from "react";
class Inner extends Component {
  static propTypes = {
    doc: PropsType.object,
    docID: PropsType.string,
    ibase: PropsType.object,
    addOns: PropsType.object,
    onSubmit: PropsType.func,
    onQuit: PropsType.func,
    onQuitRefresh: PropsType.func,
    auth: PropsType.object,
    level: PropsType.number,
    showIDOnTop: PropsType.bool,
    allowBackToTop: PropsType.bool,
    formizo: PropsType.object,
  };

  static defaultProps = {
    doc: {},
    docID: "",
    ibase: {},
    addOns: {},
    onSubmit: () => {},
    onQuit: (docID) => {},
    onQuitRefresh: (docID) => {},
    auth: {},
    level: 999,
    showIDOnTop: true,
    allowBackToTop: false,
    formizo: {},
  };

  constructor() {
    super();
    this.state = {
      atTop: true, // State to track if the user is at the top
    };
    this.formRef = createRef();
    this.scrollToTop = this.scrollToTop.bind(this); // Ensure method is bound to the component instance
    this.handleScroll = this.handleScroll.bind(this);
  }

  componentDidMount() {
    this._setAllStates();
    if (this.formRef.current) {
      this.formRef.current.addEventListener("scroll", this.handleScroll);
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (!Accessor.IsIdentical(prevProps, this.props, Object.keys(Inner.defaultProps))) {
      this._setAllStates();
    }
  }

  componentWillUnmount() {
    if (this.formRef.current) {
      this.formRef.current.removeEventListener("scroll", this.handleScroll);
    }
    this.setState = (state, callback) => {
      return;
    };
  }

  _setAllStates = (callback) => {
    this.setState(
      (state, props) => ({
        ...props,
      }),
      callback
    );
  };

  onMountFormizo = (callbacks) => {
    this.MountFormizo = callbacks;
  };

  scrollToTop() {
    if (this.formRef && this.formRef.current) {
      this.formRef.current.scrollTop = 0;
    }
  }
  handleScroll() {
    if (this.formRef.current) {
      this.setState({ atTop: this.formRef.current.scrollTop === 0 });
    }
  }

  _onQuit = () => {
    let { ibase, onQuit, onQuitRefresh, docID } = this.props;
    if (ibase.QuitReload) {
      if (onQuitRefresh) {
        onQuitRefresh(docID);
      }
    } else {
      if (onQuit) {
        onQuit(docID);
      }
    }
  };

  renderFormizo() {
    let { ibase, doc, onSubmit, addOns, auth, level, formizo } = this.props;

    return (
      <Formizo
        schema={ibase.schema}
        formID={v1()}
        buttons={ibase.buttons || []}
        buttonAlign="right"
        readOnly={ibase.readOnly}
        onMounted={this.onMountFormizo}
        defaultValue={doc}
        onSubmit={onSubmit}
        addOns={addOns}
        auth={auth}
        level={level}
        {...formizo}
      />
    );
  }

  renderBackBar() {
    let { ibase, docID, showIDOnTop } = this.props;
    return (
      <HStack>
        <Box height="100%" background="#f9ffff">
          <HStack>
            <Typography
              style={{
                textAlign: "left",
                fontFamily: "Segoe UI",
                fontSize: 20,
                color: ColorX.GetColorCSS("Primary"),
                marginRight: 10,
              }}
            >
              {ibase.title}
            </Typography>
            {docID && showIDOnTop && (
              <Typography
                style={{
                  textAlign: "left",
                  fontFamily: "Segoe UI",
                  fontSize: 20,
                  color: "black",
                }}
              >
                {docID}
              </Typography>
            )}
          </HStack>
        </Box>
        <Spacer />
        <StyledButton
          theme={{
            color: "white",
            background: ColorX.GetColorCSS("Primary"),
            hover: {
              background: ColorX.GetColorCSS("Primary2"),
            },
            boxShadow: "transparent",
            borderRadius: "0px",
          }}
          onClick={() => {
            this._onQuit(docID);
          }}
        >
          <ArrowBack />
        </StyledButton>
      </HStack>
    );
  }

  renderContent() {
    let { ibase, docID, doc, onQuit, onQuitRefresh, addOns, onSubmit, auth, level, formizo } = this.props;

    if (_.isFunction(ibase.Custom)) {
      return ibase.Custom(
        docID,
        doc,
        onQuit,
        onQuitRefresh,
        () => {
          return this.renderFormizo();
        },
        addOns,
        ibase,
        onSubmit,
        auth,
        level,
        formizo
      );
    } else {
      return this.renderFormizo();
    }
  }

  render() {
    let { ibase, allowBackToTop } = this.props;
    if (!ibase) return null;
    return (
      <VStack ref={this.formRef} width="100%" height={"100vh"} sx={{ p: 1, py: "10px", pb: 5, overflow: "auto", justifyContent: "flex-start" }}>
        {this.renderBackBar()}
        {this.renderContent()}
        {!this.state.atTop && allowBackToTop && (
          <Button variant="contained" color="primary" onClick={this.scrollToTop} sx={{ position: "fixed", bottom: 16, right: 16 }}>
            Back to Top
          </Button>
        )}
      </VStack>
    );
  }
}

export default Inner;
