import _ from "lodash";

interface IRules {
  [key: string]: (value: string) => boolean;
}
interface IErrorMsg {
  [key: string]: string;
}

const Rules: IRules = {
  required: (value: string) => !_.isEmpty(value) || _.isNumber(value),
  email: (value: string) => {
    return !value || /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value);
  },
};

const ErrorMsg: IErrorMsg = {
  required: "Required!",
  email: "Please enter a valid email address",
};

const Criteria: string[] = [];

type CriteriaType = keyof IRules;

const Validate = (value: string, criteria: CriteriaType[] = []): { err: boolean; msg: string } => {
  let error: string = "";
  _.forEach(criteria, (criteriaKey) => {
    if (!Rules[criteriaKey](value)) {
      if (_.isEmpty(error)) {
        error = ErrorMsg[criteriaKey];
      }
    }
  });
  return { err: !_.isEmpty(error), msg: error };
};

export { Criteria, Rules, ErrorMsg, Validate };
