import { LineChart, Line, XAxis, YAxis, Tooltip, ResponsiveContainer, CartesianGrid } from "recharts";

import styles from "./LinePlot.module.css";
import { VStack } from "Components/LabIZO/Stackizo";
import { ColorX } from "static";
import { GraphTitle } from "../GraphTitle/GraphTitle";

interface LinePlotProps<T = any> {
  title: string;
  dataPoints?: T[];
}

export const LinePlot = <T,>({ title, dataPoints }: LinePlotProps<T>): JSX.Element => {
  const renderLine = () => {
    if (dataPoints && dataPoints.length > 0) {
      return (
        <ResponsiveContainer className={styles.linePlotContainer}>
          <LineChart data={dataPoints}>
            <Line yAxisId="left" type="monotone" dataKey="count" stroke="#8884d8" activeDot={{ r: 3 }} />
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="name" />
            <YAxis yAxisId="left" />
            <Tooltip isAnimationActive={false} />
          </LineChart>
        </ResponsiveContainer>
      );
    }
    return <div />;
  };

  return (
    <VStack width="100%" className="linePlot" style={{ background: ColorX.GetColorCSS("GraphBG") }}>
      <GraphTitle title={title} />
      {renderLine()}
    </VStack>
  );
};
