import { FormControlLabel, Switch } from "@mui/material";
import { DBAnswerSpec } from "interfaces";
import { useContext } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { BodyTitle } from "Components";
import { HStack, VStack } from "Components/Layouts";
import { IAllFLEAnswers, IFLEDoc } from "interfaces/db.interfaces/fle.interface";
import { ConditionalAnswer } from "../AnswerSettings/ConditionalAnswer/ConditionalAnswer";
import { WithAPIIntegration } from "../AnswerSettings/APIIntegation/WithAPIIntegration";
import { FLEFormContext } from "Pages/FLE/hooks/FLEContexts";
import { Authority } from "static";

interface IAnswerSetupProps {}

export const AnswerSetup = (props: IAnswerSetupProps) => {
  const { control, watch, setValue } = useFormContext<IFLEDoc<DBAnswerSpec>>();
  const aFormValue = watch();
  const formCtxt = useContext(FLEFormContext);

  if (!aFormValue || !formCtxt) return <></>;
  const disabled = formCtxt?.mode == "Info";

  let { answers, integrations, contexts } = aFormValue;

  if (!answers) {
    answers = {
      defaultAnsDoc: {
        _id: "default",
        text: { EN: [""] },
        domain: "master",
        name: "",
      },
    };
  }

  const onChangeAnswers = (answers: IAllFLEAnswers<DBAnswerSpec>) => {
    setValue("answers", answers);
  };

  const watchAPIEnabled = watch("integrations.api")?.enabled;

  return (
    <VStack width="100%" alignItems="space-between" justifyContent="flex-start">
      {Authority.IsAccessibleQ("FLE", 999, "APIIntegrator") && (
        <HStack justifyContent={"flex-start"}>
          <BodyTitle sx={{ color: "primary.main" }}>API Integration</BodyTitle>
          <Controller
            name="integrations.api"
            control={control}
            defaultValue={integrations?.api}
            render={({ field: { onChange, value }, fieldState: { error }, formState }) => {
              return (
                <FormControlLabel
                  //
                  labelPlacement="end"
                  label={value?.enabled ? "ON" : "OFF"}
                  control={
                    <Switch
                      checked={(value && value.enabled) || false}
                      onChange={(e, checked) => {
                        if (!value) {
                          value = {
                            enabled: checked,
                            url: "",
                            method: "GET",
                            request: {},
                            responses: {
                              default: {
                                answerDoc: { _id: "", text: { EN: [""] }, domain: "master", name: "" },
                              },
                            },
                          };
                        } else {
                          value = {
                            ...value,
                            enabled: checked,
                          };
                        }
                        onChange(value);
                      }}
                      disabled={disabled}
                    />
                  }
                />
              );
            }}
          />
        </HStack>
      )}

      {watchAPIEnabled && <WithAPIIntegration disabled={disabled} />}
      {!watchAPIEnabled && <ConditionalAnswer contexts={contexts} answers={answers} onChangeAnswers={onChangeAnswers} disabled={disabled} />}
    </VStack>
  );
};
