import { DownloadOutlined } from "@mui/icons-material";
import { Button } from "@mui/material";
import { store } from "static";
import fileDownload from "js-file-download";
import { DATA_LINK, backendConnector } from "connectors";

interface DownloadButtonProps {
  URL: DATA_LINK;
  redirectURL: string;
  filename: string;
  disabled?: boolean;
  children?: React.ReactNode;
  variant?: "text" | "outlined" | "contained";
}

export const DownlonadButton = (props: DownloadButtonProps) => {
  const buttonContent = props.children || <DownloadOutlined />;
  const handleDownload = async () => {
    let redirectURL = props.redirectURL;
    if (!redirectURL?.startsWith("https://") && !redirectURL?.startsWith("http://")) {
      // If the redirectURL is relative, we need to append the current protocol and host to it
      redirectURL = window.location.protocol + "//" + window.location.host + redirectURL;
    }
    const url = props.URL;
    store.isLoading(true);
    try {
      const data = await backendConnector.export(url, {
        redirectURL: redirectURL,
      });
      store.isLoading(false);
      const contentType = data.type;

      const fileTypeMap: { [key: string]: string } = {
        "text/plain": "txt",
        "application/pdf": "pdf",
        "application/vnd.openxmlformats-officedocument.wordprocessingml.document": "docx",
        "application/msword": "doc",
        "audio/mpeg": "mp3",
        "video/mp4": "mp4",
        "audio/wav": "wav",
        "audio/x-wav": "wav",
      };

      const fileType = fileTypeMap[contentType];
      if (!fileType) {
        store.Alert(`File type ${contentType} is not supported.`, "error");
        return;
      }
      store.isLoading(false);
      const blob = new Blob([data], { type: contentType });

      //if filename ends with fileType, no need to append fileType
      if (!props.filename.endsWith(fileType)) {
        fileDownload(blob, `${props.filename}.${fileType}`); // download file with filename and fileType
      } else {
        fileDownload(blob, props.filename); // download file with filename
      }
    } catch (e) {
      console.error(e);
      store.isLoading(false);
      store.Alert("Download Failed.", "error");
    }
  };
  return (
    <Button fullWidth variant={props.variant} component="label" disabled={props.disabled} onClick={handleDownload} sx={{ width: "100%", height: "100%" }}>
      {buttonContent}
    </Button>
  );
};
