import { IDocumentManager } from "../../interfaces/document-manager-api.interface";
import { useFormContext } from "react-hook-form";
import { ControlledTextFieldWithIcon } from "Components/HookForm/ControlledTextFieldWithIcon";
import { Box, Button, Grid, Typography, Modal, LinearProgress } from "@mui/material";
import { ArrowBack } from "@mui/icons-material";
import { styled } from "@mui/system";
import { DATA_LINK } from "connectors/datalink";
import { HStack, VStack } from "Components/Layouts";
import { DownlonadButton } from "Components/DownloadButton";
import { useState } from "react";
import DocumentReupload from "../DocumentReuploadForm/DocumentReupload";
import { backendConnector } from "connectors";
import { Authority, store } from "static";
import { CusAuth } from "interfaces";

export const DocumentInfo = ({ mode, onSubmit, onQuitRefresh }: { mode: "Add" | "Edit" | "Info"; onSubmit: (quitForm?: boolean) => void; onQuitRefresh: (_id: string) => void }) => {
  const { control, getValues, setValue } = useFormContext<IDocumentManager>();
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  const renderDownloadButton = (filePath: "original_file" | "text_file") => {
    const filename = getValues("alias");
    const redirectURL = getValues(`${filePath}.location`);
    const disabled = !getValues(`${filePath}.available`);
    return (
      <DownlonadButton variant="contained" disabled={disabled} filename={filename} URL={DATA_LINK.DMDownload} redirectURL={redirectURL}>
        {<Typography> {filePath == "original_file" ? "Download Original File" : "Download Processed File"}</Typography>}
      </DownlonadButton>
    );
  };

  const renderSummarizeButton = () => {
    const handleClick = async () => {
      setLoading(true);
      try {
        const res = await backendConnector.post<string>(DATA_LINK.DMSummaries, {
          _id: getValues("_id"),
        });
        if (res?.Success) {
          store.Alert("Summarize Successfully", "success");
          setValue("summary", res.payload);
          setLoading(false);
        }
      } catch (error) {
        setLoading(false);
        store.Alert("Summarize failed", "error");
      }
    };
    return (
      <Button sx={{ height: "100%" }} variant="contained" color="info" fullWidth onClick={handleClick}>
        {<Typography>Summarize</Typography>}
      </Button>
    );
  };

  const renderSummary = () => {
    return (
      <Grid item xs={12} sm={10}>
        <Typography variant="body1" color="primary.main" style={{ font: "normal normal normal 16px/21px Segoe UI", marginBottom: "5px" }}>
          Summary
        </Typography>
        {loading ? <LinearProgress /> : <ControlledTextFieldWithIcon name="summary" control={control} variant="outlined" fullWidth multiline />}
      </Grid>
    );
  };

  const renderReuploadButton = () => {
    const parentId = getValues("documentLink.parentId");
    const _id = getValues("_id");
    const handleReupload = async () => {
      setOpen(true);
    };
    return (
      <Button sx={{ height: "100%" }} variant="contained" color="info" fullWidth disabled={!!(parentId && parentId != _id) || mode === "Info"} onClick={handleReupload}>
        {<Typography>Re-Upload</Typography>}
      </Button>
    );
  };
  const renderPopUpWindow = () => {
    const CustomBox = styled(Box)({
      position: "absolute",
      top: "50%",
      left: "50%",
      width: "95%",
      height: "95%",
      transform: "translate(-50%, -50%)",
      border: "2px solid #000",
      backgroundColor: "white",
    });

    const handleClose = () => {
      setOpen(false);
    };

    return (
      <Modal sx={{ zIndex: "3" }} open={open} onClose={handleClose} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
        <CustomBox>
          <VStack width="100%" height="100%">
            <HStack sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", width: "100%" }}>
              <Typography variant="h5" sx={{ padding: "10px 25px" }}>
                Re-Upload Document
              </Typography>
              <Button sx={{ paddingRight: "25px" }} onClick={handleClose}>
                <ArrowBack />
              </Button>
            </HStack>
            <DocumentReupload mode={mode} onSubmit={onSubmit} onQuitRefresh={onQuitRefresh} />
          </VStack>
        </CustomBox>
      </Modal>
    );
  };

  return (
    <VStack width={"100%"} sx={{ alignItems: "flex-start" }}>
      <Grid container spacing={2} sx={{ marginLeft: "30px", marginRight: "30px" }}>
        <Grid item xs={12} sm={2}>
          <ControlledTextFieldWithIcon name="_id" label="ID" control={control} variant="outlined" fullWidth disabled />
        </Grid>
        <Grid item xs={12} sm={4}>
          <ControlledTextFieldWithIcon
            name="alias"
            label="Document Name"
            control={control}
            variant="outlined"
            fullWidth
            disabled={mode === "Info"}
            rules={{
              required: "This field is required",
              validate: {
                notOnlyWhitespace: (value) => value.trim().length > 0 || "The field cannot be empty",
              },
            }}
          />
        </Grid>
        <Grid item xs={12} sm={1.5}>
          {renderDownloadButton("text_file")}
        </Grid>
        <Grid item xs={12} sm={1.5}>
          {renderDownloadButton("original_file")}
        </Grid>
        {/* <Grid item xs={12} sm={1}>
          {Authority.RenderComponentAuthCheck(renderSummarizeButton(), { reqAuth: `GPTQA.DocumentManager`, reqFunc: CusAuth.Summarize })}
        </Grid> */}
        <Grid item xs={12} sm={1.5}>
          {Authority.RenderComponentAuthCheck(renderReuploadButton(), { reqAuth: `GPTQA.DocumentManager`, reqFunc: CusAuth.Reupload })}
        </Grid>
        {open && (
          <Grid item xs={12} sm={10}>
            {renderPopUpWindow()}
          </Grid>
        )}
      </Grid>
      <Typography variant="body1" color="primary.main" style={{ font: "normal normal normal 16px/21px Segoe UI", marginLeft: "50px", marginBottom: "10px" }}>
        Reference Settings
      </Typography>
      <Grid container spacing={2} sx={{ marginLeft: "30px", marginRight: "30px" }}>
        <Grid item xs={3} sm={3}>
          <ControlledTextFieldWithIcon name="reference.title" label="Reference Button Label" control={control} variant="outlined" fullWidth disabled={mode == "Info"} />
        </Grid>
        <Grid item xs={6} sm={6}>
          <ControlledTextFieldWithIcon name="reference.link" label="Reference URL" control={control} variant="outlined" fullWidth disabled={mode == "Info"} />
        </Grid>
      </Grid>
      <Grid container spacing={2} sx={{ marginLeft: "30px", marginRight: "30px" }}>
        <Grid item xs={12} sm={10}>
          {mode != "Add" ? <ControlledTextFieldWithIcon name="CREATED_AT" label="Created At" control={control} variant="outlined" fullWidth disabled /> : <></>}
        </Grid>
        <Grid item xs={12} sm={10}>
          {mode != "Add" ? <ControlledTextFieldWithIcon name="LASTMODIFIED" label="Last Modified Time" control={control} variant="outlined" fullWidth disabled /> : <></>}
        </Grid>
        {/* {Authority.RenderComponentAuthCheck(renderSummary(), {
          reqAuth: `GPTQA.DocumentManager`,
          reqFunc: CusAuth.Summarize,
        })} */}
        {/* <Grid item xs={12} sm={10}>
          <Typography variant="body1" color="primary.main" style={{ font: "normal normal normal 16px/21px Segoe UI", marginBottom: "5px" }}>
            Summary
          </Typography>
          {loading ? <LinearProgress /> : <ControlledTextFieldWithIcon name="summary" control={control} variant="outlined" fullWidth multiline />}
        </Grid> */}
      </Grid>
    </VStack>
  );
};
