import { Box, Typography } from "@mui/material";
import Datumizo from "Components/LabIZO/Datumizo/Datumizo";
import { HStack, VStack } from "Components/LabIZO/Stackizo";
import { Accessor, ColorX, ErrorX, store } from "static";
import _ from "lodash";
import { observer } from "mobx-react";
import moment from "moment";
import PropsType from "prop-types";
import { Component } from "react";
import schema from "./vbschema";
import Summary from "./Summary/Summary";
import { DATA_LINK, backendConnector } from "connectors";

class VerifyTagging extends Component {
  static propTypes = {
    docID: PropsType.string,
    doc: PropsType.object,
    onQuit: PropsType.func,
    onQuitRefresh: PropsType.func,
    addOns: PropsType.object,
  };

  static defaultProps = {
    docID: "",
    doc: {},
    onQuit: () => {},
    onQuitRefresh: () => {},
    addOns: {},
  };

  constructor() {
    super();
    this.state = {
      title: "",
      serverSidePagination: false,
      intents: [],
      base: {
        title: "",
        exportDoc: "",
        schema: schema,
        reqAuth: "",

        tablizo: {
          showSelector: false,

          columnsToolbar: false,
          filterToolbar: false,
          densityToolbar: true,
          exportToolbar: false,
        },

        Connect: {
          DBInfo: DATA_LINK.TBankDBInfo,
          List: DATA_LINK.TBankList,
          Get: DATA_LINK.TBankGet,
          schema: schema.Table,
        },

        operations: {
          Edit: {
            title: "Edit TrainTagging ",
            url: DATA_LINK.TBankEdit,
            success: "TrainTagging Edited Successfully",
            fail: "TrainTagging Edit Failed: ",
            schema: schema.Edit,
            buttons: ["Revert", "Submit"],
            onSubmit: "Edit",
          },

          Info: {
            title: "TrainTagging ",
            success: "TrainTagging Load Successfully",
            fail: "TrainTagging Load Failed: ",
            schema: schema.Info,
          },
        },

        buttons: {
          inline: [],
          left: [
            {
              inject: (tableData, addOns) => this.renderLogDetails(addOns),
            },
          ],
          right: [
            {
              inject: (tableData) => (
                <HStack width={200} key="inject">
                  <Summary data={tableData} />
                </HStack>
              ),
            },
          ],
        },
      },
    };
  }

  componentDidMount() {
    this._setAllStates(() => {
      this.getAllIntents();
    });
  }

  componentDidUpdate(prevProps, prevState) {
    if (!Accessor.IsIdentical(prevProps, this.props, Object.keys(VerifyTagging.defaultProps))) {
      this._setAllStates();
    }
  }

  componentWillUnmount() {
    this.setState = (state, callback) => {
      return;
    };
  }

  _setAllStates = (callback) => {
    this.setState(
      (state, props) => ({
        ...props,
      }),
      callback
    );
  };

  _StartTraining = async () => {
    let { doc, onQuitRefresh } = this.props;
    console.log("_StartTraining", doc);
    const url = DATA_LINK.TrainingTrain;
    try {
      await backendConnector.post(url, doc);
      if (onQuitRefresh) onQuitRefresh();
    } catch (e) {
      ErrorX.Handle(e);
    }
  };

  onMountDatumizo = (callbacks) => {
    this.MountDatumizo = callbacks;
  };

  getAllIntents = async () => {
    const url = DATA_LINK.IntentList;
    const data = {
      skip: 0,
      limit: 9999,
      fields: ["_id"],
    };
    const addOns = {
      workspace: "ALL",
      allIntents: true,
    };
    try {
      const { Success, payload } = await backendConnector.post(url, data, addOns, { skipAudit: true });
      console.log(url, payload);

      if (Success === true) {
        let docs = payload.docs;
        let intents = _.map(docs, (o, i) => {
          let splited = o._id.split(":");
          let caption = o._id;
          if (splited.length === 2) {
            caption = splited[1];
          }
          return {
            _id: o._id,
            caption: caption,
          };
        });
        this.setState((state, props) => ({
          intents: intents,
        }));
      } else {
        ErrorX.Handle(payload.message || "");
      }
    } catch (e) {
      ErrorX.Handle(e);
    }
  };

  _onVerifyToggle = async (id, f) => {
    console.log(id, f);
    let { docID } = this.props;
    const url = DATA_LINK.TBankAccept;
    const data = {
      logID: id,
      trainID: docID,
      value: f,
      mode: "After",
    };

    try {
      const { Success, payload } = await backendConnector.post(url, data);
      console.log(url, payload);
      if (Success === true) {
        store.Alert("Record Updated", "success");
        this.MountDatumizo.SoftReload(id);
      } else {
        store.Alert("Record Update Failed", "error");
      }
    } catch (e) {
      ErrorX.Handle(e);
    }
  };

  renderAlias() {
    let { doc } = this.props;
    return (
      <HStack justifyContent="flex-start">
        <Typography
          style={{
            textAlign: "left",
            width: "100%",
            minWidth: 500,
            fontSize: 25,
            color: ColorX.GetColorCSS("Primary"),
          }}
        >
          {doc.alias}
        </Typography>
      </HStack>
    );
  }
  renderLogDetails(addOns) {
    if (!addOns) return;
    let { doc } = addOns;
    if (!doc) return;
    return (
      <HStack key="details" justifyContent="flex-start" gap={50} width="fit-content">
        <Typography
          style={{
            textAlign: "left",
            fontSize: 16,
            color: ColorX.GetColorCSS("dark"),
          }}
        >
          {doc.logPeriod && "Log Period: " + moment(doc.logPeriod.from).format("DD/MM/YYYY") + " - " + moment(doc.logPeriod.to).format("DD/MM/YYYY")}
        </Typography>
        <Typography
          style={{
            textAlign: "left",
            fontSize: 16,
            color: ColorX.GetColorCSS("dark"),
          }}
        >
          {"Sample Size: " + (doc.sampleSize * 100 + "%")}
        </Typography>
      </HStack>
    );
  }
  render() {
    let { base, serverSidePagination, title, addOns, intents } = this.state;
    let { docID, doc } = this.props;
    let ModaddOns = {
      ...addOns,
      trainID: docID,
      onVerifyToggle: this._onVerifyToggle,
      intents: intents,
      doc,
    };
    return (
      <VStack width="100%">
        <Box width="100%">
          <Typography
            style={{
              textAlign: "left",
              width: "100%",
              fontSize: 25,
              color: ColorX.GetColorCSS("Primary"),
            }}
          >
            {title}
          </Typography>
        </Box>

        {/* Scnerio Name */}
        <Box padding={1} width="100%">
          <VStack alignItems="flex-start">{this.renderAlias()}</VStack>
        </Box>
        <Datumizo base={base} onMounted={this.onMountDatumizo} serverSidePagination={serverSidePagination} refreshOnPageChange={false} addOns={ModaddOns} languageSelector={false} />
      </VStack>
    );
  }
}

export default observer(VerifyTagging);
