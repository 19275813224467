import { AvailLANG } from "../available-lang.interface";

export enum RoleID {
  GENERAL_PUBLIC = "Public",
  HEALTHCARE_PROFESSIONAL = "Professional",
  HEALTHCARE_PROVIDER = "Provider",
}

export interface Answer {
  id: string;
  key: string;
  value: Value;
  doc: string;
}

export interface DBAnswerSpec {
  _id: string;
  _rev?: string;
  name: string;
  domain: string;
  category?: string;
  subcategory?: any;
  CREATED_AT?: string;
  LASTMODIFIED?: string;

  text: Text;
  image?: string;
  quickReplies?: QuickReply[];
  learnMore?: {
    text: Record<AvailLANG, string>;
    url?: Record<AvailLANG, string>;
  };
  rating?: boolean;
  roleSelection?: boolean;
  api?: Record<AvailLANG, AnswerAPIParam>;

  //Not used
  video?: Video;
  table?: Table;
  buttons?: Button[];
  imgbuttons?: ImgButton[];
  templates?: Template[];
  action?: string;
  webnav?: string;
  audio?: Record<AvailLANG, string> | null;
}

export interface AnswerAPIParam {
  url: string;
  parameters: AnswerAPIParameter[];
}

interface AnswerAPIParameter {
  from: string;
  to: string;
}

interface Template {
  title: {
    [key in AvailLANG]?: string;
  };
  subtitle: {
    [key in AvailLANG]?: string;
  };
  video?: {
    [key in AvailLANG]?: {
      src?: string;
      poster?: string;
    };
  };
  image: {
    [key in AvailLANG]?: string;
  };
  text: {
    [key in AvailLANG]?: string;
  };
  buttons: Button[];
}

interface ImgButton {
  image: {
    [key in AvailLANG]?: string;
  };
  title: {
    [key in AvailLANG]?: string;
  };
  payload: string;
  type: string;
}

interface Button {
  title: {
    [key in AvailLANG]?: string;
  };
  payload: string;
  type?: "web" | "phone" | "postback";
  active?: string;
  stype?: string;
}

export interface QuickReply {
  title: {
    [key in AvailLANG]?: string;
  };
  payload: string;
  type?: "web" | "phone" | "postback";
}

interface Table {
  columns: Column[];
  rows: Record<string, string | number>[];
}

interface Column {
  Header: string;
  accessor: string;
}

interface Video {
  src?: {
    [key in AvailLANG]?: string;
  };
  poster?: {
    [key in AvailLANG]?: string;
  };
}

export interface Text {
  EN?: string[];
  TC?: string[];
  SC?: string[];
}

interface Value {
  rev: string;
}
