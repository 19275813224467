import { Button } from "@mui/material";
import { AvailLANG, DBAnswerSpec } from "interfaces";
import axios from "axios";
import { DOMAIN } from "config/config";
import { Authority, ErrorX, store } from "static";
import _ from "lodash";
import { useState } from "react";
import { FieldErrors, FormProvider, useForm } from "react-hook-form";
import { HStack, VStack } from "Components/Layouts";
import { ErrorSummary, TabSelector, TabContents } from "../components";
import { IFLETab, defaultTabs } from "../constants";
import { defaultDoc } from "../constants/defaultDoc";
import { FLEFormContext } from "../hooks/FLEContexts";
import { IFLEDoc } from "interfaces/db.interfaces/fle.interface";
import { Intent, SkillIntent } from "interfaces/db.interfaces/intent.interface";

export interface ExtendedIFLEDoc<T, U> extends IFLEDoc<T, U> {
  generatedQuestions?: Record<AvailLANG, string[]>;
}

interface IFLESetUpProps {
  id: string;
  row?: IFLEDoc<DBAnswerSpec>;
  onQuit: () => void;
  onQuitRefresh: (_id: string) => void;
  renderFormizo: (schema: any, buttons: any, onSubmit: any) => JSX.Element;
  tabs?: IFLETab[];
  mode?: "Add" | "Edit" | "Info";
}

export const FLESetUp: React.FC<IFLESetUpProps> = ({ tabs = defaultTabs, row = defaultDoc, mode = "Add", ...props }) => {
  const [tabIdx, setTabIdx] = useState(0);
  const [errors, setErrors] = useState<FieldErrors<ExtendedIFLEDoc<DBAnswerSpec, Intent>> | null>(null);
  const { id } = props;
  const methods = useForm<ExtendedIFLEDoc<DBAnswerSpec, Intent>>({ defaultValues: row, mode: "all" });

  const tabsInUse = tabs.filter((tab) => {
    if (tab.reqAuth) {
      if (!Authority.IsAccessibleQ(tab.reqAuth, 999, tab.reqFunc ?? "")) return false;
    }

    if (!tab.mode?.include) {
      return true;
    } else {
      if (tab.mode.include.includes(mode)) return true;
      else return false;
    }
  });

  const onValidSubmit = async (row: ExtendedIFLEDoc<DBAnswerSpec, Record<AvailLANG, SkillIntent>>) => {
    try {
      setErrors(null);
      const url = DOMAIN + "/Tables/FLE/" + mode;

      const data = _.cloneDeep(row);
      // merge generated questions into intent examples
      const generatedQuestions = row.generatedQuestions;
      if (generatedQuestions) {
        const intentDoc = row.intentDoc;
        const updatedDocs = Object.fromEntries(
          Object.entries(intentDoc)
            //Filter out non-AvailLANG for handling Intent Generation in Edit
            .filter(([lang, _]) => Object.values(AvailLANG).includes(lang as AvailLANG))
            .map<[AvailLANG, SkillIntent]>(([lang, doc]) => {
              //Avoid generatedQuestions is empty
              const questions = generatedQuestions[lang as AvailLANG] ?? [];
              return [
                lang as AvailLANG,
                {
                  ...doc,
                  examples: _.uniq([...doc.examples, ...questions.map((g: string) => ({ text: g }))]),
                },
              ];
            })
        ) as Record<AvailLANG, SkillIntent>;
        data.intentDoc = {
          ...intentDoc, // Spread the original document to retain all fields
          ...updatedDocs, // Apply updates only to the language-specific fields
        };

        delete data["generatedQuestions"];
      }

      const { data: res } = await axios.post(url, {
        JWT: store.user.JWT,
        data,
      });
      if (!res.Success) return ErrorX.Handle(res.payload);
      props.onQuitRefresh(row._id);
    } catch (e: any) {
      ErrorX.Handle(e);
    }
  };
  const onInValid = (errors: FieldErrors<IFLEDoc<DBAnswerSpec>>) => {
    setErrors(errors);
  };

  const onTabChage = (idx: number) => {
    setErrors(null);
    setTabIdx(idx);
  };

  return (
    <VStack width={"100%"} alignItems={"flex-start"} gap={2} height="100%" justifyContent={"flex-start"}>
      {errors && <ErrorSummary errors={errors} />}
      <HStack justifyContent={"space-between"}>
        {
          <TabSelector
            tabs={tabsInUse}
            tabIdx={tabIdx}
            setTabIdx={(idx) => {
              methods.handleSubmit(() => onTabChage(idx), onInValid)();
            }}
          />
        }
        {mode !== "Info" && (
          <Button
            //
            variant="contained"
            color="secondary"
            onClick={methods.handleSubmit(onValidSubmit, onInValid)}
          >
            Submit
          </Button>
        )}
      </HStack>
      <FormProvider {...methods}>
        <FLEFormContext.Provider value={{ mode, register: methods.register }}>{<TabContents tabs={tabsInUse} tabIdx={tabIdx} id={id} />}</FLEFormContext.Provider>
      </FormProvider>
    </VStack>
  );
};
