import { Component } from "react";

import { observer } from "mobx-react";

import { ExitToAppOutlined } from "@mui/icons-material";
import { Box, IconButton, Typography, Tooltip, Popper } from "@mui/material";
import VersionList from "Components/VersionList";

import "./Container.css";
import { Accessor, ColorX, store } from "static";
import { HStack, Spacer } from "Components/LabIZO/Stackizo";
import { Project } from "config/config";
import { ClassicMenuConfigMode } from "./Menu/Menu.interface";
import { DATA_LINK, backendConnector } from "connectors";

class NavBar extends Component {
  static propTypes = {};

  static defaultProps = {};

  constructor() {
    super();
    this.state = {
      showVersion: false,
      anchorEl: null,
    };
  }

  componentDidMount() {
    this._setAllStates();
  }

  componentDidUpdate(prevProps, prevState) {
    if (!Accessor.IsIdentical(prevProps, this.props, Object.keys(NavBar.defaultProps))) {
      this._setAllStates();
    }
  }

  componentWillUnmount() {
    this.setState = (state, callback) => {
      return;
    };
  }
  onMouseOver = () => {
    this.setState({
      showVersion: true,
      anchorEl: this.cellDiv,
    });
  };
  onmouseout = () => {
    this.setState({
      showVersion: false,
    });
  };

  _setAllStates = (callback) => {
    this.setState(
      (state, props) => ({
        ...props,
      }),
      callback
    );
  };

  _Logout = async () => {
    await backendConnector.post(DATA_LINK.SignOut);
    store.isLoading(true);
    store.clearUser();
    store.clearClassicMenuMode();
    store.isLoading(false);
    store.Alert("Logout Successful", "success");
  };

  render() {
    const bgcolor = store.menuMode === ClassicMenuConfigMode.ML ? ColorX.GetColorCSS("NavBarML") : ColorX.GetColorCSS("NavBar");
    let { showVersion, anchorEl } = this.state;
    let style = {
      color: ColorX.GetColorCSS("Primary"),
      fontSize: 10,
    };
    return (
      <HStack width="100%" height="30px" bgcolor={bgcolor} paddingRight={2} position="fixed" zIndex="300" overflow="hidden" style={{ userSelect: "none" }}>
        <HStack>
          {/* <HStack width="fit-content">
            <Typography style={{ marginLeft: 10, fontFamily: "Palanquin", fontSize: 14, color: ColorX.GetColorCSS("Secondary", 0.5) }}>{IZOVersion}</Typography>
          </HStack> */}
          <HStack width="fit-content">
            <Typography style={{ marginLeft: 30, fontFamily: "Palanquin", fontSize: 14, color: ColorX.GetColorCSS("Secondary", 1) }}>{"Logged in as "}</Typography>
            <Typography style={{ marginLeft: 10, fontFamily: "Palanquin", fontSize: 14, color: ColorX.GetColorCSS("Secondary", 0.8) }}>{store.user && store.user.UserDisplayName}</Typography>
          </HStack>

          <Spacer />

          {/* <Accessizo reqLevel={0} auth={store.user.auth} level={store.user.level}>
            <Typography style={{ fontFamily: "Palanquin", color: ColorX.GetColorCSS("Secondary", 0.5) }}>{"v" + Version}</Typography>
          </Accessizo> */}
          <Box ref={(e) => (this.cellDiv = e)} width="fit-content" marginX={3} onMouseOver={this.onMouseOver} onMouseOut={this.onmouseout}>
            {Project}
          </Box>
          {showVersion && (
            <Popper style={{ zIndex: 999 }} open={showVersion && anchorEl != null} anchorEl={anchorEl}>
              <Box sx={{ background: ColorX.GetColorCSS("Background") }}>{VersionList(style)}</Box>
            </Popper>
          )}
          <Box
            position="absolute"
            sx={{
              width: 70,
              height: 70,
              marginX: 3,
              right: 180,
              top: -20,
              opacity: 0.2,
              backgroundColor: "white",
            }}
            className="syva_logo"
          >
            {/* <img src={NavbarDis && NavbarDis.src} alt="elain" draggable={false} /> */}
          </Box>
          <Tooltip title="Logout" arrow={true} placement="bottom">
            <IconButton style={{ color: ColorX.GetColorCSS("Secondary", 1) }} size="small" onClick={() => this._Logout()}>
              <ExitToAppOutlined />
            </IconButton>
          </Tooltip>
        </HStack>
      </HStack>
    );
  }
}

export default observer(NavBar);
