import { IDocumentManager } from "../../interfaces/document-manager-api.interface";
import { DocUploader } from "Components/Chat/_gears/SlideMenu/doc-uploader";
import { VStack } from "Components/Layouts";
import { FieldErrors, FormProvider, useForm } from "react-hook-form";
import { DocumentUploadFormContext } from "../hooks/upload-hooks";
import { IDocumentUploader } from "Components/_PageComponents/interfaces/document-uploader.interface";
import { ErrorX, store } from "static";
import { backendConnector, DATA_LINK } from "connectors";
interface IProps {
  id: string;
  row?: IDocumentManager;
  onQuit: () => void;
  onQuitRefresh: () => void;
  renderFormizo: (schema: any, buttons: any, onSubmit: any) => JSX.Element;
}
export const DocumentUploadForm = ({ id, row, onQuit, onQuitRefresh, renderFormizo }: IProps) => {
  const methods = useForm<IDocumentUploader>({
    defaultValues: {
      readByPage: false,
      ocr: false,
      summaryInstruction: `以下是客戶服務中心的對話，請整合對話內容，並用 bullet points 撰寫摘要。只需摘要重要內容，不需全部摘要。
然後，跟據對話內容，提取對話的背景資料。
e.g.
1. 客戶姓名: [客戶姓名]
2. 員工姓名: [員工姓名]
3. 客戶查詢: [客戶查詢]
4. 客戶情緒: [客戶情緒]`,
    },
    mode: "all",
  });

  const handleSubmit = (setLoading: (loading: boolean) => void, setAlert: (alert: { message: string; type: "success" | "error" | "info" }) => void) => {
    const submitStatus = {
      valid: async (_newRow: IDocumentUploader) => {
        setLoading(true);
        console.log(`Valid Submit: ${JSON.stringify(_newRow)}`);
        try {
          const { files, alias, fileTypes, ocr, reference, readByPage, languages, summaryInstruction, audioLang } = _newRow;

          for (let i = 0; i < files.length; i++) {
            const file = files[i];
            const fileName = alias[i];
            const fileType = fileTypes[i];

            try {
              const addOns = {
                ocr: ocr,
                filename: fileName.trim(),
                filetype: fileType,
                reference: reference,
                readByPage: readByPage,
                translated_language: languages,
                summaryInstruction: summaryInstruction,
                audio_language: audioLang,
              };
              const { Success, payload } = await backendConnector.upload<any>(DATA_LINK.DMUpload, file, addOns);
              setLoading(false);
              if (!Success) {
                store.Alert(`Upload Failed: ${payload}`, "error");
              }
            } catch (e: any) {
              setLoading(false);
              console.error(e);
              setAlert({ message: JSON.stringify(e?.message ?? e), type: "error" });
            }
          }
          setAlert({ message: "Uploaded", type: "success" });
          onQuitRefresh();
        } catch (err: any) {
          setLoading(false);
          ErrorX.Handle(err);
          store.Alert(`Upload Failed: ${err?.message ?? err}`, "error");
        }
      },
      invalid: (errors: FieldErrors<IDocumentUploader>) => {
        setLoading(false);
        store.Alert(`Upload Failed: ${errors}`, "error");
      },
    };

    methods.handleSubmit(submitStatus.valid, submitStatus.invalid)();
  };

  return (
    <VStack width={"80vh"}>
      <FormProvider {...methods}>
        <DocumentUploadFormContext.Provider value={{ mode: "Upload" }}>
          <DocUploader onUpload={handleSubmit} />
        </DocumentUploadFormContext.Provider>
      </FormProvider>
    </VStack>
  );
};
