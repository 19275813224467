import { Component } from "react";

import _ from "lodash";
import moment from "moment";
import { Typography, Button } from "@mui/material";
import { SaveOutlined, Warning } from "@mui/icons-material";
import schema from "./schema";
import { DATA_LINK, backendConnector } from "connectors";
import ExpandableBox from "Components/ExpandBox/Expandbox";
import Tablizo from "Components/LabIZO/Tablizo";
import { Accessor, ColorX, store, ErrorX, Authority } from "static";
import { HStack, Spacer, VStack } from "Components/LabIZO/Stackizo";
import Dropzone from "./_gears/Dropzone";
import TagForm from "./_gears/TagForm";
import DownloadForm from "./_gears/DownloadForm";
class SysBnR extends Component<any, any> {
  static propTypes = {};

  static defaultProps = {};

  constructor(props: any) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    this._setAllStates(() => {
      this._GetInfo();
    });
  }

  componentDidUpdate(prevProps: any, prevState: any) {
    if (!Accessor.IsIdentical(prevProps, this.props, Object.keys(SysBnR.defaultProps))) {
      this._setAllStates();
    }
  }

  componentWillUnmount() {
    this.setState = (state, callback) => {
      return;
    };
  }

  _setAllStates = (callback?: any) => {
    this.setState(
      (state: any, props: any) => ({
        ...props,
      }),
      callback
    );
  };

  _dateStrToMomemt = (str: any) => {
    return moment(str, "YYYYMMDDHHmmss");
  };

  _momentToDisplay = (obj: any) => {
    return obj.format("DD MMM, YYYY HH:mm:ss");
  };

  _GetInfo = async () => {
    try {
      const res = await backendConnector.post(DATA_LINK.SYSBackupRestore, {});
      let { Success, payload } = res;
      if (Success === true) {
        let { dbs, LastBackup, Backups, BackupDetails, ImportDetails, include, Imports, LastImport } = payload;
        dbs = _.map(dbs, (o, i) => {
          return {
            name: o,
            included: include.includes(o),
          };
        });

        Backups = _.map(Backups, (o, i) => {
          return {
            _id: o,
            name: this._momentToDisplay(this._dateStrToMomemt(o)),
            tag: BackupDetails && BackupDetails[i]?.tag,
            fileloc: BackupDetails && BackupDetails[i]?.fileloc,
          };
        });

        Imports = _.map(Imports, (o, i) => {
          return {
            _id: o,
            name: this._momentToDisplay(this._dateStrToMomemt(o)),
            tag: ImportDetails && ImportDetails[i]?.tag,
            fileloc: ImportDetails && ImportDetails[i]?.fileloc,
          };
        });

        this.setState({
          dbs: dbs,
          LastBackup: LastBackup,
          Backups: Backups,
          LastImport: LastImport,
          Imports: Imports,
          includeDB: include,
        });
      } else {
        store.Alert("Server return error.", "error");
      }
    } catch (e) {
      console.log(e);
      store.Alert("Cannot connect to Server.", "error");
    }
  };

  _Backup = {
    onClick: () => {
      store.Ask("Backup", "Backup System?", this._Backup.onSubmit);
    },
    onSubmit: async () => {
      try {
        const res = await backendConnector.post(DATA_LINK.SYSBackup, {});
        console.log(res);
        let { Success } = res;
        if (Success === true) {
          store.Alert("Backup Successful.", "success");

          this._GetInfo();
        } else {
          this._Backup.onError(res.message);
        }
      } catch (e) {
        this._Backup.onError(e);
      }
    },
    onError: (e: any) => {
      ErrorX.Handle(e);
    },
    onDelete: {
      onClick: (datestr: string) => {
        let mObj = this._dateStrToMomemt(datestr);
        let str = this._momentToDisplay(mObj);
        store.Ask("Delete", "Delete Backup " + str + "?", async () => {
          await this._Backup.onDelete.onSubmit(datestr);
        });
      },
      onSubmit: async (datestr: string) => {
        let mObj = this._dateStrToMomemt(datestr);
        let str = this._momentToDisplay(mObj);

        try {
          const res = await backendConnector.post(DATA_LINK.SYSBackupRestoreDelete, { datestr: datestr });
          let { Success } = res;
          if (Success === true) {
            store.Alert(str + " Successfully Deleted.", "success");
            this._GetInfo();
          } else {
            this._Backup.onDelete.onError(res.message);
          }
        } catch (e) {
          this._Backup.onDelete.onError(e);
        }
      },
      onError: (e: any) => {
        ErrorX.Handle(e);
      },
    },

    onTag: {
      onClick: (row: any) => {
        this.setState({ TagFormRow: row });
        this.setState({ showBackupTagForm: true });
        //ok, proceed
      },
      onClose: () => {
        this.setState({ showBackupTagForm: false });
      },
      onSubmit: async (row: any) => {
        try {
          const res = await backendConnector.post(DATA_LINK.SYSBackupRestoreAddtag, row);
          console.log(res);
          let { Success } = res;
          if (Success === true) {
            store.Alert(row._id + " Tag Successfully Updated.", "success");
            this._GetInfo();
            this._Backup.onTag.onClose();
          } else {
            this._Backup.onTag.onError(res.message);
          }
        } catch (e) {
          this._Backup.onTag.onError(e);
        }
      },
      onError: (payload: any) => {
        let msg = payload.message || "";
        store.Alert(msg, "error");
      },
    },
  };

  _IncToggle = async (dbname: string, f: any) => {
    try {
      const res = await backendConnector.post(DATA_LINK.SYSIncludeToggle, {
        dbname: dbname,
        include: f,
      });
      let { Success } = res;
      if (Success === true) {
        this._GetInfo();
      } else {
        store.Alert("Cannot Update Doc Status.", "error");
      }
    } catch (e: any) {
      ErrorX.Handle(e);
    }
  };

  _Restore = {
    onClick: (datestr: string) => {
      let mObj = this._dateStrToMomemt(datestr);
      let str = this._momentToDisplay(mObj);
      store.Ask("Restore", "Restore System to " + str + "?<br/>The current state of the system will be backup-ed automatically.", async () => {
        await this._Restore.onSubmit(datestr);
      });
    },
    onSubmit: async (datestr: string) => {
      let mObj = this._dateStrToMomemt(datestr);
      let str = this._momentToDisplay(mObj);

      try {
        const res = await backendConnector.post(DATA_LINK.SYSRestore, {
          datestr: datestr,
        });
        let { Success } = res;
        if (Success === true) {
          store.Alert("Restore Successful to \n" + str + ".", "success");
          this._GetInfo();
        } else {
          this._Restore.onError(res.message);
        }
      } catch (e: any) {
        this._Restore.onError(e);
      }
    },
    onError: (e: any) => {
      ErrorX.Handle(e);
    },
  };

  renderBackup() {
    let { LastBackup } = this.state;
    return (
      <HStack justifyContent="flex-start" gap={1}>
        <Button onClick={this._Backup.onClick} style={{ width: "200px", backgroundColor: ColorX.GetColorCSS("Decorate1"), color: ColorX.GetColorCSS("ButtonText1") }} className="backup-btn">
          <HStack gap={5}>
            <SaveOutlined />
            <Typography>Backup</Typography>
          </HStack>
        </Button>
        <Typography>Last Backup:</Typography>
        <Typography style={{ fontWeight: "bold" }}>{LastBackup || "No Backup Available."}</Typography>
      </HStack>
    );
  }

  renderDropZone() {
    return (
      <VStack>
        <Typography>Database File</Typography>
        <Dropzone onDrop={(acceptedFiles) => this.setState({ acceptedDBFiles: acceptedFiles })} />
        <Typography>NLP File</Typography>
        <Dropzone onDrop={(acceptedFiles) => this.setState({ acceptedWatsonFiles: acceptedFiles })} />
      </VStack>
    );
  }

  renderImportOperation() {
    return (
      <HStack height="100%" width="auto">
        {this.renderDropZone()}
        {this.renderImport()}
      </HStack>
    );
  }

  renderImport() {
    let { LastImport } = this.state;

    return (
      <VStack justifyContent="flex-start" gap={1} padding={2}>
        <HStack justifyContent="center" gap={1}>
          <Button onClick={this._Import.onClick} style={{ width: "200px", backgroundColor: ColorX.GetColorCSS("Decorate1"), color: ColorX.GetColorCSS("ButtonText1") }}>
            <HStack gap={5}>
              <Warning style={{ color: ColorX.GetColorCSS("yellow") }} />
              <Typography>Deploy</Typography>
            </HStack>
          </Button>
          <Typography>Last Import:</Typography>
          <Typography style={{ fontWeight: "bold" }}>{LastImport || "No Import Available."}</Typography>
        </HStack>
        {this.renderVersions("import")}
        <Spacer />
      </VStack>
    );
  }

  renderBackupOperations() {
    return (
      <VStack justifyContent="flex-start" gap={1} padding={2}>
        {this.renderBackup()}
        {this.renderVersions("backup")}
        <Spacer />
      </VStack>
    );
  }

  renderDatabases() {
    let { dbs } = this.state;

    const renderComonent = (
      <VStack paddingY={2}>
        <Tablizo
          width={400}
          height="100%"
          density="compact"
          auth={store.user.authority}
          level={store.user.level}
          rowIdAccessor="name"
          schema={schema.database}
          showSelector={false}
          data={dbs}
          addOns={{
            onToggle: this._IncToggle,
          }}
        />
      </VStack>
    );
    return (
      <VStack height="100%">
        <ExpandableBox component={renderComonent} />;
      </VStack>
    );
  }

  /**
   *
   * @param {string} type "backup" || "import"

   */
  renderVersions(type: "backup" | "import") {
    let { Backups, Imports } = this.state;
    let data = Backups;
    if (type === "import") data = Imports;

    if (!data) return <></>;

    return (
      <Tablizo
        width={500}
        height="100%"
        density="compact"
        auth={store.user.authority}
        level={store.user.level}
        schema={schema.restore}
        showSelector={false}
        data={data}
        addOns={{
          Restore: type === "backup" ? this._Restore.onClick : undefined,
          Delete: type === "backup" ? this._Backup.onDelete.onClick : this._Import.onDelete.onClick,
          Download: type === "backup" ? this._Download.onClick : undefined,
          Tag: type === "backup" ? this._Backup.onTag.onClick : this._Import.onTag.onClick,
        }}
      />
    );
  }

  _Import = {
    onClick: (datestr: any) => {
      const { acceptedDBFiles, acceptedWatsonFiles } = this.state;
      //no file in the dropzone
      if (!acceptedDBFiles && !acceptedWatsonFiles) {
        store.Alert("No files to import", "error");
        store.clearAsk();
        return;
      }
      //more than 1 file
      if (acceptedDBFiles?.length > 1 || acceptedWatsonFiles?.length > 1) {
        store.Alert("Please upload only 1 .gz file at a time.", "error");
        store.clearAsk();
        return;
      }
      //ok, proceed
      store.Ask("Import", "Import and Deploy System?.", async () => {
        await this._Import.onSubmit();
      });
    },

    onSubmit: async () => {
      const { acceptedDBFiles, acceptedWatsonFiles } = this.state;

      store.SetAskLoading(true);
      console.log("submit _Import");

      try {
        let datetimeCurr = moment().format("YYYYMMDDHHmmss");
        if (acceptedDBFiles) {
          const res = await backendConnector.upload(DATA_LINK.SYSDBImport, acceptedDBFiles[0], { datetime: datetimeCurr });
          if (!res.Success) throw new Error(res.payload);
          this._Import.onSuccess(res.payload);
        }
        if (acceptedWatsonFiles) {
          const res = await backendConnector.upload(DATA_LINK.SYSWatsonImport, acceptedWatsonFiles[0], { datetime: datetimeCurr });
          if (!res.Success) throw new Error(res.payload);
          this._Import.onSuccess(res.payload);
        }
      } catch (e) {
        this._Import.onFail(e);
      }
    },

    onSuccess: (payload: any) => {
      if (!_.isEmpty(payload.error)) {
        let msg = _.map(payload.error, (o, i) => "ID (" + o.id + "): " + o.error);
        store.Alert("_Import Successfully with warning: \n" + msg.join("\n"), "warning");
      } else {
        store.Alert("_Import Successfully.", "success");
      }
      store.clearAsk();
      this._GetInfo();
    },

    onFail: (payload: any) => {
      let msg = payload.message || "";
      store.Alert(msg, "error");
      store.clearAsk();
    },

    onDelete: {
      onClick: (datestr: string) => {
        let mObj = this._dateStrToMomemt(datestr);
        let str = this._momentToDisplay(mObj);
        store.Ask("Delete", "Delete Backup " + str + "?", async () => {
          await this._Import.onDelete.onSubmit(datestr);
        });
      },
      onSubmit: async (datestr: string) => {
        let mObj = this._dateStrToMomemt(datestr);
        let str = this._momentToDisplay(mObj);

        try {
          const res = await backendConnector.post(DATA_LINK.SYSBackupRestoreDelete, {
            datestr: datestr,
            dir: "import",
          });
          let { Success } = res;
          if (Success === true) {
            store.Alert(str + " Successfully Deleted.", "success");
            this._GetInfo();
          } else {
            this._Import.onDelete.onError(res.message); // TODO
          }
        } catch (e) {
          this._Import.onDelete.onError(e); //TODO
        }
      },
      onError: (e: any) => {
        ErrorX.Handle(e);
      },
    },

    onTag: {
      onClick: (row: any) => {
        this.setState({ TagFormRow: row });
        this.setState({ showImportTagForm: true });
      },
      onClose: () => {
        this.setState({ showImportTagForm: false });
      },
      onSubmit: async (row: any) => {
        try {
          const res = await backendConnector.post(DATA_LINK.SYSBackupRestoreAddtag, { ...row, dir: "import" });
          let { Success } = res;
          if (Success === true) {
            store.Alert(row._id + " Tag Successfully Updated.", "success");
            this._GetInfo();
            this._Import.onTag.onClose();
          } else {
            this._Import.onTag.onError(res.message);
          }
        } catch (e) {
          this._Import.onTag.onError(e);
        }
      },
      onError: (e: any) => {
        ErrorX.Handle(e);
      },
    },
  };

  _Download = {
    onClick: (fileloc: { db?: string; watsons?: string }) => {
      this.setState({ fileloc });
      this.setState({ showDownloadForm: true });
    },
  };

  render() {
    const { reqFunc } = schema;
    return (
      <HStack justifyContent="flex-start" height="100%" width="100%" gap={1} paddingX={2}>
        {Authority.RenderComponentAuthCheck(this.renderDatabases(), { reqAuth: "System.BnR", reqFunc: reqFunc.Edit })}
        {Authority.RenderComponentAuthCheck(this.renderBackupOperations(), { reqAuth: "System.BnR", reqFunc: reqFunc.Backup })}
        {Authority.RenderComponentAuthCheck(this.renderImportOperation(), { reqAuth: "System.BnR", reqFunc: reqFunc.Import })}
        <TagForm
          //
          title={"Backup Tag"}
          open={this.state.showBackupTagForm}
          onClose={this._Backup.onTag.onClose}
          onSubmit={this._Backup.onTag.onSubmit}
          row={this.state.TagFormRow}
        />
        <TagForm
          //
          title={"Import Tag"}
          open={this.state.showImportTagForm}
          onClose={this._Import.onTag.onClose}
          onSubmit={this._Import.onTag.onSubmit}
          row={this.state.TagFormRow}
        />

        {this.state.showDownloadForm && <DownloadForm open={this.state.showDownloadForm} onClose={() => this.setState({ showDownloadForm: false })} fileloc={this.state.fileloc} />}
      </HStack>
    );
  }
}

export default SysBnR;
