export enum ImagePath {
  contactUs = "Images/Sticker/contact-us-t.png",
  conversation = "Images/Sticker/conversation-t.png",
  dataAccessRequest = "Images/Sticker/data-access-request-t.png",
  dataCorrectionRequest = "Images/Sticker/data-correction-request-t.png",
  doctorSearch = "Images/Sticker/doctor-search-t.png",
  downloadForMobile = "Images/Sticker/download-for-mobile-t.png",
  fallBack = "Images/Sticker/fall-back-t.png",
  forbidden = "Images/Sticker/forbidden-list-t.png",
  foulLanguage = "Images/Sticker/foul-language-t.png",
  identityHcp = "Images/Sticker/identity-hcp-t.png",
  identityHcprof2 = "Images/Sticker/identity-hcprof2-t.png",
  identityHcprof = "Images/Sticker/identity-hcprof-t.png",
  identityHcr = "Images/Sticker/identity-hcr-t.png",
  mobileEhealthForGreeting = "Images/Sticker/mobile-ehealth-app-t.png",
  successfulRegist = "Images/Sticker/successful-register-t.png",
}

export enum UnderMaintenanceImagePath {
  EN = "Images/Sticker/under-maintenance-en.png",
  TC = "Images/Sticker/under-maintenance-tc.png",
  SC = "Images/Sticker/under-maintenance-sc.png",
}
