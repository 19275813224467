import { SelectorValue, DefaultFilterType } from "../interfaces/Condition.interface";
import { parseCondition } from "./parseCondition";

export type IDefaultFilter = Record<string, Record<string, Record<string, SelectorValue>>[]>;

export const parseDefaultFilter = (defaultFilter: DefaultFilterType): IDefaultFilter => {
  const selector: Record<string, Record<string, Record<string, SelectorValue>>[]> = {};
  selector[defaultFilter.logicalOperator as string] = parseCondition(defaultFilter.conditions);
  return selector;
};
