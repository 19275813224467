import { NativeSelect, TextField } from "@mui/material";
import moment from "moment";
import React from "react";
import { Control, Controller, FieldPath, useWatch, UseFormResetField } from "react-hook-form";
import NumberFormat from "react-number-format";
import { FieldType } from "../interfaces/Filterable.interface";
import { FilterValueFieldProps } from "../interfaces/FilterValue.interface";
import { Filterable } from "../interfaces/Filterable.interface";
import { Property } from "csstype";
import { FilterFormProps } from "../interfaces/Condition.interface";
interface FilterRowProps {
  filterables: Filterable[];
  control: Control<any, any>;
  name: string;
  width?: Property.Width;
  resetField?: UseFormResetField<FilterFormProps>;
}

const ControllerPlus = <TInput extends string, TOutput>({
  control,
  transform,
  name,
  defaultValue,
  type,
  variant = "standard",
}: {
  transform: {
    input: (value: TOutput) => TInput;
    output: (value: React.ChangeEvent<HTMLInputElement | HTMLInputElement | HTMLTextAreaElement>) => TOutput;
  };
  name: FieldPath<any>;
  control: Control<any>;
  defaultValue?: any;
  type?: React.InputHTMLAttributes<unknown>["type"];
  variant?: "standard" | "filled" | "outlined";
}) => {
  return (
    <Controller
      defaultValue={defaultValue}
      control={control}
      name={name}
      render={({ field, fieldState }) => (
        <TextField {...field} variant={variant} type={type} error={Boolean(fieldState?.error)} onChange={(e) => field.onChange(transform.output(e))} value={transform.input(field.value)} />
      )}
    />
  );
};

export const FilterValue = ({ filterables, control, name, width = "100px" }: FilterRowProps) => {
  // detect type for corresponding filter field
  const filterField = useWatch({
    control,
    name: `${name}.field`,
  });
  const filterOperatorValue = useWatch({
    control,
    name: `${name}.operator`,
  });

  const fieldType = filterables.find((f) => f.field === filterField);
  const CustomFilterValue = fieldType?.CustomFilterValue;
  let FilterValueField: React.FC<FilterValueFieldProps>;
  if (CustomFilterValue) {
    // use the custome filter value component if exist
    FilterValueField = ({ field, fieldState }: FilterValueFieldProps) => <CustomFilterValue field={field} width={width} fieldState={fieldState} />;
  } else {
    switch (fieldType?.type) {
      case FieldType.text:
        FilterValueField = ({ field, fieldState }: FilterValueFieldProps) => <TextField sx={{ width }} variant="standard" error={Boolean(fieldState?.error)} {...field} />;
        break;
      case FieldType.number:
        FilterValueField = ({ field, fieldState }: FilterValueFieldProps) => (
          <NumberFormat
            sx={{ width }}
            customInput={TextField}
            error={Boolean(fieldState?.error)}
            variant="standard"
            onValueChange={({ floatValue }) => field?.onChange(floatValue)}
            value={field?.value}
          />
        );
        break;
      case FieldType.date:
        FilterValueField = ({ field, fieldState }: FilterValueFieldProps) => <TextField sx={{ width }} variant="standard" type="date" error={Boolean(fieldState?.error)} {...field} />;
        break;
      case FieldType.datetime:
        // FilterValueField = ({ field }: FilterValueFieldProps) => <TextField sx={{ width }} variant="standard" type="datetime-local" {...field} />;
        FilterValueField = ({ field, fieldState }: FilterValueFieldProps) => (
          <ControllerPlus<any, any>
            transform={{
              input: (value) => {
                // return value && new Date(value).toISOString().substring(0, 19);
                return value && moment(value).format("YYYY-MM-DDTHH:mm:ss");
              },
              output: (e) => {
                return moment(e.target.value).toISOString();
                // console.log(date.toISOString().substring(0, 19));
                // return date.toISOString().substring(0, 19);
              },
            }}
            control={control}
            name={field?.name!}
            type="datetime-local"
          />
        );
        break;
      case FieldType.boolean:
        FilterValueField = ({ field, fieldState }: FilterValueFieldProps) => (
          <NativeSelect {...field} sx={{ width }} error={Boolean(fieldState?.error)}>
            <option value={""}></option>
            <option value={"true"}>true</option>
            <option value={"false"}>false</option>
          </NativeSelect>
        );
        break;
      default:
        FilterValueField = ({ field, fieldState }: FilterValueFieldProps) => (
          <TextField sx={{ width }} variant="standard" error={Boolean(fieldState?.error)} {...field} disabled={filterOperatorValue == "$notExists"} />
        );
        break;
    }
  }

  return (
    <Controller
      //
      control={control}
      name={`${name}.value`}
      rules={{ required: true }}
      render={({ field, fieldState }) => <FilterValueField field={field} fieldState={fieldState} />}
    />
  );
};
