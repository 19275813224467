import { Alert } from "@mui/material";
import { FieldErrors } from "react-hook-form";
import { IExampleDetails } from "../Interface/TopicClusterDetail.interface";

export function ErrorSummary({ errors }: { errors: FieldErrors<IExampleDetails> }) {
  return (
    <>
      {errors.examples && <Alert severity="error">{"Example is required"}</Alert>}
      {errors._id && <Alert severity="error">{"Settings are incomplete on Context Page."}</Alert>}
    </>
  );
}
