import React, { createContext, useContext } from "react";
import { ITalk2VAAddOns } from "../interfaces/combined-talk2va-addons.interface";

interface IAddOnsContext {
  addOns: ITalk2VAAddOns;
  updateAddOns: (newAddOns: Partial<ITalk2VAAddOns>) => void;
}

export const AddOnsContext = createContext<IAddOnsContext | undefined>(undefined);

export const useAddOns = () => {
  const context = useContext(AddOnsContext);
  if (!context) {
    throw new Error("useAddOns must be used within a AddOnsProvider");
  }
  return context;
};
