import { Person, Group, Workspaces } from "@mui/icons-material";
import SysUser from "./SysUser/SysUser";
import SysRole from "./SysRole/SysRole";
import SysInstance from "./SysInstace/SysInstance";

export interface UserControlTab {
  label: string;
  icon: React.ReactElement;
  reqAuth?: string;
  reqLevel?: number;
  reqFunc?: string;

  render: React.ReactElement;
  iconPos: "top" | "bottom" | "left" | "right";
  noTransform?: boolean;
  alignment?: "left" | "right";
  spacing?: number;
  disabled?: boolean;
  minHeight?: string;
  minWidth?: string;
}

export const tabs: UserControlTab[] = [
  {
    label: "User Access Control",
    icon: <Person />,
    reqAuth: "UserControl.User",
    render: <SysUser />,
    iconPos: "left",
    noTransform: true,
    alignment: "left",
  },
  {
    label: "Role Based Access Control",
    icon: <Group />,
    reqAuth: "UserControl.Role",
    render: <SysRole />,
    iconPos: "left",
    noTransform: true,
    alignment: "left",
  },
  // {
  //   label: "Instance Access Control",
  //   icon: <Workspaces />,
  //   reqAuth: "UserControl.Instance",
  //   render: <SysInstance />,
  //   iconPos: "left",
  //   noTransform: true,
  //   alignment: "left",
  // },
  // {
  //   label: "Instace Access Control",
  //   icon: <Workspaces />,
  //   reqAuth: "UserControl.Instance",
  //   render: <SysInstance />,
  //   iconPos: "left",
  //   noTransform: true,
  //   alignment: "left",
  // },
];
