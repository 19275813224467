import { Paper, styled, Popper, ClickAwayListener, Box, Menu, IconButton } from "@mui/material";
import _ from "lodash";
import MenuIcon from "@mui/icons-material/Menu";
import { useState } from "react";
import { store } from "static";

interface IProps {
  cssp?: string;
  onClearHistory?: () => void;
}

const StyledPaperContainer = styled(Box)(({ theme }) => ({
  backgroundColor: "#FFFFFF",
  border: "1px solid #E0E0E0",
  borderRadius: "8px",
  boxShadow: "0px 2px 8px rgba(0, 0, 0, 0.15)",
  width: "180px",
  padding: "4px 0",
  marginRight: "8px",
}));

const StyledMenuItem = styled(Box)(({ theme }) => ({
  cursor: "pointer",
  padding: "8px 16px",
  color: "#0079ff",
  fontSize: "14px",
  "&:hover": {
    backgroundColor: "#F5F5F5",
  },
  "&:not(:last-child)": {
    borderBottom: "1px solid #E0E0E0",
  },
}));

enum reloadMessage {
  EN = "Reload",
  TC = "重新整理",
  SC = "重新整理",
}

const ZCMenu = ({ cssp, onClearHistory }: IProps) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const changeDefaultLang = (lang: string) => {
    store.setLang(lang);
  };

  const renderLangSetting = () => {
    let rendered = [];
    if (store.chatbotLang !== "TC") {
      rendered.push(
        <StyledMenuItem
          key="TC"
          onClick={() => {
            changeDefaultLang("TC");
            onClearHistory && onClearHistory();
            handleMenuClose();
          }}
        >
          繁體中文
        </StyledMenuItem>
      );
    }
    if (store.chatbotLang !== "EN") {
      rendered.push(
        <StyledMenuItem
          key="EN"
          onClick={() => {
            changeDefaultLang("EN");
            onClearHistory && onClearHistory();
            handleMenuClose();
          }}
        >
          English
        </StyledMenuItem>
      );
    }
    if (store.chatbotLang !== "SC") {
      rendered.push(
        <StyledMenuItem
          key="SC"
          onClick={() => {
            changeDefaultLang("SC");
            onClearHistory && onClearHistory();
            handleMenuClose();
          }}
        >
          简体中文
        </StyledMenuItem>
      );
    }
    return rendered;
  };

  const renderMenuItems = () => {
    return (
      <StyledPaperContainer>
        {renderLangSetting()}
        <StyledMenuItem
          onClick={() => {
            onClearHistory && onClearHistory();
            handleMenuClose();
          }}
        >
          {reloadMessage[store.chatbotLang as keyof typeof reloadMessage]}
        </StyledMenuItem>
      </StyledPaperContainer>
    );
  };

  return (
    <Box className={cssp}>
      <IconButton aria-label="menu" onClick={handleMenuOpen} className="settingButton">
        <MenuIcon />
      </IconButton>
      <Popper
        open={open}
        anchorEl={anchorEl}
        placement="top-end"
        disablePortal={false}
        modifiers={[
          {
            name: "offset",
            options: {
              offset: [0, 10],
            },
          },
        ]}
      >
        <ClickAwayListener onClickAway={handleMenuClose}>{renderMenuItems()}</ClickAwayListener>
      </Popper>
    </Box>
  );
};

export default ZCMenu;
