import _ from "lodash";
import { Typography } from "@mui/material";
import { Cancel, CheckCircle, Help } from "@mui/icons-material";

import { ColorX } from "static";
import { HStack } from "Components/LabIZO/Stackizo";

const Summary = ({ data }) => {
  let pending = 0;
  let correct = 0;
  let inCorrect = 0;

  if (data && data.length > 0) {
    pending = _.filter(data, (o) => o && !o.Before.Tag.tagged && !o.Before.Tag.passed).length;
    correct = _.filter(data, (o) => o && o.Before.Tag.passed).length;
    inCorrect = _.filter(data, (o) => o && !o.Before.Tag.passed && o.Before.Tag.tagged).length;
  }

  return (
    <HStack gap={1}>
      <Help style={{ color: ColorX.GetColorCSS("Caution") }} />
      <Typography key="pending">{pending}</Typography>
      <CheckCircle style={{ color: ColorX.GetColorCSS("Approval") }} />
      <Typography key="correct">{correct}</Typography>
      <Cancel style={{ color: ColorX.GetColorCSS("Cancel") }} />
      <Typography key="incorrect">{inCorrect}</Typography>
    </HStack>
  );
};
export default Summary;
