export enum IWebsiteStatus {
  INDEXING = "indexing",
  INDEXED = "indexed",
  DELETING = "deleting",
  EDITING = "editing",
  ERROR = "error",
  DISABLED = "disabled",
  SYNCING = "syncing",
  ENABLING = "enabling",
  DISABLING = "disabling",
}

export enum IWebsiteURLType {
  EXPLICIT = "explicit",
  IMPLICIT = "*",
}

interface IWebsiteURL {
  type: IWebsiteURLType;
  url: string;
}

export interface IWebsiteManagerPayload {
  _id: string;
  alias: string;
  enabled: boolean;
  urlSetting: IWebsiteURL;
  status: IWebsiteStatus;
  CREATED_AT: string;
  LASTMODIFIED: string;
  notice?: boolean;
  error_message?: string;
}
