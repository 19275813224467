export interface IDocumentManager {
  _id: string;
  alias: string;
  enabled: boolean;
  status: DocumentManagerStatus;
  text_file?: {
    available: boolean;
    name: string;
    location: string;
  };
  original_file?: {
    available: boolean;
    name: string;
    location: string;
  };
  reference: {
    link: string;
    title: string;
    target: "blank" | "self";
  };
  knowledge_grps?: IDMKnowledeGroup[];
  documentLink?: IDocumentLink;
}

export enum DocumentManagerStatus {
  Preparing = "preparing",
  Ready = "ready",
  Error = "error",
}

export interface IDMKnowledeGroup {
  _id: string;
  alias: string;
  enabled: boolean;
}

export enum RelationType {
  Translate = "translate",
  ReadByPage = "read_by_page",
  Audio = "audio",
}

interface IDocumentLinkBase {
  type: RelationType;
  parentId: string;
}

interface ITranslateDocumentLink extends IDocumentLinkBase {
  type: RelationType.Translate;
  lang: string;
}

interface IReadByPageDocumentLink extends IDocumentLinkBase {
  type: RelationType.ReadByPage;
}

interface IAudioDocumentLink extends IDocumentLinkBase {
  type: RelationType.Audio;
  lang?: string;
  prompt?: string;
}

type IDocumentLink = ITranslateDocumentLink | IReadByPageDocumentLink | IAudioDocumentLink;
