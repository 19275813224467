import { Box, SxProps } from "@mui/material";
import { Component } from "react";
import "./syva_logo.css";
import { SYS_ICON, SYS_ICON_CSS_CLASS } from "constants/system-name";
import "Components/syva-logo/syva_logo.css";

export class SyvaLogo extends Component<{ sx?: SxProps }> {
  render() {
    let sx = this.props.sx;
    let className = "cms-logo";

    if (SYS_ICON_CSS_CLASS === "custom_logo") {
      sx = {
        ...sx,
        background: `url(${SYS_ICON}) no-repeat center/contain`,
        backgrounColor: "transparent",
      };
    } else {
      sx = {
        ...sx,
        backgroundColor: "primary.main",
        // mask: `url('./logo_syva_black.svg') no-repeat center/contain`,
      };
      className = "syva_logo";
    }

    return <Box width="100%" p={2} sx={sx} className={className} />;
  }
}
