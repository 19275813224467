import { Typography, Button, Alert } from "@mui/material";
import Dropzone from "./Dropzone";
import { useEffect, useState } from "react";
import { VStack } from "Components";
import { FileUpload } from "@mui/icons-material";
import { Box } from "@mui/system";
import { Loading } from "Components/Loading";
import { IDocumentUploader } from "Components/_PageComponents/interfaces/document-uploader.interface";
import { useFormContext, useWatch } from "react-hook-form";
import { DocUploaderDetails } from "./doc-detail";
interface IProps {
  onUpload: (setLoading: (loading: boolean) => void, setAlert: (alert: { message: string; type: "success" | "error" | "info" }) => void) => void;
  reupload?: boolean;
  renderWarningMessage?: () => JSX.Element;
}

export const DocUploader = ({ onUpload, reupload, renderWarningMessage }: IProps) => {
  const { control, getValues, setValue } = useFormContext<IDocumentUploader>();
  const [alert, setAlert] = useState<{ message: string; type: "success" | "error" | "info" }>();
  const [loading, setLoading] = useState<boolean>(false);
  const files = useWatch({ name: "files", control });

  useEffect(() => {}, [files]);

  const onDropError = (errorMsg: string) => {
    setAlert({ message: "Please check the file type", type: "error" });
  };

  const onDropFiles = (acceptedFiles: File[]) => {
    const fileNames = acceptedFiles.map((file) => file.name.split(".").slice(0, -1).join("."));
    const fileTypes = acceptedFiles.map((file) => file.name.split(".").pop() || "");

    setValue("files", acceptedFiles);
    setValue("alias", fileNames);
    setValue("fileTypes", fileTypes);
  };

  const renderReminder = () => {
    return (
      <VStack width={"100%"} height="100%">
        <Typography fontSize={"0.8rem"} color={"grey"} fontStyle={"italic"}>
          *** Recommended Document file size is less than 10MB ***
        </Typography>
        <Typography fontSize={"0.8rem"} color={"grey"} fontStyle={"italic"}>
          *** Recommended Audio File size is less than 20MB and less than 2 minutes length ***
        </Typography>
      </VStack>
    );
  };

  return (
    <VStack width={"100%"} height="100%">
      <Loading open={loading} />
      <Typography fontWeight={"bold"}>{reupload ? "Reupload" : "Upload"} a document to begin</Typography>
      <Dropzone multiple={reupload ? false : true} accept={[".pdf", ".txt", ".docx", ".mp3", ".wav"]} onDrop={onDropFiles} onError={onDropError} />
      {renderReminder()}
      {getValues("files")?.length > 0 && <DocUploaderDetails reupload={reupload} />}
      {renderWarningMessage && renderWarningMessage()}
      {alert && (
        <Alert sx={{ width: "100%" }} severity={alert.type}>
          {alert.message}
        </Alert>
      )}
      <Box width={"100%"} sx={{ display: "flex", justifyContent: "flex-end" }}>
        <Button
          disabled={getValues("files")?.length === 0}
          variant={"outlined"}
          onClick={() => {
            onUpload(setLoading, setAlert);
          }}
          startIcon={<FileUpload />}
        >
          Upload
        </Button>
      </Box>
    </VStack>
  );
};
