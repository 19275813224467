import { HStack, VStack } from "Components/LabIZO/Stackizo";
import _ from "lodash";
import { ArrowBack, ZoomIn } from "@mui/icons-material";
import { useEffect, useState } from "react";
import { Button, Modal, Typography } from "@mui/material";
import { Box, styled } from "@mui/system";
import { DATA_LINK, backendConnector } from "connectors";
import { GPTConvlogDetail } from "Pages/gpt-qa/convlog/ConvlogDetail/GPTConvlogDetail";
import { IGPTConvLog } from "interfaces/db.interfaces/gpt-qa-conv-log.interface";
import { Authority } from "static";
import { CusAuth } from "interfaces";

const CustomBox = styled(Box)({
  position: "absolute",
  top: "50%",
  left: "50%",
  width: "95%",
  height: "90%",
  transform: "translate(-50%, -50%)",
  border: "2px solid #000",
  backgroundColor: "white",
  zIndex: 10,
});

interface IGPTConvlogDetailProps {
  id: string;
  period: { DateRange: [string, string] };
}

export function GPTDetail({ id, period }: IGPTConvlogDetailProps) {
  const [open, setOpen] = useState(false);
  const [data, setData] = useState<IGPTConvLog>();

  useEffect(() => {
    getGPTDetail();
  }, [id]);

  const getGPTDetail = async () => {
    const res = await backendConnector.post(
      DATA_LINK.GPTConvList,
      {
        selector: {
          $and: [
            {
              _id: {
                $eq: id,
              },
            },
          ],
        },
      },
      period
    );
    if (res.payload.docs.length == 1) {
      setData(res.payload.docs[0]);
    }
  };
  const onClickFunction = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const renderPopUpWindow = () => {
    return (
      <CustomBox>
        <VStack width="100%" height="100%" sx={{ overflow: "auto" }}>
          <HStack sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", width: "100%" }}>
            <Typography variant="h5" sx={{ padding: "0px 25px", color: "primary.main" }}>
              GPT CONVERSATION DETAILS
            </Typography>
            <Button sx={{ paddingRight: "25px" }} onClick={handleClose}>
              <ArrowBack />
            </Button>
          </HStack>
          {data && <GPTConvlogDetail doc={data} id={data._id} period={period} />}
        </VStack>
      </CustomBox>
    );
  };
  const accessible = Authority.IsAccessibleQ(`Convlog`, 999, CusAuth.GPTDetail);
  if (!data || !accessible) return null;
  return (
    <VStack>
      <ZoomIn onClick={onClickFunction} />
      {open && renderPopUpWindow()}
    </VStack>
  );
}
