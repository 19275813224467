import { IDocumentManager } from "../../interfaces/document-manager-api.interface";
import FormMDEditor from "Components/FormMDEditor/FormMDEditor";
import { HStack, VStack } from "Components/Layouts";
import { useFormContext } from "react-hook-form";
import { useState } from "react";
import { EmbeddingsViewer } from "./EmbeddingsViewer";
import { FormControlLabel, Grid, SelectChangeEvent, Switch } from "@mui/material";
import { useText } from "../hooks/useText";
import { useProcessedText } from "../hooks/useProcessedText";
import { GoogleTranslateSupportedLang } from "./constants/supported-lang";
import _ from "lodash";
import { useTranslation as useTranslateText } from "../hooks/useTranslation";

export interface IEmbeddingObject {
  text: string[];
  embeddings: number[][];
  filename: string[];
  metadata: any[];
}

export const ContextEditors = ({ mode }: { mode: "Add" | "Edit" | "Info" }) => {
  const { setValue, getValues, control } = useFormContext<IDocumentManager>();
  const [showProcessedText, setShowProcessedText] = useState(true);
  const [showEditor, setShowEditor] = useState(true);
  const [translateOption, setTranslateOption] = useState({
    enabled: false,
    targetLanguage: GoogleTranslateSupportedLang.English,
  });

  const docId = getValues("_id");
  const { text, setText } = useText(docId);
  const { processedText } = useProcessedText(text || "", [text]);
  const { translatedText } = useTranslateText(processedText, translateOption.targetLanguage, [processedText, translateOption]);

  const onChangeContext = (_newContext: string | undefined) => {
    setText(_newContext);
    setValue("context", { text: _newContext });
  };
  const handleChangeShowPPText = (event: React.ChangeEvent<HTMLInputElement>) => {
    setShowProcessedText(event.target.checked);
  };
  const handleChangeShowEditor = (event: React.ChangeEvent<HTMLInputElement>) => {
    setShowEditor(event.target.checked);
  };

  const handleTranslateEnabled = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const checked = event.target.checked;
    setTranslateOption({ ...translateOption, enabled: checked });
  };

  const handleLanguageChange = async (e: SelectChangeEvent<string>) => {
    const targetLanguage = e.target.value as GoogleTranslateSupportedLang;
    setTranslateOption({ ...translateOption, targetLanguage: targetLanguage });
  };

  return (
    <VStack width={"80vw"} alignItems="flex-start">
      <HStack alignItems="center">
        <FormControlLabel
          //
          control={<Switch checked={showEditor} onChange={handleChangeShowEditor} />}
          label="Show Editor"
          labelPlacement="start"
        />
        <FormControlLabel
          //
          control={<Switch checked={showProcessedText} onChange={handleChangeShowPPText} />}
          label="Show Processed Text"
          labelPlacement="start"
        />
        {/* <Accessizo key={"translate-btn"} reqAuth={"GPTQA.DocumentManager"} reqFunc={CusAuth.Translate} reqLevel={0} auth={store.user.authority} level={store.user.level}>
          <FormControlLabel
            //
            control={<Switch checked={translateOption.enabled} onChange={handleTranslateEnabled} />}
            label={<Translate sx={{ spacing: 2 }} />}
            labelPlacement="start"
          />
        </Accessizo>

        <Select sx={{ visibility: translateOption.enabled ? "visible" : "hidden" }} value={translateOption.targetLanguage} onChange={handleLanguageChange}>
          {_.map(GoogleTranslateSupportedLang, (_val, _name) => {
            return (
              <MenuItem key={_val} value={_val}>
                {_name}
              </MenuItem>
            );
          })}
        </Select> */}
      </HStack>

      <Grid
        container
        spacing={1}
        sx={{
          width: "100%",
          alignItems: "flex-start",
          height: "80vh",
          marginTop: "10px",
        }}
      >
        {showEditor && (
          <Grid item className="md-container" sx={{ width: "100%", height: "100%" }} md={showProcessedText ? 6 : 12}>
            <FormMDEditor value={text} onChange={onChangeContext} allowEdit={mode === "Info"} />
          </Grid>
        )}

        {showProcessedText && (
          <Grid item className="embedding-container" sx={{ width: "100%", height: "100%" }} md={showEditor ? 6 : 12}>
            {processedText && <EmbeddingsViewer processedText={translateOption.enabled ? translatedText : processedText} />}
          </Grid>
        )}
      </Grid>
    </VStack>
  );
};
