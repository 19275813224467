import { Box, Typography } from "@mui/material";
import Datumizo from "Components/LabIZO/Datumizo/Datumizo";
import { HStack, VStack } from "Components/LabIZO/Stackizo";
import { Accessor, Authority, ColorX, store } from "static";
import moment from "moment";
import { Component } from "react";
import schema from "./schema";
import { GPTConvlogDetail } from "./ConvlogDetail/GPTConvlogDetail";
import { DateTimeRangePicker } from "Components/DatePicker/DateTimeRangePicker";
import { DATA_LINK } from "connectors";

class GPTConvlog extends Component {
  static propTypes = {};

  static defaultProps = {};

  constructor() {
    super();
    this.state = {
      title: "CHATGPT CONVERSATION OVERVIEW",
      period: { DateRange: [moment().startOf("month"), moment().endOf("month")] },
      serverSidePagination: true,
      base: {
        title: "Convlog",
        exportDoc: "GPTQA_Conversation_Log",
        schema: schema,
        reqAuth: "GPTQA.ConversationLog",
        columnAuth: "GPTConvlog",

        tablizo: {
          showSelector: true,
          columnsToolbar: true,
          filterToolbar: true,
          densityToolbar: true,
          exportToolbar: false,
        },

        Connect: {
          DBInfo: DATA_LINK.GPTConvDBInfo,
          List: DATA_LINK.GPTConvList,
          SetColumnVisibilitySetting: DATA_LINK.GPTConvSetColumnVisibilitySetting,
          schema: schema.Table,
        },

        operations: {
          Export: {
            url: DATA_LINK.GPTConvExport,
            schema: schema.Export,
          },

          Info: {
            title: "CONVERSATION DETAILS",
            success: "Convlog Load Successfully",
            fail: "Convlog Load Failed: ",
            schema: schema.Info,
            readOnly: true,
            Custom: (id, doc, onQuit, onQuitRefresh, renderFormizo, addOns) => {
              return <GPTConvlogDetail id={id} doc={doc} period={this.state.period} />;
            },
          },
        },

        buttons: {
          inline: [{ icon: "info", func: "Info", reqFunc: "Info", caption: "Details" }],
          right: [{ icon: "export", func: "Export", caption: (n) => "Export (" + (n === 0 ? "All" : n) + ")", reqFunc: "Export" }],
        },
      },
    };
  }

  componentDidMount() {
    Authority.Require("GPTQA.ConversationLog");
  }

  componentDidUpdate(prevProps, prevState) {
    if (!Accessor.IsIdentical(prevProps, this.props, Object.keys(GPTConvlog.defaultProps))) {
      this._setAllStates();
    }
  }

  componentWillUnmount() {
    this.setState = (state, callback) => {
      return;
    };
  }

  _setAllStates = (callback) => {
    this.setState(
      (state, props) => ({
        ...props,
      }),
      callback
    );
  };

  onMountDatumizo = (callbacks) => {
    this.MountDatumizo = callbacks;
  };

  _initTraining = () => {
    console.log("init training");
    this.MountDatumizo.CustomInner("Training");
  };

  datePickerCallback = (dates) => {
    if (Array.isArray(dates) && dates.length == 2) {
      this.setState(() => ({
        period: {
          DateRange: dates,
        },
      }));
    }
  };

  renderDatePicker = () => {
    const { period } = this.state;
    const handleDateChange = (newDates) => {
      if (newDates && newDates[0] && newDates[1]) {
        this.setState((state) => {
          return {
            period: {
              ...state.period,
              DateRange: newDates,
            },
            base: {
              ...state.base,
              exportDoc: `GPTQA_Conversation_Log_${newDates[0].format("YYYYMMDD")}_${newDates[1].format("YYYYMMDD")}`,
            },
          };
        });
        this.MountDatumizo.Reload();
        store.setFilterSettingDateRange(newDates, "GPTConvlog");
      }
    };

    return (
      <DateTimeRangePicker
        dates={period.DateRange}
        onChangeDates={handleDateChange}
        limitSelectedPeriod={{ unit: "years", value: 1 }}
        helperText="Select date range to filter the data. You may select up to 1 year's data."
      />
    );
  };

  getDoc = (docID) => {
    return this.MountDatumizo.GetDoc(docID);
  };

  renderTable = () => {
    let { base, serverSidePagination, period } = this.state;
    return (
      <Datumizo
        base={base}
        addOns={{
          ...period,
          getDoc: this.getDoc,
        }}
        serverSidePagination={serverSidePagination}
        onMounted={this.onMountDatumizo}
        languageSelector={false}
        datePickerCallback={this.datePickerCallback}
      />
    );
  };

  render() {
    let { title } = this.state;
    return (
      <VStack>
        <Box padding={1} width="100%">
          <HStack
            justifyContent="flex-start"
            gap={8}
            style={{
              display: "flex",
              width: "100%",
            }}
          >
            <Typography
              style={{
                alignItems: "left",
                display: "flex",
                fontSize: 25,
                color: ColorX.GetColorCSS("Primary"),
              }}
            >
              {title}
            </Typography>
            {this.renderDatePicker()}
          </HStack>
        </Box>

        {this.renderTable()}
      </VStack>
    );
  }
}
export default GPTConvlog;
