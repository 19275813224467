// import { Player } from "video-react";
import { Typography } from "@mui/material";
import { FormatSchema, FormizoSchema } from "Components/LabIZO/Formizo";
import { HStack, VStack } from "Components/LabIZO/Stackizo";
import { TablizoSchema } from "Components/LabIZO/Tablizo";
import _ from "lodash";
import AnsLib from "static/AnsLib";
import ZCMsg from "Components/Messenger/components/ZChat/ZCMsg/ZCMsg";
import ZEye from "Components/ZEye/ZEye";
import { Roles } from "interfaces/role.interface";
import { store } from "static";

const Table: TablizoSchema[] = [
  {
    label: "Answer Alias",
    name: "name",
    width: 180,
  },
  {
    label: "Sample (EN)",
    name: "Public.text.EN.0",
    htmlFormat: true,
  },
  {
    label: "Sample (TC)",
    name: "Public.text.TC.0",
    htmlFormat: true,
  },
  {
    label: "Sample (SC)",
    name: "Public.text.SC.0",
    htmlFormat: true,
  },
  {
    label: "Preview",
    name: "",
    width: 240,
    Cell: (row) => {
      let ansObj = {
        EN: AnsLib.Doc2Chat(row.Public, "EN"),
        TC: AnsLib.Doc2Chat(row.Public, "TC"),
        SC: AnsLib.Doc2Chat(row.Public, "SC"),
      };
      let rtn: any = [];
      _.map(ansObj, (o, i) => {
        rtn.push(
          <HStack gap={0} padding={0} key={i}>
            <Typography>{i}</Typography>
            <ZEye>
              <VStack>
                <ZCMsg message_id="" cssp="zchat z" _onQuickReply={() => {}} pos="in" item={o as any} last={true} typingBubbles={false} showQuickRepliesAsButtons={true} HTMLEnabled={true} />
              </VStack>
            </ZEye>
          </HStack>
        );
      });
      return <HStack gap={0}>{rtn}</HStack>;
    },
  },
];

const Preview: FormizoSchema[] = [
  {
    tabs: Roles.map((role) => ({
      label: `${role.display[store.sysInfo.Language.default]}`,
      page: [
        {
          label: "Preview",
          width: 50,
          tabs: [
            {
              label: "EN",
              page: [
                {
                  name: "EN",
                  format: "display",
                  noLabelGrid: true,
                  Custom: (row) => {
                    let o = AnsLib.Doc2Chat(row[role.value], "EN");
                    return (
                      <HStack gap={0}>
                        <VStack>
                          <ZCMsg message_id="" cssp="zchat z" _onQuickReply={() => {}} pos="in" item={o as any} last={true} typingBubbles={false} showQuickRepliesAsButtons={true} HTMLEnabled={true} />
                        </VStack>
                      </HStack>
                    );
                  },
                },
              ],
            },
            {
              label: "TC",
              page: [
                {
                  name: "TC",
                  format: "display",
                  noLabelGrid: true,
                  Custom: (row) => {
                    let o = AnsLib.Doc2Chat(row[role.value], "TC");
                    return (
                      <HStack gap={0}>
                        <VStack>
                          <ZCMsg message_id="" cssp="zchat z" _onQuickReply={() => {}} pos="in" item={o as any} last={true} typingBubbles={false} showQuickRepliesAsButtons={true} HTMLEnabled={true} />
                        </VStack>
                      </HStack>
                    );
                  },
                },
              ],
            },
            {
              label: "SC",
              page: [
                {
                  name: "SC",
                  format: "display",
                  noLabelGrid: true,
                  Custom: (row) => {
                    console.log(row);
                    let o = AnsLib.Doc2Chat(row[role.value], "SC");
                    return (
                      <HStack gap={0}>
                        <VStack>
                          <ZCMsg message_id="" cssp="zchat z" _onQuickReply={() => {}} pos="in" item={o as any} last={true} typingBubbles={false} showQuickRepliesAsButtons={true} HTMLEnabled={true} />
                        </VStack>
                      </HStack>
                    );
                  },
                },
              ],
            },
          ],
        },
      ],
    })),
  },
];

const defaultLang = store.sysInfo.Language.default;

const Tail: FormizoSchema[] = [
  {
    tabs: Roles.map((role) => ({
      label: `${role.display[store.sysInfo.Language.default]}`,
      page: [
        {
          tabs: [
            {
              label: "Text",
              page: [
                {
                  columns: [
                    {
                      page: [
                        {
                          label: "Text (EN)",
                          name: `${role.value}.text.EN.0`,
                          format: "textarea",
                          variant: "outlined",
                        },
                      ],
                    },
                    {
                      page: [
                        {
                          label: "Text (TC)",
                          name: `${role.value}.text.TC.0`,
                          format: "textarea",
                          variant: "outlined",
                        },
                      ],
                    },
                    {
                      page: [
                        {
                          label: "Text (SC)",
                          name: `${role.value}.text.SC.0`,
                          format: "textarea",
                          variant: "outlined",
                        },
                      ],
                    },
                  ],
                },
              ],
            },
            {
              label: "Quick Reply",
              page: [
                {
                  label: "Quick Reply Buttons",
                  name: `${role.value}.quickReplies`,
                  canAdd: true,
                  canDelete: true,
                  headerStyle: "outlined",
                  addStyle: "header",
                  array: [
                    {
                      label: "Display",
                      width: 50,
                      name: " ",
                      tabs: [
                        {
                          label: "EN",
                          page: [
                            {
                              label: "Display",
                              name: "title.EN",
                              format: "text",
                              validate: defaultLang == "EN" ? ["required"] : [],
                            },
                          ],
                        },
                        {
                          label: "TC",
                          page: [
                            {
                              label: "Display",
                              name: "title.TC",
                              format: "text",
                              validate: defaultLang == "TC" ? ["required"] : [],
                            },
                          ],
                        },
                        {
                          label: "SC",
                          page: [
                            {
                              label: "Display",
                              name: "title.SC",
                              format: "text",
                              validate: defaultLang == "SC" ? ["required"] : [],
                            },
                          ],
                        },
                      ],
                    },
                    {
                      label: "Button Payload",
                      name: "payload",
                      format: "select",
                      selectStyle: "dropdown",
                      selectRef: "intents",
                      selectCap: "caption",
                      selectVal: "_id",
                      placeholder: "Intent",
                      validate: ["required"],
                      variant: "grid",
                      width: 250,
                    },
                  ],
                },
              ],
            },
            {
              reqLevel: 0,
              label: "Image",
              page: [
                {
                  label: "Image Source",
                  name: `${role.value}.image`,
                  format: "multiSelect",
                  selectStyle: "autocomplete",
                  optionType: "static",
                  options: [
                    // Ref : Image_path of hbb-syva-chatbot
                    { value: "contactUs", label: "contactUs" },
                    { value: "conversation", label: "conversation" },
                    { value: "dataAccessRequest", label: "dataAccessRequest" },
                    { value: "dataCorrectionRequest", label: "dataCorrectionRequest" },
                    { value: "doctorSearch", label: "doctorSearch" },
                    { value: "downloadForMobile", label: "downloadForMobile" },
                    { value: "fallBack", label: "fallBack" },
                    { value: "forbidden", label: "forbiden" },
                    { value: "identityHcp", label: "identityHcp" },
                    { value: "identityHcprof2", label: "identityHcprof2" },
                    { value: "identityHcprof", label: "identityHcprof" },
                    { value: "identityHcr", label: "identityHcr" },
                    { value: "mobileEhealthForGreeting", label: "mobileEhealthForGreeting" },
                    { value: "successfulRegist", label: "successfulRegist" },
                    { value: "UnderMaintenance", label: "UnderMaintenance" },
                  ],
                },
              ],
            },
            {
              reqLevel: 0,
              label: "Learn More",
              page: [
                {
                  label: "Title",
                  width: 40,
                  name: " ",
                  tabs: [
                    {
                      label: "EN",
                      page: [
                        {
                          label: "Title",
                          name: `${role.value}.learnMore.title.EN`,
                          format: "text",
                        },
                      ],
                    },
                    {
                      label: "TC",
                      page: [
                        {
                          label: "Title",
                          name: `${role.value}.learnMore.title.TC`,
                          format: "text",
                        },
                      ],
                    },
                    {
                      label: "SC",
                      page: [
                        {
                          label: "Title",
                          name: `${role.value}.learnMore.title.SC`,
                          format: "text",
                        },
                      ],
                    },
                  ],
                },
                {
                  label: "Display",
                  width: 40,
                  name: " ",
                  tabs: [
                    {
                      label: "EN",
                      page: [
                        {
                          label: "Display",
                          name: `${role.value}.learnMore.text.EN`,
                          format: "text",
                        },
                      ],
                    },
                    {
                      label: "TC",
                      page: [
                        {
                          label: "Display",
                          name: `${role.value}.learnMore.text.TC`,
                          format: "text",
                        },
                      ],
                    },
                    {
                      label: "SC",
                      page: [
                        {
                          label: "Display",
                          name: `${role.value}.learnMore.text.SC`,
                          format: "text",
                        },
                      ],
                    },
                  ],
                },
                {
                  label: "URL",
                  name: "",
                  width: 40,
                  tabs: [
                    {
                      label: "EN",
                      page: [
                        {
                          label: "URL",
                          name: `${role.value}.learnMore.url.EN`,
                          format: "text",
                        },
                      ],
                    },
                    {
                      label: "TC",
                      page: [
                        {
                          label: "URL",
                          name: `${role.value}.learnMore.url.TC`,
                          format: "text",
                        },
                      ],
                    },
                    {
                      label: "SC",
                      page: [
                        {
                          label: "URL",
                          name: `${role.value}.learnMore.url.SC`,
                          format: "text",
                        },
                      ],
                    },
                  ],
                },
              ],
            },
            {
              reqLevel: 0,
              label: "Additional",
              page: [
                {
                  label: "Rating System ",
                  name: `${role.value}.rating`,
                  format: "bool",
                },
                {
                  label: "Role Selection",
                  name: `${role.value}.roleSelection`,
                  format: "bool",
                },
                {
                  label: "Bypass Role",
                  name: `${role.value}.bypassRole`,
                  format: "bool",
                },
              ],
            },
          ],
        },
      ],
    })),
  },
];

const Add: FormizoSchema[] = [
  {
    columns: [
      {
        page: [
          {
            label: "Name",
            name: "name",
            format: "text",
          },
          {
            label: "Remarks",
            name: "remarks",
            format: "text",
          },
          ...Tail,
        ],
      },
      {
        width: 300,
        page: Preview,
      },
    ],
  },
];

const Info: FormizoSchema[] = [
  {
    columns: [
      {
        page: [
          {
            label: "ID",
            name: "_id",
            validate: ["required"],
            format: "text",
            readOnly: true,
          },
          {
            label: "Name",
            name: "name",
            format: "text",
            readOnly: true,
          },
          {
            label: "Remarks",
            name: "remarks",
            format: "text",
          },
          ...Tail,
        ],
      },
      {
        width: 500,
        page: Preview,
      },
    ],
  },
];

const Edit: FormizoSchema[] = [...Info];

const Export: FormatSchema[] = [
  {
    label: "Answer ID",
    name: "_id",
  },
  {
    label: "Name",
    name: "name",
  },
  {
    label: "Domain",
    name: "domain",
  },
  {
    label: "Category",
    name: "category",
  },
  {
    label: "Sub-Category",
    name: "subcategory",
  },
  {
    label: "Sample (EN) sep.';;'",
    name: "text.EN.0",
    format: "array",
    separator: ";;",
  },
  {
    label: "Sample (TC) sep.';;'",
    name: "text.TC.0",
    format: "array",
    separator: ";;",
  },
  {
    label: "Sample (SC) sep.';;'",
    name: "text.SC.0",
    format: "array",
    separator: ";;",
  },
  {
    label: "Quick Replies (JSON)",
    name: "quickReplies",
    format: "json",
  },
];

const Import: FormizoSchema[] = [
  {
    name: "upload",
    label: "Upload file",
    format: "file",
    validate: ["required"],
    accept: ".xlsx, xls",
  },
];

const ImportFormat: FormatSchema[] = [...Export];

const Filter: any[] = [
  {
    label: "AnsID",
    format: "text",
    fields: [{ name: "_id" }],
  },
  {
    label: "Answer",
    format: "text",
    fields: [
      { name: "text.EN", type: "array" },
      { name: "text.TC", type: "array" },
      { name: "text.SC", type: "array" },
    ],
  },
  {
    label: "Domain",
    format: "text",
    fields: [{ name: "domain", type: "text" }],
  },
  {
    label: "Category",
    format: "text",
    fields: [{ name: "category", type: "text" }],
  },
  {
    label: "SubCat",
    format: "text",
    fields: [{ name: "subcategory", type: "text" }],
  },
];

const Filterables = [
  {
    field: "name",
    label: "Answer ID",
    type: "text",
  },
  {
    field: "text.EN",
    label: "Answer (EN)",
    type: "array",
  },
];

const AdvFilter = [
  {
    label: "FAQ ID",
    name: "_id",
    format: "text",
  },
  {
    label: "Answer EN",
    name: "text.EN",
    format: "text",
  },
  {
    label: "Answer TC",
    name: "text.TC",
    format: "text",
  },
  {
    label: "Answer SC",
    name: "text.SC",
    format: "text",
  },
  {
    inline: [
      {
        label: "Date From",
        name: "dateFrom",
        format: "datetime",
      },
      {
        label: "To",
        name: "dateTo",
        format: "datetime",
      },
    ],
  },
];

const schema = {
  Table,
  Info,
  Add,
  Edit,
  Export,
  Import,
  ImportFormat,
  Filter,
  AdvFilter,
  Filterables,
};

export default schema;
