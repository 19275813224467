import React, { useState } from "react";

import { Box, Typography } from "@mui/material";

import schema from "./schema";

import Datumizo from "Components/LabIZO/Datumizo/Datumizo";
import { VStack } from "Components/LabIZO/Stackizo";
import { ColorX } from "static";
import { DATA_LINK } from "connectors";
import { DatumizoBase } from "Components/LabIZO/Datumizo/DatumizoProps.interface";
import { observer } from "mobx-react";

interface ICounterExampleProps {
  title?: string;
  serverSidePagination?: boolean;
  base?: DatumizoBase;
}

const defaultProps: ICounterExampleProps = {
  title: "COUNTER EXAMPLES",
  serverSidePagination: false,
  base: {
    title: "Counter Example",
    exportDoc: "counterexample",
    schema: schema,
    reqAuth: "Workspace.CounterExample",
    columnAuth: "CounterExample",

    noDefaultTable: false,
    noDefaultButtons: false,

    tablizo: {
      columnsToolbar: true,
      filterToolbar: true,
      densityToolbar: true,
      exportToolbar: false,
      density: "standard",
      defaultPageSize: 25,
      showSelector: true,
    },

    Connect: {
      DBInfo: DATA_LINK.CounterExampleDBInfo,
      List: DATA_LINK.CounterExampleList,
      SetColumnVisibilitySetting: DATA_LINK.CounterExampleSetColumnVisibilitySetting,
      schema: schema.Table,
    },

    operations: {
      Add: {
        title: "Add Counter Example",
        url: DATA_LINK.CounterExampleAdd,
        success: "Counter Example Added Successfully",
        fail: "Counter Example Add Failed: ",
        schema: schema.Add,
        buttons: ["Clear", "Submit"],
        onSubmit: "Add",
      },
      Delete: {
        title: "Delete this Counter Example?",
        content: "Caution: This is irrevertable.",
        url: DATA_LINK.CounterExampleDelete,
        success: "Counter Example Deleted Successfully.",
        fail: "Counter Example Delete Failed: ",
        onSubmit: "Delete",
      },
      Edit: {
        title: "Edit Counter Example ",
        url: DATA_LINK.CounterExampleEdit,
        success: "Counter Example Edited Successfully",
        fail: "Counter Example Edit Failed: ",
        schema: schema.Edit,
        buttons: ["Revert", "Submit"],
        onSubmit: "Edit",
      },
      Info: {
        title: "Counter Examples ",
        success: "Counter Examples Load Successfully",
        fail: "Counter Examples Load Failed: ",
        schema: schema.Info,
        readOnly: true,
      },
      Import: {
        title: "Counter Example Import",
        content: "",
        url: DATA_LINK.CounterExampleImport,
        success: "Counter Example Imported Successfully.",
        fail: "Counter Example Import Failed: ",
        schema: schema.ImportFormat,
        accept: [".xlsx", ".xls"],
        replace: false,
      },
      Export: {
        url: DATA_LINK.CounterExampleExport,
        schema: schema.Export,
      },
      Sync: {
        title: "Sync from NLP",
        content: "Caution: This is irrevertable.",
        url: DATA_LINK.CounterExampleSync,
        success: "Entity Sync Successfully.",
        fail: "Entity Sync Failed: ",
        onSubmit: "Sync",
      },
      DeleteBulk: {
        title: (n: string) => "Delete these " + n + " Counter Example?",
        content: "Caution: This is irrevertable.",
        url: DATA_LINK.CounterExampleDeleteBulk,
        success: "Counter Example Deleted Successfully.",
        fail: "Counter Example Delete Failed: ",
        onSubmit: "DeleteBulk",
      },
    },

    buttons: {
      inline: [
        { icon: "edit", func: "Edit", caption: "Edit", reqFunc: "Edit" },
        { icon: "info", func: "Info", caption: "Details" },
        { icon: "delete", func: "Delete", caption: "Delete", reqFunc: "Delete" },
      ],
      left: [{ icon: "add", func: "Add", caption: "Add Counter Example", reqFunc: "Add" }],
      right: [
        { icon: "deletebulk", func: "DeleteBulk", caption: (n) => "Delete (" + n + ")", reqFunc: "Delete", theme: "caution" },
        { icon: "export", func: "Export", caption: (n) => "Export (" + (n === 0 ? "All" : n) + ")", reqFunc: "Export" },
        { icon: "import", func: "Import", caption: "Import", reqFunc: "Import" },
        { icon: "syncfrom", func: "Sync", caption: "Sync", reqFunc: "Sync" },
      ],
    },
  },
};

const CounterExample: React.FC<ICounterExampleProps> = (props) => {
  const { title, serverSidePagination, base } = props;
  const [MountDatumizo, setMountDatumizo] = useState<any>(false);

  function onMountDatumizo(callback: any) {
    setMountDatumizo(callback);
  }

  async function onQuitInner() {
    await MountDatumizo?.Reload();
  }
  return (
    <VStack>
      <Box padding={1} width="100%">
        <Typography
          style={{
            textAlign: "left",
            width: "100%",
            fontSize: 25,
            color: ColorX.GetColorCSS("Primary"),
          }}
        >
          {title}
        </Typography>
      </Box>
      <Datumizo
        //
        base={base}
        serverSidePagination={serverSidePagination}
        onMounted={onMountDatumizo}
        onQuitInner={onQuitInner}
        addOns={{
          workspace: "master",
        }}
      />
    </VStack>
  );
};

CounterExample.defaultProps = defaultProps;

export default observer(CounterExample);
