import { forwardRef, Ref } from "react";
import { HStack } from "Components/Layouts";
import { StackTypes } from "Components/Layouts/HStack";

const LegacyHStack = forwardRef((props: StackTypes, ref: Ref<HTMLDivElement>) => {
  const { gap = 0, ...other } = props;
  return <HStack gap={gap} {...other} ref={ref} />;
});

LegacyHStack.displayName = "LegacyHStack";

export default LegacyHStack;
