import { AvailLANG, IQuickReply, IT2EDetect, T2EContext, ZChat } from "interfaces";
import { createContext } from "react";

export interface IZChatContext {
  typingDisabled: boolean;
  inQR: boolean;
  messages: Array<any>;
  quickReplies: Array<any>;
  typing: boolean;
  detect?: IT2EDetect;
  responseLang?: AvailLANG;
  input?: string;
  sessionID?: string;
  feedbackUrl?: string;
  regenerateOutput?: boolean;

  //
  onSend?: (
    input: { text: string },
    optional?: {
      hideInput?: boolean;
      displayText?: string;
      message_id?: string;
    }
  ) => void;
  onQuickReply?: (quickReply: IQuickReply, id: string) => void;
  onMsgPress?: (msgId: string, msg: { text: string }) => void;
  onClearHistory?: () => Promise<void>;
  onSetMessages?: (_messages: ZChat[]) => void;
  onContextUpdate?: (actionId: string, contexts: T2EContext[]) => Promise<void>;
  onRegenerateResponse?: (item: ZChat, text: string) => Promise<void>;
}

export const ZChatContext = createContext<IZChatContext | null>(null);
