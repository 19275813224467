import { HStack, Spacer, VStack } from "Components/LabIZO/Stackizo";
import { IArena } from "interfaces/db.interfaces/arena.interface";
import { Typography } from "@mui/material";
import { styled } from "@mui/material";
import { ColorX } from "static";
import { ControlledTextFieldWithIcon } from "Components/HookForm/ControlledTextFieldWithIcon";
import { useForm } from "react-hook-form";
import ModelConvMsg from "./ModelConvMsg";
import { Check, Close } from "@mui/icons-material";

const StyledFieldLabel = styled(Typography)({
  color: ColorX.GetColorCSS("TableText"),
  flexWrap: "wrap",
  overflow: "auto",
  whiteSpace: "pre-wrap",
  wordBreak: "break-all",
});

interface ArenaLogInfoProps {
  id: string;
  row: IArena;
  onQuit: () => void;
  onQuitRefresh: (_id: string) => void;
  renderFormizo: (schema: any, buttons: any, onSubmit: any) => JSX.Element;
}

export default function ArenaLogInfo(props: ArenaLogInfoProps) {
  const methods = useForm<ArenaLogInfoProps>({ defaultValues: props });
  const { control, getValues } = methods;

  function renderBasicInfo() {
    return (
      <HStack>
        <ControlledTextFieldWithIcon name="id" label="ID" control={control} variant="outlined" fullWidth disabled />
        <ControlledTextFieldWithIcon name="row.recTime" label="Received Time" control={control} variant="outlined" fullWidth disabled />
        {/* <ControlledTextFieldWithIcon name="row.input" label="Input" control={control} variant="outlined" fullWidth disabled />
        <ControlledTextFieldWithIcon name="row.comparison" label="Result" control={control} variant="outlined" fullWidth disabled />
        <ControlledTextFieldWithIcon name="row.winner" label="Winner" control={control} variant="outlined" fullWidth disabled /> */}
      </HStack>
    );
  }

  function renderHeader(label: string, width = undefined) {
    return (
      <HStack width={width}>
        <Typography sx={{ color: ColorX.GetColorCSS("Primary"), fontWeight: "bold", fontSize: 20 }}>{label && label.toUpperCase()}</Typography>
        <Spacer />
      </HStack>
    );
  }
  function renderField(label: string, value: any, width = 150, fontSize = 14, maxHeight: number = 150) {
    if (typeof value === "boolean") {
      value = value ? <Check /> : <Close />;
    }
    value = value ? value : "-";
    const maxHeightValue = maxHeight === Infinity ? maxHeight : "none";

    return (
      <HStack justifyContent="flex-start" gap={1} alignItems="flex-start" style={{ flexWrap: "wrap" }} key={label}>
        <Typography style={{ color: ColorX.GetColorCSS("Primary"), width: width, fontSize: fontSize }}>{label && label.toUpperCase()}</Typography>
        {typeof value === "string" ? (
          <StyledFieldLabel
            sx={{
              fontSize: fontSize,
              maxHeight: maxHeightValue,
            }}
          >
            {value}
          </StyledFieldLabel>
        ) : (
          value
        )}
      </HStack>
    );
  }

  function renderModelInfo(modelID: 1 | 2) {
    return (
      <VStack width="70%" sx={{ paddingTop: "10px" }}>
        {renderHeader(`Model ${modelID} Basic Information`)}
        {renderField("ID", getValues(`row.llm${modelID}.id`))}
        {renderField("Name", getValues(`row.llm${modelID}.name`))}
        {renderField("Model", getValues(`row.llm${modelID}.model`))}
        {renderField("Prompt", getValues(`row.llm${modelID}.prompt`))}
        {renderField("Instruction", getValues(`row.llm${modelID}.instruction`))}
        {renderField("Parameter", <pre>{JSON.stringify(getValues(`row.llm${modelID}.parameter`), null, 2)}</pre>)}
        {renderField("Session ID", getValues(`row.llm${modelID}.sessionId`))}
        {renderField("Message ID", getValues(`row.llm${modelID}.messageId`))}
        {renderField("Elo", getValues(`row.llm${modelID}.elo`))}
        {renderField("Previous Message", <ModelConvMsg msgs={getValues(`row.llm${modelID}.prevMsg`)} messageID={getValues(`row.llm${modelID}.messageId`)} />, 150, 14, Infinity)}
      </VStack>
    );
  }

  return (
    <VStack sx={{ pt: 3 }} width={"100%"}>
      {renderBasicInfo()}
      <HStack width="100%">
        {renderModelInfo(1)}
        {renderModelInfo(2)}
      </HStack>
    </VStack>
  );
}
