import { useState } from "react";

import _ from "lodash";
import { observer } from "mobx-react";
import { Typography, Tooltip, Box, ButtonBase, SxProps } from "@mui/material";

import Accessizo from "Components/LabIZO/Accessizo";
import { HStack, Spacer, VStack } from "Components/LabIZO/Stackizo";
import { store, ColorX } from "static";
import { HMarquee } from "Components/LabIZO/Animatizo";
import "../../_gears/syva_logo.css";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { IMenuItemSchema } from "../Menu.interface";

interface MenuButtonProps {
  location: Location;
  caption: string;
  icon: string | JSX.Element;
  id?: string;
  path?: string;
  reqAuth?: string;
  reqLevel?: any;
  subMenu?: IMenuItemSchema[];
  disabled?: boolean;
  mini?: boolean;
  zIndex?: number;
  parentPath?: string[];
  onClick?: (...args: any) => void;
}

const MenuButton: React.FC<MenuButtonProps> = ({
  location, //
  caption,
  icon,
  id = "",
  path = "",
  reqAuth = "",
  reqLevel = 999,
  subMenu = [],
  disabled = false,
  mini = false,
  zIndex = 50,
  parentPath,
  onClick,
}) => {
  const [openNested, setOpenNested] = useState(false);
  const navigate = useNavigate();
  const [inPage, setInPage] = useState(false);

  useEffect(() => {
    // if (location.pathname.includes(path) || parentPath?.map((path) => location.pathname.includes(path))) {
    if (location.pathname.includes(path) || subMenu.find((item) => location.pathname.includes(item.link))) {
      setInPage(true);
    } else {
      setInPage(false);
    }
  }, [location.pathname, path]);

  const _onClick = (path: string) => {
    navigate(path);
  };

  const openNestedMenu = () => {
    setOpenNested(true);
  };

  const closeNestedMenu = () => {
    setOpenNested(false);
  };

  const renderIcon = (icon: string | JSX.Element) => {
    if (_.isString(icon)) {
      if (icon === "syva_logo") {
        const style = { backgroundColor: inPage ? ColorX.GetColorCSS("Secondary") : ColorX.GetColorCSS("Primary") };
        return <Box sx={style} height={"25px"} width={"30px"} className={"syva_logo"}></Box>;
      } else {
        return <i className={icon} />;
      }
    }
    return icon;
  };

  const renderButton = () => {
    let inPageTheme: SxProps = {
      padding: "0",
      textTransform: "none",
      borderRadius: "0px",
      color: ColorX.GetColorCSS("Secondary"),
      background: ColorX.GetColorCSS("Primary"),
      //hover
      "&:hover": {
        color: ColorX.GetColorCSS("Secondary"),
        background: ColorX.GetColorCSS("Primary"),
      },
      position: "relative",
      zIndex: zIndex,
    };

    let theme: SxProps = {
      padding: "0",
      textTransform: "none",
      borderRadius: "0px",
      color: ColorX.GetColorCSS("Primary"),
      background: ColorX.GetColorCSS("Secondary"),
      //hover
      "&:hover": {
        color: ColorX.GetColorCSS("Secondary"),
        background: ColorX.GetColorCSS("Primary"),
        "& .syva_logo": {
          backgroundColor: ColorX.GetColorCSS("Secondary"),
        },
      },
      position: "relative",
      zIndex: zIndex,
    };

    return (
      <ButtonBase sx={inPage ? inPageTheme : theme}>
        <HStack padding={1} width="140px">
          <HMarquee width="90px">
            <Typography
              sx={{
                fontSize: 14,
                opacity: 1,
                whiteSpace: "nowrap",
                height: "100%",
                visibility: mini ? "hidden" : "visible",
                width: "100%",
                textAlign: "center",
                //color: inPage ? ColorX.GetColorCSS("Secondary") : ColorX.GetColorCSS("Primary"),
              }}
            >
              {caption}
            </Typography>
          </HMarquee>
          <Spacer />
          <VStack gap={0}>
            <Spacer />
            <Tooltip title={mini ? caption : ""} aria-label="label" placement="top-start" arrow={true}>
              <HStack width={24} height={24} sx={{ justifyContent: "flex-start" }}>
                {renderIcon(icon)}
                <Spacer />
              </HStack>
            </Tooltip>
            <Spacer />
          </VStack>
        </HStack>
      </ButtonBase>
    );
  };

  const NestedMenu = () => {
    if (openNested && subMenu) {
      return (
        <VStack
          gap="0px"
          sx={{
            background: ColorX.GetColorCSS("Secondary"),
            position: "relative",
            height: "fit-content",
            zIndex: zIndex - 1,
            justifyContent: "flex-start",
            alignItems: "flex-start",
          }}
        >
          {subMenu.map((item) => (
            <MenuButton
              location={location}
              path={item.link}
              icon={item.icon}
              reqAuth={item.reqAuth}
              reqLevel={item.reqLevel}
              subMenu={item.subMenu}
              disabled={item.disabled}
              id={item.id}
              key={item.caption}
              caption={item.caption}
              parentPath={(parentPath ?? []).concat(path)}
              mini={mini}
            />
          ))}
        </VStack>
      );
    }
    return <></>;
  };

  const renderInner = () => {
    return (
      <HStack
        gap={0}
        id={id ? id : caption}
        className="MenuButton-inner"
        style={{ width: "100%", position: "relative" }}
        onClick={(e: any) => {
          e.stopPropagation();
          if (onClick) {
            onClick();
          } else if (!disabled) {
            _onClick(path);
          }
        }}
      >
        {renderButton()}
      </HStack>
    );
  };

  return (
    <Accessizo key={caption} reqAuth={reqAuth} reqLevel={reqLevel} auth={store.user.authority} level={store.user.level}>
      <Box
        sx={{
          border: openNested ? `1px solid ${ColorX.GetColorCSS("Primary2")}` : undefined,
          boxSizing: openNested ? "border-box" : undefined,
        }}
        onClick={(e: any) => {
          e.stopPropagation();
          if (onClick) {
            onClick();
          } else if (!disabled) {
            _onClick(path);
          }
        }}
        onMouseEnter={() => {
          openNestedMenu();
        }}
        onMouseLeave={() => {
          closeNestedMenu();
        }}
      >
        {renderInner()}
        <NestedMenu />
      </Box>
    </Accessizo>
  );
};

export default observer(MenuButton);
