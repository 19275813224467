import React, { Component } from "react";
import PropsType from "prop-types";

import Avatar3D from "./Avatar3D";
import DialogBox from "./DialogBox";

import { Accessor } from "static";
import { HStack, Spacer, VStack } from "Components/LabIZO/Stackizo";

/**
 *
 * @augments {Component<Props, State>}
 */
class Chat3D extends Component {
  static propTypes = {
    onMounted: PropsType.func,
  };

  static defaultProps = {
    onMounted: null,
  };

  constructor() {
    super();
    this.state = {};
  }

  componentDidMount() {
    this._setAllStates();
  }

  componentDidUpdate(prevProps, prevState) {
    if (!Accessor.IsIdentical(prevProps, this.props, Object.keys(Chat3D.defaultProps))) {
      this._setAllStates();
    }
  }

  componentWillUnmount() {
    this.setState = (state, callback) => {
      return;
    };
  }

  _setAllStates = (callback) => {
    this.setState(
      (state, props) => ({
        ...props,
      }),
      () => {
        if (this.props.onMounted) {
          this.props.onMounted({
            Play: this._Play,
            Msg: this._Msg,
            GetActions: this._GetActions,
          });
        }
        if (callback) callback();
      }
    );
  };

  onMountAvatar = (callbacks) => {
    this.MountAvatar = callbacks;
  };

  onMountDialogBox = (callbacks) => {
    this.MountDialogBox = callbacks;
  };

  _Play = (animation, loop = false) => {
    this.MountAvatar.Play(animation, loop);
  };

  _Msg = (msg) => {
    this.MountDialogBox.showMessage(msg);
  };

  _GetActions = () => {
    if (this.MountAvatar) return this.MountAvatar.GetActions();
  };

  render() {
    return (
      <HStack
        alignItems="flex-start"
        style={{
          width: "400px",
          height: "470px",
          position: "fixed",
          right: 0,
          bottom: 0,
          zIndex: 50,
        }}
      >
        <Spacer />
        <HStack
          style={{
            position: "absolute",
            top: -60,
            right: 0,
          }}
        >
          <DialogBox onMounted={this.onMountDialogBox} />
        </HStack>
        <VStack
          style={{
            position: "absolute",
            right: 0,
          }}
        >
          <Spacer />
          <Avatar3D onMounted={this.onMountAvatar} />
        </VStack>
      </HStack>
    );
  }
}

export default Chat3D;
