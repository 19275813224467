import { IOptionalSendToServerParam, MiddlewareConnector } from "Pages/CombinedTalk2VA/connectors/middleware.connector";
import { IQuickReply, IT2EDetect, T2EStreamResponse, T2EResponse, ZChat as IZChat, T2EContext, IResponseChunkError } from "interfaces";
import _ from "lodash";
import moment from "moment";
import { useState, useRef } from "react";
import { v4 } from "uuid";
import { MESSAGE_STATUS } from "../Messenger";
import { Analyzer } from "../classes/Analzyer";
import { UserId } from "../interfaces/messenger.interface";
import { useUrlParams } from "./useUrlParams";
import { ErrorX, store } from "static";
import { IRatingProps } from "../components/ZChat/ZCMsg/ZCMRating";

export function useMessages({
  onMsgSent, //
  connector,
  intermediateResponseUrl,
  enableLiveChat = false,
}: {
  onMsgSent?: (answer: T2EResponse, msgId: string) => void; //
  connector: MiddlewareConnector;
  intermediateResponseUrl?: string;
  enableLiveChat?: boolean;
}) {
  const [messages, setMessages] = useState<IZChat[]>([]);
  const [quickReplies, setQuickReplies] = useState<IQuickReply[]>([]); // for quick reply bar
  const [typing, setTyping] = useState<boolean>(false);
  const [detect, setDetect] = useState<IT2EDetect>();
  const [model, setModel] = useState<string>();
  const [livechat, setLivechat] = useState<boolean>(false);
  const [ratingSystem, setRatingSystem] = useState<IRatingProps>({});
  const analyzerRef = useRef(new Analyzer());
  const { urlParams } = useUrlParams();
  function getDateStr() {
    return moment().toISOString();
  }

  /**
   * High level methods to update the states in various ways
   */
  const stateMethod = {
    setStatus(msgId: string, status: MESSAGE_STATUS, optional: { userId?: number } = { userId: 1 }) {
      setMessages((messages) => {
        let msgIndex = messages.findIndex((msg) => msg._id === msgId && msg.user._id === optional.userId);
        if (msgIndex === -1) return messages;
        messages[msgIndex].status = status;
        return [...messages];
      });
    },

    sendText: async (
      sessionId: string,
      text: string,
      optional: {
        hideInput?: boolean;
        displayText?: string;
        payloadMeta?: IOptionalSendToServerParam;
        type?: "text" | "button";
      } = {}
    ) => {
      try {
        if (text.trim() === "") return;
        const msgId = optional?.payloadMeta?.message_id || v4();
        delete optional?.payloadMeta?.message_id;
        const isSystemCommand = _.includes(text, "CMD_");
        //set state
        setTyping(true);
        let msgObj: IZChat = {
          _id: msgId,
          msg: { text: optional?.displayText ?? text },
          status: MESSAGE_STATUS.PENDING,
          user: { _id: 1 },
          createdAt: getDateStr(),
        };
        if (!optional.hideInput) setMessages((messages) => [...messages, msgObj]);

        //clear quick replies
        setQuickReplies([]);
        setRatingSystem({});

        //send message
        let answerPromise: Promise<{ isStream: boolean; response: T2EResponse | T2EStreamResponse }>;
        if (optional.type === "button") {
          //Update Input type for avoiding the intermediate response
          answerPromise = connector.sendQuikcReply(text, {
            sessionId: sessionId,
            viaBackend: false,
            message_id: msgId,
            project: optional?.payloadMeta?.project,
            knowledgeGroup: optional?.payloadMeta?.knowledgeGroup,
            tasks: optional?.payloadMeta?.tasks,
            llm: optional?.payloadMeta?.llm,
            livechat: livechat,
            urlParams: urlParams,
            userId: store.user.UserDisplayName ?? "talk2va",
            outputLang: store.chatbotLang,
            role: store.role,
            ...optional.payloadMeta, // override
          });
        } else {
          answerPromise = connector.sendText(text, {
            sessionId: sessionId,
            viaBackend: false,
            message_id: msgId,
            project: optional?.payloadMeta?.project,
            knowledgeGroup: optional?.payloadMeta?.knowledgeGroup,
            tasks: optional?.payloadMeta?.tasks,
            llm: optional?.payloadMeta?.llm,
            livechat: livechat,
            urlParams: urlParams,
            userId: store.user.UserDisplayName ?? "talk2va",
            outputLang: store.chatbotLang,

            role: store.role,
            ...optional.payloadMeta, // override
          });
        }
        stateMethod.setStatus(msgId, MESSAGE_STATUS.SENT);
        let { isStream, response: answerResponse } = await answerPromise;

        const answer = answerResponse as T2EResponse;

        setModel(answer.model);

        //handle streaming
        if (isStream) {
          console.log("[-] Streaming");
          return await stateMethod.handleStream(msgId, sessionId, answerResponse as T2EStreamResponse, optional?.payloadMeta?.streamUrl ?? "", {
            getIntermediateResponse: !isSystemCommand,
            intermediateResponseUrl: intermediateResponseUrl,
            payloadMeta: optional?.payloadMeta,
            input: optional?.displayText ?? text,
          });
        }

        //handle LiveChat
        if (answer.live_chat && enableLiveChat) stateMethod.handleLivechat(sessionId, optional?.payloadMeta ?? {});
        else setLivechat(false);

        let msgsToAppend: IZChat[] = [];

        if (answer.error?.status) {
          msgsToAppend = [
            {
              _id: msgId,
              msg: answer.error?.message,
              user: { _id: 0 },
              createdAt: getDateStr(),
            } as IZChat,
          ];
        } else {
          //need to append msg_id somehow
          msgsToAppend = answer.message.map((_msg) => {
            return {
              ..._msg,
              _id: msgId, // keep the same id as the original message (user's message)
              text: optional?.displayText ?? text,
              user: { _id: 0 },
              createdAt: getDateStr(),
              showEvaluate: isSystemCommand ? false : true,
              pipeline: answer.pipeline,
            };
          });
        }

        analyzerRef.current.addRecord(msgId, answer);
        setMessages((messages) => [...messages, ...msgsToAppend]);

        if (onMsgSent) onMsgSent(answer, msgId);

        // set quick replies
        if (answer?.message.length > 0) {
          const lastMessage = answer.message[answer.message.length - 1];
          if (lastMessage.msg.quickReplies) {
            const quickReplies = lastMessage.msg.quickReplies;
            setQuickReplies(quickReplies);
          }
          if (lastMessage.msg.rating) {
            const intentRef = answer.intent ?? answer.input?.content.split(" ")[1];
            setRatingSystem({
              messageid: msgId,
              sessionid: sessionId,
              intent: intentRef,
            });
          }
        }

        //set detected context
        if (answer?.detect) {
          setDetect(answer.detect);
        }

        // update status
        setTyping(false);
        stateMethod.setStatus(msgId, MESSAGE_STATUS.READ);
      } catch (e) {
        setTyping(false);
        console.error(e);
      }
    },

    sendWelcomeMessage: async (sessionId: string, payloadMeta?: IOptionalSendToServerParam) => {
      await stateMethod.sendText(sessionId, "CMD_WELCOME", { hideInput: true, payloadMeta });
    },

    addLineBreak: (text: string = "New Session") => {
      setMessages((messages) => [
        ...messages,
        {
          _id: v4(), //
          msg: { linkbreak: true, text: text },
          status: MESSAGE_STATUS.SENT,
          user: { _id: 1 },
          createdAt: getDateStr(),
        } as IZChat,
      ]);
    },

    handleStream: async (
      messageId: string,
      sessionId: string,
      streamResponse: T2EStreamResponse,
      streamUrl: string,
      optional?: {
        thinkingText?: string;
        getIntermediateResponse?: boolean;
        intermediateResponseUrl?: string;
        payloadMeta?: IOptionalSendToServerParam;
        input?: string;
      }
    ) => {
      setTyping(false);

      stateMethod.setStatus(messageId, MESSAGE_STATUS.SENT);

      if (streamResponse.error?.status) {
        //Handle error Msg
        setMessages((messages) => [
          ...messages,
          {
            _id: messageId, //
            msg: streamResponse.error?.message,
            user: { _id: 0 },
            createdAt: getDateStr(),
          } as IZChat,
        ]);
        return;
      }

      // set intermediate response placeholder
      if (optional?.getIntermediateResponse && optional?.intermediateResponseUrl) {
        setMessages((messages) => [
          ...messages,
          {
            _id: `${messageId}_intermediate`,
            msg: { text: "Searching For: " },
            user: { _id: 2 },
            createdAt: getDateStr(),
          } as IZChat,
        ]);
      }

      // append a new response message. This will be updated during the stream
      setMessages((messages) => [
        ...messages,
        {
          _id: messageId, //
          msg: { text: optional?.thinkingText ?? "Thinking..." },
          status: MESSAGE_STATUS.PENDING,
          user: { _id: 0 },
          createdAt: getDateStr(),
        } as IZChat,
      ]);

      // async start streaming
      const finalStreamResponsePromise = connector.getGPTResponseChunk(
        messageId,
        sessionId,
        streamUrl,
        {
          model: optional?.payloadMeta?.model,
        },
        async (msgs: IZChat[]) => {
          setMessages((messages) => {
            //pop the last chatbot message
            const lastChatbotMessageIndex = messages.findIndex((msg) => msg.user._id == UserId.chatbot && msg._id === messageId);
            if (lastChatbotMessageIndex === -1) return msgs;
            // insert the new messages

            msgs.map((_msg) => {
              _msg._id = messageId;
              _msg.user._id = UserId.chatbot;
              _msg.createdAt = getDateStr();
              _msg.text = optional?.input;
              _msg.pipeline = "gptQa";
            });
            messages.splice(lastChatbotMessageIndex, 1, ...msgs);
            return [...messages];
          });
        }
      );

      // get intermediate response, update the placeholder
      if (optional?.getIntermediateResponse && intermediateResponseUrl) {
        const itmRes = await connector.getIntermediateResponse(intermediateResponseUrl, { message_id: messageId, model: optional?.payloadMeta?.model });
        const itmMsg: IZChat[] = itmRes.map((_msg) => {
          return {
            _id: `${messageId}_intermediate`,
            msg: { text: _msg.text },
            user: { _id: 2 },
            createdAt: getDateStr(),
            pipeline: "gptQa",
          } as IZChat;
        });
        setMessages((messages) => {
          //find the intermediate response placeholder
          let itmResIdx = messages.findIndex((msg) => msg.user._id == UserId.user && msg._id === `${messageId}_intermediate`);
          if (itmResIdx === -1) return messages;
          // insert the new messages
          messages.splice(itmResIdx, 1, ...itmMsg);
          // if no intermediate response, replace "thinking" as error msg
          if (itmRes.length == 0) {
            let msgIndex = messages.findIndex((msg) => msg._id === messageId && msg.user._id == UserId.chatbot);
            if (msgIndex === -1) return messages;
            messages[msgIndex].msg.text = "No Intermediate Reponse";
          }
          return [...messages];
        });
      }

      //wait for streaming to finish
      const finalStreamResponse: T2EResponse | IResponseChunkError | undefined = await finalStreamResponsePromise;

      setMessages((messages) => {
        let msgIndex = messages.findIndex((msg) => msg._id === messageId && msg.user._id == UserId.chatbot);
        if (msgIndex === -1) return messages;
        messages[msgIndex].showEvaluate = optional?.getIntermediateResponse ? true : false;
        //Chips only can be added to the last response
        messages[msgIndex].msg.chips = finalStreamResponse?.message[0].msg.chips;
        messages[msgIndex].msg.chipTitle = finalStreamResponse?.message[0].msg.chipTitle;
        return [...messages];
      });

      if (finalStreamResponse != undefined) {
        // quick replies??
        const msgs = finalStreamResponse.message;
        if (msgs?.length > 0) {
          const lastMessage = msgs[msgs.length - 1];
          if (lastMessage.msg.quickReplies) {
            const quickReplies = lastMessage.msg.quickReplies;
            setQuickReplies(quickReplies);
          }
        }
      }

      stateMethod.setStatus(messageId, MESSAGE_STATUS.READ);

      if (!finalStreamResponse) return;
      analyzerRef.current.addRecord(messageId, finalStreamResponse);
      if (onMsgSent && finalStreamResponse) onMsgSent(finalStreamResponse as T2EResponse, messageId);
    },

    handleLivechat: async (sessionId: string, optional?: IOptionalSendToServerParam) => {
      console.log("[-] Live Chat Start Polling");
      setLivechat(true);

      const finalLivechatPromise = connector.getLiveChatResponse(
        sessionId,
        async (msgs: IZChat[], msgId: string) => {
          const msgToAppend = msgs.map((_msg) => {
            return {
              ..._msg,
              _id: msgId,
              user: { _id: 0 },
              createdAt: getDateStr(),
            };
          });
          console.log("msgs: ", msgs);
          setMessages((messages) => [...messages, ...msgToAppend]);
        },
        optional
      );
      const finalLivechatResponse: T2EResponse | undefined = await finalLivechatPromise;
      if (!finalLivechatResponse) {
        setTyping(false);
        setLivechat(false);
      }
    },

    /**
     * Update Context and set the new message after context update
     * @param sessionId
     * @param actionId
     * @param contexts
     */
    updateContext: async (sessionId: string, actionId: string, contexts: T2EContext[]) => {
      try {
        const res = await connector.updateContext(sessionId, actionId, contexts);
        if (!res) throw new Error("Update Context Failed");
        const messagesToAppend = res.message;
        setMessages((messages) => [...messages, ...messagesToAppend]);
        const lastMessage = messagesToAppend[messagesToAppend.length - 1];
        if (lastMessage.msg.quickReplies) {
          const quickReplies = lastMessage.msg.quickReplies;
          setQuickReplies(quickReplies);
        }
      } catch (e: any) {
        ErrorX.HandleError(e);
      }
    },

    regenerateAnswer: async (item: IZChat, text: string) => {
      const appendQuickReply = (text: string, quickReply?: { payload: string; title: string }[]) => {
        const newMessage = messages.map((msg) => {
          if (msg._id === "Retry_" + item?._id) {
            return {
              ...msg,
              msg: {
                text: text,
                quickReplies: quickReply && quickReply.length > 0 ? quickReply : undefined,
                showQuickReplies: true,
              },
            };
          }
          return msg;
        });
        setMessages([...newMessage]);
      };
      try {
        const res = await connector.getRegeneratedResponse(item);
        if (!res) throw new Error("Unable to regenerate the answer. The server returned a null or undefined response.");
        const suggestedQue = res.options.map((item: string) => {
          return {
            payload: item,
            title: item,
          };
        });
        appendQuickReply(text, suggestedQue);
      } catch (e: any) {
        ErrorX.HandleError(e);
        appendQuickReply("There is some issue with the server. Please try again later.");
      }
      //return await connector.getRegeneratedResponse(item);
    },
  };

  return {
    messages,
    quickReplies,
    ratingSystem,
    typing,
    detect,
    analyzerRef,
    stateMethod,
    setMessages,
    model,
  };
}
