import { NativeSelect } from "@mui/material";
import _ from "lodash";
import React from "react";
import { Controller, useWatch, Control, UseFormResetField } from "react-hook-form";
import { OperatorsType, FieldType, TextOperators, DateOperators, NumberOperators, BooleanOperators, ArrayOperators, SelectOperators } from "../interfaces/Filterable.interface";
import { Filterable } from "../interfaces/Filterable.interface";
import { Property } from "csstype";
import { FilterFormProps } from "../interfaces/Condition.interface";
import { Accessor } from "static";

interface FilterRowProps {
  filterables: Filterable[];
  control: Control<any, any>;
  name: string;
  width?: Property.Width;
  defaultOption?: string;
  resetField?: UseFormResetField<FilterFormProps>;
}

export const FilterOperator = ({ filterables, control, name, width = "100px", defaultOption }: FilterRowProps) => {
  // detect type for corresponding filter field
  const filterField = useWatch({
    control,
    name: `${name}.field`,
  });
  const fieldType = filterables.find((f) => f.field === filterField);
  let options: OperatorsType | undefined;
  if (fieldType?.CustomFilterOperator) {
    options = fieldType?.CustomFilterOperator;
  } else {
    switch (fieldType?.type) {
      case FieldType.text:
        options = TextOperators;
        break;
      case FieldType.datetime:
      case FieldType.date:
        options = DateOperators;
        break;
      case FieldType.number:
        options = NumberOperators;
        break;
      case FieldType.boolean:
        options = BooleanOperators;
        break;
      case FieldType.array:
        options = ArrayOperators;
        break;
      case FieldType.select:
        options = SelectOperators;
        break;
      default:
        break;
    }
  }
  return (
    <Controller
      control={control}
      name={`${name}.operator`}
      rules={{ required: true }}
      render={({ field, fieldState }) => {
        const firstOption = _.map(options, (v) => v)[0];
        if (!field.value && options) {
          field.onChange(defaultOption || firstOption);
        }
        let tmp = defaultOption ? Accessor.Get(options, `${defaultOption}`) : "";
        const defaultValue = tmp ? tmp : "";
        return (
          <NativeSelect {...field} sx={{ width }} defaultValue={defaultValue} error={Boolean(fieldState.error)}>
            {options &&
              _.map(options, (option, key) => {
                return (
                  <option key={`${name}-${key}`} value={option}>
                    {key}
                  </option>
                );
              })}
          </NativeSelect>
        );
      }}
    />
  );
};
