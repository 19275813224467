import { useState } from "react";

import { Validate } from "./Validation";
import _ from "lodash";
import TextField from "@mui/material/TextField";

interface IEmailFeildProps {
  autoFocus?: boolean;
  id?: string;
  criteria?: string[];
  label?: string;
  onInput: (value: string) => void;
}

const EmailFeild = ({ autoFocus, id, criteria = [], label, onInput }: IEmailFeildProps) => {
  const [error, setError] = useState(true);
  const [msg, setMsg] = useState("Required!");

  const handleInput = (value: string) => {
    const validation = Validate(value, criteria);
    setError(validation.err);
    setMsg(validation.msg);
    if (!validation.err) onInput(value);
  };

  return <TextField error={error} autoFocus={autoFocus} margin="dense" id={id} label={label} type="Email" fullWidth onChange={(e) => handleInput(e.target.value)} helperText={msg && msg} />;
};

export default EmailFeild;
