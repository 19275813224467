import { useEffect, useState } from "react";
import { ErrorX, store } from "static";
import { VStack, HStack } from "Components/LabIZO/Stackizo";
import { DATA_LINK, backendConnector } from "connectors";
import Formizo from "Components/LabIZO/Formizo";
import { schema } from "./schema";
import { AvailLANG } from "interfaces";

interface IContentFilterConfig {
  content: string;
  lang: AvailLANG[];
}

const SysContentFilter = () => {
  const [ContentFilterInfo, setContentFilterInfo] = useState<{ contentFilter: IContentFilterConfig[] }>();

  useEffect(() => {
    getContentFilterInfo();
  }, []);

  const handleSubmit = async (ContentFilterData: { contentFilter: IContentFilterConfig[] }) => {
    try {
      console.log(ContentFilterData);
      const res = await backendConnector.post(DATA_LINK.SYSContentFilterEdit, ContentFilterData);
      if (res.Success) {
        store.Alert("System Content Filter updated successfully", "success");
      } else {
        store.Alert("System Content Filter update fails.", "error");
      }
    } catch (e: any) {
      store.Alert(ErrorX.Handle(e), "error");
    }
  };

  const getContentFilterInfo = async () => {
    const { payload } = await backendConnector.post(DATA_LINK.SYSContentFilterInfo, {});
    setContentFilterInfo(payload);
  };

  return <Formizo height="100%" schema={schema} buttons={["Revert", "Submit"]} onSubmit={handleSubmit} defaultValue={ContentFilterInfo} />;
};

export { schema };
export default SysContentFilter;
