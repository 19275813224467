import { Box, TextField } from "@mui/material";
import MDEditor, { commands, ICommand, EditorContext } from "@uiw/react-md-editor";
import { useEffect, useState } from "react";
import "./md-editor.css";

interface IProps {
  value: string | undefined;
  onChange?: (value: string | undefined) => void;
  allowEdit?: boolean;
}
export default function FormMDEditor(props: IProps) {
  const [value, setValue] = useState<string | undefined>("**Hello world!!!**");

  useEffect(() => {
    setValue(props.value);
  }, [props.value]);

  const handleChange = (value: string | undefined) => {
    setValue(value);

    if (props.onChange) props.onChange(value);
  };

  return (
    <Box className="container" sx={{ width: "100%", height: "100%", overflow: "scroll" }}>
      {/* <MDEditor value={value} onChange={handleChange} visibleDragbar={true} preview="edit" fullscreen={true} /> */}
      {/* <MDEditor.Markdown source={value} style={{ whiteSpace: "pre-wrap" }} /> */}
      <TextField multiline fullWidth value={value} onChange={(e) => handleChange(e.target.value)} disabled={props.allowEdit} />
    </Box>
  );
}
