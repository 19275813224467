import React from "react";

import _ from "lodash";
import { FormControl, FormHelperText } from "@mui/material";

import { Accessor } from "static";
import { StyledInput, StyledTextField } from "Components/LabIZO/Stylizo";

interface IFFNumberProps {
  //data
  ischema: any;
  iname: string;
  addOns: any;

  //root func
  _onValueChange: (name: string, value: any, criteria: any) => void;
  _onBlurInlineSubmit: any;
  _onInlineSubmit: any;
  _onFieldFocus: () => void;
  _onFieldBlur: () => void;

  //disability
  errorsShowOnHelperText: boolean;
  readOnly: boolean;

  //runtime
  formValue?: any;
  formError: any;

  //style
  ifieldStyle: string;
  fieldSize?: string;
  theme?: any;
}

export const FFNumber: React.FC<IFFNumberProps> = (props) => {
  const { formValue, formError, iname, ischema, readOnly, errorsShowOnHelperText, theme, fieldSize, _onFieldFocus, _onFieldBlur, _onBlurInlineSubmit, _onValueChange, ifieldStyle } = props;

  const toNumber = (value?: string) => {
    return value ? Number(value) : undefined;
  };

  const renderInput = () => {
    if (!ischema) return null;

    let ivalue = Accessor.Get(formValue, iname);
    if (ivalue === undefined || ivalue === null) ivalue = "";
    let ierror = Accessor.Get(formError, iname);
    let ireadOnly = ischema.readOnly || readOnly;

    let helperText = ischema.helperText;
    if (errorsShowOnHelperText) {
      helperText = ierror;
    }

    return (
      <FormControl
        error={!_.isEmpty(ierror)}
        disabled={ireadOnly}
        fullWidth={ischema.fullWidth !== false}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
          _onValueChange(iname, toNumber(e.target.value), ischema.validate);
        }}
        onFocus={(e) => {
          _onFieldFocus();
        }}
        onBlur={(e) => {
          _onFieldBlur();
          _onBlurInlineSubmit(iname);
        }}
        defaultValue={ischema.defaultValue}
      >
        <StyledInput
          value={ivalue}
          type="number"
          inputProps={{
            min: ischema.min,
            max: ischema.max,
            step: ischema.step,
          }}
          placeholder={ischema.placeholder}
          startAdornment={ischema.before}
          endAdornment={ischema.after}
          theme={theme && theme.textfield}
        />
        {!_.isEmpty(helperText) && <FormHelperText>{helperText}</FormHelperText>}
      </FormControl>
    );
  };

  const renderTextField = () => {
    let ivalue = Accessor.Get(formValue, iname);
    if (ivalue === undefined || ivalue === null) ivalue = "";
    let ierror = Accessor.Get(formError, iname);
    let ireadOnly = ischema.readOnly || readOnly;

    let helperText = ischema.helperText;
    if (errorsShowOnHelperText) {
      helperText = ierror;
    }

    return (
      <StyledTextField
        value={ivalue}
        type="number"
        label={ischema.label || ""}
        helperText={helperText || ""}
        placeholder={ischema.placeholder || ""}
        onChange={(e: { target: { value: any } }) => _onValueChange(iname, toNumber(e.target.value), ischema.validate)}
        onFocus={(e: any) => {
          _onFieldFocus();
        }}
        onBlur={(e: any) => {
          _onFieldBlur();
          _onBlurInlineSubmit(iname);
        }}
        name={iname}
        variant={ifieldStyle}
        fullWidth={ischema.fullWidth !== false}
        error={!_.isEmpty(ierror)}
        disabled={ireadOnly}
        InputProps={{
          startAdornment: ischema.before,
          endAdornment: ischema.after,
          inputProps: {
            min: ischema.min,
            max: ischema.max,
            step: ischema.step,
          },
        }}
        defaultValue={ischema.defaultValue}
        size={fieldSize}
        theme={theme && theme.textfield}
      />
    );
  };

  if (!ischema) return null;
  if (!ifieldStyle || ifieldStyle === "grid") {
    return renderInput();
  } else {
    return renderTextField();
  }
};
