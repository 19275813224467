import { AnswerSetup } from "../FLESetup/FLETabs/AnswerSetup";
import { ContextSetup } from "../FLESetup/FLETabs/ContextSetup";
import { QuestionSetup } from "../FLESetup/FLETabs/QuestionSetup";
import { ExampleGenerationSetup } from "../FLESetup/FLETabs/ExampleGenerationSetup";
import { CusAuth } from "interfaces";

export interface IFLETab {
  name: string;
  component: (id: string) => JSX.Element;
  reqAuth?: string;
  reqFunc?: string;
  mode?: {
    include?: ("Add" | "Edit" | "Info")[];
    // exclude?: string[];
  };
}
export const defaultTabs: IFLETab[] = [
  { name: "Question", component: () => <QuestionSetup /> },
  {
    name: "Question Generation",
    component: () => <ExampleGenerationSetup />,
    reqAuth: "FLE",
    reqFunc: CusAuth.IntentGeneration,
    mode: { include: ["Add", "Edit"] },
  },
  { name: "Context", component: () => <ContextSetup /> },
  { name: "Answer", component: (id) => <AnswerSetup /> },
];
