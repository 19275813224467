import { store } from "static";
import MenuButton from "../MenuButton/MenuButton";
import { getClassicMenuSchema, getMenuConfigModeByLandingPage, getMenuConfigModesForUser } from "../constants/classic-menu-schema";
import { VStack } from "Components/Layouts";
import { ClassicMenuConfigMode } from "../Menu.interface";
import { SwitchLeft, SwitchRight } from "@mui/icons-material";
import { observer } from "mobx-react-lite";
import { useEffect, useState } from "react";
import { LandingPageSetting } from "Pages/UserControl/SysRole/Component/RoleInfo";

export interface ClassicMenuLayoutProps {
  location: Location;
  mini: boolean;
}

const ClassicMenuLayout: React.FC<ClassicMenuLayoutProps> = ({ location, mini }) => {
  const [showModeToggle, setShowModeToggle] = useState<boolean>(false);

  useEffect(() => {
    if (!store.classicMenuMode) {
      // menuMode is only null on first render
      const menuMode = getMenuConfigModeByLandingPage(store.user.landingPage as LandingPageSetting);
      store.setClassicMenuMode(menuMode);
    }
    if (!store.user?.authority) throw new Error("Store: User authority should not be null or undefined"); // this line is for type check
    const multiModesAvailable = getMenuConfigModesForUser(store.user.authority, [], new Set<ClassicMenuConfigMode>()).size > 1;
    setShowModeToggle(multiModesAvailable);
  }, []);

  const ClassicMenuConfigModeToggle = () => {
    if (!showModeToggle) return <></>;
    const icon = store.classicMenuMode === ClassicMenuConfigMode.ML ? <SwitchLeft /> : <SwitchRight />;
    return (
      <div style={{ position: "absolute", bottom: 0 }}>
        <MenuButton
          location={location}
          id="toggle-menu-mode"
          key="toggle-menu-mode"
          caption="Toggle Menu Mode"
          icon={icon}
          onClick={() => store.setClassicMenuMode(store.classicMenuMode === ClassicMenuConfigMode.ML ? ClassicMenuConfigMode.NLP : ClassicMenuConfigMode.ML)}
        />
      </div>
    );
  };

  if (store.classicMenuMode === null || !store.user) return <></>;

  return (
    <VStack width="100%" sx={{ paddingTop: "40px" }}>
      {getClassicMenuSchema(store.classicMenuMode).map((item) => (
        <MenuButton
          location={location}
          path={item.link}
          icon={item.icon}
          reqAuth={item.reqAuth}
          reqLevel={item.reqLevel}
          subMenu={item.subMenu}
          disabled={item.disabled}
          id={item.id}
          key={item.caption}
          caption={item.caption}
          mini={mini}
        />
      ))}
      <ClassicMenuConfigModeToggle />
    </VStack>
  );
};

export default observer(ClassicMenuLayout);
