import { styled } from "@mui/system";
import "./ZCMsg.css";
import { Box, Button, Rating, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { store } from "static";
import { HtmlParserWrapper } from "Components/HtmlParserWrapper";
import { backendConnector, BackendConnector, DATA_LINK } from "connectors";
import { AvailLANG } from "interfaces";
import { Star } from "@mui/icons-material";
export interface IRatingProps {
  messageid?: string;
  sessionid?: string;
  intent?: string;
  cssp?: string;
}
const StyledBox = styled(Box)(({ theme }) => ({
  padding: "5px",
  borderRadius: theme.shape.borderRadius,
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  gap: theme.spacing(1),
}));

const multiLangQuestion: Record<AvailLANG, string> = {
  [AvailLANG.EN]: "Do you agree I can help you?",
  [AvailLANG.SC]: "你认同我可以帮助你吗？",
  [AvailLANG.TC]: "你認同我可以幫助你嗎？",
};
const multiLangSubmit: Record<AvailLANG, string> = {
  [AvailLANG.EN]: "Submit",
  [AvailLANG.SC]: "提交",
  [AvailLANG.TC]: "提交",
};

const multiLangThankYou: Record<AvailLANG, string> = {
  [AvailLANG.EN]: "Thank you for your rating.",
  [AvailLANG.SC]: "感謝您的意見",
  [AvailLANG.TC]: "感謝您的意見",
};

const ZCMRating: React.FC<IRatingProps> = ({ messageid, sessionid, intent, cssp }) => {
  const [value, setValue] = useState<number | null>(null);
  const [isSubmitted, setIsSubmitted] = useState(false);

  const handleSubmit = async () => {
    const res = await backendConnector.post(DATA_LINK.UserFeedback, {
      messageId: messageid,
      star: value,
      deviceId: store.user.Username,
      sessionId: sessionid,
      intent: intent,
      role: store.role,
    });
    if (res) {
      setIsSubmitted(true);
    }
  };

  if (isSubmitted) {
    return (
      <div className={cssp + " msg-text"} style={{ whiteSpace: "pre-line" }}>
        <HtmlParserWrapper html={multiLangThankYou[store.chatbotLang as keyof typeof multiLangThankYou]} />
      </div>
    );
  }

  const renderThankYou = () => {
    return <HtmlParserWrapper html={multiLangThankYou[store.chatbotLang]} />;
  };

  const renderRating = () => {
    return (
      <StyledBox>
        <Typography>{multiLangQuestion[store.chatbotLang]}</Typography>
        <Rating
          name="feedback-rating"
          value={value}
          onChange={(event, newValue) => {
            setValue(newValue);
          }}
          emptyIcon={<Star style={{ color: "grey" }} fontSize="inherit" />}
        />
        <Button sx={{ justifyContent: "flex-end" }} onClick={handleSubmit} disabled={value === null}>
          {multiLangSubmit[store.chatbotLang]}
        </Button>
      </StyledBox>
    );
  };

  return (
    <div className={cssp + " msg-text"} style={{ whiteSpace: "pre-line" }}>
      {isSubmitted ? renderThankYou() : renderRating()}
    </div>
  );
};
export default ZCMRating;
