import { HStack } from "Components/LabIZO/Stackizo";

import PasswordChanger from "Components/PasswordChanger/PasswordChanger";
import EmailChanger from "Components/EmailChanger/EmailChanger";

import { store } from "static";
import { TablizoSchema } from "Components/LabIZO/Tablizo";
import { FormizoSchema } from "Components/LabIZO/Formizo";
import { Typography } from "@mui/material";
import UnlockUser from "./UnlockUser";

const Head: TablizoSchema[] = [
  {
    label: "Display Name",
    name: "UserDisplayName",
    width: 200,
  },
  {
    label: "Username",
    name: "username",
    width: 200,
  },
  {
    label: "Role",
    name: "role",
  },
  {
    label: "Email",
    name: "email",
  },
  {
    label: "Last Password Update",
    name: "LastPasswordUpdate",
    transform: "datetime",
    dateFormat: "YYYY/MM/DD HH:mm:ss",
  },
  {
    label: "Last Update",
    name: "LASTMODIFIED",
    transform: "datetime",
    dateFormat: "YYYY/MM/DD HH:mm:ss",
  },
];

const Table: TablizoSchema[] = [
  ...Head,
  {
    label: "Actions",
    name: "",
    width: 500,
    Cell: (row, field, addOns) => {
      return (
        <HStack gap={1}>
          {store.user.Username == row.username && <PasswordChanger username={row.username} />}
          {store.user.Username == row.username && <EmailChanger username={row.username} />}
          {row.failLoginCount >= 5 && <UnlockUser username={row.username} />}
        </HStack>
      );
    },
  },
];

const Add: FormizoSchema[] = [
  {
    label: "Display Name",
    name: "UserDisplayName",
    validate: ["required"],
    format: "text",
  },
  {
    label: "Username",
    name: "username",
    validate: ["required"],
    format: "text",
  },
  {
    label: "Email",
    name: "email",
    validate: ["email", "required"],
    format: "text",
  },
  {
    label: "Password",
    name: "password",
    validate: ["required", "minimum8", "comprise"],
    format: "password",
  },
  {
    label: "Role",
    name: "role",
    format: "select",
    selectStyle: "dropdown",
    selectRef: "roleList",
    selectCap: "name",
    selectVal: "name",
    validate: ["required"],
  },
  // {
  //   label: "Instance",
  //   name: "UserInstance",
  //   format: "select",
  //   selectStyle: "dropdown",
  //   selectRef: "InstanceList",
  //   selectCap: "name",
  //   selectVal: "_id",
  // },
  {
    control: "UserInstance",
    controlFunc: (doc: any, field: any) => field === "_Custom",
    fold: [
      {
        label: "Custom Instance Name",
        name: "CustomInstanceName",
        format: "text",
        validate: ["plain"],
      },
      {
        label: "Model",
        name: "model",
        format: "multiSelect",
        selectStyle: "autocomplete",
        optionType: "addOns",
        selectRef: "modelList",
        selectCap: "name",
        selectVal: "id",
      },
      {
        label: "",
        format: "display",
        Custom: (data, field, state) => {
          return (
            <Typography
              sx={{
                color: "grey",
              }}
            >
              Please note that the first model in the list will be the default model for the instance. And the other models will be the alternative models.
            </Typography>
          );
        },
      },
    ],
  },
];

const Info: FormizoSchema[] = [
  {
    label: "Display Name",
    name: "UserDisplayName",
    format: "text",
    validate: ["required"],
  },
  {
    label: "Username",
    name: "username",
    format: "text",
    readOnly: true,
  },
  {
    label: "Email",
    name: "email",
    format: "text",
    validate: ["email"],
  },
  {
    label: "Fail Login Count",
    name: "failLoginCount",
    format: "text",
  },
  {
    label: "Role",
    name: "role",
    format: "text",
  },
];

const Edit: FormizoSchema[] = [
  {
    label: "Display Name",
    name: "UserDisplayName",
    format: "text",
    validate: ["required"],
  },
  {
    label: "Username",
    name: "username",
    format: "text",
    readOnly: true,
  },
  {
    label: "Email",
    name: "email",
    format: "text",
    validate: ["email"],
  },
  {
    label: "Role",
    name: "role",
    format: "select",
    selectStyle: "dropdown",
    selectRef: "roleList",
    selectCap: "name",
    selectVal: "name",
    validate: ["required"],
  },
];

const Export: FormizoSchema[] = [];

const Import: FormizoSchema[] = [];

const ImportFormat = [...Export];

const Filter: FormizoSchema[] = [];

const schema = {
  Table,
  Info,
  Add,
  Edit,
  Export,
  Import,
  ImportFormat,
  Filter,
};

export default schema;
