import { Box, SxProps, Theme } from "@mui/material";
import { ResponsiveStyleValue } from "@mui/system";
import { SystemProps } from "@mui/system/Box";
import { Property } from "csstype";
import React, { forwardRef, Ref } from "react";

export interface StackTypes extends Omit<SystemProps<Theme>, "flexDirection"> {
  flexWrap?: ResponsiveStyleValue<Property.FlexWrap | Property.FlexWrap[] | undefined> | ((theme: Theme) => ResponsiveStyleValue<Property.FlexWrap | Property.FlexWrap[] | undefined>);
  justifyContent?:
    | ResponsiveStyleValue<Property.JustifyContent | Property.JustifyContent[] | undefined>
    | ((theme: Theme) => ResponsiveStyleValue<Property.JustifyContent | Property.JustifyContent[] | undefined>);
  alignContent?:
    | ResponsiveStyleValue<Property.AlignContent | Property.AlignContent[] | undefined>
    | ((theme: Theme) => ResponsiveStyleValue<Property.AlignContent | Property.AlignContent[] | undefined>);
  alignItems?: ResponsiveStyleValue<Property.AlignItems | Property.AlignItems[] | undefined> | ((theme: Theme) => ResponsiveStyleValue<Property.AlignItems | Property.AlignItems[] | undefined>);
  width?:
    | ResponsiveStyleValue<Property.Width<string | number> | Property.Width<string | number>[] | undefined>
    | ((theme: Theme) => ResponsiveStyleValue<Property.Width<string | number> | Property.Width<string | number>[] | undefined>);
  gap?:
    | string
    | ResponsiveStyleValue<Property.Gap<string | number> | Property.Gap<string | number>[] | undefined>
    | ((theme: Theme) => ResponsiveStyleValue<Property.Gap<string | number> | Property.Gap<string | number>[] | undefined>);
  sx?: SxProps<Theme>;
  style?: React.CSSProperties;
  children: React.ReactNode | React.ReactNode[];
  onClick?: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
  onMouseEnter?: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
  onMouseLeave?: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
  id?: string;
  className?: string;
}

const HStack = forwardRef((props: StackTypes, ref: Ref<HTMLDivElement>) => {
  const { flexWrap = "nowrap", justifyContent = "center", alignContent, alignItems = "center", width = "100%", gap = 1, children, id, style, sx, ...other } = props;

  return (
    <Box
      id={id}
      className={other.className ? other.className : "HStack"}
      display="flex"
      flexDirection="row"
      flexWrap={flexWrap}
      justifyContent={justifyContent}
      alignContent={alignContent}
      alignItems={alignItems}
      width={width}
      gap={gap}
      ref={ref}
      sx={{
        ...style,
        ...sx,
      }}
      {...other}
    >
      {children}
    </Box>
  );
});
HStack.displayName = "HStack";
export default HStack;
