import { IDocumentManager } from "../../interfaces/document-manager-api.interface";
import { useState } from "react";
import { VStack } from "Components/Layouts";
import { FieldErrors, FormProvider, useForm, useFormContext } from "react-hook-form";
import { Box, Grid, List, ListItem, ListItemIcon, ListItemText, Typography } from "@mui/material";
import { DocUploader } from "Components/Chat/_gears/SlideMenu/doc-uploader";
import { IDocDiff } from "./interfaces/document-diff.interface";
import { ReuploadViewSwap } from "./components/ReuploadViewSwap";
import { ConfirmReuploadPopup } from "./components/ConfirmReuploadPopup";
import { IDocumentUploader } from "Components/_PageComponents/interfaces/document-uploader.interface";
import { backendConnector, DATA_LINK } from "connectors";
import { ErrorX, store } from "static";
import { DocumentUploadFormContext } from "../hooks/upload-hooks";
import { Warning } from "@mui/icons-material";

export default function DocumentReupload({ onSubmit, onQuitRefresh }: { mode: "Add" | "Edit" | "Info"; onSubmit: (quitForm?: boolean) => void; onQuitRefresh: (_id: string) => void }) {
  const { getValues } = useFormContext<IDocumentManager>();
  const [compare, setCompare] = useState<boolean>(true);
  const [finishCompare, setfinishCompare] = useState<boolean>(false);
  const [newText, setNewText] = useState<IDocDiff | null>(null);
  const [newFile, setNewFile] = useState<{ file: File; addOns: any } | null>(null);
  const [confirm, setConfirm] = useState<boolean>(false);
  const methods = useForm<IDocumentUploader>({
    defaultValues: {
      readByPage: false,
      ocr: false,
      summaryInstruction: `以下是客戶服務中心的對話，請整合對話內容，並用 bullet points 撰寫摘要。只需摘要重要內容，不需全部摘要。
然後，跟據對話內容，提取對話的背景資料。
e.g.
1. 客戶姓名: [客戶姓名]
2. 員工姓名: [員工姓名]
3. 客戶查詢: [客戶查詢]
4. 客戶情緒: [客戶情緒]`,
    },
    mode: "all",
  });

  const handleSubmit = (setLoading: (loading: boolean) => void, setAlert: (alert: { message: string; type: "success" | "error" | "info" }) => void) => {
    const submitStatus = {
      valid: async (_newRow: IDocumentUploader) => {
        setLoading(true);
        console.log(`Valid Submit: ${JSON.stringify(_newRow)}`);
        try {
          const { files, alias, fileTypes, ocr, reference, readByPage, languages, summaryInstruction, audioLang } = _newRow;
          setLoading(true);
          const file = files[0];
          const fileName = alias[0];
          const fileType = fileTypes[0];
          const addOns = {
            ocr: ocr,
            filename: fileName.trim(),
            filetype: fileType,
            reference: reference,
            readByPage: readByPage,
            reupload: true,
            translated_language: languages,
            oldDocID: getValues("_id"),
            summaryInstruction: summaryInstruction,
            audio_language: audioLang,
          };
          const { Success, payload } = await backendConnector.upload<any>(DATA_LINK.DMCompare, file, addOns);
          setLoading(false);
          setNewText(payload);
          setNewFile({ file: file, addOns: addOns });
          if (Success) {
            if (setNewText) setNewText(payload);
            if (setNewFile) setNewFile({ file: file, addOns: addOns });
          } else {
            setAlert({ message: "Upload Failed", type: "error" });
          }
          setCompare(!Success);
          setfinishCompare(Success);
        } catch (err: any) {
          setLoading(false);
          ErrorX.Handle(err);
          store.Alert(`Upload Failed: ${err?.message ?? err}`, "error");
        }
      },
      invalid: (errors: FieldErrors<IDocumentUploader>) => {
        setLoading(false);
        store.Alert(`Upload Failed: ${errors}`, "error");
      },
    };

    methods.handleSubmit(submitStatus.valid, submitStatus.invalid)();
  };

  const renderWarningMessage = () => {
    const childrenAlias = getValues("documentLink")?.childAlias;

    return (
      <Box width="100%" display="flex" flexDirection="column" gap={2}>
        <Typography fontSize={"0.9rem"} color="error">
          Reuploading will overwrite the existing document. Please proceed with caution.
        </Typography>
        {childrenAlias && childrenAlias.length > 0 && (
          <Box>
            <Typography fontSize={"0.9rem"} color="error">
              The below documents will be deleted if you proceed:
            </Typography>
            <List dense>
              {childrenAlias.map((alias: string) => (
                <ListItem key={alias}>
                  <ListItemIcon>
                    <Warning color="error" />
                  </ListItemIcon>
                  <ListItemText primary={alias} primaryTypographyProps={{ fontSize: "0.9rem", color: "error" }} />
                </ListItem>
              ))}
            </List>
          </Box>
        )}
      </Box>
    );
  };
  return (
    <VStack width="100%" height="100%">
      <Grid item xs={12} sm={10}>
        {confirm && <ConfirmReuploadPopup newFile={newFile} confirm={confirm} setConfirm={setConfirm} setfinishCompare={setfinishCompare} onQuitRefresh={onQuitRefresh} />}
        {compare ? (
          <VStack width={"80vh"}>
            <FormProvider {...methods}>
              <DocumentUploadFormContext.Provider value={{ mode: "Reupload" }}>
                <DocUploader onUpload={handleSubmit} reupload={true} renderWarningMessage={renderWarningMessage} />
              </DocumentUploadFormContext.Provider>
            </FormProvider>
          </VStack>
        ) : finishCompare ? (
          <VStack width={"80vh"}>
            <ReuploadViewSwap newText={newText} newFile={newFile} setfinishCompare={setfinishCompare} setCompare={setCompare} setConfirm={setConfirm} onSubmit={onSubmit} />
          </VStack>
        ) : (
          <></>
        )}
      </Grid>
    </VStack>
  );
}
