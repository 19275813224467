import { DatePicker } from "Components/DatePicker/DatePicker";
import { DateValue } from "Components/DatePicker/DatePicker";
import { Accessor } from "static";
import { IFDate } from "../../FormizoSchema.interface";
import type { Moment } from "moment";
import moment from "moment";

interface IFFDateProps {
  formValue: any;
  ischema: IFDate;
  _onFieldFocus: () => void;
  _onFieldBlur: () => void;
  _onValueChange: (name: string, value: Moment, criteria: any) => void;
  _onBlurInlineSubmit: (name: string) => void;
}

export const FFDate: React.FC<IFFDateProps> = ({ formValue, ischema, _onFieldFocus, _onFieldBlur, _onBlurInlineSubmit, _onValueChange }) => {
  if (!ischema) return null;
  const ivalue = Accessor.Get(formValue, ischema.name);
  const date = typeof ivalue === "string" ? moment(ivalue) : ivalue || null;

  const handleDateChange = (newDate: DateValue, _: string | string[]) => {
    if (newDate) {
      _onValueChange(ischema.name, newDate, ischema.validate);
    }
  };

  return (
    <DatePicker
      date={date}
      onChangeDate={handleDateChange}
      dateFormat={ischema.dateFormat}
      minDate={ischema.minDate}
      maxDate={ischema.maxDate}
      helperText={ischema.helperText}
      handleFocus={(_: any) => {
        _onFieldFocus();
      }}
      handleBlur={(_: any) => {
        _onFieldBlur();
        _onBlurInlineSubmit(ischema.name);
      }}
    />
  );
};
