import { FormizoSchema } from "Components/LabIZO/Formizo/FormizoSchema.interface";
import CloudSyncIcon from "@mui/icons-material/CloudSync";

/** ---------------- Main Config Schema ----------------- */

const watsonAssistantSettings: FormizoSchema = {
  label: "Watson Assistant Settings",
  foldStyle: "outlined",
  defaultShow: true,
  collapse: [
    {
      label: "Sync Dialogue Flow",
      name: "syncDialogueFlow",
      format: "button",
      buttonColor: "blue",
      tooltipTitle: "Sync",
      iconComponent: <CloudSyncIcon style={{ fontSize: "26px" }} />,
    },
  ],
};

const quickReplyControl: FormizoSchema = {
  control: "talk2va.quick_reply.enabled",
  fold: [
    {
      label: "Quick Reply Buttons",
      name: "talk2va.quick_reply.quickReplies",
      canAdd: true,
      canDelete: true,
      headerStyle: "outlined",
      addStyle: "header",
      array: [
        {
          label: "Display",
          width: 50,
          name: "title",
          tabs: [
            {
              label: "EN",
              page: [
                {
                  label: "Display",
                  name: "title.EN",
                  format: "text",
                },
              ],
            },
            {
              label: "TC",
              page: [
                {
                  label: "Display",
                  name: "title.TC",
                  format: "text",
                },
              ],
            },
            {
              label: "SC",
              page: [
                {
                  label: "Display",
                  name: "title.SC",
                  format: "text",
                },
              ],
            },
          ],
          validate: ["required"],
        },
        {
          label: "Button Type",
          name: "type",
          format: "select",
          width: "100",
          options: [
            { label: "FAQ/Context Action", value: "text" },
            { label: "External Link", value: "web" },
          ],
          defaultValue: "text",
        },
        {
          control: "type",
          controlFunc: (doc, field) => field === "text",
          fold: [
            {
              label: "Button Payload",
              name: "payload",
              format: "select",
              selectStyle: "dropdown",
              selectRef: "intents",
              selectCap: "caption",
              selectVal: "_id",
              placeholder: "Intent",
              validate: ["required"],
              variant: "grid",
            },
          ],
        },
        {
          control: "type",
          controlFunc: (doc, field) => field === "web",
          fold: [
            {
              label: "URL",
              name: "payload",
              format: "text",
            },
          ],
        },
      ],
    },
  ],
};

const T2GPTSettings: FormizoSchema = {
  label: "Talk to GPT Virtual Assistant Settings",
  foldStyle: "outlined",
  defaultShow: true,
  collapse: [
    {
      label: "Enable citation on chatbot",
      name: "citation.enabled",
      format: "bool",
    },
    {
      label: "Enable Quick Reply Buttons",
      name: "talk2va.quick_reply.enabled",
      format: "bool",
      defaultValue: false,
    },
    quickReplyControl,
    {
      label: "Enable Followup Question",
      name: "followUpQuestions.enabled",
      format: "bool",
      defaultValue: false,
    },
    {
      label: "Enable Web Search",
      name: "talk2va.web_search.enabled",
      format: "bool",
      defaultValue: false,
    },
    {
      label: "Search Engine Type",
      name: "talk2va.web_search.type",
      format: "text",
    },
    {
      label: "Google Search ID",
      name: "talk2va.web_search.google.search_engine_id",
      format: "text",
    },
    {
      label: "Username",
      name: "talk2va.web_search.username",
      format: "text",
    },
    {
      label: "Password",
      name: "talk2va.web_search.password",
      format: "text",
    },
    {
      label: "API Endpoint",
      name: "talk2va.web_search.api_endpoint",
      format: "text",
    },
    {
      label: "Document Base Path",
      name: "doc_base_path",
      format: "text",
    },
  ],
};

const schema: FormizoSchema[] = [
  // {
  //   reqAuth: "System.NLP",
  //   reqFunc: "Watson",
  //   accessizo: [watsonAssistantSettings],
  // },
  {
    reqAuth: "System.NLP",
    reqFunc: "Talk2GPT",
    accessizo: [T2GPTSettings],
  },
  // {
  //   reqAuth: "System.NLP",
  //   reqFunc: "EmbeddingModel",
  //   accessizo: [embeddingModelConfig],
  // },
  // {
  //   reqAuth: "System.NLP",
  //   reqFunc: "CompletionModel",
  //   accessizo: [completionModelConfig],
  // },
];

export default schema;
