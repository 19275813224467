import React from 'react';

import ZCMImage from '../ZCMImage';
import ZCMVideo from '../ZCMVideo';

import _ from 'lodash';

import "./ZCMTags.css";

class ZTagParser {

  /**
   * Special Tag
   * 
   * <url href='@url'>{children}</url>
   * 
   * <mail href='@url'></mail>
   * 
   * <map lang='@string'></map>
   * 
   * <videourl>{children}</videourl>
   * 
   * <imageurl>{children}</imageurl>
   * 
   */

  static Parse(cssPrefix, parsed, iaddOns){
    
    if(_.isString(parsed)) return parsed;
    
    if(!Array.isArray(parsed)) {
      parsed = [parsed];
    }
    
    let rendered = [];
    _.map(parsed, (o, i) => {

      let {href, children, target, src, title, poster, option} = o.props || {};

      switch(o.type){
        case 'url':
          rendered.push(
            <div key={o.key} 
              className={cssPrefix + " zchat-msg-htmltag-url"} 
              onClick={() => window.open(href,
              target || "_blank",
              option || "resizable=1, width=800, height=600, scrollbars=yes")}>
              {children}
            </div>
          );
          break;
        
        case 'mail':
          rendered.push(
            <a key={o.key} 
              className={cssPrefix + " zchat-msg-htmltag-mail"} 
              href={"mailto:" + href}>
              {children}
            </a>
          );
          break;

        case 'map':
          console.log("map", o);
          break;

        case 'imageurl':
          rendered.push(
            <ZCMImage 
              key={o.key} 
              cssPrefix={cssPrefix}
              onImageClick={() => {
                if(href) {
                  window.open(href,
                    target || "_blank",
                    option || "resizable=1, width=800, height=600, scrollbars=yes");
                }}}
              src={src}
              title={title}
              >
            </ZCMImage>
          );
          break;

        case 'videourl':
          rendered.push(
            <div key={o.key} 
              className={cssPrefix + " zchat-msg-htmltag-video"}>
              <ZCMVideo 
                key={o.key} 
                cssPrefix={cssPrefix}
                video={{
                  src: src,
                  poster: poster
                }}
                >
              </ZCMVideo>
            </div>
          );
          break;
        
        default:
          if(children && Array.isArray(children)){
            rendered.push(this.Parse(cssPrefix, children, iaddOns));
          }else{
            rendered.push(o);
          }

      }
    });
    return rendered;
  }
  
}

export default ZTagParser;