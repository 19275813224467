import { Box, Grid, Typography } from "@mui/material";
import { ColorX } from "static";
import React from "react";
import { ITextDifference } from "../interfaces/document-diff.interface";

export const CompareDiff = ({ diff, action }: { diff: ITextDifference[]; action?: "removed" | "added" }) => {
  const renderDocDiff = (_diff: ITextDifference[], action?: "removed" | "added") => {
    const renderLines = (lines: string[], keyColor?: string, isChange?: boolean, _changeIndex?: number) => (
      <>
        <span style={{ backgroundColor: keyColor, whiteSpace: "pre" }}>
          {lines.map((line: string, lineIndex: number) => (
            <React.Fragment key={lineIndex}>
              {line}
              {lineIndex !== lines.length - 1 && <br />}
            </React.Fragment>
          ))}
        </span>
        {isChange && <sup>{_changeIndex}</sup>}
      </>
    );

    let changeNumber = 0;
    return (
      <Box sx={{ width: "100%", height: "90%", overflowY: "scroll", overflowX: "hidden", lineHeight: "normal" }}>
        {_diff.length === 1 && <span style={{ color: "grey" }}>No changes on new document comparing to original one.</span>}
        {_diff.length > 1 &&
          _diff.map((part: any, index: number) => {
            const { value, added, removed } = part;
            const keyColor = added ? ColorX.GetColorCSS("Insert") : removed ? ColorX.GetColorCSS("Delete") : "inherit";
            const splitValue = value.split("\n");

            const isChange = (added || removed) ?? false;
            if (isChange) {
              changeNumber += 1;
            }

            if (action === "added") {
              if (!removed) {
                return <span key={index}>{renderLines(splitValue, keyColor, isChange, changeNumber)}</span>;
              }
            } else if (action === "removed") {
              if (!added) {
                return <span key={index}>{renderLines(splitValue, keyColor, isChange, changeNumber)}</span>;
              }
            } else {
              return <span key={index}>{renderLines(splitValue, keyColor, isChange, changeNumber)}</span>;
            }

            return null;
          })}
      </Box>
    );
  };

  return (
    <Grid item xs={12} sm={6} sx={{ width: "100%", height: "100%", overflow: "hidden", padding: "10px" }}>
      <Typography variant="body1" color="primary.main" style={{ font: "bold normal normal 16px/21px Segoe UI", marginBottom: "10px" }}>
        {action === "removed" ? "Old Changes" : "Incoming Changes"}
      </Typography>
      <Grid item xs={12} sm={12} sx={{ width: "100%", height: "100%" }}>
        {renderDocDiff(diff, action)}
      </Grid>
    </Grid>
  );
};
