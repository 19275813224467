import { FormizoSchema } from "Components/LabIZO/Formizo";

export const schema: FormizoSchema[] = [
  //Model Config
  {
    label: "Model Config",
    name: "models",
    format: "array",
    canAdd: true,
    canDelete: true,
    canMove: true,
    array: [
      {
        label: "Model ID",
        name: "id",
        format: "text",
        validate: ["required"],
      },
      {
        label: "Model Name",
        name: "name",
        format: "text",
        validate: ["required"],
      },
      {
        label: "Model Type",
        name: "model",
        format: "text",
      },
      {
        label: "Service URL",
        name: "url",
        format: "text",
        validate: ["required"],
      },
      {
        label: "API Limit",
        name: "limit",
        format: "number",
      },
    ],
  },
];
