import { FormControl, MenuItem, Select } from "@mui/material";
import { HStack } from "Components";
import { StyledTypeTypography } from "../StyledComponent";

interface DropDownPanelProps {
  label: string;
  value: string;
  options: { label: string; value: string }[];
  onChange: (value: string) => void;
}

export const DropDownPanel = ({ label, value, options, onChange }: DropDownPanelProps) => {
  return (
    <HStack width="100%" gap={2} justifyContent={"flex-start"}>
      <StyledTypeTypography>{label}</StyledTypeTypography>
      <FormControl sx={{ width: "40%" }}>
        <Select value={value} variant="outlined" onChange={(e) => onChange(e.target.value)}>
          {options.map((option, index) => (
            <MenuItem value={option.value} key={index}>
              {option.label}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </HStack>
  );
};
