import _ from "lodash";
import { Tooltip } from "@mui/material";
import { Warning, RemoveCircle } from "@mui/icons-material";

import CheckPass from "../CheckPass";
import NewIntent from "./NewIntent";
import "../TBank.css";
import ZEye from "../../../Components/ZEye/ZEye";
import { ZCMsg } from "Components/Messenger/components";

import { HStack, VStack } from "Components/LabIZO/Stackizo";
import { Accessor, ColorX } from "static";
import { RoleID } from "interfaces";

const Table = [
  {
    label: "Language",
    name: "lang",
    width: 115,
  },
  {
    label: "P. Time",
    name: "Before.processTime",
    width: 115,
  },
  {
    label: "Role",
    name: "role",
    width: 115,
  },
  {
    label: "Question",
    name: "Input.Content",
  },

  {
    label: "Answer",
    name: "Before.Response.0.msg.text",
  },
  {
    label: " ",
    name: "Before.Response",
    width: 60,
    Cell: (row, field, addOns) => (
      <HStack key={row._id}>
        <ZEye>
          <VStack>
            {_.map(field, (o, i) => {
              return <ZCMsg key={i} cssp="zchat z" _onQuickReply={() => {}} pos="in" item={o} last={true} typingBubbles={false} showQuickRepliesAsButtons={true} HTMLEnabled={true} />;
            })}
          </VStack>
        </ZEye>
      </HStack>
    ),
  },
  {
    label: "Accept",
    name: "Before.Tag.passed",
    width: 115,
    defaultSort: "asc",
    Cell: (row, field, addOns) => {
      let { trainID, onPassToggle } = addOns;
      if (!row.Before.Tag.ignored)
        return <CheckPass docID={row._id} doc={row} tagged={row.Before.Tag.tagged || (row.Before.Tag.preTagged && row.Before.Tag.passed)} passed={field} trainID={trainID} onToggle={onPassToggle} />;
      return <div />;
    },
  },
  {
    label: "Suggested Intent",
    name: "Before.Tag.newIntent",
    width: 300,
    Cell: (row, field, addOns) => {
      let { trainID, onNewToggle, onSuggestIntent, intents, answers } = addOns;
      let closeIntents = Accessor.Get(row, "Before.wsRes.master.intents");
      const role = row.role ?? RoleID.GENERAL_PUBLIC;
      let front = [];
      let back = [];
      let firstFive = !_.isEmpty(closeIntents) ? closeIntents.slice(0, 5) : [];
      let firstFiveIntentCap = _.map(firstFive, (o, i) => o.intent);
      _.map(intents, (o, i) => {
        if (firstFiveIntentCap.includes(o.caption) || i === 0) {
          front.push(o);
        } else {
          back.push(o);
        }
      });

      intents = [...front, ...back];

      intents = _.map(intents, (o, i) => {
        const ans = _.find(answers, (a) => a._id === o._id);
        if (ans) o = { ...o, ans };
        return o;
      });

      //Answer  preview mod
      const AnsLibLang = row.lang || "EN";
      answers = _.map(answers, (answer) => {
        let hasAns = answer?.[role]?.text && answer?.[role].text[AnsLibLang] && answer?.[role].text[AnsLibLang][0];

        //show answer of other language
        if (!hasAns) {
          answer.text = {
            ...answer.text,
            [AnsLibLang]: [],
          };
          _.map(answer.text, (o, lang) => {
            if (lang !== AnsLibLang && o && o[0]) {
              const warnText = `<b style='color:red;'>No (${AnsLibLang}) Answer was set. Displaying (${lang}) Answer.</b>`;
              answer.text[AnsLibLang] = [warnText + "<br>" + o[0]];
            }
          });

          //still no answer
          if (!answer.text[AnsLibLang][0]) answer.text[AnsLibLang] = ["<b style='color:red;'>No Answer found.</b>"];
        }

        return answer;
      });

      return (
        <NewIntent
          key={row._id}
          docID={row._id}
          doc={row}
          tagged={row.Before.Tag.tagged || row.Before.Tag.preTagged}
          passed={row.Before.Tag.passed}
          trainID={trainID}
          newIntent={field}
          onToggle={onNewToggle}
          onSelect={onSuggestIntent}
          suggestedIntent={row.Before.Tag.suggestedIntent}
          intents={intents}
          answers={answers}
          role={role}
          AnsLibLang={AnsLibLang}
        />
      );
    },
  },
  {
    label: "Warnings",
    name: "Before.Tag.preTagged",
    width: 120,
    Cell: (row, field, addOns) => {
      const renderWarn = () => {
        if (!row.Before.Tag.passed && !row.Before.Tag.suggestedIntent && row.Before.Tag.newIntent) {
          return (
            <Tooltip title={"Missing Suggested Intent"} key="Missing">
              <Warning style={{ color: ColorX.GetColorCSS("Cancel") }} />
            </Tooltip>
          );
        } else if (field && !row.Before.Tag.tagged) {
          return (
            <Tooltip title={"Tagged by Classifier"} key="Classifier">
              <Warning style={{ color: ColorX.GetColorCSS("Caution") }} />
            </Tooltip>
          );
        }
      };

      const renderGBTag = () => {
        if (row?.Before?.Tag.ignored) {
          return (
            <Tooltip title={"Detected as Garbage"} key="Garbage">
              <Warning style={{ color: ColorX.GetColorCSS("grey") }} />
            </Tooltip>
          );
        }
      };
      return (
        <HStack>
          {renderGBTag()}
          {renderWarn()}
        </HStack>
      );
    },
  },
  {
    label: "-",
    name: "Before.Tag.ignored",
    width: 80,
    Cell: (row, field, addOns) => {
      if (field) {
        return (
          <HStack>
            <Tooltip title={"Entry is Ignored"}>
              <RemoveCircle style={{ color: ColorX.GetColorCSS("grey") }} />
            </Tooltip>
          </HStack>
        );
      }
      return <HStack />;
    },
  },
];

const Info = [];

const schema = {
  Table,
  Info,
};

export default schema;
