import sanitizeHtml from "sanitize-html";
import htmlParser from "html-react-parser";
import { HTMLReactParserOptions } from "html-react-parser";
import { isValidElement } from "react";
import { Accessor } from "static";
import domToReact from "html-react-parser/lib/dom-to-react";

function isHTMLStringValid(htmlString: string) {
  try {
    // make a stack for checking if the tags are balanced
    let stack: string[] = [];

    for (let i = 0; i < htmlString.length; i++) {
      if (htmlString[i] === "<") {
        if (stack.length !== 0) return false;
        stack.push(htmlString[i]);
      } else if (htmlString[i] === ">") {
        if (stack.length > 1 || stack.length === 0) return false;
        else if (stack.length === 1) {
          stack.pop();
        }
      }
    }
    return stack.length === 0;
  } catch (error) {
    return false;
  }
}

export function HtmlParserWrapper(html: { html: string }, options?: HTMLReactParserOptions) {
  let htmlString = html.html;

  htmlString = sanitizeHtml(html.html, {
    allowedAttributes: { "*": ["*"] },
    parseStyleAttributes: false,
    allowedTags: sanitizeHtml.defaults.allowedTags.concat(["img", "iframe"]),
  });
  htmlString = htmlString.replace(/<a\s/gi, '<a target="_blank" rel="noopener noreferrer" style="color: #007bff; text-decoration: none;" ');

  return <>{htmlParser(htmlString, options ? options : {})}</>;
}

export function HtmlParserWrapperGetChildren(html: { html: string }, options?: HTMLReactParserOptions) {
  let htmlCodes = HtmlParserWrapper(html, options);
  const getChildrenRecursive = (children: JSX.Element["props"]["children"]) => {
    let text = "";
    if (typeof children === "string") return (text += " " + children);
    if (Array.isArray(children)) {
      for (let child of children) {
        text += " " + getChildrenRecursive(child);
      }
    }
    if (isValidElement(children)) {
      let innerChild = Accessor.Get(children, "props.children");
      if (innerChild) text += " " + getChildrenRecursive(innerChild);
    }
    return text;
  };
  return getChildrenRecursive(htmlCodes.props.children);
}

export function ConcatenateChildren() {}

export function ColumnHtmlParser(html: { html: string }, options?: HTMLReactParserOptions): ReturnType<typeof domToReact> {
  let htmlString = html.html;

  htmlString = sanitizeHtml(html.html, {
    allowedAttributes: { "*": ["*"] },
    parseStyleAttributes: false,
    allowedTags: ["a", "b", "u", "i"],
  });
  return htmlParser(htmlString, options ? options : {});
}
