import React, { Component } from "react";

import PropsType from "prop-types";
import _ from "lodash";

import FItem from "../FItem";

import { Accessor } from "static";
import { HStack, VStack } from "Components/LabIZO/Stackizo";

class FGColumns extends Component {
  static propTypes = {
    //data
    ischema: PropsType.object.isRequired,
    formValue: PropsType.object,
    addOns: PropsType.object,
  };

  static defaultProps = {
    ischema: {},
    formValue: {},
    addOns: {},
  };

  constructor() {
    super();
    this.state = {};
  }

  componentDidMount() {
    this._setAllStates();
  }

  componentDidUpdate(prevProps, prevState) {
    if (!Accessor.IsIdentical(prevProps, this.props, Object.keys(FGColumns.defaultProps))) {
      this._setAllStates();
    }
  }

  componentWillUnmount() {
    this.setState = (state, callback) => {
      return;
    };
  }

  _setAllStates = (callback) => {
    this.setState(
      (state, props) => ({
        ...props,
      }),
      callback
    );
  };

  getColmunSchema = () => {
    let { ischema, formValue, addOns } = this.props;
    if (_.isFunction(ischema.columns)) {
      return ischema.columns(formValue, addOns);
    }
    return ischema.columns;
  };

  getPageSchema = (page) => {
    let { formValue, addOns } = this.props;
    if (_.isFunction(page)) {
      return page(formValue, addOns);
    }
    return page;
  };

  renderSchema(page) {
    let { ischema, ...other } = this.props;
    let pageSchema = this.getPageSchema(page);
    return _.map(pageSchema, (o, i) => {
      return <FItem key={i} ischema={o} {...other} />;
    });
  }

  renderColumns() {
    const { ischema, formValue } = this.props;
    if (!ischema) return null;
    const columnSchema = this.getColmunSchema();

    return columnSchema.reduce((acc, cur, i) => {
      if (cur.control && cur.controlFunc) {
        const v = Accessor.Get(formValue, cur.control);
        if (!cur.controlFunc(formValue, v)) return acc;
      }
      acc.push(
        <VStack key={i} width={cur.width} height={ischema.height} paddingX={cur.paddingX || 1} flexGrow={1} overflow={"auto"}>
          {this.renderSchema(cur.page)}
        </VStack>
      );
      return acc;
    }, []);
  }

  render() {
    let { ischema } = this.props;
    if (!ischema) return null;
    return (
      <HStack alignItems={"flex-start"} height="100%" overflow="hidden">
        {this.renderColumns()}
      </HStack>
    );
  }
}

export default FGColumns;
