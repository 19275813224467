import { backendConnector, DATA_LINK } from "connectors";
import { useState, useEffect } from "react";
import { GoogleTranslateSupportedLang } from "../DocumentManagerForm/constants/supported-lang";
async function getTranslatedTexts(_text: string[], _translateTo: GoogleTranslateSupportedLang) {
  const { Success, payload } = await backendConnector.post<{ translation: string[] }>(DATA_LINK.TRANSLATE_TEXT, {
    text: _text,
    targetLanguage: _translateTo,
  });

  const translation = payload?.translation || "";
  return translation;
}
export function useTranslation(texts: string[], lang: GoogleTranslateSupportedLang, deps?: React.DependencyList) {
  const [translatedText, setTranslatedText] = useState<string[]>(texts);
  useEffect(() => {
    async function handleTranslate() {
      const translation = await getTranslatedTexts(texts, lang);
      setTranslatedText(translation);
    }
    handleTranslate();
  }, deps);
  return { translatedText, setTranslatedText };
}
