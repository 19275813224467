import { NativeSelect } from "@mui/material";
import React from "react";
import { Controller, ControllerRenderProps, Control, UseFormResetField } from "react-hook-form";
import { Authority } from "static";
import { Filterable } from "../interfaces/Filterable.interface";
import { Property } from "csstype";
import { FilterFormProps } from "../interfaces/Condition.interface";

interface FilterRowProps {
  filterables: Filterable[];
  control: Control<any, any>;
  name: string;
  width?: Property.Width;
  resetField?: UseFormResetField<FilterFormProps>;
}

export const FilterField = ({ filterables, name, control, resetField, width = "100px" }: FilterRowProps) => {
  const handleChange = (e: React.ChangeEvent<HTMLSelectElement>, field: ControllerRenderProps<any, `${string}.field`>) => {
    if (!resetField) throw new Error("ResetField not found in component props");
    let _name = name as `conditions.${number}`;
    resetField(`${_name}.operator`);
    field.onChange(e);
  };

  return (
    <Controller
      control={control}
      name={`${name}.field`}
      rules={{ required: true }}
      render={({ field, fieldState }) => (
        <NativeSelect {...field} sx={{ width: width }} error={Boolean(fieldState.error)} onChange={(e) => handleChange(e, field)}>
          <option value={""}></option>
          {filterables.reduce<JSX.Element[]>((acc, f) => {
            if (f.reqAuth && !Authority.IsAccessibleQ(f.reqAuth, f.reqLevel, f.reqFunc)) return acc;
            acc.push(
              <option key={`${name}.field-${f.label}`} value={f.field}>
                {f.label}
              </option>
            );
            return acc;
          }, [])}
        </NativeSelect>
      )}
    />
  );
};
