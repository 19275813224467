import { Alert } from "@mui/material";
import { IRole } from "interfaces/db.interfaces/role.interface";
import { FieldErrors } from "react-hook-form";

export function ErrorSummary({ errors }: { errors: FieldErrors<IRole> }) {
  if (errors.authority?.FAQ) return <>{errors.authority && <Alert severity="error">{errors.authority.FAQ.message}</Alert>}</>;
  if (errors.authority?.FLE) return <>{errors.authority && <Alert severity="error">{errors.authority.FLE.message}</Alert>}</>;
  else {
    return (
      <>
        {errors.authority && <Alert severity="error">{"Authority:Cannot select other options when All is selected"}</Alert>}
        {/* {errors._id && <Alert severity="error">{"Settings are incomplete on Context Page."}</Alert>}
        {errors.level && <Alert severity="error">{"Settings are incomplete on Answer Page."}</Alert>}
        {errors.role && <Alert severity="error">{"Alias of role is invalid"}</Alert>} */}
      </>
    );
  }
}
