import { Theme } from "@mui/material";
import { Box, SxProps } from "@mui/system";
import { DragDropContext, Droppable, DropResult } from "react-beautiful-dnd";
import { DragableList } from "./DragableList";
import _ from "lodash";

interface IDragAndDropProps<T> {
  initItems: T[];
  children: (item: T, index: number) => JSX.Element;
  onDragEnd?: (result: DropResult) => void;
  DropContainerSx?: SxProps<Theme>;
  DragableContainerSx?: SxProps<Theme>;
  inDragBackground?: string;
  afterDragBackground?: string;
  showDragIndicator?: boolean;
  idField?: string;
  id?: string;
}

export const DragAndDrop = <T = any,>(props: IDragAndDropProps<T>) => {
  const {
    //
    initItems,
    inDragBackground: inDragBackground = "lightblue",
    afterDragBackground = "transparent",
    showDragIndicator = true,
    id = "DragAndDrop",
    idField = "id",
  } = props;

  function handleOnDragEnd(result: DropResult) {
    props.onDragEnd?.(result);
  }

  return (
    <DragDropContext onDragEnd={handleOnDragEnd}>
      <Droppable droppableId={`droppable`}>
        {(provided, snapshot) => (
          <Box
            //
            {...provided.droppableProps}
            ref={provided.innerRef}
            sx={
              {
                background: snapshot.isDraggingOver ? inDragBackground : afterDragBackground,
                ...props.DropContainerSx,
              } as SxProps
            }
          >
            {initItems &&
              initItems.map((_item, index) => {
                const id = _.get(_item, idField) || `${index}`;
                return (
                  <DragableList key={id} id={id} item={_item} index={index} sx={props.DragableContainerSx} showDragIndicator={showDragIndicator}>
                    {props.children}
                  </DragableList>
                );
              })}
            {provided.placeholder}
          </Box>
        )}
      </Droppable>
    </DragDropContext>
  );
};
