import type { Moment } from "moment";
import { Box, Typography } from "@mui/material";
import { DatePickerMoment } from "Components/DatePicker/DatePickerMoment";
const { RangePicker } = DatePickerMoment;

type DateValuePiece = Moment | null;

interface DateRangePickerProps {
  dates: [DateValuePiece, DateValuePiece];
  onChangeDates: (newDate: [DateValuePiece, DateValuePiece], formatString: [string, string]) => void;
  minDate?: Moment;
  maxDate?: Moment;
  limitSelectedPeriod?: {
    unit: "days" | "months" | "years";
    value: number;
  };
  dateFormat?: "YYYY-MM-DD" | "DD/MM/YYYY";
  helperText?: string;
  handleFocus?: Function;
  handleBlur?: Function;
}

const defaultDateFormat = "YYYY-MM-DD";

export const DateRangePicker: React.FC<DateRangePickerProps> = ({ dates, onChangeDates, minDate, maxDate, limitSelectedPeriod, helperText, dateFormat, handleFocus, handleBlur }) => {
  const handleDateDisable = (currentDate: Moment, info: { from?: Moment }) => {
    if (!limitSelectedPeriod) return false;
    if (info.from) {
      return Math.abs(currentDate.diff(info.from, limitSelectedPeriod.unit)) > limitSelectedPeriod.value;
    }
    return false;
  };

  // NOTE: buggy behavior from antd if passed in needConfirm={true}

  return (
    <Box>
      <RangePicker
        value={dates}
        onFocus={handleFocus && (() => handleFocus())}
        onBlur={handleBlur && (() => handleBlur())}
        minDate={minDate}
        maxDate={maxDate}
        onChange={onChangeDates}
        format={dateFormat ?? defaultDateFormat}
        disabledDate={handleDateDisable}
        renderExtraFooter={() => (helperText ? <Typography sx={{ my: "3px" }}>{helperText}</Typography> : <></>)}
      />
    </Box>
  );
};
