import React from "react";

import _ from "lodash";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { FormControl, FormHelperText, IconButton, InputAdornment } from "@mui/material";

import { Accessor } from "static";
import { StyledInput, StyledTextField } from "Components/LabIZO/Stylizo";

interface IFFPasswordProps {
  //data
  ischema: any;
  iname: string;
  addOns: any;

  //root func
  _onValueChange: (name: string, value: any, criteria: any) => void;
  _onBlurInlineSubmit: any;
  _onInlineSubmit: any;
  _onFieldFocus: () => void;
  _onFieldBlur: () => void;

  //disability
  errorsShowOnHelperText: boolean;
  readOnly: boolean;

  //runtime
  formValue?: any;
  formError: any;

  //style
  ifieldStyle: string;
  fieldSize?: string;
  theme?: any;
}

export const FFPassword: React.FC<IFFPasswordProps> = (props) => {
  const { ischema, iname, formValue, formError, _onValueChange, _onBlurInlineSubmit, _onFieldFocus, _onFieldBlur, errorsShowOnHelperText, readOnly, ifieldStyle, fieldSize, theme } = props;
  const [showPassword, setShowPassword] = React.useState(false);
  let ivalue = Accessor.Get(formValue, iname);
  if (ivalue === undefined || ivalue === null) ivalue = "";
  const ierror = Accessor.Get(formError, iname);
  const ireadOnly = ischema.readOnly || readOnly;

  let helperText = ischema.helperText;
  if (errorsShowOnHelperText) {
    helperText = ierror;
  }

  const togglePassword = () => {
    setShowPassword(!showPassword);
  };

  const renderInput = () => {
    return (
      <FormControl
        error={!_.isEmpty(ierror)}
        disabled={ireadOnly}
        fullWidth={ischema.fullWidth !== false}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => _onValueChange(iname, e.target.value, ischema.validate)}
        onFocus={(e) => {
          _onFieldFocus();
        }}
        onBlur={(e) => {
          _onFieldBlur();
          _onBlurInlineSubmit(iname);
        }}
      >
        <StyledInput
          value={ivalue}
          placeholder={ischema.placeholder}
          startAdornment={ischema.before}
          endAdornment={[
            ischema.after,
            <InputAdornment position="end" key="showPassword">
              <IconButton className="formizo-h-m" aria-label="toggle password visibility" onClick={() => togglePassword()} edge="end">
                {showPassword ? <Visibility /> : <VisibilityOff />}
              </IconButton>
            </InputAdornment>,
          ]}
          type={showPassword ? "text" : "password"}
          size={fieldSize}
          theme={theme && theme.textfield}
        />
        {!_.isEmpty(helperText) && <FormHelperText>{helperText}</FormHelperText>}
      </FormControl>
    );
  };

  const renderTextField = () => {
    return (
      <StyledTextField
        value={ivalue}
        label={ischema.label || ""}
        helperText={helperText || ""}
        placeholder={ischema.placeholder || ""}
        onChange={(e: { target: { value: any } }) => _onValueChange(iname, e.target.value, ischema.validate)}
        onFocus={(e: any) => {
          _onFieldFocus();
        }}
        onBlur={(e: any) => {
          _onFieldBlur();
          _onBlurInlineSubmit(iname);
        }}
        name={iname}
        variant={ifieldStyle}
        fullWidth={ischema.fullWidth !== false}
        error={!_.isEmpty(ierror)}
        disabled={ireadOnly}
        InputProps={{
          startAdornment: ischema.before,
          endAdornment: [
            ischema.after,
            <InputAdornment position="end" key="showpassword">
              <IconButton className="formizo-h-m" aria-label="toggle password visibility" onClick={() => togglePassword()} edge="end">
                {showPassword ? <Visibility /> : <VisibilityOff />}
              </IconButton>
            </InputAdornment>,
          ],
        }}
        type={showPassword ? "text" : "password"}
        size={fieldSize}
        theme={theme && theme.textfield}
        autoFocus={ischema.autoFocus || false}
      />
    );
  };
  if (!ischema) {
    return null;
  }
  if (!ifieldStyle || ifieldStyle === "grid") {
    return renderInput();
  } else {
    return renderTextField();
  }
};
