import { Box, Typography } from "@mui/material";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { History } from "@mui/icons-material";
import Formizo from "Components/LabIZO/Formizo";
import { HStack, Spacer, VStack } from "Components/LabIZO/Stackizo";
import Stepizo from "Components/LabIZO/Stepizo";
import { StyledButton } from "Components/LabIZO/Stylizo";
import { Accessor, Authority, ColorX, ErrorX, store } from "static";
import { Component } from "react";
import schema from "../schema";
import _ from "lodash";
import { ExampleGeneration } from "./ExampleGeneration";
import { DATA_LINK, backendConnector } from "connectors";

// TODO: rewrite and split the 3 steps into separate components
class FAQCreate extends Component {
  static defaultProps = {
    onQuit: () => {},
    onQuitRefresh: () => {},
    getQuickReplies: () => {},
    addOns: {},
  };

  constructor() {
    super();
    this.state = {
      activeStep: 0,
      steps: [
        {
          label: "Questions",
          render: this.renderQuestion.bind(this),
        },
        {
          label: "Answers",
          render: this.renderAnswer.bind(this),
        },
        {
          label: "Question Generation",
          reqAuth: "FAQ",
          reqFunc: "IntentGeneration",
          render: this.renderExampleGeneration.bind(this),
        },
        {
          label: "Settings",
          render: this.renderSettings.bind(this),
        },
      ],
      formProps: {
        Questions: {},
        Answers: {},
        Settings: {},
      },
    };
  }

  componentDidMount() {
    this._setAllStates();
  }

  componentDidUpdate(prevProps, prevState) {
    if (!Accessor.IsIdentical(prevProps, this.props, Object.keys(FAQCreate.defaultProps))) {
      this._setAllStates();
    }
  }

  componentWillUnmount() {
    this.setState = (state, callback) => {
      return;
    };
  }

  _setAllStates = (callback) => {
    this.setState(
      (state, props) => ({
        ...props,
      }),
      callback
    );
  };

  onMountStepizo = (callbacks) => {
    this.MountStepizo = callbacks;
  };

  onMountFormizoQ = (callbacks) => {
    this.MountFormizoQ = callbacks;
  };

  onMountFormizoA = (callbacks) => {
    this.MountFormizoA = callbacks;
  };

  onMountFormizoS = (callbacks) => {
    this.MountFormizoS = callbacks;
  };

  _onStepChange = (step) => {
    this.setState({ activeStep: step });
  };

  _realSubmit = async () => {
    let { formProps } = this.state;
    let { addOns, onQuitRefresh, getQuickReplies } = this.props;
    console.log(formProps);

    // merge generated examples into intent examples
    if (formProps.GeneratedExamples) {
      for (const lang of Object.keys(formProps.GeneratedExamples)) {
        if (!formProps.Questions.intent[lang]) {
          formProps.Questions.intent[lang] = { examples: [] };
        }
        const generatedExamples = formProps.GeneratedExamples[lang].map((ex) => ({ text: ex }));
        formProps.Questions.intent[lang].examples.push(...generatedExamples);
      }
    }
    const url = DATA_LINK.FAQAdd;
    try {
      store.isLoading(true);
      const { Success, payload } = await backendConnector.post(url, _.merge(formProps.Questions, formProps.Answers, formProps.Settings), {
        workspace: "master",
      });
      store.isLoading(false);

      console.log(url, payload);

      if (Success === true) {
        store.Alert("FAQ Added Successfully", "success");
        onQuitRefresh();
      } else {
        let msg = "FAQ Add Failed: " + (payload?.message || "");
        store.Alert(msg, "error");
        return ErrorX.Handle(payload.message || "");
      }
    } catch (e) {
      let msg = "FAQ Add Failed: " + e;
      store.Alert(msg, "error");
    }
  };

  _onSubmitS = (formProps) => {
    console.log(formProps);
    this.setState(
      (state, props) => ({
        formProps: {
          ...state.formProps,
          Settings: formProps,
        },
      }),
      () => {
        this.MountStepizo.setCompleted(2);
        this._realSubmit();
      }
    );
  };

  renderNextButtonS() {
    return (
      <StyledButton
        key="next"
        onClick={() => {
          this.MountFormizoS.Submit();
        }}
        theme={{
          color: "white",
          background: ColorX.GetColorCSS("Primary"),
          hover: {
            background: ColorX.GetColorCSS("Primary2"),
          },
          boxShadow: "transparent",
          borderRadius: "0px",
          width: "150px",
        }}
      >
        <HStack>
          <Typography>FINISH</Typography>
          <Spacer />
        </HStack>
      </StyledButton>
    );
  }

  _onSubmitA = (formProps) => {
    const faqType = formProps.type;
    if (faqType === "static") {
      if (!formProps.answer?.text?.[store.sysInfo.Language.default]?.[0]) {
        return store.Alert("Please provide answer text.", "error");
      }
    } else if (faqType === "redirectToGPT") {
      if (!formProps.intent.trigger?.__param) {
        return store.Alert("Please provide GPT redirect parameters.");
      } else if (formProps.intent.trigger.__param.source === "document" && !formProps.intent.trigger.__param.document) {
        return store.Alert("Please select document for answer redirection.", "error");
      } else if (formProps.intent.trigger.__param.source === "knowledgeGroup" && !formProps.intent.trigger.__param.knowledgeGroup) {
        return store.Alert("Please select knowledge group for answer redirection.", "error");
      }
    }

    this.setState(
      (state, props) => ({
        formProps: {
          ...state.formProps,
          Answers: formProps,
        },
        activeStep: state.activeStep + 1,
      }),
      () => {
        this.MountStepizo.setCompleted(1);
      }
    );
  };

  renderNextButtonA() {
    return (
      <StyledButton
        id="next"
        key="next"
        onClick={() => {
          this.MountFormizoA.Submit();
        }}
        theme={{
          color: "white",
          background: ColorX.GetColorCSS("Primary"),
          hover: {
            background: ColorX.GetColorCSS("Primary2"),
          },
          boxShadow: "transparent",
          borderRadius: "0px",
          width: "150px",
        }}
      >
        <HStack>
          <div>NEXT</div>
          <Spacer />
          <i className="fas fa-arrow-right" />
        </HStack>
      </StyledButton>
    );
  }

  _onSubmitQ = (formProps) => {
    console.log(formProps);
    this.setState(
      (state, props) => ({
        formProps: {
          ...state.formProps,
          Questions: formProps,
        },
        activeStep: state.activeStep + 1,
      }),
      () => {
        this.MountStepizo.setCompleted(0);
      }
    );
  };

  renderNextButtonQ() {
    return (
      <StyledButton
        id="next"
        key="next"
        onClick={() => {
          this.MountFormizoQ.Submit();
        }}
        theme={{
          color: "white",
          background: ColorX.GetColorCSS("Primary"),
          hover: {
            background: ColorX.GetColorCSS("Primary2"),
          },
          boxShadow: "transparent",
          borderRadius: "0px",
          width: "150px",
        }}
      >
        <HStack>
          <div>NEXT</div>
          <Spacer />
          <i className="fas fa-arrow-right" />
        </HStack>
      </StyledButton>
    );
  }

  // Revert Buttons
  renderRevertA() {
    return (
      <StyledButton
        key="revert"
        onClick={() => {
          this.MountFormizoA.Revert();
        }}
        theme={{
          color: "white",
          background: ColorX.GetColorCSS("Primary"),
          hover: {
            background: ColorX.GetColorCSS("Primary2"),
          },
          boxShadow: "transparent",
          borderRadius: "0px",
          width: "100px",
          margin: "10px",
        }}
      >
        <HStack>
          <HStack>
            <div>REVERT</div>
            <Spacer />
            <History />
          </HStack>
        </HStack>
      </StyledButton>
    );
  }

  renderRevertQ() {
    return (
      <StyledButton
        key="revert"
        onClick={() => {
          this.MountFormizoQ.Revert();
        }}
        theme={{
          color: "white",
          background: ColorX.GetColorCSS("Primary"),
          hover: {
            background: ColorX.GetColorCSS("Primary2"),
          },
          boxShadow: "transparent",
          borderRadius: "0px",
          width: "100px",
          margin: "10px",
        }}
      >
        <HStack>
          <HStack>
            <div>REVERT</div>
            <Spacer />
            <History />
          </HStack>
        </HStack>
      </StyledButton>
    );
  }

  renderRevertS() {
    return (
      <StyledButton
        key="revert"
        onClick={() => {
          this.MountFormizoS.Revert();
        }}
        theme={{
          color: "white",
          background: ColorX.GetColorCSS("Primary"),
          hover: {
            background: ColorX.GetColorCSS("Primary2"),
          },
          boxShadow: "transparent",
          borderRadius: "0px",
          width: "100px",
          margin: "10px",
        }}
      >
        <HStack>
          <HStack>
            <div>REVERT</div>
            <Spacer />
            <History />
          </HStack>
        </HStack>
      </StyledButton>
    );
  }

  //Setting Page
  renderSettings(_stepNum) {
    return (
      <VStack>
        <Typography
          style={{
            textAlign: "left",
            width: "100%",
            fontSize: 20,
            fontFamily: "Segoe UI",
            color: ColorX.GetColorCSS("Primary"),
            margin: "5px 0",
          }}
        >
          SETTINGS
        </Typography>
        <Typography
          style={{
            textAlign: "left",
            width: "100%",
            fontSize: 16,
            fontFamily: "Segoe UI",
            color: "black",
            margin: "5px 0",
          }}
        >
          Give a representative ID and alias to this FAQ set for easier recongnition and search.
        </Typography>
        <HStack alignItems="flex-start" marginY={3}>
          <Formizo
            height="auto"
            width="600px"
            schema={schema.Settings}
            buttons={[this.renderRevertS(), this.renderNextButtonS()]}
            onMounted={this.onMountFormizoS}
            onSubmit={this._onSubmitS}
            fieldStyle="filled"
            buttonAlign="right"
            onInvalid={() => {
              store.Alert("The data is invalid.", "warning");
            }}
          />
        </HStack>
      </VStack>
    );
  }

  renderAnswer(_stepNum) {
    const { addOns } = this.props;

    return (
      <VStack>
        <Typography
          style={{
            textAlign: "left",
            width: "100%",
            fontSize: 20,
            fontFamily: "Segoe UI",
            color: ColorX.GetColorCSS("Primary"),
            margin: "5px 0",
            padding: "0 5px",
          }}
        >
          INPUT ANSWER RESPONSE
        </Typography>
        <Typography
          style={{
            textAlign: "left",
            width: "100%",
            fontSize: 16,
            fontFamily: "Segoe UI",
            color: "black",
            margin: "5px 0",
            padding: "0 5px",
          }}
        >
          Input the answer to be responded by the chatbot for the question asked.
        </Typography>
        <Formizo
          height="auto"
          width="600px"
          schema={schema.Answers}
          buttons={[this.renderRevertA(), this.renderNextButtonA()]}
          onMounted={this.onMountFormizoA}
          onSubmit={this._onSubmitA}
          fieldStyle="filled"
          buttonAlign="right"
          onInvalid={() => {
            store.Alert("The data is invalid.", "warning");
          }}
          addOns={addOns}
        />
      </VStack>
    );
  }

  renderQuestion(_stepNum) {
    return (
      <VStack>
        <Typography
          style={{
            textAlign: "left",
            width: "100%",
            fontSize: 20,
            fontFamily: "Segoe UI",
            color: ColorX.GetColorCSS("Primary"),
            margin: "5px 0",
          }}
        >
          INPUT REPRESENTATIVE QUESTION
        </Typography>
        <Typography
          style={{
            textAlign: "left",
            width: "100%",
            fontSize: 16,
            fontFamily: "Segoe UI",
            color: "black",
            margin: "5px 0",
          }}
        >
          Input the question that can well represent the intention of the enquirer.
        </Typography>
        <HStack alignItems="flex-start" marginY={3}>
          <Typography
            style={{
              textAlign: "left",
              width: "100px",
              fontSize: 20,
              fontFamily: "Segoe UI",
              color: ColorX.GetColorCSS("Primary"),
              padding: "15px 0",
            }}
          >
            QUESTION
          </Typography>
          <Formizo
            height="500px"
            width="600px"
            schema={schema.Questions}
            buttons={[this.renderRevertQ(), this.renderNextButtonQ()]}
            onMounted={this.onMountFormizoQ}
            onSubmit={this._onSubmitQ}
            fieldStyle="filled"
            buttonAlign="right"
            onInvalid={() => {
              store.Alert("The data is invalid.", "warning");
            }}
          />
        </HStack>
      </VStack>
    );
  }

  renderExampleGeneration(stepNum) {
    const { Questions, Answers, GeneratedExamples } = this.state.formProps;

    const localTheme = createTheme({
      typography: {
        fontFamily: [`"Segoe UI"`, "Roboto"].join(","),
      },
    });

    if (this.state.activeStep !== stepNum) return <></>;

    return (
      <VStack gap="20px">
        <Typography
          style={{
            textAlign: "left",
            width: "100%",
            fontSize: 20,
            fontFamily: "Segoe UI",
            color: ColorX.GetColorCSS("Primary"),
            margin: "8px 0",
          }}
        >
          QUESTION GENERATION
        </Typography>
        <Typography
          style={{
            textAlign: "left",
            width: "100%",
            fontSize: 16,
            fontFamily: "Segoe UI",
            color: "black",
            margin: "5px 0",
          }}
        >
          Generate questions from the provided representative question(s) and answer(s).
        </Typography>
        <ThemeProvider theme={localTheme}>
          <ExampleGeneration intent={Questions.intent} answers={Answers?.answer?.text} existingGenerations={GeneratedExamples} onSubmit={this._onSubmitExampleGeneration} />
        </ThemeProvider>
      </VStack>
    );
  }

  _onSubmitExampleGeneration = (generatedExamples) => {
    this.setState(
      (state, props) => ({
        formProps: {
          ...state.formProps,
          GeneratedExamples: generatedExamples,
        },
        activeStep: state.activeStep + 1,
      }),
      () => {
        this.MountStepizo.setCompleted(2);
      }
    );
  };

  renderStep(steps) {
    const { activeStep } = this.state;
    return (
      <Box>
        {steps.map((s, idx) => (
          <Box key={idx} display={activeStep === idx ? undefined : "none"}>
            {s.render(idx)}
          </Box>
        ))}
      </Box>
    );
  }

  render() {
    const { steps, activeStep } = this.state;
    const authorizedSteps = steps.filter(({ reqAuth, reqFunc }) => {
      if (!reqAuth) return true;
      return Authority.IsAccessibleQ(reqAuth, 999, reqFunc ?? "");
    });

    return (
      <VStack>
        <Stepizo steps={authorizedSteps} activeStep={activeStep} onMounted={this.onMountStepizo} onStepChange={this._onStepChange} />
        {this.renderStep(authorizedSteps)}
      </VStack>
    );
  }
}

export default FAQCreate;
