import { Stack, Typography, styled } from "@mui/material";
import { AvailLANG } from "interfaces";
import { ExampleGenerationByLang } from "./ExampleGenerationByLang";
import { useState } from "react";

const StyledContainer = styled(Stack)({
  width: "80vw",
  maxWidth: "1050px",
  flexDirection: "column",
});

export const ExampleGenerationSetup: React.FC<{}> = () => {
  const unavailableText = "Sorry, question generation is not available at the moment.";
  const [showError, setShowError] = useState(false);

  return (
    <StyledContainer>
      {showError ? (
        <Typography sx={{ fontWeight: "bold" }}>{unavailableText}</Typography> //
      ) : (
        Object.values(AvailLANG).map((lang) => <ExampleGenerationByLang key={lang} lang={lang} setShowError={setShowError} />)
      )}
    </StyledContainer>
  );
};
