import type { Moment } from "moment";
import { Box, Typography } from "@mui/material";
import { DatePickerMoment } from "Components/DatePicker/DatePickerMoment";
const { RangePicker } = DatePickerMoment;

export type DateValuePiece = Moment | null;

interface DateTimeRangePickerProps {
  dates: [DateValuePiece, DateValuePiece];
  onChangeDates: (newDate: [DateValuePiece, DateValuePiece], formatString: [string, string]) => void;
  dateFormat?: "YYYY-MM-DD HH:mm:ss";
  minDate?: Moment;
  maxDate?: Moment;
  limitSelectedPeriod?: {
    unit: "days" | "months" | "years";
    value: number;
  };
  helperText?: string;
  handleFocus?: Function;
  handleBlur?: Function;
}

const defaultDateFormat = "YYYY-MM-DD HH:mm:ss";

export const DateTimeRangePicker: React.FC<DateTimeRangePickerProps> = ({ dates, onChangeDates, minDate, maxDate, limitSelectedPeriod, helperText, dateFormat }) => {
  const handleDateDisable = (currentDate: Moment, info: { from?: Moment }) => {
    if (!limitSelectedPeriod) return false;
    if (info.from) {
      return Math.abs(currentDate.diff(info.from, limitSelectedPeriod.unit)) >= limitSelectedPeriod.value;
    }
    return false;
  };

  // NOTE: until antd upgrades its feature, confirm button is compulsory for datetime range picker

  return (
    <Box>
      <RangePicker
        showTime={true}
        value={dates}
        minDate={minDate}
        maxDate={maxDate}
        onChange={onChangeDates}
        format={dateFormat ?? defaultDateFormat}
        disabledDate={handleDateDisable}
        renderExtraFooter={() => (helperText ? <Typography sx={{ fontSize: "0.9rem", my: "3px" }}>{helperText}</Typography> : <></>)}
      />
    </Box>
  );
};
