import { Badge } from "@mui/material";
import { ViewColumn } from "@mui/icons-material";
import { GridToolbarColumnsButton } from "@mui/x-data-grid";

interface ColumnBarProps {
  badgeNumber?: number;
}

const ColumnBar = ({ badgeNumber }: ColumnBarProps) => {
  return (
    <GridToolbarColumnsButton
      size="small"
      startIcon={
        <Badge badgeContent={badgeNumber? `${badgeNumber}` : undefined} color="error">
          <ViewColumn />
        </Badge>
      }
    />
  );
}

export default ColumnBar;