import { NativeSelect } from "@mui/material";
import { FormizoSchema } from "Components/LabIZO/Formizo";
import { CustomFilterValueProps, FieldType, Filterable, TablizoSchema } from "Components/LabIZO/Tablizo";
import { ILogExportSchemaEntry } from "interfaces/be.interfaces/file-export-interface";
import _ from "lodash";

const Table: TablizoSchema[] = [
  {
    label: "Receive Time",
    name: "recTime",
    type: "dateTime",
    transform: "datetime",
    width: 180,
    filterable: true,
    defaultSort: "desc",
  },
  {
    label: "User Display Name",
    name: "userDisplayName",
    filterable: true,
    valueGetter: (_: any, cellVal: any) => cellVal || "N/A",
  },
  {
    label: "Cat",
    name: "cat",
    filterable: true,
  },
  {
    label: "Subcat",
    name: "subcat",
    filterable: true,
  },
  {
    label: "Action",
    name: "action",
    filterable: true,
  },
  {
    label: "IP",
    name: "ip",
    filterable: true,
    width: 120,
  },
  {
    label: "Request Body",
    name: "body",
    sortable: false,
  },
];

const Info: FormizoSchema[] = [
  {
    label: "ID",
    name: "_id",
    validate: ["required"],
    format: "text",
    readOnly: true,
  },
  {
    label: "User Display Name",
    name: "userDisplayName",
    format: "text",
    readOnly: true,
  },
  {
    label: "Cat",
    name: "cat",
    format: "text",
    readOnly: true,
  },
  {
    label: "Subcat",
    name: "subcat",
    format: "text",
    readOnly: true,
  },
  {
    label: "Action",
    name: "action",
    format: "text",
    readOnly: true,
  },
  {
    label: "URL",
    name: "url",
    format: "text",
    readOnly: true,
  },
  {
    label: "Receive Time",
    name: "recTime",
    format: "text",
    readOnly: true,
  },
  {
    label: "Host",
    name: "host",
    format: "text",
    readOnly: true,
  },
  {
    label: "Origin",
    name: "origin",
    format: "text",
    readOnly: true,
  },
  {
    label: "Referrer",
    name: "referrer",
    format: "text",
    readOnly: true,
  },
  {
    label: "IP",
    name: "ip",
    format: "text",
    readOnly: true,
  },
  {
    label: "Request Body",
    name: "body",
    format: "textarea",
    readOnly: true,
  },
];

const Filterables: Filterable[] = [
  {
    field: "userDisplayName",
    label: "User Display Name",
    type: FieldType.select,
    CustomFilterValue: ({ field, width, fieldState }: CustomFilterValueProps) => (
      <NativeSelect {...field} sx={{ width: width }} error={Boolean(fieldState?.error)}>
        <option value={""}></option>
        <option value={"SYVA Demo"}>SYVA Demo</option>
        <option value={"SYVA Super Admin"}>SYVA Super Admin</option>
      </NativeSelect>
    ),
  },
  {
    field: "cat",
    label: "Cat",
    type: FieldType.text,
  },
  {
    field: "subcat",
    label: "Subcat",
    type: FieldType.text,
  },
  {
    field: "action",
    label: "Action",
    type: FieldType.text,
  },
  {
    field: "ip",
    label: "IP",
    type: FieldType.text,
  },
];

const Export: ILogExportSchemaEntry[] = [
  {
    Header: "ID",
    accessor: "_id",
    width: 35,
  },
  {
    Header: "User Display Name",
    accessor: "userDisplayName",
    width: 25,
  },
  {
    Header: "Subcat",
    accessor: "subcat",
    width: 25,
  },
  {
    Header: "Action",
    accessor: "action",
    width: 25,
  },
  {
    Header: "URL",
    accessor: "url",
    width: 30,
  },
  {
    Header: "Receive time",
    accessor: "recTime",
    width: 25,
    format: "datetime",
  },
  {
    Header: "Host",
    accessor: "host",
    width: 25,
  },
  {
    Header: "Origin",
    accessor: "origin",
    width: 25,
  },
  {
    Header: "Referrer",
    accessor: "referrer",
    width: 25,
  },
  {
    Header: "IP",
    accessor: "ip",
    width: 25,
  },
];

const AdvFilter: any = [];

const schema: any = {
  Table,
  Info,
  Export,
  AdvFilter,
  Filterables,
};

export default schema;
