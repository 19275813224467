import { ColorX } from "static";
import { Typography } from "@mui/material";

interface HeaderProps {
  title?: string;
}

export const Header: React.FC<HeaderProps> = ({ title = "" }) => {
  return (
    <Typography
      style={{
        textAlign: "left",
        width: "100%",
        fontSize: 25,
        color: ColorX.GetColorCSS("Primary"),
        // textShadow: "0 1px 60px rgba(0, 0, 0, 0.4)"
      }}
    >
      {title}
    </Typography>
  );
};
