import React, { Component, useEffect } from "react";
import PropsType from "prop-types";

import moment from "moment";

import "./ZCMsg.css";
import { AccessTime, Done, DoneAll, Warning } from "@mui/icons-material";
import { HStack } from "Components";
interface IZCMFooterProps {
  cssp?: string;
  pos?: string;
  createdAt?: string;
  lapseTime?: number;
  showStatus?: boolean;
  showDateTime?: boolean;
  showLapseTime?: boolean;
  status?: string;
}

function ZCMFooter(props: IZCMFooterProps) {
  function renderLapseTime() {
    let { cssp, showLapseTime, lapseTime } = props;
    if (!showLapseTime || !lapseTime) return;
    return <div className={cssp + " msg-footer-lapse"}>{lapseTime + "s"}</div>;
  }

  function renderDateTime() {
    let { cssp, showDateTime, createdAt } = props;
    if (!showDateTime) return;
    let format = "HH:mm";
    if (!createdAt) createdAt = moment().format();
    let createdAtM = moment(createdAt);
    if (moment().date !== createdAtM.date) {
      format = "DD MMM HH:mm";
    }
    if (moment().year !== createdAtM.year) {
      format = "DD MMM, YYYY HH:mm";
    }

    return <div className={cssp + " msg-footer-datetime"}>{createdAtM.format(format)}</div>;
  }

  let { cssp, pos } = props;
  return (
    <div className={cssp + " msg-footer " + pos}>
      {pos === "in" && renderLapseTime()}
      {props.status && pos === "out" && <StatusIcon status={props.status} cssp={props.cssp} showStatus={props.showStatus} />}
      {renderDateTime()}
    </div>
  );
}

ZCMFooter.defaultProps = {
  cssp: "",
  pos: "out",
  createdAt: null,
  lapseTime: 0,
  showStatus: true,
  showDateTime: true,
  showLapseTime: true,
};

export default ZCMFooter;

function StatusIcon(props: { status?: string; cssp?: string; showStatus?: boolean }): JSX.Element {
  let { cssp, showStatus } = props;
  if (!showStatus) <></>;

  function getStatusIcon(status?: string) {
    const fontSize = "0.75rem";
    let icon = <div />;
    switch (status) {
      case "pending":
        icon = <AccessTime sx={{ fontSize }} />;
        break;
      case "sent":
        icon = <Done sx={{ fontSize }} />;
        break;
      case "received":
        icon = <DoneAll sx={{ fontSize }} />;
        break;
      case "read":
        icon = <DoneAll sx={{ color: "#4caf50", fontSize }} />;
        break;
      case "error":
        icon = <Warning sx={{ fontSize }} />;
        break;
      default:
        icon = <AccessTime sx={{ fontSize }} />;
        break;
    }
    return icon;
  }

  return (
    <HStack sx={{ gap: 0.25, justifyContent: "flex-start" }}>
      {props.status}
      {getStatusIcon(props.status)}
    </HStack>
  );
}
