import { Component, useState } from "react";

import { Grid, Box, Typography, Tooltip } from "@mui/material";

import { FFText, FFHidden, FFPassword, FFNumber, FFBool, FFTextarea, FFSelect, FFDate, FFDateRange, FFDateTimeRange, FFUpload, FFSlider, FFMultiSelect } from "./_inputs";

import { Accessor } from "static";
import { StyledButton } from "Components/LabIZO/Stylizo";
import { HStack, Spacer } from "Components/LabIZO/Stackizo";
import FFRichText from "./_inputs/FFRichText";
import FFSelectTable from "./_inputs/FFSelectTable";
import { ColorX } from "static";

interface IFFieldProps {
  ischema: any;
  iname: string;
  inTable?: boolean;
  _onValueChange: (value: any) => void;
  _onInlineSubmit: (accessor: string) => void;
  _onInlineRevert: (accessor: string) => void;
  _onBlurInlineSubmit: () => void;
  _setHiddenValue: (value: any) => void;
  _Validate: () => void;
  addOns: any;
  formValue?: any;
  formError: any;
  auth: any;
  level: number;
  enableInlineSubmit: boolean;
  errorsShowOnHelperText: boolean;
  readOnly: boolean;
  fieldStyle: "grid" | "standard" | "filled" | "outlined";
  labelXS: number;
  labelPaddingX: number;
  labelJustify: string;
  fieldXS: number;
  fieldPaddingX: number;
  separator: string;
  fieldSize?: string;
  theme?: any;
}

const FField: React.FC<IFFieldProps> = (props) => {
  const [focusing, setFocusing] = useState(false);

  const {
    ischema,
    iname,
    inTable,
    _onValueChange,
    _onBlurInlineSubmit,
    _onInlineSubmit,
    _onInlineRevert,
    _setHiddenValue,
    _Validate,
    addOns,
    formValue,
    formError,
    auth,
    level,
    enableInlineSubmit,
    errorsShowOnHelperText,
    readOnly,
    fieldStyle,
    labelXS,
    labelPaddingX,
    labelJustify,
    fieldXS,
    fieldPaddingX,
    separator,
    fieldSize,
    theme,
  } = { ...FField.defaultProps, ...props };

  const _onFieldFocus = () => {
    setFocusing(true);
  };

  const _onFieldBlur = () => {
    setTimeout(() => {
      setFocusing(false);
    }, 150);
  };

  const renderDisplay = () => {
    const { Custom } = ischema as any;
    const fvalue = Accessor.Get(formValue, iname);

    if (Custom) {
      return (
        <HStack width={"100%"} key={"display"}>
          {Custom(formValue, fvalue, addOns, _onValueChange, { iname }, readOnly)}
        </HStack>
      );
    }

    return null;
  };

  const renderButton = () => {
    const { tooltipTitle, iconComponent, buttonColor } = ischema as any;
    const handler = addOns?.handlers?.[iname];

    if (typeof handler !== "function") {
      return null;
    }

    return (
      <StyledButton
        className="formizo-h-m"
        theme={{
          color: buttonColor,
          display: "inline-flex",
          width: "100%",
        }}
        onClick={handler}
      >
        {tooltipTitle ? (
          <Tooltip title={tooltipTitle} key={0} arrow={true}>
            {iconComponent}
          </Tooltip>
        ) : (
          iconComponent
        )}
      </StyledButton>
    );
  };

  const renderText = () => {
    const ifieldStyle = ischema.variant || fieldStyle;

    return <FFText key={"text"} ifieldStyle={ifieldStyle} _onFieldFocus={_onFieldFocus} _onFieldBlur={_onFieldBlur} {...props} />;
  };

  const renderTextarea = () => {
    const ifieldStyle = ischema.variant || fieldStyle;

    return <FFTextarea key={"textarea"} ifieldStyle={ifieldStyle} _onFieldFocus={_onFieldFocus} _onFieldBlur={_onFieldBlur} {...props} />;
  };

  const renderRichText = () => {
    const ifieldStyle = ischema.variant || fieldStyle;

    return <FFRichText key={"richtext"} ifieldStyle={ifieldStyle} _onFieldFocus={_onFieldFocus} _onFieldBlur={_onFieldBlur} {...props} />;
  };

  const renderNumber = () => {
    const ifieldStyle = ischema.variant || fieldStyle;

    return <FFNumber key={"number"} ifieldStyle={ifieldStyle} _onFieldFocus={_onFieldFocus} _onFieldBlur={_onFieldBlur} {...props} />;
  };

  const renderPassword = () => {
    const ifieldStyle = ischema.variant || fieldStyle;

    return <FFPassword key={"password"} ifieldStyle={ifieldStyle} _onFieldFocus={_onFieldFocus} _onFieldBlur={_onFieldBlur} {...props} />;
  };

  const renderBool = () => {
    const ifieldStyle = ischema.variant || fieldStyle;

    return <FFBool key={"bool"} ifieldStyle={ifieldStyle} _onFieldFocus={_onFieldFocus} _onFieldBlur={_onFieldBlur} {...props} />;
  };

  const renderSelect = () => {
    const ifieldStyle = ischema.variant || fieldStyle;

    return <FFSelect key={"select"} ifieldStyle={ifieldStyle} _onFieldFocus={_onFieldFocus} _onFieldBlur={_onFieldBlur} {...props} />;
  };

  const renderSlider = () => {
    const ifieldStyle = ischema.variant || fieldStyle;

    return <FFSlider key={"slider"} ifieldStyle={ifieldStyle} _onFieldFocus={_onFieldFocus} _onFieldBlur={_onFieldBlur} {...props} />;
  };

  const renderHidden = () => {
    return <FFHidden ischema={ischema} iname={iname} _setHiddenValue={_setHiddenValue} formValue={formValue} />;
  };

  const renderDate = () => {
    return <FFDate formValue={formValue} ischema={ischema} _onFieldFocus={_onFieldFocus} _onFieldBlur={_onFieldBlur} _onValueChange={_onValueChange} _onBlurInlineSubmit={_onBlurInlineSubmit} />;
  };

  const renderDateRange = () => {
    return <FFDateRange formValue={formValue} ischema={ischema} _onFieldFocus={_onFieldFocus} _onFieldBlur={_onFieldBlur} _onValueChange={_onValueChange} _onBlurInlineSubmit={_onBlurInlineSubmit} />;
  };

  const renderDateTimeRange = () => {
    return (
      <FFDateTimeRange formValue={formValue} ischema={ischema} _onFieldFocus={_onFieldFocus} _onFieldBlur={_onFieldBlur} _onValueChange={_onValueChange} _onBlurInlineSubmit={_onBlurInlineSubmit} />
    );
  };

  const renderUpload = () => {
    const ifieldStyle = ischema.variant || fieldStyle;

    return <FFUpload key={"upload"} ifieldStyle={ifieldStyle} _onFieldFocus={_onFieldFocus} _onFieldBlur={_onFieldBlur} {...props} />;
  };

  const renderSelectTable = () => {
    const ifieldStyle = ischema.variant || fieldStyle;

    return <FFSelectTable key={"selectTable"} ifieldStyle={ifieldStyle} _onFieldFocus={_onFieldFocus} _onFieldBlur={_onFieldBlur} {...props} />;
  };

  const renderMultiSelect = () => {
    const ifieldStyle = ischema.variant || fieldStyle;

    return <FFMultiSelect key={"multiSelect"} ifieldStyle={ifieldStyle} _onFieldFocus={_onFieldFocus} _onFieldBlur={_onFieldBlur} {...props} />;
  };

  const renderInside = () => {
    const { format } = ischema as any;

    if (!format) return null;

    switch (format) {
      case "display":
      case "custom":
        return renderDisplay();
      case "button":
        return renderButton();
      case "text":
        return renderText();
      case "textarea":
        return renderTextarea();
      case "hidden":
        return renderHidden();
      case "number":
        return renderNumber();
      case "password":
        return renderPassword();
      case "bool":
        return renderBool();
      case "select":
        return renderSelect();
      case "date":
        return renderDate();
      case "daterange":
        return renderDateRange();
      case "datetimerange":
        return renderDateTimeRange();
      case "file":
        return renderUpload();
      case "slider":
        return renderSlider();
      case "richtext":
        return renderRichText();
      case "selectTable":
        return renderSelectTable();
      case "multiSelect":
        return renderMultiSelect();
      default:
        return null;
    }
  };

  const renderInlineSubmitButtons = () => {
    const { format } = ischema as any;

    if (format === "display" || format === "hidden") return null;

    if (enableInlineSubmit) {
      return [
        <StyledButton
          className="formizo-h-m"
          theme={{
            color: "green",
            display: focusing ? "inline-flex" : "none",
            width: 30,
          }}
          onClick={() => _onInlineSubmit(ischema.name)}
        >
          <Tooltip title="Submit" key={0} arrow={true}>
            <i className="far fa-check-circle" />
          </Tooltip>
        </StyledButton>,
        <StyledButton
          className="formizo-h-m"
          theme={{
            color: ColorX.GetColorCSS("Primary"),
            display: focusing ? "inline-flex" : "none",
            width: 30,
          }}
          onClick={() => _onInlineRevert(ischema.name)}
        >
          <Tooltip title="Revert" key={1} arrow={true}>
            <i className="fas fa-history" />
          </Tooltip>
        </StyledButton>,
      ];
    }

    return null;
  };

  const renderField = () => {
    const vstyle = ischema.variant || fieldStyle;

    if (!inTable && vstyle === "grid" && !ischema.noLabelGrid && ischema.format !== "selectTable") {
      return (
        <Grid container key={"grid"}>
          {ischema.label ? (
            <>
              <Grid container item xs={labelXS + (enableInlineSubmit ? 0 : 1)} key={0}>
                <Box paddingX={labelPaddingX} borderRight={separator} width={"100%"} height={"100%"}>
                  <HStack justifyContent={labelJustify} height="100%">
                    <Typography>{ischema.label}</Typography>
                  </HStack>
                </Box>
              </Grid>
              <Grid container item xs={fieldXS + (enableInlineSubmit ? 0 : 2)} key={1}>
                <Box paddingX={fieldPaddingX} width={"100%"}>
                  {renderInside()}
                </Box>
              </Grid>
            </>
          ) : (
            <Grid container item xs={12} key={1}>
              <Box paddingX={fieldPaddingX} width={"100%"}>
                {renderInside()}
              </Box>
            </Grid>
          )}
          {enableInlineSubmit && (
            <Grid container item xs={3} key={2}>
              <Box paddingX={fieldPaddingX} width={"100%"}>
                {renderInlineSubmitButtons()}
              </Box>
            </Grid>
          )}
        </Grid>
      );
    } else {
      if (enableInlineSubmit) {
        return [renderInside(), renderInlineSubmitButtons()];
      } else {
        return renderInside();
      }
    }
  };

  if (!ischema) return null;

  return (
    <HStack>
      {renderField()}
      <Spacer />
    </HStack>
  );
};

FField.defaultProps = {
  ischema: {},
  iname: "",
  inTable: false,
  _onValueChange: () => {},
  _onBlurInlineSubmit: () => {},
  _onInlineSubmit: () => {},
  _onInlineRevert: () => {},
  _setHiddenValue: () => {},
  _Validate: () => {},
  addOns: {},
  formValue: {},
  formError: {},
  auth: {},
  level: 999,
  enableInlineSubmit: false,
  errorsShowOnHelperText: true,
  readOnly: false,
  fieldStyle: "grid",
  labelXS: 3,
  labelPaddingX: 1,
  labelJustify: "flex-end",
  fieldXS: 6,
  fieldPaddingX: 1,
  separator: "1px solid rgba(125, 125, 125, 0.2)",
  fieldSize: "normal",
  theme: {},
};

export default FField;
