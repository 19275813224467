import React, { useState } from "react";
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Alert } from "@mui/material";
import { store } from "static";
import { HtmlParserWrapper } from "Components/HtmlParserWrapper";
import PasswordFeild from "./PasswordFeild";
import { Criteria } from "./Validation";
import { DATA_LINK, backendConnector } from "connectors";

interface IPasswordChangerProps {
  username: string;
}

const PasswordChanger = ({ username }: IPasswordChangerProps) => {
  const [open, setOpen] = React.useState(false);
  const [alert, setAlert] = React.useState(<div />);

  const onClose = () => {
    setOpen(false);
  };

  const onSubmit = async (prevPw: string, newPw: string, newPwConfirm: string) => {
    try {
      const rtn = await backendConnector.post(DATA_LINK.UserChangePassword, {
        UserName: username,
        OldPassword: prevPw,
        NewPassword: newPw,
        NewPasswordConfirm: newPwConfirm,
      });

      let { Success } = rtn;

      if (!Success) return setAlert(<Alert severity="error">{rtn?.payload?.message}</Alert>);

      store.Alert(rtn?.payload?.message);
      setAlert(<div />);

      setOpen(false);
    } catch (e) {
      setAlert(<Alert severity="error">{JSON.stringify(e)}</Alert>);
    }
  };

  return (
    <div className="PasswordChanger">
      <Button
        variant="outlined"
        color="primary"
        onClick={() => {
          setOpen(true);
        }}
      >
        Change Password
      </Button>
      <PopupPasswordChanger open={open} onClose={onClose} onSubmit={onSubmit} onAlert={alert} />
    </div>
  );
};

interface IPopupPasswordChangerProps {
  open: boolean;
  onClose: () => void;
  onAlert: JSX.Element;
  onSubmit: (prevPw: string, newPw: string, newPwConfirm: string) => void;
}

const PopupPasswordChanger = ({ open, onClose, onAlert, onSubmit }: IPopupPasswordChangerProps) => {
  const [prevPw, setPrevPw] = useState("");
  const [newPw, setNewPw] = useState("");
  const [newPwConfirm, setNewPwConfirm] = useState("");

  return (
    <Dialog open={open ? true : false} onClose={onClose} aria-labelledby="form-dialog-title">
      <DialogTitle id="form-dialog-title">Change Password</DialogTitle>
      <DialogContent>
        <DialogContentText>{<HtmlParserWrapper html={Criteria.join("<br>")} />}</DialogContentText>
        <PasswordFeild autoFocus id="prevPw" label="Previous Password" onInput={(value) => setPrevPw(value)} />
        <PasswordFeild id="newPw" label="New Password" criteria={["required", "minimum8", "comprise"]} onInput={(value) => setNewPw(value)} />
        <PasswordFeild id="newPwConfirm" label="New Password Confirm" criteria={["required", "minimum8", "comprise"]} onInput={(value) => setNewPwConfirm(value)} />
        {onAlert}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Cancel
        </Button>
        <Button
          disabled={!(prevPw && newPw && newPwConfirm)}
          onClick={() => {
            onSubmit(prevPw, newPw, newPwConfirm);
          }}
          color="primary"
          type="submit"
        >
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default PasswordChanger;
