import { Paper, Typography } from "@mui/material";
import MDEditor from "@uiw/react-md-editor";
import { HStack, VStack } from "Components/Layouts";

export const EmbeddingsViewer = ({ processedText }: { processedText: string[] }) => {
  if (!processedText) return <></>;

  return (
    <VStack className="embeddings-container" width="100%" sx={{ height: "100%", overflow: "scroll", justifyContent: "flex-start" }}>
      {processedText.map((text, index) => (
        <Paper key={index} component={HStack} p={1} justifyContent="flex-start" width={"100%"}>
          <Typography sx={{ p: 1, width: "5%" }}>{`${index + 1}.`}</Typography>
          {/* <Typography sx={{ p: 1, whiteSpace: "pre-wrap" }}>{text}</Typography> */}
          <MDEditor.Markdown source={text} style={{ whiteSpace: "pre-line", overflowWrap: "break-word", width: "95%" }} />
        </Paper>
      ))}
    </VStack>
  );
};
