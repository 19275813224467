import { Component } from "react";

import PropsType from "prop-types";

import { Accessor } from "static";

interface IFFHiddenProps {
  //data
  ischema: any;
  iname: string;

  //root func
  _setHiddenValue: (name: string, value: any) => void;

  //runtime
  formValue: any;
}

export const FFHidden: React.FC<IFFHiddenProps> = (props) => {
  const { ischema, iname, formValue, _setHiddenValue } = props;

  const ivalue = Accessor.Get(formValue, iname);
  _setHiddenValue(iname, ivalue);

  return null;
};
