import styles from "./ScoreCard.module.css";

import { HStack } from "Components/LabIZO/Stackizo";
import { ColorX } from "static";
import { HtmlParserWrapper } from "Components/HtmlParserWrapper";
import { ScoreCardProps } from "./ScoreCard";

type ScoreCardVarProps = ScoreCardProps;

export const ScoreCardVar: React.FC<ScoreCardVarProps> = ({ title, values, remarks }) => {
  return (
    <div className={styles.ScoreCard} style={{ background: ColorX.GetColorCSS("CardBG2") }}>
      <h4 className={styles.smallContent}>{values && <HtmlParserWrapper html={`${values ?? ""}`} />}</h4>
      <HStack width="100%">
        <p className={styles.title}>{title && <HtmlParserWrapper html={`${title ?? ""}`} />}</p>
        {remarks && <p className={styles.title} style={{ color: ColorX.GetColorCSS("Primary"), paddingLeft: 5 }}>{`  -  ${remarks}`}</p>}
      </HStack>
    </div>
  );
};
