import DeleteIcon from "@mui/icons-material/Delete";
import FilterListIcon from "@mui/icons-material/FilterList";
import { Badge, Box, Button, IconButton, Popover, ToggleButton, ToggleButtonGroup } from "@mui/material";
import React from "react";
import { Control, useFieldArray, useForm } from "react-hook-form";
import { MultiFilterHeaders } from "./components/MultiFilterHeaders";
import { FilterField } from "./components/FilterField";
import { FilterOperator } from "./components/FilterOperator";
import { FilterValue } from "./components/FilterValue";
import { Condition, DefaultFilterType, FilterFormProps } from "./interfaces/Condition.interface";
import { Filterable, CombinationOperator } from "./interfaces/Filterable.interface";
import { parseCondition } from "./constants/parseCondition";
import { HStack } from "Components/Layouts";
import { parseSelector } from "./constants/parseSelector";

interface MultiFilterProps {
  _onFilterChange: (arg: any) => void;
  filterables: Filterable[];
  DefaultFilter: DefaultFilterType;
  activeSelector: any;
}
const detectFilter = (activeSelector: any) => {
  let ttlLength = 0;

  ["and", "or"].forEach((operator) => {
    const operatorKey = `$${operator}`;
    if (activeSelector?.[operatorKey]?.length > 0) {
      ttlLength = activeSelector[operatorKey].length;
    }
  });

  return ttlLength;
};

const defaultCondition: Condition = {
  field: "",
  operator: "",
  value: "",
};

const MultiFilter = ({ filterables, DefaultFilter, _onFilterChange, activeSelector }: MultiFilterProps) => {
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);
  const updatedDefaultFilter = DefaultFilter && parseSelector(DefaultFilter);
  const [logicOperator, setLogicOperator] = React.useState<CombinationOperator>(updatedDefaultFilter && updatedDefaultFilter.logicalOperator ? updatedDefaultFilter.logicalOperator : CombinationOperator.and);
  const { control, reset, resetField, handleSubmit } = useForm<FilterFormProps>({
    defaultValues: {
      conditions: updatedDefaultFilter ? updatedDefaultFilter.conditions : [{ field: "", operator: "", value: "" }],
    },
  });
  const { fields, append, remove } = useFieldArray({
    name: "conditions",
    control: control,
  });

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const onAdd = () => append(defaultCondition);
  const onReset = () => {
    reset({ conditions: [defaultCondition] });
    _onFilterChange({});
  };
  const onDelete = (fields: any[], index: number) => {
    if (fields.length && fields.length > 0) {
      remove(index);
    }
  };
  const onApply = (data: FilterFormProps) => {
    const conditions = data.conditions;

    //append type to each condition
    const conditionsExtended = conditions.map((condition) => {
      const field = filterables.find((f) => f.field === condition.field);
      if (field) {
        return { ...condition, keyValue: field.keyName, type: field.type, CustomCondition: field.CustomCondition, CustomFilter: field.CustomFilter };
      }
      return condition;
    });

    const selectors = parseCondition(conditionsExtended);
    let combineSlector: Record<string, typeof selectors> = {};
    if (selectors.length > 0) {
      combineSlector[logicOperator] = selectors;
    }
    _onFilterChange(combineSlector);
    handleClose();
  };

  const renderToggle = () => {
    return (
      <ToggleButtonGroup
        color="primary"
        value={logicOperator}
        exclusive
        onChange={(event: React.MouseEvent<HTMLElement>, value: string) => {
          setLogicOperator(value as CombinationOperator);
        }}
        aria-label="Platform"
      >
        <ToggleButton value={CombinationOperator.and}>And</ToggleButton>
        <ToggleButton value={CombinationOperator.or}>Or</ToggleButton>
      </ToggleButtonGroup>
    );
  };

  const renderPopOver = () => {
    return (
      <Popover
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <HStack>
          <HStack sx={{ display: "block", justifyItems: "flex-start" }}>
            <Button onClick={onAdd}>Add</Button>
            <Button onClick={onReset}>Reset</Button>
          </HStack>
          {renderToggle()}
        </HStack>
        <MultiFilterHeaders />
        {fields.map((field, index) => (
          <Box key={`filter-${index}`} display="flex" gap={2} alignItems="flex-end" p={1}>
            <IconButton onClick={() => onDelete(fields, index)}>
              <DeleteIcon />
            </IconButton>
            <FilterField control={control as Control<any, any>} resetField={resetField} filterables={filterables} name={`conditions.${index}`} width="150px" />
            <FilterOperator control={control as Control<any, any>} filterables={filterables} name={`conditions.${index}`} width="200px" defaultOption={field.operator || ""} />
            <FilterValue control={control as Control<any, any>} filterables={filterables} name={`conditions.${index}`} width="250px" />
          </Box>
        ))}
        <Button onClick={handleSubmit(onApply)}>Apply</Button>
      </Popover>
    );
  };

  return (
    <Box>
      <Button
        onClick={handleClick}
        size="small"
        startIcon={
          <Badge badgeContent={detectFilter(activeSelector) || DefaultFilter?.conditions?.length} color="error">
            <FilterListIcon />
          </Badge>
        }
      >
        Filters
      </Button>
      {renderPopOver()}
    </Box>
  );
};

export default MultiFilter;
