import { IMessengerProps } from "../interfaces";

export const defaultProps: IMessengerProps = {
  url: "",
  streamUrl: "",
  clearHistoryUrl: "",
  name: "Talk to Virtual Assistant",
  LiveChatEnabled: false,

  viaBackend: false,
  intermediateResponse: "",

  //zchat
  hideLongAnswer: false,
  revertReadMore: true,
  buttonWidthFitContent: true,

  pressEnterToSend: true,
  animated: true,

  showHeader: false,
  showFooter: true,
  showStatus: false,
  showDateTime: true,
  showLapseTime: true,
  isUpdatingIntermediateResponse: false,

  showInAvatar: false,
  showOutAvatar: false,
  hideSameAvatar: true,
  avatarAtTop: false,

  appendTextAfterSend: true,
  showQuickReplyBar: false,
  showQuickRepliesAsButtons: false,
  showQuickRepliesStack: true,
  disableButtonsAfterSend: true,
  disableTemplateButtonsAfterSend: false,

  canClickOnIn: false,
  canClickOnOut: true,
  HTMLEnabled: true,
};
