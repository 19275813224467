import { Grid } from "@mui/material";
import { DBAnswerSpec } from "interfaces";
import { IEntityValue, IFLEDoc } from "interfaces/db.interfaces/fle.interface";
import { Control, Controller, useFormContext } from "react-hook-form";
import { DropDownSelector } from "Components/DropDownSelector";
import { DeleteButton } from "Components/IconButtons";
import { HStack } from "Components/Layouts";

export interface IEntitySelectionProps {
  GridProps: { container: boolean };
  entityOptions: { label: string; value: string }[];
  name: `contexts.${number}.setting.values`;
}

export function EntitySelection(props: IEntitySelectionProps) {
  const { GridProps, entityOptions, name } = props;
  const { watch, setValue, control } = useFormContext<IFLEDoc<DBAnswerSpec>>();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { onChange, value } }) => {
        if (!value) return <></>;
        const settings = value as IEntityValue[];

        if (settings.length === 0) {
          settings.push({ entity: "", values: [] });
          setValue(name, settings);
        }

        const inSelected = (e: string) => {
          const selectedEntity = settings.map((_s) => _s.entity);
          if (selectedEntity.includes(e)) return true;
          return false;
        };

        const methods = {
          onSelect: (value: string, index: number) => {},
          onDelete: (svInx: number) => {
            settings.splice(svInx, 1);
            setValue(name, settings);
          },
        };

        return (
          <Grid container>
            <Grid {...GridProps} item xs={11}>
              {settings.map((_s, _i) => {
                return (
                  <Grid {...GridProps} item xs={4} md={3} lg={3} key={_i}>
                    <SelectorWithDelete
                      //
                      control={control}
                      index={_i}
                      name={`${name}.${_i}.entity`}
                      options={entityOptions.map((e: any) => ({ label: e.label, value: e.value, disabled: inSelected(e.value) }))}
                      onSelect={methods.onSelect}
                      onDelete={methods.onDelete}
                    />
                  </Grid>
                );
              })}
            </Grid>
          </Grid>
        );
      }}
    />
  );
}

interface ISelectorWithDeleteProps {
  index: number;
  control: Control<IFLEDoc<DBAnswerSpec>>;
  name: `contexts.${number}.setting.values.${number}.entity`;
  onSelect?: (e: string, index: number) => void;
  onDelete?: (index: number) => void;
  options: { label: string; value: string }[];
}

function SelectorWithDelete(props: ISelectorWithDeleteProps) {
  const { index, name, control } = props;
  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { onChange, value } }) => (
        <HStack width="100%">
          <DropDownSelector
            value={value}
            options={props.options}
            handleChange={(value) => {
              onChange(value);
              if (props.onSelect) props.onSelect(value, index);
            }}
          />
          <DeleteButton
            onClick={() => {
              if (props.onDelete) props.onDelete(index);
            }}
          />
        </HStack>
      )}
    />
  );
}
