import { useEffect, useState } from "react";

import { v4 } from "uuid";
import _ from "lodash";
import { Box } from "@mui/material";

import { VStack } from "Components/LabIZO/Stackizo";
import { Authority, store } from "static";
import { observer } from "mobx-react";
import "./Messenger.css";
import { IMessengerProps } from "./interfaces/messenger-props.interface";
import { defaultProps } from "./constants/messenger-default-props";
import ZChat from "./components/ZChat/ZChat";
import { MiddlewareConnector } from "Pages/CombinedTalk2VA/connectors/middleware.connector";
import { MessengerLink } from "./components/MessengerLink";
import { IQuickReply, ZChat as IZChat, T2EContext } from "interfaces/mw.interfaces/response.interface";
import { useMessages } from "./hooks/use-messages";
import { useModelOption } from "Pages/CombinedTalk2VA/components/Arena/hooks/use-model-option";
import { ModelSelectHeader } from "Components/ModelSelectHeader";
import { HeaderComponent } from "./components/HeaderComponent/HeaderComponent";
import { CusAuth } from "interfaces";
import { SYS_NAME } from "constants/system-name";

export enum MESSAGE_STATUS {
  PENDING = "pending",
  SENT = "sent",
  RECEIVED = "received",
  READ = "read",
  Error = "error",
}

function Messenger(props: IMessengerProps) {
  const [sessionId, setSessionId] = useState<string>(props.sessionId ? props.sessionId : v4());
  const [customModel, setCustomModel] = useState<string>();
  const connector = new MiddlewareConnector(process.env.REACT_APP_CHATBOTDOMAIN ?? "http://localhost:5034");
  const { modelOption } = useModelOption();
  const useMsg = props.customUseMessage;
  const preSetUseMsg = useMessages({
    onMsgSent: props.onMsgSent,
    connector: connector,
    intermediateResponseUrl: props.intermediateResponseUrl,
    enableLiveChat: props.LiveChatEnabled,
  });
  const {
    messages, //
    quickReplies,
    ratingSystem,
    typing,
    detect,
    stateMethod,
    analyzerRef,
    setMessages,
    model,
  } = useMsg || preSetUseMsg;

  useEffect(() => {
    stateMethod.sendWelcomeMessage(sessionId, {
      qaMode: props.qaMode,
      stream: props.streamOutput,
      streamUrl: props.streamUrl,
    });
  }, []);

  /**
   * Methods to be passed to ZChat as props. These methods are called by components inside ZChat
   * Make these methods as simple as possible by making use of stateMethod to handle state changes
   */
  const zchatPropsCallback = {
    onSend: (
      { text }: { text: string },
      optional: {
        hideInput?: boolean;
        displayText?: string;
        message_id?: string;
      } = {}
    ) => {
      stateMethod.sendText(sessionId, text, {
        ...optional,
        payloadMeta: {
          message_id: optional.message_id,
          knowledgeGroup: props.knowledgeGroup,
          tasks: props.tasks,
          qaMode: props.qaMode,
          project: props.project,
          model: customModel,
          userId: store.user.UserDisplayName ?? "talk2va",
          stream: props.streamOutput,
          streamUrl: props.streamUrl,
          llm: props.llm,
        },
      });
    },
    onQuickReply: (quickReply: IQuickReply, msgId: string) => {
      if (quickReply.payload != null)
        stateMethod.sendText(sessionId, quickReply.payload, {
          displayText: quickReply.title,
          payloadMeta: {
            // message_id: optional.message_id,
            knowledgeGroup: props.knowledgeGroup,
            qaMode: props.qaMode,
            project: props.project,
            model: customModel,
            userId: store.user.UserDisplayName ?? "talk2va",
            stream: props.streamOutput,
            streamUrl: props.streamUrl,
          },
          type: "button",
        });
    },
    onCardBTNPress: async () => {},
    onMsgPress: (
      msgId: string,
      msg: {
        text: string;
      }
    ) => {
      const record = analyzerRef.current.findRecord(msgId);
      if (record && props.onMsgSelected) props.onMsgSelected(record.addOns);
    },

    onClearHistory: async () => {
      const newSessionId = v4();
      setSessionId(newSessionId);
      store.setRole();
      // stateMethod.addLineBreak();
      setMessages([]);
      stateMethod.sendWelcomeMessage(newSessionId, {
        stream: props.streamOutput,
        streamUrl: props.streamUrl,
        qaMode: props.qaMode,
      });
    },
    onSetMessages: (_messages: IZChat[]) => {
      setMessages((messages) => [..._messages]);
    },
    onContextUpdate: async (actionId: string, contexts: T2EContext[]) => {
      await stateMethod.updateContext(sessionId, actionId, contexts);
    },
    onRegenerateResponse: async (item: IZChat, text: string) => {
      await stateMethod.regenerateAnswer(item, text);
    },
  };

  //Header priority : props.header > Model Select Authority ? ModelSelectHeader > HeaderComponent
  return (
    <VStack sx={{ width: "100%", minHeight: "calc(100%)", height: "100%", background: "#fff" }}>
      {props.header ? (
        props.header
      ) : props.showModelOption ? (
        <ModelSelectHeader defaultChatbotName={props.name ?? SYS_NAME} option={modelOption} setModel={setCustomModel} value={customModel} />
      ) : (
        <HeaderComponent chatbotName={props.name ?? SYS_NAME} modelName={model} />
      )}

      <MessengerLink />
      <Box className="t2va-chat" sx={{ width: "100%", height: "calc(100% - 50px)", paddingBottom: "0", pt: "0px" }}>
        <ZChat
          typing={typing}
          messages={messages}
          quickReplies={quickReplies}
          detect={detect}
          browser={store.browser}
          ratingSystem={ratingSystem}
          user={{ _id: 1 }}
          //methods
          {...zchatPropsCallback}
          //props
          showQuickRepliesStack={props?.showQuickRepliesStack}
          showQuickRepliesAsButtons={props?.showQuickRepliesAsButtons}
          quickReplyBar={props?.showQuickReplyBar}
          customInputbar={props?.customInputbar}
          hideInputbar={props?.hideInputbar}
          feedbackUrl={props?.feedbackUrl}
          regenerateOutput={props?.regenerateOutput}
          inputValue={props?.inputValue}
        />
        {/* <Snackbar anchorOrigin={{ vertical: "bottom", horizontal: "center" }} open={state.sbopen} autoHideDuration={6000} onClose={method.CloseSB}>
          <Alert onClose={method.CloseSB} severity={state.sbseverity} sx={{ width: "100%" }}>
            {state.sbmsg ?? ""}
          </Alert>
        </Snackbar> */}
      </Box>
    </VStack>
  );
}

Messenger.defaultProps = defaultProps;
export default observer(Messenger);
