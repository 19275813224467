import { FileCopy } from "@mui/icons-material";
import { IconButton, SxProps, Theme } from "@mui/material";
import { store } from "static";

interface ICopyButtonProps {
  onClick?: () => void;
  disabled?: boolean;
  onClickCopy?: string;
  sx?: SxProps<Theme>;
  iconSx?: SxProps<Theme>;
}

export const CopyButton = ({ onClick, onClickCopy, sx, iconSx, ...props }: ICopyButtonProps) => {
  const copyToClipboard = (text: string) => {
    navigator.clipboard.writeText(text);
    store.Alert("Copied to clipboard", "success");
  };

  const handleClick = (event: React.MouseEvent) => {
    event.stopPropagation();

    if (onClick) onClick();
    if (onClickCopy) {
      copyToClipboard(onClickCopy);
    }
  };

  return (
    <IconButton onClick={handleClick} {...props} sx={{ p: 0, ...sx }}>
      <FileCopy sx={iconSx} />
    </IconButton>
  );
};
