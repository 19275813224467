import { FormControlLabel, Switch, Accordion, AccordionSummary, AccordionDetails, Grid, Box, Typography } from "@mui/material";
import { HStack, VStack } from "Components";
import { useState } from "react";
import { ExpandMore } from "@mui/icons-material";
interface ConversationContentProps {
  doc: any;
  renderField: (label: string, value: any, width?: string | number, fontSize?: number, maxHeight?: number | string, sx?: {}) => JSX.Element;
}

interface IContextSummaryProps {
  alias: string;
  chunks: string[];
  filename: string;
  id: string;
  meta: {
    link?: string;
    similarity?: string;
    title?: string;
  };
}

export function ConversationContent({ doc, renderField }: ConversationContentProps) {
  const [isToggled, setIsToggled] = useState(false);

  const renderContextSummary = (o: IContextSummaryProps) => {
    const type = o.meta?.link ? "SearchResult" : "Document";
    return (
      <HStack width={"100%"}>
        <Grid container>
          {type == "SearchResult" && (
            <Grid item xs={4}>
              <Box sx={{ backgroundColor: "primary.main", color: "white", borderRadius: 1, mx: 1 }}>
                <Typography sx={{ textAlign: "center" }}>{type}</Typography>
              </Box>
            </Grid>
          )}
          {type == "Document" && (
            <Grid item xs={4}>
              <Box sx={{ backgroundColor: "info.main", color: "white", borderRadius: 1, mx: 1 }}>
                <Typography sx={{ textAlign: "center" }}>{type}</Typography>
              </Box>
            </Grid>
          )}
          <Grid>
            <Typography sx={{ textAlign: "center" }}>{o.alias}</Typography>
          </Grid>
        </Grid>
      </HStack>
    );
  };

  const renderContextDetail = (o: IContextSummaryProps) => {
    const chunkText = o.chunks.join("\n");
    return (
      <VStack>
        {renderField("Id", o.id)}
        {o.meta?.title && renderField("Website Title", o.meta?.title)}
        {o.meta?.similarity && renderField("Similarity", o.meta?.similarity)}
        {o.meta?.link && renderField("Link", o.meta?.link)}
        {renderField("Chunk", chunkText, "100%", 13, "auto")}
      </VStack>
    );
  };

  const renderTopContext = () => {
    const top_context = doc.top_contexts;
    return (
      <VStack sx={{ width: "100%" }}>
        {top_context.map((o: any) => (
          <Accordion sx={{ width: 0.95 }} key={o.id}>
            <AccordionSummary expandIcon={<ExpandMore />}>{renderContextSummary(o)}</AccordionSummary>
            <AccordionDetails>{renderContextDetail(o)}</AccordionDetails>
          </Accordion>
        ))}
      </VStack>
    );
  };
  return (
    <VStack sx={{ width: "100%" }}>
      <FormControlLabel
        control={<Switch checked={isToggled} onChange={(event) => setIsToggled(event.target.checked)} name="toggle" color="primary" />}
        label={isToggled ? "Top contexts" : "Retrieval"}
      />
      {isToggled ? renderTopContext() : renderField("Retrieval", doc.context, "100%", 12, "auto")}
    </VStack>
  );
}
