import { IMsg } from "interfaces/db.interfaces/arena.interface";
import { HStack, VStack } from "Components";
import ZCMsg from "Components/Messenger/components/ZChat/ZCMsg/ZCMsg";
import { v1 } from "uuid";

interface IModelConvMsg {
  msg: {
    text?: string;
  };
}

function renderConversation(msgs: IMsg[], border?: boolean) {
  const borderWidth = border ? 1 : 0;

  return (
    <VStack
      sx={{
        width: "100%",
        border: `${borderWidth}px solid rgba(0, 95, 115, 0.7)`,
        // borderImage: "linear-gradient(to bottom right, rgba(0, 95, 115, 0.3) , rgba(0, 95, 115, 1))",
        borderImageSlice: 1,
      }}
      key={v1()}
    >
      {msgs?.map((o) => {
        let fallback;
        switch (o.msg.lang) {
          case "EN":
            fallback = o.msg.fallback?.EN[0];
            break;
          case "TC":
            fallback = o.msg.fallback?.TC[0];
            break;
          case "SC":
            fallback = o.msg.fallback?.SC[0];
            break;
          default:
            fallback = o.msg.fallback?.EN[0];
            break;
        }

        // First set the text to the fallback from user._id === 2
        const text = {
          msg: {
            text: o.msg.text ? o.msg.text : fallback,
          },
        };
        if (o.user._id == 1) {
          text.msg.text = o.msg.text ? o.msg.text : "-";
        }

        const poe = o.user._id == 1 ? "out" : "in";

        return (
          <ZCMsg
            message_id=""
            cssp="zchat z"
            key={v1()}
            _onQuickReply={() => {}}
            pos={poe}
            item={text as any}
            last={true}
            typingBubbles={false}
            showQuickRepliesAsButtons={true}
            HTMLEnabled={true}
            opacity={1}
          />
        );
      })}
    </VStack>
  );
}

export default function ModelConvMsg({ msgs, messageID }: { msgs: IMsg[]; messageID: string }) {
  console.log("messages: ", msgs);

  const lastIndex = msgs
    ?.slice()
    .reverse()
    .findIndex((msg: IMsg) => msg.user._id === 1);

  // Get the elements from the last user._id === 1 to the end of the array
  const boundedMsgs: IMsg[] = msgs?.slice(msgs?.length - lastIndex - 1);
  console.log("boundedMsgs: ", boundedMsgs);

  const unboundedMsgs = msgs?.filter((msg) => !boundedMsgs.some((newMsg) => newMsg._id === msg._id));
  // console.log("unboundedMsgs: ", unboundedMsgs);

  return (
    <VStack sx={{ width: "80%" }}>
      {renderConversation(unboundedMsgs, false)}
      {renderConversation(boundedMsgs, true)}
    </VStack>
  );
}
