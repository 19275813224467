import React, { Component } from "react";
import { Box } from "@mui/material";
import "./ZChat.css";

class ZCAvatar extends Component {
  constructor() {
    super();
    this.state = {};
  }

  componentDidMount() {
    this.setState((state, props) => ({
      ...props,
    }));
  }

  static getDerivedStateFromProps(nextProps, prevStat) {
    if (prevStat !== nextProps) {
      return {
        ...nextProps,
      };
    }
  }

  componentWillUnmount() {
    this.setState = (state, callback) => {
      return;
    };
  }

  render() {
    let { cssp, iuser, hideImg, visibility, onAvatarClick } = this.state;
    visibility = visibility ? visibility : "visible";

    return (
      <div className={cssp + " avatarbox"}>
        {!hideImg && (
          <div className={cssp + " avatar"} onClick={() => onAvatarClick(iuser)}>
            <Box
              sx={{
                height: "30px",
                width: "30px",
                backgroundColor: "primary.main",
                visibility: visibility,
              }}
              className={"syva_logo"}
            ></Box>
          </div>
        )}
      </div>
    );
  }
}

export default ZCAvatar;
