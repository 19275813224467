import _ from "lodash";
import { SelectorValue, ConditionsExtended } from "../interfaces/Condition.interface";
import { ArrayOperators, ConditionOperator, NumberOperators, SelectOperators } from "../interfaces/Filterable.interface";

export const parseCondition = (conditions: ConditionsExtended[]) => {
  const selectors = conditions.map((condition) => {
    const selector: Record<string, Record<string, SelectorValue>> = {};

    if (condition.operator === "equals") condition.operator = ConditionOperator.eq;
    if (condition.operator === "contains") condition.operator = "$regex";
    if (condition.operator in NumberOperators) condition.operator = NumberOperators[condition.operator];
    // if (condition.operator in ArrayOperators) condition.operator = ArrayOperators[condition.operator];
    if (condition.operator in SelectOperators) condition.operator = SelectOperators[condition.operator];

    // backend query modification
    if (condition.type === "custom" && _.isFunction(condition.CustomCondition)) {
      let res = condition.CustomCondition(condition);
      res.customFilter = condition.CustomFilter;
      return res;
    }

    if (condition.type === "array") {
      if (condition.keyValue) {
        selector[condition.field] = {
          [condition.operator]: {
            [condition.keyValue]: `(?i)${condition.value}`,
          },
        };
      } else {
        selector[condition.field] = {
          [condition.operator]: `(?i)${condition.value}`,
        };
      }
      return selector;
    }

    //boolean condition modification
    if (condition.type === "boolean") {
      selector[condition.field] = {
        $eq: condition.value == "true",
      };
      return selector;
    }

    //case insensitive for "contains"
    if (condition.operator === ConditionOperator.regex) {
      selector[condition.field] = {
        [ConditionOperator.regex]: `(?i)${condition.value}`,
      };
      return selector;
    }

    //else
    selector[condition.field] = {
      [condition.operator]: condition.value,
    };
    return selector;
  });
  const cleanedSelectors = selectors.filter((s) => {
    const field = Object.keys(s)[0];
    const operator = Object.keys(s[field])[0];
    const value = s[field][operator];
    return field && operator && value != undefined;
  });
  return cleanedSelectors;
};
