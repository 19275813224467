import { Link } from "@mui/material";
import { HStack, VStack } from "Components";
import { ZCMsg } from "Components/Messenger/components";
import { backendConnector, DATA_LINK } from "connectors";
import { useEffect, useState } from "react";
import { v1 } from "uuid";

export function ConversationHistory({ doc, period }: any) {
  const [history, setHistory] = useState<any[]>([]);
  const [page, setPage] = useState(0);
  const opacity = 1;
  const eachPage = 5;
  const [total, setTotal] = useState(0);

  useEffect(() => {
    if (doc?.session_id) getHistory(doc.session_id);
  }, [doc.session_id, page]);
  async function getHistory(session_id: string) {
    const res = await backendConnector.post(
      DATA_LINK.GPTConvList,
      {
        selector: {
          $and: [
            {
              session_id: {
                $eq: session_id,
              },
            },
          ],
        },
        sort: [
          {
            CREATED_AT: "asc",
          },
        ],
        limit: eachPage,
        skip: page * eachPage,
      },
      period
    );

    if (total === 0) setTotal(res?.payload?.total);
    setHistory(res?.payload?.docs);
  }

  const onNextPage = (e: any) => {
    setPage((page) => page + 1);
  };

  const onPreviousPage = (e: any) => {
    setPage((page) => Math.max(0, page - 1));
  };
  if (!doc?.session_id) return <></>;
  return (
    <VStack sx={{ width: "100%" }}>
      {history.map((o) => {
        const input = {
          msg: {
            text: o.answer,
          },
          user: {},
          createdAt: o.CREATED_AT,
        };

        const output = {
          msg: {
            text: o.question,
          },
          user: {},
          createdAt: o.received_at_iso,
        };

        // Show border if the conversation log is selected
        const borderWidth = o._id === doc._id ? 2 : 0;
        return (
          <VStack
            sx={{
              width: "100%",
              border: `${borderWidth}px solid`,
              borderImage: "linear-gradient(to bottom right, rgba(0, 95, 115, 0.3) , rgba(0, 95, 115, 1))",
              borderImageSlice: 1,
            }}
            key={v1()}
          >
            <ZCMsg
              cssp="zchat z"
              message_id=""
              key={v1()}
              _onQuickReply={() => {}}
              pos={"out"}
              item={output}
              last={true}
              typingBubbles={false}
              showQuickRepliesAsButtons={true}
              HTMLEnabled={true}
              opacity={opacity}
            />
            <ZCMsg
              message_id=""
              cssp="zchat z"
              key={v1()}
              _onQuickReply={() => {}}
              pos={"in"}
              item={input}
              last={true}
              typingBubbles={false}
              showQuickRepliesAsButtons={true}
              HTMLEnabled={true}
              opacity={opacity}
            />
          </VStack>
        );
      })}
      <HStack sx={{ marginTop: "auto" }}>
        {page > 0 && (
          <Link component="button" variant="body2" sx={{ marginX: "20px" }} onClick={onPreviousPage}>
            Previous Page
          </Link>
        )}
        {page < total / eachPage - 1 && (
          <Link component="button" variant="body2" sx={{ marginX: "20px" }} onClick={onNextPage}>
            Next Page
          </Link>
        )}
      </HStack>
    </VStack>
  );
}
