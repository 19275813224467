import { VStack } from "Components";
import Formizo from "Components/LabIZO/Formizo";
import { DATA_LINK, backendConnector } from "connectors";
import { useEffect, useState } from "react";
import { ErrorX, store } from "static";
import { schema } from "./schema";
import { ISpamCheckConfig } from "interfaces/db.interfaces/config.interfaces";
import _ from "lodash";

const SysSpamSetting: React.FC<{}> = () => {
  const [settingDoc, setSettingDoc] = useState<{ SettingDoc: ISpamCheckConfig } | null>(null);

  const getSettings = async (): Promise<void> => {
    try {
      store.isLoading(true);
      const { Success, payload, message } = await backendConnector.post<ISpamCheckConfig>(DATA_LINK.SYSSpamConfig, {});
      store.isLoading(false);

      if (Success) {
        setSettingDoc({ SettingDoc: payload });
      } else {
        store.Alert(message, "error");
      }
    } catch (e: any) {
      ErrorX.Handle(e);
    }
  };

  const handleSubmit = async (data: { SettingDoc: ISpamCheckConfig }): Promise<void> => {
    //change data from string to number
    const cleanedSpamCheck = _.cloneDeep(data.SettingDoc);
    delete (cleanedSpamCheck as any).JWT;

    try {
      store.isLoading(true);
      const { Success, message } = await backendConnector.post<{}>(DATA_LINK.SYSSpamConfigEdit, cleanedSpamCheck);
      store.isLoading(false);

      if (Success) {
        store.Alert("System message updated successfully", "success");
      } else {
        store.Alert(message, "error");
      }
    } catch (e: any) {
      store.isLoading(false);
      store.Alert(e.message, "error");
    }
  };

  useEffect(() => {
    getSettings();
  }, []);

  if (!settingDoc) {
    store.isLoading(true);
    return <></>;
  }

  store.isLoading(false);

  return <Formizo height="100%" schema={schema} buttons={["Revert", "Submit"]} onSubmit={handleSubmit} defaultValue={settingDoc} />;
};

export { schema };
export default SysSpamSetting;
