import { TablizoCustom } from "Components/LabIZO/Tablizo";
import { Info, Add, Edit } from "./schema/form-schema";
import { Typography } from "@mui/material";
import { FormizoSchema } from "Components/LabIZO/Formizo";
import { FormizoCustom } from "Components/LabIZO/Formizo/FormizoSchema.interface";
import { VStack } from "Components/LabIZO/Stackizo";
import { store } from "static";
import { SwitchButton } from "Components/SwitchButton/SwitchButton";
import { Filterables } from "./schema/filter-schema";
import { DATA_LINK } from "connectors/datalink";
import { RoleId, Roles } from "interfaces/role.interface";
import { AvailLANG } from "interfaces";
import { CopyToAllButton } from "./CopyToAllButton";

const defaultLang = store.sysInfo.Language.default;
const Table: TablizoCustom = (tbLang: { display: string; value: string }) => [
  {
    label: "Enabled",
    name: "disabled",
    width: 100,
    reqAuth: "FAQ",
    reqLevel: 999,
    reqFunc: "Enable",
    Cell(row, field, fetchData) {
      return <SwitchButton defaultValue={!field} URL={DATA_LINK.FAQEnable} row={row} fetchData={fetchData} />;
    },
  },
  {
    label: "ID",
    name: "name",
    filterable: true,
    defaultSort: "asc",
  },
  {
    label: "Alias",
    name: "alias",
    filterable: true,
  },
  {
    label: `Type`,
    name: `type`,
    filterable: true,
  },
  {
    label: `Button Caption (${tbLang.value})`,
    name: `intent.${tbLang.value}.description`,
    filterable: true,
  },
  {
    label: `Question (${tbLang.value})`,
    name: `intent.${tbLang.value}.examples.0.text`,
    filterable: true,
  },
  {
    label: `Answer - Public (${tbLang.value})`,
    name: `answer.${RoleId.GENERAL_PUBLIC}.text.${tbLang.value}.0`,
    filterable: true,
    htmlFormat: true,
  },
  {
    label: `No. of Examples (${tbLang.value})`,
    name: `intent.${tbLang.value}.examples.length`,
    // Cell(row, field, addOns) {
    //   return <div>{row.intent?.[tbLang.value]?.examples?.length || 0}</div>;
    // },
    valueGetter(row) {
      return row.intent?.[tbLang.value]?.examples?.length || 0;
    },
    filterable: false,
  },
  {
    label: `Created Time`,
    name: `CREATED_AT`,
    filterable: false,
  },
  {
    label: `Last Updated Time`,
    name: `LASTMODIFIED`,
    filterable: false,
  },
];

export const QuickReply: FormizoSchema = {
  label: "Quick Reply Buttons",
  name: "answer.quickReplies",
  canAdd: true,
  canDelete: true,
  headerStyle: "outlined",
  addStyle: "header",
  array: [
    {
      label: "Display",
      width: 50,
      name: " ",
      tabs: [
        {
          label: "EN",
          page: [
            {
              label: "Display",
              name: "title.EN",
              format: "text",
              validate: defaultLang == "EN" ? ["required"] : [],
            },
          ],
        },
        {
          label: "TC",
          page: [
            {
              label: "Display",
              name: "title.TC",
              format: "text",
              validate: defaultLang == "TC" ? ["required"] : [],
            },
          ],
        },
        {
          label: "SC",
          page: [
            {
              label: "Display",
              name: "title.SC",
              format: "text",
              validate: defaultLang == "SC" ? ["required"] : [],
            },
          ],
        },
      ],
    },
    {
      label: "Button Payload",
      name: "payload",
      format: "select",
      selectStyle: "dropdown",
      selectRef: "intents",
      selectCap: "caption",
      selectVal: "_id",
      placeholder: "Intent",
      validate: ["required"],
      variant: "grid",
      width: 250,
    },
  ],
};

const Questions: FormizoCustom = (formValue, addOns, fLang: { display: string; value: string }) => [
  {
    label: `${fLang.display}(${fLang.value})`,
    name: `intent.${fLang.value}.examples.0.text`,
    format: "text",
    validate: ["required"],
  },
  {
    label: "Other Languages",
    foldStyle: "none",
    collapse: store.sysInfo.Language.available.map((o) => {
      if (o.value === fLang.value) return <div />;
      return {
        label: `${o.display}`,
        name: `intent.${o.value}.examples.0.text`,
        format: "text",
      };
    }),
  },
];
const Answers: FormizoCustom = (formValue, addOns, fLang: { display: string; value: string }) => [
  {
    tabs: Roles.map((role) => ({
      label: `${role.display[store.sysInfo.Language.default as AvailLANG]}`,
      page: [
        {
          label: ` ${fLang.display}(${fLang.value})`,
          name: `answer.${role.value}.text.${fLang.value}.0`,
          format: "textarea",
          rows: 5,
          variant: "outlined",
        },
        {
          label: "Other Languages",
          foldStyle: "none",
          collapse: store.sysInfo.Language.available.map((o) => {
            if (o.value === fLang.value) return {};
            return {
              label: `${o.display}`,
              name: `answer.${role.value}.text.${o.value}.0`,
              format: "textarea",
              rows: 5,
              variant: "outlined",
            } as FormizoSchema;
          }),
        },
        {
          label: "Rating System ",
          name: `answer.${role.value}.rating`,
          format: "bool",
        },
        {
          label: "Image Source",
          name: `answer.${role.value}.image`,
          format: "multiSelect",
          selectStyle: "autocomplete",
          optionType: "static",
          options: [
            // Ref : Image_path of hbb-syva-chatbot
            { value: "contactUs", label: "contactUs" },
            { value: "conversation", label: "conversation" },
            { value: "dataAccessRequest", label: "dataAccessRequest" },
            { value: "dataCorrectionRequest", label: "dataCorrectionRequest" },
            { value: "doctorSearch", label: "doctorSearch" },
            { value: "downloadForMobile", label: "downloadForMobile" },
            { value: "fallBack", label: "fallBack" },
            { value: "forbidden", label: "forbiden" },
            { value: "identityHcp", label: "identityHcp" },
            { value: "identityHcprof2", label: "identityHcprof2" },
            { value: "identityHcprof", label: "identityHcprof" },
            { value: "identityHcr", label: "identityHcr" },
            { value: "mobileEhealthForGreeting", label: "mobileEhealthForGreeting" },
            { value: "successfulRegist", label: "successfulRegist" },
            { value: "UnderMaintenance", label: "UnderMaintenance" },
          ],
        },
        {
          label: "Learn More",
          name: "",
          collapse: [
            {
              label: "Title",
              width: 40,
              name: " ",
              tabs: [
                {
                  label: "EN",
                  page: [
                    {
                      label: "Title",
                      name: `answer.${role.value}.learnMore.title.EN`,
                      format: "text",
                    },
                  ],
                },
                {
                  label: "TC",
                  page: [
                    {
                      label: "Title",
                      name: `answer.${role.value}.learnMore.title.TC`,
                      format: "text",
                    },
                  ],
                },
                {
                  label: "SC",
                  page: [
                    {
                      label: "Title",
                      name: `answer.${role.value}.learnMore.title.SC`,
                      format: "text",
                    },
                  ],
                },
              ],
            },
            {
              label: "Display",
              width: 40,
              name: " ",
              tabs: [
                {
                  label: "EN",
                  page: [
                    {
                      label: "Display",
                      name: `answer.${role.value}.learnMore.text.EN`,
                      format: "text",
                    },
                  ],
                },
                {
                  label: "TC",
                  page: [
                    {
                      label: "Display",
                      name: `answer.${role.value}.learnMore.text.TC`,
                      format: "text",
                    },
                  ],
                },
                {
                  label: "SC",
                  page: [
                    {
                      label: "Display",
                      name: `answer.${role.value}.learnMore.text.SC`,
                      format: "text",
                    },
                  ],
                },
              ],
            },
            {
              label: "URL",
              name: "",
              width: 40,
              tabs: [
                {
                  label: "EN",
                  page: [
                    {
                      label: "URL",
                      name: `answer.${role.value}.learnMore.url.EN`,
                      format: "text",
                    },
                  ],
                },
                {
                  label: "TC",
                  page: [
                    {
                      label: "URL",
                      name: `answer.${role.value}.learnMore.url.TC`,
                      format: "text",
                    },
                  ],
                },
                {
                  label: "SC",
                  page: [
                    {
                      label: "URL",
                      name: `answer.${role.value}.learnMore.url.SC`,
                      format: "text",
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          label: "Quick Reply Buttons",
          name: `answer.${role.value}.quickReplies`,
          canAdd: true,
          canDelete: true,
          headerStyle: "outlined",
          addStyle: "header",
          array: [
            {
              label: "Display",
              width: 50,
              name: " ",
              tabs: [
                {
                  label: "EN",
                  page: [
                    {
                      label: "Display",
                      name: "title.EN",
                      format: "text",
                      validate: defaultLang == "EN" ? ["required"] : [],
                    },
                  ],
                },
                {
                  label: "TC",
                  page: [
                    {
                      label: "Display",
                      name: "title.TC",
                      format: "text",
                      validate: defaultLang == "TC" ? ["required"] : [],
                    },
                  ],
                },
                {
                  label: "SC",
                  page: [
                    {
                      label: "Display",
                      name: "title.SC",
                      format: "text",
                      validate: defaultLang == "SC" ? ["required"] : [],
                    },
                  ],
                },
              ],
            },
            {
              label: "Button Payload",
              name: "payload",
              format: "select",
              selectStyle: "dropdown",
              selectRef: "intents",
              selectCap: "caption",
              selectVal: "_id",
              placeholder: "Intent",
              validate: ["required"],
              variant: "grid",
              width: 250,
            },
          ],
        },
        {
          label: "",
          name: "",
          format: "display",
          Custom: (data: any, field: any, state: any, _onValueChange: (feildName: string, value: any) => {}) => {
            return <CopyToAllButton data={data} currentRole={role.value} _onValueChange={_onValueChange} />;
          },
        },
      ],
    })),
  },
];

const Settings: FormizoSchema[] = [
  {
    inject: (
      <VStack alignItems="flex-start" width="100%">
        <Typography style={{ fontSize: 9 }}>{"*ID will add FAQ_ prefix to associated components ID."}</Typography>
        <Typography style={{ fontSize: 9 }}>{"*Only alphanumeric characters (a-z, A-Z, 0-9) and underscore (_) is allowed."}</Typography>
      </VStack>
    ),
  },
  {
    label: "ID",
    name: "id",
    validate: ["required", "plain"],
    format: "text",
  },
  {
    label: "Advanced Settings",
    collapse: [
      {
        label: "Alias",
        name: "alias",
        format: "text",
        placeholder: "(Optional) Alias will be the same as ID if empty.",
      },
      {
        label: "Description",
        name: "description",
        format: "text",
        placeholder: "(Optional) Description will be the same as Alias if empty.",
      },
      {
        label: "Button Caption (EN)",
        name: "btn.EN",
        format: "text",
        placeholder: "(Optional) Caption will be the same as Description if empty.",
      },
      {
        label: "Button Caption (TC)",
        name: "btn.TC",
        format: "text",
        placeholder: "(Optional) Caption will be the same as Description if empty.",
      },
      {
        label: "Button Caption (SC)",
        name: "btn.SC",
        format: "text",
        placeholder: "(Optional) Caption will be the same as Description if empty.",
      },
    ],
  },
];

const schema = {
  Table,
  Info,
  Add,
  Edit,
  Questions,
  Answers,
  Settings,
  Filterables,
};

export default schema;
