import { Box, IconButton, List, ListItem, ListItemIcon, Typography } from "@mui/material";
import { HStack, VStack } from "Components";
import DisplaySetting from "./DisplaySetting";
import DataSetting from "./DataSetting";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import { DataItem, TopicItem } from "../../Interface/TopicCluster.interface";
import { getColor } from "../PerceptualMap/PerceptualMap";
import { useEffect, useState } from "react";
import { ArrowBack, ArrowForward } from "@mui/icons-material";
import { DATA_LINK, backendConnector } from "connectors";
interface ISettingPanel {
  data: TopicItem[];
  handlePointClick: (dataItem: TopicItem) => void;
  importantTopic: number;
}

export default function SettingPanel({ data, handlePointClick, importantTopic }: ISettingPanel) {
  const [currentPage, setCurrentPage] = useState(1);
  const [topics, setTopics] = useState(data);

  useEffect(() => {
    setTopics(data);
    if (data?.length > 0) {
      getCurrentTopicName(data, currentPage);
    }
  }, [data]);

  useEffect(() => {
    getCurrentTopicName(data, currentPage);
  }, [currentPage]);

  const getCurrentTopicName = async (_data: TopicItem[], _page: number) => {
    if (!_data || _data.length === 0) return;
    const startIndex = (_page - 1) * importantTopic;
    const endIndex = _page * importantTopic;
    const currentData = _data.slice(startIndex, endIndex);
    const updatedData = await Promise.all(
      currentData.map(async (entry) => {
        if (!entry.topic_name || entry.topic_name === "") {
          const examples = entry.examples.map(({ lang, text }) => ({ lang, text }));
          const { payload, Success } = await backendConnector.post<string>(DATA_LINK.GetTopicName, { examples }, { workspace: "master" });
          if (Success) {
            return { ...entry, topic_name: payload };
          } else return entry;
        }
        return entry;
      })
    );

    setTopics((prev) => [...prev.slice(0, startIndex), ...updatedData, ...prev.slice(endIndex)]);
  };

  const renderSetting = () => {
    return (
      <Box
        sx={{
          height: "100%",
          width: "100%",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Typography variant="h5" sx={{ color: "primary.main", textAlign: "center", paddingY: "5px" }}>
          Setting
        </Typography>
        <Box
          sx={{
            overflowY: "auto",
            flexGrow: 1,
            paddingY: "5px",
          }}
        >
          <DisplaySetting />
          <DataSetting />
        </Box>
      </Box>
    );
  };

  const renderClassList = () => {
    const startIndex = (currentPage - 1) * importantTopic;
    return (
      <Box sx={{ height: "100%", width: "100%", margin: "5px" }} display="flex" flexDirection="column">
        <Typography variant="h5" sx={{ color: "primary.main", textAlign: "center", marginBottom: "5px" }}>
          Topics
        </Typography>
        <Box sx={{ width: "100%", overflowY: "auto", flexGrow: 1 }}>
          <List>
            {topics.slice(startIndex, currentPage * importantTopic).map((entry, index) => (
              <ListItem
                key={`color-class-${index}`}
                onClick={() => {
                  handlePointClick(entry);
                }}
              >
                <ListItemIcon>
                  <FiberManualRecordIcon sx={{ color: getColor(startIndex + index) }} />
                </ListItemIcon>
                <Typography variant="body2">{entry.topic_name || "..."}</Typography>
              </ListItem>
            ))}
          </List>
        </Box>
        {/* Pagination */}
        <HStack>
          <IconButton
            onClick={() => {
              if (currentPage > 1) setCurrentPage(currentPage - 1);
            }}
            disabled={currentPage === 1}
          >
            <ArrowBack />
          </IconButton>
          <Typography>{`${currentPage}/${Math.ceil(topics.length / importantTopic)}`}</Typography>
          <IconButton
            onClick={() => {
              if (currentPage < topics.length / importantTopic) setCurrentPage(currentPage + 1);
            }}
            disabled={currentPage === topics.length / importantTopic}
          >
            <ArrowForward />
          </IconButton>
        </HStack>
      </Box>
    );
  };

  return (
    <VStack width="100%" height="100%">
      <VStack height={"40%"} width="100%">
        {renderClassList()}
      </VStack>
      <VStack height={"60%"} width="100%">
        {renderSetting()}
      </VStack>
    </VStack>
  );
}
