import { Grid, Typography } from "@mui/material";
import { DBAnswerSpec } from "interfaces";
import { IEntityContext, IEntityValue, IFLEDoc } from "interfaces/db.interfaces/fle.interface";
import { useFormContext } from "react-hook-form";
import { DropDownSelector } from "Components/DropDownSelector";
import { AddButton, DeleteButton } from "Components/IconButtons";
import { HStack } from "Components/Layouts";

interface IProps {
  entityValueOptions: any;
  name: `contexts.${number}.setting.values`;
  contextIndex: number;
}

export function EntityValSelection(props: IProps) {
  const { entityValueOptions, name, contextIndex } = props;
  const getSettingValues = (context: IEntityContext, svInx: number) => context.setting.values[svInx].values;
  const lastSettingVal = (context: IEntityContext, svInx: number) => getSettingValues(context, svInx)[getSettingValues(context, svInx).length - 1];
  const { watch, setValue, control } = useFormContext<IFLEDoc<DBAnswerSpec>>();

  const settings = watch(name) as IEntityValue[];
  const context = watch(`contexts.${contextIndex}`) as IEntityContext;

  return (
    <Grid container>
      {settings.map((sVal, svInx) => {
        const inSelected = (svInx: number, e: string) => {
          const selectedEntity = settings[svInx].values;
          if (selectedEntity.includes(e)) return true;
          return false;
        };
        return (
          <Grid container item key={svInx}>
            {(
              <Grid container item xs={11} md={11}>
                <Grid
                  item
                  container
                  md={12}
                  lg={2}
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <Typography sx={{ whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis" }}>{sVal.entity}</Typography>
                </Grid>

                <Grid item container md={12} lg={10}>
                  {sVal.values.map((sValVal, sValValInx) => {
                    return (
                      <Grid container item xs={6} md={4} lg={3} key={sValValInx}>
                        <HStack>
                          <DropDownSelector
                            value={sValVal}
                            options={entityValueOptions[sVal.entity].map((e: { label: string; value: string }) => (inSelected(svInx, e.value) ? { ...e, disabled: true } : e))}
                            handleChange={(value) => {
                              settings[svInx].values[sValValInx] = value;
                              setValue(name, settings);
                            }}
                          />
                          <DeleteButton
                            onClick={() => {
                              settings[svInx].values.splice(sValValInx, 1);
                              setValue(name, settings);
                            }}
                          />
                        </HStack>
                      </Grid>
                    );
                  })}
                </Grid>
              </Grid>
            ) || <></>}

            <Grid container item xs={1}>
              <AddButton
                disabled={!sVal.entity || lastSettingVal(context, svInx) === ""}
                onClick={() => {
                  const prevSettingValues = getSettingValues(context, svInx);
                  settings[svInx].values = [...prevSettingValues, ""];
                  setValue(name, settings);
                }}
              />
            </Grid>
          </Grid>
        );
      })}
    </Grid>
  );
}
