import React from "react";
import { FormizoCustom } from "../Formizo/FormizoSchema.interface";
import { ILogExportSchemaEntryWithAuth } from "interfaces/be.interfaces/file-export-interface";
import { DATA_LINK } from "connectors";

export interface DatumizoProps {
  base?: DatumizoBase;
  inject?: null | React.ReactNode;
  injectRightButtons?: null | React.ReactNode;
  children?: null | React.ReactNode;
  noDefaultTable?: boolean;
  addOns: object;
  onMounted?: (callbacks: any) => void | undefined;
  onQuitInner?: (id?: string, callback?: () => void) => void;
  onLoad?: () => void;
  onDataChange?: (filterData: any, rtnPayload?: any) => void;

  //settings
  serverSidePagination: boolean;
  refreshOnPageChange: boolean;
  languageSelector: boolean;
  languageOptions: DatumizoLanguageOpt[];
  docID?: string | null;

  //Custom Tab
  renderCustomTab?: (defaultTab: string, handleTabChange: (newTab: string) => void) => JSX.Element;
  tabValue?: string;
  filterDataByTab?: (tabValue: string, data: any[]) => any[];
  //Check any popup is open
  isPopupOpen?: boolean;

  //DatePicker
  datePickerCallback?: (dates: moment.Moment[]) => void;

  // control the visible columns in the table
  //Structure: {name: boolean}
  columnVisibilityModel?: any;
}

export interface DatumizoBase {
  title: string;

  exportDoc?: string;
  rowIdAccessor?: string;
  reqAuth: string;
  columnAuth?: string;
  showSelector?: boolean;
  noDefaultButtons?: boolean;
  noDefaultTable?: boolean;
  allowBackToTop?: boolean;

  tablizo?: any;

  formizo?: any;

  operations:
    | Record<DatumizoOperationType, DatumizoOperation>
    | {
        [key: string]: DatumizoOperation;
      };
  schema?: any;
  Connect: {
    DBInfo?: DATA_LINK;
    List?: DATA_LINK;
    schema: any;
    Get?: DATA_LINK;
    DeleteBulk?: string;
    SetColumnVisibilitySetting?: DATA_LINK;
  };
  buttons: {
    inline?: DatumizoButtonProps[];
    inlineOpposite?: DatumizoButtonProps[];
    left?: DatumizoButtonProps[];
    right?: DatumizoButtonProps[];
  };
  showIDOnTop?: boolean;
}
export enum DatumizoOperationType {
  Info = "Info",
  Add = "Add",
  Edit = "Edit",
  Delete = "Delete",
  DeleteBulk = "DeleteBulk",
  Export = "Export",
  Import = "Import",
  ImportConfirm = "ImportConfirm",
  Sync = "Sync",
  Enable = "Enable",
  Disable = "Disable",
  List = "List",
  Reupload = "Reupload",
}
export interface DatumizoOperation {
  title?: string | Record<any, any> | (() => string);
  content?: string | Record<any, any> | (() => string);
  url?: DATA_LINK; //| Record<any, any> | (() => DATA_LINK);
  success?: string | Record<any, any> | (() => string);
  fail?: string | Record<any, any> | (() => string);
  schema?: FormizoCustom | ILogExportSchemaEntryWithAuth[] | object[] | ((formValue: any, addOns: any, fLang: { display: string; value: string }) => FormizoCustom | object[]);
  buttons?: string[];
  readOnly?: boolean;
  onSubmit?: string | (() => any);
  Custom?: (id: "string", row: any, onQuit: any, onQuitRefresh: any, renderFormizo: any, addOns?: any) => JSX.Element;
  QuitReload?: boolean;
  onSuccess?: (payload?: any) => any;
  onFail?: (payload?: any) => any;
  accept?: string[]; // file accept
  onClick?: (...args: any[]) => any;
  defaultDoc?: any;
  replace?: boolean;
  exportFileType?: "json" | "xlsx";
  addOnsOverride?: any;
}

export interface DatumizoButtonProps {
  caption: string | Record<any, any> | ((rows: any) => string);
  icon?: JSX.Element | string;
  func?: string | Record<any, any> | (() => string);
  reqLevel?: string | number;
  reqFunc?: string;
  theme?: string | Record<any, any> | (() => string);
  disableFunc?: (id?: string, row?: any) => boolean;
  inject?: <T = any, A = any>(data: T, addOns: A) => string | React.ReactNode;
}

export interface DatumizoLanguageOpt {
  display: string;
  value: string;
}
