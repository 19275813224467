import React from "react";
import _ from "lodash";
import { ExampleItem } from "../../Interface/TopicCluster.interface";
import { AvailLANG } from "interfaces";
import { VStack } from "Components";
import { IExampleDetails, QuestionSource } from "../../Interface/TopicClusterDetail.interface";
import { useFormContext } from "react-hook-form";
import { ExampleGenerationByLang } from "./ExampleGenerationByLang";

interface ExampleGenerationProps {
  data: ExampleItem[];
}

export const ExampleGeneration: React.FC<ExampleGenerationProps> = ({ data }) => {
  const dataGroupByLang = _.groupBy(data, "lang");
  const { control, setValue } = useFormContext<IExampleDetails>();

  const onStatusChange = (lang: AvailLANG, question: string[]) => {
    setValue(`examples.${lang}`, question);
  };

  return (
    <VStack width={"100%"}>
      {Object.values(AvailLANG).map((lang) => (
        <ExampleGenerationByLang
          lang={lang}
          question={dataGroupByLang?.[lang]?.[0].text ?? data[0].text}
          data={dataGroupByLang?.[lang]?.map((q) => ({
            question: q.text,
            selected: false,
            source: QuestionSource.Cluster,
          }))}
          onStatusChange={onStatusChange}
        />
      ))}
    </VStack>
  );
};
