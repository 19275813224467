import { HeaderComponent } from "Components/Messenger/components/HeaderComponent/HeaderComponent";
import Messenger from "Components/Messenger/Messenger";
import { ChatDOMAIN, DOMAIN } from "config/config";
import { SettingPanel } from "./components/SettingPanel/SettingPanel";
import { ArenaChat } from "./components/Arena/ArenaChat";
import { CusAuth, QAMode } from "interfaces";
import { ArenaDetailsPanel } from "./components/Arena/ArenaDetailPanel";
import { T2EResponse } from "interfaces";
import { DATA_LINK, backendConnector } from "connectors";
import React, { useEffect, useState } from "react";
import { FormControl, InputLabel } from "@mui/material";
import { ITalk2VAAddOns } from "./interfaces/combined-talk2va-addons.interface";
import { Authority, store } from "static";
import { PlaygroundPanel } from "./components/SettingPanel/PlaygroundPanel";
import { SYS_NAME } from "constants/system-name";
export interface ChatTab {
  label: string;
  name: string; // for auth check
  qaMode?: QAMode;
  reqAuth?: string;
  reqLevel?: number;
  reqFunc?: string;
  noTransform?: boolean;
  icon?: React.ReactElement;
  iconPos?: "left" | "right" | "top" | "bottom";
  spacing?: number;
  alignment?: "left" | "right";
  disabled?: boolean;
  allowSuggestedIntent?: boolean;
  minHeight?: string | number;
  minWidth?: string | number;
  settingComponent?: (addOns: any, setAddOns: any) => JSX.Element;
  detailsComponentOveride?: (addOns: any, setAddOns: any) => JSX.Element;
  render: ((addOns: any, setAddOns: any) => JSX.Element) | JSX.Element;
}

interface HeaderComponentWrapperProps {
  syvaName: string;
}
const HeaderComponentWrapper: React.FC<HeaderComponentWrapperProps> = (props) => {
  const [model, setModel] = useState("");
  useEffect(() => {
    getModelName();
  }, []);
  const getModelName = async () => {
    const { payload } = await backendConnector.post(DATA_LINK.SYSMiddlewareModel, {});
    setModel(payload);
  };
  function modelIndicator() {
    const maxModelNameLength = 14;
    let displayedModelName = model;
    if (model.length > maxModelNameLength) {
      displayedModelName = model.substring(0, maxModelNameLength) + "...";
    }
    return (
      <FormControl variant="filled" fullWidth>
        <InputLabel style={{ color: "white", marginTop: "-8px", fontSize: "12px" }} id="demo-simple-select-label">
          Model
        </InputLabel>
        <HeaderComponent chatbotName={props.syvaName} modelName={displayedModelName} />
      </FormControl>
    );
  }
  return modelIndicator();
};

export const tabs: ChatTab[] = [
  {
    label: `${SYS_NAME} FAQ`,
    name: "FAQ",
    qaMode: QAMode.INTENT_CLASSIFICATION,
    reqAuth: "Talk2VA.FAQ",
    noTransform: true,
    allowSuggestedIntent: true,
    render: (addOns, setAddOns) => {
      return (
        <Messenger
          name={`${SYS_NAME} FAQ`}
          LiveChatEnabled={true}
          viaBackend={false}
          qaMode={QAMode.INTENT_CLASSIFICATION}
          url={`${ChatDOMAIN}${DATA_LINK.Talk2GPT}`}
          onMsgSent={(msg: T2EResponse, id: string) => {
            setAddOns({ ...addOns, payload: msg });
          }}
          onMsgSelected={(record: T2EResponse) => {
            setAddOns({ ...addOns, payload: record });
          }}
        />
      );
    },
  },
  // {
  //   label: `${SYS_NAME} Hybrid`,
  //   name: "Hybrid",
  //   qaMode: QAMode.HYBRID,
  //   reqAuth: "Talk2VA.Hybrid",
  //   noTransform: true,
  //   allowSuggestedIntent: true,
  //   render: (addOns: ITalk2VAAddOns, setAddOns) => {
  //     const showGenerate = addOns.tasks.includes("Composition") || addOns.tasks.includes("Summary");
  //     return (
  //       <Messenger
  //         name={`${SYS_NAME} Hybrid`}
  //         project={addOns?.project}
  //         knowledgeGroup={addOns?.knowledgeGroup}
  //         tasks={addOns.tasks}
  //         LiveChatEnabled
  //         qaMode={QAMode.HYBRID}
  //         url={`${ChatDOMAIN}${DATA_LINK.Talk2GPT}`}
  //         regenerateOutput={Authority.IsAccessibleQ(`Talk2VA.Hybrid`, 999, CusAuth.Regenerate) ? true : false}
  //         feedbackUrl={Authority.IsAccessibleQ(`Talk2VA.Hybrid`, 999, CusAuth.Feedback) ? `${DOMAIN}${DATA_LINK.UserFeedback}` : undefined}
  //         showModelOption={Authority.IsAccessibleQ(`Talk2VA.Hybrid`, 999, CusAuth.ModelSelect) ? true : false}
  //         onMsgSent={(msg: T2EResponse, id: string) => {
  //           setAddOns((addOns: ITalk2VAAddOns) => ({ ...addOns, payload: msg }));
  //         }}
  //         onMsgSelected={(record: T2EResponse) => {
  //           setAddOns((addOns: ITalk2VAAddOns) => ({ ...addOns, payload: record }));
  //         }}
  //         // customInputbar={showGenerate ? (props) => <CustomInputBarComponent showGenerate={showGenerate} addOns={addOns} props={props} /> : undefined}
  //       />
  //     );
  //   },
  //   settingComponent: (addOns, setAddOns) => <SettingPanel tab={"Hybrid"} />,
  // },
  // {
  //   label: "LLM Playground",
  //   name: "Playground",
  //   qaMode: QAMode.DIRECT_LLM,
  //   reqAuth: "Talk2VA.Playground",
  //   noTransform: true,
  //   render: (addOns: ITalk2VAAddOns, setAddOns) => {
  //     return (
  //       <Messenger
  //         name={"Playground"}
  //         qaMode={QAMode.DIRECT_LLM}
  //         llm={addOns.LLM}
  //         clearHistoryUrl={`${ChatDOMAIN}${DATA_LINK.RemoveHistory}`}
  //         feedbackUrl={Authority.IsAccessibleQ(`Talk2VA.Playground`, 999, CusAuth.Feedback) ? `${DOMAIN}${DATA_LINK.UserFeedback}` : undefined}
  //         url={`${ChatDOMAIN}${DATA_LINK.Talk2GPT}`}
  //         streamUrl={`${ChatDOMAIN}${DATA_LINK.PlaygroundStream}`}
  //         streamOutput={addOns.stream ?? false}
  //         showModelOption={Authority.IsAccessibleQ(`Talk2VA.Playground`, 999, CusAuth.ModelSelect) ? true : false}
  //         onMsgSent={(msg: T2EResponse, id: string) => {
  //           setAddOns((addOns: ITalk2VAAddOns) => ({ ...addOns, payload: msg }));
  //         }}
  //         onMsgSelected={(record: T2EResponse) => {
  //           setAddOns((addOns: ITalk2VAAddOns) => ({ ...addOns, payload: record }));
  //         }}
  //       />
  //     );
  //   },
  //   settingComponent: (addOns, setAddOns) => <PlaygroundPanel />,
  // },
  // {
  //   label: `${SYS_NAME} GPT-QA`,
  //   name: "GPTQA",
  //   qaMode: QAMode.LLM,
  //   reqAuth: "Talk2VA.GPTQA",
  //   noTransform: true,
  //   render: (addOns: ITalk2VAAddOns, setAddOns) => {
  //     const showGenerate = addOns.tasks.includes("Composition") || addOns.tasks.includes("Summary");
  //     return (
  //       <Messenger
  //         name={`${SYS_NAME} GPT-QA`}
  //         project={addOns?.project}
  //         knowledgeGroup={addOns?.knowledgeGroup}
  //         qaMode={QAMode.LLM}
  //         tasks={addOns.tasks}
  //         clearHistoryUrl={`${ChatDOMAIN}${DATA_LINK.RemoveHistory}`}
  //         feedbackUrl={Authority.IsAccessibleQ(`Talk2VA.GPTQA`, 999, CusAuth.Feedback) ? `${DOMAIN}${DATA_LINK.UserFeedback}` : undefined}
  //         url={`${ChatDOMAIN}${DATA_LINK.Talk2GPT}`}
  //         streamUrl={`${ChatDOMAIN}${DATA_LINK.Talk2GPTStream}`}
  //         streamOutput={true}
  //         showModelOption={Authority.IsAccessibleQ(`Talk2VA.GPTQA`, 999, CusAuth.ModelSelect) ? true : false}
  //         regenerateOutput={Authority.IsAccessibleQ(`Talk2VA.GPTQA`, 999, CusAuth.Regenerate) ? true : false}
  //         intermediateResponseUrl={`${ChatDOMAIN}${DATA_LINK.Talk2GPTIntermediateResponse}`}
  //         onMsgSent={(msg: T2EResponse, id: string) => {
  //           setAddOns((addOns: ITalk2VAAddOns) => ({ ...addOns, payload: msg }));
  //         }}
  //         onMsgSelected={(record: T2EResponse) => {
  //           setAddOns((addOns: ITalk2VAAddOns) => ({ ...addOns, payload: record }));
  //         }}
  //         // customInputbar={showGenerate ? (props) => <CustomInputBarComponent showGenerate={showGenerate} addOns={addOns} props={props} /> : undefined}
  //       />
  //     );
  //   },
  //   settingComponent: (addOns, setAddOns) => <SettingPanel tab={"GPTQA"} />,
  // },

  // {
  //   label: "LLM Playground",
  //   name: "Playground",
  //   qaMode: QAMode.DIRECT_LLM,
  //   reqAuth: "Talk2VA.Playground",
  //   noTransform: true,
  //   render: (addOns: ITalk2VAAddOns, setAddOns) => {
  //     return (
  //       <Messenger
  //         name={"Playground"}
  //         qaMode={QAMode.DIRECT_LLM}
  //         llm={addOns.LLM}
  //         clearHistoryUrl={`${ChatDOMAIN}${DATA_LINK.RemoveHistory}`}
  //         feedbackUrl={Authority.IsAccessibleQ(`Talk2VA.GPTQA`, 999, CusAuth.Feedback) ? `${DOMAIN}${DATA_LINK.UserFeedback}` : undefined}
  //         url={`${ChatDOMAIN}${DATA_LINK.Talk2GPT}`}
  //         streamUrl={`${ChatDOMAIN}${DATA_LINK.PlaygroundStream}`}
  //         streamOutput={addOns.stream ?? false}
  //         showModelOption={Authority.IsAccessibleQ(`Talk2VA.GPTQA`, 999, CusAuth.ModelSelect) ? true : false}
  //         onMsgSent={(msg: T2EResponse, id: string) => {
  //           setAddOns((addOns: ITalk2VAAddOns) => ({ ...addOns, payload: msg }));
  //         }}
  //         onMsgSelected={(record: T2EResponse) => {
  //           setAddOns((addOns: ITalk2VAAddOns) => ({ ...addOns, payload: record }));
  //         }}
  //       />
  //     );
  //   },
  //   settingComponent: (addOns, setAddOns) => <PlaygroundPanel />,
  // },
  // {
  //   label: "Arena",
  //   name: "Arena",
  //   reqAuth: "Talk2VA.Arena",
  //   noTransform: true,
  //   render: (addOns, setAddOns) => <ArenaChat addOns={addOns} setAddOns={setAddOns} />,
  //   detailsComponentOveride: (addOns, setAddons) => <ArenaDetailsPanel addOns={addOns} />,
  // },
  // {
  //   label: "Soul Machine",
  //   name: "SoulMachine",
  //   reqAuth: "Talk2VA.SoulMachine",
  //   noTransform: true,
  //   qaMode: QAMode.INTENT_CLASSIFICATION /* tmp used only */,
  //   render: (addOns, setAddOns) => <SoulMachineChat addOns={addOns} setAddOns={setAddOns} />,
  // },
];
