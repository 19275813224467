import { useEffect, useState } from "react";

import Formizo from "Components/LabIZO/Formizo";
import { ErrorX, store } from "static";
import { VStack, HStack } from "Components/LabIZO/Stackizo";
import schema from "./schema";
import { ISystemConfig } from "interfaces/db.interfaces/config.interfaces";
import { IErrorX } from "static/ErrorX";
import { CircularProgress } from "@mui/material";
import { DATA_LINK, backendConnector } from "connectors";

const SysIntent = () => {
  const [sysinfo, setSysinfo] = useState<ISystemConfig | null>(null);
  useEffect(() => {
    getSysInfo();
  }, []);

  const getSysInfo = async () => {
    try {
      const res = await backendConnector.post(DATA_LINK.SYSIntentGeneration, {});
      if (!res.Success) ErrorX.Handle(res as IErrorX);
      setSysinfo(res.payload);
    } catch (e) {
      store.Alert("Cannot get system info", "error");
    }
  };

  const onSubmit = async (data: any) => {
    try {
      store.isLoading(true);
      const res = await backendConnector.post(DATA_LINK.SYSIntentGenerationEdit, data);
      store.isLoading(false);
      if (res.Success) {
        store.Alert("System Config updated successfully", "success");
      } else {
        store.Alert("System Config update fails.", "error");
      }
    } catch (e: any) {
      store.Alert(ErrorX.Handle(e), "error");
    }
  };

  const renderIntentSetting = () => {
    if (!sysinfo) return <CircularProgress />;
    return (
      <Formizo
        auth={store.user.authority} //
        schema={schema}
        buttons={["Revert", "Submit"]}
        onSubmit={onSubmit}
        defaultValue={sysinfo}
      />
    );
  };

  return (
    <HStack justifyContent="flex-start" height="100%" gap={3} paddingX={4}>
      <VStack width="100%">{renderIntentSetting()}</VStack>
    </HStack>
  );
};

export { schema };
export default SysIntent;
