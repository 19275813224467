import { ArrowBack } from "@mui/icons-material";
import { Box, Button, Typography, styled } from "@mui/material";
import { HStack, VStack } from "Components";
import { DataItem, TopicItem } from "../../Interface/TopicCluster.interface";
import { useState } from "react";
import ActionPanel from "./ActionPanel";
import { DropDownPanel } from "./DropDownPanel";
import { FieldErrors, FormProvider, useForm } from "react-hook-form";
import { IExampleDetails } from "../../Interface/TopicClusterDetail.interface";
import { DATA_LINK, backendConnector } from "connectors";
import { store } from "static";
import { TopicClusterFormContext } from "../../Hooks/contexts-hook";
import { ErrorSummary } from "../ErrorSummary";
const CustomBox = styled(Box)({
  position: "absolute",
  top: "50%",
  left: "50%",
  width: "90%",
  height: "90%",
  transform: "translate(-50%, -50%)",
  border: "2px solid #000",
  backgroundColor: "white",
  zIndex: 25,
});

interface ITCDetail {
  setOpen: (open: boolean) => void;
  data: TopicItem;
  allowedActions: ("Add" | "Edit")[];
}
export default function TopicClusterDetail({ setOpen, data, allowedActions }: ITCDetail) {
  const [action, setAction] = useState<"Add" | "Edit">(allowedActions[0]);
  const methods = useForm<IExampleDetails>({ defaultValues: {}, mode: "all" });
  const [errors, setErrors] = useState<FieldErrors<IExampleDetails> | null>(null);
  const handleClose = () => {
    setOpen(false);
  };

  const handleSubmit = async () => {
    const submitStatus = {
      valid: async (_newRow: IExampleDetails) => {
        setErrors(null);
        const url = action === "Add" ? DATA_LINK.AddExample : DATA_LINK.UpdateIntent;
        const res = await backendConnector.post(url, _newRow);
        if (res.Success == false) {
          store.Alert("Request Failed: " + res.payload?.message, "error");
          return;
        }
        store.Alert("Request Success", "success");
        setOpen(false);
      },
      invalid: (errors: any) => {
        setErrors(errors);
      },
    };
    methods.handleSubmit(submitStatus.valid, submitStatus.invalid)();
  };

  const renderDetailPanel = () => {
    let option = [];
    if (allowedActions.includes("Add")) option.push({ label: "Create New FAQ", value: "Add" });
    if (allowedActions.includes("Edit")) option.push({ label: "Edit Existing FAQ", value: "Edit" });
    const onChange = (value: string) => {
      setAction(value as "Add" | "Edit");
    };
    return (
      <HStack justifyContent="flex-start" sx={{ flexWrap: "wrap", height: "100%", overflowY: "auto" }}>
        <DropDownPanel label="Action Type" value={action} options={option} onChange={onChange} />
        <VStack width="100%" height="auto" justifyContent="flex-start">
          {errors && <ErrorSummary errors={errors} />}
          <FormProvider {...methods}>
            <TopicClusterFormContext.Provider value={{ mode: action }}>
              <ActionPanel action={action} data={data} />
              <ButtonPannel onSubmit={handleSubmit} />
            </TopicClusterFormContext.Provider>
          </FormProvider>
        </VStack>
      </HStack>
    );
  };

  return (
    <CustomBox>
      <VStack width="100%" height="100%" sx={{ padding: "5px 25px" }}>
        <HStack sx={{ justifyContent: "space-between", alignItems: "center", width: "100%" }}>
          <Typography variant="h5" sx={{ color: "primary.main" }}>
            Topic Clustering Detail
          </Typography>
          <Button onClick={handleClose}>
            <ArrowBack />
          </Button>
        </HStack>
        {renderDetailPanel()}
      </VStack>
    </CustomBox>
  );
}

const ButtonPannel = ({ onSubmit }: { onSubmit: () => void }) => {
  return (
    <Button sx={{ margin: "20px" }} variant="contained" onClick={onSubmit} color="secondary">
      Submit
    </Button>
  );
};
