export enum BNR_MODE {
  BACKUP = 0,
  IMPORT = 1,
}

export const BACKUP_SERVICE: Record<string, string> = {
  db: "Database",
  watsons: "NLP (Watsons)",
  gptDoc: "GPTDocument & VectorDB",
};
