import { Box, Stack, Typography, styled } from "@mui/material";
import _ from "lodash";

export const StyledBaseBox = styled(Box)({
  border: "1px solid rgba(125, 125, 125, 0.2)",
  borderRadius: "5px",
  width: "100%",
  height: "100%",
});

export const StyledQuestionContainer = styled(Stack)({
  flexDirection: "column",
  width: "100%",
});

export const StyledQuestionRow = styled(Stack)({
  flexDirection: "row",
  width: "100%",
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
});

export const StyledContainer = styled(Stack)({
  flexDirection: "column",
  width: "100%",
});

export const StyledLabelTypography = styled(Typography)(({ theme }) => ({
  alignItems: "left",
  display: "flex",
  fontSize: 25,
  color: theme.palette.primary.main,
}));

export const StyledTypeTypography = styled(Typography)(({ theme }) => ({
  minWidth: "100px",
  textAlign: "left",
  fontSize: 18,
  color: theme.palette.primary.main,
}));

export const StyledQuestionTypography = styled(Typography)(({ theme }) => ({
  color: theme.palette.primary.main,
  justifyContent: "flex-start",
  textAlign: "left",
  fontSize: 16,
  fontFamily: "Segoe UI",
  alignItems: "flex-start",
  margin: "8px 0",
  minWidth: "100px",
}));

export const StyledBox = styled(Box)({
  flex: 1,
  display: "flex",
  justifyContent: "flex-end",
  alignItems: "center",
});

export const StyledDetailStack = styled(Stack)({
  flexWrap: "wrap",
  height: "100%",
  overflowY: "auto",
  justifyContent: "flex-start",
});

export const StyledEaxmpleBox = styled(Box)({
  flex: "0 0 10%",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
});

export const StyledQuestionBox = styled(Box)({
  display: "flex",
  justifyContent: "flex-end",
  alignItems: "center",
});
