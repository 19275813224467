import { Box, Typography } from "@mui/material";
import { Component } from "react";
import moment from "moment";
import type { Moment } from "moment";
import schema from "./schema";
import { HStack, VStack } from "Components";
import { Accessor, Authority, ColorX, store } from "static";
import Datumizo from "Components/LabIZO/Datumizo";
import { DateTimeRangePicker } from "Components/DatePicker/DateTimeRangePicker";
import { DATA_LINK } from "connectors";

type RequestLogProps = typeof RequestLog.defaultProps;

interface RequestLogStates {
  title: string;
  period: { DateRange: [Moment, Moment] };
  serverSidePagination: boolean;
  base: any;
}

class RequestLog extends Component<RequestLogProps, RequestLogStates> {
  static defaultProps = {};
  MountDatumizo: Record<string, (...args: any[]) => any> = {};

  constructor(props: RequestLogProps) {
    super(props);
    this.state = {
      title: "REQUEST LOG",
      period: { DateRange: [moment().startOf("month"), moment().endOf("month")] },
      serverSidePagination: true,
      base: {
        title: "RequestLog",
        exportDoc: "RequestLog",
        schema,
        reqAuth: "RequestLog",

        tablizo: {
          showSelector: true,
          columnsToolbar: true,
          filterToolbar: true,
          densityToolbar: true,
          exportToolbar: false,
        },

        Connect: {
          List: DATA_LINK.RequestLogList,
          SetColumnVisibilitySetting: DATA_LINK.RequestLogSetColumnVisibilitySetting,
          schema: schema.Table,
        },

        operations: {
          Export: {
            url: DATA_LINK.RequestLogExport,
            schema: schema.Export,
          },

          Info: {
            title: "REQUEST LOG DETAILS",
            url: DATA_LINK.RequestLogDBInfo,
            success: "Request Log Successful",
            fail: "Request Log Failed: ",
            schema: schema.Info,
            readOnly: true,
          },
        },

        buttons: {
          inline: [{ icon: "info", func: "Info", reqFunc: "Info", caption: "Details" }],
          right: [{ icon: "export", func: "Export", caption: (n: number) => `Export (${n === 0 ? "All" : n})`, reqFunc: "Export" }],
        },
      },
    };
  }

  componentDidMount() {
    Authority.Require("RequestLog");
    this._setAllStates();
  }

  componentDidUpdate(prevProps: RequestLogProps, prevState: RequestLogStates) {
    if (!Accessor.IsIdentical(prevProps, this.props, Object.keys(RequestLog.defaultProps))) {
      this._setAllStates();
    }
  }

  componentWillUnmount() {
    this.setState = (state, callback) => {
      return;
    };
  }

  _setAllStates = (callback?: () => void) => {
    this.setState(
      (state, props) => ({
        ...props,
      }),
      callback
    );
  };

  onMountDatumizo = (callbacks: Record<string, (...args: any[]) => any>) => {
    this.MountDatumizo = callbacks;
  };

  datePickerCallback = (dates: moment.Moment[]) => {
    if (dates.length == 2) {
      this.setState(() => ({
        period: {
          DateRange: [dates[0], dates[1]]
        }
      }));
    }
  }

  renderDatePicker = () => {
    const { period } = this.state;
    const handleDateChange = (newDates: [Moment | null, Moment | null]) => {
      if (newDates && newDates[0] && newDates[1]) {
        this.setState((state: any) => {
          return {
            period: {
              ...state.period,
              DateRange: newDates,
            },
            base: {
              ...state.base,
              exportDoc: `RequestLog_${newDates[0]!.format("YYYYMMDD")}_${newDates[1]!.format("YYYYMMDD")}`,
            },
          };
        });
        this.MountDatumizo.Reload();
        store.setFilterSettingDateRange(newDates, "RequestLog");
      }
    };

    return (
      <DateTimeRangePicker
        dates={period.DateRange}
        onChangeDates={handleDateChange}
        limitSelectedPeriod={{ unit: "years", value: 1 }}
        helperText="Select date range to filter the data. You may select up to 1 year's data."
      />
    );
  };

  renderTable = () => {
    let { base, serverSidePagination, period } = this.state;
    return (
      <Datumizo
        base={base}
        addOns={{
          ...period,
        }}
        serverSidePagination={serverSidePagination}
        onMounted={this.onMountDatumizo}
        languageSelector={false}
        datePickerCallback={this.datePickerCallback}
      />
    );
  };

  render = () => {
    const { title } = this.state;
    return (
      <VStack height="100%" width="100%">
        <Box padding={1} width="100%">
          <HStack
            justifyContent="flex-start"
            gap={8}
            style={{
              display: "flex",
              width: "100%",
            }}
          >
            <Typography
              style={{
                alignItems: "left",
                display: "flex",
                fontSize: 25,
                color: ColorX.GetColorCSS("Primary"),
              }}
            >
              {title}
            </Typography>
            {this.renderDatePicker()}
          </HStack>
        </Box>

        {this.renderTable()}
      </VStack>
    );
  };
}

export default RequestLog;
