import React, { Component } from "react";

import PropsType from "prop-types";
import _ from "lodash";
import { FormControl, FormHelperText, FormLabel, InputLabel, MenuItem, Select, Tooltip, Typography } from "@mui/material";
import { Accessor, Authority, ColorX } from "static";
import { OutlinedBox } from "Components/LabIZO/Stylizo";
import { HStack, Spacer } from "Components/LabIZO/Stackizo";
import { IFSelect } from "../../FormizoSchema.interface";

interface IFFDropdownProps {
  ischema: IFSelect;
  iname: string;
  addOns: any;

  _onValueChange: (name: string, value: string[], criteria: any) => void;
  _onBlurInlineSubmit: any;
  _onInlineSubmit: any;
  _onFieldFocus: () => void;
  _onFieldBlur: () => void;

  errorsShowOnHelperText: boolean;
  readOnly: boolean;

  formValue?: any;
  formError: any;

  ifieldStyle: string; // "grid", "standard", "filled", "outlined"
}

export const FFDropdown: React.FC<IFFDropdownProps> = (props) => {
  const { ischema, iname, addOns, _onValueChange, _onBlurInlineSubmit, _onInlineSubmit, _onFieldFocus, _onFieldBlur, errorsShowOnHelperText, readOnly, formValue, formError, ifieldStyle } = props;

  const renderOption = () => {
    if (ischema.options) {
      const opts = _.map(ischema.options, (_opt, i) => {
        if (Authority.IsAccessibleQ(_opt.reqAuth, _opt.reqLevel, _opt.reqFunc)) {
          return (
            <MenuItem key={_opt.value} value={_opt.value} disabled={_opt.disabled}>
              <Typography>{_opt.label}</Typography>
            </MenuItem>
          );
        }
      });

      return opts; // early return
    }

    //else, construct options from addOns
    let options;
    if (_.isArray(ischema.selectRef)) {
      options = ischema.selectRef;
    } else if (typeof ischema.selectRef === "string") {
      options = Accessor.Get(addOns, ischema.selectRef);
    } else {
      options = [];
    }
    return _.map(options, (o, i) => {
      let v = typeof ischema.selectVal === "string" ? Accessor.Get(o, ischema.selectVal) : o;
      let c = typeof ischema.selectCap === "string" ? Accessor.Get(o, ischema.selectCap) : o;
      let t = typeof ischema.selectTip === "string" ? Accessor.Get(o, ischema.selectTip) : o;
      if (ischema.showToolTip) {
        return (
          <MenuItem key={v} value={v} disabled={ischema.selectDisable && o[ischema.selectDisable]}>
            <Tooltip title={t || ""} placement="top" arrow>
              <Typography>{c}</Typography>
            </Tooltip>
          </MenuItem>
        );
      } else {
        return (
          <MenuItem key={v} value={v} disabled={ischema.selectDisable && o[ischema.selectDisable]}>
            {c}
          </MenuItem>
        );
      }
    });
  };
  const renderSelect = () => {
    let ivalue = Accessor.Get(formValue, iname);
    if (ivalue === undefined || ivalue === null) ivalue = "";
    return (
      <Select
        value={ivalue}
        onChange={(e) => {
          e.stopPropagation();
          _onValueChange(iname, e.target.value, ischema.validate);
        }}
        labelId={ischema.labelStyle === "within" ? "ffdropdown-label" : undefined}
        label={ischema.labelStyle === "within" ? ischema.label : undefined}
        fullWidth={ischema.fullWidth !== false}
        sx={{
          width: ischema.width || "100%",
          minWidth: ischema.minWidth || 120,
          maxWidth: ischema.maxWidth || "100%",
        }}
      >
        {renderOption()}
      </Select>
    );
  };
  const renderInside = () => {
    let ierror = Accessor.Get(formError, iname);
    const ireadOnly = ischema.readOnly || readOnly;

    let helperText = ischema.helperText;
    if (!ireadOnly && errorsShowOnHelperText) {
      helperText = ierror;
    }

    return (
      <FormControl
        error={!_.isEmpty(ierror)}
        disabled={ireadOnly}
        fullWidth={ischema.fullWidth !== false}
        style={{
          minWidth: 200,
        }}
        onFocus={(e) => {
          _onFieldFocus();
        }}
        onBlur={(e) => {
          _onFieldBlur();
          _onBlurInlineSubmit(iname);
        }}
        defaultValue={ischema.defaultValue || ""}
      >
        {ifieldStyle === "grid" ? (
          <HStack>{renderSelect()}</HStack>
        ) : ifieldStyle === "outlined" ? (
          <OutlinedBox label={ischema.label}>{renderSelect()}</OutlinedBox>
        ) : ischema.labelStyle === "within" ? (
          <>
            <InputLabel id="ffdropdown-label">{ischema.label}</InputLabel>
            {renderSelect()}
          </>
        ) : (
          <HStack>
            <FormLabel className="formizo-h-m">{ischema.label}</FormLabel>
            {renderSelect()}
            <Spacer />
          </HStack>
        )}
        {!_.isEmpty(helperText) && <FormHelperText>{helperText}</FormHelperText>}
      </FormControl>
    );
  };

  if (!ischema) return null;
  return renderInside();
};
