import { Paper } from "@mui/material";
import React from "react";
import { Box } from "@mui/system";
import { HStack } from "./Layouts";
import { CopyButton } from "./IconButtons/CopyButton";

interface IProps {
  children: object;
}

export function JsonView(props: IProps) {
  const { children } = props;
  const jsonString = JSON.stringify(children, null, 2);
  return (
    <Paper sx={{ width: "100%" }}>
      <HStack alignItems={"flex-start"} justifyContent={"space-between"} p={3}>
        <pre>{jsonString}</pre>
        <CopyButton onClickCopy={jsonString} sx={{ color: "grey" }}></CopyButton>
      </HStack>
    </Paper>
  );
}
