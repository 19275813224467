import { DBAnswerSpec } from "interfaces";
import { IFLEDoc, ISystemContextRegex } from "interfaces/db.interfaces/fle.interface";
import { useFormContext } from "react-hook-form";
import { BodyTitle } from "Components";
import { ControlledTextField } from "Components/HookForm/ControlledTextField";
import { AddButton, DeleteButton } from "Components/IconButtons";
import { HStack, VStack } from "Components/Layouts";
import { ITypeSettingProps } from "./ITypeSettingProps.interface";

export const RegexSetting = ({ setting, onChange, name, ...props }: ITypeSettingProps<ISystemContextRegex>) => {
  const { control } = useFormContext<IFLEDoc<DBAnswerSpec>>();
  return (
    <VStack width={"100%"} alignItems={"flex-start"}>
      <BodyTitle>Regex Values</BodyTitle>
      <HStack>
        <>
          {setting.values &&
            setting.values.map((sVal, svInx) => {
              return (
                <HStack key={svInx}>
                  <ControlledTextField<IFLEDoc<DBAnswerSpec>>
                    //
                    variant="filled"
                    name={`${name}.${svInx}.value`}
                    control={control}
                    rules={{ required: "This is required" }}
                    {...props}
                  />

                  <DeleteButton
                    onClick={() => {
                      setting.values && setting.values.splice(svInx, 1);
                      onChange({ ...setting });
                    }}
                    {...props}
                  />
                </HStack>
              );
            })}
        </>
        <AddButton
          onClick={() => {
            let { values } = setting;
            if (!values) values = [];

            values.push({ value: "" });
            onChange({ ...setting, values });
          }}
          {...props}
        />
      </HStack>
    </VStack>
  );
};
