import _ from "lodash";

interface IRules {
  [key: string]: (value: string) => boolean;
}
interface IErrorMsg {
  [key: string]: string;
}

const Rules: IRules = {
  required: (value: string) => !_.isEmpty(value) || _.isNumber(value),
  minimum8: (value: string) => !value || /^.{8,}$/i.test(value),
  comprise: (value: string) => !value || /^(?=.*[a-zA-Z])(?=.*[0-9])(?=.*[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?~`]).{8,}$/i.test(value),
};

const ErrorMsg: IErrorMsg = {
  required: "Required!",
  minimum8: "Must have minimum of (8) characters",
  comprise: "Must be comprised of letters, numbers, and special characters",
};

const Criteria = [
  "• Be a minimum of eight (8) characters in length; ",
  "• Be comprised of letters, numbers, and special characters (!@#$%^&*()_+\\-=[\\]{};':\"\\\\|,.<>/?~`) to the extent possible;",
  "• A maximum lifespan of 90 days for any password except system service account; ",
  "• Password history to avoid reuse of last thirteen (13) passwords; and ",
  "• Account lockout after ten (10) invalid logon attempts",
];

type CriteriaType = keyof IRules;

const Validate = (value: string, criteria: CriteriaType[] = []): { err: boolean; msg: string } => {
  let error: string = "";
  _.forEach(criteria, (criteriaKey) => {
    if (!Rules[criteriaKey](value)) {
      if (_.isEmpty(error)) {
        error = ErrorMsg[criteriaKey];
      }
    }
  });
  return { err: !_.isEmpty(error), msg: error };
};

export { Criteria, Rules, ErrorMsg, Validate };
