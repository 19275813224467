import { Box, Tab, Tabs } from "@mui/material";
import { VStack } from "Components/Layouts";
import React from "react";
import { REPLACE_MODE } from "../constants/replace-mode";
import { IDocDiff } from "../interfaces/document-diff.interface";
import { CompleteCompareDocDiff } from "./CompleteCompareDocDiff";
import { ManuallyCompareDocDiff } from "./ManuallyCompareDocDiff";

export const ReuploadViewSwap = ({
  newText,
  newFile,
  setfinishCompare,
  setCompare,
  setConfirm,
  onSubmit,
}: {
  newText: IDocDiff | null;
  newFile: { file: File; addOns: any } | null;
  setConfirm: (confirm: boolean) => void;
  setfinishCompare: (finishCompare: boolean) => void;
  setCompare: (upload: boolean) => void;
  onSubmit: (quitForm?: boolean) => void;
}) => {
  const [replaceMode, setReplaceMode] = React.useState<REPLACE_MODE>(REPLACE_MODE.MANUAL);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setReplaceMode(newValue);
  };

  return (
    <Box sx={{ width: "100%", bgcolor: "background.paper" }}>
      <Tabs value={replaceMode} onChange={handleChange} centered>
        <Tab label="Update Changes Manually" />
        <Tab label="Upload and Replace" />
      </Tabs>
      <VStack height={"100%"}>
        {replaceMode === REPLACE_MODE.MANUAL ? (
          <ManuallyCompareDocDiff newText={newText} newFile={newFile} onSubmit={onSubmit} setfinishCompare={setfinishCompare} setCompare={setCompare} />
        ) : (
          <CompleteCompareDocDiff newText={newText} setfinishCompare={setfinishCompare} setCompare={setCompare} setConfirm={setConfirm} />
        )}
      </VStack>
    </Box>
  );
};
