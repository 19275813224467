import { ExpandMore } from "@mui/icons-material";
import { Accordion, AccordionSummary, AccordionDetails } from "@mui/material";
import { ReactElement, useState, useEffect } from "react";

export interface CustomAccordionProps {
  summary: ReactElement;
  children: ReactElement | ReactElement[];
  defaultExpanded?: boolean;
  key: string;
}

export const CustomAccordion = ({ key, defaultExpanded, ...props }: CustomAccordionProps) => {
  const [expandedState, setExpandedState] = useState(defaultExpanded);

  useEffect(() => {
    setExpandedState(defaultExpanded);
  }, [defaultExpanded]);

  return (
    <Accordion sx={{ width: 0.95 }} expanded={expandedState} onChange={() => setExpandedState(!expandedState)} key={key}>
      <AccordionSummary expandIcon={<ExpandMore />}>{props.summary}</AccordionSummary>
      <AccordionDetails>{props.children}</AccordionDetails>
    </Accordion>
  );
};
