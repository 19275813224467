import { Dialog, DialogTitle, DialogContent, DialogActions, Button } from "@mui/material";
import { backendConnector, DATA_LINK } from "connectors";
import { IDocumentManager } from "Components/_PageComponents/interfaces/document-manager-api.interface";
import { useFormContext } from "react-hook-form";
import { useState } from "react";
import { store } from "static";

export const ConfirmReuploadPopup = ({
  newFile,
  confirm,
  setConfirm,
  setfinishCompare,
  onQuitRefresh,
}: {
  newFile: { file: File; addOns: any } | null;
  confirm: boolean;
  setConfirm: (confirm: boolean) => void;
  setfinishCompare: (finishCompare: boolean) => void;
  onQuitRefresh: (docId: string) => void;
}) => {
  const { getValues } = useFormContext<IDocumentManager>();

  const handleConfirmReupload = async () => {
    try {
      const rtn = newFile && (await backendConnector.upload<any>(DATA_LINK.DMUpload, newFile?.file, newFile?.addOns));
      if (rtn?.Success) {
        store.Alert("Completely Re-Uploaded", "success");
        setConfirm(false);
        setfinishCompare(false);
        onQuitRefresh(getValues("_id"));
      } else {
        store.Alert("Completely Re-Upload Failed", "error");
      }
    } catch (e: any) {
      console.error(e);
      store.Alert("Completely Re-Upload Failed" + JSON.stringify(e?.message ?? e), "error");
    }
  };

  return (
    <Dialog open={confirm} onClose={() => setConfirm(false)} aria-labelledby="form-dialog-title">
      <DialogTitle id="form-dialog-title">Confirm Re-upload</DialogTitle>
      <DialogContent>Old Changes will be completely replaced by Incoming Changes? Are you confirm to upload?</DialogContent>
      <DialogActions>
        <Button onClick={() => setConfirm(false)} color="primary">
          Cancel
        </Button>
        <Button
          onClick={() => {
            handleConfirmReupload();
          }}
          color="primary"
          type="submit"
        >
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  );
};
