import { PieChartDistribution, PieChartDistributionProps } from "Components/Graphs";
import { HStack } from "Components/Layouts";
import { ColorX } from "static";

interface PieChartStackProps {
  dataSets: {
    data: PieChartDistributionProps["data"];
    title: string;
    fallbackText?: string;
    take?: number;
  }[];
}

export const PieChartStack: React.FC<PieChartStackProps> = ({ dataSets }) => {
  const pieCharts = dataSets.map((dataSet) => (
    <PieChartDistribution
      key={dataSet.title} //
      title={dataSet.title}
      data={dataSet.data}
      fallbackText={dataSet.fallbackText}
      take={dataSet.take}
    />
  ));
  return (
    <HStack
      className="PieChartStack"
      width="100%"
      height="100%"
      style={{
        boxShadow: "5px 5px 13px #ededed, 5px 5px 13px #ffffff",
        backgroundColor: ColorX.GetColorCSS("GraphBG"),
        borderRadius: "20px",
        overflow: "hidden",
      }}
    >
      {pieCharts}
    </HStack>
  );
};
