import { Alert, AlertTitle, AlertProps } from "@mui/material";

export interface IProps extends AlertProps {
  title?: string;
  message?: string;
  severity?: "error" | "warning" | "info" | "success";
}

export function CustomAlert({ title, message = "", severity = "warning", ...props }: IProps) {
  return (
    <Alert severity={severity} {...props}>
      <AlertTitle>{title}</AlertTitle>
      {message}
    </Alert>
  );
}
