import { DataItem, TopicItem } from "../../Interface/TopicCluster.interface";
import { MultiLangTabs, VStack } from "Components";
import { useEffect, useState } from "react";
import { ExampleGeneration } from "./ExampleGeneration";
import { DATA_LINK, backendConnector } from "connectors";
import { store } from "static";
import { AvailLANG } from "interfaces";
import { IExampleDetails } from "../../Interface/TopicClusterDetail.interface";
import { useFormContext } from "react-hook-form";
import { ControlledTextFieldWithIcon } from "Components/HookForm/ControlledTextFieldWithIcon";
import { ControlledSelectWithIcon } from "Components/HookForm/ControlledSelectWithIcon";
import { StyledTypeTypography } from "../StyledComponent";

interface IActionPanel {
  action: "Add" | "Edit";
  data: TopicItem;
}

export default function ActionPanel({ action, data }: IActionPanel) {
  const { control, setValue } = useFormContext<IExampleDetails>();
  const defaultLang = (store.sysInfo.Language.default ?? "EN") as AvailLANG;

  const [intentList, setIntentList] = useState<{ _id: string }[]>([]);

  useEffect(() => {
    //Clear the _id value when action change
    setValue("_id", "");
  }, [action]);

  useEffect(() => {
    getFaqList();
    setValue("_id", data.topic_name);
  }, [data]);

  const getFaqList = async () => {
    const res = await backendConnector.post<{ docs: { _id: string }[] }>(DATA_LINK.IntentList, {}, { workspace: "master" });
    if (res.Success) {
      setIntentList(res.payload.docs);
    }
  };

  const renderAnswerPanel = () => {
    return (
      <VStack width={"100%"} sx={{ alignItems: "start", padding: "10px" }}>
        <StyledTypeTypography>Answer</StyledTypeTypography>
        <MultiLangTabs>
          {(_lang, _index) => {
            const lang = _lang as AvailLANG;
            return (
              <ControlledTextFieldWithIcon
                multiline
                name={`answer.${lang}.0`}
                label="Answer"
                rules={_lang === defaultLang ? { required: "This field is required" } : {}}
                control={control}
                fullWidth
                variant="outlined"
              />
            );
          }}
        </MultiLangTabs>
      </VStack>
    );
  };

  return (
    <VStack width="100%" height="100%" style={{ justifyContent: "flex-start" }}>
      {action === "Add" ? (
        <ControlledTextFieldWithIcon
          name="_id"
          label="FAQ ID"
          rules={{
            required: "This field is required",
            pattern: {
              value: /^[a-zA-Z0-9_]+$/,
              message: "No spaces and special characters except _",
            },
          }}
          control={control}
          fullWidth
          variant="outlined"
        />
      ) : (
        <ControlledSelectWithIcon
          name="_id"
          control={control}
          label="Suggested Intent"
          options={intentList.map((intent) => {
            return { label: intent._id, value: intent._id };
          })}
          rules={{
            required: "This field is required",
            pattern:
              action === "Edit"
                ? undefined
                : {
                    value: /^[a-zA-Z0-9_:]+$/,
                    message: "No spaces and special characters except _ and :",
                  },
          }}
          fullWidth
        />
      )}
      <ExampleGeneration data={data.examples} />
      {action === "Add" && renderAnswerPanel()}
    </VStack>
  );
}
