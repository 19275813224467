import type { Moment } from "moment";
import { Box, Typography } from "@mui/material";
import { DatePickerMoment } from "Components/DatePicker/DatePickerMoment";

export type DateValue = Moment | null;

interface DatePickerProps {
  date: DateValue;
  onChangeDate: (newDate: DateValue, formatString: string | string[]) => void;
  dateFormat?: string;
  minDate?: Moment;
  maxDate?: Moment;
  helperText?: string;
  handleFocus?: Function;
  handleBlur?: Function;
  showTime?: boolean;
  disabled?: boolean;
}

const defaultDateFormat = "YYYY-MM-DD";

export const DatePicker: React.FC<DatePickerProps> = ({ date, onChangeDate, minDate, maxDate, helperText, dateFormat, showTime, disabled }) => {
  // const handleDateDisable = (currentDate: Moment, info: { from?: Moment }) => {
  //   if (!limitSelectedPeriod) return false;
  //   if (info.from) {
  //     return Math.abs(currentDate.diff(info.from, limitSelectedPeriod.unit)) >= limitSelectedPeriod.value;
  //   }
  //   return false;
  // };

  return (
    <Box>
      <DatePickerMoment
        showTime={showTime ?? true}
        value={date}
        minDate={minDate}
        maxDate={maxDate}
        onChange={onChangeDate}
        format={dateFormat ?? defaultDateFormat}
        disabled={disabled}
        renderExtraFooter={() => (helperText ? <Typography sx={{ fontSize: "0.9rem", my: "3px" }}>{helperText}</Typography> : <></>)}
      />
    </Box>
  );
};
