import axios from "axios";
import { RoleId, T2EContext } from "interfaces";
import { QAMode, UserInput } from "interfaces/mw.interfaces/user-input.interface";
import { IResponseChunkError, T2EResponse, T2EStreamResponse, ZChat } from "interfaces/mw.interfaces/response.interface";
import { DATA_LINK } from "connectors";
import { v4 } from "uuid";
import { ILLM } from "../interfaces/combined-talk2va-addons.interface";
import { store } from "static";

export interface IOptionalConstructorParam {
  intermediateResponseUrl?: string;
}
export interface ICallback {
  onMsgSent?: (payload: any, id: any, msgs: any) => void;
  onReceiveMsg?: (payloadOut: any, payloadIn: any, startTime: any, endTime: any, id: any, version: any) => void;
  onStreaming?: any;
  onStatusChange?: (status: "typing" | "sent" | "received", id?: string) => void;
}
export interface IOptionalSendToServerParam {
  sessionId?: string;
  livechat?: boolean;
  channel?: string;
  urlParams?: object;
  userId?: string;
  jwt?: string;
  env?: string | null;
  message_id?: string;
  project?: string[];
  knowledgeGroup?: string[];
  tasks?: Array<"Chatbot" | "Summary" | "Composition">;
  model?: string;
  viaBackend?: boolean;
  lang?: string;
  qaMode?: string;
  record?: boolean;
  remarks?: any;
  stream?: boolean;
  streamUrl?: string;
  llm?: ILLM;
  outputLang?: string;
  role?: RoleId;
}

export interface MessengerInput {
  type: "system" | "text" | "button" | "attachment" | "setContext";
  content: string;
  contexts?: T2EContext[];
}

export interface IIntermidiateResponsePayload {
  type: string;
  text: string;
}

export interface IRegeneratedResponsePayload {
  retryCount: number;
  options: string[];
}

export class MiddlewareConnector {
  constructor(private url: string, private optionalParam?: IOptionalConstructorParam) {}

  async sendMsgViaBackend(input: MessengerInput, optionalParam?: IOptionalSendToServerParam) {
    const { channel, remarks, livechat, urlParams, userId, jwt, env, message_id, project, knowledgeGroup, model, viaBackend, qaMode, lang, stream, sessionId } = optionalParam || {};

    const payloadOut = {
      JWT: jwt,
      env: env,
      data: {
        user_id: userId,
        session_id: sessionId,
        message_id: message_id,
        channel: channel,
        urlParams: urlParams,
        project: project,
        knowledgeGroup: knowledgeGroup,
        model: model,
        input: input,
        lang: lang,
        livechat: livechat,
        timestamp: new Date(),
        qa_mode: qaMode,
        remarks: remarks,
        streamGPT: stream,
        instance: store.user.Instance ?? undefined,
      },
    };

    const { data } = await axios.post<{ payload: T2EResponse }>(`${this.url}/Talk2VA`, payloadOut);

    const isStream = !!stream;
    return { isStream, response: data.payload };
  }

  async sendMsg(input: MessengerInput, optionalParam?: IOptionalSendToServerParam): Promise<{ isStream: boolean; response: T2EResponse | T2EStreamResponse }> {
    const { channel, remarks, livechat, urlParams, userId, jwt, env, message_id, project, knowledgeGroup, model, viaBackend, qaMode, lang, stream, sessionId, tasks, outputLang, role } =
      optionalParam || {};
    let startTime = new Date();
    let payloadOut: UserInput | any = {};

    if (viaBackend) {
      return await this.sendMsgViaBackend(input, optionalParam);
    } else {
      payloadOut = {
        user_id: userId,
        session_id: sessionId,
        message_id: message_id,
        channel: channel,
        urlParams: urlParams,
        project: project || knowledgeGroup ? project : ["All"],
        model: model,
        knowledgeGroup: knowledgeGroup,
        input: input,
        lang: lang,
        livechat: livechat,
        tasks: tasks,
        timestamp: new Date(),
        qa_mode: qaMode,
        remarks: remarks,
        streamGPT: stream,
        outputLang: outputLang,
        role: role,
      } as UserInput;
      console.log("[>] Data Sent: " + startTime, payloadOut);

      const { data } = await axios.post<T2EResponse | T2EStreamResponse>(`${this.url}/Talk2VA`, payloadOut);

      const endTime = new Date();

      let processTime = (endTime.getTime() - startTime.getTime()) / 1000;
      console.log("[<] Data Received: " + endTime);
      console.log("[-] Process Time: " + processTime + "s");
      console.log("[-] MW Response: ", data);

      const isStream = !!stream;
      return { isStream, response: data };
    }
  }

  async sendText(text: string, optionalParam?: IOptionalSendToServerParam) {
    const input = {
      type: "text",
      content: text,
    } as MessengerInput;

    return await this.sendMsg(input, optionalParam);
  }

  async sendQuikcReply(text: string, optionalParam?: IOptionalSendToServerParam) {
    const input = {
      type: "button",
      content: text,
    } as MessengerInput;

    return await this.sendMsg(input, optionalParam);
  }

  async sendLiveChat(optionalParam?: IOptionalSendToServerParam) {
    const input = {
      type: "system",
      content: "CMD_POLLING",
    } as MessengerInput;
    return await this.sendMsg(input, optionalParam);
  }

  async getGPTResponseChunk(
    messageId: string,
    sessionId: string, //
    streamUrl: string,
    optionalStreamParam: { prevChunk?: string; model?: string; noMsgCount?: number },
    chunkCallback: (msg: ZChat[]) => Promise<void>,
    prevExtractedContext?: string
  ): Promise<T2EResponse | IResponseChunkError | undefined> {
    try {
      if (!streamUrl) throw new Error("streamUrl is required");
      if (!sessionId) throw new Error("sessionId is required");

      //initialise variables
      let finalChunkPayload: IT2EChunk["payload"] | undefined;
      let noMsgCount = optionalStreamParam?.noMsgCount ?? 0;

      const { data: chunkResponse } = await axios.post<IT2EChunk>(streamUrl, {
        qaMode: QAMode.LLM,
        message_id: messageId,
        data: {
          session_id: sessionId,
          response_id: messageId,
          prev_chunk: optionalStreamParam?.prevChunk ?? "",
          model: optionalStreamParam?.model,
          instance: store.user.Instance,
        },
      });
      if (!chunkResponse.payload.Success) return chunkResponse.payload; // Chunk Error
      const msg = chunkResponse?.payload?.message && chunkResponse?.payload?.message[0];

      // To prevent final chunk from losing the extracted context
      let extracted_context = chunkResponse?.payload?.extracted_context;
      if (!extracted_context && prevExtractedContext && chunkResponse?.payload) chunkResponse.payload.extracted_context = prevExtractedContext;

      if (chunkResponse.payload && chunkResponse.payload?.isFinished) {
        console.log("finalChunkPayload", chunkResponse.payload);
        return chunkResponse.payload;
      } else {
        if (!msg?.msg.text) {
          noMsgCount++;
          if (noMsgCount <= 5) {
            await new Promise((resolve) => setTimeout(resolve, noMsgCount * 1000));
          }
          if (noMsgCount > 10) {
            console.error("noMsgCount > 10");
            //wait for 1 second before calling the function again
            await new Promise((resolve) => setTimeout(resolve, 1000));
            return;
          }
        } else {
          // reset noMsgCount
          noMsgCount = 0;
          await chunkCallback(chunkResponse?.payload?.message ?? []);
        }

        //recursively call the function until isFinished is true
        return await this.getGPTResponseChunk(messageId, sessionId, streamUrl, { ...optionalStreamParam, noMsgCount, prevChunk: msg?.msg.text }, chunkCallback, prevExtractedContext);
      }
    } catch (e) {
      console.log(e);
      return;
    }
  }

  async getIntermediateResponse(url: string, payload: { message_id: string; model?: string }): Promise<IIntermidiateResponsePayload[]> {
    // await new Promise((resolve) => setTimeout(resolve, 200));
    const { data: intermediateResponse } = await axios.post(url, {
      data: {
        ...payload,
        instance: store.user.Instance,
      },
    });

    return intermediateResponse?.payload;
  }

  async updateContext(sessionId: string, actionId: string, contexts: T2EContext[]): Promise<T2EResponse | undefined> {
    console.log(`[>] Updating context for action ${actionId}`);

    const { data } = await axios.post<T2EResponse>(`${this.url}/Talk2VA/context/${actionId}`, {
      session_id: sessionId,
      contexts,
      instance: store.user.Instance,
    });

    return data;
  }
  async getRegeneratedResponse(payload: ZChat): Promise<IRegeneratedResponsePayload> {
    const { data: regeneratedResponse } = await axios.post(`${this.url}${DATA_LINK.Talk2GPTRegenerate}`, {
      data: {
        ...payload,
        instance: store.user.Instance,
      },
    });
    return regeneratedResponse?.payload;
  }
  async getLiveChatResponse(session_id: string, livechatCallback: (msg: ZChat[], msgId: string) => Promise<void>, optionalParam?: IOptionalSendToServerParam): Promise<T2EResponse | undefined> {
    const msgId = v4();

    try {
      const livechatPromise = this.sendLiveChat({
        sessionId: session_id,
        viaBackend: false,
        message_id: msgId,
        qaMode: optionalParam?.qaMode,
        project: optionalParam?.project,
      });
      let { response: livechatResponse } = await livechatPromise;

      const answer = livechatResponse as T2EResponse;
      if (answer.Success) {
        await livechatCallback(answer.message, msgId);
        if (answer.live_chat) {
          return await this.getLiveChatResponse(session_id, livechatCallback, optionalParam);
        } else {
          console.log("[-] Live Chat Stop Polling");
          return;
        }
      } else {
        console.log("[-] Live Chat Stop Polling");
        return;
      }
    } catch (e) {
      console.log("[-] Live Chat Stop Polling");
      return;
    }
  }
}

export interface IT2EChunk {
  Success: boolean;
  payload: T2EResponse;
  message: string;
}
