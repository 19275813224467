import { TextField } from "@mui/material";
import { DBAnswerSpec } from "interfaces";
import { store } from "static";
import { Controller, FieldError, useFormContext } from "react-hook-form";
import { IFLEDoc } from "interfaces/db.interfaces/fle.interface";
interface IContextAliasTextFeildProps {
  index: number;
  lang: string;
  name: `contexts.${number}`;
  disabled?: boolean;
}
export const ContextAliasTextFeild = (props: IContextAliasTextFeildProps) => {
  const defaultLang = store.sysInfo.Language.default;
  const { lang } = props;
  const { setValue, getValues, control } = useFormContext<IFLEDoc<DBAnswerSpec>>();

  const _validate = (value?: string) => {
    if (lang != defaultLang) return; //no restriction on non-default language

    const currentContext = getValues(props.name);
    if (!value && !currentContext.alias.id) return "Alias is required";
    if (!value) value = "";

    //check if the alias is already used
    const allContexts = getValues("contexts");
    //filter out the current context
    const otherContexts = allContexts?.filter((c, i) => i != props.index);
    const allAliasesID = otherContexts?.map((c) => c.alias.id);

    //Check if the alias id is already used
    const newId = value.trim().toLowerCase().replace(/ /g, "_").replace(/[{}#]/g, "");
    if (allAliasesID?.includes(newId)) {
      return "Alias Id is already used";
    }
    return true;
  };

  const _onChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, error: FieldError | undefined) => {
    const context = getValues(props.name);

    if (!context.alias) {
      context.alias = {
        id: "",
        value: {
          [lang]: e.target.value,
        },
      };
    }
    context.alias.value[lang] = e.target.value;

    //update context id
    if (lang == defaultLang) {
      context.alias.id = e.target.value.trim().toLowerCase().replace(/ /g, "_");
      // "{", "#", "}" will be used as special system characters
      context.alias.id = context.alias.id.replace(/[{}#]/g, "");

      if (!error) {
        const conditionals = getValues("answers.conditionals");
        if (conditionals) {
          conditionals.forEach((_cond) => {
            const newCond = _cond.conditions.length == props.index;
            const allCondsAns = getValues("answers.conditionals") || [];

            allCondsAns.forEach((_a) => {
              if (newCond) _a.conditions.push({ key: context.alias.id, value: "default" });
              else _a.conditions[props.index].key = context.alias.id;
            });
            setValue("answers.conditionals", allCondsAns);
          });
        }
        setValue("answers.conditionals", conditionals);
      }
    }

    setValue(props.name, context);
  };

  return (
    <Controller
      name={`${props.name}.alias.value.${lang}`}
      control={control}
      render={({ field: { value, onChange }, fieldState: { error } }) => {
        return (
          <TextField
            //
            label="Context Alias"
            value={value}
            onChange={(e) => {
              onChange(e);
              _onChange(e, error);
            }}
            error={!!error}
            helperText={error?.message}
            disabled={defaultLang == lang && props.disabled} //allow editing on non-default language
          />
        );
      }}
      rules={{ validate: _validate }}
    />
  );
};
