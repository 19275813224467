import { Restore, DeleteOutline, LocalOfferOutlined, Download } from "@mui/icons-material";
import { Button, IconButton, Tooltip } from "@mui/material";
import SysDBInc from "./SysDBInc";
import { TablizoSchema } from "Components/LabIZO/Tablizo";
import { HStack } from "Components/LabIZO/Stackizo";
import { ColorX } from "static";

const reqFunc = {
  Edit: "Edit",
  Backup: "Backup",
  Import: "Import",

  Export: "Export",
  Restore: "Restore",
  Delete: "Delete",
};

const database: TablizoSchema[] = [
  {
    label: "Databases",
    name: "name",
  },
  {
    label: "Included",
    name: "included",
    width: 115,
    Cell: (row, field, addOns) => {
      return <SysDBInc dbname={row.name} included={field} onToggle={addOns.onToggle} />;
    },
  },
];
const restore: TablizoSchema[] = [
  {
    label: "Version",
    name: "name",
  },
  {
    label: "Tag",
    name: "tag",
    width: 100,
  },
  {
    label: " ",
    name: "_id",
    width: 50,
    reqAuth: "System.BnR",
    reqLevel: 999,
    reqFunc: reqFunc.Restore,
    Cell: (row, field, addOns) => {
      if (!addOns.Restore) return <></>;
      return (
        <Tooltip title="Restore" arrow={true} placement="top">
          <Button
            onClick={() => {
              if (addOns.Restore) {
                addOns.Restore(field);
              }
            }}
            sx={{
              width: 25,
              minWidth: 25,
              height: 25,
              background: ColorX.GetColorCSS("Decorate2"),
              color: ColorX.GetColorCSS("ButtonText1"),
              "& :hover": {
                background: ColorX.GetColorCSS("ButtonText1"),
                color: ColorX.GetColorCSS("Decorate2"),
              },
            }}
          >
            <HStack gap={5}>
              <Restore fontSize="small" />
            </HStack>
          </Button>
        </Tooltip>
      );
    },
  },
  {
    label: " ",
    name: "",
    width: 50,
    reqAuth: "System.BnR",
    reqLevel: 999,
    reqFunc: reqFunc.Delete,
    Cell: (row, field, addOns) => {
      return (
        <Tooltip title="Delete" arrow={true} placement="top">
          <Button
            onClick={() => {
              if (addOns.Delete) {
                addOns.Delete(row._id);
              }
            }}
            sx={{
              width: 25,
              minWidth: 25,
              height: 25,
              background: ColorX.GetColorCSS("Cancel"),
              color: ColorX.GetColorCSS("ButtonText1"),
              "& :hover": {
                background: ColorX.GetColorCSS("ButtonText1"),
                color: ColorX.GetColorCSS("Cancel"),
              },
            }}
          >
            <HStack gap={5}>
              <DeleteOutline fontSize="small" />
            </HStack>
          </Button>
        </Tooltip>
      );
    },
  },
  {
    label: "  ",
    name: " ",
    width: 50,
    Cell: (row, field, addOns) => {
      return (
        <Tooltip title="Tag" arrow={true} placement="top">
          <Button
            onClick={() => {
              if (addOns.Tag) {
                addOns.Tag(row);
              }
            }}
            sx={{
              width: 25,
              minWidth: 25,
              height: 25,
              background: ColorX.GetColorCSS("Edit"),
              color: ColorX.GetColorCSS("ButtonText1"),
              "& :hover": {
                background: ColorX.GetColorCSS("ButtonText1"),
                color: ColorX.GetColorCSS("Decorate2"),
              },
            }}
          >
            <HStack gap={5}>
              <LocalOfferOutlined fontSize="small" />
            </HStack>
          </Button>
        </Tooltip>
      );
    },
  },

  {
    label: " ",
    name: "fileloc",
    width: 50,
    reqAuth: "System.BnR",
    reqLevel: 999,
    reqFunc: reqFunc.Export,
    Cell: (row, field, addOns) => {
      if (!field) return <></>;
      return (
        <Tooltip title="Download" arrow={true} placement="top">
          <IconButton
            onClick={() => {
              if (addOns.Download) {
                addOns.Download(field);
              }
            }}
            sx={{
              width: 25,
              minWidth: 25,
              height: 25,
              background: ColorX.GetColorCSS("Accuracy1"),
              color: ColorX.GetColorCSS("ButtonText1"),
              "& :hover": {
                background: ColorX.GetColorCSS("ButtonText1"),
                color: ColorX.GetColorCSS("Accuracy1"),
              },
            }}
          >
            <HStack gap={1}>
              <Download fontSize="small" />
            </HStack>
          </IconButton>
        </Tooltip>
      );
    },
  },
];

let schema = {
  database,
  restore,
  reqFunc,
};

export default schema;
