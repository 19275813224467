import React, { Component } from "react";

import PropsType from "prop-types";
import _ from "lodash";
import { Checkbox, FormControl, FormControlLabel, FormGroup, FormHelperText, FormLabel } from "@mui/material";

import { Accessor, ColorX } from "static";
import { HStack, Spacer } from "Components/LabIZO/Stackizo";
import { OutlinedBox } from "Components/LabIZO/Stylizo";

interface IFFCheckboxProps {
  //data
  ischema: any;
  iname: string;
  addOns: any;

  //root func
  _onValueChange: (name: string, value: boolean, criteria: any) => void;
  _onBlurInlineSubmit: any;
  _onInlineSubmit: any;
  _onFieldFocus: () => void;
  _onFieldBlur: () => void;

  //disability
  errorsShowOnHelperText: boolean;
  readOnly: boolean;

  //runtime
  formValue?: any;
  formError: any;

  //style
  ifieldStyle: string;
}

export const FFCheckbox: React.FC<IFFCheckboxProps> = (props) => {
  const { ischema, iname, formValue, addOns, formError, ifieldStyle, _onBlurInlineSubmit, _onFieldFocus, _onFieldBlur, _onValueChange, errorsShowOnHelperText, readOnly } = props;

  const renderOption = (ivalue: string) => {
    let options;
    if (_.isArray(ischema.selectRef)) {
      options = ischema.selectRef;
    } else {
      options = Accessor.Get(addOns, ischema.selectRef);
    }

    return _.map(options, (o, i) => {
      let val;
      let cap;
      if (_.isEmpty(ischema.selectVal)) {
        val = o;
      } else {
        val = Accessor.Get(o, ischema.selectVal);
      }
      if (_.isEmpty(ischema.selectCap)) {
        cap = o;
      } else {
        cap = Accessor.Get(o, ischema.selectCap);
      }
      let disabled = ischema.selectDisable && Accessor.Get(o, ischema.selectDisable);

      let ovalue = Accessor.Get(ivalue, val) || false;
      let oname = iname + "." + val;
      return (
        <FormControlLabel
          key={oname}
          value={ovalue}
          disabled={disabled}
          control={<Checkbox color={"primary"} checked={ovalue} onChange={(e) => _onValueChange(oname, e.target.checked, ischema.validate)} name="" />}
          label={cap}
        />
      );
    });
  };

  const renderFormGroup = () => {
    let ivalue = Accessor.Get(formValue, iname);
    if (ivalue === undefined || ivalue === null) ivalue = "";
    return <FormGroup>{ischema.selectDirection === "row" ? <HStack flexWrap="wrap">{renderOption(ivalue)}</HStack> : renderOption(ivalue)}</FormGroup>;
  };

  const renderInside = () => {
    let ierror = Accessor.Get(formError, iname);
    let ireadOnly = ischema.readOnly || readOnly;

    let helperText = ischema.helperText;
    if (errorsShowOnHelperText) {
      helperText = ierror;
    }

    return (
      <FormControl
        error={!_.isEmpty(ierror)}
        disabled={ireadOnly}
        fullWidth={ischema.fullWidth !== false}
        style={{
          minWidth: 200,
        }}
        onFocus={(e) => {
          _onFieldFocus();
        }}
        onBlur={(e) => {
          _onFieldBlur();
          _onBlurInlineSubmit(iname);
        }}
        defaultValue={ischema.defaultValue}
      >
        {ifieldStyle === "grid" ? (
          renderFormGroup()
        ) : ifieldStyle === "outlined" ? (
          <OutlinedBox label={ischema.label}>{renderFormGroup()}</OutlinedBox>
        ) : (
          <HStack>
            <FormLabel className="formizo-h-m">{ischema.label}</FormLabel>
            {renderFormGroup()}
            <Spacer />
          </HStack>
        )}
        {!_.isEmpty(helperText) && <FormHelperText>{helperText}</FormHelperText>}
      </FormControl>
    );
  };
  if (!ischema) return null;
  return renderInside();
};
