import { Typography, styled } from "@mui/material";
import { HStack } from "Components/Layouts";
import { ColorX } from "static";

const StyledSectionLabel = styled(Typography)({
  color: ColorX.GetColorCSS("Primary"),
  fontWeight: "bold",
  textTransform: "uppercase",
});

export const TableHeader: React.FC<{ label: string; width?: any }> = ({ label, width }) => {
  return (
    <HStack width={width} justifyContent="flex-start">
      <StyledSectionLabel>{label}</StyledSectionLabel>
    </HStack>
  );
};
