import { useState } from "react";

import { observer } from "mobx-react";
import { Box, Link, Typography } from "@mui/material";

import schema from "./schema";

import VersionList from "Components/VersionList";

import Formizo from "Components/LabIZO/Formizo";
import { Accessor, store, ColorX, Env } from "static";
import { VStack, HStack, Spacer } from "Components/LabIZO/Stackizo";
import { StyledButton, StyledLinearProgress } from "Components/LabIZO/Stylizo";
import { useNavigate } from "react-router-dom";
import { SYS_NAME } from "constants/system-name";
import { SyvaLogo } from "Components/syva-logo/syva-logo";
import { backendConnector, DATA_LINK } from "connectors";
import MFAVerify from "./component/MFAVerify";

interface IHomeProps {
  history?: any;

  username?: "";
  userDisplayName?: "";
  page?: "user";
  loading?: false;
  errorMsg?: "";
}

interface IFormProps {
  username: string;
}

const Home = (props: IHomeProps) => {
  const [username, setUsername] = useState("");
  const [userDisplayName, setUserDisplayName] = useState("");
  const [page, setPage] = useState("user");
  const [loading, setLoading] = useState(false);
  const [mfaChar, setMfaChar] = useState("");
  let navigate = useNavigate();

  let MountForm: any;

  const onMountForm = (callbacks?: any) => {
    MountForm = callbacks;
  };

  const _CheckUser = async (formProps: IFormProps) => {
    let req = {
      ...formProps,
    };

    try {
      const res = await backendConnector.post(DATA_LINK.CheckUserName, req);
      const { Success, payload } = res;
      if (Success) {
        setPage("password");
        setLoading(false);
        setUsername(formProps.username);
        setUserDisplayName(payload.UserDisplayName);
      } else {
        store.Alert("User not found", "error");
        setLoading(false);
      }
    } catch (e) {
      store.Alert("Cannot connect to server", "error");
      setLoading(false);
    }
  };

  const _Login = async (formProps: IFormProps) => {
    console.log("_signIn");
    let req = { username };
    req = { ...req, ...formProps };
    setLoading(true);

    try {
      const res = await backendConnector.post(DATA_LINK.SignIn, req);

      const { Success, payload } = res;
      if (Success === true) {
        if (payload.MFA) {
          setPage("mfaVerify");
          setMfaChar(payload.MFA);
          setLoading(false);
        } else {
          //Handle for Syva@Dev
          store.setUser(payload);
          const landingPage = payload.landingPage || "/StarterDashboard";
          store.Alert("Login Successful", "success");
          await Env.CheckInitialized();
          if (!store.isInitialized()) {
            navigate("/Initialize");
          } else {
            await Env.GetSysInfo();
            redirectToLoginPage(landingPage);
          }
        }
      } else {
        store.Alert(`Login Failed: ${payload}`, "error");
        setLoading(false);
      }
    } catch (e) {
      store.Alert("Cannot connect to server", "error");
      setLoading(false);
    }
  };

  const _MFAVerify = async (mfaCode: string) => {
    const req = {
      username: username,
      mfaCode: mfaCode,
    };
    setLoading(true);

    try {
      const res = await backendConnector.post(DATA_LINK.MFAVerify, req);

      const { Success, payload } = res;
      if (Success === true) {
        store.setUser(payload);
        const landingPage = payload.landingPage || "/StarterDashboard";

        store.Alert("Login Successful", "success");
        await Env.CheckInitialized();
        if (!store.isInitialized()) {
          navigate("/Initialize");
        } else {
          await Env.GetSysInfo();
          redirectToLoginPage(landingPage);
        }
      } else {
        store.Alert(`Login Failed: ${payload}`, "error");
        setLoading(false);
      }
    } catch (e) {
      store.Alert("Cannot connect to server", "error");
      setLoading(false);
    }
  };

  const _ForgotPassword = async (formProps: { username: string; email: string }) => {
    setLoading(true);
    try {
      const res = await backendConnector.post(DATA_LINK.ForgotPassword, formProps);
      const { Success, payload } = res;

      if (Success === true) {
        store.setUser(payload);
        store.Alert("Please Check your email", "success");
        setPage("user");
        setLoading(false);
      } else {
        store.Alert(payload.message, "error");
        setLoading(false);
      }
    } catch (e) {
      store.Alert("Cannot connect to server", "error");
    }
  };

  const redirectToLoginPage = (url: string) => {
    if (store.isInitialized()) {
      setTimeout(() => {
        navigate(url);
        store.isLoading(false);
      }, 1000);
    }
  };

  const renderNextButton = () => {
    return (
      <VStack width="100%" key="next">
        <StyledButton
          id="next"
          onClick={() => {
            MountForm.Submit();
          }}
          theme={{
            label: "white",
            background: loading ? ColorX.GetColorCSS("Primary2") : ColorX.GetColorCSS("Primary"),
            hover: {
              background: ColorX.GetColorCSS("Primary2"),
            },
            borderRadius: "0px",
            width: "100%",
          }}
          disabled={loading}
        >
          <HStack>
            <div>NEXT</div>
            <Spacer />
            <i className="fas fa-arrow-right" />
          </HStack>
        </StyledButton>
        {loading && <StyledLinearProgress theme={{ bar: ColorX.GetColorCSS("Primary"), background: ColorX.GetColorCSS("Primary2") }} />}
      </VStack>
    );
  };

  const renderLoginButton = () => {
    return (
      <VStack width="100%" key="next">
        <StyledButton
          id="login-button"
          onClick={() => {
            MountForm.Submit();
          }}
          theme={{
            label: "white",
            background: loading ? ColorX.GetColorCSS("Primary2") : ColorX.GetColorCSS("Primary"),
            hover: {
              background: ColorX.GetColorCSS("Primary2"),
            },
            borderRadius: "0px",
            width: "100%",
          }}
          disabled={loading}
        >
          <HStack>
            <div>Log in</div>
            <Spacer />
            <i className="fas fa-arrow-right" />
          </HStack>
        </StyledButton>
        {loading && <StyledLinearProgress theme={{ bar: ColorX.GetColorCSS("Primary"), background: ColorX.GetColorCSS("Primary2") }} />}
      </VStack>
    );
  };

  const renderForgotPasswordButton = () => {
    return (
      <VStack width="100%" key="next">
        <StyledButton
          id="next"
          onClick={() => {
            MountForm.Submit();
          }}
          theme={{
            label: "white",
            background: loading ? ColorX.GetColorCSS("Primary2") : ColorX.GetColorCSS("Primary"),
            hover: {
              background: ColorX.GetColorCSS("Primary2"),
            },
            borderRadius: "0px",
            width: "100%",
          }}
        >
          <HStack>
            <div>Forgot Password</div>
            <Spacer />
            <i className="fas fa-arrow-right" />
          </HStack>
        </StyledButton>
      </VStack>
    );
  };

  const renderForm = () => {
    return (
      <Formizo
        formID="login-form"
        height={page === "user" ? "95px" : page === "password" ? "95px" : page === "forgot" ? "160px" : "225px"}
        schema={page === "user" ? schema.loginName : page === "password" ? schema.loginPassword : page === "forgot" ? schema.forgotPassword : schema.initial}
        buttons={[page === "user" ? renderNextButton() : page === "password" ? renderLoginButton() : page === "forgot" ? renderForgotPasswordButton() : renderLoginButton()]}
        buttonPadding={0}
        onSubmit={page === "user" ? _CheckUser : page === "password" ? _Login : page === "forgot" ? _ForgotPassword : () => {}}
        onMounted={onMountForm}
        fieldStyle="standard"
        fieldSize="small"
        errorsShowOnHelperText={false}
        theme={{
          textfield: {
            input: ColorX.GetColorCSS("Primary"),
            background: "transparent",
            line: "transparent",
          },
        }}
        disabled={{ loading }}
      />
    );
  };

  const backToUser = () => {
    setPage("user");
    setUsername("");
    setUserDisplayName("");
  };
  const forgotPasswordPage = () => {
    setPage("forgot");
    setUsername("");
    setUserDisplayName("");
  };

  const renderHeaderMessage = () => {
    switch (page) {
      default:
      case "user":
        return "Log in with your User ID";
      case "password":
      case "mfaVerify":
        return <Link onClick={() => backToUser()}>{"Not " + userDisplayName + " ?"}</Link>;
      case "forgot":
        return <Link onClick={() => backToUser()}>{"Back to Login Page"}</Link>;
    }
  };

  const renderForgotButton = () => {
    return (
      <VStack style={{ height: "auto" }}>
        <StyledButton onClick={forgotPasswordPage}>
          <HStack>
            <div>Forgot Password</div>
            <Spacer />
          </HStack>
        </StyledButton>
      </VStack>
    );
  };

  const renderInside = () => {
    return (
      <VStack>
        <Spacer />
        <Box margin={1} width={1}>
          {renderHeaderMessage()}
        </Box>
        <Box width="300px" style={{ background: "white" }}>
          {page == "mfaVerify" ? <MFAVerify loading={loading} onSubmit={_MFAVerify} mfaChar={mfaChar} /> : renderForm()}
        </Box>
        {page != "forgot" && page != "mfaVerify" && renderForgotButton()}
        <Spacer />
      </VStack>
    );
  };

  const renderLogo = () => {
    let style = {
      color: ColorX.GetColorCSS("Primary2", 0.5),
      fontSize: 9,
    };
    return (
      <VStack gap="1px" alignItems="center">
        <SyvaLogo sx={{ width: "300px", height: "350px" }} />
        <Typography variant="h2" style={{ color: ColorX.GetColorCSS("Primary") }}>
          {SYS_NAME}
        </Typography>
        {VersionList(style)}
        <HStack gap={1}>{renderPackages(style)}</HStack>
      </VStack>
    );
  };

  const renderPackages = (style: any) => {
    let rtn = [];
    if (Accessor.EnvWith("AVATAR")) {
      rtn.push(
        <Typography key="avatar" style={style}>
          [AVATAR]
        </Typography>
      );
    }
    if (Accessor.EnvWith("AWS")) {
      rtn.push(
        <Typography key="aws" style={style}>
          [AWS]
        </Typography>
      );
    }
    return rtn;
  };

  return (
    <VStack
      style={{
        background: ColorX.GetColorCSS("Background"),
        color: ColorX.GetColorCSS("Primary"),
      }}
    >
      <Spacer />
      <HStack gap="75px" sx={{ overflow: "hidden" }}>
        <Spacer />
        {renderLogo()}
        {renderInside()}
        <Spacer />
      </HStack>
      <Spacer />
    </VStack>
  );
};

export default observer(Home);
