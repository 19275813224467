import { useEffect, useMemo } from "react";
export function useViewportSize(dependencies = []) {
  const viewportSize = useMemo(() => {
    const setVWVHVariables = () => {
      const vh = window.innerHeight * 0.01;
      const vw = window.innerWidth * 0.01;
      document.documentElement.style.setProperty("--vh", `${vh}px`);
      document.documentElement.style.setProperty("--vw", `${vw}px`);
    };

    window.addEventListener("resize", setVWVHVariables);

    setVWVHVariables();

    return () => {
      window.removeEventListener("resize", setVWVHVariables);
    };
  }, dependencies);

  useEffect(() => {
    return viewportSize;
  }, [viewportSize]);
}
