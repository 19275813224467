import React, { Component } from "react";

import { Typography } from "@mui/material";

import { DOMAIN } from "config/config";

import Accessizo from "Components/LabIZO/Accessizo";
import { HStack, Spacer } from "Components/LabIZO/Stackizo";
import { Accessor, store, ColorX, BrowserX } from "static";

class Footer extends Component {
  static propTypes = {};

  static defaultProps = {};

  constructor() {
    super();
    this.state = {};
  }

  componentDidMount() {
    this._setAllStates();
  }

  componentDidUpdate(prevProps, prevState) {
    if (!Accessor.IsIdentical(prevProps, this.props, Object.keys(Footer.defaultProps))) {
      this._setAllStates();
    }
  }

  componentWillUnmount() {
    this.setState = (state, callback) => {
      return;
    };
  }

  _setAllStates = (callback) => {
    this.setState(
      (state, props) => ({
        ...props,
      }),
      callback
    );
  };

  render() {
    let style = {
      color: ColorX.GetColorCSS("Primary"),
      paddingRight: 5,
      fontSize: 9,
    };

    return (
      <Accessizo reqLevel={0} auth={store.user.auth} level={store.user.level}>
        <HStack
          height={15}
          sx={{
            position: "fixed",
            paddingLeft: 40,
            bottom: 0,
            background: "transparent",
            zIndex: 100,
          }}
        >
          {/* <Typography style={style}>{"[" + BrowserX.getBrowser() + "] " + BrowserX.getUserAgent()}</Typography>
          <Spacer />
          <Typography style={style}>{DOMAIN}</Typography> */}
        </HStack>
      </Accessizo>
    );
  }
}

export default Footer;
