import { TablizoCustom } from "Components/LabIZO/Tablizo";
import { Typography } from "@mui/material";

const Errors = [
  // {
  //   label: "#",
  //   name: "idx",
  //   width: 100
  // },
  {
    label: "Error",
    name: "error",
  },
  {
    label: "FAQ ID",
    name: "faq",
    width: 200,
  },
];

const Update: TablizoCustom = (tbLang) => [
  {
    label: "FAQ ID",
    name: "id",
    width: 100,
  },
  {
    label: "Btn Caption EN",
    name: "intent.EN.description",
  },
  {
    label: "TC",
    name: "intent.TC.description",
  },
  {
    label: "SC",
    name: "intent.SC.description",
  },
  {
    label: "Updated Type",
    name: "type",
    width: 140,
  },
  {
    label: "Disabled?",
    name: "disabled",
    Cell: (row, field, addOns) => {
      return <Typography variant="body2">{field ? "Yes" : "No"}</Typography>;
    },
  },
  {
    label: "(EN) +",
    name: "intent.EN.add",
    width: 100,
  },

  {
    label: "(TC) +",
    name: "intent.TC.add",
    width: 100,
  },

  {
    label: "(SC) +",
    name: "intent.SC.add",
    width: 100,
  },

  {
    label: "Answer Update (EN)",
    name: "answer.EN.new",
  },
  {
    label: "Answer Update (TC)",
    name: "answer.TC.new",
  },
  {
    label: "Answer Update (SC)",
    name: "answer.SC.new",
  },

  {
    label: "New Quick Reply ",
    name: "quickReply.new",
  },
  {
    label: "Updated Quick Reply ",
    name: "quickReply.updated",
  },
  {
    label: "Redirect Type",
    name: "gptRedirect.type",
  },
  {
    label: "Redirect Selection",
    name: "gptRedirect.selection",
  },
];

const New: TablizoCustom = (tbLang) => [
  // {
  //   label: "#",
  //   name: "idx",
  //   width: 100,
  // },
  {
    label: "FAQ ID",
    name: "id",
    width: 100,
  },
  {
    label: "Btn Caption EN",
    name: "intent.EN.description",
  },
  {
    label: "TC",
    name: "intent.TC.description",
  },
  {
    label: "SC",
    name: "intent.SC.description",
  },
  {
    label: "Type",
    name: "type",
    width: 100,
  },
  {
    label: "Disabled?",
    name: "disabled",
    Cell: (row, field, addOns) => {
      return <Typography variant="body2">{field ? "Yes" : "No"}</Typography>;
    },
  },
  {
    label: "Question Sample",
    name: "intent.EN.first",
  },
  {
    label: "EN",
    name: "intent.EN.length",
    width: 100,
  },
  {
    label: "TC",
    name: "intent.TC.length",
    width: 100,
  },
  {
    label: "SC",
    name: "intent.SC.length",
    width: 100,
  },
  {
    label: "Answer Sample",
    name: "answer.EN.first",
  },
  {
    label: "EN",
    name: "answer.EN.length",
    width: 100,
  },
  {
    label: "TC",
    name: "answer.TC.length",
    width: 100,
  },
  {
    label: "SC",
    name: "answer.SC.length",
    width: 100,
  },
  {
    label: "New Quick Reply ",
    name: "quickReply.new",
  },
  {
    label: "Redirect Type",
    name: "gptRedirect.type",
  },
  {
    label: "Redirect Selection ",
    name: "gptRedirect.selection",
  },
];

const Warnings = [
  {
    label: "Error",
    name: "error",
  },
  {
    label: "FAQ ID",
    name: "faq",
    width: 200,
  },
];

const schema = {
  Errors,
  Update,
  New,
  Warnings,
};

export default schema;
