import { Stack, Typography, styled } from "@mui/material";
import { ColorX } from "static";
import { DataEventLog } from "interfaces/db.interfaces/DataEventLog.interface";
import prettyStringify from "json-stringify-pretty-compact";

const StyledInsertTable = styled(Stack)({
  flexDirection: "column",
  padding: "10px",
  gap: "10px",
  alignItems: "center",
  justifyContent: "center",
});

interface InsertTableProps {
  dataEvent: DataEventLog;
}

export const InsertTable: React.FC<InsertTableProps> = ({ dataEvent }) => {
  // dataEvent.changes only has 1 element if the document is newly created / deleted
  // insert indentation for readability
  const val = prettyStringify(JSON.parse(dataEvent.changes[0].add!));

  return (
    <StyledInsertTable className="insert-table">
      <Typography variant="body2" fontWeight="700" textAlign="center" color={ColorX.GetColorCSS("Primary")}>
        Document
      </Typography>
      <div style={{ width: "100%", backgroundColor: ColorX.GetColorCSS("Insert") }}>
        <Typography variant="body2">
          <pre>{val}</pre>
        </Typography>
      </div>
    </StyledInsertTable>
  );
};
