import React, { Component } from "react";
import ZCTouch from "../ZCTouch";
import ZCScroll from "../ZCScroll";

import _ from "lodash";

import "./ZCQReplies.css";

class ZCQReplies extends Component {
  onMountScroll = (callbacks) => {
    this.MountScroll = callbacks;
  };

  scrollLeft = () => {
    this.MountScroll.scroll(-50);
  };

  scrollRight = () => {
    this.MountScroll.scroll(50);
  };

  renderButtons() {
    let { cssp, quickReplies, _onQuickReply, disabled } = this.props;
    let rendered = [];
    _.map(quickReplies, (o, i) => {
      rendered.push(
        <div key={i} className={cssp + " quickreplybar-btn " + (disabled ? "disabled" : "")}>
          <ZCTouch {...this.props} cssPrefix={cssp} onPress={() => _onQuickReply(o)} disabled={disabled} stopPropagation={false}>
            <div className={cssp + " quickreplybar-btn-text " + (disabled ? "disabled" : "")}>{o.title}</div>
          </ZCTouch>
        </div>
      );
    });

    return rendered;
  }

  render() {
    let { cssp } = this.props;

    return (
      <div className={cssp + " quickreplybar"}>
        <div key={"left"} className={cssp + " quickreplybar-arrows"}>
          <ZCTouch cssPrefix={cssp} onHold={() => this.scrollLeft()} forceLongPress={false}>
            <i className="fas fa-chevron-left fa-lg" />
          </ZCTouch>
        </div>
        <ZCScroll cssPrefix={cssp + " msg-qrlist"} onMounted={this.onMountScroll}>
          {this.renderButtons()}
        </ZCScroll>
        <div key={"right"} className={cssp + " quickreplybar-arrows"}>
          <ZCTouch cssPrefix={cssp} onHold={() => this.scrollRight()} forceLongPress={false}>
            <i className="fas fa-chevron-right fa-lg" />
          </ZCTouch>
        </div>
      </div>
    );
  }
}

export default ZCQReplies;
