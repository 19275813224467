import React, { Component } from "react";

import PropsType from "prop-types";
import _ from "lodash";
import { FormControl, FormControlLabel, FormHelperText, FormLabel, Radio, RadioGroup, Box } from "@mui/material";

import { Accessor, Authority, ColorX } from "static";
import { HStack, Spacer } from "Components/LabIZO/Stackizo";
import { OutlinedBox } from "Components/LabIZO/Stylizo";

interface IFFRadioProps {
  //data
  ischema: any;
  iname: string;
  addOns: any;

  //root func
  _onValueChange: (name: string, value: any, criteria: any) => void;
  _onBlurInlineSubmit: any;
  _onInlineSubmit: any;
  _onFieldFocus: () => void;
  _onFieldBlur: () => void;

  //disability
  errorsShowOnHelperText: boolean;
  readOnly: boolean;

  //runtime
  formValue?: any;
  formError: any;

  //style
  ifieldStyle: string;
  fieldSize?: string;
  theme?: any;
}

export const FFRadio: React.FC<IFFRadioProps> = (props) => {
  const { ischema, iname, formValue, formError, addOns, _onValueChange, _onBlurInlineSubmit, _onFieldFocus, _onFieldBlur, errorsShowOnHelperText, readOnly, ifieldStyle, fieldSize, theme } = props;

  const renderOption = () => {
    if (ischema.options) {
      const opts = _.map(ischema.options, (_opt, i) => {
        if (Authority.IsAccessibleQ(_opt.reqAuth, _opt.reqLevel, _opt.reqFunc)) {
          return <FormControlLabel key={i} value={_opt.value} disabled={_opt.disabled} control={<Radio />} label={_opt.label} />;
        }
      });

      return opts; // early return
    }

    //else, construct options from addOns
    let options;
    if (_.isArray(ischema.selectRef)) {
      options = ischema.selectRef;
    } else {
      options = Accessor.Get(addOns, ischema.selectRef);
    }
    return _.map(options, (o, i) => {
      let val;
      let cap;
      if (_.isEmpty(ischema.selectVal)) {
        val = o;
      } else {
        val = Accessor.Get(o, ischema.selectVal);
      }
      if (_.isEmpty(ischema.selectCap)) {
        cap = o;
      } else {
        cap = Accessor.Get(o, ischema.selectCap);
      }
      let disabled = (ischema.selectDisable && Accessor.Get(o, ischema.selectDisable)) || ischema.readOnly;
      return <FormControlLabel key={val} value={val} disabled={disabled} control={<Radio />} label={cap} />;
    });
  };
  const renderRadioGroup = () => {
    let ivalue = Accessor.Get(formValue, iname);
    if (ivalue === undefined || ivalue === null) ivalue = "";
    return (
      <Box className="radio-group-container" sx={{ display: "flex", justifyContent: "center", alignItems: "center", flexGrow: 5 }}>
        <RadioGroup key={iname} name={iname} value={ivalue} onChange={(e) => _onValueChange(iname, e.target.value, ischema.validate)}>
          {ischema.selectDirection === "row" ? <HStack flexWrap="wrap">{renderOption()}</HStack> : renderOption()}
        </RadioGroup>
      </Box>
    );
  };

  const renderInside = () => {
    let ierror = Accessor.Get(formError, iname);
    let ireadOnly = ischema.readOnly || readOnly;
    let helperText = ischema.helperText;
    if (errorsShowOnHelperText) {
      helperText = ierror;
    }
    return (
      <FormControl
        error={!_.isEmpty(ierror)}
        disabled={ireadOnly}
        fullWidth={ischema.fullWidth !== false}
        style={{
          minWidth: 200,
        }}
        onFocus={(e) => {
          _onFieldFocus();
        }}
        onBlur={(e) => {
          _onFieldBlur();
          _onBlurInlineSubmit(iname);
        }}
        defaultValue={ischema.defaultValue}
      >
        {ifieldStyle === "grid" ? (
          renderRadioGroup()
        ) : ifieldStyle === "outlined" ? (
          <OutlinedBox label={ischema.label}>{renderRadioGroup()}</OutlinedBox>
        ) : (
          <HStack width="100%" gap={"5px"}>
            <FormLabel className="formizo-h-m">{ischema.label}</FormLabel>
            {renderRadioGroup()}
            <Spacer />
          </HStack>
        )}
        {!_.isEmpty(helperText) && <FormHelperText>{helperText}</FormHelperText>}
      </FormControl>
    );
  };
  return renderInside();
};
