import { Box, Button, Container, Typography } from "@mui/material";
import Datumizo from "Components/LabIZO/Datumizo";
import { Authority, ColorX } from "static";
import { observer } from "mobx-react-lite";
import { useState } from "react";
import { HStack, VStack } from "Components/Layouts";
import { IDefaultProps } from "./defaultProps";
import IScatterBoard from "./DocChart";
import { CusAuth } from "interfaces";
import { styled } from "@mui/material";

export enum DM_VIEW {
  Table = "Table",
  "3D" = "3D",
}

interface ViewSelectorProps {
  view: DM_VIEW;
  setView: any;
  reqAuth?: string;
}

const view2AuthorityMap: Record<DM_VIEW, CusAuth | null> = {
  [DM_VIEW["3D"]]: CusAuth["3DView"],
  [DM_VIEW.Table]: null,
};

const ViewSelector: React.FC<ViewSelectorProps> = ({ view, setView, reqAuth }) => {
  const viewButtons: JSX.Element[] = [];

  Object.values(DM_VIEW).forEach((v) => {
    if (!reqAuth || v === DM_VIEW.Table || Authority.IsAccessibleQ(reqAuth, undefined, view2AuthorityMap[v]!)) {
      viewButtons.push(
        <Button key={v} variant={view === v ? "contained" : "outlined"} onClick={() => setView(v)}>
          {`${v} View`}
        </Button>
      );
    }
  });

  return viewButtons.length > 1 ? <>{viewButtons}</> : <></>;
};

const DocumentManager: React.FC<IDefaultProps> = (props) => {
  const { title, base } = props;
  const [MountDatumizo, setMountDatumizo] = useState<any>(false);
  const [data, setData] = useState<any>(false);
  const [view, setView] = useState<DM_VIEW>(DM_VIEW.Table);

  function onMountDatumizo(callback: any) {
    setMountDatumizo(callback);
  }

  async function onQuitInner() {
    await MountDatumizo?.Reload(true);
  }

  return (
    <VStack height="100%" justifyContent="flex-start">
      <Box padding={1} width="100%">
        <Typography
          style={{
            textAlign: "left",
            width: "100%",
            fontSize: 25,
            color: ColorX.GetColorCSS("Primary"),
          }}
        >
          {title}
        </Typography>
      </Box>

      <Datumizo
        //
        base={base}
        addOns={{ workspace: "master", data }}
        languageSelector={false}
        onMounted={onMountDatumizo}
        onQuitInner={onQuitInner}
        serverSidePagination={false}
        //onLoad={this._SetAllAutoSoft Reload}
        // noDefaultTable={view === "3D"}
        // injectRightButtons={<ViewSelector view={view} setView={setView} reqAuth={base?.reqAuth} />}
      >
        {/* {<IScatterBoard view={view} />} */}
      </Datumizo>
    </VStack>
  );
};

export default observer(DocumentManager);
