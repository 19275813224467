import { VStack } from "Components/Layouts";
import { IFLETab } from "../FLESetup/FLETabs";

export interface ITablContentProps {
  tabs: IFLETab[];
  tabIdx: number;
  id: string;
}

export function TabContents({ tabs, tabIdx, id }: ITablContentProps): JSX.Element {
  return <VStack width={"100%"}>{tabs[tabIdx].component(id)}</VStack>;
}
