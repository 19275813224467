import { Box, Chip, Typography, styled } from "@mui/material";
import { ColorX } from "static";
import { DataDiffChangeDelta, DataEventLog, DiffDelta } from "interfaces/db.interfaces/DataEventLog.interface";
import { Delta } from "../Delta/Delta";
import UnfoldMoreIcon from "@mui/icons-material/UnfoldMore";
import UnfoldLessIcon from "@mui/icons-material/UnfoldLess";
import { useState } from "react";

const StyledDiffTable = styled(Box)({
  display: "grid",
  gridTemplateColumns: "1fr 2fr",
  padding: "10px",
  columnGap: "5px",
  rowGap: "10px",
  alignItems: "center",
});

const StyledUpdateTextContainer = styled(Box)({
  display: "block",
  flexDirection: "row",
  justifyContent: "center",
  gap: "2px",
  lineHeight: "0.5rem",
});

interface DiffTableProps {
  dataEvent: DataEventLog;
}

export const DiffTable: React.FC<DiffTableProps> = ({ dataEvent }) => {
  const ChangeRow: React.FC<{ change: DataDiffChangeDelta }> = ({ change }) => {
    const { field, add, del, update } = change;
    // only one of add , del, update can exist at a time
    const keyColor = add ? ColorX.GetColorCSS("Insert") : del ? ColorX.GetColorCSS("Delete") : "inherit";
    return (
      <>
        <Typography variant="body2" textAlign="center" fontStyle="italic" key={`diff-${field}-field`} sx={{ backgroundColor: keyColor }}>
          {field}
        </Typography>
        {add && <Delta key={`diff-${field}-add`} type={DiffDelta.INSERT} val={add} breakWords={false} />}
        {del && <Delta key={`diff-${field}-del`} type={DiffDelta.DELETE} val={del} breakWords={false} />}
        {update && <UpdateCell key={`diff-${field}-update`} updates={update} />}
      </>
    );
  };

  const UpdateCell: React.FC<{ updates: [DiffDelta, string][] }> = ({ updates }) => {
    const contentCharLimit = 240;
    const ellipsis = "......";
    const [showDetails, setShowDetails] = useState<boolean>(false);

    const toggleDetails = () => setShowDetails(!showDetails);

    // if an update chunk is too long, omit except for the characters adjacent to chunks of type 1 or -1
    // cut at word boundary
    // note that a delta type 0 (no change) chunk is always surrounded by type 1 or -1 chunks (unless it is at the start or end of the content)
    let omitted: boolean = false;
    let processedUpdates = updates.map<[DiffDelta, string]>(([deltaType, val], idx) => {
      if (deltaType === DiffDelta.EQUAL && val.length > contentCharLimit) {
        if (idx === 0) {
          let idx = val.length - contentCharLimit / 2;
          while (val[idx] !== "\n" && val[idx] !== " " && idx !== 0) idx--;
          val = ellipsis + "\n" + val.slice(idx);
        } else if (idx === updates.length - 1) {
          let idx = contentCharLimit / 2;
          while (val[idx] !== "\n" && val[idx] !== " " && idx !== val.length - 1) idx++;
          val = val.slice(0, idx) + "\n" + ellipsis;
        } else {
          let idx1 = contentCharLimit / 2;
          while (val[idx1] !== "\n" && val[idx1] !== " " && idx1 !== val.length - 1) idx1++;
          const firstSlice = val.slice(0, idx1);
          let idx2 = val.length - contentCharLimit / 2;
          while (val[idx2] !== "\n" && val[idx2] !== " " && idx2 !== 0) idx2--;
          const secondSlice = val.slice(idx2);
          val = firstSlice + "\n" + ellipsis + "\n" + secondSlice;
        }
        omitted = true;
      }
      return [deltaType, val];
    });

    return (
      <StyledUpdateTextContainer className="update-text-container">
        {omitted && (
          <div style={{ display: "flex", justifyContent: "flex-end" }}>
            <Chip
              icon={showDetails ? <UnfoldLessIcon /> : <UnfoldMoreIcon />} //
              label={showDetails ? "Show Less" : "Show All"}
              onClick={toggleDetails}
              sx={{ height: "auto", "& .MuiChip-label": { overflow: "visible" } }}
            />
          </div>
        )}
        <div>
          {(showDetails ? updates : processedUpdates).map(([deltaType, val], idx) => {
            return <Delta key={`delta-${idx}-update`} type={deltaType} val={val} breakWords={true} />;
          })}
        </div>
      </StyledUpdateTextContainer>
    );
  };

  return (
    <StyledDiffTable className="diff-table">
      <Typography variant="body2" fontWeight="700" textAlign="center" color={ColorX.GetColorCSS("Primary")}>
        Property
      </Typography>
      <Typography variant="body2" fontWeight="700" textAlign="center" color={ColorX.GetColorCSS("Primary")}>
        Changes
      </Typography>
      {dataEvent.changes.flatMap((change, idx) => (
        <ChangeRow key={idx} change={change} />
      ))}
    </StyledDiffTable>
  );
};
