import { Chip, FormLabel, Paper, TableCell, Typography, styled } from "@mui/material";

export const StyledSettingInterface = styled(Paper)({
  width: "100%",
  height: "100%",
  p: 2,
  display: "flex",
});

export const StyleFormLabel = styled(FormLabel)({
  textAlign: "center",
  font: "normal normal normal 20px/27px Segoe UI",
  padding: "10px",
  color: "primary.main",
});

export const StyledWarningChip = styled(Chip)({
  mx: "10px",
  height: "auto",
  "& .MuiChip-label": {
    whiteSpace: "normal",
    padding: "5px 12px",
  },
});

export const StyledPaper = styled(Paper)({
  p: 2,
  height: "100%",
  width: "100%",
  border: "1px solid #ccc",
  borderRadius: "4px",
  boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
  display: "flex",
  flexDirection: "column",
  overflow: "auto",
  padding: "2px",
});

export const StyledTableCell = styled(TableCell)({
  padding: "4px",
  maxWidth: "200px",
  overflow: "hidden",
  textOverflow: "ellipsis",
  whiteSpace: "nowrap",
  "&:hover": {
    overflow: "visible",
    whiteSpace: "normal",
    position: "relative",
    zIndex: 1,
    maxWidth: "200px",
    wordBreak: "break-all",
    maxHeight: "5px",
  },
});

export const StyledTypography = styled(Typography)({
  font: "normal normal normal 16px/21px Segoe UI",
  color: "#C9C9C9",
  paddingTop: "10px",
});
