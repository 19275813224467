import { FormizoSchema } from "Components/LabIZO/Formizo";
import { AvailLANG } from "interfaces";

export const schema: FormizoSchema[] = [
  {
    label: "Content Filter Setting",
    name: "contentFilter",
    format: "array",
    canAdd: true,
    canDelete: true,
    array: [
      {
        label: "Keyword to Filter",
        name: "content",
        format: "text",
        validate: ["required"],
      },
      {
        label: "Filter in Language",
        name: "lang",
        placeholder: "Language",
        format: "select", // TODO: multi-select
        selectStyle: "dropdown",
        // optionType: "static",
        options: [
          {
            value: AvailLANG.EN,
            label: "EN",
          },
          {
            value: AvailLANG.TC,
            label: "TC",
          },
          {
            value: AvailLANG.SC,
            label: "SC",
          },
          {
            value: "ALL",
            label: "ALL",
          },
        ],
        validate: ["required"],
        defaultValue: "ALL",
      },
    ],
  },
];
