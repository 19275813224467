import { FormizoSchema } from "Components/LabIZO/Formizo/FormizoSchema.interface";

const WhatsappSettings: FormizoSchema = {
  label: "Whatsapp Settings",
  foldStyle: "outlined",
  defaultShow: true,
  collapse: [
    {
      label: "Whatsapp Type",
      name: "whatsapp.type",
      format: "select",
      selectStyle: "dropdown",
      selectRef: "WhatsappTypes",
      selectCap: "caption",
      selectVal: "_id",
      placeholder: "Whatsapp Type",
      options: [
        { value: "emma", label: "Emma" },
        { value: "meta", label: "Meta" },
      ],
      defaultValue: "emma",
    },
    {
      control: "whatsapp.type",
      controlFunc: (doc, field) => doc?.whatsapp?.type === "emma",
      fold: [
        {
          label: "API Token",
          name: "whatsapp.emma.api_token",
          format: "text",
        },
        {
          label: "Path",
          name: "whatsapp.emma.path",
          format: "text",
        },
        {
          label: "Text",
          name: "whatsapp.emma.text",
          format: "text",
        },
      ],
    },
    {
      control: "whatsapp.type",
      controlFunc: (doc, field) => doc?.whatsapp?.type === "meta",
      fold: [
        {
          label: "API Token",
          name: "whatsapp.meta.api_token",
          format: "text",
        },
        {
          label: "Path",
          name: "whatsapp.meta.path",
          format: "text",
        },
        {
          label: "Validation Token",
          name: "whatsapp.meta.validationToken",
          format: "text",
        },
      ],
    },
  ],
};

const FacebookSettings: FormizoSchema = {
  label: "Facebook Settings",
  foldStyle: "outlined",
  defaultShow: true,
  collapse: [
    {
      label: "Validation Token",
      name: "facebook.validationToken",
      format: "text",
    },
    {
      label: "Page Access Token",
      name: "facebook.pageAccessToken",
      format: "text",
    },
  ],
};

const schema: FormizoSchema[] = [
  {
    reqAuth: "System.Channel",
    reqFunc: "Whatsapp",
    accessizo: [WhatsappSettings],
  },
  // {
  //   reqAuth: "System.Channel",
  //   reqFunc: "Facebook",
  //   accessizo: [FacebookSettings],
  // },
];

export default schema;
