import { Box, Typography } from "@mui/material";
import { Component } from "react";
import moment from "moment";
import type { Moment } from "moment";
import schema from "./schema";
import { HStack, VStack } from "Components";
import { Accessor, Authority, ColorX, store } from "static";
import Datumizo from "Components/LabIZO/Datumizo";
import { DateTimeRangePicker } from "Components/DatePicker/DateTimeRangePicker";
import { DATA_LINK } from "connectors";

type RatingReportProps = typeof RatingReport.defaultProps;

interface RatingReportStates {
  title: string;
  period: { DateRange: [Moment, Moment] };
  serverSidePagination: boolean;
  base: any;
}

class RatingReport extends Component<RatingReportProps, RatingReportStates> {
  static defaultProps = {};
  MountDatumizo: Record<string, (...args: any[]) => any> = {};

  constructor(props: RatingReportProps) {
    super(props);
    this.state = {
      title: "Rating Report",
      period: { DateRange: [moment().startOf("month"), moment().endOf("month")] },
      serverSidePagination: true,
      base: {
        title: "Rating Report",
        exportDoc: "RatingReport",
        schema,
        reqAuth: "RatingReport",

        tablizo: {
          showSelector: true,
          columnsToolbar: true,
          filterToolbar: true,
          densityToolbar: true,
          exportToolbar: false,
        },

        Connect: {
          List: DATA_LINK.RatingReportList,
          SetColumnVisibilitySetting: DATA_LINK.RatingReportSetColumnVisibilitySetting,
          schema: schema.Table,
        },

        operations: {
          Export: {
            url: DATA_LINK.RatingReportExport,
            schema: schema.Export,
          },
        },

        buttons: {
          right: [{ icon: "export", func: "Export", caption: (n: number) => `Export (${n === 0 ? "All" : n})`, reqFunc: "Export" }],
        },
      },
    };
  }

  componentDidMount() {
    Authority.Require("RatingReport");
    this._setAllStates();
  }

  componentDidUpdate(prevProps: RatingReportProps, prevState: RatingReportStates) {
    if (!Accessor.IsIdentical(prevProps, this.props, Object.keys(RatingReport.defaultProps))) {
      this._setAllStates();
    }
  }

  componentWillUnmount() {
    this.setState = (state, callback) => {
      return;
    };
  }

  _setAllStates = (callback?: () => void) => {
    this.setState(
      (state, props) => ({
        ...props,
      }),
      callback
    );
  };

  onMountDatumizo = (callbacks: Record<string, (...args: any[]) => any>) => {
    this.MountDatumizo = callbacks;
  };

  datePickerCallback = (dates: moment.Moment[]) => {
    if (dates.length == 2) {
      this.setState(() => ({
        period: {
          DateRange: [dates[0], dates[1]],
        },
      }));
    }
  };

  renderDatePicker = () => {
    const { period } = this.state;
    const handleDateChange = (newDates: [Moment | null, Moment | null]) => {
      if (newDates && newDates[0] && newDates[1]) {
        this.setState((state: any) => {
          return {
            period: {
              ...state.period,
              DateRange: newDates,
            },
            base: {
              ...state.base,
              exportDoc: `RatingReport_${newDates[0]!.format("YYYYMMDD")}_${newDates[1]!.format("YYYYMMDD")}`,
            },
          };
        });
        this.MountDatumizo.Reload();
        store.setFilterSettingDateRange(newDates, "RatingReport");
      }
    };

    return (
      <DateTimeRangePicker
        dates={period.DateRange}
        onChangeDates={handleDateChange}
        limitSelectedPeriod={{ unit: "years", value: 1 }}
        helperText="Select date range to filter the data. You may select up to 1 year's data."
      />
    );
  };

  renderTable = () => {
    let { base, serverSidePagination, period } = this.state;
    return (
      <Datumizo
        base={base}
        addOns={{
          ...period,
        }}
        serverSidePagination={serverSidePagination}
        onMounted={this.onMountDatumizo}
        languageSelector={false}
        datePickerCallback={this.datePickerCallback}
      />
    );
  };

  render = () => {
    const { title } = this.state;
    return (
      <VStack height="100%" width="100%">
        <Box padding={1} width="100%">
          <HStack
            justifyContent="flex-start"
            gap={8}
            style={{
              display: "flex",
              width: "100%",
            }}
          >
            <Typography
              style={{
                alignItems: "left",
                display: "flex",
                fontSize: 25,
                color: ColorX.GetColorCSS("Primary"),
              }}
            >
              {title}
            </Typography>
            {this.renderDatePicker()}
          </HStack>
        </Box>

        {this.renderTable()}
      </VStack>
    );
  };
}

export default RatingReport;
