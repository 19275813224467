import { AvailLANG } from "interfaces";

export enum QuestionSource {
  Cluster = "Convlog",
  Generated = "Generated",
  Custom = "User Defined",
}

export interface QuestionDetails {
  question: string;
  selected: boolean;
  source: QuestionSource;
}

export interface IExampleDetails {
  _id: string;
  examples: ILang;
  answer?: ILang;
}
interface ILang {
  EN?: string[];
  SC?: string[];
  TC?: string[];
}
