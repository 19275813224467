import { useState } from "react";

import schema from "./schema";
import { Box, Typography } from "@mui/material";

import Datumizo from "Components/LabIZO/Datumizo/Datumizo";
import { VStack } from "Components/LabIZO/Stackizo";
import { ColorX } from "static";
import { DATA_LINK } from "connectors";
import { DatumizoBase } from "Components/LabIZO/Datumizo/DatumizoProps.interface";
import { observer } from "mobx-react";

interface IIntentProps {
  title?: string;
  serverSidePagination?: boolean;
  base?: DatumizoBase;
}

const defaultProps: IIntentProps = {
  title: "INTENTS",
  serverSidePagination: false,
  base: {
    title: "INTENTS",
    exportDoc: "Intents",
    schema: schema,
    reqAuth: "Workspace.Intent",
    columnAuth: "Intent",

    tablizo: {
      showSelector: true,
      columnsToolbar: true,
      filterToolbar: true,
      densityToolbar: true,
      exportToolbar: false,
    },

    Connect: {
      DBInfo: DATA_LINK.IntentDBInfo,
      List: DATA_LINK.IntentList,
      SetColumnVisibilitySetting: DATA_LINK.IntentSetColumnVisibilitySetting,
      schema: schema.Table,
    },

    operations: {
      Add: {
        title: "Add Intent",
        url: DATA_LINK.IntentAdd,
        success: "Intent Added Successfully",
        fail: "Intent Add Failed: ",
        schema: schema.Add,
        buttons: ["Clear", "Submit"],
        onSubmit: "Add",
      },
      Delete: {
        title: "Delete this Intent?",
        content: "Caution: This is irrevertable.",
        url: DATA_LINK.IntentDelete,
        success: "Intent Deleted Successfully.",
        fail: "Intent Delete Failed: ",
        onSubmit: "Delete",
      },
      Edit: {
        title: "Edit Intent ",
        url: DATA_LINK.IntentEdit,
        success: "Intent Edited Successfully",
        fail: "Intent Edit Failed: ",
        schema: schema.Edit,
        buttons: ["Revert", "Submit"],
        onSubmit: "Edit",
      },
      Info: {
        title: "Intent ",
        success: "Intent Load Successfully",
        fail: "Intent Load Failed: ",
        schema: schema.Info,
        readOnly: true,
      },
      Sync: {
        title: "Sync from NLP",
        content: "Caution: This is irrevertable.",
        url: DATA_LINK.IntentSync,
        success: "Intent Sync Successfully.",
        fail: "Intent Sync Failed: ",
        onSubmit: "Sync",
      },
      DeleteBulk: {
        title: (n: string) => "Delete these " + n + " Intents?",
        content: "Caution: This is irrevertable.",
        url: DATA_LINK.IntentDeleteBulk,
        success: "Intents Deleted Successfully.",
        fail: "Intents Delete Failed: ",
        onSubmit: "DeleteBulk",
      },
      Export: {
        url: DATA_LINK.IntentExport,
        exportFileType: "xlsx",
        schema: schema.Export,
      },
    },

    buttons: {
      inline: [
        { icon: "edit", func: "Edit", caption: "Edit", reqFunc: "Edit" },
        { icon: "info", func: "Info", caption: "Details" },
        { icon: "delete", func: "Delete", caption: "Delete", reqFunc: "Delete" },
      ],
      left: [{ icon: "add", func: "Add", caption: "Add Intent", reqFunc: "Add" }],
      right: [
        { icon: "deletebulk", func: "DeleteBulk", caption: (n) => "Delete (" + n + ")", reqFunc: "Delete", theme: "caution" },
        { icon: "export", func: "Export", caption: (n) => "Export (" + (n === 0 ? "All" : n) + ")", reqFunc: "Export" },
        { icon: "import", func: "Import", caption: "Import", reqFunc: "Import" },
        { icon: "syncfrom", func: "Sync", caption: "Sync", reqFunc: "Sync" },
      ],
    },
  },
};

const Intent: React.FC<IIntentProps> = (props) => {
  const { title, serverSidePagination, base } = props;
  const [MountDatumizo, setMountDatumizo] = useState<any>(false);

  function onMountDatumizo(callback: any) {
    setMountDatumizo(callback);
  }

  async function onQuitInner() {
    await MountDatumizo?.Reload();
  }
  return (
    <VStack>
      <Box padding={1} width="100%">
        <Typography
          style={{
            textAlign: "left",
            width: "100%",
            fontSize: 25,
            color: ColorX.GetColorCSS("Primary"),
          }}
        >
          {title}
        </Typography>
      </Box>
      <Datumizo
        base={base}
        serverSidePagination={serverSidePagination}
        onMounted={onMountDatumizo}
        onQuitInner={onQuitInner}
        addOns={{
          workspace: "master",
          allIntents: true,
        }}
      />
    </VStack>
  );
};

Intent.defaultProps = defaultProps;

export default observer(Intent);
