import DeleteIcon from "@mui/icons-material/Delete";
import { Box, IconButton, Typography } from "@mui/material";
import React from "react";

export function MultiFilterHeaders() {
  return (
    <Box display="flex" gap={2} pr={0.5} pl={0.5} alignItems="center" textAlign="center">
      {/** For alignment only */}
      <IconButton
        sx={{
          visibility: "hidden",
        }}
      >
        <DeleteIcon />
      </IconButton>
      {/* -------Header ------- */}
      <Typography width="150px" variant="button">
        Column
      </Typography>
      <Typography width="200px" variant="button">
        Operator
      </Typography>
      <Typography width="250px" variant="button">
        Value
      </Typography>
      {/* -------Header END------- */}
    </Box>
  );
}
