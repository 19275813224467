import { SetStateAction, useState } from "react";
import { Box, TextField, Typography } from "@mui/material";
import { StyledButton, StyledLinearProgress } from "Components/LabIZO/Stylizo";
import { HStack, VStack } from "Components";
import { ColorX } from "static";
import { Spacer } from "Components/LabIZO/Stackizo";

interface HeaderComponentProps {
  mfaChar?: string;
  onSubmit: (mfaCode: string) => void;
  loading: boolean;
}

//Only for FAQ
const MFAVerify = ({ mfaChar, onSubmit, loading }: HeaderComponentProps) => {
  const [mfaPass, setMfaPass] = useState<string>("");

  const verifyCode = () => {
    onSubmit(`${mfaChar}-${mfaPass}`);
  };

  const renderVerifyButton = () => {
    return (
      <VStack width="100%" key="next">
        <StyledButton
          id="login-button"
          onClick={() => {
            verifyCode();
          }}
          theme={{
            label: "white",
            background: loading ? ColorX.GetColorCSS("Primary2") : ColorX.GetColorCSS("Primary"),
            hover: {
              background: ColorX.GetColorCSS("Primary2"),
            },
            borderRadius: "0px",
            width: "100%",
          }}
          disabled={loading}
        >
          <HStack>
            <div>Verify</div>
            <Spacer />
            <i className="fas fa-arrow-right" />
          </HStack>
        </StyledButton>
        {loading && <StyledLinearProgress theme={{ bar: ColorX.GetColorCSS("Primary"), background: ColorX.GetColorCSS("Primary2") }} />}
      </VStack>
    );
  };

  return (
    <VStack>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
        }}
      >
        <Typography
          sx={{
            marginRight: "8px",
          }}
        >
          {`${mfaChar} - `}
        </Typography>
        <TextField
          value={mfaPass}
          onChange={(e: { target: { value: SetStateAction<string> } }) => setMfaPass(e.target.value)}
          variant="outlined"
          placeholder="Enter last 6 digits"
          inputProps={{
            maxLength: 6,
          }}
        />
      </Box>
      {renderVerifyButton()}
    </VStack>
  );
};

export default MFAVerify;
