export interface IDocumentManager {
  _id: string;
  alias: string;
  enabled: boolean;
  status: DocumentStatus;
  text_file?: {
    available: boolean;
    name: string;
    location: string;
  };
  original_file?: {
    available: boolean;
    name: string;
    location: string;
  };
  reference: {
    link: string;
    title: string;
    target: "blank" | "self";
  };
  knowledge_grps?: IDMKnowledeGroup[];
  documentLink?: IDocumentLink;
  context?: {
    text?: string;
  };
  summary?: string;
  CREATED_AT: string;
  LASTMODIFIED: string;
}

export enum DocumentStatus {
  PREPARING = "preparing",
  READY = "ready",
  ERROR = "error",
}

export interface IDMKnowledeGroup {
  _id: string;
  alias: string;
  enabled: boolean;
}

export enum RelationType { //Please update qa-service if u change the wordings
  Translate = "translate",
  ReadByPage = "read_by_page",
  Audio = "audio",
}

interface IDocumentLinkBase {
  type: RelationType;
  parentId: string;
  parentAlias?: string;
  childAlias?: string[];
}

interface ITranslateDocumentLink extends IDocumentLinkBase {
  type: RelationType.Translate;
  lang?: string;
}

interface IReadByPageDocumentLink extends IDocumentLinkBase {
  type: RelationType.ReadByPage;
  page?: string;
}

interface IAudioDocumentLink extends IDocumentLinkBase {
  type: RelationType.Audio;
  lang?: string;
  prompt?: string;
}

type IDocumentLink = ITranslateDocumentLink | IReadByPageDocumentLink | IAudioDocumentLink;
