import { Box, Button, CircularProgress, Stack, Tooltip, Typography, styled } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import TaskAltIcon from "@mui/icons-material/TaskAlt";
import { AvailLANG, DBAnswerSpec } from "interfaces";
import React, { useContext, useEffect, useRef, useState } from "react";
import AutorenewIcon from "@mui/icons-material/Autorenew";
import { v1 } from "uuid";
import _ from "lodash";
import { ColorX, store } from "static";
import { FLEFormContext } from "Pages/FLE/hooks/FLEContexts";
import { UseFormSetValue, useFormContext } from "react-hook-form";
import { ExtendedIFLEDoc } from "Pages/FLE/FLESetup/FLESetup";
import { Intent } from "interfaces/db.interfaces/intent.interface";
import { DATA_LINK, backendConnector } from "connectors";

const StyledByLangContainer = styled(Stack)({
  flexDirection: "column",
  gap: "5px",
  padding: "5px 0",
  borderBottom: "1px solid #a1a1a1",
});

const StyledHeaderContainer = styled(Stack)({
  flexDirection: "row",
  alignItems: "flex-start",
  minHeight: "2.3rem",
});

const StyledQuestionContainer = styled(Stack)({
  flexDirection: "column",
  alignItems: "center",
});

const StyledQuestionRow = styled(Stack)({
  flexDirection: "row",
  alignItems: "center",
  width: "100%",
});

interface ExampleGenerationByLangProps {
  lang: AvailLANG;
  setShowError: React.Dispatch<React.SetStateAction<boolean>>;
}

interface QuestionDetails {
  key: string;
  question: string;
  selected: boolean;
}

export const ExampleGenerationByLang: React.FC<ExampleGenerationByLangProps> = ({ lang, setShowError }) => {
  const numGeneration = 5;
  const { getValues, setValue } = useFormContext<ExtendedIFLEDoc<DBAnswerSpec, Intent>>();
  const defaultLang = (store.sysInfo.Language.default ?? "EN") as AvailLANG;

  const form = getValues();
  // fallback questions from default languages
  const questions = form.intentDoc[lang]?.examples.map((e) => e.text) ?? form.intentDoc[defaultLang].examples.map((e) => e.text);
  const generatedQuestions = form.generatedQuestions?.[lang] ?? [];
  const description = form.description ?? "";

  const [questionDetails, setQuestionDetails] = useState<QuestionDetails[]>(generatedQuestions.map((g) => ({ key: v1(), question: g, selected: true })));
  const [loading, setLoading] = useState(false);

  const buttonRef = useRef<HTMLButtonElement>(null);
  useEffect(() => buttonRef.current?.click(), []);

  const ctx = useContext(FLEFormContext);
  useEffect(() => {
    ctx!.register(`generatedQuestions.${lang}`);
  }, []);

  const handleGenerate = async (_e: React.MouseEvent<HTMLButtonElement>) => {
    setLoading(true);
    try {
      const questions = await generate();
      if (questions === null) {
        setShowError(true);
      } else {
        setQuestionDetails((state) => {
          const newQs = state.filter(({ selected }) => selected);
          for (const q of questions) {
            newQs.push({ key: v1(), question: q, selected: false });
          }
          return newQs;
        });
      }
      setLoading(false);
    } catch (err) {
      console.error(err);
    }
  };

  const handleStatusChange = (question: QuestionDetails, add: boolean) => {
    setQuestionDetails((state) => {
      const newQs = state.find((q) => q.key === question.key);
      if (!newQs) throw new Error("Question not found");
      newQs.selected = !newQs.selected;
      return state;
    });
    const updatedGeneratedQuestions = add ? _.uniq([...generatedQuestions, question.question]) : generatedQuestions.filter((q) => q !== question.question);
    setValue<any>(`generatedQuestions.${lang}`, updatedGeneratedQuestions);
  };

  const generate = async (): Promise<string[] | null> => {
    const { Success, payload, message } = await backendConnector.post<{ generatedQuestions: string[] }>(DATA_LINK.IntentGenerate, {
      questions: questions,
      lang,
      num_examples: numGeneration,
      prev_generations: questionDetails.map((q) => q.question),
      description,
    });
    if (!Success) {
      console.error(message);
      return null;
    }
    return payload.generatedQuestions;
  };

  return (
    <StyledByLangContainer>
      <StyledHeaderContainer>
        <Typography sx={{ color: ColorX.GetColorCSS("Primary"), flex: "0 0 30%" }}>{`Provided Questions (${lang}):`}</Typography>
        <ProvidedQuestions questions={questions} />
        {questions?.length ? (
          loading ? (
            <Box sx={{ flex: "0 0 10%", display: "flex", alignItems: "center", justifyContent: "center" }}>
              <CircularProgress style={{ width: "20px", height: "20px" }} />
            </Box>
          ) : (
            <Tooltip title="Generate Questions">
              <Button sx={{ flex: "0 0 10%" }} ref={buttonRef} className="gen-button" onClick={handleGenerate}>
                <AutorenewIcon />
              </Button>
            </Tooltip>
          )
        ) : (
          <Box sx={{ flex: "0 0 10%" }}></Box>
        )}
      </StyledHeaderContainer>
      <StyledQuestionContainer>
        {questionDetails.map((q) => {
          return (
            <StyledQuestionRow key={q.key} className="question-row">
              <Typography sx={{ flex: 4 }}>{q.question}</Typography>
              <Box sx={{ flex: 1 }} className="question-button-container">
                {q.selected ? (
                  <Button sx={{ color: "green" }} className="question-button" onClick={() => handleStatusChange(q, false)}>
                    <TaskAltIcon />
                  </Button>
                ) : (
                  <Button className="question-button" onClick={() => handleStatusChange(q, true)}>
                    <AddIcon />
                  </Button>
                )}
              </Box>
            </StyledQuestionRow>
          );
        })}
      </StyledQuestionContainer>
    </StyledByLangContainer>
  );
};

const ProvidedQuestions: React.FC<{ questions?: string[] }> = ({ questions }) => {
  if (!questions?.length)
    return (
      <Typography
        sx={{
          color: ColorX.GetColorCSS("grey"),
          flex: "0 0 60%",
          fontWeight: 600,
          fontStyle: "italic",
        }}
      >
        No question provided
      </Typography>
    );

  return (
    <Box sx={{ display: "flex", flexDirection: "column", color: ColorX.GetColorCSS("Primary"), flex: "0 0 60%" }}>
      {questions.map((q, idx) => (
        <Typography key={idx} sx={{ fontWeight: 600 }}>
          {q}
        </Typography>
      ))}
    </Box>
  );
};
