import { FormizoSchema } from "Components/LabIZO/Formizo";

export const schema: FormizoSchema[] = [
  {
    header: "Human SPAM Setting",
  },
  {
    label: "Block Time (sec)",
    name: "SettingDoc.HumanSpam.BlockDuration",
    variant: "outlined",
    format: "number",
  },
  {
    label: "Attempt Threshold",
    name: "SettingDoc.HitRate.HumanMaxStamp",
    variant: "outlined",
    format: "number",
  },
  {
    header: "BOT SPAM Setting",
  },
  {
    label: "Block Time (sec)",
    name: "SettingDoc.BotSpam.BlockDuration",
    variant: "outlined",
    format: "number",
  },
  {
    label: "Time interval (sec)",
    name: "SettingDoc.BotSpam.TimeInterval",
    variant: "outlined",
    format: "number",
  },
  {
    label: "Attempt Threshold",
    name: "SettingDoc.HitRate.BotMaxStamp",
    variant: "outlined",
    format: "number",
  },
  {
    header: "IP SPAM Setting",
  },
  {
    label: "Block Time (sec)",
    name: "SettingDoc.IpSpam.BlockDuration",
    variant: "outlined",
    format: "number",
  },
  {
    label: "Time interval (sec)",
    name: "SettingDoc.IpSpam.TimeInterval",
    variant: "outlined",
    format: "number",
  },
  {
    label: "Attempt Threshold",
    name: "SettingDoc.HitRate.IpMaxStamp",
    variant: "outlined",
    format: "number",
  },
];
