import { Typography } from "@mui/material";
import { TablizoCustom } from "Components/LabIZO/Tablizo";
import { SwitchButton } from "Components/SwitchButton/SwitchButton";
import { DATA_LINK } from "connectors";
import Tooltip from "@mui/material/Tooltip";
import { IWebsiteStatus } from "Components/_PageComponents/interfaces/website-manager-api.interface";

export const WebsiteStatusColor: Record<string, string> = {
  [IWebsiteStatus.INDEXED]: "green",
  [IWebsiteStatus.INDEXING]: "orange",
  [IWebsiteStatus.EDITING]: "orange",
  [IWebsiteStatus.DELETING]: "orange",
  [IWebsiteStatus.SYNCING]: "purple",
  [IWebsiteStatus.ENABLING]: "blue",
  [IWebsiteStatus.DISABLING]: "blue",
  [IWebsiteStatus.ERROR]: "red",
  [IWebsiteStatus.DISABLED]: "black",
};

export const TABLE_SCHEMA: TablizoCustom = (tbLang) => [
  {
    label: "Enabled",
    name: "enabled",
    width: 100,
    reqAuth: "GPTQA.WebsiteManager",
    reqLevel: 999,
    reqFunc: "Enable",
    Cell(row, field, addOns, fetchData) {
      return (
        <SwitchButton defaultValue={field} URL={DATA_LINK.WebsiteEnable} row={row} fetchData={fetchData} disabled={row.status != IWebsiteStatus.INDEXED && row.status != IWebsiteStatus.DISABLED} />
      );
    },
  },
  {
    label: "Website Name",
    name: "alias",
    filterable: true,
  },
  {
    label: "URL",
    name: "urlSetting.url",
    filterable: true,
  },
  // {
  //   label: "URL Type",
  //   name: "urlSetting.type",
  //   filterable: true,
  // },
  {
    label: "Status",
    name: "status",
    Cell(row, field, addOns) {
      const statusColor = WebsiteStatusColor[row.status];

      if (row.status === "error") {
        return (
          <Tooltip title={row.error_message} arrow>
            <Typography color={statusColor}>{row.status}</Typography>
          </Tooltip>
        );
      } else {
        return <Typography color={statusColor}>{row.status}</Typography>;
      }
    },
    width: 100,
    filterable: true,
  },
  {
    label: "Last Index Time",
    name: "last_index_time",
    valueGetter(row) {
      let displayedIndexTime = row?.last_index_time > row?.LASTMODIFIED ? row?.last_index_time : row?.LASTMODIFIED;
      return displayedIndexTime;
    },
    filterable: true,
  },
  {
    label: "Created At",
    name: "CREATED_AT",
    filterable: true,
  },
  {
    label: "Last Modified Time",
    name: "LASTMODIFIED",
    filterable: true,
  },
];
