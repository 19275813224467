import React from "react";

import _ from "lodash";
import { Checkbox, FormControlLabel, Switch } from "@mui/material";
import { Favorite, FavoriteBorder } from "@mui/icons-material";

import { Accessor } from "static";
import { ColorX } from "static";
import { IFBoolean } from "../../FormizoSchema.interface";

interface IFFBoolProps {
  //data
  ischema: IFBoolean;
  iname: string;
  addOns: any;

  //root func
  _onValueChange: (name: string, value: boolean, criteria: any) => void;
  _onBlurInlineSubmit: any;
  _onInlineSubmit: any;
  _onFieldFocus: () => void;
  _onFieldBlur: () => void;

  //disability
  errorsShowOnHelperText: boolean;
  readOnly: boolean;

  //runtime
  formValue?: any;
  formError: any;

  //style
  ifieldStyle: string;
}

export const FFBool: React.FC<IFFBoolProps> = (props) => {
  const { formValue, iname, ischema, readOnly, _onValueChange, ifieldStyle } = props;
  const ivalue = Accessor.Get(formValue, iname) || false;
  const ireadOnly = ischema.readOnly || readOnly;
  const renderCheckBox = () => {
    if (ifieldStyle === "grid") {
      return <Checkbox sx={{ color: ColorX.GetColorCSS("Primary") }} name={iname} checked={ivalue} onChange={(e) => _onValueChange(iname, e.target.checked, ischema.validate)} disabled={ireadOnly} />;
    } else {
      return (
        <FormControlLabel
          className="formizo-h-m"
          control={
            <Checkbox sx={{ color: ColorX.GetColorCSS("Primary") }} name={iname} checked={ivalue} onChange={(e) => _onValueChange(iname, e.target.checked, ischema.validate)} disabled={ireadOnly} />
          }
          label={ischema.label}
        />
      );
    }
  };
  const renderSwitch = () => {
    if (ifieldStyle === "grid") {
      return (
        <Switch
          color={"primary"}
          name={iname}
          checked={ivalue}
          onChange={(e) => _onValueChange(iname, e.target.checked, ischema.validate)}
          inputProps={{ "aria-label": "primary checkbox" }}
          disabled={ireadOnly}
        />
      );
    } else {
      return (
        <FormControlLabel
          className="formizo-h-m"
          control={
            <Switch
              color={"primary"}
              name={iname}
              checked={ivalue}
              onChange={(e) => _onValueChange(iname, e.target.checked, ischema.validate)}
              inputProps={{ "aria-label": "primary checkbox" }}
              disabled={ireadOnly}
            />
          }
          label={ischema.label}
        />
      );
    }
  };
  const renderHeart = () => {
    if (ifieldStyle === "grid") {
      return (
        <Checkbox name={iname} checked={ivalue} onChange={(e) => _onValueChange(iname, e.target.checked, ischema.validate)} disabled={ireadOnly} icon={<FavoriteBorder />} checkedIcon={<Favorite />} />
      );
    } else {
      return (
        <FormControlLabel
          className="formizo-h-m"
          control={
            <Checkbox
              name={iname}
              checked={ivalue}
              onChange={(e) => _onValueChange(iname, e.target.checked, ischema.validate)}
              disabled={ireadOnly}
              icon={<FavoriteBorder />}
              checkedIcon={<Favorite />}
            />
          }
          label={ischema.label}
        />
      );
    }
  };
  const renderInside = () => {
    let iboolStyle = ischema.boolStyle || "checkbox";
    switch (iboolStyle) {
      case "checkbox":
        return renderCheckBox();
      case "switch":
        return renderSwitch();
      case "heart":
        return renderHeart();
      default:
        return renderCheckBox();
    }
  };

  if (!ischema) {
    return null;
  }
  return renderInside();
};
