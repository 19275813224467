import { Box } from "@mui/material";
import { HStack, VStack } from "Components";
import PerceptualMapView from "./components/PerceptualMap/PerceptualMapView";
import { DATA_LINK, backendConnector } from "connectors";
import { useEffect, useState } from "react";
import TopicClusterDetail from "./components/TopicClusterDetail/TopicClusterDetail";
import { DataItem, TopicItem } from "./Interface/TopicCluster.interface";
import { ChartSetting } from "./Interface/setting.interface";
import { StyledLabelTypography } from "./components/StyledComponent";
import { ChartSettingContext } from "./Hooks/setting-hook";
import { Authority, store } from "static";

const TopicClustering: React.FC<{}> = () => {
  const [open, setOpen] = useState(false);
  const [data, setData] = useState<TopicItem[]>([]);
  const [selectedTopic, setSelectedTopic] = useState<TopicItem | null>(null);
  const [setting, setSetting] = useState<ChartSetting>({
    display: {
      showItem: "All",
      displayCluster: 10,
      labelFormat: "ratio",
      showCircle: true,
    },
    data: {
      onlyLowConfidence: true,
      convlog: 200,
      cluster: undefined,
      after_cleansing: 200,
    },
  });

  const allowedAction: ("Add" | "Edit")[] = [];
  if (Authority.IsAccessibleQ("Analytics.TopicClustering", undefined, "Add")) {
    allowedAction.push("Add");
  }
  if (Authority.IsAccessibleQ("Analytics.TopicClustering", undefined, "Edit")) {
    allowedAction.push("Edit");
  }

  useEffect(() => {
    getClusters();
  }, []);

  const getClusters = async (setLoading?: (state: boolean) => void) => {
    try {
      if (setLoading) setLoading(true);
      const response = await backendConnector.post<DataItem>(DATA_LINK.TopicClustering, setting.data);
      if (response.Success) {
        //Descending order by examples length
        let pl = response.payload;
        pl.topics = pl.topics.sort((a, b) => b.examples.length - a.examples.length);
        setData(pl.topics);
        setSetting({
          ...setting,
          display: {
            ...setting.display,
            displayCluster: pl.topics.length,
            dataLength: pl.topics.length,
          },
          data: {
            ...setting.data,
            cluster: pl?.topics.length,
            after_cleansing: pl?.total,
          },
        });
      } else {
        store.Alert("Generate Cluster Failed. You may try to increase the number of logs", "error");
      }
      if (setLoading) setLoading(false);
    } catch (error) {
      store.Alert("Generate Cluster Failed", "error");
      console.error(error);
    }
  };

  const updateSetting = (newSetting: Partial<ChartSetting>) => {
    setSetting({ ...setting, ...newSetting });
  };

  const handlePointClick = (dataItem: TopicItem) => {
    setSelectedTopic(dataItem);
    setOpen(true);
  };

  const renderBase = () => {
    return (
      <VStack height="100%" width="100%">
        <Box padding={1} width="100%" height="100%">
          <HStack
            justifyContent="flex-start"
            gap={8}
            style={{
              display: "flex",
              width: "100%",
            }}
          >
            <StyledLabelTypography>Topic Clustering</StyledLabelTypography>
          </HStack>
          <ChartSettingContext.Provider value={{ setting, updateSetting, getData: getClusters }}>
            <PerceptualMapView data={data} handlePointClick={handlePointClick} />
          </ChartSettingContext.Provider>
        </Box>
      </VStack>
    );
  };

  return (
    <VStack height="100%" width="100%">
      {renderBase()}
      {open && allowedAction.length > 0 && <TopicClusterDetail setOpen={setOpen} data={selectedTopic!} allowedActions={allowedAction} />}
    </VStack>
  );
};

export default TopicClustering;
