import { DatumizoBase } from "Components/LabIZO/Datumizo/DatumizoProps.interface";
import { DATA_LINK } from "connectors";
import schema from "./constants/schema/schema";
import WebsiteManagerForm from "./WebsiteManagerForm";
import { Authority } from "static";

export interface IDefaultProps {
  title?: string;
  base?: DatumizoBase;
}

const accessible = Authority.IsAccessibleQ(`GPTQA.WebsiteManager`, 999, "*"); // unused

export const defaultProps: IDefaultProps = {
  title: "Website Manager",
  base: {
    title: "Website Manager",
    exportDoc: "Websites",
    schema: schema,
    reqAuth: "GPTQA.WebsiteManager",
    columnAuth: "GPTWebsite",

    tablizo: {
      showSelector: true,
      columnsToolbar: true,
      filterToolbar: true,
      densityToolbar: true,
      exportToolbar: false,
    },

    Connect: {
      // DBInfo: DATA_LINK.WebsiteDBInfo,
      List: DATA_LINK.WebsiteList,
      SetColumnVisibilitySetting: DATA_LINK.WebsiteSetColumnVisibilitySetting,
      schema: schema.Table,
    },

    operations: {
      Add: {
        title: "Create Website",
        url: DATA_LINK.WebsiteAdd,
        success: "Website Added Successfully",
        fail: "Website Add Failed: ",
        schema: schema.Add,
        buttons: ["Clear", "Submit"],
        Custom: (id, row, onQuit, onQuitRefresh, renderFormizo) => <WebsiteManagerForm {...{ id, row, onQuit, onQuitRefresh, renderFormizo, mode: "Add" }} />,
      },
      Delete: {
        title: "Delete this Website?",
        content: "Caution: This is irrevertable.",
        url: DATA_LINK.WebsiteDelete,
        success: "Website Deleted Successfully.",
        fail: "Website Delete Failed: ",
        onSubmit: "Delete",
        addOnsOverride: { notice: !accessible },
      },
      Edit: {
        title: "Edit Website",
        url: DATA_LINK.WebsiteEdit,
        success: "Website Edited Successfully",
        fail: "Website Edit Failed: ",
        schema: schema.Edit,
        buttons: ["Revert", "Submit"],
        Custom: (id, row, onQuit, onQuitRefresh, renderFormizo) => <WebsiteManagerForm {...{ id, row, onQuit, onQuitRefresh, renderFormizo, mode: "Edit" }} />,
      },
      Info: {
        title: "Website DETAILS",
        schema: schema.Info,
        readOnly: true,
        buttons: [],
        Custom: (id, row, onQuit, onQuitRefresh, renderFormizo) => <WebsiteManagerForm {...{ id, row, onQuit, onQuitRefresh, renderFormizo, mode: "Info" }} />,
      },
      DeleteBulk: {
        title: (n: number) => "Delete these " + n + " Websites?",
        content: "Caution: This is irrevertable.",
        url: DATA_LINK.WebsiteDeleteBulk,
        success: "Websites Deleted Successfully.",
        fail: "Websites Delete Failed: ",
        onSubmit: "DeleteBulk",
        addOnsOverride: { notice: !accessible },
      },
      Enable: {
        title: (n: number) => "Enable these " + n + " Websites?",
        content: "Caution: This is irrevertable.",
        url: DATA_LINK.WebsiteEnable,
        success: "Websites Enabled Successfully.",
        fail: "Websites Enabled Failed: ",
        onSubmit: "Enable",
        addOnsOverride: { notice: !accessible },
      },
      Disable: {
        title: (n: number) => "Disable these " + n + " Websites?",
        content: "Caution: This is irrevertable.",
        url: DATA_LINK.WebsiteEnable,
        success: "Websites Disabled Successfully.",
        fail: "Websites Disabled Failed: ",
        onSubmit: "Disable",
        addOnsOverride: { notice: !accessible },
      },
      Export: {
        url: DATA_LINK.WebsiteExport,
        // schema: schema.Export,
        exportFileType: "xlsx",
      },
      Import: {
        title: "Website Import",
        content:
          "<b>Please follow the URL format:</b> <br />1. URL must ends with * <br />2. If URL does not have subpath, it should only ends with /* <br />3. If URL have subpath, it could ends with /* or * <br /><br />Example of valid URL: www.example.com/* or www.example.com/docs/* or www.example.com/docs* <br /><br>(File size cannot exceed 10MB, only accept .xlsx and .xls)</p>",
        url: DATA_LINK.WebsiteImport,
        success: "Website Imported Successfully.",
        fail: "Website Import Failed: ",
        accept: [".xlsx", ".xls"],
      },
      Sync: {
        success: "Website Syncing",
        fail: "Website Sync Failed: ",
        url: DATA_LINK.WebsiteSync,
        title: "Sync Website from Search Engine",
        content: "Caution: This is irrevertable.",
        addOnsOverride: { notice: !accessible },
      },
    },

    buttons: {
      inline: [
        { icon: "edit", func: "Edit", caption: "Edit", reqFunc: "Edit" },
        { icon: "info", func: "Info", caption: "Details" },
      ],
      inlineOpposite: [{ icon: "delete", func: "Delete", caption: "Delete", reqFunc: "Delete" }],
      left: [{ icon: "add", func: "Add", caption: "Add Website", reqFunc: "Add" }],
      right: [
        { icon: "deletebulk", func: "DeleteBulk", caption: (n: number) => "Delete (" + n + ")", reqFunc: "Delete", theme: "caution" },
        { icon: "syncto", func: "Sync", caption: "Sync", reqFunc: "Sync" },
        { icon: "export", func: "Export", caption: (n) => "Export (" + (n === 0 ? "All" : n) + ")", reqFunc: "Export" },
        { icon: "import", func: "Import", caption: "Import", reqFunc: "Import" },
        { icon: "enable", func: "Enable", caption: (n: number) => "Enable (" + n + ")", reqFunc: "Enable" },
        { icon: "disable", func: "Disable", caption: (n: number) => "Disable (" + n + ")", reqFunc: "Disable" },
      ],
    },
  },
};
