import { FormizoSchema } from "Components/LabIZO/Formizo";

const schema: FormizoSchema[] = [
  {
    label: "Disable Answer",
    name: "field.answer.disabled",
    format: "bool",
  },
  {
    label: "Disable Description",
    name: "field.description.disabled",
    format: "bool",
  },
  {
    label: "Disable Previous Generation",
    name: "field.prev_generation.disabled",
    format: "bool",
  },
  {
    label: "Number of exmaples",
    name: "field.num_examples.value",
    format: "number",
  },
  // {
  //   label: "Auto Translate",
  //   name: "field.auto_translate",
  //   format: "bool",
  // },
  {
    label: "Service Url",
    name: "serviceUrl",
    format: "text",
  },
];

export default schema;
