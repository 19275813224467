import { Paper } from "@mui/material";
import { DBAnswerSpec } from "interfaces";
import { IFLEDoc, TSystemContextSetting, TSystemContextType } from "interfaces/db.interfaces/fle.interface";
import { useFormContext } from "react-hook-form";
import { BodyTitle, RadioButtonsGroup } from "Components";
import { VStack } from "Components/Layouts";
import { DateSetting, NumericSetting, RegexSetting } from ".";

interface ISystemSettingProps {
  name: `contexts.${number}.setting`;
  disabled?: boolean;
}

export function SystemSettings({ name, ...props }: ISystemSettingProps) {
  const { watch, setValue, getValues } = useFormContext<IFLEDoc<DBAnswerSpec>>();
  const systemTypes = [
    { label: "Date", value: TSystemContextType.date },
    { label: "Number", value: TSystemContextType.numeric },
    { label: "String", value: TSystemContextType.string },
    { label: "Boolean", value: TSystemContextType.boolean },
    { label: "Regex", value: TSystemContextType.regex },
  ];

  const onSettingChange = (setting: TSystemContextSetting) => {
    setValue(name, setting);
  };

  const setting = watch(name) as TSystemContextSetting;
  if (!setting) return <></>;
  return (
    <VStack width={"100%"} alignItems="flex-start">
      <Paper sx={{ width: "100%", p: 2 }}>
        <BodyTitle>System Entity Settings</BodyTitle>
        <RadioButtonsGroup
          label="System Entity Type"
          value={setting.type}
          options={systemTypes}
          onChange={(value: string) => {
            setting.type = value as TSystemContextType;
            setting.values = [];
            onSettingChange(setting);
          }}
          {...props}
        />
      </Paper>

      <Paper sx={{ width: "100%", p: 1, overflowY: "auto" }}>
        {setting.type === TSystemContextType.date && <DateSetting name={`${name}.values`} setting={setting} onChange={onSettingChange} {...props} />}
        {setting.type === TSystemContextType.numeric && <NumericSetting name={`${name}.values`} setting={setting} onChange={onSettingChange} {...props} />}
        {setting.type === TSystemContextType.regex && <RegexSetting name={`${name}.values`} setting={setting} onChange={onSettingChange} {...props} />}
      </Paper>
    </VStack>
  );
}
