import React, { Component } from "react";
import PropsType from "prop-types";
import { HtmlParserWrapper } from "Components/HtmlParserWrapper";
import ZTagParser from "./ZCMHTMLTags/ZTagParser";
import _ from "lodash";

interface IProps {
  text: string;
  cssp?: string;
  textColor?: string;

  //Read More and read Less
  cssPrefix?: any;
  HTMLSpecialTagParser?: any;
  iaddOns?: any;
  readMoreCaption?: string;
  readLessCaption?: string;
  HTMLEnabled?: boolean;
  hideLongAnswer?: boolean;
  longAnswerLength?: number;
  revertReadMore?: boolean;
}

const ZCMText = ({ text, cssp, textColor, ...otherProps }: IProps) => {
  const [hide, setHide] = React.useState(true);

  const ReplaceSpecialTags = (text: string) => {
    let parsed = _.isString(text) ? <HtmlParserWrapper html={text} /> : text;
    let { cssPrefix, HTMLSpecialTagParser, iaddOns } = otherProps;
    try {
      if (HTMLSpecialTagParser) {
        return HTMLSpecialTagParser(cssPrefix, parsed, iaddOns);
      } else {
        return ZTagParser.Parse(cssPrefix, parsed, iaddOns);
      }
    } catch {
      return text;
    }
  };

  const renderReadMore = () => {
    let { readMoreCaption } = otherProps;
    return (
      <div key="ReadMore" className={cssp + " msg-text-readmore"} onClick={() => setHide(false)}>
        {readMoreCaption}
      </div>
    );
  };
  const renderReadLess = () => {
    let { readLessCaption } = otherProps;
    return (
      <div key="ReadLess" className={cssp + " msg-text-readmore"} onClick={() => setHide(true)}>
        {readLessCaption}
      </div>
    );
  };

  const renderText = (text: string) => {
    let { HTMLEnabled, hideLongAnswer, longAnswerLength, revertReadMore } = otherProps;
    let rtn = [];
    if (HTMLEnabled) {
      let blocks = ReplaceSpecialTags(text);
      if (hideLongAnswer) {
        if (!Array.isArray(blocks)) {
          blocks = [blocks];
        }
        let length = 0;
        let short = false;
        _.map(blocks, (o, i) => {
          if (o.props && o.props.children) {
            if (_.isString(o.props.children)) {
              length += o.props.children.length;
            }
          } else {
            if (_.isString(o)) {
              length += o.length;
            }
          }

          if (longAnswerLength && length < longAnswerLength) {
            if (Number(i) === blocks.length - 1) {
              short = true;
            }
            rtn.push(o);
          } else {
            if (!hide) {
              rtn.push(o);
            }
          }
        });
        if (short) {
          if (revertReadMore) {
            rtn.push(renderReadMore());
          } else {
            rtn.push(renderReadLess());
          }
        } else {
          if (hide) {
            if (revertReadMore) {
              rtn.push(renderReadMore());
            } else {
              rtn.push(renderReadLess());
            }
          }
        }
      }
    } else {
      rtn = [text];
      if (hideLongAnswer) {
        if (longAnswerLength && text.length > longAnswerLength) {
          let showText = text.substring(0, longAnswerLength);
          let hideText = text.substring(longAnswerLength);

          rtn = [
            <div className={cssp + " msg-text-box"}>
              <div key="showtext" className={cssp + " msg-text-show"}>
                {showText}
              </div>
              {!hide && (
                <div key="hidetext" className={cssp + " msg-text-hide"}>
                  {hideText}
                </div>
              )}
              {hide && renderReadMore()}
              {!hide && revertReadMore && renderReadLess()}
            </div>,
          ];
        }
      }
    }
    return rtn;
  };

  const renderHTMLtext = (text: string) => {
    return <HtmlParserWrapper html={text} />;
  };
  return (
    <div className={cssp + " msg-text"} style={{ whiteSpace: "pre-line", color: textColor }}>
      {renderHTMLtext(text)}
    </div>
  );
};

export default ZCMText;
