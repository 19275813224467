import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@mui/material";
import { Roles } from "interfaces";
import { useState } from "react";

interface CopyToAllButtonProps {
  data: any;
  currentRole: string;
  _onValueChange: (feildName: string, value: any) => {};
}

export const CopyToAllButton: React.FC<CopyToAllButtonProps> = (props) => {
  const { data, currentRole, _onValueChange } = props;
  const [openConfirm, setOpenConfirm] = useState(false);

  const handleOpenConfirm = () => {
    setOpenConfirm(true);
  };

  const handleCloseConfirm = () => {
    setOpenConfirm(false);
  };

  const handleUpdate = () => {
    try {
      const roleData = data.answer[currentRole];
      const otherRoles = Roles.filter((r) => r.value !== currentRole); // Assuming Roles is globally available

      otherRoles.forEach((r) => {
        _onValueChange(`answer.${r.value}`, roleData); // Assuming _onValueChange is globally available
      });

      handleCloseConfirm();
    } catch (error) {
      console.error("Error copying data:", error);
    }
  };

  return (
    <>
      <Button variant="contained" color="primary" onClick={handleOpenConfirm}>
        Copy to ALL
      </Button>

      <Dialog open={openConfirm} onClose={handleCloseConfirm} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
        <DialogTitle id="alert-dialog-title">Confirm Action</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">Are you sure you want to copy this content to all roles?</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseConfirm} color="primary">
            Cancel
          </Button>
          <Button onClick={handleUpdate} color="primary" autoFocus>
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
