import { useEffect, useState } from "react";
import Formizo from "Components/LabIZO/Formizo";
import { Env, ErrorX, store } from "static";
import { VStack, HStack } from "Components/LabIZO/Stackizo";
import { backendConnector, DATA_LINK } from "connectors";
import schema from "./schema";

const SysChannel: React.FC<{}> = () => {
  const [channelInfo, setChannelInfo] = useState(null);

  const getChannelConfig = async () => {
    try {
      const data = await backendConnector.post(DATA_LINK.SYSChannelInfo, {});
      setChannelInfo(data.payload);
    } catch (e: any) {
      ErrorX.Handle(e);
    }
  };
  useEffect(() => {
    getChannelConfig();
  }, []);

  const onSubmit = async (data: any) => {
    try {
      store.isLoading(true);
      const res = await backendConnector.post(DATA_LINK.SYSChannelEdit, data);
      store.isLoading(false);
      if (res.Success) {
        await Env.GetSysInfo();
        store.Alert("System Channel updated successfully", "success");
      } else {
        store.Alert("System Channel update fails.", "error");
      }
    } catch (e: any) {
      store.Alert("Cannot set system Channel", "error");
      store.Alert(ErrorX.Handle(e), "error");
    }
  };

  const renderChannelSetting = () => {
    return (
      <Formizo
        auth={store.user.authority}
        schema={schema} //
        buttons={["Revert", "Submit"]}
        defaultValue={channelInfo}
        onSubmit={onSubmit}
      />
    );
  };
  return (
    <HStack justifyContent="flex-start" height="100%" gap={3} paddingX={4}>
      <VStack width="100%">{renderChannelSetting()}</VStack>
    </HStack>
  );
};

export { schema };
export default SysChannel;
