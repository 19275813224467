import { FormizoSchema } from "Components/LabIZO/Formizo";

export const schema: FormizoSchema[] = [
  {
    label: "Service Url",
    name: "serviceUrl",
    format: "text",
  },
  {
    label: "Convlog Source",
    name: "source",
    format: "select",
    selectStyle: "dropdown",
    validate: ["required"],
    options: [
      { label: "Convlog", value: "ConvDB" },
      { label: "GPT Convlog", value: "GPTQAConvDB" },
    ],
    defaultValue: "ConvDB",
  },
];
