import React, { useState } from "react";
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Alert } from "@mui/material";
import { store } from "static";
import { HtmlParserWrapper } from "Components/HtmlParserWrapper";
import EmailFeild from "./EmailFeild";
import { Criteria } from "./Validation";
import { DATA_LINK, backendConnector } from "connectors";

interface IEmailChangerProps {
  username: string;
  setEmailChanged?: (value: boolean) => void;
}

const EmailChanger = ({ username, setEmailChanged }: IEmailChangerProps) => {
  const [open, setOpen] = React.useState(false);
  const [alert, setAlert] = React.useState(<div />);

  const onClose = () => {
    setOpen(false);
  };

  const onSubmit = async (newEmail: string, newEmailConfirm: string) => {
    try {
      const rtn = await backendConnector.post(DATA_LINK.UserChangeEmail, {
        UserName: username,
        NewEmail: newEmail,
        NewEmailConfirm: newEmailConfirm,
      });

      let { Success } = rtn;

      if (!Success) return setAlert(<Alert severity="error">{rtn?.payload?.message}</Alert>);

      if (setEmailChanged) setEmailChanged(true);

      store.Alert(rtn?.payload?.message);
      setAlert(<div />);

      setOpen(false);
    } catch (e) {
      setAlert(<Alert severity="error">{JSON.stringify(e)}</Alert>);
    }
  };

  return (
    <div className="EmailChanger">
      <Button
        variant="outlined"
        color="warning"
        onClick={() => {
          setOpen(true);
        }}
      >
        Change Email
      </Button>
      <PopupEmailChanger open={open} onClose={onClose} onAlert={alert} onSubmit={onSubmit} />
    </div>
  );
};

interface IPopupEmailChangerProps {
  open: boolean;
  onClose: () => void;
  onAlert: JSX.Element;
  onSubmit: (newEmail: string, newEmailConfirm: string) => void;
}

const PopupEmailChanger = ({ open, onClose, onAlert, onSubmit }: IPopupEmailChangerProps) => {
  const [newEmail, setNewEmail] = useState("");
  const [newEmailConfirm, setNewEmailConfirm] = useState("");

  return (
    <Dialog open={open ? true : false} onClose={onClose} aria-labelledby="form-dialog-title">
      <DialogTitle id="form-dialog-title">Change Email</DialogTitle>
      <DialogContent>
        <DialogContentText>{<HtmlParserWrapper html={Criteria.join("<br>")} />}</DialogContentText>
        <EmailFeild id="newEmail" label="New Email" criteria={["required", "email"]} onInput={(value) => setNewEmail(value)} />
        <EmailFeild id="newEmailConfirm" label="New Email Confirm" criteria={["required", "email"]} onInput={(value) => setNewEmailConfirm(value)} />
        {onAlert}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Cancel
        </Button>
        <Button
          disabled={!(newEmail && newEmailConfirm)}
          onClick={() => {
            onSubmit(newEmail, newEmailConfirm);
          }}
          color="primary"
          type="submit"
        >
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default EmailChanger;
