import { FormControl, InputLabel, OutlinedInput, MenuItem, Select, SelectChangeEvent, FormHelperText } from "@mui/material";
import { TFMultiSelect } from "Components/LabIZO/Formizo/FormizoSchema.interface";
import _ from "lodash";
import * as React from "react";
import { Accessor } from "static";
import { getOptions } from "./getOptions";

interface IFFBasicMultiSelectProps {
  ifieldStyle?: string;
  _onFieldFocus?: () => void;
  _onFieldBlur?: () => void;
  ischema: TFMultiSelect;
  _onValueChange?: (name: string, value: string[], criteria: any) => void;
  addOns?: any;
  iname: string;
  formError: any;
  _onBlurInlineSubmit?: any;
  errorsShowOnHelperText?: boolean;
  readOnly?: boolean;
  _Validate?: any;
  formValue?: any;
}

export const FFBasicMultiSelect: React.FC<IFFBasicMultiSelectProps> = (props) => {
  const [selectedNames, setSelectedNames] = React.useState([]);

  const options = getOptions(props.ischema, props.addOns);

  const handleChange = (event: SelectChangeEvent<any>) => {
    console.log("selected values", event.target.value);
    setSelectedNames(event.target.value as any);
    if (props._onValueChange && props.ischema.name) {
      props._Validate && props._Validate(props.ischema.name, event.target.value as any, props.ischema.validate);
      props._onValueChange(props.ischema.name, event.target.value as any, {});
    }
  };

  React.useEffect(() => {
    const { ischema, iname, _Validate, formValue } = props;
    let ivalue = Accessor.Get(formValue, iname);

    if (!_.isEmpty(ischema.validate)) {
      _Validate(iname, ivalue, ischema.validate);
    }
  }, [props.formError, Accessor.Get(props.formValue, props.iname)]);

  React.useEffect(() => {
    setSelectedNames(Accessor.Get(props.formValue, props.iname) || []);
  }, [Accessor.Get(props.formValue, props.iname)]);

  let { ischema, iname, formError, ifieldStyle, _onBlurInlineSubmit, _onFieldFocus, _onFieldBlur, errorsShowOnHelperText, readOnly } = props;
  if (!ischema) return null;

  let ierror = Accessor.Get(formError, iname);
  const ireadOnly = ischema.readOnly || readOnly;

  let helperText = ischema.helperText as string;
  if (!ireadOnly && errorsShowOnHelperText) {
    helperText = ierror;
  }

  return (
    <FormControl sx={{ m: 1, width: "100%" }} error={!_.isEmpty(ierror)} disabled={ireadOnly as boolean}>
      <InputLabel>{props.ischema.label}</InputLabel>
      <Select multiple value={selectedNames} onChange={handleChange} input={<OutlinedInput label="Multiple Select" />}>
        {options?.map((_opt) => {
          return (
            <MenuItem key={_opt.value} value={_opt.value}>
              {_opt.label}
            </MenuItem>
          );
        })}
      </Select>
      {!_.isEmpty(helperText) && <FormHelperText>{helperText}</FormHelperText>}
    </FormControl>
  );
};
