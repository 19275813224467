import { Button, Chip, CircularProgress, TextField, Tooltip, Typography } from "@mui/material";
import { AvailLANG } from "interfaces";
import { useEffect, useState } from "react";
import _ from "lodash";
import { Autorenew, Add, TaskAlt, AddCircleOutline, Delete } from "@mui/icons-material";
import { DATA_LINK, backendConnector } from "connectors";
import { StyledBox, StyledContainer, StyledQuestionBox, StyledQuestionContainer, StyledQuestionRow, StyledQuestionTypography } from "../StyledComponent";
import { HStack } from "Components";
import { QuestionDetails, QuestionSource } from "../../Interface/TopicClusterDetail.interface";

interface ExampleGenerationByLangProps {
  lang: AvailLANG;
  question: string;
  data: QuestionDetails[];
  onStatusChange: (lang: AvailLANG, question: string[]) => void;
}

export const ExampleGenerationByLang = ({ lang, question, data, onStatusChange }: ExampleGenerationByLangProps) => {
  const [loading, setLoading] = useState(false);
  const [questionDetails, setQuestionDetails] = useState<QuestionDetails[]>([]);
  const [error, setError] = useState(false);

  const [textfieldExist, setTextfieldExist] = useState(false);
  const [customQuestion, setCustomQuestion] = useState<string>("");

  const numGeneration = 5;

  useEffect(() => {
    handleGenerate();
    setQuestionDetails(data || []);
  }, []);

  const handleGenerate = async () => {
    setLoading(true);
    const { Success, payload, message } = await backendConnector.post<{ generatedQuestions: string[] }>(DATA_LINK.IntentGenerate, {
      questions: [question],
      lang: lang,
      num_examples: numGeneration,
      prev_generations: questionDetails?.map((q) => q.question),
    });
    if (!Success) {
      setLoading(false);
      setError(true);
    }
    setQuestionDetails((prevDetails) => {
      const newQs = prevDetails.filter(({ selected, source }) => selected || source == QuestionSource.Cluster);
      payload.generatedQuestions.map((q) => {
        newQs.push({
          question: q,
          selected: false,
          source: QuestionSource.Generated,
        });
      });
      return newQs;
    });
    setLoading(false);
  };

  const handleStatusChange = (question: QuestionDetails, add: boolean) => {
    const newQuestionDetails = questionDetails.map((q) => {
      if (q.question === question.question) {
        return { ...q, selected: add };
      }
      return q;
    });
    setQuestionDetails(newQuestionDetails);
    const selectedQuestions = newQuestionDetails.filter((q) => q.selected).map((q) => q.question);
    onStatusChange(lang, selectedQuestions);
  };

  const renderCustomQuestion = () => {
    const handleTextfieldChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      setCustomQuestion(e.target.value);
    };
    const handleAddCustomQuestion = () => {
      if (customQuestion.trim() === "") {
        return;
      }
      const newQuestion = {
        question: customQuestion,
        selected: true,
        source: QuestionSource.Custom,
      };
      setQuestionDetails((prevDetails) => [...prevDetails, newQuestion]);
      setCustomQuestion("");
      setTextfieldExist(false);
      const selectedQuestions = [...questionDetails.filter((q) => q.selected).map((q) => q.question), customQuestion];
      onStatusChange(lang, selectedQuestions);
    };
    return (
      <StyledQuestionContainer>
        <StyledQuestionRow className="question-row">
          <TextField fullWidth value={customQuestion} onChange={handleTextfieldChange} />
          <StyledBox>
            <Button sx={{ color: "green" }} className="question-button">
              <Add onClick={handleAddCustomQuestion} />
            </Button>
            <Button sx={{ color: "red" }} className="question-button">
              <Delete onClick={() => setTextfieldExist(false)} />
            </Button>
          </StyledBox>
        </StyledQuestionRow>
      </StyledQuestionContainer>
    );
  };

  const renderGeneratedExample = () => {
    const getChipColor = (source: QuestionSource) => {
      switch (source) {
        case QuestionSource.Generated:
          return "primary";
        case QuestionSource.Cluster:
          return "success";
        case QuestionSource.Custom:
          return "secondary";
      }
    };
    return (
      <StyledQuestionContainer>
        {questionDetails?.map((q, index) => (
          <StyledQuestionRow key={index} className="question-row">
            <Typography sx={{ flex: 4, fontSize: 14 }}>{q.question}</Typography>
            <StyledBox className="question-button-container">
              <Chip label={q.source} color={getChipColor(q.source)} sx={{ minWidth: "100px", marginRight: "8px" }} />
              <Button sx={{ color: q.selected ? "green" : "default" }} className="question-button" onClick={() => handleStatusChange(q, !q.selected)}>
                {q.selected ? <TaskAlt /> : <Add />}
              </Button>
            </StyledBox>
          </StyledQuestionRow>
        ))}
        {loading && <CircularProgress style={{ width: "20px", height: "20px" }} />}
        {error && <Typography sx={{ color: "red" }}>Failed to generate questions</Typography>}
        {textfieldExist && renderCustomQuestion()}
      </StyledQuestionContainer>
    );
  };
  return (
    <StyledContainer>
      <HStack style={{ justifyContent: "space-between" }}>
        <StyledQuestionTypography>QUESTION {lang}</StyledQuestionTypography>
        <StyledQuestionBox>
          {!textfieldExist && (
            <Tooltip title="Add Custom Questions">
              <Button
                className="add-custom-button"
                onClick={() => {
                  setTextfieldExist(true);
                }}
              >
                <AddCircleOutline />
              </Button>
            </Tooltip>
          )}
          <Tooltip title="Generate Questions">
            <Button className="gen-button" onClick={handleGenerate}>
              <Autorenew />
            </Button>
          </Tooltip>
        </StyledQuestionBox>
      </HStack>
      {renderGeneratedExample()}
    </StyledContainer>
  );
};
