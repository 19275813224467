import { Box, Button, CircularProgress, Stack, Tooltip, Typography, styled } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import TaskAltIcon from "@mui/icons-material/TaskAlt";
import { AvailLANG } from "interfaces";
import { useEffect, useRef, useState } from "react";
import AutorenewIcon from "@mui/icons-material/Autorenew";
import { v1 } from "uuid";
import _ from "lodash";
import { ColorX } from "static";
import { DATA_LINK, backendConnector } from "connectors";

interface ExampleGenerationByLangProps {
  lang: AvailLANG;
  existingGenerations: string[];
  question?: string;
  answer?: string;
  setGeneratedQuestions: (lang: AvailLANG, add: boolean, newQuestions: QuestionDetails) => void;
  setShowError: React.Dispatch<React.SetStateAction<boolean>>;
}

export interface QuestionDetails {
  key: string;
  question: string;
  selected: boolean;
}

const StyledByLangContainer = styled(Stack)({
  flexDirection: "column",
  gap: "15px",
  padding: "5px 0",
  borderBottom: "1px solid #a1a1a1",
  width: "100%",
});

const StyledHeaderContainer = styled(Stack)({
  flexDirection: "row",
  alignItems: "center",
  minHeight: "2.3rem",
});

const StyledQuestionContainer = styled(Stack)({
  flexDirection: "column",
  alignItems: "center",
});

const StyledQuestionRow = styled(Stack)({
  flexDirection: "row",
  alignItems: "center",
  width: "100%",
});

export const ExampleGenerationByLang: React.FC<ExampleGenerationByLangProps> = ({ lang, question, answer, existingGenerations, setGeneratedQuestions, setShowError }) => {
  const numGeneration = 5;
  const [questionDetails, setQuestionDetails] = useState<QuestionDetails[]>(existingGenerations.map((g) => ({ key: v1(), question: g, selected: true })));
  const [loading, setLoading] = useState(false);

  const buttonRef = useRef<HTMLButtonElement>(null);

  useEffect(() => buttonRef.current?.click(), []);

  const handleGenerate = async (_e: React.MouseEvent<HTMLButtonElement>) => {
    setLoading(true);
    const questions = await generate();
    if (questions === null) {
      setShowError(true);
    } else {
      setQuestionDetails((state) => {
        const newQs = state.filter(({ selected }) => selected);
        for (const q of questions) {
          newQs.push({ key: v1(), question: q, selected: false });
        }
        return newQs;
      });
    }
    setLoading(false);
  };

  const handleStatusChange = (question: QuestionDetails, add: boolean) => {
    setQuestionDetails((state) => {
      const newQs = state.find((q) => q.key === question.key);
      if (!newQs) throw new Error("Question not found");
      newQs.selected = !newQs.selected;
      return state;
    });
    setGeneratedQuestions(lang, add, question);
  };

  const generate = async (): Promise<string[] | null> => {
    const { Success, payload, message } = await backendConnector.post<{ generatedQuestions: string[] }>(DATA_LINK.IntentGenerate, {
      questions: [question],
      answer,
      lang,
      num_examples: numGeneration,
      prev_generations: questionDetails.map((q) => q.question),
    });
    if (!Success) {
      console.error(message);
      return null;
    }
    return payload.generatedQuestions;
  };

  return (
    <StyledByLangContainer>
      <StyledHeaderContainer>
        <Typography sx={{ color: ColorX.GetColorCSS("Primary"), flex: "0 0 30%" }}>{`Representitive Question (${lang}):`}</Typography>
        <Typography
          sx={{
            color: question ? ColorX.GetColorCSS("Primary") : ColorX.GetColorCSS("grey"),
            flex: "0 0 60%",
            fontWeight: 600,
            fontStyle: question ? "normal" : "italic",
          }}
        >
          {!question ? "No question provided" : question}
        </Typography>
        {question ? (
          loading ? (
            <Box sx={{ flex: "0 0 10%", display: "flex", alignItems: "center", justifyContent: "center" }}>
              <CircularProgress style={{ width: "20px", height: "20px" }} />
            </Box>
          ) : (
            <Tooltip title="Generate Questions">
              <Button sx={{ flex: "0 0 10%" }} ref={buttonRef} className="gen-button" onClick={handleGenerate}>
                <AutorenewIcon />
              </Button>
            </Tooltip>
          )
        ) : (
          <Box sx={{ flex: "0 0 10%" }}></Box>
        )}
      </StyledHeaderContainer>
      <StyledQuestionContainer>
        {questionDetails.map((q) => {
          return (
            <StyledQuestionRow key={q.key} className="question-row">
              <Typography sx={{ flex: 4 }}>{q.question}</Typography>
              <Box sx={{ flex: 1 }} className="question-button-container">
                {q.selected ? (
                  <Button sx={{ color: "green" }} className="question-button" onClick={() => handleStatusChange(q, false)}>
                    <TaskAltIcon />
                  </Button>
                ) : (
                  <Button className="question-button" onClick={() => handleStatusChange(q, true)}>
                    <AddIcon />
                  </Button>
                )}
              </Box>
            </StyledQuestionRow>
          );
        })}
      </StyledQuestionContainer>
    </StyledByLangContainer>
  );
};
