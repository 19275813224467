export interface IMenuItemSchema {
  caption: string;
  id: string;
  link: string;
  icon: JSX.Element | string;
  reqAuth?: string;
  reqLevel?: any;
  disabled?: boolean;
  subMenu?: IMenuItemSchema[];
}

export enum ClassicMenuConfigMode {
  ML = "ml",
  NLP = "nlp",
}
