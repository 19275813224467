import { FormizoSchema } from "Components/LabIZO/Formizo";

export const schema: FormizoSchema[] = [
  {
    label: "Logger Database",
    name: "logger.type",
    format: "select",
    selectStyle: "dropdown",
    selectRef: "LoggerTypes",
    selectCap: "caption",
    selectVal: "_id",
    placeholder: "Database Type",
    validate: ["required"],
    options: [{ value: "ElasticSearch", label: "ElasticSearch / OpenSearch" }],
    defaultValue: "ElasticSearch",
  },
  {
    control: "logger.type",
    controlFunc: (doc, field) => doc?.logger?.type === "ElasticSearch",
    fold: [
      {
        label: "Username",
        name: "logger.username",
        format: "text",
      },
      {
        label: "Password",
        name: "logger.password",
        format: "text",
      },
      {
        label: "URL",
        name: "logger.url",
        format: "text",
      },
      {
        label: "Conversation Log Index",
        name: "logger.index",
        format: "text",
      },
      {
        label: "Index Timeframe",
        name: "logger.indexTimeframe",
        format: "select",
        selectStyle: "dropdown",
        validate: ["required"],
        defaultValue: "YYYYMM",
        options: [
          { value: "YYYYMMDD", label: "daily" },
          { value: "YYYYMM", label: "monthly" },
        ],
      },
    ],
  },
];
