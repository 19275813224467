import { TablizoSchema } from "Components/LabIZO/Tablizo";
const Table: TablizoSchema[] = [
  {
    label: "ID",
    name: "_id",
  },
  {
    label: "Role Name",
    name: "role",
  },
  {
    label: "Level",
    name: "level",
  },
  {
    label: "Landing Page",
    name: "landingPage",
  },
];
const Info: TablizoSchema[] = [
  {
    label: "ID",
    name: "_id",
  },
  {
    label: "Role Name",
    name: "role",
  },
  {
    label: "Level",
    name: "level",
  },
  {
    label: "Landing Page",
    name: "landingPage",
  },
];
const Add: TablizoSchema[] = [
  {
    label: "Role Name",
    name: "role",
  },
  {
    label: "Level",
    name: "level",
  },
  {
    label: "Landing Page",
    name: "landingPage",
  },
];
const Edit: TablizoSchema[] = [
  {
    label: "ID",
    name: "_id",
  },
  {
    label: "Role Name",
    name: "role",
  },
  {
    label: "Level",
    name: "level",
  },
  {
    label: "Landing Page",
    name: "landingPage",
  },
];
const schema = {
  Table,
  Info,
  Edit,
  Add,
};

export default schema;
