import { Component } from "react";

import { observer } from "mobx-react";
import fileDownload from "js-file-download";
import { Box, Typography } from "@mui/material";

import schema from "./schema";
import FAQCreate from "./FAQCreate/FAQCreate";
import ImportConfirm from "./ImportConfirm/ImportConfirm";

import Datumizo from "Components/LabIZO/Datumizo/Datumizo";
import { Accessor, ColorX, Authority, store, ErrorX } from "static";
import { VStack } from "Components/LabIZO/Stackizo";
import _ from "lodash";
import { backendConnector, DATA_LINK } from "connectors";

class FAQ extends Component {
  static propTypes = {};

  static defaultProps = {};

  constructor() {
    super();
    this.state = {
      title: "FAQ MANAGEMENT",
      serverSidePagination: false,
      base: {
        title: "FAQs",
        exportDoc: "FAQs",
        schema: schema,
        reqAuth: "FAQ",

        tablizo: {
          showSelector: true,
          columnsToolbar: true,
          filterToolbar: true,
          densityToolbar: true,
          exportToolbar: false,
        },

        Connect: {
          DBInfo: DATA_LINK.FAQDBInfo,
          List: DATA_LINK.FAQList,
          SetColumnVisibilitySetting: DATA_LINK.FAQSetColumnVisibilitySetting,
          schema: schema.Table,
        },

        operations: {
          Add: {
            title: "Create FAQ",
            url: DATA_LINK.FAQAdd,
            success: "FAQ Added Successfully",
            fail: "FAQ Add Failed: ",
            schema: schema.Add,
            buttons: ["Clear", "Submit"],
            Custom: (id, row, onQuit, onQuitRefresh, renderFormizo) => {
              const { intents, documents, knowledgeGroups } = this.state;
              const addOns = {
                workspace: "master",
                intents,
                documents,
                knowledgeGroups,
              };
              return <FAQCreate onQuit={onQuit} onQuitRefresh={onQuitRefresh} getQuickReplies={this.getQuickReplies} addOns={addOns} />;
            },
          },
          Delete: {
            title: "Delete this FAQ?",
            content: "Caution: This is irrevertable.",
            url: DATA_LINK.FAQDelete,
            success: "FAQ Deleted Successfully.",
            fail: "FAQ Delete Failed: ",
            onSubmit: "Delete",
            addOnsOverride: { workspace: "master" },
          },
          Edit: {
            title: "Edit FAQ",
            url: DATA_LINK.FAQEdit,
            success: "FAQ Edited Successfully",
            fail: "FAQ Edit Failed: ",
            schema: schema.Edit,
            buttons: ["Revert", "Submit"],
            onSubmit: "Edit",
            addOnsOverride: { workspace: "master" },
          },
          Info: {
            title: "FAQ DETAILS",
            schema: schema.Info,
            readOnly: true,
          },

          Export: {
            url: DATA_LINK.FAQExport,
            exportFileType: "xlsx",
            addOnsOverride: { workspace: "master" },
          },

          Import: {
            title: "FAQ Import",
            content: "(File size cannot exceed 10MB, only accept .xlsx and .xls)",
            url: DATA_LINK.FAQImport,
            onSuccess: this.ImportConfirm.onReturn,
            success: "FAQ Imported Successfully.",
            fail: "FAQ Import Failed: ",
            accept: [".xlsx", ".xls"],
            addOnsOverride: { workspace: "master" },
          },

          ImportConfirm: {
            title: "Import Summary",
            Custom: (id, row, onQuit, onQuitRefresh, renderFormizo, addOns) => (
              <ImportConfirm docID={id} doc={row} onQuit={onQuit} onQuitRefresh={onQuitRefresh} addOns={{ workspace: addOns.workspace }} />
            ),
          },

          DeleteBulk: {
            title: (n) => "Delete these " + n + " FAQs?",
            content: "Caution: This is irrevertable.",
            url: DATA_LINK.FAQDeleteBulk,
            success: "FAQs Deleted Successfully.",
            fail: "FAQs Delete Failed: ",
            onSubmit: "DeleteBulk",
            addOnsOverride: { workspace: "master" },
          },
          Enable: {
            title: (n) => "Enable these " + n + " FAQs?",
            content: "Caution: This is irrevertable.",
            url: DATA_LINK.FAQEnable,
            success: "Enable All FAQs Successfully.",
            fail: "Enable All FAQs Failed: ",
            onSubmit: "Enable",
            addOnsOverride: { workspace: "master" },
          },
          Disable: {
            title: (n) => "Disable these " + n + " FAQs?",
            content: "Caution: This is irrevertable.",
            url: DATA_LINK.FAQEnable,
            success: "Disable All FAQs Successfully.",
            fail: "Disable All FAQs Failed: ",
            onSubmit: "Disable",
            addOnsOverride: { workspace: "master" },
          },
        },

        buttons: {
          inline: [
            { icon: "edit", func: "Edit", caption: "Edit", reqFunc: "Edit" },
            { icon: "info", func: "Info", caption: "Details" },
          ],
          inlineOpposite: [{ icon: "delete", func: "Delete", caption: "Delete", reqFunc: "Delete" }],
          left: [{ icon: "add", func: "Add", caption: "Add FAQ", reqFunc: "Add" }],
          right: [
            // {
            //   icon: "analyse",
            //   func: this.Analyse,
            //   caption: "Analyse",
            //   reqFunc: "Analyse",
            //   reqLevel: 0,
            //   theme: {
            //     label: "white",
            //     background: ColorX.GetColorCSS("Decorate1"),
            //     hover: {
            //       background: "#115293",
            //     },
            //   },
            // },
            { icon: "deletebulk", func: "DeleteBulk", caption: (n) => "Delete (" + n + ")", reqFunc: "Delete", theme: "caution" },
            { icon: "export", func: "Export", caption: (n) => "Export (" + (n === 0 ? "All" : n) + ")", reqFunc: "Export" },
            {
              icon: "import",
              func: "Import",
              caption: "Import",
              reqFunc: "Import",
            },
            { icon: "enable", func: "Enable", caption: (n) => "Enable (" + n + ")", reqFunc: "Enable" },
            { icon: "disable", func: "Disable", caption: (n) => "Disable (" + n + ")", reqFunc: "Enable" },
          ],
        },
      },
    };
  }

  componentDidMount() {
    Authority.Require("FAQ");
    this._setAllStates(() => {
      this.getAvailableDocs();
      this.getAvailableKnowledgeGroups();
    });
  }

  componentDidUpdate(prevProps, prevState) {
    if (!Accessor.IsIdentical(prevProps, this.props, Object.keys(FAQ.defaultProps))) {
      this._setAllStates();
    }
  }

  componentWillUnmount() {
    this.setState = (state, callback) => {
      return;
    };
  }

  _setAllStates = (callback) => {
    this.setState(
      (state, props) => ({
        ...props,
      }),
      callback
    );
  };

  onMountDatumizo = (callbacks) => {
    this.MountDatumizo = callbacks;
  };

  Analyse = async (rows) => {
    try {
      store.isLoading(true);
      const data = await backendConnector.export(DATA_LINK.FAQAnalyse, {
        rows,
      });
      store.isLoading(false);
      if (data) {
        const blob = new Blob([data], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" });
        fileDownload(blob, "Conflict Report.xlsx");
      } else {
        store.Alert("Failed", "error");
      }
    } catch (e) {
      ErrorX.Handle(e);
    }
  };

  ImportConfirm = {
    onReturn: (payload) => {
      let { uuid } = payload;
      if (this.MountDatumizo) {
        this.MountDatumizo.CustomInner("ImportConfirm", uuid, payload);
      }
      store.clearAsk();
    },
  };

  getQuickReplies = async (faqDocs) => {
    try {
      const data = {
        skip: 0,
        limit: 9999,
        fields: ["_id"],
      };
      const addOns = {
        workspace: "ALL",
        allIntents: true,
      };
      const rtn = await backendConnector.post(DATA_LINK.IntentList, data, addOns, { skipAudit: true });

      if (rtn.Success === true) {
        let docs = rtn.payload.docs;

        const intentsToHide = faqDocs.reduce((accu, curr) => {
          if (curr.disabled) accu.push(curr.intentRef);
          return accu;
        }, []);

        const filteredDocs = docs.filter((doc) => doc._id !== "" && !intentsToHide.includes(doc._id));
        let intents = _.map(filteredDocs, (o) => {
          let [workspace, alias] = o._id.split(":");

          return {
            _id: `CMD_BTN ${alias}`,
            caption: alias,
          };
        });

        this.setState((state, props) => ({
          intents: intents,
        }));
      } else {
        ErrorX.Handle(rtn);
      }
    } catch (e) {
      ErrorX.Handle(e);
    }
  };

  getAvailableDocs = async () => {
    const { payload } = await backendConnector.post(DATA_LINK.DMList, payload, {}, { skipAudit: true });
    const enabledDocuments = payload?.docs.filter((doc) => doc.enabled);
    const sortedDcouments = enabledDocuments.slice().sort((a, b) => b.reference.title.localeCompare(a.reference.title));
    this.setState({ documents: sortedDcouments });
  };

  getAvailableKnowledgeGroups = async () => {
    const { payload } = await backendConnector.post(DATA_LINK.KGList, {}, {}, { skipAudit: true });
    const enabledKnowledgeGroups = payload?.docs.filter((doc) => doc.enabled);
    this.setState({ knowledgeGroups: enabledKnowledgeGroups });
  };

  render() {
    const { base, serverSidePagination, title, intents, documents, knowledgeGroups } = this.state;
    return (
      <VStack>
        <Box padding={1} width="100%">
          <Typography
            style={{
              textAlign: "left",
              width: "100%",
              fontSize: 25,
              color: ColorX.GetColorCSS("Primary"),
            }}
          >
            {title}
          </Typography>
        </Box>
        <Datumizo
          onMounted={this.onMountDatumizo}
          base={base}
          serverSidePagination={serverSidePagination}
          addOns={{
            workspace: "master",
            intents,
            documents,
            knowledgeGroups,
          }}
          languageSelector={true}
          onDataChange={this.getQuickReplies}
        />
      </VStack>
    );
  }
}

export default observer(FAQ);
