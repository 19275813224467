import { NativeSelect } from "@mui/material";
import { CustomFilterValueProps, FieldType, Filterable } from "Components/LabIZO/Tablizo";
import { DocumentStatus } from "Components/_PageComponents/interfaces/document-manager-api.interface";

export const FILTERABLES: Filterable[] = [
  {
    field: "alias",
    label: "Document Name",
    type: FieldType.text,
  },
  {
    field: "knowledge_grps",
    label: "Knowledge Groups Name",
    keyName: "alias",
    type: FieldType.array,
  },
  {
    field: "status",
    label: "Status",
    type: FieldType.select,
    CustomFilterValue: ({ field, width, fieldState }: CustomFilterValueProps) => {
      return (
        <NativeSelect {...field} sx={{ width: width }} error={Boolean(fieldState?.error)}>
          <option value={""}></option>
          <option value={DocumentStatus.ERROR}>{DocumentStatus.ERROR}</option>
          <option value={DocumentStatus.PREPARING}>{DocumentStatus.PREPARING}</option>
          <option value={DocumentStatus.READY}>{DocumentStatus.READY}</option>
        </NativeSelect>
      );
    },
  },
];
