import { HStack, VStack } from "Components/Layouts";
import { Box, Button, Grid, Typography } from "@mui/material";
import { FileUpload } from "@mui/icons-material";
import { IDocDiff } from "../interfaces/document-diff.interface";
import { Legend } from "Pages/AuditLog/AuditLogDetail/Legend/Legend";
import { CompareDiff } from "./CompareDiff";

export const CompleteCompareDocDiff = ({
  newText,
  setfinishCompare,
  setCompare,
  setConfirm,
}: {
  newText: IDocDiff | null;
  setfinishCompare: (finishCompare: boolean) => void;
  setCompare: (upload: boolean) => void;
  setConfirm: (confirm: boolean) => void;
}) => {
  return (
    <VStack width={"80vw"} alignItems="flex-start">
      <Typography variant="body1" color="primary.main" style={{ font: "bold normal normal 20px/21px Segoe UI", marginBottom: "10px" }}>
        Compare Changes
      </Typography>
      <Legend />
      <Grid
        container
        spacing={1}
        sx={{
          width: "100%",
          alignItems: "flex-start",
          height: "50vh",
          marginTop: "10px",
        }}
      >
        {newText && <CompareDiff diff={newText.complete} action="removed" />}
        {newText && <CompareDiff diff={newText.complete} action="added" />}
        {/* Upload button Panel */}
        <Grid item xs={12} sm={12}>
          <HStack>
            <Box width={"100%"} sx={{ display: "flex", justifyContent: "flex-end" }}>
              <Box paddingRight={"10px"}>
                <Button
                  variant={"outlined"}
                  onClick={() => {
                    setfinishCompare(false);
                    setCompare(true);
                  }}
                >
                  Cancel
                </Button>
              </Box>
              <Button
                variant={"outlined"}
                onClick={() => {
                  setConfirm(true);
                }}
                startIcon={<FileUpload />}
                disabled={newText?.complete.length === 1}
              >
                Replace & Upload
              </Button>
            </Box>
          </HStack>
        </Grid>
      </Grid>
    </VStack>
  );
};
