import { DateTimeRangePicker, DateValuePiece } from "Components/DatePicker/DateTimeRangePicker";
import { Accessor } from "static";
import { IFDateTimeRange } from "../../FormizoSchema.interface";
import type { Moment } from "moment";

interface IFFDateTimeRangeProps {
  formValue: any;
  ischema: IFDateTimeRange;
  _onFieldFocus: () => void;
  _onFieldBlur: () => void;
  _onValueChange: (name: string, value: [Moment, Moment], criteria: any) => void;
  _onBlurInlineSubmit: (name: string) => void;
}

export const FFDateTimeRange: React.FC<IFFDateTimeRangeProps> = ({ formValue, ischema, _onFieldFocus, _onFieldBlur, _onBlurInlineSubmit, _onValueChange }) => {
  if (!ischema) return null;
  const ivalue = Accessor.Get(formValue, ischema.name);
  const defaultDates = ischema.valueGetter ? ischema.valueGetter(formValue, ivalue) : [null, null];
  const dates = ivalue || defaultDates;

  const handleDateChange = (newDates: [DateValuePiece, DateValuePiece], _: [string, string]) => {
    if (newDates && newDates[0] && newDates[1]) {
      _onValueChange(ischema.name, [newDates[0], newDates[1]], ischema.validate);
    }
  };

  return (
    <DateTimeRangePicker
      dates={dates}
      onChangeDates={handleDateChange}
      minDate={ischema.minDate}
      maxDate={ischema.maxDate}
      limitSelectedPeriod={ischema.limitSelectedPeriod}
      helperText={ischema.helperText}
      dateFormat={ischema.dateFormat}
      handleFocus={(_: any) => {
        _onFieldFocus();
      }}
      handleBlur={(_: any) => {
        _onFieldBlur();
        _onBlurInlineSubmit(ischema.name);
      }}
    />
  );
};
