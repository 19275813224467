import { useState } from "react";
import Dropzone from "react-dropzone";
import { VStack } from "Components/LabIZO/Stackizo";
import { CloudUploadOutlined } from "@mui/icons-material";

import "./Dropzone.css";
//https://react-dropzone.js.org/#section-basic-example

const ImportDropzone = ({
  //
  onDrop = (acceptedFiles) => console.log(acceptedFiles),
  accept = [".gz"],
  mode = "",
}) => {
  const [acceptedFiles, setAcceptedFiles] = useState(null);
  const [over, setOver] = useState(false);

  const _onDrop = (acceptedFiles) => {
    acceptedFiles && setAcceptedFiles(acceptedFiles);
    onDrop(acceptedFiles);
  };

  accept = mode === "userBnR" ? [".enc"] : [".gz"];

  return (
    <Dropzone onDrop={_onDrop} accept={accept} onDragEnter={() => setOver(true)} onDragLeave={() => setOver(false)}>
      {({ getRootProps, getInputProps }) => (
        <VStack className="Dropzone">
          <div {...getRootProps({ className: "inner " + (over ? "over" : "") })}>
            <CloudUploadOutlined fontSize="large" />
            <input {...getInputProps()} />
            <p>Drag and drop some files here, or click to select files</p>
            <em>{`(Only ${accept} will be accepted)`}</em>
          </div>
          <aside>
            <ul>{acceptedFiles && acceptedFiles.map((file) => <li key={file.path}>{file.path}</li>)}</ul>
          </aside>
        </VStack>
      )}
    </Dropzone>
  );
};

export default ImportDropzone;
