import { TCustomValidation, TValidationType } from "./TValidationType";
import { ValidationError } from "./ValidationError";
import { ValidationRules } from "./ValidationRules";

export function validate<T = any>(ruleNames: (TCustomValidation | TValidationType)[], value: T): { error: boolean; message?: ValidationError | string } {
  for (const ruleName of ruleNames) {
    if (typeof ruleName === "function") {
      return ruleName(value);
    }

    const checkValid = ValidationRules[ruleName];
    if (!checkValid) {
      throw new Error(`Validation rule ${ruleName} does not exist`);
    }
    const result = checkValid(value);
    if (!result.valid) {
      return {
        error: true,
        message: result.message,
      };
    }
  }

  return { error: false };
}
