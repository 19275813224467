import { IDocumentManager } from "../../interfaces/document-manager-api.interface";
import { useState } from "react";
import { VStack } from "Components/Layouts";
import { FieldErrors, FormProvider, useForm } from "react-hook-form";
import { DocumentManagerFormContext } from "../hooks/contexts-hook";
import { ErrorSummary } from "Pages/FLE/components";
import { DATA_LINK } from "../../../../connectors/datalink";
import { ErrorX, store } from "static";
import { backendConnector } from "connectors/backend-connector";
import { Loading } from "Components/Loading";
import DocumentReupload from "./DocumentReupload";
import { Typography } from "@mui/material";
import { RelationType } from "interfaces/db.interfaces/gpt-qa-document.interface";

interface IProps {
  id: string;
  row?: IDocumentManager;
  onQuit: () => void;
  onQuitRefresh: (_id: string) => void;
  renderFormizo: (schema: any, buttons: any, onSubmit: any) => JSX.Element;
  mode?: "Add" | "Edit" | "Info";
}
export default function DocumentReuploadForm({ id, row, onQuit, onQuitRefresh, renderFormizo, mode }: IProps) {
  const methods = useForm<IDocumentManager>({ defaultValues: row, mode: "all" });
  const [errors, setErrors] = useState<FieldErrors<IDocumentManager> | null>(null);
  const [loading, setLoading] = useState<boolean>(false);

  const actionMode = mode || "Info";

  const disabled = row?.documentLink && row?.documentLink.type != RelationType.Translate && row?.documentLink.parentId !== row._id;

  const handleSubmit = (quitForm: boolean = true) => {
    const submitStatus = {
      valid: async (_newRow: IDocumentManager) => {
        setErrors(null);
        setLoading(true);
        console.log(`Valid Submit: ${JSON.stringify(_newRow)}`);

        try {
          const res = await backendConnector.post(DATA_LINK.DMEdit, _newRow);
          if (!res.Success) {
            store.Alert("Document Edit Failed: " + res.payload?.message, "error");
            return ErrorX.Handle(res.payload);
          }
          store.Alert("Document Edited Successfully", "success");

          if (quitForm) onQuitRefresh(_newRow._id);
        } catch (err: any) {
          ErrorX.Handle(err);
        }
        setLoading(false);
      },
      invalid: (errors: FieldErrors<IDocumentManager>) => {
        setErrors(errors);
        setLoading(false);
      },
    };

    methods.handleSubmit(submitStatus.valid, submitStatus.invalid)();
  };
  if (disabled)
    return (
      <VStack sx={{ pt: 3 }} width={"100%"}>
        <Typography>Document is linked to another document, re-upload is disabled.</Typography>
      </VStack>
    );

  return (
    <VStack sx={{ pt: 3 }} width={"100%"}>
      <Loading open={loading} />
      {errors && <ErrorSummary errors={errors} />}
      <FormProvider {...methods}>
        <DocumentManagerFormContext.Provider value={{ mode: actionMode }}>
          <DocumentReupload mode={actionMode} onSubmit={handleSubmit} onQuitRefresh={onQuitRefresh} />
        </DocumentManagerFormContext.Provider>
      </FormProvider>
    </VStack>
  );
}
