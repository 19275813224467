import { Box, TextField } from "@mui/material";
import { FLEOperator, ISystemContextNumeric } from "interfaces/db.interfaces/fle.interface";
import { BodyTitle } from "Components";
import { DropDownSelector } from "Components/DropDownSelector";
import { AddButton, DeleteButton } from "Components/IconButtons";
import { HStack, VStack } from "Components/Layouts";
import { ITypeSettingProps } from "./ITypeSettingProps.interface";

export const NumericSetting = ({ setting, onChange, ...props }: ITypeSettingProps<ISystemContextNumeric>) => (
  <VStack width={"100%"} alignItems={"flex-start"}>
    <BodyTitle>Numeric Values</BodyTitle>
    <HStack>
      <>
        {setting.values &&
          setting.values.map((sVal, svInx) => {
            return (
              <HStack key={svInx} sx={{ maxWidth: "768px" }}>
                <DropDownSelector
                  key={svInx}
                  value={sVal.operator}
                  options={[
                    { label: "Equals", value: FLEOperator.eq },
                    { label: "Greater Than", value: FLEOperator.gt },
                    { label: "Less Than", value: FLEOperator.lt },
                  ]}
                  handleChange={(value) => {
                    const { values } = setting;
                    if (values) {
                      values[svInx].operator = value as FLEOperator;
                      onChange({ ...setting, values });
                    }
                  }}
                  {...props}
                />
                <Box sx={{ width: "70%" }}>
                  <TextField
                    variant="filled"
                    type="number"
                    value={sVal.value}
                    label={""}
                    onChange={(e) => {
                      const { values } = setting;
                      if (values) {
                        values[svInx].value = Number(e.target.value);
                        onChange({ ...setting, values });
                      }
                    }}
                    {...props}
                  />
                </Box>

                <DeleteButton
                  onClick={() => {
                    setting.values && setting.values.splice(svInx, 1);
                    onChange({ ...setting });
                  }}
                  {...props}
                />
              </HStack>
            );
          })}
      </>
      <AddButton
        onClick={() => {
          let { values } = setting;
          if (!values) values = [];

          values.push({ value: 0, operator: FLEOperator.eq });
          onChange({ ...setting, values });
        }}
        {...props}
      />
    </HStack>
  </VStack>
);
