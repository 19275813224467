import { Check } from "@mui/icons-material";
import { Autocomplete, SelectChangeEvent, TextField, MenuItem, FormControl, FormHelperText } from "@mui/material";
import { TFMultiSelect } from "Components/LabIZO/Formizo/FormizoSchema.interface";
import _ from "lodash";
import * as React from "react";
import { Accessor } from "static";
import { getOptions } from "./getOptions";

interface IFFAdvancedMultiSelectProps {
  ifieldStyle?: string;
  _onFieldFocus?: () => void;
  _onFieldBlur?: () => void;
  ischema: TFMultiSelect;
  _onValueChange?: (name: string, value: string[], criteria: any) => void;
  addOns?: any;
  iname: string;
  formError: any;
  _onBlurInlineSubmit?: any;
  errorsShowOnHelperText?: boolean;
  readOnly?: boolean;
  _Validate?: any;
  formValue?: any;
}

export const FFAdvancedMultiSelect: React.FC<IFFAdvancedMultiSelectProps> = (props) => {
  const [selectedNames, setSelectedNames] = React.useState<string[]>(Accessor.Get(props.formValue, props.iname) || []);

  const options = getOptions(props.ischema, props.addOns);

  const handleChange = (
    event: React.SyntheticEvent<any>,
    value: {
      value: string;
      label: string;
    }[]
  ) => {
    const valArr = value.map((v) => v.value);
    setSelectedNames(valArr);
    if (props._onValueChange && props.ischema.name) {
      props._Validate && props._Validate(props.ischema.name, valArr, props.ischema.validate);
      props._onValueChange(props.ischema.name, valArr, {});
    }
  };

  React.useEffect(() => {
    const { ischema, iname, _Validate, formValue } = props;
    const ivalue = Accessor.Get(formValue, iname);

    if (!_.isEmpty(ischema.validate)) {
      _Validate(iname, ivalue, ischema.validate);
    }
  }, [props.formError, Accessor.Get(props.formValue, props.iname)]);

  React.useEffect(() => {
    setSelectedNames(Accessor.Get(props.formValue, props.iname) || []);
  }, [Accessor.Get(props.formValue, props.iname)]);

  let { ischema, iname, formError, ifieldStyle, _onBlurInlineSubmit, _onFieldFocus, _onFieldBlur, errorsShowOnHelperText, readOnly } = props;
  if (!ischema) return null;

  let ierror = Accessor.Get(formError, iname);
  const ireadOnly = ischema.readOnly || readOnly;

  let helperText = ischema.helperText as string;
  if (!ireadOnly && errorsShowOnHelperText) {
    helperText = ierror;
  }

  const placeholderText: string = ischema.placeholder ?? "Search";

  return (
    <FormControl sx={{ m: 1, width: "100%" }} error={!_.isEmpty(ierror)} disabled={ireadOnly as boolean}>
      <Autocomplete
        sx={{ m: 1 }}
        multiple
        value={selectedNames?.map((_v) => {
          return _.find(options, { value: _v }) || { value: _v, label: _v };
        })}
        options={options ?? []}
        getOptionLabel={(option) => option.label}
        disableCloseOnSelect
        isOptionEqualToValue={(option, value) => option.value === value.value}
        renderInput={(params) => {
          return <TextField {...params} variant="outlined" placeholder={placeholderText} />;
        }}
        renderOption={(_props, _opt, { selected }) => {
          return (
            <MenuItem
              {..._props}
              key={_opt.value}
              value={_opt.value}
              disabled={_opt.disabled}
              sx={{ justifyContent: "space-between", width: "100%", whiteSpace: "pre-line", overflowWrap: "break-word" }}
            >
              {_opt.label}
              {selected ? <Check color="info" /> : null}
            </MenuItem>
          );
        }}
        onChange={handleChange}
      />
      {!_.isEmpty(helperText) && <FormHelperText>{helperText}</FormHelperText>}
    </FormControl>
  );
};
