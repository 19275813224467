import { Typography } from "@mui/material";
import { ArrowRight } from "@mui/icons-material";
import { HMarquee } from "Components/LabIZO/Animatizo";
import { HStack, Spacer, VStack } from "Components/LabIZO/Stackizo";
import { ColorX } from "static";
import _ from "lodash";
import { TDetectedObj } from "interfaces";
import { IConvLog } from "interfaces/db.interfaces/conv-log.interface";

interface ConvlogNLPProps {
  doc: IConvLog;
  intents: any;
  renderHeader: (label: string, width?: number) => JSX.Element;
  renderField: (label: string, value: any, width?: number, fontSize?: number, children?: JSX.Element) => JSX.Element;
}

export function ConvlogNLP({ doc, intents, renderHeader, renderField }: ConvlogNLPProps) {
  const indexColor = (idx: number) => {
    let bgColor = ColorX.GetColorCSS("Primary");
    if (idx % 2 === 1) {
      bgColor = ColorX.GetColorCSS("Secondary");
    }
    return bgColor;
  };
  const renderNoData = (msg: string) => {
    return (
      <HStack>
        <Typography>{msg}</Typography>
        <Spacer />
      </HStack>
    );
  };
  const renderDetectedSemanticBlock = (iSemantic: TDetectedObj, idx: number) => {
    const bgColor = indexColor(idx);
    const textColor = indexColor(idx + 1);
    const { name, pattern, value } = iSemantic;
    let ivalue = value;
    if (!_.isString(ivalue)) {
      try {
        ivalue = JSON.stringify(ivalue);
      } catch {
        ivalue = ivalue.toString();
      }
    }
    return (
      <HStack style={{ background: bgColor }} key={idx}>
        <VStack alignItems="flex-start" padding={1} style={{ width: 300 }}>
          <Typography style={{ color: textColor, fontSize: 14, fontWeight: "bold" }}>{pattern}</Typography>
          <Spacer />
          <HStack justifyContent="flex-start" gap={2}>
            <Typography style={{ color: textColor, fontSize: 9, width: "30%" }}>{"Semantic".toUpperCase()}</Typography>
            <HMarquee width="70%">
              <Typography style={{ color: textColor, fontSize: 9, overflow: "visible" }} noWrap>
                {name}
              </Typography>
            </HMarquee>
          </HStack>
          <HStack justifyContent="flex-start" gap={2}>
            <Typography style={{ color: textColor, fontSize: 9, width: "30%" }}>{"Value".toUpperCase()}</Typography>
            <HMarquee width="70%">
              <Typography style={{ color: textColor, fontSize: 9, overflow: "visible" }} noWrap>
                {ivalue}
              </Typography>
            </HMarquee>
          </HStack>
        </VStack>
      </HStack>
    );
  };

  const renderDetectedSemantics = (doc: IConvLog) => {
    const semantics = doc.Detect.Sementics ?? [];
    return (
      <VStack minHeight={250}>
        {renderHeader("Detected Semantics", 300)}
        <VStack style={{ maxHeight: 300, overflow: "auto", width: "100%", justifyContent: "flex-start" }}>
          {semantics.length > 0
            ? _.map(semantics, (o, i) => {
                return renderDetectedSemanticBlock(o, i);
              })
            : renderNoData("No Semantic Phrase Detected.")}
        </VStack>
      </VStack>
    );
  };

  const renderDetectedEntityBlock = (iEntity: TDetectedObj, idx: number) => {
    const bgColor = indexColor(idx);
    const textColor = indexColor(idx + 1);
    const { name, pattern, value } = iEntity;
    let ivalue = value;
    if (!_.isString(ivalue)) {
      try {
        ivalue = JSON.stringify(ivalue);
      } catch {
        ivalue = ivalue.toString();
      }
    }
    return (
      <HStack style={{ background: bgColor }} key={idx}>
        <VStack alignItems="flex-start" padding={1} style={{ width: 300, color: textColor }}>
          <Typography style={{ fontSize: 14, fontWeight: "bold" }}>{pattern}</Typography>
          <Spacer />
          <HStack justifyContent="flex-start" gap={2}>
            <Typography style={{ fontSize: 9, width: "30%" }}>{"Entity".toUpperCase()}</Typography>
            <HMarquee width="70%">
              <Typography style={{ fontSize: 9, overflow: "visible" }} noWrap>
                {name.toString()}
              </Typography>
            </HMarquee>
          </HStack>
          <HStack justifyContent="flex-start" gap={2}>
            <Typography style={{ fontSize: 9, width: "30%" }}>{"Value".toUpperCase()}</Typography>
            <HMarquee width="70%">
              <Typography style={{ fontSize: 9, overflow: "visible" }} noWrap>
                {ivalue}
              </Typography>
            </HMarquee>
          </HStack>
        </VStack>
      </HStack>
    );
  };

  const renderDetectedEntities = (doc: IConvLog) => {
    const entities = doc.Detect.Entities ?? [];
    return (
      <VStack minHeight={250} sx={{ alignItems: "flex-start" }}>
        {renderHeader("Detected Keywords", 300)}
        <VStack sx={{ maxHeight: 200, overflow: "auto", width: "100%", justifyContent: "flex-start" }}>
          {entities.length > 0
            ? _.map(entities, (o, i) => {
                return renderDetectedEntityBlock(o, i);
              })
            : renderNoData("No Keywords Detected.")}
        </VStack>
      </VStack>
    );
  };

  const renderNodeBlock = (node: string, idx: number) => {
    const bgColor = indexColor(idx);
    const textColor = indexColor(idx + 1);
    return (
      <HStack style={{ background: bgColor }} key={idx}>
        <VStack alignItems="flex-start" padding={1} style={{ width: 300 }}>
          <HStack>
            <ArrowRight />
            <HMarquee>
              <Typography style={{ fontSize: 14, fontWeight: "bold", color: textColor }}>{node}</Typography>
            </HMarquee>
          </HStack>
          <Spacer />
        </VStack>
      </HStack>
    );
  };

  const renderNodesVisited = (doc: IConvLog) => {
    const nodes = doc.wsRes?.[doc.ws]?.output?.nodes_visited ?? [];
    return (
      <VStack minHeight={250}>
        {renderHeader("Nodes Visited", 300)}
        <VStack style={{ maxHeight: 300, overflow: "auto", width: "100%", justifyContent: "flex-start" }}>
          {nodes.length > 0
            ? _.map(nodes, (o, i) => {
                return renderNodeBlock(o, i);
              })
            : renderNoData("No Node Visited.")}
        </VStack>
      </VStack>
    );
  };

  const renderEntityBlock = (iEntity: { entity: string; location: number[]; value: string; confidence?: number }, input: string, idx: number) => {
    const bgColor = indexColor(idx);
    const { entity, location, value, confidence } = iEntity;
    let ivalue = value;
    if (!_.isString(ivalue)) {
      try {
        ivalue = JSON.stringify(ivalue);
      } catch {
        ivalue = ivalue.toString();
      }
    }
    return (
      <HStack style={{ background: bgColor }} key={idx}>
        <VStack alignItems="flex-start" padding={1} style={{ width: 300 }}>
          <Typography style={{ fontSize: 14, fontWeight: "bold" }}>{input && input.substring(location[0], location[1])}</Typography>
          <Spacer />
          <HStack justifyContent="flex-start" gap={2}>
            <Typography style={{ fontSize: 9, width: "30%" }}>{"Entity".toUpperCase()}</Typography>
            <HMarquee width="70%">
              <Typography style={{ fontSize: 9, overflow: "visible" }} noWrap>
                {entity}
              </Typography>
            </HMarquee>
          </HStack>
          <HStack justifyContent="flex-start" gap={2}>
            <Typography style={{ fontSize: 9, width: "30%" }}>{"Value".toUpperCase()}</Typography>
            <HMarquee width="70%">
              <Typography style={{ fontSize: 9, overflow: "visible" }} noWrap>
                {ivalue}
              </Typography>
            </HMarquee>
          </HStack>
          <HStack justifyContent="flex-start" gap={2}>
            <Typography style={{ fontSize: 9, width: "30%" }}>{"Confidence".toUpperCase()}</Typography>
            <HMarquee width="70%">
              <Typography style={{ fontSize: 9 }}>{confidence}</Typography>
            </HMarquee>
          </HStack>
        </VStack>
      </HStack>
    );
  };

  const renderEntities = (doc: IConvLog) => {
    const entities = doc.wsRes?.[doc.ws]?.entities ?? [];
    return (
      <VStack minHeight={250}>
        {renderHeader("Detected Entities", 300)}
        <VStack style={{ maxHeight: 300, overflow: "auto", width: "100%", justifyContent: "flex-start" }}>
          {entities.length > 0
            ? _.map(entities, (o, i) => {
                return renderEntityBlock(o, doc.Input.Content, i);
              })
            : renderNoData("No Entities Detected.")}
        </VStack>
      </VStack>
    );
  };

  const renderIntentBlock = (intent: { intent: string; confidence: number }, idx: number) => {
    const bgColor = indexColor(idx);
    const textColor = indexColor(idx + 1);
    let that = intents.find((o: any) => o.caption === intent.intent);
    return (
      <HStack style={{ background: bgColor }} key={idx}>
        <VStack alignItems="flex-start" padding={1} style={{ width: 300 }}>
          <Typography style={{ color: textColor, fontSize: 14, fontWeight: "bold" }}>{"#" + intent.intent}</Typography>
          <Spacer />
          <HStack justifyContent="flex-start" gap={2}>
            <Typography style={{ color: textColor, fontSize: 9, width: "30%" }}>{"Description".toUpperCase()}</Typography>
            <HMarquee width="70%">
              <Typography style={{ color: textColor, fontSize: 9, overflow: "visible" }} noWrap>
                {(that && that.Description) || "Intent not found."}
              </Typography>
            </HMarquee>
          </HStack>
          <HStack justifyContent="flex-start" gap={2}>
            <Typography style={{ color: textColor, fontSize: 9, width: "30%" }}>{"Confidence".toUpperCase()}</Typography>
            <HMarquee width="70%">
              <Typography style={{ color: textColor, fontSize: 9 }}>{intent.confidence}</Typography>
            </HMarquee>
          </HStack>
        </VStack>
      </HStack>
    );
  };

  const renderIntentsInner = (intents: { intent: string; confidence: number }[]) => {
    if (intents.length <= 0) {
      return renderNoData("No Intents Detected.");
    }

    let rtn = [];
    for (let i = 0; i < intents.length; i++) {
      rtn.push(renderIntentBlock(intents[i], i));
    }

    return rtn;
  };

  const renderIntents = (doc: IConvLog) => {
    const intents = doc.wsRes?.[doc.ws]?.intents ?? [];
    return (
      <VStack>
        {renderHeader("NLP Intents", 300)}
        <VStack style={{ maxHeight: 500, overflow: "auto", width: "100%" }}>{renderIntentsInner(intents)}</VStack>
      </VStack>
    );
  };

  return (
    <HStack justifyContent="flex-start" gap={2} alignItems="flex-start">
      {renderIntents(doc)}
      <VStack gap={2}>
        {renderEntities(doc)}
        {renderNodesVisited(doc)}
      </VStack>
      <VStack gap={2}>
        {renderDetectedEntities(doc)}
        {renderDetectedSemantics(doc)}
      </VStack>
    </HStack>
  );
}
