import { store, ErrorX, Authority } from "static";
import Formizo from "Components/LabIZO/Formizo";
import schema from "./schema";
import { useState, useEffect } from "react";
import _ from "lodash";
import { HStack } from "Components/LabIZO/Stackizo";
import { Box } from "@mui/system";
import { Accordion, AccordionDetails, AccordionSummary, Typography } from "@mui/material";
import { ExpandMore } from "@mui/icons-material";
import { DATA_LINK, backendConnector } from "connectors";
import { ITalk2VAAddOns } from "Pages/CombinedTalk2VA/interfaces/combined-talk2va-addons.interface";

interface IIntentObj {
  _id: String;
  caption: String;
}
interface SuggestedIntentProps {
  addOns: ITalk2VAAddOns;
  reqAuth?: string;
  reqFunc?: string;
  reqLevel?: number;
}

const SuggestedIntent = ({ addOns, reqAuth, reqFunc, reqLevel }: SuggestedIntentProps) => {
  const isAccessible = Authority.IsAccessibleQ(reqAuth, reqLevel, reqFunc);

  const [intents, setIntents] = useState<IIntentObj[]>([]);

  let formRef: any = null;

  const setFormRef = (ref: any) => {
    formRef = ref;
  };

  useEffect(() => {
    const getAllIntents = async () => {
      try {
        let rtn = await backendConnector.post(
          DATA_LINK.IntentList,
          {
            skip: 0,
            limit: 9999,
            fields: ["_id"],
          },
          {
            workspace: "ALL",
            allIntents: true,
          }
        );
        if (rtn.Success === true) {
          const docs = rtn.payload.docs;
          let intents = _.map(docs, (o: any, i: any) => {
            let splited = o._id.split(":");
            let caption = o._id;
            if (splited.length === 2) {
              caption = splited[1];
            }
            return {
              _id: o._id,
              caption: caption,
            };
          });

          setIntents(intents);
        } else {
          ErrorX.Handle(rtn.payload);
        }
      } catch (e: any) {
        ErrorX.Handle(e);
      }
    };
    getAllIntents();
  }, []);
  if (!Authority.IsAccessibleQ(reqAuth, reqLevel, reqFunc)) return <></>;

  const LogRecord = async (data: { remarks: string; suggested: string }) => {
    const record = addOns?.payload;
    const env = await getEnv();
    const payload = {
      env: env,
      input: record?.input?.content,
      intent: record?.intent,
      language: record?.language,
      comment: {
        suggest_intent: data.suggested,
        remarks: data.remarks,
      },
      response: {
        current: {
          ...record,
        },
      },
    };
    try {
      const rtn = await backendConnector.post(DATA_LINK.Talk2VALog, payload);
      if (rtn.Success) {
        store.Alert("Record Updated!", "success");
      } else {
        ErrorX.Handle(rtn.payload);
      }
    } catch (e: any) {
      ErrorX.Handle(e);
    }
  };

  const getEnv = async () => {
    try {
      const rtn = await backendConnector.post(DATA_LINK.MiddlewareEnv, {});
      return rtn.payload.current;
    } catch (e) {
      console.error(e);
    }
  };

  return isAccessible ? (
    <Accordion sx={{ width: "100%", justifyContent: "flex-start", flexShrink: 0 }}>
      <AccordionSummary expandIcon={<ExpandMore />}>
        <HStack>
          <Typography variant="button">Suggested Intent</Typography>
        </HStack>
      </AccordionSummary>
      <AccordionDetails>
        <Box>
          <Formizo
            ref={setFormRef}
            formID={"suggest_intent"}
            schema={schema}
            readOnly={addOns?.payload?.input?.content?.startsWith("CMD")}
            buttons={["Clear", "Submit"]}
            addOns={{ intents, input: addOns?.payload?.input?.content || "" }}
            fieldSize={"small"}
            onSubmit={(data) => {
              LogRecord(data);
              if (formRef) {
                formRef._onClear();
              }
            }}
            onInvalid={() => {
              store.Alert("Provided information is invalid, no action taken.", "warning");
            }}
          ></Formizo>
        </Box>
      </AccordionDetails>
    </Accordion>
  ) : (
    <></>
  );
};

export default SuggestedIntent;
