import { IDocumentManager } from "../../../interfaces/document-manager-api.interface";
import { HStack, VStack } from "Components/Layouts";
import { useFormContext } from "react-hook-form";
import { Box, Button, Grid, Typography, Alert } from "@mui/material";
import FormMDEditor from "Components/FormMDEditor/FormMDEditor";
import { Legend } from "Pages/AuditLog/AuditLogDetail/Legend/Legend";
import { useText } from "../../hooks/useText";
import { CompareDiff } from "./CompareDiff";
import { IDocDiff } from "../interfaces/document-diff.interface";
import React from "react";
import { Loading } from "Components/Loading";

export const ManuallyCompareDocDiff = ({
  newText,
  newFile,
  onSubmit,
  setfinishCompare,
  setCompare,
}: {
  newText: IDocDiff | null;
  newFile: { file: File; addOns: any } | null;
  onSubmit: (quitForm?: boolean) => void;
  setfinishCompare: (finishCompare: boolean) => void;
  setCompare: (upload: boolean) => void;
}) => {
  const { setValue, getValues, control } = useFormContext<IDocumentManager>();
  const docId = getValues("_id");
  const { text, setText } = useText(docId);
  const [alert, setAlert] = React.useState<{ message: string; type: "success" | "error" } | null>(null);
  const [loading, setLoading] = React.useState(false);
  const [textOnChange, setTextOnChange] = React.useState(false);

  const onChangeContext = (_newContext: string | undefined) => {
    setTextOnChange(true);
    setText(_newContext);
    setValue("context", { text: _newContext });
  };

  const handleManualSubmit = async () => {
    console.log("Confirm Manually Save Changes");
    setLoading(true);
    try {
      onSubmit(true); // call DMEdit api
      setfinishCompare(true);
      setAlert({ message: "Manually Save Changes", type: "success" });
    } catch (e: any) {
      console.error(e);
      setAlert({ message: JSON.stringify(e?.message ?? e), type: "error" });
      setfinishCompare(false);
    }
  };

  return (
    <VStack width={"80vw"} alignItems="flex-start">
      <Loading open={loading} />
      <Typography variant="body1" color="primary.main" style={{ font: "bold normal normal 20px/21px Segoe UI", marginBottom: "10px" }}>
        Compare Changes
      </Typography>
      <Legend />
      <Grid
        container
        spacing={1}
        sx={{
          width: "100%",
          alignItems: "flex-start",
          height: "60vh",
          marginTop: "10px",
        }}
      >
        {/* Changes and edit */}
        {newText && <CompareDiff diff={newText.manual} />}
        {newText && (
          <Grid item xs={12} sm={6} sx={{ width: "100%", height: "95%" }}>
            <Typography variant="body1" color="primary.main" style={{ font: "bold normal normal 16px/21px Segoe UI", marginBottom: "10px" }}>
              Edit Changes
            </Typography>
            <FormMDEditor value={text} onChange={onChangeContext} allowEdit={false} />
          </Grid>
        )}

        {/* Action Buttons */}
        <Grid item xs={12} sm={12}>
          <HStack>
            <Box width={"100%"} sx={{ display: "flex", justifyContent: "flex-end" }}>
              <Box paddingRight={"10px"}>
                <Button
                  variant={"outlined"}
                  onClick={() => {
                    setfinishCompare(false);
                    setCompare(true);
                  }}
                >
                  Cancel
                </Button>
              </Box>
              <Button variant={"outlined"} onClick={() => handleManualSubmit()} disabled={newText?.manual.length === 1 || !textOnChange}>
                Save Changes
              </Button>
            </Box>
          </HStack>
        </Grid>
        {/* Alert */}
        {alert && (
          <Alert sx={{ width: "100%" }} severity={alert.type}>
            {alert.message}
          </Alert>
        )}
      </Grid>
    </VStack>
  );
};
