import { useState } from "react";
import { Button } from "@mui/material";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";

import DialogTitle from "@mui/material/DialogTitle";

const Popup = (props) => {
  const { row, open, onClose, onSubmit, title = "Edit Tag" } = props;
  const [tag, setTag] = useState("");

  const handleInput = (input) => {
    input && setTag(input);
  };
  return (
    <Dialog open={open ? true : false} onClose={onClose} aria-labelledby="form-dialog-title">
      <DialogTitle id="tag title">{title}</DialogTitle>

      <DialogActions>
        <TextField autoFocus margin="dense" id={"tagger"} label={"Tags"} fullWidth onInput={(e) => handleInput(e.target.value)} />
        {/* Action buttons */}
        <Button onClick={onClose} color="error">
          Cancel
        </Button>
        <Button
          color="success"
          disabled={!tag}
          onClick={() => {
            row.tag = tag;
            onSubmit(row);
          }}
          type="submit"
        >
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const TagForm = ({ open, onClose, onSubmit, row, title }) => {
  return <Popup title={title} row={row} open={open} onClose={onClose} onSubmit={onSubmit} />;
};

export default TagForm;
