import { Grid } from "@mui/material";
import { DBAnswerSpec } from "interfaces";
import { ContextId } from "Pages/FLE/components";
import { useFormContext } from "react-hook-form";
import { VStack } from "Components/Layouts";
import { IFLEDoc } from "interfaces/db.interfaces/fle.interface";

interface IProps {
  disabled?: boolean;
}

export function ContextsTable({ ...props }: IProps) {
  const { control, watch } = useFormContext<IFLEDoc<DBAnswerSpec>>();
  const contexts = watch("contexts");
  if (!contexts) return <></>;
  const styledGrid = {
    paddingLeft: 12,
  };
  return (
    <VStack sx={{ width: "100%" }}>
      {contexts.map((context, _i) => {
        return (
          <Grid container key={`context-${_i}`} >
            <Grid sx={styledGrid}>
              <ContextId name={`contexts.${_i}.alias.id`} />
            </Grid>
            <Grid sx={styledGrid}>
              {" "}
              {context.type}
            </Grid>
            <Grid sx={styledGrid}>
              {context.setting.type == "entity" && context.setting.values.map((o) => o.entity).join(", ")}
              {context.setting.type != "entity" && context.setting.type}
            </Grid>
            <Grid sx={styledGrid}>
              {context.question["EN"]}
            </Grid>
          </Grid>
        );
      })}
    </VStack>
  );
}
