import { SwitchButton } from "Components/SwitchButton/SwitchButton";
import { TablizoCustom } from "Components/LabIZO/Tablizo";
import { DATA_LINK } from "connectors";
export const TABLE_SCHEMA: TablizoCustom = (tbLang) => [
  {
    label: "Enabled",
    name: "enabled",
    width: 100,
    reqAuth: "GPTQA.KnowledgeGroupManagement",
    reqLevel: 999,
    reqFunc: "Enable",
    Cell(row, field, addOns, fetchData) {
      return <SwitchButton defaultValue={field} URL={DATA_LINK.KGEnable} row={row} fetchData={fetchData} />;
    },
  },
  {
    label: "Knowledge Group Name",
    name: "alias",
    filterable: true,
    defaultSort: "desc",
  },
  {
    label: "Chosen Documents",
    name: "chosenDocs",
    // Cell(row, field, addOns) {
    //   return <div>{row?.docs?.map((item: { alias: any }) => item?.alias).join(", ")}</div>;
    // },
    valueGetter(row) {
      return row?.docs?.map((item: { alias: any }) => item?.alias).join(", ");
    },
    filterable: true,
  },
  {
    label: "Enabled Documents",
    name: "enabledDocs",
    // Cell(row, field, addOns) {
    //   let enabledDocs = row?.docs?.filter((item: { enabled: boolean }) => item?.enabled === true);
    //   return <div>{enabledDocs?.map((item: { alias: any; enabled: boolean }) => item?.alias).join(", ")}</div>;
    // },
    valueGetter(row) {
      let enabledDocs = row?.docs?.filter((item: { enabled: boolean }) => item?.enabled === true);
      return enabledDocs?.map((item: { alias: any; enabled: boolean }) => item?.alias).join(", ");
    },
    filterable: true,
  },
  {
    label: "Created At",
    name: "CREATED_AT",
    filterable: true,
  },
  {
    label: "Last Modified Time",
    name: "LASTMODIFIED",
    filterable: true,
  },
];
