import { TextField, Typography } from "@mui/material";
import { DBAnswerSpec } from "interfaces";
import { IFLEDoc } from "interfaces/db.interfaces/fle.interface";
import { ParamsTable } from "./ParamsTable";
import { Controller, useFormContext } from "react-hook-form";
import { RadioButtonsGroup } from "Components";
import { ControlledTextField } from "Components/HookForm/ControlledTextField";
import HStack from "Components/Layouts/HStack";
import VStack from "Components/Layouts/VStack";
import { useState } from "react";
interface IProps {
  disabled?: boolean;
}
export function APIEndpointConfig({ ...props }: IProps) {
  const { control, getValues } = useFormContext<IFLEDoc<DBAnswerSpec>>();
  const method = getValues("integrations.api.method");
  const [apiMethod, setApiMethod] = useState<"GET" | "POST">(method);
  return (
    <Controller
      name="integrations.api"
      control={control}
      render={({ field: { value: apiConfig, onChange: onChangeAPIConfig } }) => {
        if (!apiConfig) return <></>;
        return (
          <VStack width={"100%"}>
            <RadioButtonsGroup
              label="Method"
              value={apiConfig.method}
              options={[
                { label: "GET", value: "GET" },
                { label: "POST", value: "POST" },
              ]}
              disabled={props.disabled}
              onChange={(value) => {
                apiConfig.method = value as "GET" | "POST";
                onChangeAPIConfig(apiConfig);
                setApiMethod(value as "GET" | "POST");
              }}
            ></RadioButtonsGroup>
            <HStack>
              <Typography sx={{ color: "primary", minWidth: "10%" }}>API Path</Typography>
              <TextField
                value={apiConfig.url}
                onChange={(e) => {
                  apiConfig.url = e.target.value;
                  onChangeAPIConfig(apiConfig);
                }}
                disabled={props.disabled}
                fullWidth
                variant="outlined"
              />
            </HStack>
            {method == "POST" && (
              <HStack>
                <Typography sx={{ color: "primary", minWidth: "10%" }}>Body</Typography>
                <ParamsTable disabled={props.disabled} />
              </HStack>
            )}
          </VStack>
        );
      }}
    />
  );
}
