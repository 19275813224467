import MenuButton from "../MenuButton/MenuButton";
import { VStack } from "Components/Layouts";
import { ClassicMenuLayoutProps } from "../ClassicLayout/ClassicLayout";
import schema from "../constants/starter-menu-schema";
import { observer } from "mobx-react-lite";
import { styled } from "@mui/material";

type StarterMenuLayoutProps = ClassicMenuLayoutProps;

const StyledSectionContainer = styled(`div`)({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
});

const StarterMenuLayout: React.FC<StarterMenuLayoutProps> = ({ location, mini }) => {
  return (
    <VStack width="100%" sx={{ paddingTop: "40px", gap: "25px" }}>
      {schema.map((section, i) => (
        <StyledSectionContainer className="sectionContainer" key={`section-${i}`}>
          {section.map((item) => (
            <MenuButton
              location={location}
              path={item.link}
              icon={item.icon}
              reqAuth={item.reqAuth}
              reqLevel={item.reqLevel}
              subMenu={item.subMenu}
              disabled={item.disabled}
              id={item.id}
              key={item.caption}
              caption={item.caption}
              mini={mini}
            />
          ))}
        </StyledSectionContainer>
      ))}
    </VStack>
  );
};

export default observer(StarterMenuLayout);
