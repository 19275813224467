import _ from "lodash";
import { Condition } from "../interfaces/Condition.interface";
import { CombinationOperator } from "../interfaces/Filterable.interface";

interface ISelector {
  logicalOperator: CombinationOperator;
  conditions: Condition[];
}

const defaultISelector: ISelector = {
  logicalOperator: CombinationOperator.and,
  conditions: []
};

const operatorConvert = {
  "$eq": { parseOperator: "equals", parseValue: (o: string) => { return o } },
  "$lt": { parseOperator: "<", parseValue: (o: string) => { return o } },
  "$lte": { parseOperator: "≤", parseValue: (o: string) => { return o } },
  "$gt": { parseOperator: ">", parseValue: (o: string) => { return o } },
  "$gte": { parseOperator: "≥", parseValue: (o: string) => { return o } },
  "$ne": { parseOperator: "≠", parseValue: (o: string) => { return o } },
  "$regex": { parseOperator: "contains", parseValue: (o: string) => { return o.replace(/^\(\?i\)/, "") } }
}

type TOperator = "$eq" | "$lt" | "$lte" | "$gt" | "$gte" | "$ne" | "$regex";

export const parseSelector = (selector: any): ISelector => {
  let logicalOperator = CombinationOperator.and;
  let conditions: Condition[] = [];
  if (!selector.filters || _.isEmpty(selector.filters)) return defaultISelector;

  let and = selector.filters?.$and;
  let or = selector.filters?.$or;
  if (and && Array.isArray(and) && and.length > 0) logicalOperator = CombinationOperator.and;
  if (or && Array.isArray(or) && or.length > 0) logicalOperator = CombinationOperator.or;

  let arr = selector.filters.$or ? selector.filters.$or : selector.filters.$and;
  if (Array.isArray(arr)) {
    for (let item of arr) {
      let field = Object.keys(item)[0];
      if (field == "recTime") continue;
      let opera = Object.keys(item[field])[0];
      let operator = "";
      let value = "";
      if (Object.keys(operatorConvert).includes(opera)) {
        const { parseOperator, parseValue } = operatorConvert[opera as TOperator];
        operator = parseOperator;
        value = parseValue(opera ? item[field][opera] : "");
      }

      if (field && operator && value) {
        conditions.push({ field, operator, value });
      }
    }
  }
  return { logicalOperator, conditions };
}