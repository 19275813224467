import React from "react";
import { CustomFilterValueProps } from "./FilterValue.interface";
import { Condition } from "./Condition.interface";

export interface Filterable {
  label: string;
  field: string;
  type: FieldType;
  keyName?: string; //For array type, the keyName is the key of the object in the array
  reqAuth?: string;
  reqLevel?: number;
  reqFunc?: string;
  CustomFilterValue?: React.FC<CustomFilterValueProps>;
  CustomFilterOperator?: OperatorsType;
  CustomCondition?: (condition: Condition) => {};
  CustomFilter?: (baseData: any, value: string) => {};
}
// TODO: boolean fieldtype does not cater for server side filter
// current workaround: use select with custom options

export enum FieldType {
  text = "text",
  number = "number",
  boolean = "boolean",
  date = "date",
  datetime = "datetime",
  array = "array",
  select = "select",
  custom = "custom",
}

export enum CombinationOperator {
  and = "$and",
  or = "$or",
  not = "$not",
  nor = "$nor",
  all = "$all",
  elemMatch = "$elemMatch",
  allMatch = "$allMatch",
  keyMapMatch = "$keyMapMatch",
}

export enum ConditionOperator {
  lt = "$lt",
  lte = "$lte",
  eq = "$eq",
  ne = "$ne",
  gte = "$gte",
  gt = "$gt",
  exists = "$exists",
  type = "$type",
  in = "$in",
  nin = "$nin",
  size = "$size",
  mod = "$mod",
  regex = "$regex",
  contains = "$elemMatch",
  notExists = "$notExists",
}

export type OperatorsType = Record<string, ConditionOperator>;

export const TextOperators: OperatorsType = {
  contains: ConditionOperator.regex,
  match: ConditionOperator.eq,
  // "is not": ConditionOperator.ne,
};

export const DateOperators: OperatorsType = {
  "is on": ConditionOperator.eq,
  "is not on": ConditionOperator.ne,
  "is on or earlier than": ConditionOperator.lte,
  "is earlier than": ConditionOperator.lt,
  "is on or later than": ConditionOperator.gte,
  "is later than": ConditionOperator.gt,
};

export const NumberOperators: OperatorsType = {
  "=": ConditionOperator.eq,
  "≠": ConditionOperator.ne,
  "≤": ConditionOperator.lte,
  "<": ConditionOperator.lt,
  "≥": ConditionOperator.gte,
  ">": ConditionOperator.gt,
};

export const BooleanOperators: OperatorsType = {
  is: ConditionOperator.eq,
};
export const ArrayOperators: OperatorsType = {
  contains: ConditionOperator.contains,
  notExists: ConditionOperator.notExists,
};

export const SelectOperators: OperatorsType = {
  is: ConditionOperator.eq,
};
