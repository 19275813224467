import { useEffect, useState } from "react";

import { Box, Button, Typography } from "@mui/material";

import schema from "./schema";

import Datumizo from "Components/LabIZO/Datumizo/Datumizo";
import { VStack, HStack } from "Components/LabIZO/Stackizo";
import { ColorX, ErrorX } from "static";
import { backendConnector, DATA_LINK } from "connectors";
import { DatumizoBase } from "Components/LabIZO/Datumizo/DatumizoProps.interface";
import { observer } from "mobx-react";
import _ from "lodash";

interface IAnswerProps {
  title?: string;
  serverSidePagination?: boolean;
  base?: DatumizoBase;
}

const defaultProps: IAnswerProps = {
  title: "GENERIC ANSWERS",
  serverSidePagination: false,
  base: {
    title: "Generic Answers",
    exportDoc: "Generic_Answers",
    schema: schema,
    reqAuth: "Answer",
    columnAuth: "Answer",

    tablizo: {
      columnsToolbar: true,
      filterToolbar: true,
      densityToolbar: true,
      exportToolbar: false,
      showSelector: true,
    },

    Connect: {
      DBInfo: DATA_LINK.AnswerDBInfo,
      List: DATA_LINK.AnswerList,
      SetColumnVisibilitySetting: DATA_LINK.AnswerSetColumnVisibilitySetting,
      schema: schema.Table,
    },

    operations: {
      Add: {
        title: "Add Answer",
        url: DATA_LINK.AnswerAdd,
        success: "Answer Added Successfully",
        fail: "Answer Add Failed: ",
        schema: schema.Add,
        buttons: ["Clear", "Submit"],
        onSubmit: "Add",
      },
      Delete: {
        title: "Delete this Answer?",
        content: "Caution: This is irrevertable.",
        url: DATA_LINK.AnswerDelete,
        success: "Answer Deleted Successfully.",
        fail: "Answer Delete Failed: ",
        onSubmit: "Delete",
      },
      Edit: {
        title: "Edit Answer ",
        url: DATA_LINK.AnswerEdit,
        success: "Answer Edited Successfully",
        fail: "Answer Edit Failed: ",
        schema: schema.Edit,
        buttons: ["Revert", "Submit"],
        onSubmit: "Edit",
      },
      Info: {
        title: "Answer",
        schema: schema.Info,
        readOnly: true,
      },
      DeleteBulk: {
        title: (n: string) => "Delete these " + n + " Answers?",
        content: "Caution: This is irrevertable.",
        url: DATA_LINK.AnswerDeleteBulk,
        success: "Answers Deleted Successfully.",
        fail: "Answers Delete Failed: ",
        onSubmit: "DeleteBulk",
      },
      Export: {
        url: DATA_LINK.AnswerExport,
        schema: schema.Export,
      },
      Import: {
        title: "Answer Import",
        content: "(File size cannot exceed 10MB, only accept .xlsx and .xls)",
        url: DATA_LINK.AnswerImport,
        success: "Answer Imported Successfully.",
        fail: "Answer Import Failed: ",
        schema: schema.ImportFormat,
        accept: [".xlsx", ".xls"],
      },
    },

    buttons: {
      inline: [
        { icon: "edit", func: "Edit", caption: "Edit", reqFunc: "Edit" },
        { icon: "info", func: "Info", caption: "Details" },
      ],
      inlineOpposite: [{ icon: "delete", func: "Delete", caption: "Delete", reqFunc: "Delete" }],
      left: [{ icon: "add", func: "Add", caption: "Add Answer", reqFunc: "Add" }],
      right: [
        { icon: "deletebulk", func: "DeleteBulk", caption: (n) => "Delete (" + n + ")", reqFunc: "Delete", theme: "caution" },
        { icon: "export", func: "Export", caption: (n) => "Export (" + (n === 0 ? "All" : n) + ")", reqFunc: "Export" },
        { icon: "import", func: "Import", caption: "Import", reqFunc: "Import" },
      ],
    },
  },
};

const Answer: React.FC<IAnswerProps> = (props) => {
  const { title, serverSidePagination, base } = props;
  const [MountDatumizo, setMountDatumizo] = useState<any>(false);
  const [intents, setIntents] = useState<any>([]);

  useEffect(() => {
    _getQuickReply();
  }, []);

  function onMountDatumizo(callback: any) {
    setMountDatumizo(callback);
  }

  async function onQuitInner() {
    await MountDatumizo?.Reload();
  }

  const _renderTabSeletor = (defaultTab: string, handleTabChange: (newTab: string) => void) => {
    return (
      <HStack width="auto" gap="2px">
        <Button
          variant={defaultTab === "FAQ" ? "contained" : "outlined"}
          key={"0"}
          onClick={() => {
            handleTabChange("FAQ");
          }}
        >
          FAQ
        </Button>
        <Button
          variant={defaultTab === "FLE" ? "contained" : "outlined"}
          key={"1"}
          onClick={() => {
            handleTabChange("FLE");
          }}
        >
          FLE
        </Button>
        <Button
          variant={defaultTab === "SYSTEM" ? "contained" : "outlined"}
          key={"2"}
          onClick={() => {
            handleTabChange("SYSTEM");
          }}
        >
          System
        </Button>
      </HStack>
    );
  };

  const _filterDataByTab = (tabValue: string, data: any[]) => {
    let filteredData = [];
    switch (tabValue) {
      case "FAQ":
        filteredData = data.filter((obj) => obj.name.startsWith("FAQ"));
        break;
      case "FLE":
        filteredData = data.filter((obj) => obj.name.startsWith("Context_Action"));
        break;
      case "SYSTEM":
        filteredData = data.filter((obj) => !obj.name.startsWith("Context_Action") && !obj.name.startsWith("FAQ"));
        break;
      default:
        filteredData = data;
        break;
    }
    return filteredData;
  };

  const _getQuickReply = async () => {
    try {
      const rtn = await backendConnector.post(
        DATA_LINK.IntentList,
        {
          fields: ["_id"],
        },
        { workspace: "ALL", allIntents: true },
        { skipAudit: true }
      );
      if (rtn.Success === true) {
        let docs = rtn.payload.docs;
        let intents = _.map(docs, (o) => {
          let [workspace, alias] = o._id.split(":");
          return {
            _id: `CMD_BTN ${alias}`,
            caption: alias,
          };
        });
        setIntents(intents);
      }
    } catch (e: any) {
      ErrorX.Handle(e);
    }
  };

  return (
    <VStack>
      <Box padding={1} width="100%">
        <Typography
          style={{
            textAlign: "left",
            width: "100%",
            fontSize: 25,
            color: ColorX.GetColorCSS("Primary"),
          }}
        >
          {title}
        </Typography>
      </Box>
      <Datumizo
        //
        base={base}
        onMounted={onMountDatumizo}
        onQuitInner={onQuitInner}
        serverSidePagination={serverSidePagination}
        tabValue="FAQ"
        renderCustomTab={_renderTabSeletor}
        filterDataByTab={_filterDataByTab}
        addOns={{ workspace: "master", intents }}
      />
    </VStack>
  );
};
Answer.defaultProps = defaultProps;

export default observer(Answer);
