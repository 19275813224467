import { TextField } from "@mui/material";
import { DeleteButton } from "Components/IconButtons";
import { HStack } from "Components/Layouts";

interface IAPIReplaceableSettingProps {
  toReplace: string;
  replaceBy: string;
  idx: number;
  onChange: (key: string, value: string, i: number) => void;
  disabled?: boolean;
}

export function APIReplaceableSetting({ toReplace, replaceBy, idx, onChange, ...props }: IAPIReplaceableSettingProps) {
  return (
    <HStack>
      <TextField
        fullWidth
        variant="outlined"
        label="To Replace"
        value={toReplace}
        onChange={(e) => {
          onChange(e.target.value, replaceBy, idx);
        }}
        disabled={props.disabled}
      />
      <TextField
        fullWidth
        variant="outlined"
        label="Replaced By API Value"
        value={replaceBy}
        onChange={(e) => {
          onChange(toReplace, e.target.value, idx);
        }}
        disabled={props.disabled}
      />
      <DeleteButton
        onClick={() => {
          onChange("", "", idx);
        }}
        disabled={props.disabled}
      />
    </HStack>
  );
}
