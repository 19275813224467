import _ from "lodash";

const Rules = {
  required: (value) => !_.isEmpty(value),
  email: (value) => !value || /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value),
  number: (value) => !value || !isNaN(Number(value)),
  plain: (value) => !value || /^[a-zA-Z0-9_]+$/i.test(value),
  plainSpace: (value) => !value || /^[a-zA-Z0-9_ ]+$/i.test(value),
  entityName: (value) => !value || /^[\w\-]{1,64}$/.test(value),
  entityValue: (value) => !value || /^.{1,64}$/.test(value),
  minimum8: (value) => !value || /^.{8,}$/i.test(value),
  comprise: (value) => !value || /^(?=.*[A-z])(?=.*\d)(?=.*[@$!%*?&_-])[A-Za-z\d@$!%*?&_-]{8,}$/i.test(value),
};

const ErrorMsg = {
  required: "Required",
  email: "Invalid email address",
  number: "Must be a number",
  plain: "No spaces and special characters except _",
  plainSpace: "No special characters except _",
  entityName: "Must be 64 characters or less and can only contain alphanumeric, underscore or hyphen characters.",
  entityValue: "Must be 64 characters or less",
  minimum8: "Must have minimum of (8) characters",
  comprise: "Must be comprised of letters, numbers, and special characters",
};

let validates = {
  Rules,
  ErrorMsg,
};

export default validates;
