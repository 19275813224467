import "./ZCMsg.css";
import { Box, Button, Divider, Link, Paper, Typography } from "@mui/material";
import { AvailLANG } from "interfaces";
import { useEffect, useState } from "react";
import { store } from "static";

interface ILearnMoreProps {
  text?: string;
  link?: string;
  title?: string;
  cssp?: string;
}

enum multiLangLearnMore {
  EN = "Learn More",
  SC = "了解更多",
  TC = "了解更多",
}

const ZCMLearnMore = ({ text, link, cssp, title }: ILearnMoreProps) => {
  const [favicon, setFavicon] = useState<string | null>(null);
  const lang = store.sysInfo.Language.default;

  useEffect(() => {
    // Function to extract the base URL
    const getBaseUrl = (url: string) => {
      try {
        const urlObj = new URL(url);
        return `${urlObj.protocol}//${urlObj.hostname}`;
      } catch (error) {
        console.error("Invalid URL:", error);
        return null;
      }
    };

    // Fetch favicon
    if (link) {
      const baseUrl = getBaseUrl("https://www.ehealth.gov.hk/en/contact-us.html");
      if (baseUrl) {
        setFavicon(`${baseUrl}/favicon.ico`);
      }
    }
  }, [link]);

  const renderLearnMore = () => {
    return (
      <Paper sx={{ width: "50%", padding: "8px 12px", margin: "5px 10px", borderColor: "black" }}>
        <Typography>{title ?? multiLangLearnMore[lang || AvailLANG.EN]}</Typography>
        <Divider sx={{ marginTop: "3px" }} />
        {/* Replace Box with an anchor tag */}
        <a
          href={link}
          target="_blank"
          rel="noopener noreferrer"
          style={{
            textDecoration: "none", // Remove default underline
            color: "inherit", // Inherit text color
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginTop: "8px", // Equivalent to mt: 1
          }}
        >
          <Typography
            sx={{
              color: "#337ab7",
            }}
          >
            {text}
          </Typography>
          {favicon && (
            <img
              src={favicon}
              alt="favicon"
              style={{ width: "30px", height: "30px", marginRight: "8px" }}
              onError={() => setFavicon(null)} // Hide favicon if it fails to load
            />
          )}
        </a>
      </Paper>
    );
  };
  return (
    <Box maxWidth={"75%"} className={cssp + " msg-image"}>
      {renderLearnMore()}
    </Box>
  );
};
export default ZCMLearnMore;
