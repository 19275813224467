import { Button, Grid, styled } from "@mui/material";
import moment from "moment";
import { IEntityContextSetting, IFLECondition, TSystemContextSetting } from "interfaces/db.interfaces/fle.interface";
import React from "react";

const StyledEntityContainer = styled(Grid)({
  display: "flex",
  minWidth: "25%",
  justifyContent: "center",
  alignItems: "center",
  spacing: 1,
});

export function AnsCondButtons({
  setting,
  condition,
  setCondition,
  index,
  ...props
}: {
  setting: TSystemContextSetting | IEntityContextSetting;
  condition: IFLECondition;
  setCondition: (condition: IFLECondition) => void;
  index: number;
  aliasID: string;
}) {
  const _setCondition = (_condition: IFLECondition) => {
    setCondition(_condition);
  };

  const GridItemProps = {
    container: true,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    item: true,
    xs: 4,
    md: 3,
    lg: 2,
    gap: 1,
  };
  const containerProps = {
    sx: {
      width: "100%",
      height: "100%",
      display: "flex",
      justifyContent: "flex-start",
      alignItems: "flex-end",
    },
  };

  if (setting.type == "entity") {
    return (
      <Grid container item xs={10} {...containerProps}>
        {setting.values.map((v, i) => (
          <React.Fragment key={i}>
            {v.values.map((o) => (
              <StyledEntityContainer key={`entity-${o}`}>
                <Button //
                  variant={condition?.value == o ? "contained" : "text"}
                  onClick={() => props.aliasID && _setCondition({ key: props.aliasID, value: o })}
                  sx={{ whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis" }}
                >
                  {`${o}`}
                </Button>
              </StyledEntityContainer>
            ))}
          </React.Fragment>
        ))}
      </Grid>
    );
  }

  if (setting.type == "date" && setting.values) {
    return (
      <>
        {setting.values.map((v) => (
          <Grid {...GridItemProps} key={`date-${v.value}`}>
            <Button //
              variant={condition?.value == v.value ? "contained" : "text"}
              onClick={() => props.aliasID && _setCondition({ key: props.aliasID, value: v.value, operator: v.operator })}
            >{`${v.operator} ${moment(v.value).format("YYYY-MM-DD")}`}</Button>
          </Grid>
        ))}
      </>
    );
  }

  if (setting.type == "numeric" && setting.values) {
    return (
      <>
        {setting.values.map((v) => (
          <Grid {...GridItemProps} key={`numeric-${v.value}`}>
            <Button //
              variant={condition?.value == v.value ? "contained" : "text"}
              onClick={() => props.aliasID && _setCondition({ key: props.aliasID, value: v.value, operator: v.operator })}
            >{`${v.operator} ${v.value}`}</Button>
          </Grid>
        ))}
      </>
    );
  }

  if (setting.type == "regex" && setting.values) {
    return (
      <>
        {setting.values.map((v) => (
          <Grid {...GridItemProps} key={`regex-${v.value}`}>
            <Button //
              variant={condition?.value == v.value ? "contained" : "text"}
              onClick={() => props.aliasID && _setCondition({ key: props.aliasID, value: v.value })}
            >{`${v.value}`}</Button>
          </Grid>
        ))}
      </>
    );
  }

  if (setting.type == "boolean") {
    return (
      <>
        <Grid {...GridItemProps}>
          <Button
            //
            variant={condition?.value == true ? "contained" : "text"}
            onClick={() => props.aliasID && _setCondition({ key: props.aliasID, value: true })}
          >
            True
          </Button>
        </Grid>
        <Grid {...GridItemProps}>
          <Button
            //
            variant={condition?.value == false ? "contained" : "text"}
            onClick={() => props.aliasID && _setCondition({ key: props.aliasID, value: false })}
          >
            False
          </Button>
        </Grid>
      </>
    );
  }

  return <></>;
}
