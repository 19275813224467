import { FormizoSchema } from "Components/LabIZO/Formizo";
import { TablizoSchema } from "Components/LabIZO/Tablizo";

const Table: TablizoSchema[] = [
  {
    label: "Language",
    name: "skill",
    width: 150,
  },
  {
    label: "Example",
    name: "text",
  },
];

const Tail: FormizoSchema[] = [
  {
    label: "Language",
    name: "skill",
    format: "select",
    selectStyle: "radio",
    selectRef: ["EN", "TC", "SC"],
    selectVal: "",
    selectCap: "",
  },
  {
    label: "Example",
    name: "text",
    format: "text",
  },
];

const Add = [...Tail];

const Info = [...Tail];

const Edit = [...Info];

const Export = [
  {
    label: "Language",
    name: "skill",
  },
  {
    label: "Example",
    name: "text",
  },
];

const Import: FormizoSchema[] = [];

const ImportFormat = [...Export];

const Filter: any[] = [];

const AdvFilter: any[] = [];

const schema = {
  Table,
  Info,
  Add,
  Edit,
  Export,
  Import,
  ImportFormat,
  Filter,
  AdvFilter,
};

export default schema;
