import { DatumizoBase } from "Components/LabIZO/Datumizo/DatumizoProps.interface";
import { DATA_LINK } from "connectors";
import schema from "./schema";
import RoleManager from "./RoleManager";

export interface IDefaultProps {
  title?: string;
  base?: DatumizoBase;
}
export const defaultProps: IDefaultProps = {
  title: "Role Based Access Control",
  base: {
    title: "Role Based Access Control",
    exportDoc: "roles",
    schema: schema,
    reqAuth: "UserControl.Role",
    columnAuth: "Role",
    noDefaultTable: false,
    noDefaultButtons: false,

    tablizo: {
      showSelector: true,
      columnsToolbar: true,
      filterToolbar: true,
      densityToolbar: true,
      exportToolbar: false,
    },

    Connect: {
      DBInfo: DATA_LINK.RoleInfo,
      List: DATA_LINK.RoleList,
      SetColumnVisibilitySetting: DATA_LINK.RoleSetColumnVisibilitySetting,
      schema: schema.Table,
    },

    operations: {
      Add: {
        title: "Add Roles",
        url: DATA_LINK.RoleAdd,
        success: "Roles Added Successfully",
        fail: "Roles Add Failed: ",
        schema: schema.Add,
        buttons: ["Clear", "Submit"],
        Custom: (id, row, onQuit, onQuitRefresh, renderFormizo) => <RoleManager {...{ id, row, onQuit, onQuitRefresh, renderFormizo, mode: "Add" }} />,
      },
      Delete: {
        title: "Delete this Role?",
        content: "Caution: This is irrevertable.",
        url: DATA_LINK.RoleDelete,
        success: "Role Deleted Successfully.",
        fail: "Role Delete Failed: ",
        onSubmit: "Delete",
      },
      Edit: {
        title: "Edit Role",
        url: DATA_LINK.RoleEdit,
        success: "Role Edited Successfully",
        fail: "Role Edit Failed: ",
        schema: schema.Edit,
        buttons: ["Revert", "Submit"],
        Custom: (id, row, onQuit, onQuitRefresh, renderFormizo) => <RoleManager {...{ id, row, onQuit, onQuitRefresh, renderFormizo, mode: "Edit" }} />,
      },
      Info: {
        title: "Role DETAILS",
        schema: schema.Info,
        readOnly: true,
        buttons: [],
        Custom: (id, row, onQuit, onQuitRefresh, renderFormizo) => <RoleManager {...{ id, row, onQuit, onQuitRefresh, renderFormizo, mode: "Info" }} />,
      },

      DeleteBulk: {
        title: (n: number) => "Delete these " + n + " Roles?",
        content: "Caution: This is irrevertable.",
        url: DATA_LINK.RoleDeleteBulk,
        success: "Roles Deleted Successfully.",
        fail: "Roles Delete Failed: ",
        onSubmit: "DeleteBulk",
      },
    },

    buttons: {
      inline: [
        { icon: "edit", func: "Edit", caption: "Edit", reqFunc: "Edit" },
        { icon: "info", func: "Info", caption: "Details" },
      ],
      inlineOpposite: [{ icon: "delete", func: "Delete", caption: "Delete", reqFunc: "Delete" }],
      left: [{ icon: "add", func: "Add", caption: "Add Role", reqFunc: "Add" }],
      right: [{ icon: "deletebulk", func: "DeleteBulk", caption: (n: number) => "Delete (" + n + ")", reqFunc: "Delete", theme: "caution" }],
    },
  },
};
