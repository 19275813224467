import { Checkbox, FormControlLabel, Grid, Typography } from "@mui/material";
import { MultiLangTabs } from "Components";
import { HStack, VStack } from "Components/Layouts";
import { ContextAliasTextFeild } from "Pages/FLE/components";
import { QnFallback } from "../QnFallback";
import { ContextTypeSelector } from "./ContextTypeSelector";
import { ContextSettings } from "./ContextSettings";
import { DBAnswerSpec } from "interfaces";
import { IFLEDoc, TFLEContext } from "interfaces/db.interfaces/fle.interface";
import { useFormContext } from "react-hook-form";
import { CustomAlert } from "../../../../../Components/CustomAlert";
import { IUIEntity } from "Pages/FLE/interfaces/IUIEntity";
import { useEffect, useState } from "react";

interface IProps {
  index: number;
  entities: IUIEntity[];
  name: `contexts.${number}`;
  disabled?: boolean;
}

export function ContextDetail({ index, entities, disabled, ...props }: IProps) {
  const { getValues, setValue } = useFormContext<IFLEDoc<DBAnswerSpec>>();
  const context = getValues(`contexts.${index}`);
  const [required, setRequired] = useState(context.required ?? true);

  useEffect(() => {
    if (context.required !== undefined) {
      setRequired(context.required);
    }
  }, [context.required]);

  const onChangeContext = (context: TFLEContext, index: number) => {
    setValue(`contexts.${index}`, context);
  };
  const handleOptionalChange = (event: any) => {
    const newvalue = event.target.checked;
    setRequired(newvalue);
    setValue(`contexts.${index}.required`, newvalue);
  };

  function _checkInUse() {
    const conditionals = getValues("answers.conditionals");

    if (!conditionals) return false;

    const hasRelatedAnswer = conditionals.some((_cond) => {
      return _cond.conditions.some((_c) => {
        return _c?.key == context?.alias?.id && _c?.value != "default";
      });
    });
    return hasRelatedAnswer;
  }
  const inUse = _checkInUse();
  const _disabled = disabled || inUse;
  return (
    <Grid container>
      <Grid container item xs={12}>
        <VStack width="90vw" gap={2} alignItems={"flex-start"}>
          <Grid container item alignItems="center">
            <FormControlLabel control={<Checkbox checked={required} onChange={handleOptionalChange} />} label="Required" labelPlacement="start" disabled={_disabled} />
          </Grid>
          <MultiLangTabs>{(_lang, _index) => <QnFallback key={`qnf-${_index}`} lang={_lang} index={_index} onChange={onChangeContext} {...props} />}</MultiLangTabs>

          {inUse && <CustomAlert message={"This context is in use, some fields cannot be edited"} />}

          <MultiLangTabs>{(_lang, _index) => <ContextAliasTextFeild key={_index} index={index} lang={_lang} disabled={_disabled} {...props} />}</MultiLangTabs>
          <ContextTypeSelector index={index} {...props} disabled={_disabled} />
          <ContextSettings index={index} entities={entities} disabled={_disabled} {...props} />
        </VStack>
      </Grid>
    </Grid>
  );
}
