import React, { Component } from "react";

import PropsType from "prop-types";
import _ from "lodash";
import { Cancel, CheckCircle } from "@mui/icons-material";
import { IconButton } from "@mui/material";

import ZEye from "Components/ZEye/ZEye";
import { ZCMsg } from "Components/Messenger/components";
import AnsLib from "static/AnsLib";
import { Accessor, ColorX } from "static";
import { HStack, Spacer, VStack } from "Components/LabIZO/Stackizo";
import { FFSelect } from "Components/LabIZO/Formizo/_gears/_inputs";

class NewIntent extends Component {
  static propTypes = {
    docID: PropsType.string,
    doc: PropsType.object,
    passed: PropsType.bool,
    tagged: PropsType.bool,
    trainID: PropsType.string,
    onToggle: PropsType.func,
    onSelect: PropsType.func,
    newIntent: PropsType.bool,
    suggestedIntent: PropsType.string,
    intents: PropsType.array,
    answers: PropsType.array,
    role: PropsType.string,
    schema: PropsType.object,
    AnsLibLang: PropsType.string,
  };

  static defaultProps = {
    docID: "",
    doc: {},
    passed: false,
    tagged: false,
    trainID: "",
    onToggle: () => {},
    onSelect: () => {},
    newIntent: false,
    suggestedIntent: "",
    intents: [],
    answers: [],
    role: undefined,
    schema: {
      label: "",
      name: "suggestedIntent",
      format: "select",
      selectStyle: "autocomplete",
      selectRef: "intents",
      selectCap: "caption",
      selectVal: "_id",
      selectTip: "ans.text.EN",
      noLabelGrid: true,
      width: 160,
      AnsLibLang: "EN",
    },
  };

  constructor() {
    super();
    this.state = {
      pass: false,
      tag: false,
      nIntent: false,
      sIntent: "",
    };
  }

  componentDidMount() {
    this._setAllStates(() => {
      this.setState((state, props) => ({
        pass: props.passed,
        tag: props.tagged,
        nIntent: props.newIntent,
        sIntent: props.suggestedIntent,
      }));
    });
  }

  componentDidUpdate(prevProps, prevState) {
    if (!Accessor.IsIdentical(prevProps, this.props, Object.keys(NewIntent.defaultProps))) {
      this._setAllStates(() => {
        this.setState((state, props) => ({
          pass: props.passed,
          tag: props.tagged,
        }));
      });
    }
  }

  componentWillUnmount() {
    this.setState = (state, callback) => {
      return;
    };
  }

  _setAllStates = (callback) => {
    this.setState(
      (state, props) => ({
        ...props,
      }),
      callback
    );
  };

  _setNewIntent = (f) => {
    let { onToggle, docID } = this.props;
    this.setState(
      {
        nIntent: f,
      },
      () => {
        if (onToggle) onToggle(docID, f);
      }
    );
  };

  _onSuggestedIntentChange = (name, value) => {
    let { onSelect, docID } = this.props;
    this.setState(
      {
        sIntent: value,
      },
      () => {
        if (onSelect) onSelect(docID, value);
      }
    );
  };

  renderEye() {
    let { sIntent, doc } = this.state;
    let { answers, role } = this.props;
    let filteredAns = _.filter(answers, (o, i) => o._id === sIntent);
    if (filteredAns.length === 1) {
      let ansObj = AnsLib.Doc2Chat(filteredAns[0][role], doc.lang);
      return (
        <ZEye>
          <VStack>
            <ZCMsg cssp="zchat z" _onQuickReply={() => {}} pos="in" item={ansObj} last={true} typingBubbles={false} showQuickRepliesAsButtons={true} HTMLEnabled={true} />
          </VStack>
        </ZEye>
      );
    } else {
      return <ZEye disabled />;
    }
  }

  render() {
    let { pass, nIntent, tag, sIntent } = this.state;
    let { intents, schema, AnsLibLang } = this.props;
    return (
      <HStack>
        {tag && !pass && nIntent && (
          <IconButton
            onClick={(e) => {
              e.stopPropagation();
              this._setNewIntent(false);
            }}
            style={{ color: ColorX.GetColorCSS("Approval") }}
          >
            <CheckCircle />
          </IconButton>
        )}
        {tag && !pass && !nIntent && (
          <IconButton
            onClick={(e) => {
              e.stopPropagation();
              this._setNewIntent(true);
            }}
            style={{ color: ColorX.GetColorCSS("Cancel") }}
          >
            <Cancel />
          </IconButton>
        )}

        {tag && !pass && nIntent && (
          <FFSelect
            formValue={{
              sIntent: sIntent,
            }}
            ischema={schema}
            iname="sIntent"
            ifieldStyle="grid"
            addOns={{
              intents: intents,
              AnsLibLang,
            }}
            _onValueChange={this._onSuggestedIntentChange}
          />
        )}
        {tag && !pass && nIntent && this.renderEye()}
        <Spacer />
      </HStack>
    );
  }
}

export default NewIntent;
