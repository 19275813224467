import { useEffect, useState } from "react";

import schema from "./schema";

import Formizo from "Components/LabIZO/Formizo";
import { Env, ErrorX, store } from "static";
import { VStack, HStack } from "Components/LabIZO/Stackizo";
import { backendConnector, DATA_LINK } from "connectors";
import _ from "lodash";

const SysNLP: React.FC<{}> = () => {
  const [gptInfo, setGptInfo] = useState(null);
  const [intents, setIntents] = useState<{ _id: string; caption: string }[]>([]);

  const getQuickReplies = async () => {
    try {
      const data = {
        skip: 0,
        limit: 9999,
        fields: ["_id"],
      };
      const addOns = {
        workspace: "ALL",
        allIntents: true,
      };

      let rtn = await backendConnector.post(DATA_LINK.IntentList, data, addOns, { skipAudit: true });

      let docs = rtn.payload.docs;
      let intents = _.map(docs, (o, i) => {
        let [workspace, alias] = o._id.split(":");

        return {
          _id: `CMD_BTN ${alias}`,
          caption: alias as string,
        };
      });

      intents = _.filter(intents, (o) => o._id !== "");
      intents = [...intents];
      setIntents(intents);
    } catch (e: any) {
      ErrorX.Handle(e);
    }
  };

  const getGptConfig = async () => {
    try {
      const data = await backendConnector.post(DATA_LINK.SYSGPTConfig, {}, {}, { skipAudit: true });
      setGptInfo(data.payload);
    } catch (e: any) {
      ErrorX.Handle(e);
    }
  };
  useEffect(() => {
    getGptConfig();
  }, []);

  useEffect(() => {
    getQuickReplies();
  }, []);

  const onSubmit = async (data: any) => {
    try {
      store.isLoading(true);
      const res = await backendConnector.post(DATA_LINK.SYSGPTConfigEdit, data);
      store.isLoading(false);
      if (res.Success) {
        await Env.GetSysInfo();
        store.Alert("System GPTConfig updated successfully", "success");
      } else {
        store.Alert("System GPTConfig update fails.", "error");
      }
    } catch (e: any) {
      store.Alert("Cannot set system GPTconfig", "error");
      store.Alert(ErrorX.Handle(e), "error");
    }
  };

  const handlerWASyncButtonClick = (): void => {
    store.Ask("Sync Dialog Flow", "Sync the EN flow to other languages?", handlerWASyncSubmit);
  };

  const handlerWASyncSubmit = async (): Promise<void> => {
    try {
      const res = await backendConnector.post(DATA_LINK.SYSSyncDialog, {});
      if (!res.Success) throw new Error(`Sync Dialog Flow failed: ${res.message}`);
      store.Alert("Sync Dialog Flow successful.", "success");
    } catch (e: any) {
      ErrorX.Handle(e);
    }
  };

  return (
    <Formizo
      height="100%"
      auth={store.user.authority}
      schema={schema} //
      buttons={["Revert", "Submit"]}
      defaultValue={gptInfo}
      onSubmit={onSubmit}
      addOns={{
        intents: intents,
        handlers: {
          syncDialogueFlow: handlerWASyncButtonClick,
        },
      }}
    />
  );
};

export { schema };
export default SysNLP;
