// //page
import Container from "Components/_PageComponents/Container/Container";
// //store
import { Env, store } from "static";
// //react

import { BrowserRouter, Route, Routes } from "react-router-dom";

import { theme } from "config/theme";

import { ThemeProvider } from "@mui/material";

import "./index.css";
import "antd/dist/reset.css";

import * as serviceWorker from "./serviceWorker";

import { createRoot } from "react-dom/client";
import { BaseHomePage } from "Pages/base/pages/base-home-page";
import { MessengerWithAvatar } from "Pages/base";
import { CMS_ROUTES } from "config/routes";
import { AvailLANG } from "interfaces";
// //mute the console on production launch
if (process.env.NODE_ENV === "production") {
  window.console.log = () => {};
}

store.isLoading(false);
store.clearAsk();
if (store.isLoggedIn()) {
  Env.CheckInitialized();
  Env.GetSysInfo();
  Env.RefreshToken();
}

// refresh token every 10 minutes
setInterval(() => {
  if (store.isLoggedIn()) {
    Env.RefreshToken();
  }
}, 600000);

const setAppstoreUrlParams = async () => {
  const searchParams = new URLSearchParams(window.location.search);
  const params: Record<string, string> = {};
  searchParams.forEach((value, key) => {
    params[key] = value;
  });
  const lang = params.lang ? params.lang.toUpperCase() : "";
  const urlParams = {
    lang: ["EN", "TC", "SC"].includes(lang) ? (lang as AvailLANG) : undefined,
  };
  store.setUrlParams(urlParams);
};
setAppstoreUrlParams();

const baseContainer = document.getElementById("base");
const baseRoot = createRoot(baseContainer!);
baseRoot.render(
  <BrowserRouter>
    <ThemeProvider theme={theme}>
      <Routes>
        <Route path="/" element={<BaseHomePage />}></Route>
        <Route path="*" element={<MessengerWithAvatar />}></Route>
      </Routes>
    </ThemeProvider>
  </BrowserRouter>
);

const container = document.getElementById("root");
const root = createRoot(container!);

const App = () => (
  <ThemeProvider theme={theme}>
    <Container>
      <Routes>
        {CMS_ROUTES.map((route) => (
          <Route key={route.key} path={route.path} element={route.element} />
        ))}
      </Routes>
    </Container>
  </ThemeProvider>
);

root.render(
  <BrowserRouter basename={process.env.REACT_APP_CMS_BASE_PATH ?? "/cms"}>
    <Routes>
      <Route path="*" element={<App />}></Route>
    </Routes>
  </BrowserRouter>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
