import { TablizoSchema } from "Components/LabIZO/Tablizo";
import { ILogExportSchemaEntry } from "interfaces/be.interfaces/file-export-interface";
import _ from "lodash";

const Table: TablizoSchema[] = [
  {
    label: "Receive Time",
    name: "recTime",
    type: "dateTime",
    transform: "datetime",
    width: 180,
    filterable: true,
    defaultSort: "desc",
  },
  {
    label: "Session ID",
    name: "session_id",
    filterable: true,
  },
  {
    label: "Message ID",
    name: "message_id",
    filterable: true,
  },
  {
    label: "FAQ Id",
    name: "faq_id",
    filterable: true,
  },
  {
    label: "Device ID",
    name: "device_id",
    filterable: true,
  },
  {
    label: "Role",
    name: "role",
    filterable: true,
  },
  {
    label: "Rating",
    name: "rating",
    filterable: true,
    width: 120,
  },
];

const Export: ILogExportSchemaEntry[] = [
  {
    Header: "Receive Time",
    accessor: "recTime",
    width: 35,
  },
  {
    Header: "Session ID",
    accessor: "session_id",
    width: 35,
  },
  {
    Header: "Message ID",
    accessor: "message_id",
    width: 35,
  },
  {
    Header: "Device ID",
    accessor: "device_id",
    width: 35,
  },
  {
    Header: "FAQ ID",
    accessor: "faq_id",
    width: 35,
  },
  {
    Header: "Rating",
    accessor: "rating",
    width: 25,
  },
];

const schema: any = {
  Table,
  Export,
};

export default schema;
