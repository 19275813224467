import { Typography, styled } from "@mui/material";
import moment from "moment";
import { ColorX } from "static";
import { TextareaAutosize } from "@mui/base/TextareaAutosize";
import { Property } from "csstype";

const StyledContentContainer = styled(`div`)({
  display: "grid",
  gridTemplateColumns: "1fr 3fr",
  gap: "3px",
  width: "100%",
  height: "100%",
});

export interface ITableContentProps {
  items: ITableRow[];
}

export interface ITableRow {
  label: string;
  value?: string;
  options?: {
    skipIfEmpty?: boolean;
    format?: "date" | "textarea";
    wordBreak?: Property.WordBreak;
  };
}

export const TableContent: React.FC<ITableContentProps> = ({ items }) => {
  const fallbackVal = "N/A";
  return (
    <StyledContentContainer>
      {items.flatMap((item) => {
        const { format, skipIfEmpty, wordBreak } = item.options ?? {};
        if (skipIfEmpty && (item.value === undefined || item.value === "")) return [];
        if (item.value && format === "date") {
          const date = moment(item.value);
          item.value = date.format("YYYY-MM-DD HH:mm:ss");
        }

        return [
          <Typography key={`${item.label}-key`} style={{ fontWeight: 500, color: ColorX.GetColorCSS("Primary") }}>
            {item.label}
          </Typography>,
          format === "textarea" ? ( //
            <TextareaAutosize value={item.value ?? fallbackVal} />
          ) : (
            <Typography style={{ wordBreak: wordBreak }} key={`${item.label}-value`}>
              {item.value ?? fallbackVal}
            </Typography>
          ),
        ];
      })}
    </StyledContentContainer>
  );
};
