import { Typography } from "@mui/material";
import { Check, Close } from "@mui/icons-material";
import { HStack, Spacer, VStack } from "Components/LabIZO/Stackizo";
import { ColorX } from "static";
import _ from "lodash";
import { ConversationHistory } from "./ConversationHistory";
import { ConversationContent } from "./ConversationContent";
import { IGPTConvLog } from "interfaces/db.interfaces/gpt-qa-conv-log.interface";

interface IGPTConvlogDetailProps {
  id: string;
  doc: IGPTConvLog;
  period: {
    DateRange: moment.Moment[] | [string, string];
  };
}
export function GPTConvlogDetail({ doc, id, period }: IGPTConvlogDetailProps) {
  const renderHeader = (label: string, width?: number) => {
    return (
      <HStack width={width}>
        <Typography style={{ color: ColorX.GetColorCSS("Primary"), fontWeight: "bold" }}>{label.toUpperCase()}</Typography>
        <Spacer />
      </HStack>
    );
  };
  const renderField = (label: string, value: any, width: string | number = 150, fontSize = 14, maxHeight: number | string = 200, sx = {}) => {
    if (_.isBoolean(value)) {
      value = value ? <Check /> : <Close />;
    }
    if (label === "Link" && typeof value === "string") {
      value = (
        <a href={value} target="_blank" rel="noopener noreferrer">
          {value}
        </a>
      );
    }
    return (
      <HStack justifyContent="flex-start" gap={1} alignItems="flex-start" style={{ flexWrap: "wrap" }} key={label}>
        <Typography style={{ color: ColorX.GetColorCSS("Primary"), width: width, fontSize: fontSize }}>{label && label.toUpperCase()}</Typography>
        <Typography
          sx={{
            color: ColorX.GetColorCSS("TableText"),
            fontSize: fontSize,
            flexWrap: "wrap",
            overflow: "auto",
            maxHeight: maxHeight,
            whiteSpace: "pre-wrap",
            wordBreak: "break-all",
            ...sx,
          }}
        >
          {value}
        </Typography>
      </HStack>
    );
  };

  const renderBasic = (doc: IGPTConvLog) => {
    return (
      <VStack width="70%" gap={2}>
        {renderHeader("Basic Information")}
        {renderField("Conversation ID", doc._id)}
        {renderField("Session ID", doc.session_id)}
        {renderField("Created At", doc.CREATED_AT)}
        {renderField("Knowledge Base", doc.knowledge_base)}
        {renderField("Question", doc.question)}
        {renderField("Detected Language", doc.language)}
        {renderField("From Follow Up", doc.is_follow_up)}
        {doc.error && renderField("Error", doc.error, 200, 14, 100, { color: "red" })}
      </VStack>
    );
  };

  const renderUrlParams = (doc: IGPTConvLog) => {
    return (
      <VStack width="70%" gap={2}>
        {renderHeader("URL parameters")}
        {Object.entries(doc.url_params).map(([key, value]) => {
          const stringifiedUrlParam = typeof value === "object" ? JSON.stringify(value) : value;
          return renderField(key, stringifiedUrlParam);
        })}
      </VStack>
    );
  };

  const renderAnswer = (doc: IGPTConvLog) => {
    return (
      <VStack width="70%" sapcing={2} sx={{ justifyContent: "flex-start" }}>
        {renderHeader("Answer")}
        {renderField("Answer Messsage", doc.answer)}
        {renderField("Solved", doc.solved)}
        {renderField("Answer Time", doc.CREATED_AT)}
        {renderField("Model", doc.model_name)}
        {renderField("Total Tokens", doc.usage.total_tokens)}
      </VStack>
    );
  };

  const renderContent = (doc: IGPTConvLog) => {
    return (
      <VStack width="100%" sapcing={2} sx={{ justifyContent: "flex-start" }} height={"100%"}>
        {renderHeader("Content")}
        <ConversationContent doc={doc} renderField={renderField} />
      </VStack>
    );
  };
  const renderInstruction = (doc: IGPTConvLog) => {
    return (
      <VStack width="100%" sapcing={2} sx={{ justifyContent: "flex-start" }} height={"autp"}>
        {renderHeader("Prompt")}
        {renderField("Instructions", doc.instruction)}
      </VStack>
    );
  };

  const renderInfo = (doc: IGPTConvLog) => {
    return (
      <HStack width="calc(100vw - 80px)" gap={2} height="auto">
        <VStack gap={2} width="45%" justifyContent="flex-start" alignItems="flex-start">
          {renderHeader("Conversation History")}
          <ConversationHistory doc={doc} period={period} />
          {renderBasic(doc)}
          {renderUrlParams(doc)}
          {renderAnswer(doc)}
        </VStack>
        <VStack gap={2} width="55%" justifyContent="flex-start" alignItems="flex-start">
          {doc.instruction ? renderInstruction(doc) : renderContent(doc)}
        </VStack>
      </HStack>
    );
  };

  if (!doc) {
    return (
      <VStack>
        <Typography>{"Conversation Log cannot be loaded"}</Typography>
      </VStack>
    );
  }
  return (
    <HStack justifyContent="flex-start" sx={{ flexWrap: "wrap", height: "auto" }}>
      <VStack
        padding={1}
        height="100%"
        sx={{
          flexWrap: "wrap",
          width: "80%",
          minWidth: "768px",
        }}
      >
        {renderInfo(doc)}
      </VStack>
    </HStack>
  );
}
