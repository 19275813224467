import { ConditionOperator, FieldType, Filterable } from "Components/LabIZO/Tablizo";
import { Accessor } from "static";
import { CustomFilterValueProps } from "Components/LabIZO/Tablizo";
import { NativeSelect } from "@mui/material";
import { IWebsiteStatus, IWebsiteURLType } from "../../../interfaces/website-manager-api.interface";

export const FILTERABLES: Filterable[] = [
  {
    field: "alias",
    label: "Website Name",
    type: FieldType.text,
  },
  {
    field: "urlSetting.url",
    label: "URL",
    type: FieldType.text,
  },
  // {
  //   field: "urlSetting.type",
  //   label: "URL Type",
  //   type: FieldType.select,
  //   CustomFilterValue: ({ field, width, fieldState }: CustomFilterValueProps) => {
  //     return (
  //       <NativeSelect {...field} sx={{ width: width }} error={Boolean(fieldState?.error)}>
  //         <option value={""}></option>
  //         <option value={IWebsiteURLType.EXPLICIT}>{IWebsiteURLType.EXPLICIT}</option>
  //         <option value={IWebsiteURLType.IMPLICIT}>{IWebsiteURLType.IMPLICIT}</option>
  //       </NativeSelect>
  //     );
  //   },
  // },
  {
    field: "status",
    label: "Status",
    type: FieldType.select,
    CustomFilterValue: ({ field, width, fieldState }: CustomFilterValueProps) => {
      return (
        <NativeSelect {...field} sx={{ width: width }} error={Boolean(fieldState?.error)}>
          <option value={""}></option>
          <option value={IWebsiteStatus.INDEXING}>{IWebsiteStatus.INDEXING}</option>
          <option value={IWebsiteStatus.INDEXED}>{IWebsiteStatus.INDEXED}</option>
        </NativeSelect>
      );
    },
  },
  {
    field: "last_index_time",
    label: "Last Index Time",
    type: FieldType.text,
  },
  // {
  //   field: "enabled",
  //   label: "Enabled",
  //   type: FieldType.select,
  // },
];
