import { Accessor } from "static";
import { Typography } from "@mui/material";
import { TablizoSchema } from "Components/LabIZO/Tablizo";
import { FormatSchema, FormizoSchema } from "Components/LabIZO/Formizo";

const Table: TablizoSchema[] = [
  // {
  //   label: "Active",
  //   name: "inactive",
  //   width: 100,
  //   Cell(row, field, addOns, fetchData) {
  //     return <SwitchButton defaultValue={!field} URL={""} disabled />;
  //   },
  // },
  {
    label: "Active",
    name: "inactive",
    width: 100,
    Cell(row, field, addOns) {
      return <Typography color={field ? "red" : "green"}>{field ? "Inactive" : "Active"}</Typography>;
    },
  },
  {
    label: "Intent",
    name: "name",
  },
  {
    label: "Description",
    name: "Description",
  },
  {
    label: "EN Examples (Count)",
    name: "EN",
    valueGetter: (row, field, addOns) => {
      let examples = Accessor.Get(field, "examples");
      return examples ? examples.length : 0;
    },
  },
  {
    label: "TC Examples (Count)",
    name: "TC",
    valueGetter: (row, field, addOns) => {
      let examples = Accessor.Get(field, "examples");
      return examples ? examples.length : 0;
    },
  },
  {
    label: "SC Examples (Count)",
    name: "SC",
    valueGetter: (row, field, addOns) => {
      let examples = Accessor.Get(field, "examples");
      return examples ? examples.length : 0;
    },
  },
];

const Tail: FormizoSchema[] = [
  {
    label: "Description",
    name: "Description",
    format: "text",
  },
  {
    header: "Questions",
  },
  {
    tabs: [
      {
        label: "EN",
        page: [
          {
            label: "Button Caption",
            name: "EN.description",
            format: "text",
          },
          {
            label: "Examples (EN)",
            name: "EN.examples",
            canAdd: true,
            canDelete: true,
            array: [
              {
                label: "",
                name: "text",
                format: "text",
              },
            ],
          },
        ],
      },
      {
        label: "TC",
        page: [
          {
            label: "Button Caption",
            name: "TC.description",
            format: "text",
          },
          {
            label: "Examples (TC)",
            name: "TC.examples",
            canAdd: true,
            canDelete: true,
            array: [
              {
                label: "",
                name: "text",
                format: "text",
              },
            ],
          },
        ],
      },
      {
        label: "SC",
        page: [
          {
            label: "Button Caption",
            name: "SC.description",
            format: "text",
          },
          {
            label: "Examples (SC)",
            name: "SC.examples",
            canAdd: true,
            canDelete: true,
            array: [
              {
                label: "",
                name: "text",
                format: "text",
              },
            ],
          },
        ],
      },
    ],
  },
];

const Add = [
  {
    label: "Intent",
    name: "name",
    validate: ["required"],
    format: "text",
  },
  ...Tail,
];

const Info = [
  {
    label: "Intent",
    name: "name",
    validate: ["required"],
    format: "text",
    readOnly: true,
  },
  ...Tail,
];

const Edit = [...Info];

const Export: FormatSchema[] = [];

const Import: FormizoSchema[] = [];

const ImportFormat = [...Export];

const Filter: any[] = [];

const AdvFilter: any[] = [];

const schema = {
  Table,
  Info,
  Add,
  Edit,
  Export,
  Import,
  ImportFormat,
  Filter,
  AdvFilter,
};

export default schema;
