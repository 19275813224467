import { FormizoSchema } from "Components/LabIZO/Formizo/FormizoSchema.interface";
import { StyledButton } from "Components/LabIZO/Stylizo";
import { ColorX } from "static";
import { QAMode } from "interfaces";
import { DeleteOutline, Restore } from "@mui/icons-material";
import { HStack } from "Components";
import { Typography } from "@mui/material";

const restore: FormizoSchema[] = [
  {
    label: " ",
    name: "_id",
    width: 120,
    Custom: (data, field, addOns) => {
      return (
        <StyledButton
          onClick={() => {
            if (addOns.Restore) {
              addOns.Restore(field);
            }
          }}
          theme={{
            width: 100,
            height: 25,
            color: ColorX.GetColorCSS("Decorate2"),
            hover: {
              background: ColorX.GetColorCSS("Decorate2"),
              color: ColorX.GetColorCSS("Decorate2"),
            },
          }}
        >
          <HStack gap={5}>
            <Restore fontSize="small" />
            <Typography style={{ fontSize: 12 }}>Restore</Typography>
          </HStack>
        </StyledButton>
      );
    },
  },
  {
    label: " ",
    name: "",
    width: 50,
    Custom: (data, field, addOns) => {
      return (
        <StyledButton
          onClick={() => {
            if (addOns.Delete) {
              addOns.Delete(field);
            }
          }}
          theme={{
            width: 25,
            minWidth: 25,
            height: 25,
            color: "red",
            hover: {
              background: ColorX.GetColorCSS("Cancel"),
              color: ColorX.GetColorCSS("Cancel"),
            },
          }}
        >
          <HStack gap={5}>
            <DeleteOutline fontSize="small" />
          </HStack>
        </StyledButton>
      );
    },
  },
];

const AWSEmbeddingConfig: FormizoSchema = {
  control: "LiveChat.channel",
  controlFunc: (doc, field) => doc?.LiveChat?.channel === "AWSConnect",
  fold: [
    {
      label: "Instance ID",
      name: "LiveChat.AWSConnect.instanceID",
      format: "text",
      validate: ["required"],
      defaultValue: "placeholder",
    },
    {
      label: "Flow ID",
      name: "LiveChat.AWSConnect.flowID",
      format: "text",
      validate: ["required"],
      defaultValue: "placeholder",
    },
  ],
};

const liveChatEmbeddingConfig: FormizoSchema = {
  control: "LiveChat.enabled",
  controlFunc: (doc, field) => doc?.LiveChat?.enabled,
  fold: [
    {
      label: "Channel",
      name: "LiveChat.channel",
      format: "select",
      selectStyle: "dropdown",
      validate: ["required"],
      options: [
        { value: "cinnox", label: "Cinnox" },
        { value: "AWSConnect", label: "AWSConnect" },
      ],
      defaultValue: "cinnox",
    },
    AWSEmbeddingConfig,
  ],
};

const CFLevelConfig: FormizoSchema = {
  label: "Confidence Level",
  foldStyle: "outlined",
  defaultShow: true,
  collapse: [
    {
      label: "CF Level (High)",
      name: "CFLevel.HIGH",
      format: "number",
    },
    {
      label: "CF Level  (Mid)",
      name: "CFLevel.MID",
      format: "number",
    },
    // {
    //   label: "CF Level  (Change)",
    //   name: "CFLevel.CHANGE",
    //   format: "number",
    // },
    {
      label: "CF Level (Vague)",
      name: "CFLevel.VAGUE",
      format: "number",
    },
  ],
};

const mwconfig: FormizoSchema[] = [
  {
    label: "Chatbot Settings",
    foldStyle: "outlined",
    defaultShow: true,
    collapse: [
      // {
      //   label: "Chatbot Name",
      //   name: "cbname",
      //   format: "text",
      // },
      // {
      //   label: "Icon URL",
      //   name: "avatar",
      //   format: "text",
      // },
      {
        label: "Live Chat",
        name: "LiveChat.enabled",
        format: "bool",
        boolStyle: "switch",
      },
      liveChatEmbeddingConfig,
      {
        label: "Ambiguous Handling",
        name: "SResolve",
        format: "bool",
        boolStyle: "switch",
      },
      {
        label: "Allowed QA Modes",
        name: "AllowedQAModes",
        format: "multiSelect",
        selectStyle: "autocomplete",
        optionType: "static",
        options: [
          { value: QAMode.INTENT_CLASSIFICATION, label: "Intent Classification" },
          { value: QAMode.LLM, label: "LLM" },
          { value: QAMode.HYBRID, label: "HYBRID" },
          { value: QAMode.DIRECT_LLM, label: "DIRECT_LLM" },
        ],
        placeholder: "QA Mode",
        validate: ["required"],
      },
      {
        reqAuth: "System.Chatbot",
        reqFunc: "LLMQuotaLimit",
        accessizo: [
          {
            label: "Limit",
            name: "DefaultLlm.limit",
            format: "number",
          },
        ],
      },
      {
        label: "LLM Model Settings",
        control: "AllowedQAModes",
        controlFunc: (doc: any, field: string) => doc?.AllowedQAModes?.includes(QAMode.LLM) || doc?.AllowedQAModes?.includes(QAMode.HYBRID),
        fold: [
          {
            label: "Primary Model",
            name: "DefaultLlm.model.primary",
            format: "select",
            selectCap: "name",
            selectRef: "models",
            selectVal: "id",
          },

          {
            label: "Alternate Models",
            name: "DefaultLlm.model.alternate",
            format: "multiSelect",
            selectStyle: "autocomplete",
            optionType: "addOns",
            selectCap: "name",
            selectRef: "models",
            selectVal: "id",
          },
          {
            label: "API Limit",
            name: "DefaultLlm.limit.api",
            format: "number",
            readOnly: true,
          },
          {
            label: "API Usage",
            name: "DefaultLlm.usage.api",
            format: "number",
            readOnly: true,
          },

          //   {
          //     reqAuth: "System.Chatbot",
          //     reqFunc: "LLMQuotaLimit",
          //     accessizo: [],
          //   },
        ],
      },
      CFLevelConfig,
      // {
      //   label: "Connect to Facebook",
      //   foldStyle: "none",
      //   collapse: [
      //     {
      //       label: "page_access_token",
      //       name: "page_access_token",
      //       format: "text",
      //     },
      //     {
      //       label: "validation_token",
      //       name: "validation_token",
      //       format: "text",
      //     },
      //     {
      //       label: "app_secret",
      //       name: "app_secret",
      //       format: "text",
      //     },
      //   ],
      // },
    ],
  },
];

interface ILangSetting {
  caption: string;
  value: string;
}

export const schema: FormizoSchema[] = [...restore, ...mwconfig];
