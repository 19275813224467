import { IKnowledgeGrpManagementPayload } from "../../interfaces/knowledge-grp-management-api.interface";
import { useState } from "react";
import { HStack, VStack } from "Components/Layouts";
import { FieldErrors, FormProvider, useForm } from "react-hook-form";
import { KnowledgeGrpManagementFormContext } from "../hooks/contexts-hook";
import { KnowledgeGrpInfo } from "./KnowledgeGrpInfo";
import { KnowledgeGrpDocInfo } from "./KnowledgeGrpDocInfo";
import { Button } from "@mui/material";
import { ErrorSummary } from "Pages/FLE/components";
import { DATA_LINK } from "../../../../connectors/datalink";
import { ErrorX, store } from "static";
import { backendConnector } from "connectors/backend-connector";
import { v4 } from "uuid";

interface IProps {
  id: string;
  row?: IKnowledgeGrpManagementPayload;
  onQuit: () => void;
  onQuitRefresh: (_id: string) => void;
  renderFormizo: (schema: any, buttons: any, onSubmit: any) => JSX.Element;
  mode?: "Add" | "Edit" | "Info";
}
export default function KnowledgeGrpManagementForm(props: IProps) {
  const methods = useForm<IKnowledgeGrpManagementPayload>({ defaultValues: props.row, mode: "all" });
  const [errors, setErrors] = useState<FieldErrors<IKnowledgeGrpManagementPayload> | null>(null);

  const mode = props.mode || "Info";

  const handleSubmit = (quitForm: boolean = true) => {
    const submitStatus = {
      valid: async (_newRow: IKnowledgeGrpManagementPayload) => {
        setErrors(null);
        console.log(`Valid Submit: ${JSON.stringify(_newRow)}`);

        try {
          let url = props.mode == "Add" ? DATA_LINK.KGAdd : DATA_LINK.KGEdit;

          // if user does not enter alias, generate one
          if (props.mode == "Add") {
            // TODO: random generate a id
            _newRow._id = `kg-${v4()}`;
            if (!_newRow.alias) {
              const timestamp = Date.now();
              _newRow.alias = `Knowledge Group ${timestamp}`;
            }
          }
          const res = await backendConnector.post(url, _newRow);
          if (!res.Success) {
            const errorMsg = props.mode == "Add" ? "Knowledge Group Add Failed:  " : "Knowledge Group Edit Failed:  ";
            store.Alert(errorMsg + res.payload?.message, "error");
            return ErrorX.Handle(res.payload);
          }
          const successMsg = props.mode == "Add" ? "Knowledge Group Added Successfully" : "Knowledge Group Edited Successfully";
          store.Alert(successMsg, "success");

          if (quitForm) props.onQuitRefresh(_newRow._id);
        } catch (err: any) {
          ErrorX.Handle(err);
        }
      },
      invalid: (errors: FieldErrors<IKnowledgeGrpManagementPayload>) => {
        setErrors(errors);
      },
    };

    methods.handleSubmit(submitStatus.valid, submitStatus.invalid)();
  };

  return (
    <VStack sx={{ pt: 3, width: "100%" }}>
      {errors && <ErrorSummary errors={errors} />}
      <FormProvider {...methods}>
        <KnowledgeGrpManagementFormContext.Provider value={{ mode }}>
          <ButtonPannel mode={mode} onSubmit={handleSubmit} />
          <KnowledgeGrpInfo mode={mode} />
          <KnowledgeGrpDocInfo mode={mode} row={props.row} />
        </KnowledgeGrpManagementFormContext.Provider>
      </FormProvider>
    </VStack>
  );
}

const ButtonPannel = ({ mode, onSubmit }: { mode: "Add" | "Edit" | "Info"; onSubmit: (quitForm?: boolean) => void }) => {
  if (mode === "Info") return <></>;

  return (
    <HStack width={"100%"} justifyContent={"flex-end"} sx={{ position: "absolute", zIndex: 5, top: 40, right: 100 }}>
      <Button
        //
        variant="contained"
        color="secondary"
        onClick={() => {
          onSubmit(true);
        }}
      >
        Submit
      </Button>
    </HStack>
  );
};
