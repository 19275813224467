import axios, { AxiosInstance } from "axios";
import { store } from "static";
import { DOMAIN } from "config/config";
import { DATA_LINK } from "./datalink";
import { ServerResponse } from "interfaces";

export class BackendConnector {
  instance: AxiosInstance;
  constructor() {
    this.instance = axios.create({
      baseURL: DOMAIN,
      timeout: 0,
      headers: {
        "Content-Type": "application/json",
      },
    });
  }

  post = async <T = any>(url: DATA_LINK, data?: any, addOns?: Object, otherArgs?: any) => {
    const response = await this.instance.post<ServerResponse<T>>(url, {
      JWT: store.user.JWT,
      instance: store.user.Instance,
      data: data,
      addOns: addOns,
      ...otherArgs,
    });
    return response.data;
  };

  get = async (url: DATA_LINK, data?: any) => {
    const response = await this.instance.get(url, data);
    return response.data;
  };

  upload = async <T = any>(url: DATA_LINK, file: File, addOns?: Object, schema?: [], replace?: boolean) => {
    let payloadOut = {
      data: {
        upload: file,
      },
      schema: schema ?? [],
      replace: replace ?? false,
      JWT: store.user.JWT,
      addOns: addOns || {},
    };

    let upload = new FormData();
    upload.append("data", JSON.stringify(payloadOut.data || {}));
    upload.append("schema", JSON.stringify(payloadOut.schema || {}));
    upload.append("addOns", JSON.stringify(payloadOut.addOns || {}));
    upload.append("replace", JSON.stringify(payloadOut.replace || false));
    upload.append("JWT", store.user.JWT || "");
    upload.append("instance", store.user.Instance || "");
    if (payloadOut.data.upload) {
      upload.append("upload", payloadOut.data.upload, payloadOut.data.upload.name);
    }
    interface IBackendResponse {
      Success: boolean;
      Message: string;
      payload: T;
    }
    const response = await this.instance.post<IBackendResponse>(url, upload);
    return response.data;
  };

  export = async (url: DATA_LINK, data?: any, addOns?: any) => {
    const response = await this.instance.post(
      url,
      {
        JWT: store.user.JWT,
        instance: store.user.Instance,
        data: data,
        addOns: addOns,
      },
      {
        responseType: "blob",
      }
    );
    return response.data;
  };
}

export const backendConnector = new BackendConnector();
