import { Box, Typography } from "@mui/material";
import { Component } from "react";
import moment from "moment";
import type { Moment } from "moment";
import schema from "./schema";
import { HStack, VStack } from "Components";
import { Authority, ColorX, store } from "static";
import Datumizo from "Components/LabIZO/Datumizo";
import { DatumizoBase, DatumizoOperationType } from "Components/LabIZO/Datumizo/DatumizoProps.interface";
import { AuditLogDetail } from "./AuditLogDetail/AuditLogDetail";
import { DateTimeRangePicker } from "Components/DatePicker/DateTimeRangePicker";
import { DATA_LINK } from "connectors";

interface AuditLogStates {
  title: string;
  period: { DateRange: [Moment, Moment] };
  serverSidePagination: boolean;
  base: DatumizoBase;
}

interface AuditLogProps {}

class AuditLog extends Component<AuditLogProps, AuditLogStates> {
  private MountDatumizo: Record<string, (...args: any[]) => any> = {};

  constructor(props: AuditLogProps) {
    super(props);
    this.state = {
      title: "AUDIT LOG",
      period: { DateRange: [moment().startOf("month"), moment().endOf("month")] },
      serverSidePagination: true,
      base: {
        title: "AuditLog",
        exportDoc: "AuditLog",
        schema,
        reqAuth: "AuditLog",

        tablizo: {
          showSelector: true,
          columnsToolbar: true,
          filterToolbar: true,
          densityToolbar: true,
          exportToolbar: false,
        },

        Connect: {
          List: DATA_LINK.AuditLogList,
          SetColumnVisibilitySetting: DATA_LINK.AuditLogSetColumnVisibilitySetting,
          schema: schema.Table,
        },

        operations: {
          [DatumizoOperationType.Export]: {
            url: DATA_LINK.AuditLogExport,
            schema: schema.Export,
          },

          [DatumizoOperationType.Info]: {
            title: "AUDIT LOG DETAILS",
            success: "Audit Log Success",
            fail: "Audit Log Failed: ",
            schema: schema.Info,
            readOnly: true,
            Custom: (id, doc, _onQuit, _onQuitRefresh, _renderFormizo) => {
              return <AuditLogDetail id={id} doc={doc} />;
            },
          },
        },

        buttons: {
          inline: [{ icon: "info", func: "Info", reqFunc: "Info", caption: "Details" }],
          inlineOpposite: [],
          left: [],
          right: [{ icon: "export", func: "Export", caption: (n: number) => `Export (${n === 0 ? "All" : n})`, reqFunc: "Export" }],
        },
      },
    };
  }

  componentDidMount() {
    Authority.Require("AuditLog");
  }

  onMountDatumizo = (callbacks: Record<string, (...args: any[]) => any>) => {
    this.MountDatumizo = callbacks;
  };

  renderDatePicker = () => {
    const { period } = this.state;
    const handleDateChange = (newDates: [Moment | null, Moment | null]) => {
      if (newDates && newDates[0] && newDates[1]) {
        this.setState((state: any) => {
          return {
            period: {
              ...state.period,
              DateRange: newDates,
            },
            base: {
              ...state.base,
              exportDoc: `AuditLog_${newDates[0]!.format("YYYYMMDD")}_${newDates[1]!.format("YYYYMMDD")}`,
            },
          };
        });
        this.MountDatumizo.Reload();
        store.setFilterSettingDateRange(newDates, "AuditLog");
      }
    };

    return (
      <DateTimeRangePicker
        dates={period.DateRange}
        onChangeDates={handleDateChange}
        limitSelectedPeriod={{ unit: "years", value: 1 }}
        helperText="Select date range to filter the data. You may select up to 1 year's data."
      />
    );
  };

  datePickerCallback = (dates: moment.Moment[]) => {
    if (dates.length == 2) {
      this.setState(() => ({
        period: {
          DateRange: [dates[0], dates[1]]
        }
      }));
    }  
  }

  renderTable = () => {
    let { base, serverSidePagination, period } = this.state;
    return (
      <Datumizo
        base={base}
        addOns={{
          ...period,
        }}
        serverSidePagination={serverSidePagination}
        onMounted={this.onMountDatumizo}
        languageSelector={false}
        datePickerCallback={this.datePickerCallback}
      />
    );
  };

  render = () => {
    const { title } = this.state;
    return (
      <VStack height="100%" width="100%">
        <Box padding={1} width="100%">
          <HStack
            justifyContent="flex-start"
            gap={8}
            style={{
              display: "flex",
              width: "100%",
            }}
          >
            <Typography
              style={{
                alignItems: "left",
                display: "flex",
                fontSize: 25,
                color: ColorX.GetColorCSS("Primary"),
              }}
            >
              {title}
            </Typography>
            {this.renderDatePicker()}
          </HStack>
        </Box>

        {this.renderTable()}
      </VStack>
    );
  };
}

export default AuditLog;
