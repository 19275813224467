import React from "react";

import _ from "lodash";
import { v1 } from "uuid";
import { CloudUpload } from "@mui/icons-material";
import { FormHelperText, FormLabel, Typography } from "@mui/material";

import { Accessor, ColorX } from "static";
import { HStack, Spacer } from "Components/LabIZO/Stackizo";
import { OutlinedBox, StyledButton } from "Components/LabIZO/Stylizo";

interface IFFUploadProps {
  //data
  ischema: any;
  iname: string;
  addOns: any;

  //root func
  _onValueChange: (name: string, value: any, criteria: any) => void;
  _onBlurInlineSubmit: any;
  _onInlineSubmit: any;
  _onFieldFocus: () => void;
  _onFieldBlur: () => void;

  //disability
  errorsShowOnHelperText: boolean;
  readOnly: boolean;

  //runtime
  formValue?: any;
  formError: any;

  //style
  ifieldStyle: string;
}

export const FFUpload: React.FC<IFFUploadProps> = (props) => {
  const { ischema, iname, formValue, formError, ifieldStyle, _onBlurInlineSubmit, _onFieldFocus, _onFieldBlur, _onValueChange, errorsShowOnHelperText, readOnly } = props;
  const uuid = v1();
  let ierror = Accessor.Get(formError, iname);
  let ireadOnly = ischema.readOnly || readOnly;

  let helperText = ischema.helperText;
  if (errorsShowOnHelperText) {
    helperText = ierror;
  }

  const renderUploader = () => {
    if (!ischema) return null;
    let ivalue = Accessor.Get(formValue, iname);
    if (ivalue === undefined || ivalue === null) ivalue = "";
    let ierror = Accessor.Get(formError, iname);
    let ireadOnly = ischema.readOnly || readOnly;

    let helperText = ischema.helperText;
    if (errorsShowOnHelperText) {
      helperText = ierror;
    }

    let theme = ireadOnly
      ? {
          color: ColorX.GetColorCSS("grey"),
          background: ColorX.GetColorCSS("grey"),
          hover: {
            color: ColorX.GetColorCSS("grey"),
            background: ColorX.GetColorCSS("grey"),
          },
        }
      : {
          color: ColorX.GetColorCSS("Edit"),
          background: "white",
        };

    return (
      <HStack className="formizo-h-m">
        {ischema.middle && <Spacer />}
        <label htmlFor={uuid}>
          <StyledButton variant="contained" theme={theme} component={"span"}>
            <HStack gap={5}>
              <CloudUpload />
              <Typography style={{ fontSize: 14, fontWeight: 500 }}>Upload</Typography>
            </HStack>
          </StyledButton>
        </label>
        {ischema.showFilename !== false && <FormLabel className="formizo-h-m">{ivalue.name}</FormLabel>}
        {!_.isEmpty(helperText) && <FormHelperText>{helperText}</FormHelperText>}
        <Spacer />
      </HStack>
    );
  };
  if (!ischema) return null;

  return (
    <HStack>
      <input
        accept={ischema.accept}
        id={uuid}
        onChange={(e) => {
          if (e.target.files) {
            _onValueChange(iname, e.target.files[0], ischema.validate);
          }
        }}
        onFocus={(e) => {
          _onFieldFocus();
        }}
        onBlur={(e) => {
          _onFieldBlur();
          _onBlurInlineSubmit(iname);
        }}
        multiple={false}
        type="file"
        style={{
          display: "none",
        }}
        disabled={ireadOnly}
      />
      {ifieldStyle === "grid" ? (
        renderUploader()
      ) : ifieldStyle === "outlined" ? (
        <OutlinedBox label={ischema.label}>{renderUploader()}</OutlinedBox>
      ) : (
        <HStack>
          <FormLabel className="formizo-h-m">{ischema.label}</FormLabel>
          {renderUploader()}
        </HStack>
      )}
      {!_.isEmpty(helperText) && <FormHelperText>{helperText}</FormHelperText>}
    </HStack>
  );
};
