import { Typography } from "@mui/material";
import { FormizoSchema } from "Components/LabIZO/Formizo";
import { FieldType, Filterable, TablizoSchema } from "Components/LabIZO/Tablizo";
import moment from "moment";
import { ILogExportSchemaEntry } from "interfaces/be.interfaces/file-export-interface";

const Table: TablizoSchema[] = [
  {
    label: "Created At",
    name: "CREATED_AT",
    filterable: true,
    type: "dateTime",
    transform: "datetime",
    defaultSort: "desc",
  },
  {
    label: "Project",
    name: "knowledge_base",
    filterable: true,
  },
  {
    label: "Model",
    name: "model_name",
    filterable: true,
  },
  {
    label: "Question",
    name: "question",
  },
  {
    label: "Passage",
    name: "context",
  },
  {
    label: "Answer",
    name: "answer",
    htmlFormat: true,
  },
  {
    label: "Tokens",
    name: "usage.total_tokens",
  },
  {
    label: "Total Tokens",
    name: "total_tokens",
    sortable: false,
    Cell(row, field, addOns, fetchData) {
      const totalTokens = Number(row.usage.total_tokens) + Number(row.usage.chain_tokens);
      return <Typography>{totalTokens}</Typography>;
    },
  },
  {
    label: "Conversation ID",
    name: "_id",
  },
  {
    label: "Session ID",
    name: "session_id",
  },
];

const Info: FormizoSchema[] = [
  {
    columns: [
      {
        width: "100%",
        page: [
          {
            label: "Conversation ID",
            name: "_id",
            format: "text",
            readOnly: true,
          },
          {
            label: "Session ID",
            name: "session_id",
            format: "text",
            readOnly: true,
          },
          {
            label: "Created At",
            name: "CREATED_AT",
            format: "text",
            readOnly: true,
          },
          {
            label: "Answer Messsage",
            name: "answer",
            format: "text",
            readOnly: true,
          },
          {
            label: "Answer Time",
            name: "answers.created",
            format: "display",
            readOnly: true,
            Custom: (data: any, field: any, addOns: any) => {
              return <Typography>{moment(field).format("DD MMM YYYY, HH:mm:ss 0.SSS")}</Typography>;
            },
          },
          {
            label: "Model",
            name: "answers.model",
            format: "text",
            readOnly: true,
          },
          {
            label: "Completion Tokens",
            name: "usage.completion_tokens",
            format: "text",
            readOnly: true,
          },
          {
            label: "Prompt Tokens",
            name: "usage.prompt_tokens",
            format: "text",
            readOnly: true,
          },
          {
            label: "Total Tokens",
            name: "usage.total_tokens",
            format: "text",
            readOnly: true,
          },
          {
            label: "Passage",
            name: "context",
            format: "display",
            readOnly: true,
            Custom: (data: any, field: any, addOns: any) => {
              return <>{field}</>;
            },
          },
          {
            label: "language",
            name: "language",
            format: "text",
            readOnly: true,
          },
          {
            label: "Project",
            name: "knowledge_base",
            format: "text",
            readOnly: true,
          },
          {
            label: "Question",
            name: "question",
            format: "text",
            readOnly: true,
          },
        ],
      },
    ],
  },
];

const Export: ILogExportSchemaEntry[] = [
  {
    Header: "Created At",
    accessor: "CREATED_AT",
    format: "datetime",
    width: 20,
    wrapText: true,
  },
  {
    Header: "Knowledge Base",
    accessor: "knowledge_base",
    format: "array",
    width: 20,
  },
  {
    Header: "Language",
    accessor: "language",
    width: 20,
  },
  {
    Header: "Channel",
    accessor: "channel",
    width: 20,
  },
  {
    Header: "Question",
    accessor: "question",
    width: 40,
    wrapText: true,
  },
  {
    Header: "Answer",
    accessor: "answer",
    width: 50,
    wrapText: true,
  },
  {
    Header: "Context",
    accessor: "context",
    width: 50,
    wrapText: true,
  },
  {
    Header: "Top Contexts",
    accessor: "top_contexts",
    format: "array",
    arrayOptions: {
      nestedObjectFields: ["id", "filename", "alias", "chunks"],
    },
    width: 50,
    wrapText: true,
  },
  {
    Header: "Model Name",
    accessor: "model_name",
    width: 25,
  },
  {
    Header: "Session ID",
    accessor: "session_id",
    width: 35,
  },
  {
    Header: "Conversation ID",
    accessor: "_id",
    width: 35,
  },
  {
    Header: "Solved",
    accessor: "solved",
    width: 20,
  },
];

const Filterables: Filterable[] = [
  {
    label: "Session ID",
    field: "session_id",
    type: FieldType.text,
  },
  {
    label: "Conversation ID",
    field: "_id",
    type: FieldType.text,
  },
  {
    label: "Project",
    field: "knowledge_base",
    type: FieldType.text,
  },
  {
    label: "QA Model",
    field: "model_name",
    type: FieldType.text,
  },
  {
    label: "Question",
    field: "question",
    type: FieldType.text,
  },
  {
    label: "Answer",
    field: "answer",
    type: FieldType.text,
  },
];

const AdvFilter: any = [];

const schema: any = {
  Table,
  Info,
  Export,
  Filterables,
  AdvFilter,
};

export default schema;
