import { Box } from "@mui/material";
import moment from "moment";
import { FLEOperator, ISystemContextDate } from "interfaces/db.interfaces/fle.interface";
import { BodyTitle } from "Components";
import { DropDownSelector } from "Components/DropDownSelector";
import { AddButton, DeleteButton } from "Components/IconButtons";
import { HStack, VStack } from "Components/Layouts";
import { ITypeSettingProps } from ".";
import { DatePicker } from "Components/DatePicker/DatePicker";

export const DateSetting = ({ setting, onChange, disabled, ...props }: ITypeSettingProps<ISystemContextDate>) => (
  <VStack width={"100%"} alignItems={"flex-start"}>
    <BodyTitle>Date Values</BodyTitle>
    <VStack width="100%">
      <>
        {setting.values &&
          setting.values.map((sVal, svInx) => {
            return (
              <HStack key={svInx} sx={{ maxWidth: "768px" }}>
                <DropDownSelector
                  value={sVal.operator}
                  options={[
                    { label: "Equals", value: FLEOperator.eq },
                    { label: "Greater Than", value: FLEOperator.gt },
                    { label: "Less Than", value: FLEOperator.lt },
                  ]}
                  handleChange={(value) => {
                    const { values } = setting;
                    if (values) {
                      values[svInx].operator = value as FLEOperator;
                      onChange({ ...setting, values });
                    }
                  }}
                  disabled={disabled}
                />
                <Box sx={{ width: "70%" }}>
                  <DatePicker
                    date={moment(sVal.value)}
                    onChangeDate={(newDate) => {
                      if (setting.values && newDate) setting.values[svInx].value = newDate.format("YYYY-MM-DD");
                      onChange({ ...setting });
                    }}
                    showTime={false}
                    disabled={disabled}
                  />
                </Box>

                <DeleteButton
                  onClick={() => {
                    setting.values && setting.values.splice(svInx, 1);
                    onChange({ ...setting });
                  }}
                  disabled={disabled}
                />
              </HStack>
            );
          })}
      </>
      <AddButton
        onClick={() => {
          let { values } = setting;
          if (!values) values = [];
          values.push({ value: moment().format("YYYY-MM-DD"), operator: FLEOperator.eq });
          onChange({ ...setting, values });
        }}
        disabled={disabled}
      />
    </VStack>
  </VStack>
);
