import { useEffect, useState } from "react";
import { defaultProps } from "./defaultProps";
import { Box, Typography } from "@mui/material";
import Datumizo from "Components/LabIZO/Datumizo/Datumizo";
import { VStack } from "Components/LabIZO/Stackizo";
import _ from "lodash";
import { backendConnector } from "connectors/backend-connector";
import { DATA_LINK } from "connectors";
import { IRole } from "interfaces/db.interfaces/role.interface";
import { IInstance } from "interfaces/db.interfaces/instance.interface";

const SysUser = () => {
  const { title, base } = defaultProps;
  const [MountDatumizo, setMountDatumizo] = useState<any>(false);
  const [role, setRole] = useState<{ name: string; _id: string }[]>();
  const [instance, setInstance] = useState<{ name: string; _id: string }[]>();
  const [model, setModel] = useState<{ name: string; id: string }[]>();

  useEffect(() => {
    getRoleList();
    getInstanceList();
    getModelList();
  }, []);

  function onMountDatumizo(callback: any) {
    setMountDatumizo(callback);
  }
  async function onQuitInner() {
    await MountDatumizo?.Reload(true);
  }
  const getRoleList = async () => {
    try {
      const rtn = await backendConnector.post<{ docs: IRole[] }>(DATA_LINK.RoleList, {}, {}, { skipAudit: true });
      if (rtn.Success) {
        const roleList = rtn.payload.docs.map((o) => {
          return {
            _id: o._id,
            name: o.role,
          };
        });
        setRole(roleList);
      }
    } catch (e) {
      console.error(e);
    }
  };

  const getInstanceList = async () => {
    try {
      const rtn = await backendConnector.post<{ docs: IInstance[] }>(DATA_LINK.InstanceList, {}, {}, { skipAudit: true });
      if (rtn.Success) {
        const instanceList = rtn.payload.docs.map((o) => {
          return {
            _id: o._id,
            name: o.alias,
          };
        });
        setInstance([
          {
            //For Super Admin Setting
            _id: "_SuperAdmin",
            name: "_SuperAdmin",
          },
          ...instanceList,
          {
            //For custom instance setting
            _id: "_Custom",
            name: "_Custom",
          },
        ]);
      }
    } catch (e) {
      console.error(e);
    }
  };

  const getModelList = async () => {
    try {
      const { payload, Success } = await backendConnector.post<{ models: any[] }>(DATA_LINK.SYSModelInfo, {}, {}, { skipAudit: true });
      if (Success) {
        setModel(payload.models);
      }
    } catch (e) {
      console.error(e);
    }
  };
  return (
    <VStack height="100%" justifyContent="flex-start">
      <Box padding={1} width="100%">
        <Typography
          sx={{
            textAlign: "left",
            width: "100%",
            fontSize: 25,
            color: "primary.main",
          }}
        >
          {title}
        </Typography>
      </Box>

      <Datumizo
        //
        base={base}
        addOns={{ roleList: role, InstanceList: instance, modelList: model }}
        languageSelector={false}
        onMounted={onMountDatumizo}
        onQuitInner={onQuitInner}
        serverSidePagination={false}
        onDataChange={getInstanceList}
      ></Datumizo>
    </VStack>
  );
};

export default SysUser;
