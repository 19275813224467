import { Box, Button, Typography } from "@mui/material";
import { Component } from "react";
import moment from "moment";
import schema from "./schema";
import { HStack, VStack } from "Components";
import { Accessor, Authority, ColorX, store } from "static";
import type { Moment } from "moment";
import Datumizo from "Components/LabIZO/Datumizo";
import { LeaderboardView } from "./LeaderBoard";
import "./ArenaLog.css";
import ArenaLogInfo from "./ArenaLogInfo";
import { DateTimeRangePicker } from "Components/DatePicker/DateTimeRangePicker";
import { DATA_LINK } from "connectors";

type ArenaLogProps = typeof ArenaLog.defaultProps;

interface ArenaLogStates {
  title: string;
  period: { DateRange: [Moment, Moment] };
  serverSidePagination: boolean;
  base: any;
  view: "Table" | "Leaderboard";
}

function ViewSelector(props: { view: "Table" | "Leaderboard"; setView: any }) {
  const { view, setView } = props;
  return (
    <>
      <Button variant={view === "Table" ? "contained" : "outlined"} onClick={() => setView("Table")}>
        Table View
      </Button>
      <Button variant={view === "Leaderboard" ? "contained" : "outlined"} onClick={() => setView("Leaderboard")}>
        Leaderboard
      </Button>
    </>
  );
}

class ArenaLog extends Component<ArenaLogProps, ArenaLogStates> {
  static defaultProps = {};
  MountDatumizo: Record<string, (...args: any[]) => any> = {};

  constructor(props: ArenaLogProps) {
    super(props);
    this.state = {
      view: "Leaderboard",
      title: "Arena LOG",
      period: { DateRange: [moment().startOf("month"), moment().endOf("month")] },
      serverSidePagination: true,
      base: {
        title: "ArenaLog",
        exportDoc: "ArenaLog",
        schema,
        reqAuth: "ArenaLog",

        tablizo: {
          showSelector: true,
          columnsToolbar: true,
          filterToolbar: true,
          densityToolbar: true,
          exportToolbar: false,
        },

        Connect: {
          List: DATA_LINK.ArenaList,
          SetColumnVisibilitySetting: DATA_LINK.ArenaSetColumnVisibilitySetting,
          schema: schema.Table,
        },

        operations: {
          Export: {
            url: DATA_LINK.ArenaExport,
            schema: schema.Export,
          },

          Info: {
            title: "ARENA LOG DETAILS",
            url: DATA_LINK.ArenaInfo,
            success: "ARENA Log Successfully",
            fail: "ARENA Log Failed: ",
            schema: schema.Info,
            readOnly: true,
            Custom: (id: string, row: any, onQuit: any, onQuitRefresh: any, renderFormizo: any) => <ArenaLogInfo {...{ id, row, onQuit, onQuitRefresh, renderFormizo }} />,
          },
        },

        buttons: {
          inline: [{ icon: "info", func: "Info", reqFunc: "Info", caption: "Details" }],
          // inlineOpposite: [{ icon: "delete", func: "Delete", reqFunc: "Delete", caption: "Delete" }],
        },
      },
    };
  }

  componentDidMount() {
    Authority.Require("ArenaLog");
    this._setAllStates();
  }

  componentDidUpdate(prevProps: ArenaLogProps, prevState: ArenaLogStates) {
    if (!Accessor.IsIdentical(prevProps, this.props, Object.keys(ArenaLog.defaultProps))) {
      this._setAllStates();
    }
  }

  componentWillUnmount() {
    this.setState = (state, callback) => {
      return;
    };
  }

  _setAllStates = (callback?: () => void) => {
    this.setState(
      (state, props) => ({
        ...props,
      }),
      callback
    );
  };

  onMountDatumizo = (callbacks: Record<string, (...args: any[]) => any>) => {
    this.MountDatumizo = callbacks;
  };

  datePickerCallback = (dates: moment.Moment[]) => {
    if (dates.length == 2) {
      this.setState(() => ({
        period: {
          DateRange: [dates[0], dates[1]]
        }
      }));
    }  
  }

  renderDatePicker = () => {
    const { period } = this.state;
    const handleDateChange = (newDates: [Moment | null, Moment | null]) => {
      if (newDates && newDates[0] && newDates[1]) {
        this.setState((state: any) => {
          return {
            period: {
              ...state.period,
              DateRange: newDates,
            },
            base: {
              ...state.base,
              exportDoc: `ArenaLog_${newDates[0]!.format("YYYYMMDD")}_${newDates[1]!.format("YYYYMMDD")}`,
            },
          };
        });
        this.MountDatumizo.Reload();
        store.setFilterSettingDateRange(newDates, "Arena");
      }
    };

    return (
      <DateTimeRangePicker
        dates={period.DateRange}
        onChangeDates={handleDateChange}
        limitSelectedPeriod={{ unit: "years", value: 1 }}
        helperText="Select date range to filter the data. You may select up to 1 year's data."
      />
    );
  };

  renderTable = () => {
    let { base, serverSidePagination, period, view } = this.state;

    const setView = (view: "Table" | "Leaderboard") => {
      this.setState({ view });
    };
    return (
      <Datumizo
        base={base}
        addOns={{
          ...period,
        }}
        serverSidePagination={serverSidePagination}
        onMounted={this.onMountDatumizo}
        languageSelector={false}
        injectRightButtons={<ViewSelector view={view} setView={setView} />}
        noDefaultTable={view == "Leaderboard"}
        datePickerCallback={this.datePickerCallback}
      >
        {view == "Leaderboard" ? <LeaderboardView period={period} /> : <></>}
      </Datumizo>
    );
  };

  render = () => {
    const { title, view } = this.state;
    return (
      <VStack height="100%" width="100%">
        <Box padding={1} width="100%">
          <HStack
            justifyContent="flex-start"
            gap={8}
            style={{
              display: "flex",
              width: "100%",
            }}
          >
            <Typography
              style={{
                alignItems: "left",
                display: "flex",
                fontSize: 25,
                color: ColorX.GetColorCSS("Primary"),
              }}
            >
              {title}
            </Typography>
            {view == "Table" ? this.renderDatePicker() : <></>}
          </HStack>
        </Box>

        {this.renderTable()}
      </VStack>
    );
  };
}

export default ArenaLog;
