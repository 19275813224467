import { Accordion, AccordionDetails, AccordionSummary, Button, Checkbox, Slider, TextField, Typography } from "@mui/material";
import { HStack, VStack } from "Components";
import { useState } from "react";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useSettingContext } from "../../Hooks/setting-hook";
export default function DataSetting() {
  const { setting, updateSetting, getData } = useSettingContext();
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  const onSubmit = () => {
    getData(setLoading);
  };
  const renderDataSetting = () => {
    const handleNumberChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      const newValue = Number(event.target.value);
      if (newValue < 5 || newValue > 1000) {
        setError(true);
      } else {
        setError(false);
      }
      updateSetting({
        data: {
          ...setting.data,
          cluster: undefined,
          convlog: newValue,
        },
      });
    };
    return (
      <VStack width={"100%"}>
        <HStack>
          <Typography sx={{ color: "primary.main" }}>Number of Logs</Typography>
          <TextField
            fullWidth
            inputProps={{ type: "number", min: 5, max: 1000 }}
            type="number"
            value={setting.data.convlog}
            onChange={handleNumberChange}
            error={error}
            helperText={error ? "The number must with in 5 to 1000" : ""}
          />
        </HStack>
        <HStack width={"100%"} style={{ justifyContent: "left" }}>
          <Typography sx={{ color: "primary.main" }} align="left">
            Only LOW Confidence?
          </Typography>
          <Checkbox
            value={setting.data.onlyLowConfidence}
            defaultChecked
            onChange={(e, checked) => {
              updateSetting({ data: { ...setting.data, onlyLowConfidence: checked } });
            }}
          />
        </HStack>
        {setting.data.cluster && (
          <HStack width={"100%"}>
            <Typography sx={{ color: "primary.main" }}>Number of Clusters</Typography>
            <Slider
              onChange={(e, value) => {
                updateSetting({ data: { ...setting.data, cluster: value as number } });
              }}
              valueLabelDisplay="auto"
              step={1}
              min={2}
              max={setting.data.after_cleansing}
              defaultValue={setting.data.cluster}
            />
          </HStack>
        )}
        <Button variant="contained" disabled={error || loading} onClick={onSubmit}>
          GENERATE
        </Button>
      </VStack>
    );
  };
  return (
    <Accordion defaultExpanded>
      <AccordionSummary id="DataSetting" expandIcon={<ExpandMoreIcon />}>
        <Typography variant="body1" sx={{ color: "primary.main", textAlign: "center" }}>
          Data Setting
        </Typography>
      </AccordionSummary>
      <AccordionDetails>{renderDataSetting()}</AccordionDetails>
    </Accordion>
  );
}
