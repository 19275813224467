import React from "react";

import _ from "lodash";
import { FormControl, FormHelperText } from "@mui/material";

import { Accessor } from "static";
import { StyledInput, StyledTextField } from "Components/LabIZO/Stylizo";
interface IFFTextareaProps {
  //data
  ischema: any;
  iname: string;
  addOns: any;

  //root func
  _onValueChange: (name: string, value: any, criteria: any) => void;
  _onBlurInlineSubmit: any;
  _onInlineSubmit: any;
  _onFieldFocus: () => void;
  _onFieldBlur: () => void;

  //disability
  errorsShowOnHelperText: boolean;
  readOnly: boolean;

  //runtime
  formValue?: any;
  formError: any;

  //style
  ifieldStyle: string;
  fieldSize?: string;
  theme?: any;
}

export const FFTextarea: React.FC<IFFTextareaProps> = (props) => {
  const { formValue, iname, ischema, readOnly, formError, _onValueChange, fieldSize, theme, _onBlurInlineSubmit, _onFieldFocus, _onFieldBlur, errorsShowOnHelperText, ifieldStyle } = props;
  let ivalue = Accessor.Get(formValue, iname);
  if (ivalue === undefined || ivalue === null) ivalue = "";
  let ierror = Accessor.Get(formError, iname);
  const ireadOnly = ischema.readOnly || readOnly;

  let helperText = ischema.helperText;
  if (errorsShowOnHelperText) {
    helperText = ierror;
  }

  let rowsMin = ischema.rows || 4;
  const renderInput = () => {
    return (
      <FormControl
        error={!_.isEmpty(ierror)}
        disabled={ireadOnly}
        fullWidth={ischema.fullWidth !== false}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => _onValueChange(iname, e.target.value, ischema.validate)}
        onFocus={() => {
          _onFieldFocus();
        }}
        onBlur={() => {
          _onFieldBlur();
          _onBlurInlineSubmit(iname);
        }}
        defaultValue={ischema.defaultValue}
      >
        <StyledInput
          id={ischema.id ? ischema.id : iname}
          value={ivalue}
          placeholder={ischema.placeholder}
          startAdornment={ischema.before}
          endAdornment={ischema.after}
          minRows={rowsMin}
          maxRows={ischema.maxRows}
          multiline
          theme={theme && theme.textfield}
        />
        {!_.isEmpty(helperText) && <FormHelperText>{helperText}</FormHelperText>}
      </FormControl>
    );
  };

  const renderTextField = () => {
    return (
      <StyledTextField
        id={ischema.id ? ischema.id : iname}
        value={ivalue}
        label={ischema.label || ""}
        helperText={helperText || ""}
        placeholder={ischema.placeholder || ""}
        onChange={(e: { target: { value: any } }) => _onValueChange(iname, e.target.value, ischema.validate)}
        onFocus={() => {
          _onFieldFocus();
        }}
        onBlur={() => {
          _onFieldBlur();
          _onBlurInlineSubmit(iname);
        }}
        name={iname}
        variant={ifieldStyle}
        fullWidth={ischema.fullWidth !== false}
        error={!_.isEmpty(ierror)}
        disabled={ireadOnly}
        InputProps={{
          startAdornment: ischema.before,
          endAdornment: ischema.after,
        }}
        defaultValue={ischema.defaultValue}
        multiline
        minRows={rowsMin}
        maxRows={ischema.maxRows}
        theme={theme && theme.textfield}
        size={fieldSize}
      />
    );
  };

  if (!ischema) return null;
  if (!ifieldStyle || ifieldStyle === "grid") {
    return renderInput();
  } else return renderTextField();
};
