import { Paper } from "@mui/material";
import { DBAnswerSpec } from "interfaces";
import _ from "lodash";
import { useRef, useState } from "react";
import { AddButton, BodyTitle } from "Components";
import { VStack } from "Components/Layouts";
import { IAllFLEAnswers, IFLEAnswer, IFLECondition, TFLEContext } from "interfaces/db.interfaces/fle.interface";
import { AnsCondTable } from "./SettingTable/AnsCondTable";
import { AnsContent } from "./SettingTable/AnsContent";
interface IProps {
  contexts?: TFLEContext[];
  answers: IAllFLEAnswers<DBAnswerSpec>;
  onChangeAnswers: (answers: IAllFLEAnswers<DBAnswerSpec>) => void;
  disabled?: boolean;
}
export const ConditionalAnswer = ({ contexts, answers, onChangeAnswers, ...props }: IProps) => {
  const ansRef = useRef<IFLEAnswer<DBAnswerSpec>>();

  let defaultConditions: IFLECondition[] =
    contexts?.map((c, i) => {
      return { key: c.alias.id, value: "default" };
    }) || [];
  const [selectedConditions, setSelectedConditions] = useState<IFLECondition[]>(defaultConditions);

  const handleConditionChange = (conditions: IFLECondition[]) => {
    console.log("selected condition:", conditions);
    setSelectedConditions(conditions);
  };
  const hasCondition = answers.conditionals && answers.conditionals.length !== 0;
  const conditionExists = hasCondition && answers.conditionals?.find((o) => _.isEqual(o.conditions, selectedConditions));
  const notDefault = selectedConditions.filter((o) => o.value !== "default").length !== 0;
  const showAddButton = (!hasCondition || !conditionExists) && notDefault;

  const onAddAnswer = () => {
    if (ansRef.current) {
      onChangeAnswers({
        ...answers,
        conditionals: [...(answers.conditionals || []), ansRef.current],
      });
    }
  };

  return (
    <VStack width="100%" gap={1} justifyContent="flex-start">
      <Paper sx={{ width: "100%", height: "100%", p: 1 }}>
        <VStack alignItems={"flex-start"}>
          <BodyTitle>Answer Conditions</BodyTitle>
          <AnsCondTable contexts={contexts} answers={answers} ansRef={ansRef} selectedConditions={selectedConditions} setSelectedConditions={handleConditionChange} {...props} />
          <AddButton visible={showAddButton} onClick={onAddAnswer} {...props}>
            Add Answer
          </AddButton>
        </VStack>
      </Paper>
      <Paper sx={{ width: "100%", p: 1 }}>
        <VStack sx={{ width: "100%" }} alignItems="flex-start">
          <BodyTitle>Answer Content</BodyTitle>
          <AnsContent answers={answers} onChangeAnswers={onChangeAnswers} selectedConditions={selectedConditions} setSelectedConditions={handleConditionChange} {...props}></AnsContent>
        </VStack>
      </Paper>
    </VStack>
  );
};
