import { Typography } from "@mui/material";
import { Check, Close } from "@mui/icons-material";
import { HStack, Spacer, VStack } from "Components/LabIZO/Stackizo";
import { Accessor, Authority, ColorX } from "static";
import _ from "lodash";
import { ConvlogNLP } from "./ConvlogNLP";
import { JsonView } from "Components/JsonView";
import { ConvlogHistory } from "./ConvlogHistory";
import { ConvlogInfo } from "./ConvlogInfo";
import { IConvLog } from "interfaces/db.interfaces/conv-log.interface";

interface ConvlogDetailProps {
  id: string;
  doc: IConvLog;
  intents: any;
  period: { DateRange: [string, string] };
}

export default function ConvlogDetail({ id, doc, intents, period }: ConvlogDetailProps) {
  const renderJson = (doc: any) => {
    const src = Accessor.Get(doc, "Response") || {};
    return (
      <VStack padding={2} style={{ width: "100%", maxHeight: 520 }} alignItems="flex-start">
        <JsonView>{src}</JsonView>
      </VStack>
    );
  };

  const renderHeader = (label: string, width?: number) => {
    return (
      <HStack width={width}>
        <Typography style={{ color: ColorX.GetColorCSS("Primary"), fontWeight: "bold" }}>{label && label.toUpperCase()}</Typography>
        <Spacer />
      </HStack>
    );
  };

  const renderField = (label: string, value: any, width: number = 150, fontSize: number = 14, children?: JSX.Element) => {
    if (_.isBoolean(value)) {
      value = value ? <Check /> : <Close />;
    }
    return (
      <HStack justifyContent="flex-start" gap={1} alignItems="flex-start" style={{ flexWrap: "wrap" }} key={label}>
        <Typography style={{ color: ColorX.GetColorCSS("Primary"), width: width, fontSize: fontSize }}>{label && label.toUpperCase()}</Typography>
        <Typography
          style={{
            color: ColorX.GetColorCSS("TableText"),
            fontSize: fontSize,
            flexWrap: "wrap",
            overflow: "auto",
            maxHeight: 200,
          }}
        >
          {value ?? ""}
        </Typography>
        {children}
      </HStack>
    );
  };

  if (!doc) {
    return (
      <VStack>
        <Typography>{"Conversation Log cannot be loaded"}</Typography>
      </VStack>
    );
  } else if (Authority.IsAccessibleQ("Convlog", undefined, "NlpInfo")) {
    return (
      <HStack justifyContent="flex-start" sx={{ flexWrap: "wrap", height: "auto" }}>
        <VStack
          padding={1}
          height="100%"
          sx={{
            flexWrap: "wrap",
            width: "60%",
            minWidth: "768px",
          }}
        >
          <ConvlogInfo period={period} doc={doc} renderHeader={renderHeader} renderField={renderField} />
          <ConvlogNLP doc={doc} renderHeader={renderHeader} renderField={renderField} intents={intents} />
        </VStack>
        <VStack width={"40%"} padding={1} alignItems="flex-start" height="100%" sx={{ alignItems: "flex-start", justifyContent: "flex-start", minWidth: "300px" }}>
          <ConvlogHistory doc={doc} period={period} />
          {renderJson(doc)}
        </VStack>
      </HStack>
    );
  } else {
    return (
      <VStack justifyContent="flex-start" sx={{ flexWrap: "wrap", height: "auto", width: "100%", padding: "15px" }}>
        <ConvlogInfo period={period} doc={doc} renderHeader={renderHeader} renderField={renderField} />
        <ConvlogHistory doc={doc} period={period} />
      </VStack>
    );
  }
}
