import { useEffect, useState } from "react";
import { ErrorX, store } from "static";
import { VStack, HStack } from "Components/LabIZO/Stackizo";
import { DATA_LINK, backendConnector } from "connectors";
import Formizo from "Components/LabIZO/Formizo";
import { schema } from "./schema";
interface ITopicClusterConfig {
  parameter: object;
  id: string;
  name: string;
  url: string;
  source: string;
}
const SysTopicClustering = () => {
  const [data, setData] = useState<ITopicClusterConfig>();
  useEffect(() => {
    getModelInfo();
  }, []);

  const handleSubmit = async (updateData: ITopicClusterConfig) => {
    try {
      const res = await backendConnector.post(DATA_LINK.SysTopicClusterEdit, updateData);
      if (res.Success) {
        store.Alert("System Model updated successfully", "success");
      } else {
        store.Alert("System Model update fails.", "error");
      }
    } catch (e: any) {
      store.Alert(ErrorX.Handle(e), "error");
    }
  };

  const getModelInfo = async () => {
    const { payload } = await backendConnector.post(DATA_LINK.SysTopicClusterInfo, {});
    setData(payload);
  };
  const renderModelSetting = () => {
    return (
      <Formizo
        //
        schema={schema}
        buttons={["Revert", "Submit"]}
        onSubmit={handleSubmit}
        defaultValue={data}
      />
    );
  };

  return (
    <HStack justifyContent="flex-start" height="100%" gap={3} paddingX={4}>
      <VStack width="100%">{renderModelSetting()}</VStack>
    </HStack>
  );
};
export { schema };
export default SysTopicClustering;
