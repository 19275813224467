import { Box, Button, Grid, IconButton, InputAdornment, Modal, TextField, Typography } from "@mui/material";
import React, { useState } from "react";
import { TabConfig, tabs } from "./tabs";
import { Authority } from "static";
import { styled } from "@mui/system";
import { HStack, VStack } from "Components";
import { ArrowBack, Search, Clear } from "@mui/icons-material";
import _ from "lodash";
import { FormizoSchema } from "Components/LabIZO/Formizo";
const CustomButton = styled(Button)({
  color: "white",
  width: "100%",
  height: "100px",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  padding: "6px 8px",
  flexGrow: 1,
  flexShrink: 0,
  flexBasis: 0,
  "&:hover": {
    color: "black",
    backgroundColor: "white",
  },
});

const CustomBox = styled(Box)({
  position: "absolute",
  top: "50%",
  left: "50%",
  width: "90%",
  height: "90%",
  transform: "translate(-50%, -50%)",
  border: "2px solid #000",
  backgroundColor: "white",
});

const CustomTextfield = styled(TextField)({
  width: "100%",
  margin: "auto",
  paddingBottom: "10px",

  "& .MuiOutlinedInput-root": {
    borderRadius: "2rem",
    padding: "0px 10px",
    transition: "box-shadow 0.3s",
    borderColor: "#ccc",
    border: "1px solid #dfe1e5",

    "&.Mui-focused": {
      boxShadow: "0 0 0 2px rgba(66,133,244,0.5)",
    },
    "& fieldset": {
      // Hide the default border
      borderWidth: "0 !important",
    },
  },
  "& .MuiInputAdornment-root": {
    marginRight: "8px",
  },
});

const Searchbar = ({ searchTerm, setSearchTerm }: { searchTerm: string; setSearchTerm: React.Dispatch<React.SetStateAction<string>> }) => {
  const handleSearchChange = (event: { target: { value: React.SetStateAction<string> } }) => {
    setSearchTerm(event.target.value);
  };
  const handleClearClick = () => {
    setSearchTerm(""); // Clear the search term
  };
  return (
    <CustomTextfield
      value={searchTerm}
      onChange={handleSearchChange}
      placeholder="Search…"
      variant="outlined"
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <Search />
          </InputAdornment>
        ),
        endAdornment: searchTerm && (
          <InputAdornment position="end">
            <IconButton onClick={handleClearClick} edge="end" size="small">
              <Clear />
            </IconButton>
          </InputAdornment>
        ),
      }}
    />
  );
};

const System: React.FC<{}> = () => {
  const [open, setOpen] = useState(false);
  const [currentTab, setCurrentTab] = useState<TabConfig>(tabs[0] || null);
  const [searchTerm, setSearchTerm] = useState("");
  const colorMap = {
    General: "#2e294e",
    GPT: "#1b7f1e",
    Chatbot: "#1976d2",
    User: "#db0011",
  };

  const handleClick = (tab: TabConfig) => {
    setCurrentTab(tab);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const filterTabs = (tabsArray: TabConfig[]) => {
    const searchTermLower: string = _.toLower(searchTerm);
    const searchInSchema = (schema: FormizoSchema[]): boolean => {
      return schema.some((item) => {
        if ("label" in item && _.toLower(item.label).includes(searchTermLower)) {
          return true;
        }
        if ("accessizo" in item && item.accessizo && searchInSchema(item.accessizo)) {
          return true;
        }
        if ("collapse" in item && item.collapse && searchInSchema(item.collapse)) {
          return true;
        }
        if ("fold" in item && item.fold && searchInSchema(item.fold)) {
          return true;
        }
        if ("array" in item && item.array && Array.isArray(item.array) && searchInSchema(item.array)) {
          return true;
        }
        return false;
      });
    };
    let tabsAllowed = tabsArray.filter((tab) => {
      const hasAccess: boolean = Authority.IsAccessibleQ(tab.reqAuth, tab.reqLevel, tab.reqFunc);
      const searchTermLower: string = _.toLower(searchTerm);
      const labelIncludesSearchTerm: boolean = _.toLower(tab.label || "").includes(searchTermLower) || (!!tab.schema && searchInSchema(tab.schema));

      return hasAccess && labelIncludesSearchTerm;
    });
    return tabsAllowed;
  };

  const renderSettingPage = () => {
    let tabsAllowed = filterTabs(tabs);
    return (
      <>
        {tabsAllowed.map((tab, index) => (
          <Grid item sx={{ padding: "10px" }} key={index} xs={6} sm={6} md={4} lg={3}>
            <CustomButton sx={{ backgroundColor: colorMap[tab.type] }} onClick={() => handleClick(tab)}>
              <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}>{tab.icon}</Box>
              <Typography variant="h5" color="inherit" sx={{ pt: 1 }}>
                {tab.label}
              </Typography>
            </CustomButton>
          </Grid>
        ))}
      </>
    );
  };
  const renderPopUpWindow = () => {
    return (
      <Modal sx={{ zIndex: "3" }} open={open} onClose={handleClose} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
        <CustomBox>
          <VStack width="100%" height="100%" sx={{ overflow: "auto" }}>
            <HStack sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", width: "100%" }}>
              <Typography variant="h4" sx={{ padding: "10px 25px" }}>
                {currentTab && currentTab.label}
              </Typography>
              <Button sx={{ paddingRight: "25px" }} onClick={handleClose}>
                <ArrowBack />
              </Button>
            </HStack>
            {currentTab && currentTab.render}
          </VStack>
        </CustomBox>
      </Modal>
    );
  };

  return (
    <Grid width={"100%"} sx={{ paddingY: "10px", paddingX: "40px" }} container>
      <Searchbar searchTerm={searchTerm} setSearchTerm={setSearchTerm} />
      {renderSettingPage()}
      {renderPopUpWindow()}
    </Grid>
  );
};

export default System;
