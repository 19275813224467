import "./ZCMsg.css";
import { Box, Button } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { KeyboardArrowLeft, KeyboardArrowRight } from "@mui/icons-material";
import { store } from "static";
import { ImagePath, UnderMaintenanceImagePath } from "../constants/image_path";

interface IImageProps {
  src: (ImagePath | string)[];
  imageUrls?: string[];
  cssp?: string;
  onImageClick?: (src: string) => void;
  title?: string;
}

const ZCMImage: React.FC<IImageProps> = ({ cssp, src, imageUrls, onImageClick, title }) => {
  const containerRef = useRef<HTMLDivElement | null>(null);
  const [canScrollLeft, setCanScrollLeft] = useState(false);
  const [canScrollRight, setCanScrollRight] = useState(false);

  const getImageSource = (src: ImagePath | string): string => {
    if (src in ImagePath) {
      return ImagePath[src as keyof typeof ImagePath];
    } else if (src === "UnderMaintenance") {
      return UnderMaintenanceImagePath[store.chatbotLang as keyof typeof UnderMaintenanceImagePath];
    }
    return src;
  };

  useEffect(() => {
    const handleScroll = () => {
      if (containerRef.current) {
        const { scrollLeft, scrollWidth, clientWidth } = containerRef.current;
        setCanScrollLeft(scrollLeft > 0);
        setCanScrollRight(scrollLeft + clientWidth < scrollWidth);
      }
    };

    handleScroll();
    if (containerRef.current) {
      containerRef.current.addEventListener("scroll", handleScroll);
    }

    return () => {
      if (containerRef.current) {
        containerRef.current.removeEventListener("scroll", handleScroll);
      }
    };
  }, []);

  const scroll = (direction: "left" | "right") => {
    if (containerRef.current) {
      const scrollAmount = direction === "left" ? -300 : 300;
      containerRef.current.scrollBy({ left: scrollAmount, behavior: "smooth" });
    }
  };

  return (
    <Box className={`${cssp} image-gallery`} sx={{ position: "relative", width: "100%" }}>
      {canScrollLeft && (
        <Button
          onClick={() => scroll("left")}
          sx={{
            position: "absolute",
            left: 0,
            top: "50%",
            transform: "translateY(-50%)",
            backgroundColor: "rgba(0, 0, 0, 0.5)",
            border: "none",
            color: "white",
            cursor: "pointer",
            zIndex: 1,
            padding: "5px",
            minWidth: "30px",
            "&:hover": {
              backgroundColor: "rgba(0, 0, 0, 0.7)",
            },
          }}
        >
          <KeyboardArrowLeft />
        </Button>
      )}
      <div
        ref={containerRef}
        style={{
          display: "flex",
          width: "100%",
          overflowX: "scroll",
          scrollBehavior: "smooth",
          scrollbarWidth: "none",
          msOverflowStyle: "none",
        }}
      >
        <style>
          {`
            .image-gallery::-webkit-scrollbar {
              display: none;
            }
          `}
        </style>
        {src.map((imageSrc, index) => (
          <div
            key={index}
            style={{
              flex: "0 0 300px", // Changed from 66.66% to a fixed width
              padding: "0 8px",
              boxSizing: "border-box",
              maxHeight: "200px", // Added max height
            }}
          >
            <a
              href={imageUrls && imageUrls[index] ? imageUrls[index] : undefined}
              target="_blank"
              rel="noopener noreferrer"
              tabIndex={0}
              style={{
                cursor: "pointer",
                display: "block",
                width: "100%",
                height: "100%",
              }}
              onClick={(e) => {
                if (!imageUrls || !imageUrls[index]) {
                  e.preventDefault();
                  onImageClick && onImageClick(getImageSource(imageSrc));
                }
              }}
            >
              <img
                src={getImageSource(imageSrc)}
                alt={title || `Image ${index + 1}`}
                style={{
                  width: "100%",
                  height: "100%",
                  maxHeight: "200px", // Match container max height
                  objectFit: "contain",
                  display: "block",
                  borderRadius: "18px",
                }}
              />
            </a>
          </div>
        ))}
      </div>
      {canScrollRight && (
        <Button
          onClick={() => scroll("right")}
          sx={{
            position: "absolute",
            right: 0,
            top: "50%",
            transform: "translateY(-50%)",
            backgroundColor: "rgba(0, 0, 0, 0.5)",
            border: "none",
            color: "white",
            cursor: "pointer",
            zIndex: 1,
            padding: "5px",
            minWidth: "30px",
            "&:hover": {
              backgroundColor: "rgba(0, 0, 0, 0.7)",
            },
          }}
        >
          <KeyboardArrowRight />
        </Button>
      )}
    </Box>
  );
};

export default ZCMImage;
