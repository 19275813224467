import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";

interface IDropDownSelectorProps {
  label?: string;
  value: string;
  options: { label: string; value: string; disabled?: boolean }[];
  handleChange?: (value: string) => void;
  fullWidth?: boolean;
  disabled?: boolean;
}

export const DropDownSelector = (props: IDropDownSelectorProps) => {
  const { label = "", value, options, handleChange, fullWidth = true, ...others } = props;
  if (!options) return <></>;

  const customTheme = {
    borderRadius: "5px 5px 0 0",
    textAlign: "center",
    alignItems: "center",

    backgroundColor: "primary.main",
    color: "primary.contrastText",
    "& .MuiSelect-icon": {
      color: "primary.contrastText",
    },
  };

  const sxProps = {
    ...customTheme,
    //on focus'
    "& .MuiSelect-select:focus": {
      ...customTheme,
    },
    //on hover
    "&:hover": {
      ...customTheme,
    },
    //on active
    "&:active": {
      ...customTheme,
    },
  };
  return (
    <FormControl sx={{ width: "100%" }}>
      {label && <InputLabel id="select-label">{label}</InputLabel>}
      <Select
        variant="filled"
        labelId="select-label"
        id="select"
        value={value}
        onChange={(e) => {
          if (handleChange) handleChange(e.target.value as string);
        }}
        fullWidth={fullWidth}
        sx={sxProps}
      >
        {options.map((option, index) => {
          if (!option.value) return <></>;
          return (
            <MenuItem key={`entity-selector-${index}`} value={option.value} disabled={props.disabled || option.disabled}>
              {option.label}
            </MenuItem>
          );
        })}
      </Select>
    </FormControl>
  );
};
