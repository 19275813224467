import { ChatTab } from "../../tabs";
import Grid from "@mui/material/Grid";

export const StyledPageGrid = ({ tabs, children }: { tabs: ChatTab[]; children: React.ReactNode }) => {
  const marginTop = tabs.length > 1 ? 0 : "5px";

  return (
    <Grid
      container
      style={{
        marginTop: marginTop,
        width: "100%",
        height: "100%",
        justifyContent: "center",
        alignItems: "center",
        flexGrow: 1,
        flexShrink: 1,
        minHeight: 0,
        background: "white",
      }}
    >
      {children}
    </Grid>
  );
};
