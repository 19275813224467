import { Typography } from "@mui/material";
import axios from "axios";
import Formizo from "Components/LabIZO/Formizo";
import { VStack } from "Components/LabIZO/Stackizo";
import { Authority, ColorX, Env, store } from "static";
import { useEffect, useState } from "react";
import { DOMAIN } from "config/config";
import { schema } from "./schema";
import { DATA_LINK, backendConnector } from "connectors";

export interface IInitParam {
  initialwatsons: boolean;
  JWT: string;
  Watsons?: {
    URL: string;
    APIKEY: string;
    VERSION: string;
    EN: string;
    TC: string;
    SC: string;
  };
}

export const Initialize = () => {
  const [initParam, setInitParam] = useState({});
  useEffect(() => {
    Authority.Require("Initialize");

    const getInitParm = async () => {
      try {
        const { Success, payload } = await backendConnector.post(DATA_LINK.GetInitParam);
        if (!Success) store.Alert(payload.message);

        setInitParam(payload);
      } catch (e: any) {
        console.error(e);
        return { Success: false };
      }
    };
    getInitParm();
  }, []);
  let MountForm: any = () => {};
  const Init = {
    onClick: (formProps: { initialwatsons: boolean }) => {
      store.Ask(
        "Initialize project?",
        "(Caution: It will reset all data in database.)",
        () => {
          Init?.onSubmit(formProps);
        },
        null,
        false
      );
    },
    onSubmit: async (formProps: { initialwatsons: boolean }) => {
      store.SetAskLoading(true);
      let url = `${DOMAIN}/Base/Env/Init`;
      let payloadOut = {
        JWT: store.user.JWT,
        ...formProps,
      };

      console.log(payloadOut);

      try {
        let res = await axios.post(url, payloadOut);
        store.SetAskLoading(false);
        console.log("/Base/Env/Init", res.data);
        let { Success, payload } = res.data;
        if (Success === true) {
          Init.onSuccess(res, formProps);
          store.clearAsk();
          return { Success: true };
        } else {
          Init.onFail(payload);
          return { Success: false };
        }
      } catch (e: any) {
        Init.onFail(e);
        return { Success: false };
      }
    },
    onSuccess: async (res: any, formProps: { initialwatsons: boolean }) => {
      store.Alert("Project Initialized Successfully.", "success");
      if (formProps.initialwatsons) {
        let url = DOMAIN + "/Base/Env/InitWatsons";
        let payloadOut = {
          JWT: store.user.JWT,
        };

        try {
          let res = await axios.post(url, payloadOut);
          console.log("/Base/Env/InitWatsons", res.data);
          let { Success, payload } = res.data;
          if (Success === true) {
            await Env.CheckInitialized();
          } else {
            Init.onWatsonsFail(payload);
          }
        } catch (e: any) {
          Init.onWatsonsFail(e);
        }
      } else {
        store.isLoading(false);
        await Env.CheckInitialized();
      }
    },
    onFail: async (e: any) => {
      console.error(e);

      store.isLoading(false);
      store.Alert(e?.message || "Project Cannot be Initialized.", "error");
      await Env.CheckInitialized();
    },
    onWatsonsFail: async (e: any) => {
      console.error(e);
      store.isLoading(false);
      store.Alert("Watsons Cannot be Initialized.", "error");
      await Env.CheckInitialized();
    },
    onError: () => {
      store.isLoading(false);
      store.Alert("Cannot connect to server.", "error");
    },
  };

  return (
    <VStack padding={1} width="100%" height="80%">
      <Typography
        style={{
          textAlign: "center",
          width: "100%",
          fontSize: 25,
          color: ColorX.GetColorCSS("Primary"),
        }}
      >
        Initialize SYVA
      </Typography>

      <Formizo
        formID="init-form"
        schema={schema.initial}
        buttonPadding={0}
        width="50%"
        onSubmit={Init.onClick}
        onMounted={MountForm}
        fieldStyle="standard"
        fieldSize="small"
        errorsShowOnHelperText={false}
        buttons={["Revert", "Submit"]}
        theme={{
          textfield: {
            input: ColorX.GetColorCSS("Primary"),
            background: "transparent",
            line: "transparent",
          },
        }}
        addOns={{
          ConvDBTypes: [
            {
              _id: "CouchDB",
              caption: "CouchDB",
            },
            {
              _id: "ElasticSearch",
              caption: "Elastic Search",
            },
          ],
          IndexTimeframeTypes: [
            {
              _id: "YYYYMM",
              caption: "monthly",
            },
            {
              _id: "YYYYMMDD",
              caption: "daily",
            },
          ],
        }}
        defaultValue={initParam}
      />
    </VStack>
  );
};
