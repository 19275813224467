import React, { Component } from 'react';
import PropsType from 'prop-types';

import "./ZCMsg.css";

class ZCMSysMsg extends Component {

  static propTypes = {
    text: PropsType.string,
  }

  static defaultProps = {
    text: "",
  }

  constructor(){
    super();
    this.state = {};
  }

  componentDidMount(){
    this.setState((state, props) => ({ 
      ...props
    }));
  }

  static getDerivedStateFromProps(nextProps, prevStat){
    if(prevStat !== nextProps){
      return {
        ...nextProps
      }
    }
  }

  componentWillUnmount() {
    this.setState = (state, callback) => {
        return;
    };
  }

  render(){
    let { cssp, text } = this.state;
    return (
      <div className={cssp + " sysmsg"}>
        {text}
      </div>
    )
  }
}

export default ZCMSysMsg;