import { Button, Grid, Paper, SxProps } from "@mui/material";
import { DBAnswerSpec } from "interfaces";
import { VStack } from "Components/LabIZO/Stackizo";
import { ContextId } from "Pages/FLE/components";
import { IAllFLEAnswers, IEntityContextSetting, IFLEAnswer, IFLECondition, TFLEContext, TSystemContextSetting } from "interfaces/db.interfaces/fle.interface";
import { AnsCondButtons } from "./AnsCondButtons";

interface IAnswerConditionTableProps {
  contexts?: TFLEContext[];
  ansRef: React.MutableRefObject<IFLEAnswer | undefined>;
  selectedConditions: IFLECondition[];
  setSelectedConditions: (cond: IFLECondition[]) => void;
  answers: IAllFLEAnswers<DBAnswerSpec>;
}

export function AnsCondTable({ contexts, ansRef, selectedConditions, setSelectedConditions, ...props }: IAnswerConditionTableProps) {
  if (!contexts) return <></>;
  const GridItemProps = {
    container: true,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    item: true,
  };

  //set one condition
  const setCondition = (index: number, { key, value, operator }: any) => {
    let tempConditions: IFLECondition[] = contexts.map((c, i) => {
      return selectedConditions[i] || { key: c.alias.id, value: "default" };
    });

    tempConditions[index] = { key, value };
    if (operator) tempConditions[index].operator = operator;
    setSelectedConditions(tempConditions);

    const newAnsIdx = (props.answers.conditionals?.length || 0) + 1;

    ansRef.current = {
      conditions: tempConditions,
      answerDoc: {
        _id: `NewAns_${newAnsIdx}`,
      },
    };
  };

  return (
    <VStack sx={{ width: "100%" }}>
      {contexts.map((context, _i) => {
        const { setting, alias } = context;
        return (
          <Paper sx={{ width: "100%", m: 1, p: 1 }} key={`context-${_i}`}>
            <Grid container>
              <ContextIdField GridItemProps={GridItemProps} _i={_i} xs={1} />
              <ContextTypeField GridItemProps={GridItemProps} context={context} xs={1} />
              <ContextSettingTypeFeild setting={setting} GridItemProps={GridItemProps} xs={2} />

              <Grid container item xs={8} justifyContent="center" alignItems="center" display={"flex"} flexDirection={"row"} width="100%">
                <Grid {...GridItemProps} xs={2} container>
                  <Button
                    sx={{
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                    }}
                    variant={selectedConditions[_i]?.value == "default" ? "contained" : "text"}
                    onClick={() => setCondition(_i, { key: context.alias.id, value: "default" })}
                  >
                    Default
                  </Button>
                </Grid>
                <AnsCondButtons aliasID={alias?.id} setting={setting} setCondition={(condition) => setCondition(_i, condition)} condition={selectedConditions[_i]} index={_i} />
              </Grid>
            </Grid>
          </Paper>
        );
      })}
    </VStack>
  );
}
function ContextTypeField({ GridItemProps, context, xs }: { GridItemProps: any; context: TFLEContext; xs: number }) {
  return (
    <Grid {...GridItemProps} xs={xs}>
      {" "}
      {context.type}
    </Grid>
  );
}

function ContextIdField({ GridItemProps, _i, xs }: { GridItemProps: any; _i: number; xs: number }) {
  return (
    <Grid {...GridItemProps} xs={xs}>
      {<ContextId name={`contexts.${_i}.alias.id`} />}
    </Grid>
  );
}

function ContextSettingTypeFeild({ setting, GridItemProps, xs }: { setting: TSystemContextSetting | IEntityContextSetting; GridItemProps: any; xs: number }) {
  return (
    <>
      {setting.type != "entity" && (
        <Grid {...GridItemProps} xs={xs}>
          {setting.type}
        </Grid>
      )}
      {setting.type == "entity" && (
        <Grid
          {...GridItemProps}
          xs={1}
          sx={{
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
          }}
        >
          {setting.values.map((o) => o.entity).join(", ")}
        </Grid>
      )}
    </>
  );
}
