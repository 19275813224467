import React, { ReactElement, useEffect, useState } from "react";
import { IAuthority } from "interfaces";
import { backendConnector, DATA_LINK } from "connectors";
import { Accessor, Env, ErrorX, store, ColorX } from "static";
import { AuthorityDropDown } from "./AuthorityDropDown";
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Box } from "@mui/material";
import _ from "lodash";
import { CustomAccordion } from "Components/CustomAccordion";

interface IProps {
  mode: "Add" | "Edit" | "Info";
}

export const AuthorityTable = (props: IProps) => {
  const [roleConfig, setRoleConfig] = useState<IAuthority>({});
  useEffect(() => {
    getRoleConfig();
  }, []);
  const getRoleConfig = async () => {
    try {
      const data = await backendConnector.post(DATA_LINK.RoleConfig);
      setRoleConfig(data.payload.docs);
    } catch (e: any) {
      ErrorX.Handle(e);
    }
  };

  const extractArrays = (data: IAuthority, parentKey: string = ""): ReactElement[] => {
    return _.map(Object.entries(data), ([key, value]): ReactElement => {
      const keyName = parentKey ? parentKey + "." + key : key;
      if (Array.isArray(value)) {
        return (
          <TableRow key={keyName}>
            <TableCell>{key}</TableCell>
            <TableCell>
              <AuthorityDropDown keyName={keyName} dropDownList={value} mode={props.mode} roleConfig={roleConfig} />
            </TableCell>
          </TableRow>
        );
      } else if (typeof value === "object") {
        return (
          <CustomAccordion
            summary={
              <TableRow key={keyName}>
                <TableCell sx={{ fontWeight: "bold" }}>{key}</TableCell>
              </TableRow>
            }
            defaultExpanded={false}
            key={keyName}
          >
            <Box sx={{ pl: 3 }}>{renderTable(value, keyName)}</Box>
          </CustomAccordion>
        );
      }
      return <></>;
    });
  };
  const renderTable = (_roleConfig: IAuthority, parentKey: string = ""): ReactElement => {
    return (
      <TableContainer style={{ padding: "10px" }} component={Paper}>
        <Table>
          {renderTableHeader()}
          <TableBody>{extractArrays(_roleConfig, parentKey)}</TableBody>
        </Table>
      </TableContainer>
    );
  };

  const renderTableHeader = () => {
    return (
      <TableHead>
        <TableRow>
          <TableCell>Pages</TableCell>
          <TableCell>Permission</TableCell>
        </TableRow>
      </TableHead>
    );
  };

  return <>{renderTable(roleConfig)}</>;
};
