import { createContext, useContext } from "react";
import { ChartSetting } from "../Interface/setting.interface";

interface ISettingContext {
  setting: ChartSetting;
  updateSetting: (newSetting: Partial<ChartSetting>) => void;
  getData: (setLoading?: (state: boolean) => void) => void;
}

//For Setting Panel
export const ChartSettingContext = createContext<ISettingContext | null>(null);

export const useSettingContext = () => {
  const context = useContext(ChartSettingContext);
  if (!context) {
    throw new Error("useChatBotContext must be used within a AddOnsProvider");
  }
  return context;
};
