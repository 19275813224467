import { DatumizoBase } from "Components/LabIZO/Datumizo/DatumizoProps.interface";
import { DATA_LINK } from "connectors";
import schema from "./schema";

export interface IDefaultProps {
  title?: string;
  base?: DatumizoBase;
}

export const defaultProps: IDefaultProps = {
  title: "User Access Control",
  base: {
    title: "User",
    exportDoc: "users",
    schema: schema,
    reqAuth: "UserControl.User",
    columnAuth: "User",

    noDefaultTable: false,
    noDefaultButtons: false,

    tablizo: {
      columnsToolbar: true,
      filterToolbar: true,
      densityToolbar: true,
      exportToolbar: false,
      density: "standard", //compact, standard, comfortable
      defaultPageSize: 50,
      showSelector: true,
    },

    formizo: {},

    Connect: {
      DBInfo: DATA_LINK.UserInfo,
      List: DATA_LINK.UserList,
      SetColumnVisibilitySetting: DATA_LINK.UserSetColumnVisibilitySetting,
      schema: schema.Table,
    },

    operations: {
      Add: {
        title: "Add User",
        url: DATA_LINK.UserAdd,
        success: "User Added Successfully",
        fail: "User Add Failed: ",
        schema: schema.Add,
        buttons: ["Clear", "Submit"],
        onSubmit: "Add",
      },
      Delete: {
        title: "Delete this User?",
        content: "Caution: This is irrevertable.",
        url: DATA_LINK.UserDelete,
        success: "User Deleted Successfully.",
        fail: "User Delete Failed: ",
        onSubmit: "Delete",
      },
      Edit: {
        title: "Edit User ",
        url: DATA_LINK.UserEdit,
        success: "User Edited Successfully",
        fail: "User Edit Failed: ",
        schema: schema.Edit,
        buttons: ["Revert", "Submit"],
        onSubmit: "Edit",
      },
      Info: {
        title: "User Access Control ",
        url: DATA_LINK.UserInfo,
        success: "User Access Control Load Successfully",
        fail: "User Access Control Load Failed: ",
        schema: schema.Info,
        readOnly: true,
      },
      Import: {
        title: "User Import",
        content: "",
        url: DATA_LINK.UserImport,
        success: "User Imported Successfully.",
        fail: "User Import Failed: ",
        schema: schema.ImportFormat,
        replace: false,
      },
      Export: {
        url: DATA_LINK.UserExport,
        schema: schema.Export,
      },
      DeleteBulk: {
        title: (n: number) => "Delete these " + n + " User?",
        content: "Caution: This is irrevertable.",
        url: DATA_LINK.UserDeleteBulk,
        success: "User Deleted Successfully.",
        fail: "User Delete Failed: ",
        onSubmit: "DeleteBulk",
      },
    },

    buttons: {
      inline: [
        { icon: "edit", func: "Edit", caption: "Edit", reqFunc: "Edit" },
        { icon: "info", func: "Info", caption: "Details" },
        { icon: "delete", func: "Delete", caption: "Delete", reqFunc: "Delete" },
      ],
      left: [{ icon: "add", func: "Add", caption: "Add User", reqFunc: "Add" }],
      right: [
        { icon: "deletebulk", func: "DeleteBulk", caption: (n) => "Delete (" + n + ")", reqFunc: "Delete", theme: "caution" },
        //{ icon: "export", func: "Export", caption: (n) => "Export (" + (n === 0 ? "All" : n) + ")", reqFunc: "Export" },
        //{ icon: "import", func: "Import", caption: "Import", reqFunc: "Import" },
      ],
    },
  },
};
