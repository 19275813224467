import { TextField } from "@mui/material";
import { AvailLANG, DBAnswerSpec } from "interfaces";
import { useContext } from "react";
import { VStack } from "Components/Layouts";
import { FLEFormContext } from "../hooks/FLEContexts";

export const MultilangAnswerFeilds = ({
  langs = Object.values(AvailLANG),
  answerDoc,
  onChangeAnswerDoc = (answerDoc) => {},
  ...props
}: {
  langs?: AvailLANG[];
  answerDoc: DBAnswerSpec;
  onChangeAnswerDoc?: (answerDoc: DBAnswerSpec) => void;
  disabled?: boolean;
}) => {
  const formCtxt = useContext(FLEFormContext);
  if (!answerDoc?.text) {
    langs.map((lang) => {
      answerDoc = {
        ...answerDoc,
        text: {
          ...answerDoc?.text,
          [lang]: [""],
        },
      };
    });
  }

  langs.map((lang) => {
    if (!answerDoc.text[lang]) answerDoc.text[lang] = [""];
  });

  const ansText = answerDoc.text;

  return (
    <VStack width="100%">
      {langs.map((lang) => {
        return (
          <TextField
            fullWidth
            multiline
            key={lang}
            label={lang}
            value={ansText[lang]![0]}
            onChange={(e) => {
              ansText[lang]![0] = e.target.value;
              onChangeAnswerDoc({
                ...answerDoc,
                text: ansText,
              });
            }}
            disabled={props.disabled || formCtxt?.mode == "Info"}
          />
        );
      })}
    </VStack>
  );
};
