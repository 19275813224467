import { Button, Grid, Paper, Tab, Tabs, Typography } from "@mui/material";
import { Menu, MenuItem } from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import { Box } from "@mui/system";
import { Authority, ColorX } from "static";
import _ from "lodash";
import { useMemo, useState } from "react";
import { ChatTab, tabs } from "./tabs";
import { HStack, VStack } from "Components";
import { Spacer } from "Components/LabIZO/Stackizo";
import { AvailLANG, CusAuth } from "interfaces";
import { DetailsPanel } from "./components/DetailsPanel/DetailsPanel";
import { ITalk2VAAddOns } from "./interfaces/combined-talk2va-addons.interface";
import { StyledPageGrid, StyledChatInterface, StyledSettingInterface } from "./components/styled-components";
import { AddOnsContext } from "./hooks/setting.context";

const Talk2VA: React.FC<{}> = () => {
  const [selectedTab, setSelectedTab] = useState<number>(0);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [showSetting, setShowSetting] = useState<boolean>(false);
  const [showDetails, setShowDetails] = useState<boolean>(false);
  const [addOns, setAddOns] = useState<ITalk2VAAddOns>({
    project: ["All"],
    page: "All",
    tasks: ["Chatbot"],
    optionalParams: {
      Summary: {
        wordCount: 100,
        documentType: "paragraph",
        lang: AvailLANG.EN,
      },
      Composition: {
        wordCount: 100,
        documentType: "email",
        lang: AvailLANG.EN,
      },
    },
    LLM: {
      prompt: "",
      temperature: 0.7,
      topP: 1,
    },
  });

  const authorisedTabs = useMemo(() => tabs.filter(({ reqAuth, reqLevel, reqFunc }: ChatTab) => reqAuth === "None" || Authority.IsAccessibleQ(reqAuth, reqLevel, reqFunc)), []);

  const themeColor = ColorX.GetColorCSS("Primary");

  const updateAddOns = (newAddOns: Partial<ITalk2VAAddOns>) => {
    setAddOns((prevAddOns) => ({ ...prevAddOns, ...newAddOns }));
  };

  const calculateGridSize = (hasSettingComponent: boolean) => {
    if (!showSetting && !showDetails) {
      return { lg: 12, xs: 12 };
    } else if (!hasSettingComponent && showDetails) {
      return { lg: 8, xs: 8 };
    } else if (!hasSettingComponent && !showDetails) {
      return { lg: 12, xs: 12 };
    } else if (showSetting && !showDetails) {
      return { lg: 9, xs: 9 };
    } else if (!showSetting && showDetails) {
      return { lg: 8, xs: 8 };
    } else {
      return { lg: 5, xs: 8 };
    }
  };

  const renderTabPanels = (tabs: ChatTab[]) => {
    const preLoad = false;
    return tabs.map((tab, i) => {
      if (!preLoad && selectedTab !== i) return <Box key={i} />;
      const { settingComponent, detailsComponentOveride, render, reqAuth, qaMode, allowSuggestedIntent, name } = tab;
      const gridSize = calculateGridSize(!!tab.settingComponent);
      if (!detailsComponentOveride && !qaMode) throw new Error("QAMode must be specified if tab does not have detailsComponentOveride");
      return (
        <StyledPageGrid tabs={tabs} key={i}>
          <AddOnsContext.Provider value={{ addOns, updateAddOns }}>
            {settingComponent && showSetting && (
              <Grid item lg={3} style={{ height: "100%" }}>
                <StyledSettingInterface>{settingComponent(addOns, setAddOns)}</StyledSettingInterface>
              </Grid>
            )}

            <Grid item lg={gridSize.lg} xs={gridSize.xs} style={{ height: "100%" }}>
              <StyledChatInterface>
                <VStack key={i} hidden={selectedTab !== i} sx={{ width: "100%", height: "100%", boxSizing: "border-box" }}>
                  {typeof render === "function" ? render(addOns, setAddOns) : render}
                </VStack>
              </StyledChatInterface>
            </Grid>
          </AddOnsContext.Provider>
          {showDetails && (
            <Grid item lg={4} xs={4} style={{ height: "100%" }}>
              <VStack key={i} hidden={selectedTab !== i} sx={{ width: "100%", height: "100%" }}>
                {detailsComponentOveride ? (
                  detailsComponentOveride(addOns, setAddOns)
                ) : (
                  <DetailsPanel allowSuggestedIntent={allowSuggestedIntent} qaMode={qaMode!} addOns={addOns} reqAuth={reqAuth} reqFunc={CusAuth.Details} />
                )}
              </VStack>
            </Grid>
          )}
        </StyledPageGrid>
      );
    });
  };

  const renderSettingPanel = () => {
    const handleMenuClick = (event: React.MouseEvent<HTMLButtonElement>) => {
      setAnchorEl(event.currentTarget);
    };
    const handleMenuClose = () => {
      setAnchorEl(null);
    };
    const toggleSetting = () => {
      setShowSetting(!showSetting);
      handleMenuClose();
    };
    const toggleDetails = () => {
      setShowDetails(!showDetails);
      handleMenuClose();
    };
    return (
      <VStack sx={{ marginLeft: "auto" }}>
        <Button id="basic-button" aria-controls={anchorEl ? "basic-menu" : undefined} aria-haspopup="true" aria-expanded={Boolean(anchorEl) ? "true" : undefined} onClick={handleMenuClick}>
          <MenuIcon />
        </Button>
        <Menu id="simple-menu" anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={handleMenuClose}>
          {/* <MenuItem
            onClick={toggleSetting}
            sx={{ color: showSetting ? themeColor : "inherit", fontWeight: showSetting ? "bold" : "normal", borderLeft: showSetting ? `4px solid ${themeColor}` : "none" }}
          >
            Setting
          </MenuItem> */}
          <MenuItem
            onClick={toggleDetails}
            sx={{ color: showDetails ? themeColor : "inherit", fontWeight: showDetails ? "bold" : "normal", borderLeft: showDetails ? `4px solid ${themeColor}` : "none" }}
          >
            Details
          </MenuItem>
        </Menu>
      </VStack>
    );
  };

  const renderTabButtons = (tabs: ChatTab[]) => {
    let buttonLabel: JSX.Element;
    return tabs.map((tab: ChatTab, i) => {
      const { label, icon, iconPos, noTransform, spacing, alignment, disabled, minHeight, minWidth } = tab;

      if (noTransform) {
        buttonLabel = <Typography style={{ height: "100%", textTransform: "none" }}>{label}</Typography>;
      }
      switch (iconPos) {
        case "bottom":
          buttonLabel = (
            <VStack gap={spacing || 5}>
              {buttonLabel}
              {icon}
            </VStack>
          );
          break;
        case "left":
          buttonLabel = (
            <HStack gap={spacing || 5}>
              {alignment === "right" && <Spacer />}
              {icon}
              {label}
              {alignment === "left" && <Spacer />}
            </HStack>
          );
          break;
        case "right":
          buttonLabel = (
            <HStack gap={spacing || 5}>
              {alignment === "right" && <Spacer />}
              {label}
              {icon}
              {alignment === "left" && <Spacer />}
            </HStack>
          );
          break;
        default:
          break;
      }
      return (
        <Tab
          key={i} //
          label={!iconPos || iconPos === "top" ? label : buttonLabel}
          icon={iconPos === "top" ? icon : undefined}
          disabled={disabled}
          style={{ color: ColorX.GetColorCSS("Primary"), minHeight: minHeight || "100%", minWidth: minWidth || 200 }}
        />
      );
    });
  };

  const onChangeTab = (_e: any, tab: number) => {
    setSelectedTab(tab);
  };

  return (
    <VStack className="TestChat" width="calc(100vw - 40px)" sx={{ background: "#f0f8ff", height: "100%" }}>
      <VStack className={"centre"} style={{ width: "100%", minHeight: "100%", height: "100%", justifyContent: "flex-start", gap: 0 }}>
        <Paper style={{ width: "100%", zIndex: 3 }}>
          <Tabs
            TabIndicatorProps={{ style: { background: ColorX.GetColorCSS("Primary") } }}
            value={selectedTab}
            onChange={onChangeTab}
            sx={{ backgroundColor: ColorX.GetColorCSS("Secondary"), color: themeColor, height: "50px" }}
            variant="scrollable"
            scrollButtons={"auto"}
            allowScrollButtonsMobile={true}
          >
            {renderTabButtons(authorisedTabs)}
            {renderSettingPanel()}
          </Tabs>
        </Paper>

        {renderTabPanels(authorisedTabs)}
      </VStack>
    </VStack>
  );
};

export default Talk2VA;
