import { DatumizoBase } from "Components/LabIZO/Datumizo/DatumizoProps.interface";
import { DATA_LINK } from "../../../connectors/datalink";
import schema from "./constants/schema/schema";
import DocumentManagerForm from "./DocumentManagerForm/DocumentManagerForm";
import { DocumentUploadForm } from "./DocumentUploadForm";
import DocumentReuploadForm from "./DocumentReuploadForm/DocumentReuploadForm";

export interface IDefaultProps {
  title?: string;
  base?: DatumizoBase;
}
export const defaultProps: IDefaultProps = {
  title: "Document Manager",
  base: {
    title: "Document Manager",
    exportDoc: "GPTQA.DocumentManager",
    schema: schema,
    reqAuth: "GPTQA.DocumentManager",
    columnAuth: "DocumentManager",
    allowBackToTop: true,

    tablizo: {
      showSelector: true,
      columnsToolbar: true,
      filterToolbar: true,
      densityToolbar: true,
      exportToolbar: false,
    },

    Connect: {
      DBInfo: DATA_LINK.DMDBInfo,
      List: DATA_LINK.DMList,
      SetColumnVisibilitySetting: DATA_LINK.DMSetColumnVisibilitySetting,
      schema: schema.Table,
    },

    operations: {
      Add: {
        title: "Create Document",
        url: DATA_LINK.DMAdd,
        success: "Document Added Successfully",
        fail: "Document Add Failed: ",
        schema: schema.Add,
        buttons: ["Clear", "Submit"],
        Custom: (id, row, onQuit, onQuitRefresh, renderFormizo) => <DocumentUploadForm {...{ id, row, onQuit, onQuitRefresh, renderFormizo, mode: "Add" }} />,
      },
      Delete: {
        title: "Delete this Document?",
        content: "Caution: This is irrevertable.",
        url: DATA_LINK.DMDelete,
        success: "Document Deleted Successfully.",
        fail: "Document Delete Failed: ",
        onSubmit: "Delete",
      },
      Edit: {
        title: "Edit Document",
        url: DATA_LINK.DMEdit,
        success: "Document Edited Successfully",
        fail: "Document Edit Failed: ",
        schema: schema.Edit,
        buttons: ["Revert", "Submit"],
        Custom: (id, row, onQuit, onQuitRefresh, renderFormizo) => <DocumentManagerForm {...{ id, row, onQuit, onQuitRefresh, renderFormizo, mode: "Edit" }} />,
      },
      Info: {
        title: "Document DETAILS",
        schema: schema.Info,
        readOnly: true,
        buttons: [],
        Custom: (id, row, onQuit, onQuitRefresh, renderFormizo) => <DocumentManagerForm {...{ id, row, onQuit, onQuitRefresh, renderFormizo, mode: "Info" }} />,
      },
      Reupload: {
        title: "Document Re-upload",
        url: DATA_LINK.DMCompare,
        buttons: [],
        Custom: (id, row, onQuit, onQuitRefresh, renderFormizo) => <DocumentReuploadForm {...{ id, row, onQuit, onQuitRefresh, renderFormizo }} />,
      },
      Sync: {
        success: "Document Sync Successfully",
        fail: "Document Sync Failed: ",
        url: DATA_LINK.DMSync,
        title: "Sync Doc from GPTQA",
        content: "Caution: This is irrevertable.",
      },

      DeleteBulk: {
        title: (n: number) => "Delete these " + n + " Documents?",
        content: "Caution: This is irrevertable.",
        url: DATA_LINK.DMDeleteBulk,
        success: "Documents Deleted Successfully.",
        fail: "Documents Delete Failed: ",
        onSubmit: "DeleteBulk",
      },
      Enable: {
        title: (n: number) => "Enable these " + n + " Documents?",
        url: DATA_LINK.DMEnable,
        success: "Documents Enabled Successfully.",
        fail: "Documents Enabled Failed.",
        onSubmit: "Enable",
      },
      Disable: {
        title: (n: number) => "Disable these " + n + " Documents?",
        url: DATA_LINK.DMEnable,
        success: "Documents Disabled Successfully",
        fail: "Documents Disabled Failed. ",
        onSubmit: "Disable",
      },
    },

    buttons: {
      inline: [
        { icon: "edit", func: "Edit", caption: "Edit", reqFunc: "Edit" },
        { icon: "info", func: "Info", caption: "Details" },
        { icon: "upload", func: "Reupload", caption: "Reupload", reqFunc: "Reupload" },
      ],
      inlineOpposite: [{ icon: "delete", func: "Delete", caption: "Delete", reqFunc: "Delete" }],
      left: [{ icon: "add", func: "Add", caption: "Add Document", reqFunc: "Add" }],
      right: [
        { icon: "deletebulk", func: "DeleteBulk", caption: (n: number) => "Delete (" + n + ")", reqFunc: "Delete", theme: "caution" },
        { icon: "syncto", func: "Sync", caption: "Sync", reqFunc: "Sync" },
        { icon: "enable", func: "Enable", caption: (n: number) => "Enable (" + n + ")", reqFunc: "Enable" },
        { icon: "disable", func: "Disable", caption: (n: number) => "Disable (" + n + ")", reqFunc: "Enable" },
        // { icon: "export", func: "Export", caption: (n: number) => "Export (" + (n === 0 ? "All" : n) + ")", reqFunc: "Export" },
        // {
        //   icon: "import",
        //   func: "Import",
        //   caption: "Import",
        //   reqFunc: "Import",
        // },
      ],
    },
  },
};
