export enum DATA_LINK {
  // BE APIs
  // Answer
  AnswerDBInfo = "/Tables/Answer/Info",
  AnswerList = "/Tables/Answer/List",
  AnswerEdit = "/Tables/Answer/Edit",
  AnswerAdd = "/Tables/Answer/Add",
  AnswerDelete = "/Tables/Answer/Delete",
  AnswerDeleteBulk = "/Tables/Answer/DeleteBulk",
  AnswerExport = "/Tables/Answer/Export",
  AnswerImport = "/Tables/Answer/Import",
  AnswerSetColumnVisibilitySetting = `/Tables/Answer/SetColumnVisibilitySetting`,

  //Document Manager
  DMDBInfo = "/Tables/DocumentManager/Info",
  DMList = `/Tables/DocumentManager/List`,
  DMAdd = `/Tables/DocumentManager/Add`,
  DMDelete = `/Tables/DocumentManager/Delete`,
  DMEdit = `/Tables/DocumentManager/Edit`,
  DMUpload = "Tables/DocumentManager/Upload",
  DMCompare = "/Tables/DocumentManager/Compare",
  DMExport = `/Tables/DocumentManager/Export`,
  DMImport = `/Tables/DocumentManager/Import`,
  DMDeleteBulk = `/Tables/DocumentManager/DeleteBulk`,
  DMGetContext = "/Tables/DocumentManager/GetContext",
  DMSummaries = "/Tables/DocumentManager/Summarize",
  DMGetEmbeddings = "/Tables/DocumentManager/GetEmbeddings",
  DMGetPrcessedText = "/Tables/DocumentManager/GetProcessedText",
  DMSync = "/Tables/DocumentManager/Sync",
  DMEnable = "/Tables/DocumentManager/Enable",
  DMDownload = "/Tables/DocumentManager/Download",
  DMChart = "/Tables/DocumentManager/Chart",
  DMSetColumnVisibilitySetting = "/Tables/DocumentManager/SetColumnVisibilitySetting",

  // MW APIs
  Talk2GPT = "/Talk2VA",
  Talk2GPTStream = "/Talk2VA/stream/GetGPTResponseChunk",
  PlaygroundStream = "/Talk2VA/stream/GetLLMReponseChunk",
  Talk2GPTIntermediateResponse = "/Talk2VA/stream/GetIntermediateResponse",
  Talk2GPTRegenerate = "/Talk2VA/stream/RegenerateResponse",
  RemoveHistory = "/Talk2VA/stream/RemoveHistory",

  //T2VA Trainer
  Talk2VALog = "/Tables/Talk2VA/Log",
  T2VATrainerInfo = "/Tables/Talk2VA/Info",
  T2VATrainerList = "/Tables/Talk2VA/List",
  T2VATrainerAdd = "/Tables/Talk2VA/Add",
  T2VATrainerDelete = "/Tables/Talk2VA/Delete",
  T2VATrainerEdit = "/Tables/Talk2VA/Edit",
  T2VATrainerExport = "/Tables/Talk2VA/ExportAll",
  T2VATrainerSync = "/Tables/Talk2VA/Sync",
  T2VATrainerLearn = "/Tables/Talk2VA/Learn",
  T2VATrainerPending = "/Tables/Talk2VA/Pending",
  T2VATrainerReject = "/Tables/Talk2VA/Reject",
  T2VATrainerPass = "/Tables/Talk2VA/Pass",
  T2VATrainerDeleteBulk = "/Tables/Talk2VA/DeleteBulk",

  //Training
  TrainingDBInfo = "/Tables/Training/Info",
  TrainingList = "/Tables/Training/List",
  TrainingGet = "/Tables/Training/Get",
  TrainingAdd = "/Tables/Training/Add",
  TrainingDelete = "/Tables/Training/Delete",
  TrainingExport = "/Tables/Training/Export",
  TrainingTrain = "/Tables/Training/Train",
  TrainingUpdateSummary = "/Tables/Training/UpdateSummary",
  TrainingImport = "/Tables/Training/Import",
  TrainingImportInfo = "/Tables/Training/ImportInfo",
  TrainingGetConvScore = "/Tables/Training/GetConvScore",
  TrainingSetColumnVisibilitySetting = "/Tables/Training/SetColumnVisibilitySetting",

  TBankDBInfo = "/Tables/TrainingBank/Info",
  TBankList = "/Tables/TrainingBank/List",
  TBankGet = "/Tables/TrainingBank/Get",
  TBankAdd = "/Tables/TrainingBank/Add",
  TBankEdit = "/Tables/TrainingBank/Edit",
  TBankDelete = "/Tables/TrainingBank/Delete",
  TBankExport = "/Tables/TrainingBank/Export",
  TBankAccept = "/Tables/TrainingBank/Accept",
  TBankNewIntent = "/Tables/TrainingBank/NewIntent",
  TBankSuggestedIntent = "/Tables/TrainingBank/SuggestedIntent",

  //Feedback
  UserFeedback = `/Tables/Convlog/Feedback`,

  //Convlog
  ConvlogDBInfo = "/Tables/Convlog/Info",
  ConvlogList = "/Tables/Convlog/List",
  ConvlogExport = "/Tables/Convlog/Export",
  ConvlogListById = "/Tables/Convlog/ListByID",
  ConvlogSetColumnVisibilitySetting = "/Tables/Convlog/SetColumnVisibilitySetting",

  //Rating Report
  RatingReportDBInfo = "/Tables/RatingReport/Info",
  RatingReportList = "/Tables/RatingReport/List",
  RatingReportExport = "/Tables/RatingReport/Export",
  RatingReportSetColumnVisibilitySetting = "/Tables/RatingReport/SetColumnVisibilitySetting",

  //RequestLog
  RequestLogDBInfo = "/Tables/RequestLog/Info",
  RequestLogList = `/Tables/RequestLog/List`,
  RequestLogExport = `/Tables/RequestLog/Export`,
  RequestLogSetColumnVisibilitySetting = `/Tables/RequestLog/SetColumnVisibilitySetting`,

  //GPTConvLog
  GPTConvDBInfo = "/Tables/GPTConvlog/Info",
  GPTConvList = `/Tables/GPTConvlog/List`,
  GPTConvExport = `/Tables/GPTConvlog/Export`,
  GPTConvSetColumnVisibilitySetting = `/Tables/GPTConvlog/SetColumnVisibilitySetting`,

  //Intenet
  IntentDBInfo = "/Tables/Intent/Info",
  IntentList = `/Tables/Intent/List`,
  IntentAdd = `/Tables/Intent/Add`,
  IntentDelete = `/Tables/Intent/Delete`,
  IntentEdit = `/Tables/Intent/Edit`,
  IntentExport = `/Tables/Intent/Export`,
  IntentImport = `/Tables/Intent/Import`,
  IntentDeleteBulk = `/Tables/Intent/DeleteBulk`,
  IntentSync = `/Tables/Intent/Sync`,
  IntentGenerate = "/Data/Intent/Generate",
  IntentSetColumnVisibilitySetting = `/Tables/Intent/SetColumnVisibilitySetting`,

  //Entity
  EntityDBInfo = "/Tables/Entity/Info",
  EntityList = `/Tables/Entity/List`,
  EntityAdd = `/Tables/Entity/Add`,
  EntityDelete = `/Tables/Entity/Delete`,
  EntityEdit = `/Tables/Entity/Edit`,
  EntityExport = `/Tables/Entity/Export`,
  EntityImport = `/Tables/Entity/Import`,
  EntityDeleteBulk = `/Tables/Entity/DeleteBulk`,
  EntitySync = `/Tables/Entity/Sync`,
  EntitySetColumnVisibilitySetting = `/Tables/Entity/SetColumnVisibilitySetting`,

  //System
  //General
  SYSGeneral = "/Config/System/Info",
  SYSGeneralEdit = "/Config/System/Edit",

  //Back up and Restore
  SYSBackupRestore = "/Data/General/Info",
  SYSBackupRestoreDelete = "/Data/General/Delete",
  SYSBackupRestoreAddtag = "/Data/General/AddTag",
  SYSIncludeToggle = "/Config/Database/Include",
  SYSBackup = "/Data/General/Backup",
  SYSDBImport = "/Data/General/Import",
  SYSWatsonImport = "/Data/Watsons/Import",
  SYSRestore = "/Data/General/Restore",

  //User Backup and Restore
  SYSUserBnRImport = "/Data/General/UserBnRImport",
  SYSUserBnRDownload = "/Data/General/UserBnRDownload",

  //Intent Generation
  SYSIntentGeneration = "/Config/IntentGeneration/Info",
  SYSIntentGenerationEdit = "/Config/IntentGeneration/Edit",

  //NLP Setting
  SYSGPTConfig = `/Config/GPTSystem/Info`,
  SYSGPTConfigEdit = `/Config/GPTSystem/Edit`,
  SYSSyncDialog = `/Data/Watsons/SyncDialogFlow`,

  //Chatbot
  SYSMiddlewareInfo = "/Config/Middleware/Info",
  SYSMiddlewareEdit = "/Config/Middleware/Edit",
  SYSMiddlewareModel = "/Config/Middleware/Model",

  //Spam Setting
  SYSSpamConfig = "/Config/Spam/Info",
  SYSSpamConfigEdit = "/Config/Spam/Edit",

  //Disclaimer
  SYSDisclaimerInfo = "/Config/Disclaimer/Info",
  SYSDisclaimerEdit = "/Config/Disclaimer/Edit",

  // Email Config
  SYSEmailInfo = "/Config/Email/Info",
  SYSEmailEdit = "/Config/Email/Edit",

  // Channel Config
  SYSChannelInfo = "/Config/Channel/Info",
  SYSChannelEdit = "/Config/Channel/Edit",

  //Model
  SYSModelInfo = "/Config/GPTModel/Info",
  SYSModelEdit = "/Config/GPTModel/Edit",

  // Content Filter
  SYSContentFilterInfo = "/Config/ContentFilter/Info",
  SYSContentFilterEdit = "/Config/ContentFilter/Edit",

  //Topic Clustering
  SysTopicClusterInfo = "/Config/TopicCluster/Info",
  SysTopicClusterEdit = "/Config/TopicCluster/Edit",

  //Chatbot Arena
  ArenaInfo = "/Tables/Arena/Info",
  ArenaList = "/Tables/Arena/List",
  ArenaExport = "/Tables/Arena/Export",
  ArenaConfigInfo = "/Config/Arena/Info",
  ArenaConfigEdit = "/Config/Arena/Edit",
  ArenaSetColumnVisibilitySetting = "/Tables/Arena/SetColumnVisibilitySetting",

  //Knowledge Group
  KGDBInfo = "/Tables/GPTKnowledgeGroup/Info",
  KGList = `/Tables/GPTKnowledgeGroup/List`,
  KGAdd = `/Tables/GPTKnowledgeGroup/Add`,
  KGDelete = `/Tables/GPTKnowledgeGroup/Delete`,
  KGEdit = `/Tables/GPTKnowledgeGroup/Edit`,
  KGUpload = "Tables/GPTKnowledgeGroup/Upload",
  KGExport = `/Tables/GPTKnowledgeGroup/Export`,
  KGImport = `/Tables/GPTKnowledgeGroup/Import`,
  KGDeleteBulk = `/Tables/GPTKnowledgeGroup/DeleteBulk`,
  KGEnable = "/Tables/GPTKnowledgeGroup/Enable",
  KGSetColumnVisibilitySetting = `/Tables/GPTKnowledgeGroup/SetColumnVisibilitySetting`,

  //Website Manager
  WebsiteDBInfo = "/Tables/GPTWebsite/Info",
  WebsiteList = `/Tables/GPTWebsite/List`,
  WebsiteAdd = `/Tables/GPTWebsite/Add`,
  WebsiteDelete = `/Tables/GPTWebsite/Delete`,
  WebsiteEdit = `/Tables/GPTWebsite/Edit`,
  WebsiteDeleteBulk = `/Tables/GPTWebsite/DeleteBulk`,
  WebsiteEnable = "/Tables/GPTWebsite/Enable",
  WebsiteExport = `/Tables/GPTWebsite/Export`,
  WebsiteImport = `/Tables/GPTWebsite/Import`,
  WebsiteSync = `/Tables/GPTWebsite/Sync`,
  WebsiteSetColumnVisibilitySetting = `/Tables/GPTWebsite/SetColumnVisibilitySetting`,

  //Base APIs
  GetName = "/Base/Name/Get",
  IsInitialized = "/Base/Env/IsInitialized",
  GetInitParam = "/Base/Env/GetInitParam",
  RefreshToken = "/Base/Authorize/RefreshToken",
  ForgotPassword = "/Base/Authorize/ForgotPassword",
  CheckUserName = "/Base/Authorize/CheckUserName",
  MFAVerify = "/Base/Authorize/MFAVerify",
  SignIn = "/Base/Authorize/SignIn",
  SignOut = "Base/Authorize/SignOut",

  //User
  SYSUserProfile = "/Base/User/Profile",
  UserInfo = "/Base/User/Info",
  UserList = "/Base/User/List",
  UserAdd = "/Base/User/Add",
  UserEdit = "/Base/User/Edit",
  UserDelete = "/Base/User/Delete",
  UserDeleteBulk = "/Base/User/DeleteBulk",
  UserExport = "/Base/User/Export",
  UserImport = "/Base/User/Import",
  UserChangeEmail = "/Base/User/ChangeEmail",
  UserChangePassword = "/Base/User/ChangePassword",
  UserUnlock = "/Base/User/Unlock",
  UserSetColumnVisibilitySetting = "/Tables/User/SetColumnVisibilitySetting",

  //Role
  RoleList = "/Base/Role/List",
  RoleInfo = "/Base/Role/Info",
  RoleAdd = "/Base/Role/Add",
  RoleEdit = "/Base/Role/Edit",
  RoleDelete = "/Base/Role/Delete",
  RoleDeleteBulk = "/Base/Role/DeleteBulk",
  RoleConfig = "/Base/Role/RolesConfig",
  RoleSetColumnVisibilitySetting = "/Tables/Role/SetColumnVisibilitySetting",

  //Instance
  InstanceList = "/Base/Instance/List",
  InstanceInfo = "/Base/Instance/Info",
  InstanceAdd = "/Base/Instance/Add",
  InstanceEdit = "/Base/Instance/Edit",
  InstanceDelete = "/Base/Instance/Delete",
  InstanceDeleteBulk = "/Base/Instance/DeleteBulk",

  //FAQ
  FAQDBInfo = "/Tables/FAQ/Info",
  FAQList = "/Tables/FAQ/List",
  FAQAdd = "/Tables/FAQ/Add",
  FAQDelete = "/Tables/FAQ/Delete",
  FAQEdit = "/Tables/FAQ/Edit",
  FAQExport = "/Tables/FAQ/Export",
  FAQImport = "/Tables/FAQ/Import",
  FAQDeleteBulk = "/Tables/FAQ/DeleteBulk",
  FAQEnable = "/Tables/FAQ/Enable",

  //FLE
  FAQAnalyse = "/Tables/FAQ/Analyse",
  FAQConfirmImport = "/Tables/FAQ/ConfirmImport",
  FAQSetColumnVisibilitySetting = "/Tables/FAQ/SetColumnVisibilitySetting",

  //FLE
  FLEDBInfo = "/Tables/FLE/Info",
  FLEList = "/Tables/FLE/List",
  FLEAdd = "/Tables/FLE/Add",
  FLEDelete = "/Tables/FLE/Delete",
  FLEEdit = "/Tables/FLE/Edit",
  FLEExport = "/Tables/FLE/Export",
  FLEImport = "/Tables/FLE/Import",
  FLEDeleteBulk = "/Tables/FLE/DeleteBulk",
  FLEEnable = "/Tables/FLE/Enable",
  FLESetColumnVisibilitySetting = "/Tables/FLE/SetColumnVisibilitySetting",

  //Counter Example
  CounterExampleDBInfo = "/Tables/CounterExample/Info",
  CounterExampleList = "/Tables/CounterExample/List",
  CounterExampleAdd = "/Tables/CounterExample/Add",
  CounterExampleDelete = "/Tables/CounterExample/Delete",
  CounterExampleEdit = "/Tables/CounterExample/Edit",
  CounterExampleExport = "/Tables/CounterExample/Export",
  CounterExampleImport = "/Tables/CounterExample/Import",
  CounterExampleDeleteBulk = "/Tables/CounterExample/DeleteBulk",
  CounterExampleSync = "/Tables/CounterExample/Sync",
  CounterExampleSetColumnVisibilitySetting = "/Tables/CounterExample/SetColumnVisibilitySetting",

  //Arena , Arena Log (AL)
  Evaluate = "/Tables/Arena/Evaluate",
  ALeaderboard = "/Tables/Arena/Leaderboard",

  // Data Events / Audit Logs
  DataEventGet = "/Tables/DataEventLog/Get",
  AuditLogList = "/Tables/AuditLog/List",
  AuditLogExport = "/Tables/AuditLog/Export",
  AuditLogSetColumnVisibilitySetting = "/Tables/AuditLog/SetColumnVisibilitySetting",

  //Get Middleware Env
  MiddlewareEnv = "/Middleware/Env/Get",

  //Function
  TRANSLATE_TEXT = "/Function/Translation/Get",

  //Analytics
  TopicClustering = "/Tables/Analytics/TopicCluster",
  UpdateIntent = "/Tables/Analytics/UpdateIntent",
  AddExample = "/Tables/Analytics/AddExample",
  GetTopicName = "/Tables/Analytics/GetTopicName",

  //Soul Machine
  SoulMachineApiKey = "/Base/SoulMachine/ApiKey",
}
