import { backendConnector, DATA_LINK } from "connectors";
import { useEffect } from "react";
import React from "react";
import { Box as VStack } from "@mui/material";
import type { Moment } from "moment";
import { ColorX } from "static";
import "./ArenaLog.css";

import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import { Box } from "@mui/system";
import _ from "lodash";

interface IDefaultProps {
  period: { DateRange: [Moment, Moment] };
}

interface Rank {
  id: string;
  elo: number;
}

interface LeaderboardProps {
  ranks: Rank[];
}

const Leaderboard: React.FC<LeaderboardProps> = ({ ranks }) => {
  function roundInt(val: number) {
    //if val is not a number, return NaN
    if (_.isNumber(val) === false) {
      console.log(`round2dec: ${val} is not a number`);
      return NaN;
    }
    return Math.round(val);
  }

  return (
    <Box sx={{ height: "100%", maxWidth: 1000, border: "1px solid rgba(125, 125, 125, 0.2)", borderRadius: "5px" }} className="ArenaLogBox" justifyContent="flex-start" alignItems="flex-start">
      <TableContainer
        sx={{
          background: ColorX.GetColorCSS("TableBackground"),
          borderCollapse: "collapse",
          width: "100%",
        }}
      >
        <Table sx={{ width: "100%" }}>
          <TableHead>
            <TableRow className="ArenaLogTableHead">
              <TableCell>Rank</TableCell>
              <TableCell>ID</TableCell>
              <TableCell>Elo</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {ranks.map((rank, index) => (
              <TableRow key={rank.id} className="ArenaLogTableRow">
                <TableCell sx={{ fontSize: "1.5em", fontWeight: "bold", color: ColorX.GetColorCSS("Primary") }}>{index + 1}</TableCell>
                <TableCell>{rank.id}</TableCell>
                <TableCell>{roundInt(rank.elo)}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export const LeaderboardView: React.FC<IDefaultProps> = (props) => {
  const [ranks, setRanks] = React.useState<Rank[] | null>(null);
  useEffect(() => {
    async function getData() {
      const data = await backendConnector.post<{ ranks: Rank[] }>(DATA_LINK.ALeaderboard, {}, { DateRange: props.period });
      const ranks = data?.payload?.ranks || [];
      console.log(ranks);
      setRanks(ranks);
    }
    getData();
  }, [props.period.DateRange]);

  return ranks ? (
    <VStack width="100%" height={"auto"} className="LBVS" justifyContent="flex-start" alignItems="flex-start">
      <Leaderboard ranks={ranks} />
    </VStack>
  ) : (
    <div>Loading...</div>
  );
};
