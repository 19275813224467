import { FieldErrors, FormProvider, useForm } from "react-hook-form";
import { AuthorityTable } from "./Component/AuthorityTable";
import { Button } from "@mui/material";
import { RoleManagementFormContext } from "./hooks/contexts-hook";
import { DATA_LINK } from "connectors";
import { ErrorX, store } from "static";
import { useState } from "react";
import { backendConnector } from "connectors/backend-connector";
import { RoleInfo } from "./Component/RoleInfo";
import { ErrorSummary } from "./Component/ErrorSummary";
import _ from "lodash";
import { IRole } from "interfaces/db.interfaces/role.interface";
import { HStack, VStack } from "Components";
interface IProps {
  id: string;
  row: IRole;
  onQuit: () => void;
  onQuitRefresh: (_id: string) => void;
  renderFormizo: (schema: any, buttons: any, onSubmit: any) => JSX.Element;
  mode?: "Add" | "Edit" | "Info";
}

export default function RoleManager(props: IProps) {
  const methods = useForm<IRole>({ defaultValues: props.row, mode: "all" });
  const [errors, setErrors] = useState<FieldErrors<IRole> | null>(null);
  const mode = props.mode || "Info";
  const handleSubmit = (quitForm: boolean = true) => {
    const submitStatus = {
      valid: async (_newRow: IRole) => {
        try {
          let url = props.mode == "Add" ? DATA_LINK.RoleAdd : DATA_LINK.RoleEdit;

          const res = await backendConnector.post(url, _newRow);
          if (!res.Success) {
            const errorMsg = props.mode == "Add" ? "Role Add Failed:  " : "Role Edit Failed:  ";
            store.Alert(errorMsg + res.payload?.message, "error");
            return ErrorX.Handle(res.payload);
          }
          const successMsg = props.mode == "Add" ? "Role Added Successfully" : "Role Edited Successfully";
          store.Alert(successMsg, "success");
          if (quitForm) props.onQuitRefresh(_newRow._id);
        } catch (err: any) {
          ErrorX.Handle(err);
        }
      },
      invalid: (errors: FieldErrors<IRole>) => {
        setErrors(errors);
      },
    };

    methods.handleSubmit(submitStatus.valid, submitStatus.invalid)();
  };
  return (
    <VStack width={"100%"} height={"95%"}>
      {errors && <ErrorSummary errors={errors} />}
      <FormProvider {...methods}>
        <RoleManagementFormContext.Provider value={{ mode }}>
          <ButtonPannel mode={mode} onSubmit={handleSubmit} />
          <RoleInfo mode={mode} />
          <AuthorityTable mode={mode} />
        </RoleManagementFormContext.Provider>
      </FormProvider>
    </VStack>
  );
}

const ButtonPannel = ({ mode, onSubmit }: { mode: "Add" | "Edit" | "Info"; onSubmit: (quitForm?: boolean) => void }) => {
  if (mode === "Info") return <></>;

  return (
    <HStack width={"100%"} justifyContent={"flex-end"} sx={{ position: "absolute", zIndex: 5, top: 40, right: 100 }}>
      <Button
        //
        variant="contained"
        color="secondary"
        onClick={() => {
          onSubmit(true);
        }}
      >
        Submit
      </Button>
    </HStack>
  );
};
