import styled from "@emotion/styled";
import { Box, Button, Stack } from "@mui/material";
import { VStack } from "Components/Layouts";
import { AvailLANG, RoleId } from "interfaces";
import { store } from "static";

interface ZCMRoleProps {
  _onQuickReply: (quickReply: any) => void;
}

const multiLangPublic: Record<AvailLANG, string> = {
  [AvailLANG.EN]: "About General Public",
  [AvailLANG.SC]: "有关一般市民",
  [AvailLANG.TC]: "有關一般市民",
};

const multiLangProfessional: Record<AvailLANG, string> = {
  [AvailLANG.EN]: "About Healthcare Professional",
  [AvailLANG.SC]: "有关医护专业人员",
  [AvailLANG.TC]: "有關醫護專業人員",
};

const multiLangProvider: Record<AvailLANG, string> = {
  [AvailLANG.EN]: "About Healthcare Provider",
  [AvailLANG.SC]: "有关医护机构",
  [AvailLANG.TC]: "有關醫護機構",
};

const StyledButton = styled(Button)(({ theme }) => ({
  backgroundColor: "white",
  textTransform: "none",
  color: "rgb(41, 116, 214)",
  textAlign: "center",
  borderColor: "rgb(223, 223, 223)",
  borderBottom: "1px solid rgb(223, 223, 223)",
  fontFamily: `arial,"Microsoft JhengHei","微軟正黑體",sans-serif !important;`,
  "&:hover": {
    color: "gray",
  },
}));

export function setRoleBasedOnText(text: string) {
  // Convert the input text to lowercase for case-insensitive comparison
  const lowerText = text.toLowerCase();

  // Helper function to check if the lowercase text matches any value in the object
  const matchesAnyValue = (obj: Record<AvailLANG, string>) => Object.values(obj).some((value) => value.toLowerCase() === lowerText);

  if (matchesAnyValue(multiLangPublic)) {
    store.setRole(RoleId.GENERAL_PUBLIC);
  } else if (matchesAnyValue(multiLangProfessional)) {
    store.setRole(RoleId.HEALTHCARE_PROFESSIONAL);
  } else if (matchesAnyValue(multiLangProvider)) {
    store.setRole(RoleId.HEALTHCARE_PROVIDER);
  }
}
const ZCMRole: React.FC<ZCMRoleProps> = ({ _onQuickReply }) => {
  const onClick = (type: string, message: string) => {
    store.setRole(type);
    _onQuickReply({ title: message, payload: message });
  };
  return (
    <VStack
      sx={{
        width: "50%",
        margin: "5px 10px",
      }}
    >
      <StyledButton
        fullWidth
        onClick={() => {
          onClick(RoleId.GENERAL_PUBLIC, multiLangPublic[store.chatbotLang]);
        }}
      >
        {multiLangPublic[store.chatbotLang]}
      </StyledButton>
      <StyledButton
        fullWidth
        onClick={() => {
          onClick(RoleId.HEALTHCARE_PROFESSIONAL, multiLangProfessional[store.chatbotLang]);
        }}
      >
        {multiLangProfessional[store.chatbotLang]}
      </StyledButton>
      <StyledButton
        fullWidth
        onClick={() => {
          onClick(RoleId.HEALTHCARE_PROVIDER, multiLangProvider[store.chatbotLang]);
        }}
      >
        {multiLangProvider[store.chatbotLang]}
      </StyledButton>
    </VStack>
  );
};
export default ZCMRole;
