import { Chip, Typography } from "@mui/material";
import { DownlonadButton } from "Components/DownloadButton";
import { SwitchButton } from "Components/SwitchButton/SwitchButton";
import { TablizoSchema } from "Components/LabIZO/Tablizo";
import { DATA_LINK } from "connectors/datalink";
import { RelationType } from "interfaces/db.interfaces/gpt-qa-document.interface";

export const tableSchema: TablizoSchema[] = [
  {
    label: "Enabled",
    name: "enabled",
    width: 100,
    reqAuth: "GPTQA.DocumentManager",
    reqLevel: 999,
    reqFunc: "Enable",
    Cell(row, field, addOns, fetchData) {
      return <SwitchButton defaultValue={field} URL={DATA_LINK.DMEnable} row={row} fetchData={fetchData} disabled={row.status != "ready" || (row.knowledge_grps?.length > 0 && field == true)} />;
    },
  },
  // {
  //   label: "Document ID",
  //   name: "_id",
  // },
  {
    label: "Document Name",
    name: "alias",
    defaultSort: "desc",
  },
  // {
  //   label: "Context",
  //   name: "contexts.context",
  //   Cell(row, field, addOns) {
  //     return <>{field}</>;
  //   },
  // },

  {
    label: "Reference Link",
    name: "reference.link",
    Cell(row, field, addOns) {
      //return hyperlink
      return (
        <a href={field} target="_blank">
          {field}
        </a>
      );
    },
  },
  {
    label: "Knowledge Groups Belongs",
    name: "knowledge_grps",
    reqAuth: "GPTQA.KnowledgeGroupManagement",
    // Cell(row, field, addOns) {
    //   return <div>{row?.knowledge_grps?.map((item: { alias: any }) => item?.alias).join(", ")}</div>;
    // },
    valueGetter(row) {
      return row?.knowledge_grps?.map((item: { alias: any }) => item?.alias).join(", ");
    },
    filterable: true,
  },
  {
    label: "Created At",
    name: "CREATED_AT",
  },
  {
    label: "Last Modified Time",
    name: "LASTMODIFIED",
  },
  {
    label: "Text File",
    name: "text_file.location",
    Cell(row, field, addOns) {
      return <DownlonadButton URL={DATA_LINK.DMDownload} redirectURL={field} filename={row.alias} />;
    },
    width: 100,
  },
  {
    label: "Original File",
    name: "original_file.location",
    Cell(row, field, addOns) {
      return <DownlonadButton URL={DATA_LINK.DMDownload} redirectURL={field} filename={row?.original_file?.name ?? row.alias} />;
    },
    width: 100,
  },

  {
    label: "Status",
    name: "status",
    Cell(row, field, addOns) {
      return <Typography color={field == "ready" ? "success.main" : "info.main"}>{field}</Typography>;
    },
    width: 100,
  },
];
